const UnitsIlava = {
  Ilava: [
    { lat: 48.9969703, lng: 18.196658 },
    { lat: 48.9960856, lng: 18.1956501 },
    { lat: 48.9958515, lng: 18.1953847 },
    { lat: 48.9951577, lng: 18.1945986 },
    { lat: 48.9942098, lng: 18.1935145 },
    { lat: 48.9941809, lng: 18.1934815 },
    { lat: 48.9941664, lng: 18.1934649 },
    { lat: 48.9931302, lng: 18.1947387 },
    { lat: 48.9926451, lng: 18.1953332 },
    { lat: 48.9926156, lng: 18.1953698 },
    { lat: 48.9926051, lng: 18.1953827 },
    { lat: 48.9925291, lng: 18.195475 },
    { lat: 48.9925112, lng: 18.1954975 },
    { lat: 48.9922223, lng: 18.1958505 },
    { lat: 48.9922096, lng: 18.1958658 },
    { lat: 48.9921654, lng: 18.1959201 },
    { lat: 48.9920134, lng: 18.1961051 },
    { lat: 48.9909674, lng: 18.1973849 },
    { lat: 48.99036, lng: 18.1981284 },
    { lat: 48.9902834, lng: 18.1982294 },
    { lat: 48.9902664, lng: 18.1982487 },
    { lat: 48.9901869, lng: 18.1983393 },
    { lat: 48.9901807, lng: 18.1983475 },
    { lat: 48.9901055, lng: 18.1984336 },
    { lat: 48.9899669, lng: 18.198593 },
    { lat: 48.989412, lng: 18.19923 },
    { lat: 48.9893952, lng: 18.1992502 },
    { lat: 48.9893738, lng: 18.1992744 },
    { lat: 48.9892997, lng: 18.1993596 },
    { lat: 48.9887757, lng: 18.1999617 },
    { lat: 48.9887419, lng: 18.200001 },
    { lat: 48.9887249, lng: 18.2000194 },
    { lat: 48.9887048, lng: 18.200043 },
    { lat: 48.9886932, lng: 18.2000566 },
    { lat: 48.9885364, lng: 18.2002368 },
    { lat: 48.9885332, lng: 18.2002401 },
    { lat: 48.9882401, lng: 18.2005778 },
    { lat: 48.9882368, lng: 18.2005811 },
    { lat: 48.9877837, lng: 18.2011017 },
    { lat: 48.9877403, lng: 18.2011511 },
    { lat: 48.9877128, lng: 18.2011835 },
    { lat: 48.9876883, lng: 18.2012108 },
    { lat: 48.9876693, lng: 18.201233 },
    { lat: 48.9876534, lng: 18.2012513 },
    { lat: 48.9876227, lng: 18.2012862 },
    { lat: 48.9873252, lng: 18.2016282 },
    { lat: 48.9873072, lng: 18.2016491 },
    { lat: 48.9872627, lng: 18.2016999 },
    { lat: 48.9872289, lng: 18.201739 },
    { lat: 48.9871781, lng: 18.2017973 },
    { lat: 48.9871441, lng: 18.2018364 },
    { lat: 48.9870192, lng: 18.2019797 },
    { lat: 48.9869027, lng: 18.202113 },
    { lat: 48.9860663, lng: 18.203075 },
    { lat: 48.9859593, lng: 18.2031973 },
    { lat: 48.9858174, lng: 18.203359 },
    { lat: 48.9858165, lng: 18.2033618 },
    { lat: 48.9854139, lng: 18.2040578 },
    { lat: 48.9854098, lng: 18.2040652 },
    { lat: 48.9853724, lng: 18.2041194 },
    { lat: 48.9852703, lng: 18.2042704 },
    { lat: 48.9852449, lng: 18.2042993 },
    { lat: 48.9852114, lng: 18.2043246 },
    { lat: 48.9851994, lng: 18.2043509 },
    { lat: 48.9851451, lng: 18.2044687 },
    { lat: 48.985131, lng: 18.2044965 },
    { lat: 48.9850475, lng: 18.2046544 },
    { lat: 48.984944, lng: 18.2047593 },
    { lat: 48.9844973, lng: 18.2052153 },
    { lat: 48.9841152, lng: 18.2057169 },
    { lat: 48.983963, lng: 18.2059162 },
    { lat: 48.9835516, lng: 18.2066737 },
    { lat: 48.983319, lng: 18.2073225 },
    { lat: 48.9830842, lng: 18.2074975 },
    { lat: 48.9830776, lng: 18.207719 },
    { lat: 48.9831638, lng: 18.2078439 },
    { lat: 48.9829743, lng: 18.208141 },
    { lat: 48.9828414, lng: 18.2084016 },
    { lat: 48.982486, lng: 18.2089562 },
    { lat: 48.9821558, lng: 18.2093788 },
    { lat: 48.9816752, lng: 18.209932 },
    { lat: 48.9808917, lng: 18.2108342 },
    { lat: 48.9805399, lng: 18.2113125 },
    { lat: 48.980525, lng: 18.2114867 },
    { lat: 48.98051, lng: 18.2116572 },
    { lat: 48.9805051, lng: 18.2117092 },
    { lat: 48.9803636, lng: 18.2118401 },
    { lat: 48.9797889, lng: 18.2124654 },
    { lat: 48.979228, lng: 18.2130762 },
    { lat: 48.9790633, lng: 18.2132745 },
    { lat: 48.9789662, lng: 18.2133918 },
    { lat: 48.9784123, lng: 18.2140844 },
    { lat: 48.9781741, lng: 18.2143999 },
    { lat: 48.9778961, lng: 18.2148263 },
    { lat: 48.9775397, lng: 18.2154616 },
    { lat: 48.9775144, lng: 18.2155917 },
    { lat: 48.9771918, lng: 18.2160692 },
    { lat: 48.9769701, lng: 18.2164533 },
    { lat: 48.9761848, lng: 18.2178979 },
    { lat: 48.9759075, lng: 18.2184348 },
    { lat: 48.9757799, lng: 18.218809 },
    { lat: 48.975525, lng: 18.2193859 },
    { lat: 48.9754449, lng: 18.2195822 },
    { lat: 48.9753234, lng: 18.2199062 },
    { lat: 48.9752078, lng: 18.2203924 },
    { lat: 48.9749783, lng: 18.2209569 },
    { lat: 48.9748763, lng: 18.2212065 },
    { lat: 48.9746224, lng: 18.2216605 },
    { lat: 48.9741631, lng: 18.2224325 },
    { lat: 48.9736938, lng: 18.2232239 },
    { lat: 48.9735076, lng: 18.2234026 },
    { lat: 48.9733159, lng: 18.2237805 },
    { lat: 48.9730367, lng: 18.2242856 },
    { lat: 48.973197, lng: 18.2245845 },
    { lat: 48.9731474, lng: 18.2246449 },
    { lat: 48.9728448, lng: 18.2250166 },
    { lat: 48.9727903, lng: 18.2252091 },
    { lat: 48.9728367, lng: 18.2253283 },
    { lat: 48.9727094, lng: 18.2254122 },
    { lat: 48.9726802, lng: 18.2254542 },
    { lat: 48.9726615, lng: 18.2254816 },
    { lat: 48.9720907, lng: 18.2262974 },
    { lat: 48.9717631, lng: 18.2266048 },
    { lat: 48.9716303, lng: 18.2268489 },
    { lat: 48.9714647, lng: 18.2268944 },
    { lat: 48.9712186, lng: 18.226991 },
    { lat: 48.9706427, lng: 18.2276744 },
    { lat: 48.9705606, lng: 18.227937 },
    { lat: 48.9703354, lng: 18.2283419 },
    { lat: 48.9701023, lng: 18.2289453 },
    { lat: 48.9699795, lng: 18.2292653 },
    { lat: 48.9697146, lng: 18.2302392 },
    { lat: 48.9696856, lng: 18.2305027 },
    { lat: 48.9696419, lng: 18.2307257 },
    { lat: 48.9695265, lng: 18.231037 },
    { lat: 48.9694473, lng: 18.2313503 },
    { lat: 48.9690037, lng: 18.2318234 },
    { lat: 48.9684973, lng: 18.2319661 },
    { lat: 48.9682438, lng: 18.2320908 },
    { lat: 48.9678485, lng: 18.2322424 },
    { lat: 48.9677557, lng: 18.232337 },
    { lat: 48.9673547, lng: 18.2327461 },
    { lat: 48.9660973, lng: 18.2340642 },
    { lat: 48.9658965, lng: 18.2340246 },
    { lat: 48.965616, lng: 18.2343312 },
    { lat: 48.9655191, lng: 18.2345112 },
    { lat: 48.9651549, lng: 18.23519 },
    { lat: 48.9650798, lng: 18.2350388 },
    { lat: 48.9646569, lng: 18.2356371 },
    { lat: 48.964236, lng: 18.2361538 },
    { lat: 48.9639748, lng: 18.2364802 },
    { lat: 48.9637274, lng: 18.2368901 },
    { lat: 48.9634693, lng: 18.2368349 },
    { lat: 48.9632311, lng: 18.2368937 },
    { lat: 48.9632366, lng: 18.2372078 },
    { lat: 48.9631889, lng: 18.2374005 },
    { lat: 48.9629072, lng: 18.2376455 },
    { lat: 48.9628162, lng: 18.2379097 },
    { lat: 48.9625726, lng: 18.2379534 },
    { lat: 48.9624307, lng: 18.2380743 },
    { lat: 48.9620955, lng: 18.2383715 },
    { lat: 48.9617183, lng: 18.2389998 },
    { lat: 48.9614505, lng: 18.2395673 },
    { lat: 48.9614191, lng: 18.2399279 },
    { lat: 48.9613897, lng: 18.2401451 },
    { lat: 48.9610636, lng: 18.2411372 },
    { lat: 48.9606353, lng: 18.2409462 },
    { lat: 48.9604626, lng: 18.2418782 },
    { lat: 48.9603167, lng: 18.2423275 },
    { lat: 48.9602734, lng: 18.2425798 },
    { lat: 48.9602015, lng: 18.2433982 },
    { lat: 48.9600321, lng: 18.2438114 },
    { lat: 48.959921, lng: 18.2442784 },
    { lat: 48.9598237, lng: 18.2451464 },
    { lat: 48.9597821, lng: 18.2454061 },
    { lat: 48.9596683, lng: 18.2460454 },
    { lat: 48.9596294, lng: 18.2464544 },
    { lat: 48.9596093, lng: 18.2465364 },
    { lat: 48.9595589, lng: 18.2466813 },
    { lat: 48.9594366, lng: 18.2469914 },
    { lat: 48.9592961, lng: 18.2473189 },
    { lat: 48.959163, lng: 18.2475369 },
    { lat: 48.9590582, lng: 18.2476393 },
    { lat: 48.9590096, lng: 18.2477159 },
    { lat: 48.9589698, lng: 18.2478719 },
    { lat: 48.9589544, lng: 18.2482145 },
    { lat: 48.9589407, lng: 18.24841 },
    { lat: 48.9587702, lng: 18.2495594 },
    { lat: 48.958596, lng: 18.2504062 },
    { lat: 48.9586839, lng: 18.2530864 },
    { lat: 48.958693, lng: 18.253385 },
    { lat: 48.9586846, lng: 18.2536963 },
    { lat: 48.9577842, lng: 18.2548881 },
    { lat: 48.9575549, lng: 18.2551014 },
    { lat: 48.9574359, lng: 18.2552313 },
    { lat: 48.9570182, lng: 18.255426 },
    { lat: 48.9564028, lng: 18.255834 },
    { lat: 48.9561277, lng: 18.2559962 },
    { lat: 48.9559357, lng: 18.2561913 },
    { lat: 48.9556323, lng: 18.2566324 },
    { lat: 48.9552754, lng: 18.2571825 },
    { lat: 48.9550163, lng: 18.2575869 },
    { lat: 48.9547251, lng: 18.2581222 },
    { lat: 48.9544533, lng: 18.2585035 },
    { lat: 48.9541457, lng: 18.259187 },
    { lat: 48.9537381, lng: 18.2598571 },
    { lat: 48.953487, lng: 18.2603644 },
    { lat: 48.9531447, lng: 18.2612898 },
    { lat: 48.9528921, lng: 18.2621674 },
    { lat: 48.9523183, lng: 18.2624344 },
    { lat: 48.9521566, lng: 18.2625471 },
    { lat: 48.9520011, lng: 18.2627311 },
    { lat: 48.9519033, lng: 18.263235 },
    { lat: 48.9517892, lng: 18.2634517 },
    { lat: 48.9517573, lng: 18.263625 },
    { lat: 48.9516814, lng: 18.2637989 },
    { lat: 48.9515908, lng: 18.2638716 },
    { lat: 48.9514414, lng: 18.2638637 },
    { lat: 48.9508004, lng: 18.2636624 },
    { lat: 48.9506636, lng: 18.2635586 },
    { lat: 48.9503573, lng: 18.2634885 },
    { lat: 48.9501927, lng: 18.2635512 },
    { lat: 48.94999, lng: 18.2637168 },
    { lat: 48.9497369, lng: 18.2637512 },
    { lat: 48.9495591, lng: 18.2636782 },
    { lat: 48.9493218, lng: 18.2634942 },
    { lat: 48.9491602, lng: 18.2632719 },
    { lat: 48.9490692, lng: 18.2629983 },
    { lat: 48.9490429, lng: 18.2628328 },
    { lat: 48.9489745, lng: 18.2626809 },
    { lat: 48.9489179, lng: 18.2626384 },
    { lat: 48.9487299, lng: 18.2625672 },
    { lat: 48.9484388, lng: 18.2625264 },
    { lat: 48.9481522, lng: 18.2624058 },
    { lat: 48.9480536, lng: 18.2623978 },
    { lat: 48.9479117, lng: 18.2624245 },
    { lat: 48.9476174, lng: 18.2625654 },
    { lat: 48.9475461, lng: 18.2625816 },
    { lat: 48.9471518, lng: 18.2626108 },
    { lat: 48.9471166, lng: 18.2626287 },
    { lat: 48.9470639, lng: 18.2626548 },
    { lat: 48.9465965, lng: 18.2616145 },
    { lat: 48.9461798, lng: 18.2615683 },
    { lat: 48.9460038, lng: 18.2615484 },
    { lat: 48.9455008, lng: 18.2615531 },
    { lat: 48.9454909, lng: 18.2615962 },
    { lat: 48.9453769, lng: 18.2620729 },
    { lat: 48.945222, lng: 18.2627235 },
    { lat: 48.9451808, lng: 18.2628929 },
    { lat: 48.9451341, lng: 18.2630436 },
    { lat: 48.9449923, lng: 18.2634886 },
    { lat: 48.9449355, lng: 18.2636812 },
    { lat: 48.9449172, lng: 18.2637558 },
    { lat: 48.9448163, lng: 18.2641455 },
    { lat: 48.9447394, lng: 18.2646796 },
    { lat: 48.9436869, lng: 18.2659962 },
    { lat: 48.9435629, lng: 18.2666538 },
    { lat: 48.9431416, lng: 18.2670444 },
    { lat: 48.94188, lng: 18.2680563 },
    { lat: 48.9414817, lng: 18.2686158 },
    { lat: 48.9405073, lng: 18.2691784 },
    { lat: 48.9399463, lng: 18.2703507 },
    { lat: 48.9396043, lng: 18.2716029 },
    { lat: 48.9395264, lng: 18.2717617 },
    { lat: 48.9395142, lng: 18.2718043 },
    { lat: 48.9390624, lng: 18.2731508 },
    { lat: 48.9388161, lng: 18.2738008 },
    { lat: 48.9380111, lng: 18.2745308 },
    { lat: 48.9371885, lng: 18.2749662 },
    { lat: 48.9366213, lng: 18.275033 },
    { lat: 48.9362139, lng: 18.2750703 },
    { lat: 48.9347541, lng: 18.2771067 },
    { lat: 48.9343865, lng: 18.2777482 },
    { lat: 48.934283, lng: 18.2779144 },
    { lat: 48.9342106, lng: 18.2784094 },
    { lat: 48.9339683, lng: 18.2798495 },
    { lat: 48.9339559, lng: 18.2804071 },
    { lat: 48.9338627, lng: 18.2808162 },
    { lat: 48.9336744, lng: 18.2815356 },
    { lat: 48.9336404, lng: 18.2820512 },
    { lat: 48.9333769, lng: 18.2828185 },
    { lat: 48.933045, lng: 18.2833137 },
    { lat: 48.9327353, lng: 18.2836623 },
    { lat: 48.9336604, lng: 18.2843605 },
    { lat: 48.9340227, lng: 18.2845419 },
    { lat: 48.9345311, lng: 18.2844745 },
    { lat: 48.9346048, lng: 18.2844606 },
    { lat: 48.9347082, lng: 18.2844543 },
    { lat: 48.935261, lng: 18.2845125 },
    { lat: 48.9357408, lng: 18.2847967 },
    { lat: 48.93588, lng: 18.2848245 },
    { lat: 48.9361719, lng: 18.2849591 },
    { lat: 48.936274, lng: 18.2849672 },
    { lat: 48.9371001, lng: 18.2850326 },
    { lat: 48.9374967, lng: 18.2845634 },
    { lat: 48.9381024, lng: 18.2842017 },
    { lat: 48.9387012, lng: 18.2837583 },
    { lat: 48.9390172, lng: 18.2834401 },
    { lat: 48.9394285, lng: 18.2826917 },
    { lat: 48.9397653, lng: 18.281883 },
    { lat: 48.940243, lng: 18.2806141 },
    { lat: 48.9408084, lng: 18.2798596 },
    { lat: 48.9416488, lng: 18.2789789 },
    { lat: 48.9417737, lng: 18.278236 },
    { lat: 48.9435765, lng: 18.2787116 },
    { lat: 48.9438346, lng: 18.2784867 },
    { lat: 48.9445131, lng: 18.2785938 },
    { lat: 48.9448993, lng: 18.2782617 },
    { lat: 48.945767, lng: 18.2772442 },
    { lat: 48.9465956, lng: 18.2772131 },
    { lat: 48.9472741, lng: 18.2773192 },
    { lat: 48.9480027, lng: 18.2774349 },
    { lat: 48.9493044, lng: 18.2770917 },
    { lat: 48.9499491, lng: 18.2766212 },
    { lat: 48.9508175, lng: 18.2759564 },
    { lat: 48.9511571, lng: 18.2757965 },
    { lat: 48.9522815, lng: 18.275947 },
    { lat: 48.9525041, lng: 18.2759316 },
    { lat: 48.9532233, lng: 18.2758604 },
    { lat: 48.9541114, lng: 18.2771622 },
    { lat: 48.9544759, lng: 18.2769833 },
    { lat: 48.9551358, lng: 18.2763034 },
    { lat: 48.956116, lng: 18.276198 },
    { lat: 48.956936, lng: 18.2767317 },
    { lat: 48.9573401, lng: 18.2766551 },
    { lat: 48.9573166, lng: 18.2768192 },
    { lat: 48.957307, lng: 18.2768875 },
    { lat: 48.9572986, lng: 18.2769402 },
    { lat: 48.9572395, lng: 18.2775295 },
    { lat: 48.9573171, lng: 18.2778642 },
    { lat: 48.957523, lng: 18.2778562 },
    { lat: 48.9583011, lng: 18.2778263 },
    { lat: 48.9592431, lng: 18.2779857 },
    { lat: 48.9590164, lng: 18.2758327 },
    { lat: 48.9595439, lng: 18.2760239 },
    { lat: 48.9596838, lng: 18.2773207 },
    { lat: 48.959925, lng: 18.2784913 },
    { lat: 48.9600673, lng: 18.2803498 },
    { lat: 48.9604994, lng: 18.2811085 },
    { lat: 48.9611696, lng: 18.2799914 },
    { lat: 48.9616104, lng: 18.2778499 },
    { lat: 48.9617623, lng: 18.2778818 },
    { lat: 48.9618239, lng: 18.2778953 },
    { lat: 48.9618605, lng: 18.2779024 },
    { lat: 48.9622566, lng: 18.2779861 },
    { lat: 48.9624293, lng: 18.2776077 },
    { lat: 48.9627827, lng: 18.2768329 },
    { lat: 48.9636344, lng: 18.2773302 },
    { lat: 48.9641233, lng: 18.2776955 },
    { lat: 48.9639982, lng: 18.2780169 },
    { lat: 48.9653682, lng: 18.2796388 },
    { lat: 48.9661137, lng: 18.279434 },
    { lat: 48.9662036, lng: 18.2797663 },
    { lat: 48.9674309, lng: 18.2790433 },
    { lat: 48.9681584, lng: 18.2791399 },
    { lat: 48.9684388, lng: 18.2790103 },
    { lat: 48.9690392, lng: 18.2789542 },
    { lat: 48.9703538, lng: 18.2784179 },
    { lat: 48.9716094, lng: 18.2784575 },
    { lat: 48.9718415, lng: 18.2783513 },
    { lat: 48.9718988, lng: 18.2783255 },
    { lat: 48.972078, lng: 18.2782439 },
    { lat: 48.9721331, lng: 18.2782186 },
    { lat: 48.9724051, lng: 18.2782217 },
    { lat: 48.9729206, lng: 18.2781196 },
    { lat: 48.9731233, lng: 18.2782541 },
    { lat: 48.9736912, lng: 18.2775402 },
    { lat: 48.9736825, lng: 18.2772684 },
    { lat: 48.9739356, lng: 18.2770542 },
    { lat: 48.9741434, lng: 18.2766598 },
    { lat: 48.9741655, lng: 18.2766118 },
    { lat: 48.9742585, lng: 18.2762009 },
    { lat: 48.9745008, lng: 18.2758182 },
    { lat: 48.9745709, lng: 18.2756609 },
    { lat: 48.9746166, lng: 18.2753911 },
    { lat: 48.9745323, lng: 18.2750584 },
    { lat: 48.9746383, lng: 18.2747566 },
    { lat: 48.9746553, lng: 18.2744983 },
    { lat: 48.9747394, lng: 18.2744097 },
    { lat: 48.974914, lng: 18.274165 },
    { lat: 48.9748436, lng: 18.2745969 },
    { lat: 48.9748438, lng: 18.2750002 },
    { lat: 48.9749581, lng: 18.2751849 },
    { lat: 48.9749946, lng: 18.275451 },
    { lat: 48.9749707, lng: 18.2756671 },
    { lat: 48.9749002, lng: 18.2758961 },
    { lat: 48.9749409, lng: 18.2759382 },
    { lat: 48.9749817, lng: 18.2759817 },
    { lat: 48.9750165, lng: 18.276017 },
    { lat: 48.975208, lng: 18.2762718 },
    { lat: 48.9750572, lng: 18.2771576 },
    { lat: 48.9749695, lng: 18.2773617 },
    { lat: 48.9748538, lng: 18.2775303 },
    { lat: 48.9746436, lng: 18.2776237 },
    { lat: 48.9741211, lng: 18.2782408 },
    { lat: 48.9742981, lng: 18.2785382 },
    { lat: 48.9753827, lng: 18.2803591 },
    { lat: 48.9757296, lng: 18.2808296 },
    { lat: 48.976206, lng: 18.2814524 },
    { lat: 48.9767488, lng: 18.2821932 },
    { lat: 48.9769181, lng: 18.2824333 },
    { lat: 48.9769525, lng: 18.2824828 },
    { lat: 48.9770673, lng: 18.2826577 },
    { lat: 48.9771859, lng: 18.2828195 },
    { lat: 48.9777581, lng: 18.2836216 },
    { lat: 48.9781109, lng: 18.2827173 },
    { lat: 48.9785223, lng: 18.2807924 },
    { lat: 48.979388, lng: 18.2784032 },
    { lat: 48.9797138, lng: 18.2776412 },
    { lat: 48.9798497, lng: 18.2772905 },
    { lat: 48.9801559, lng: 18.2765218 },
    { lat: 48.9805375, lng: 18.275227 },
    { lat: 48.9807608, lng: 18.2743893 },
    { lat: 48.9811528, lng: 18.2733584 },
    { lat: 48.9816502, lng: 18.2727188 },
    { lat: 48.9821499, lng: 18.2718796 },
    { lat: 48.9824465, lng: 18.2716185 },
    { lat: 48.9827946, lng: 18.2713505 },
    { lat: 48.9830217, lng: 18.2707183 },
    { lat: 48.9832664, lng: 18.2703573 },
    { lat: 48.9834432, lng: 18.2698323 },
    { lat: 48.9846265, lng: 18.271323 },
    { lat: 48.9857061, lng: 18.2725353 },
    { lat: 48.9857289, lng: 18.2725611 },
    { lat: 48.9859377, lng: 18.2721238 },
    { lat: 48.9860192, lng: 18.2719888 },
    { lat: 48.9863527, lng: 18.2714617 },
    { lat: 48.9864269, lng: 18.2713195 },
    { lat: 48.9865294, lng: 18.2710744 },
    { lat: 48.9865715, lng: 18.2710012 },
    { lat: 48.986623, lng: 18.2709558 },
    { lat: 48.986749, lng: 18.2709081 },
    { lat: 48.9868074, lng: 18.2708045 },
    { lat: 48.9868339, lng: 18.2707554 },
    { lat: 48.9869366, lng: 18.2705348 },
    { lat: 48.987051, lng: 18.2702644 },
    { lat: 48.9876458, lng: 18.2692907 },
    { lat: 48.9886387, lng: 18.2684531 },
    { lat: 48.9892464, lng: 18.2680693 },
    { lat: 48.990153, lng: 18.2675598 },
    { lat: 48.9905069, lng: 18.2672141 },
    { lat: 48.9914296, lng: 18.266176 },
    { lat: 48.9917603, lng: 18.265838 },
    { lat: 48.9922439, lng: 18.2653919 },
    { lat: 48.9928581, lng: 18.264724 },
    { lat: 48.9934095, lng: 18.2641979 },
    { lat: 48.9937909, lng: 18.2637812 },
    { lat: 48.9938796, lng: 18.263673 },
    { lat: 48.9942534, lng: 18.2633018 },
    { lat: 48.9948148, lng: 18.2627162 },
    { lat: 48.9951164, lng: 18.2623037 },
    { lat: 48.9951569, lng: 18.2622419 },
    { lat: 48.9952654, lng: 18.2621069 },
    { lat: 48.9953055, lng: 18.2620575 },
    { lat: 48.9953277, lng: 18.2620305 },
    { lat: 48.9953392, lng: 18.2620157 },
    { lat: 48.9953846, lng: 18.2619609 },
    { lat: 48.9954637, lng: 18.261864 },
    { lat: 48.9957401, lng: 18.2615242 },
    { lat: 48.9957496, lng: 18.2615114 },
    { lat: 48.9959531, lng: 18.261261 },
    { lat: 48.9962146, lng: 18.261853 },
    { lat: 48.997668, lng: 18.2602839 },
    { lat: 48.9976995, lng: 18.2603429 },
    { lat: 48.9978527, lng: 18.2601593 },
    { lat: 48.9980379, lng: 18.2599439 },
    { lat: 48.9981538, lng: 18.2597985 },
    { lat: 48.9983074, lng: 18.2596219 },
    { lat: 48.9983847, lng: 18.2595101 },
    { lat: 48.998466, lng: 18.2593936 },
    { lat: 48.9985029, lng: 18.2593486 },
    { lat: 48.9985356, lng: 18.2593082 },
    { lat: 48.9985641, lng: 18.2592728 },
    { lat: 48.9986635, lng: 18.2591559 },
    { lat: 48.998764, lng: 18.2590386 },
    { lat: 48.9987775, lng: 18.259016 },
    { lat: 48.9991315, lng: 18.2585331 },
    { lat: 48.9993063, lng: 18.2582955 },
    { lat: 49.0001139, lng: 18.2571952 },
    { lat: 49.0007791, lng: 18.2560718 },
    { lat: 49.0008014, lng: 18.2560279 },
    { lat: 49.0012822, lng: 18.2550748 },
    { lat: 49.0022505, lng: 18.2526928 },
    { lat: 49.0024235, lng: 18.2522716 },
    { lat: 49.0024895, lng: 18.2521109 },
    { lat: 49.0028869, lng: 18.2511436 },
    { lat: 49.0029047, lng: 18.2510989 },
    { lat: 49.0030426, lng: 18.250764 },
    { lat: 49.0034229, lng: 18.2497528 },
    { lat: 49.0038459, lng: 18.2487576 },
    { lat: 49.0038756, lng: 18.2487426 },
    { lat: 49.0039545, lng: 18.2487031 },
    { lat: 49.0039776, lng: 18.2486915 },
    { lat: 49.0065306, lng: 18.247412 },
    { lat: 49.0066993, lng: 18.2468878 },
    { lat: 49.0068089, lng: 18.2453196 },
    { lat: 49.0068056, lng: 18.2444669 },
    { lat: 49.0071508, lng: 18.2448834 },
    { lat: 49.007189, lng: 18.2448216 },
    { lat: 49.0073099, lng: 18.2446253 },
    { lat: 49.0073348, lng: 18.2445861 },
    { lat: 49.0073486, lng: 18.2445637 },
    { lat: 49.0073668, lng: 18.2445341 },
    { lat: 49.0074091, lng: 18.2444653 },
    { lat: 49.0074473, lng: 18.2444031 },
    { lat: 49.0074783, lng: 18.2443527 },
    { lat: 49.0075082, lng: 18.2443038 },
    { lat: 49.0075434, lng: 18.2442469 },
    { lat: 49.0081116, lng: 18.2433251 },
    { lat: 49.0092509, lng: 18.2424851 },
    { lat: 49.0093017, lng: 18.2424472 },
    { lat: 49.0093532, lng: 18.2424196 },
    { lat: 49.0095129, lng: 18.2423328 },
    { lat: 49.0095326, lng: 18.2423221 },
    { lat: 49.0095644, lng: 18.2423052 },
    { lat: 49.0099026, lng: 18.2421214 },
    { lat: 49.0099354, lng: 18.2421034 },
    { lat: 49.0099694, lng: 18.2420856 },
    { lat: 49.010092, lng: 18.2420189 },
    { lat: 49.0101632, lng: 18.24198 },
    { lat: 49.010268, lng: 18.2419005 },
    { lat: 49.0102886, lng: 18.2418845 },
    { lat: 49.010307, lng: 18.2418706 },
    { lat: 49.010322, lng: 18.2418587 },
    { lat: 49.0105405, lng: 18.2416924 },
    { lat: 49.0107081, lng: 18.2415636 },
    { lat: 49.0109375, lng: 18.2413866 },
    { lat: 49.0110897, lng: 18.2412691 },
    { lat: 49.0113959, lng: 18.2410393 },
    { lat: 49.0124896, lng: 18.2401925 },
    { lat: 49.012536, lng: 18.2401563 },
    { lat: 49.0125706, lng: 18.240127 },
    { lat: 49.0126593, lng: 18.24006 },
    { lat: 49.0132052, lng: 18.2395809 },
    { lat: 49.0132644, lng: 18.2395298 },
    { lat: 49.0134171, lng: 18.2393983 },
    { lat: 49.0121018, lng: 18.2379351 },
    { lat: 49.0112835, lng: 18.236144 },
    { lat: 49.0111964, lng: 18.2356501 },
    { lat: 49.0108855, lng: 18.2338873 },
    { lat: 49.0108968, lng: 18.2311323 },
    { lat: 49.0102654, lng: 18.2282055 },
    { lat: 49.0101571, lng: 18.2278102 },
    { lat: 49.0100758, lng: 18.227513 },
    { lat: 49.0095635, lng: 18.2256495 },
    { lat: 49.0091014, lng: 18.2247253 },
    { lat: 49.0090772, lng: 18.2246784 },
    { lat: 49.0090428, lng: 18.2246083 },
    { lat: 49.008941, lng: 18.2244046 },
    { lat: 49.008814, lng: 18.2241964 },
    { lat: 49.0087405, lng: 18.224074 },
    { lat: 49.008738, lng: 18.2240705 },
    { lat: 49.0086583, lng: 18.2239394 },
    { lat: 49.0086533, lng: 18.2239311 },
    { lat: 49.0085338, lng: 18.2237355 },
    { lat: 49.0083495, lng: 18.2234312 },
    { lat: 49.0069387, lng: 18.2218584 },
    { lat: 49.0061096, lng: 18.2212689 },
    { lat: 49.0054899, lng: 18.2208291 },
    { lat: 49.0041171, lng: 18.2199644 },
    { lat: 49.0039145, lng: 18.219836 },
    { lat: 49.003101, lng: 18.2193614 },
    { lat: 49.0022945, lng: 18.2188896 },
    { lat: 49.0008043, lng: 18.217413 },
    { lat: 48.9999659, lng: 18.2151579 },
    { lat: 48.9996719, lng: 18.212338 },
    { lat: 49.0009066, lng: 18.2125798 },
    { lat: 49.0003924, lng: 18.2117941 },
    { lat: 48.9996782, lng: 18.2105707 },
    { lat: 48.9990196, lng: 18.2090554 },
    { lat: 48.9980293, lng: 18.2068895 },
    { lat: 48.9975189, lng: 18.2061315 },
    { lat: 48.9970696, lng: 18.2055968 },
    { lat: 48.9964577, lng: 18.2049765 },
    { lat: 48.9958114, lng: 18.2045268 },
    { lat: 48.995794, lng: 18.2045194 },
    { lat: 48.9957788, lng: 18.2042734 },
    { lat: 48.9957744, lng: 18.2041969 },
    { lat: 48.9957724, lng: 18.2041625 },
    { lat: 48.9957682, lng: 18.2040876 },
    { lat: 48.9957661, lng: 18.2040509 },
    { lat: 48.9957601, lng: 18.2039647 },
    { lat: 48.9957575, lng: 18.2039201 },
    { lat: 48.9956994, lng: 18.2029627 },
    { lat: 48.995976, lng: 18.2015844 },
    { lat: 48.9960394, lng: 18.201272 },
    { lat: 48.9960436, lng: 18.2012485 },
    { lat: 48.9960509, lng: 18.201217 },
    { lat: 48.9960551, lng: 18.2011929 },
    { lat: 48.996063, lng: 18.2011528 },
    { lat: 48.9960729, lng: 18.2011066 },
    { lat: 48.9965774, lng: 18.1986053 },
    { lat: 48.9968022, lng: 18.197487 },
    { lat: 48.9969703, lng: 18.196658 },
  ],
  DubnicanadVáhom: [
    { lat: 48.9894268, lng: 18.1828849 },
    { lat: 48.9891622, lng: 18.182381 },
    { lat: 48.9884307, lng: 18.1807445 },
    { lat: 48.9880397, lng: 18.179867 },
    { lat: 48.9878765, lng: 18.1795405 },
    { lat: 48.9871051, lng: 18.1779938 },
    { lat: 48.9868951, lng: 18.1775214 },
    { lat: 48.986877, lng: 18.1774806 },
    { lat: 48.9864972, lng: 18.176626 },
    { lat: 48.9861954, lng: 18.1759855 },
    { lat: 48.9861748, lng: 18.1759415 },
    { lat: 48.9860205, lng: 18.1756117 },
    { lat: 48.9859305, lng: 18.175421 },
    { lat: 48.9858058, lng: 18.175155 },
    { lat: 48.9852006, lng: 18.1735966 },
    { lat: 48.984526, lng: 18.1725768 },
    { lat: 48.9841068, lng: 18.1719407 },
    { lat: 48.9838434, lng: 18.1715396 },
    { lat: 48.9837968, lng: 18.1714729 },
    { lat: 48.9837437, lng: 18.1713948 },
    { lat: 48.9836367, lng: 18.1712396 },
    { lat: 48.9836269, lng: 18.1712273 },
    { lat: 48.9835493, lng: 18.1711145 },
    { lat: 48.9834645, lng: 18.1709921 },
    { lat: 48.9830288, lng: 18.1703619 },
    { lat: 48.9828208, lng: 18.1700605 },
    { lat: 48.9827962, lng: 18.1700249 },
    { lat: 48.9825795, lng: 18.1697113 },
    { lat: 48.9824342, lng: 18.1694992 },
    { lat: 48.9822508, lng: 18.1692329 },
    { lat: 48.9821856, lng: 18.1691405 },
    { lat: 48.9818928, lng: 18.1687161 },
    { lat: 48.9816982, lng: 18.1684337 },
    { lat: 48.9816661, lng: 18.1683873 },
    { lat: 48.9815563, lng: 18.1682291 },
    { lat: 48.9815161, lng: 18.1681712 },
    { lat: 48.9812859, lng: 18.1678387 },
    { lat: 48.9808427, lng: 18.1671957 },
    { lat: 48.9808194, lng: 18.1671625 },
    { lat: 48.9801855, lng: 18.166243 },
    { lat: 48.9801461, lng: 18.1661877 },
    { lat: 48.9796389, lng: 18.1654523 },
    { lat: 48.9795663, lng: 18.1653466 },
    { lat: 48.9795171, lng: 18.165277 },
    { lat: 48.9790159, lng: 18.1640568 },
    { lat: 48.9782555, lng: 18.1622173 },
    { lat: 48.9775249, lng: 18.1604227 },
    { lat: 48.9767489, lng: 18.1585271 },
    { lat: 48.9767346, lng: 18.1584942 },
    { lat: 48.9767267, lng: 18.1584748 },
    { lat: 48.9766902, lng: 18.158388 },
    { lat: 48.9766769, lng: 18.1583549 },
    { lat: 48.9761973, lng: 18.1571977 },
    { lat: 48.9761828, lng: 18.1571616 },
    { lat: 48.97607, lng: 18.1568888 },
    { lat: 48.9756094, lng: 18.1557473 },
    { lat: 48.9752007, lng: 18.1548032 },
    { lat: 48.9751444, lng: 18.1546695 },
    { lat: 48.9747877, lng: 18.1538243 },
    { lat: 48.9744272, lng: 18.1529346 },
    { lat: 48.9743545, lng: 18.1526332 },
    { lat: 48.9742332, lng: 18.152564 },
    { lat: 48.9741657, lng: 18.1524706 },
    { lat: 48.974162, lng: 18.1524647 },
    { lat: 48.9741375, lng: 18.1524306 },
    { lat: 48.9740995, lng: 18.1523789 },
    { lat: 48.973615, lng: 18.1517055 },
    { lat: 48.9733832, lng: 18.1513838 },
    { lat: 48.9719276, lng: 18.1493094 },
    { lat: 48.970753, lng: 18.147768 },
    { lat: 48.970454, lng: 18.147384 },
    { lat: 48.969857, lng: 18.146635 },
    { lat: 48.9689059, lng: 18.1454419 },
    { lat: 48.968768, lng: 18.145269 },
    { lat: 48.968523, lng: 18.144961 },
    { lat: 48.968311, lng: 18.144696 },
    { lat: 48.967585, lng: 18.14379 },
    { lat: 48.96691, lng: 18.142941 },
    { lat: 48.966856, lng: 18.142875 },
    { lat: 48.966332, lng: 18.142202 },
    { lat: 48.966254, lng: 18.142104 },
    { lat: 48.966177, lng: 18.142004 },
    { lat: 48.965684, lng: 18.141371 },
    { lat: 48.965447, lng: 18.141056 },
    { lat: 48.964902, lng: 18.140332 },
    { lat: 48.96402, lng: 18.139209 },
    { lat: 48.963133, lng: 18.138077 },
    { lat: 48.960004, lng: 18.134087 },
    { lat: 48.959756, lng: 18.133769 },
    { lat: 48.959664, lng: 18.133735 },
    { lat: 48.959456, lng: 18.13366 },
    { lat: 48.959343, lng: 18.133619 },
    { lat: 48.958565, lng: 18.133336 },
    { lat: 48.958503, lng: 18.133313 },
    { lat: 48.958368, lng: 18.133264 },
    { lat: 48.958347, lng: 18.133257 },
    { lat: 48.957542, lng: 18.1336 },
    { lat: 48.956686, lng: 18.133375 },
    { lat: 48.956274, lng: 18.133266 },
    { lat: 48.956196, lng: 18.133674 },
    { lat: 48.956135, lng: 18.133991 },
    { lat: 48.956093, lng: 18.134779 },
    { lat: 48.956074, lng: 18.135465 },
    { lat: 48.956093, lng: 18.135567 },
    { lat: 48.955757, lng: 18.135436 },
    { lat: 48.955559, lng: 18.135477 },
    { lat: 48.955267, lng: 18.135559 },
    { lat: 48.954969, lng: 18.135641 },
    { lat: 48.954947, lng: 18.13579 },
    { lat: 48.954895, lng: 18.136028 },
    { lat: 48.954865, lng: 18.136028 },
    { lat: 48.954824, lng: 18.136029 },
    { lat: 48.954641, lng: 18.136032 },
    { lat: 48.95459, lng: 18.136033 },
    { lat: 48.954468, lng: 18.135896 },
    { lat: 48.954386, lng: 18.135842 },
    { lat: 48.95424, lng: 18.135959 },
    { lat: 48.954174, lng: 18.13604 },
    { lat: 48.953901, lng: 18.136373 },
    { lat: 48.953811, lng: 18.136527 },
    { lat: 48.953761, lng: 18.136619 },
    { lat: 48.953707, lng: 18.136716 },
    { lat: 48.953607, lng: 18.136905 },
    { lat: 48.953584, lng: 18.13694 },
    { lat: 48.953557, lng: 18.136981 },
    { lat: 48.953512, lng: 18.137057 },
    { lat: 48.953464, lng: 18.137135 },
    { lat: 48.953394, lng: 18.137245 },
    { lat: 48.953315, lng: 18.13737 },
    { lat: 48.953171, lng: 18.137601 },
    { lat: 48.953096, lng: 18.13772 },
    { lat: 48.953026, lng: 18.137832 },
    { lat: 48.952971, lng: 18.13792 },
    { lat: 48.952905, lng: 18.138025 },
    { lat: 48.952781, lng: 18.138224 },
    { lat: 48.952657, lng: 18.138421 },
    { lat: 48.952604, lng: 18.138413 },
    { lat: 48.952444, lng: 18.138782 },
    { lat: 48.952295, lng: 18.139085 },
    { lat: 48.952158, lng: 18.139407 },
    { lat: 48.952057, lng: 18.139766 },
    { lat: 48.951921, lng: 18.140437 },
    { lat: 48.951845, lng: 18.140475 },
    { lat: 48.951652, lng: 18.140802 },
    { lat: 48.951578, lng: 18.140929 },
    { lat: 48.951551, lng: 18.140979 },
    { lat: 48.951468, lng: 18.141137 },
    { lat: 48.951343, lng: 18.141398 },
    { lat: 48.951335, lng: 18.141412 },
    { lat: 48.951148, lng: 18.141805 },
    { lat: 48.951096, lng: 18.141926 },
    { lat: 48.951055, lng: 18.142027 },
    { lat: 48.95101, lng: 18.14217 },
    { lat: 48.95096, lng: 18.142328 },
    { lat: 48.950926, lng: 18.14248 },
    { lat: 48.950844, lng: 18.142868 },
    { lat: 48.950832, lng: 18.14296 },
    { lat: 48.950778, lng: 18.143344 },
    { lat: 48.950767, lng: 18.143513 },
    { lat: 48.950743, lng: 18.144025 },
    { lat: 48.950697, lng: 18.144094 },
    { lat: 48.950612, lng: 18.144254 },
    { lat: 48.950287, lng: 18.144553 },
    { lat: 48.950249, lng: 18.14431 },
    { lat: 48.95019, lng: 18.144017 },
    { lat: 48.950087, lng: 18.143652 },
    { lat: 48.949957, lng: 18.143307 },
    { lat: 48.949847, lng: 18.143051 },
    { lat: 48.949597, lng: 18.14258 },
    { lat: 48.949312, lng: 18.142099 },
    { lat: 48.948897, lng: 18.14134 },
    { lat: 48.948728, lng: 18.141013 },
    { lat: 48.948594, lng: 18.140715 },
    { lat: 48.948524, lng: 18.14057 },
    { lat: 48.9485011, lng: 18.1405854 },
    { lat: 48.9484109, lng: 18.1406449 },
    { lat: 48.9482549, lng: 18.1410095 },
    { lat: 48.9474597, lng: 18.1428526 },
    { lat: 48.9474397, lng: 18.1428972 },
    { lat: 48.9473479, lng: 18.1431263 },
    { lat: 48.9472219, lng: 18.1434261 },
    { lat: 48.9470875, lng: 18.1438129 },
    { lat: 48.9469635, lng: 18.1442062 },
    { lat: 48.946898, lng: 18.1443833 },
    { lat: 48.9468536, lng: 18.1445317 },
    { lat: 48.9467052, lng: 18.1449322 },
    { lat: 48.9462451, lng: 18.1462061 },
    { lat: 48.9461603, lng: 18.1464405 },
    { lat: 48.9457905, lng: 18.1474631 },
    { lat: 48.9457791, lng: 18.1474984 },
    { lat: 48.9457735, lng: 18.1475178 },
    { lat: 48.9457665, lng: 18.1475409 },
    { lat: 48.9457583, lng: 18.1475681 },
    { lat: 48.9457213, lng: 18.1476891 },
    { lat: 48.9456709, lng: 18.1480092 },
    { lat: 48.9456088, lng: 18.1484594 },
    { lat: 48.9456426, lng: 18.1484975 },
    { lat: 48.9456284, lng: 18.1487212 },
    { lat: 48.9456306, lng: 18.148935 },
    { lat: 48.9456474, lng: 18.1491665 },
    { lat: 48.9456467, lng: 18.149545 },
    { lat: 48.9455539, lng: 18.150362 },
    { lat: 48.9454996, lng: 18.1506733 },
    { lat: 48.9454127, lng: 18.1509654 },
    { lat: 48.9451238, lng: 18.151669 },
    { lat: 48.9448872, lng: 18.1523423 },
    { lat: 48.944639, lng: 18.153067 },
    { lat: 48.9440261, lng: 18.1546505 },
    { lat: 48.9437934, lng: 18.1554266 },
    { lat: 48.9434728, lng: 18.1561317 },
    { lat: 48.9432709, lng: 18.1565058 },
    { lat: 48.9428168, lng: 18.1572202 },
    { lat: 48.9426013, lng: 18.157572 },
    { lat: 48.9420213, lng: 18.1587466 },
    { lat: 48.941772, lng: 18.1592786 },
    { lat: 48.9414034, lng: 18.159892 },
    { lat: 48.9406168, lng: 18.1610071 },
    { lat: 48.9405377, lng: 18.161084 },
    { lat: 48.9404769, lng: 18.1611437 },
    { lat: 48.9402289, lng: 18.1613253 },
    { lat: 48.9401401, lng: 18.1613902 },
    { lat: 48.9401906, lng: 18.161464 },
    { lat: 48.9406573, lng: 18.1621417 },
    { lat: 48.9409878, lng: 18.1627072 },
    { lat: 48.9412608, lng: 18.1629496 },
    { lat: 48.9413113, lng: 18.1629945 },
    { lat: 48.9414442, lng: 18.1631673 },
    { lat: 48.9415207, lng: 18.1632934 },
    { lat: 48.9415988, lng: 18.1634223 },
    { lat: 48.941632, lng: 18.1634944 },
    { lat: 48.9416607, lng: 18.1635567 },
    { lat: 48.9416762, lng: 18.1635897 },
    { lat: 48.941709, lng: 18.1637302 },
    { lat: 48.9417982, lng: 18.164024 },
    { lat: 48.9424775, lng: 18.165297 },
    { lat: 48.9424948, lng: 18.1653243 },
    { lat: 48.9428943, lng: 18.1659542 },
    { lat: 48.9435063, lng: 18.1668416 },
    { lat: 48.9434922, lng: 18.1668722 },
    { lat: 48.9430336, lng: 18.1680982 },
    { lat: 48.9428952, lng: 18.1684188 },
    { lat: 48.9428116, lng: 18.168614 },
    { lat: 48.9423836, lng: 18.1696069 },
    { lat: 48.942311, lng: 18.169661 },
    { lat: 48.9415061, lng: 18.1714602 },
    { lat: 48.9408973, lng: 18.1728607 },
    { lat: 48.940776, lng: 18.1733056 },
    { lat: 48.9406952, lng: 18.173607 },
    { lat: 48.9406747, lng: 18.1736812 },
    { lat: 48.9402581, lng: 18.1746601 },
    { lat: 48.9402193, lng: 18.1747492 },
    { lat: 48.9399936, lng: 18.1752802 },
    { lat: 48.9393565, lng: 18.1767806 },
    { lat: 48.9389243, lng: 18.1781525 },
    { lat: 48.9387652, lng: 18.1799402 },
    { lat: 48.9387623, lng: 18.1805061 },
    { lat: 48.9387617, lng: 18.1806268 },
    { lat: 48.9387617, lng: 18.1806661 },
    { lat: 48.9387611, lng: 18.1807326 },
    { lat: 48.9387599, lng: 18.1808896 },
    { lat: 48.9387573, lng: 18.1813352 },
    { lat: 48.9387561, lng: 18.1815315 },
    { lat: 48.9385651, lng: 18.1822791 },
    { lat: 48.9384358, lng: 18.1827712 },
    { lat: 48.9382819, lng: 18.183318 },
    { lat: 48.9382581, lng: 18.1834112 },
    { lat: 48.9382081, lng: 18.1836003 },
    { lat: 48.9378192, lng: 18.1841582 },
    { lat: 48.9374338, lng: 18.1848953 },
    { lat: 48.9369068, lng: 18.1853474 },
    { lat: 48.9360376, lng: 18.1856357 },
    { lat: 48.9353285, lng: 18.1862753 },
    { lat: 48.9344304, lng: 18.1873986 },
    { lat: 48.9341131, lng: 18.1876508 },
    { lat: 48.9337486, lng: 18.1882007 },
    { lat: 48.9330385, lng: 18.1892575 },
    { lat: 48.9327975, lng: 18.1898743 },
    { lat: 48.9326723, lng: 18.1905844 },
    { lat: 48.9325955, lng: 18.1921372 },
    { lat: 48.9325779, lng: 18.1929701 },
    { lat: 48.9326198, lng: 18.1937015 },
    { lat: 48.9327108, lng: 18.1944044 },
    { lat: 48.9327504, lng: 18.1948574 },
    { lat: 48.9328325, lng: 18.1951492 },
    { lat: 48.9327313, lng: 18.196396 },
    { lat: 48.93249, lng: 18.197245 },
    { lat: 48.9324336, lng: 18.1971871 },
    { lat: 48.9320748, lng: 18.1968514 },
    { lat: 48.9320107, lng: 18.1968367 },
    { lat: 48.931693, lng: 18.1967656 },
    { lat: 48.9314368, lng: 18.1965679 },
    { lat: 48.931288, lng: 18.1964533 },
    { lat: 48.9311077, lng: 18.1963964 },
    { lat: 48.9308484, lng: 18.1966289 },
    { lat: 48.9305709, lng: 18.1968161 },
    { lat: 48.930445, lng: 18.1969624 },
    { lat: 48.9300874, lng: 18.1970816 },
    { lat: 48.9297065, lng: 18.1974261 },
    { lat: 48.9292771, lng: 18.1975915 },
    { lat: 48.9291002, lng: 18.1975368 },
    { lat: 48.9288717, lng: 18.1975669 },
    { lat: 48.9286736, lng: 18.1976153 },
    { lat: 48.9278689, lng: 18.1975413 },
    { lat: 48.9278195, lng: 18.1975368 },
    { lat: 48.927246, lng: 18.1976128 },
    { lat: 48.9271257, lng: 18.1977964 },
    { lat: 48.9269017, lng: 18.19814 },
    { lat: 48.9266602, lng: 18.1987898 },
    { lat: 48.92637, lng: 18.1994955 },
    { lat: 48.9261823, lng: 18.199803 },
    { lat: 48.9257484, lng: 18.2005815 },
    { lat: 48.9254094, lng: 18.2011253 },
    { lat: 48.9249226, lng: 18.2022197 },
    { lat: 48.9246621, lng: 18.2027539 },
    { lat: 48.9244813, lng: 18.2031832 },
    { lat: 48.9241764, lng: 18.2038287 },
    { lat: 48.923834, lng: 18.204688 },
    { lat: 48.923545, lng: 18.2053755 },
    { lat: 48.9232977, lng: 18.2059646 },
    { lat: 48.9228419, lng: 18.2063786 },
    { lat: 48.9222853, lng: 18.2068651 },
    { lat: 48.92194, lng: 18.2075557 },
    { lat: 48.9219177, lng: 18.2076008 },
    { lat: 48.9218935, lng: 18.2076503 },
    { lat: 48.9217054, lng: 18.2083677 },
    { lat: 48.9219144, lng: 18.2092404 },
    { lat: 48.9224737, lng: 18.2106533 },
    { lat: 48.9224326, lng: 18.2112963 },
    { lat: 48.9226081, lng: 18.2132596 },
    { lat: 48.9225955, lng: 18.2145395 },
    { lat: 48.9223931, lng: 18.2149285 },
    { lat: 48.9219036, lng: 18.2151485 },
    { lat: 48.9209728, lng: 18.21588 },
    { lat: 48.9203316, lng: 18.2175735 },
    { lat: 48.9198524, lng: 18.2182515 },
    { lat: 48.9196449, lng: 18.2195377 },
    { lat: 48.919508, lng: 18.220903 },
    { lat: 48.919676, lng: 18.221498 },
    { lat: 48.919814, lng: 18.221778 },
    { lat: 48.919905, lng: 18.222134 },
    { lat: 48.919685, lng: 18.222777 },
    { lat: 48.91962, lng: 18.223086 },
    { lat: 48.919331, lng: 18.224395 },
    { lat: 48.918897, lng: 18.225498 },
    { lat: 48.918741, lng: 18.225824 },
    { lat: 48.918576, lng: 18.22638 },
    { lat: 48.918543, lng: 18.226749 },
    { lat: 48.918521, lng: 18.227269 },
    { lat: 48.918377, lng: 18.227315 },
    { lat: 48.9181, lng: 18.228046 },
    { lat: 48.91811, lng: 18.228679 },
    { lat: 48.917971, lng: 18.229258 },
    { lat: 48.917805, lng: 18.229809 },
    { lat: 48.917683, lng: 18.230227 },
    { lat: 48.917548, lng: 18.230839 },
    { lat: 48.917368, lng: 18.231489 },
    { lat: 48.91728, lng: 18.232179 },
    { lat: 48.917223, lng: 18.232834 },
    { lat: 48.91721, lng: 18.234042 },
    { lat: 48.91719, lng: 18.234437 },
    { lat: 48.917239, lng: 18.235067 },
    { lat: 48.917211, lng: 18.235592 },
    { lat: 48.917239, lng: 18.235759 },
    { lat: 48.917267, lng: 18.235982 },
    { lat: 48.917039, lng: 18.236519 },
    { lat: 48.916939, lng: 18.237058 },
    { lat: 48.916809, lng: 18.23746 },
    { lat: 48.916593, lng: 18.238137 },
    { lat: 48.916268, lng: 18.238692 },
    { lat: 48.916121, lng: 18.239003 },
    { lat: 48.9159943, lng: 18.2391352 },
    { lat: 48.915913, lng: 18.23922 },
    { lat: 48.915784, lng: 18.2394 },
    { lat: 48.915655, lng: 18.239715 },
    { lat: 48.915584, lng: 18.240236 },
    { lat: 48.915469, lng: 18.240473 },
    { lat: 48.915343, lng: 18.240716 },
    { lat: 48.9152, lng: 18.241087 },
    { lat: 48.915048, lng: 18.241436 },
    { lat: 48.91494, lng: 18.241834 },
    { lat: 48.914658, lng: 18.242795 },
    { lat: 48.914652, lng: 18.243284 },
    { lat: 48.91449, lng: 18.243745 },
    { lat: 48.914225, lng: 18.24419 },
    { lat: 48.913992, lng: 18.24496 },
    { lat: 48.913776, lng: 18.24567 },
    { lat: 48.91361, lng: 18.246185 },
    { lat: 48.913469, lng: 18.246554 },
    { lat: 48.913479, lng: 18.24691 },
    { lat: 48.913187, lng: 18.247419 },
    { lat: 48.913104, lng: 18.247609 },
    { lat: 48.912989, lng: 18.248649 },
    { lat: 48.912741, lng: 18.249074 },
    { lat: 48.912568, lng: 18.249594 },
    { lat: 48.912714, lng: 18.25028 },
    { lat: 48.912778, lng: 18.250864 },
    { lat: 48.913085, lng: 18.251548 },
    { lat: 48.913114, lng: 18.251626 },
    { lat: 48.913285, lng: 18.252103 },
    { lat: 48.913519, lng: 18.25253 },
    { lat: 48.913805, lng: 18.253063 },
    { lat: 48.914079, lng: 18.253176 },
    { lat: 48.914346, lng: 18.253319 },
    { lat: 48.914475, lng: 18.253441 },
    { lat: 48.914625, lng: 18.253583 },
    { lat: 48.914846, lng: 18.254036 },
    { lat: 48.915022, lng: 18.254221 },
    { lat: 48.915264, lng: 18.25427 },
    { lat: 48.915726, lng: 18.254234 },
    { lat: 48.915998, lng: 18.254278 },
    { lat: 48.916518, lng: 18.254367 },
    { lat: 48.916596, lng: 18.254539 },
    { lat: 48.91679, lng: 18.255003 },
    { lat: 48.917026, lng: 18.255467 },
    { lat: 48.917051, lng: 18.25552 },
    { lat: 48.917335, lng: 18.256131 },
    { lat: 48.917499, lng: 18.25683 },
    { lat: 48.917766, lng: 18.257763 },
    { lat: 48.917922, lng: 18.258789 },
    { lat: 48.91806, lng: 18.259737 },
    { lat: 48.918246, lng: 18.260669 },
    { lat: 48.918417, lng: 18.261438 },
    { lat: 48.918533, lng: 18.261875 },
    { lat: 48.918629, lng: 18.262536 },
    { lat: 48.918886, lng: 18.263523 },
    { lat: 48.919019, lng: 18.263788 },
    { lat: 48.919002, lng: 18.264865 },
    { lat: 48.919101, lng: 18.265148 },
    { lat: 48.919234, lng: 18.265356 },
    { lat: 48.919368, lng: 18.265921 },
    { lat: 48.91946, lng: 18.266332 },
    { lat: 48.919516, lng: 18.266583 },
    { lat: 48.919677, lng: 18.267357 },
    { lat: 48.919852, lng: 18.267689 },
    { lat: 48.919947, lng: 18.268363 },
    { lat: 48.920175, lng: 18.269007 },
    { lat: 48.920247, lng: 18.269818 },
    { lat: 48.920248, lng: 18.270106 },
    { lat: 48.920307, lng: 18.270842 },
    { lat: 48.92034, lng: 18.271333 },
    { lat: 48.920561, lng: 18.271923 },
    { lat: 48.920708, lng: 18.272782 },
    { lat: 48.920632, lng: 18.273287 },
    { lat: 48.92065, lng: 18.2735 },
    { lat: 48.920975, lng: 18.273764 },
    { lat: 48.921292, lng: 18.273951 },
    { lat: 48.921772, lng: 18.274103 },
    { lat: 48.921797, lng: 18.274114 },
    { lat: 48.92204, lng: 18.274225 },
    { lat: 48.922116, lng: 18.274167 },
    { lat: 48.922251, lng: 18.274331 },
    { lat: 48.922361, lng: 18.274728 },
    { lat: 48.922428, lng: 18.274925 },
    { lat: 48.92274, lng: 18.275578 },
    { lat: 48.922851, lng: 18.275762 },
    { lat: 48.923096, lng: 18.276018 },
    { lat: 48.92344, lng: 18.27627 },
    { lat: 48.923676, lng: 18.276476 },
    { lat: 48.923964, lng: 18.276854 },
    { lat: 48.924347, lng: 18.277283 },
    { lat: 48.924891, lng: 18.278471 },
    { lat: 48.9254918, lng: 18.2783612 },
    { lat: 48.9260902, lng: 18.2784102 },
    { lat: 48.9267786, lng: 18.2785322 },
    { lat: 48.92722, lng: 18.2786548 },
    { lat: 48.9275293, lng: 18.2785781 },
    { lat: 48.928, lng: 18.2781649 },
    { lat: 48.9284154, lng: 18.2776701 },
    { lat: 48.9288522, lng: 18.2778114 },
    { lat: 48.9294244, lng: 18.2789308 },
    { lat: 48.9294739, lng: 18.2790083 },
    { lat: 48.9299364, lng: 18.2793252 },
    { lat: 48.930596, lng: 18.2797767 },
    { lat: 48.9319738, lng: 18.2808782 },
    { lat: 48.9322954, lng: 18.2815777 },
    { lat: 48.9323366, lng: 18.2815652 },
    { lat: 48.9324308, lng: 18.2824347 },
    { lat: 48.9323925, lng: 18.2827539 },
    { lat: 48.9324332, lng: 18.2831763 },
    { lat: 48.932508, lng: 18.2833607 },
    { lat: 48.9327353, lng: 18.2836623 },
    { lat: 48.933045, lng: 18.2833137 },
    { lat: 48.9333769, lng: 18.2828185 },
    { lat: 48.9336404, lng: 18.2820512 },
    { lat: 48.9336744, lng: 18.2815356 },
    { lat: 48.9338627, lng: 18.2808162 },
    { lat: 48.9339559, lng: 18.2804071 },
    { lat: 48.9339683, lng: 18.2798495 },
    { lat: 48.9342106, lng: 18.2784094 },
    { lat: 48.934283, lng: 18.2779144 },
    { lat: 48.9343865, lng: 18.2777482 },
    { lat: 48.9347541, lng: 18.2771067 },
    { lat: 48.9362139, lng: 18.2750703 },
    { lat: 48.9366213, lng: 18.275033 },
    { lat: 48.9371885, lng: 18.2749662 },
    { lat: 48.9380111, lng: 18.2745308 },
    { lat: 48.9388161, lng: 18.2738008 },
    { lat: 48.9390624, lng: 18.2731508 },
    { lat: 48.9395142, lng: 18.2718043 },
    { lat: 48.9395264, lng: 18.2717617 },
    { lat: 48.9396043, lng: 18.2716029 },
    { lat: 48.9399463, lng: 18.2703507 },
    { lat: 48.9405073, lng: 18.2691784 },
    { lat: 48.9414817, lng: 18.2686158 },
    { lat: 48.94188, lng: 18.2680563 },
    { lat: 48.9431416, lng: 18.2670444 },
    { lat: 48.9435629, lng: 18.2666538 },
    { lat: 48.9436869, lng: 18.2659962 },
    { lat: 48.9447394, lng: 18.2646796 },
    { lat: 48.9448163, lng: 18.2641455 },
    { lat: 48.9449172, lng: 18.2637558 },
    { lat: 48.9449355, lng: 18.2636812 },
    { lat: 48.9449923, lng: 18.2634886 },
    { lat: 48.9451341, lng: 18.2630436 },
    { lat: 48.9451808, lng: 18.2628929 },
    { lat: 48.945222, lng: 18.2627235 },
    { lat: 48.9453769, lng: 18.2620729 },
    { lat: 48.9454909, lng: 18.2615962 },
    { lat: 48.9455008, lng: 18.2615531 },
    { lat: 48.9460038, lng: 18.2615484 },
    { lat: 48.9461798, lng: 18.2615683 },
    { lat: 48.9465965, lng: 18.2616145 },
    { lat: 48.9470639, lng: 18.2626548 },
    { lat: 48.9471166, lng: 18.2626287 },
    { lat: 48.9471518, lng: 18.2626108 },
    { lat: 48.9475461, lng: 18.2625816 },
    { lat: 48.9476174, lng: 18.2625654 },
    { lat: 48.9479117, lng: 18.2624245 },
    { lat: 48.9480536, lng: 18.2623978 },
    { lat: 48.9481522, lng: 18.2624058 },
    { lat: 48.9484388, lng: 18.2625264 },
    { lat: 48.9487299, lng: 18.2625672 },
    { lat: 48.9489179, lng: 18.2626384 },
    { lat: 48.9489745, lng: 18.2626809 },
    { lat: 48.9490429, lng: 18.2628328 },
    { lat: 48.9490692, lng: 18.2629983 },
    { lat: 48.9491602, lng: 18.2632719 },
    { lat: 48.9493218, lng: 18.2634942 },
    { lat: 48.9495591, lng: 18.2636782 },
    { lat: 48.9497369, lng: 18.2637512 },
    { lat: 48.94999, lng: 18.2637168 },
    { lat: 48.9501927, lng: 18.2635512 },
    { lat: 48.9503573, lng: 18.2634885 },
    { lat: 48.9506636, lng: 18.2635586 },
    { lat: 48.9508004, lng: 18.2636624 },
    { lat: 48.9514414, lng: 18.2638637 },
    { lat: 48.9515908, lng: 18.2638716 },
    { lat: 48.9516814, lng: 18.2637989 },
    { lat: 48.9517573, lng: 18.263625 },
    { lat: 48.9517892, lng: 18.2634517 },
    { lat: 48.9519033, lng: 18.263235 },
    { lat: 48.9520011, lng: 18.2627311 },
    { lat: 48.9521566, lng: 18.2625471 },
    { lat: 48.9523183, lng: 18.2624344 },
    { lat: 48.9528921, lng: 18.2621674 },
    { lat: 48.9531447, lng: 18.2612898 },
    { lat: 48.953487, lng: 18.2603644 },
    { lat: 48.9537381, lng: 18.2598571 },
    { lat: 48.9541457, lng: 18.259187 },
    { lat: 48.9544533, lng: 18.2585035 },
    { lat: 48.9547251, lng: 18.2581222 },
    { lat: 48.9550163, lng: 18.2575869 },
    { lat: 48.9552754, lng: 18.2571825 },
    { lat: 48.9556323, lng: 18.2566324 },
    { lat: 48.9559357, lng: 18.2561913 },
    { lat: 48.9561277, lng: 18.2559962 },
    { lat: 48.9564028, lng: 18.255834 },
    { lat: 48.9570182, lng: 18.255426 },
    { lat: 48.9574359, lng: 18.2552313 },
    { lat: 48.9575549, lng: 18.2551014 },
    { lat: 48.9577842, lng: 18.2548881 },
    { lat: 48.9586846, lng: 18.2536963 },
    { lat: 48.958693, lng: 18.253385 },
    { lat: 48.9586839, lng: 18.2530864 },
    { lat: 48.958596, lng: 18.2504062 },
    { lat: 48.9587702, lng: 18.2495594 },
    { lat: 48.9589407, lng: 18.24841 },
    { lat: 48.9589544, lng: 18.2482145 },
    { lat: 48.9589698, lng: 18.2478719 },
    { lat: 48.9590096, lng: 18.2477159 },
    { lat: 48.9590582, lng: 18.2476393 },
    { lat: 48.959163, lng: 18.2475369 },
    { lat: 48.9592961, lng: 18.2473189 },
    { lat: 48.9594366, lng: 18.2469914 },
    { lat: 48.9595589, lng: 18.2466813 },
    { lat: 48.9596093, lng: 18.2465364 },
    { lat: 48.9596294, lng: 18.2464544 },
    { lat: 48.9596683, lng: 18.2460454 },
    { lat: 48.9597821, lng: 18.2454061 },
    { lat: 48.9598237, lng: 18.2451464 },
    { lat: 48.959921, lng: 18.2442784 },
    { lat: 48.9600321, lng: 18.2438114 },
    { lat: 48.9602015, lng: 18.2433982 },
    { lat: 48.9602734, lng: 18.2425798 },
    { lat: 48.9603167, lng: 18.2423275 },
    { lat: 48.9604626, lng: 18.2418782 },
    { lat: 48.9606353, lng: 18.2409462 },
    { lat: 48.9610636, lng: 18.2411372 },
    { lat: 48.9613897, lng: 18.2401451 },
    { lat: 48.9614191, lng: 18.2399279 },
    { lat: 48.9614505, lng: 18.2395673 },
    { lat: 48.9617183, lng: 18.2389998 },
    { lat: 48.9620955, lng: 18.2383715 },
    { lat: 48.9624307, lng: 18.2380743 },
    { lat: 48.9625726, lng: 18.2379534 },
    { lat: 48.9628162, lng: 18.2379097 },
    { lat: 48.9629072, lng: 18.2376455 },
    { lat: 48.9631889, lng: 18.2374005 },
    { lat: 48.9632366, lng: 18.2372078 },
    { lat: 48.9632311, lng: 18.2368937 },
    { lat: 48.9634693, lng: 18.2368349 },
    { lat: 48.9637274, lng: 18.2368901 },
    { lat: 48.9639748, lng: 18.2364802 },
    { lat: 48.964236, lng: 18.2361538 },
    { lat: 48.9646569, lng: 18.2356371 },
    { lat: 48.9650798, lng: 18.2350388 },
    { lat: 48.9651549, lng: 18.23519 },
    { lat: 48.9655191, lng: 18.2345112 },
    { lat: 48.965616, lng: 18.2343312 },
    { lat: 48.9658965, lng: 18.2340246 },
    { lat: 48.9660973, lng: 18.2340642 },
    { lat: 48.9673547, lng: 18.2327461 },
    { lat: 48.9677557, lng: 18.232337 },
    { lat: 48.9678485, lng: 18.2322424 },
    { lat: 48.9682438, lng: 18.2320908 },
    { lat: 48.9684973, lng: 18.2319661 },
    { lat: 48.9690037, lng: 18.2318234 },
    { lat: 48.9694473, lng: 18.2313503 },
    { lat: 48.9695265, lng: 18.231037 },
    { lat: 48.9696419, lng: 18.2307257 },
    { lat: 48.9696856, lng: 18.2305027 },
    { lat: 48.9697146, lng: 18.2302392 },
    { lat: 48.9699795, lng: 18.2292653 },
    { lat: 48.9701023, lng: 18.2289453 },
    { lat: 48.9703354, lng: 18.2283419 },
    { lat: 48.9705606, lng: 18.227937 },
    { lat: 48.9706427, lng: 18.2276744 },
    { lat: 48.9712186, lng: 18.226991 },
    { lat: 48.9714647, lng: 18.2268944 },
    { lat: 48.9716303, lng: 18.2268489 },
    { lat: 48.9717631, lng: 18.2266048 },
    { lat: 48.9720907, lng: 18.2262974 },
    { lat: 48.9726615, lng: 18.2254816 },
    { lat: 48.9726802, lng: 18.2254542 },
    { lat: 48.9727094, lng: 18.2254122 },
    { lat: 48.9728367, lng: 18.2253283 },
    { lat: 48.9727903, lng: 18.2252091 },
    { lat: 48.9728448, lng: 18.2250166 },
    { lat: 48.9731474, lng: 18.2246449 },
    { lat: 48.973197, lng: 18.2245845 },
    { lat: 48.9730367, lng: 18.2242856 },
    { lat: 48.9733159, lng: 18.2237805 },
    { lat: 48.9735076, lng: 18.2234026 },
    { lat: 48.9736938, lng: 18.2232239 },
    { lat: 48.9741631, lng: 18.2224325 },
    { lat: 48.9746224, lng: 18.2216605 },
    { lat: 48.9748763, lng: 18.2212065 },
    { lat: 48.9749783, lng: 18.2209569 },
    { lat: 48.9752078, lng: 18.2203924 },
    { lat: 48.9753234, lng: 18.2199062 },
    { lat: 48.9754449, lng: 18.2195822 },
    { lat: 48.975525, lng: 18.2193859 },
    { lat: 48.9757799, lng: 18.218809 },
    { lat: 48.9759075, lng: 18.2184348 },
    { lat: 48.9761848, lng: 18.2178979 },
    { lat: 48.9769701, lng: 18.2164533 },
    { lat: 48.9771918, lng: 18.2160692 },
    { lat: 48.9775144, lng: 18.2155917 },
    { lat: 48.9775397, lng: 18.2154616 },
    { lat: 48.9778961, lng: 18.2148263 },
    { lat: 48.9781741, lng: 18.2143999 },
    { lat: 48.9784123, lng: 18.2140844 },
    { lat: 48.9789662, lng: 18.2133918 },
    { lat: 48.9790633, lng: 18.2132745 },
    { lat: 48.979228, lng: 18.2130762 },
    { lat: 48.9797889, lng: 18.2124654 },
    { lat: 48.9803636, lng: 18.2118401 },
    { lat: 48.9805051, lng: 18.2117092 },
    { lat: 48.98051, lng: 18.2116572 },
    { lat: 48.980525, lng: 18.2114867 },
    { lat: 48.9805399, lng: 18.2113125 },
    { lat: 48.9808917, lng: 18.2108342 },
    { lat: 48.9816752, lng: 18.209932 },
    { lat: 48.9821558, lng: 18.2093788 },
    { lat: 48.982486, lng: 18.2089562 },
    { lat: 48.9828414, lng: 18.2084016 },
    { lat: 48.9829743, lng: 18.208141 },
    { lat: 48.9831638, lng: 18.2078439 },
    { lat: 48.9830776, lng: 18.207719 },
    { lat: 48.9830842, lng: 18.2074975 },
    { lat: 48.983319, lng: 18.2073225 },
    { lat: 48.9835516, lng: 18.2066737 },
    { lat: 48.983963, lng: 18.2059162 },
    { lat: 48.9841152, lng: 18.2057169 },
    { lat: 48.9844973, lng: 18.2052153 },
    { lat: 48.984944, lng: 18.2047593 },
    { lat: 48.9850475, lng: 18.2046544 },
    { lat: 48.985131, lng: 18.2044965 },
    { lat: 48.9851451, lng: 18.2044687 },
    { lat: 48.9851994, lng: 18.2043509 },
    { lat: 48.9852114, lng: 18.2043246 },
    { lat: 48.9852449, lng: 18.2042993 },
    { lat: 48.9852703, lng: 18.2042704 },
    { lat: 48.9853724, lng: 18.2041194 },
    { lat: 48.9854098, lng: 18.2040652 },
    { lat: 48.9854139, lng: 18.2040578 },
    { lat: 48.9858165, lng: 18.2033618 },
    { lat: 48.9858174, lng: 18.203359 },
    { lat: 48.9859593, lng: 18.2031973 },
    { lat: 48.9860663, lng: 18.203075 },
    { lat: 48.9869027, lng: 18.202113 },
    { lat: 48.9870192, lng: 18.2019797 },
    { lat: 48.9871441, lng: 18.2018364 },
    { lat: 48.9871781, lng: 18.2017973 },
    { lat: 48.9872289, lng: 18.201739 },
    { lat: 48.9872627, lng: 18.2016999 },
    { lat: 48.9873072, lng: 18.2016491 },
    { lat: 48.9873252, lng: 18.2016282 },
    { lat: 48.9876227, lng: 18.2012862 },
    { lat: 48.9876534, lng: 18.2012513 },
    { lat: 48.9876693, lng: 18.201233 },
    { lat: 48.9876883, lng: 18.2012108 },
    { lat: 48.9877128, lng: 18.2011835 },
    { lat: 48.9877403, lng: 18.2011511 },
    { lat: 48.9877837, lng: 18.2011017 },
    { lat: 48.9882368, lng: 18.2005811 },
    { lat: 48.9882401, lng: 18.2005778 },
    { lat: 48.9885332, lng: 18.2002401 },
    { lat: 48.9885364, lng: 18.2002368 },
    { lat: 48.9886932, lng: 18.2000566 },
    { lat: 48.9887048, lng: 18.200043 },
    { lat: 48.9887249, lng: 18.2000194 },
    { lat: 48.9887419, lng: 18.200001 },
    { lat: 48.9887757, lng: 18.1999617 },
    { lat: 48.9892997, lng: 18.1993596 },
    { lat: 48.9893738, lng: 18.1992744 },
    { lat: 48.9893952, lng: 18.1992502 },
    { lat: 48.989412, lng: 18.19923 },
    { lat: 48.9899669, lng: 18.198593 },
    { lat: 48.9901055, lng: 18.1984336 },
    { lat: 48.9901807, lng: 18.1983475 },
    { lat: 48.9901869, lng: 18.1983393 },
    { lat: 48.9902664, lng: 18.1982487 },
    { lat: 48.9902834, lng: 18.1982294 },
    { lat: 48.99036, lng: 18.1981284 },
    { lat: 48.9909674, lng: 18.1973849 },
    { lat: 48.9920134, lng: 18.1961051 },
    { lat: 48.9921654, lng: 18.1959201 },
    { lat: 48.9922096, lng: 18.1958658 },
    { lat: 48.9922223, lng: 18.1958505 },
    { lat: 48.9925112, lng: 18.1954975 },
    { lat: 48.9925291, lng: 18.195475 },
    { lat: 48.9926051, lng: 18.1953827 },
    { lat: 48.9926156, lng: 18.1953698 },
    { lat: 48.9926451, lng: 18.1953332 },
    { lat: 48.9931302, lng: 18.1947387 },
    { lat: 48.9941664, lng: 18.1934649 },
    { lat: 48.9927709, lng: 18.1909687 },
    { lat: 48.9924891, lng: 18.1904428 },
    { lat: 48.9919724, lng: 18.1894774 },
    { lat: 48.9918792, lng: 18.1892695 },
    { lat: 48.9918314, lng: 18.1891619 },
    { lat: 48.9917859, lng: 18.1890609 },
    { lat: 48.9915068, lng: 18.188441 },
    { lat: 48.9914912, lng: 18.1884069 },
    { lat: 48.9911969, lng: 18.1875431 },
    { lat: 48.99117, lng: 18.1874673 },
    { lat: 48.9908966, lng: 18.1866745 },
    { lat: 48.990657, lng: 18.185978 },
    { lat: 48.9903887, lng: 18.1852356 },
    { lat: 48.9901865, lng: 18.1846789 },
    { lat: 48.9901636, lng: 18.1846145 },
    { lat: 48.9898818, lng: 18.1838339 },
    { lat: 48.989723, lng: 18.1835035 },
    { lat: 48.9896692, lng: 18.1833916 },
    { lat: 48.9894268, lng: 18.1828849 },
  ],
  NováDubnica: [
    { lat: 48.9360376, lng: 18.1856357 },
    { lat: 48.9369068, lng: 18.1853474 },
    { lat: 48.9374338, lng: 18.1848953 },
    { lat: 48.9378192, lng: 18.1841582 },
    { lat: 48.9382081, lng: 18.1836003 },
    { lat: 48.9382581, lng: 18.1834112 },
    { lat: 48.9382819, lng: 18.183318 },
    { lat: 48.9384358, lng: 18.1827712 },
    { lat: 48.9385651, lng: 18.1822791 },
    { lat: 48.9387561, lng: 18.1815315 },
    { lat: 48.9387573, lng: 18.1813352 },
    { lat: 48.9387599, lng: 18.1808896 },
    { lat: 48.9387611, lng: 18.1807326 },
    { lat: 48.9387617, lng: 18.1806661 },
    { lat: 48.9387617, lng: 18.1806268 },
    { lat: 48.9387623, lng: 18.1805061 },
    { lat: 48.9387652, lng: 18.1799402 },
    { lat: 48.9389243, lng: 18.1781525 },
    { lat: 48.9393565, lng: 18.1767806 },
    { lat: 48.9399936, lng: 18.1752802 },
    { lat: 48.9402193, lng: 18.1747492 },
    { lat: 48.9402581, lng: 18.1746601 },
    { lat: 48.9406747, lng: 18.1736812 },
    { lat: 48.9406952, lng: 18.173607 },
    { lat: 48.940776, lng: 18.1733056 },
    { lat: 48.9408973, lng: 18.1728607 },
    { lat: 48.9415061, lng: 18.1714602 },
    { lat: 48.942311, lng: 18.169661 },
    { lat: 48.9423836, lng: 18.1696069 },
    { lat: 48.9428116, lng: 18.168614 },
    { lat: 48.9428952, lng: 18.1684188 },
    { lat: 48.9430336, lng: 18.1680982 },
    { lat: 48.9434922, lng: 18.1668722 },
    { lat: 48.9435063, lng: 18.1668416 },
    { lat: 48.9428943, lng: 18.1659542 },
    { lat: 48.9424948, lng: 18.1653243 },
    { lat: 48.9424775, lng: 18.165297 },
    { lat: 48.9417982, lng: 18.164024 },
    { lat: 48.941709, lng: 18.1637302 },
    { lat: 48.9416762, lng: 18.1635897 },
    { lat: 48.9416607, lng: 18.1635567 },
    { lat: 48.941632, lng: 18.1634944 },
    { lat: 48.9415988, lng: 18.1634223 },
    { lat: 48.9415207, lng: 18.1632934 },
    { lat: 48.9414442, lng: 18.1631673 },
    { lat: 48.9413113, lng: 18.1629945 },
    { lat: 48.9412608, lng: 18.1629496 },
    { lat: 48.9409878, lng: 18.1627072 },
    { lat: 48.9406573, lng: 18.1621417 },
    { lat: 48.9401906, lng: 18.161464 },
    { lat: 48.9401401, lng: 18.1613902 },
    { lat: 48.9402289, lng: 18.1613253 },
    { lat: 48.9404769, lng: 18.1611437 },
    { lat: 48.9405377, lng: 18.161084 },
    { lat: 48.9406168, lng: 18.1610071 },
    { lat: 48.9414034, lng: 18.159892 },
    { lat: 48.941772, lng: 18.1592786 },
    { lat: 48.9420213, lng: 18.1587466 },
    { lat: 48.9426013, lng: 18.157572 },
    { lat: 48.9428168, lng: 18.1572202 },
    { lat: 48.9432709, lng: 18.1565058 },
    { lat: 48.9434728, lng: 18.1561317 },
    { lat: 48.9437934, lng: 18.1554266 },
    { lat: 48.9440261, lng: 18.1546505 },
    { lat: 48.944639, lng: 18.153067 },
    { lat: 48.9448872, lng: 18.1523423 },
    { lat: 48.9451238, lng: 18.151669 },
    { lat: 48.9454127, lng: 18.1509654 },
    { lat: 48.9454996, lng: 18.1506733 },
    { lat: 48.9455539, lng: 18.150362 },
    { lat: 48.9456467, lng: 18.149545 },
    { lat: 48.9456474, lng: 18.1491665 },
    { lat: 48.9456306, lng: 18.148935 },
    { lat: 48.9456284, lng: 18.1487212 },
    { lat: 48.9456426, lng: 18.1484975 },
    { lat: 48.9456088, lng: 18.1484594 },
    { lat: 48.9456709, lng: 18.1480092 },
    { lat: 48.9457213, lng: 18.1476891 },
    { lat: 48.9457583, lng: 18.1475681 },
    { lat: 48.9457665, lng: 18.1475409 },
    { lat: 48.9457735, lng: 18.1475178 },
    { lat: 48.9457791, lng: 18.1474984 },
    { lat: 48.9457905, lng: 18.1474631 },
    { lat: 48.9461603, lng: 18.1464405 },
    { lat: 48.9462451, lng: 18.1462061 },
    { lat: 48.9467052, lng: 18.1449322 },
    { lat: 48.9468536, lng: 18.1445317 },
    { lat: 48.946898, lng: 18.1443833 },
    { lat: 48.9469635, lng: 18.1442062 },
    { lat: 48.9470875, lng: 18.1438129 },
    { lat: 48.9472219, lng: 18.1434261 },
    { lat: 48.9473479, lng: 18.1431263 },
    { lat: 48.9474397, lng: 18.1428972 },
    { lat: 48.9474597, lng: 18.1428526 },
    { lat: 48.9482549, lng: 18.1410095 },
    { lat: 48.9484109, lng: 18.1406449 },
    { lat: 48.9485011, lng: 18.1405854 },
    { lat: 48.948524, lng: 18.14057 },
    { lat: 48.948363, lng: 18.140188 },
    { lat: 48.948079, lng: 18.139578 },
    { lat: 48.947729, lng: 18.138957 },
    { lat: 48.947345, lng: 18.138288 },
    { lat: 48.946942, lng: 18.137657 },
    { lat: 48.946828, lng: 18.137523 },
    { lat: 48.946697, lng: 18.137378 },
    { lat: 48.946152, lng: 18.136761 },
    { lat: 48.945739, lng: 18.136128 },
    { lat: 48.945419, lng: 18.13555 },
    { lat: 48.94503, lng: 18.134855 },
    { lat: 48.944753, lng: 18.134529 },
    { lat: 48.944206, lng: 18.133936 },
    { lat: 48.943628, lng: 18.133281 },
    { lat: 48.942786, lng: 18.132241 },
    { lat: 48.942452, lng: 18.131866 },
    { lat: 48.942301, lng: 18.131759 },
    { lat: 48.941961, lng: 18.131529 },
    { lat: 48.941829, lng: 18.131442 },
    { lat: 48.941752, lng: 18.131391 },
    { lat: 48.941636, lng: 18.131312 },
    { lat: 48.941524, lng: 18.131231 },
    { lat: 48.941462, lng: 18.131193 },
    { lat: 48.941413, lng: 18.131151 },
    { lat: 48.941147, lng: 18.130939 },
    { lat: 48.941045, lng: 18.13084 },
    { lat: 48.94089, lng: 18.130712 },
    { lat: 48.940857, lng: 18.130694 },
    { lat: 48.940825, lng: 18.130668 },
    { lat: 48.940794, lng: 18.130643 },
    { lat: 48.940762, lng: 18.130616 },
    { lat: 48.940733, lng: 18.130589 },
    { lat: 48.940702, lng: 18.13056 },
    { lat: 48.940615, lng: 18.130486 },
    { lat: 48.940385, lng: 18.130241 },
    { lat: 48.940349, lng: 18.130228 },
    { lat: 48.940307, lng: 18.1302 },
    { lat: 48.940072, lng: 18.130023 },
    { lat: 48.940019, lng: 18.129999 },
    { lat: 48.939966, lng: 18.12997 },
    { lat: 48.9399, lng: 18.129928 },
    { lat: 48.939863, lng: 18.129904 },
    { lat: 48.939839, lng: 18.129888 },
    { lat: 48.939305, lng: 18.12957 },
    { lat: 48.938831, lng: 18.129288 },
    { lat: 48.938379, lng: 18.129008 },
    { lat: 48.938301, lng: 18.12896 },
    { lat: 48.937849, lng: 18.128572 },
    { lat: 48.937633, lng: 18.128368 },
    { lat: 48.937256, lng: 18.127971 },
    { lat: 48.936962, lng: 18.127587 },
    { lat: 48.93673, lng: 18.127157 },
    { lat: 48.936509, lng: 18.12657 },
    { lat: 48.936466, lng: 18.126987 },
    { lat: 48.936474, lng: 18.127179 },
    { lat: 48.936489, lng: 18.12741 },
    { lat: 48.936511, lng: 18.12766 },
    { lat: 48.936525, lng: 18.127735 },
    { lat: 48.936557, lng: 18.127914 },
    { lat: 48.93665, lng: 18.1282 },
    { lat: 48.936734, lng: 18.128504 },
    { lat: 48.936863, lng: 18.128918 },
    { lat: 48.936881, lng: 18.12897 },
    { lat: 48.936896, lng: 18.129018 },
    { lat: 48.936859, lng: 18.129179 },
    { lat: 48.936905, lng: 18.129283 },
    { lat: 48.936565, lng: 18.129634 },
    { lat: 48.93621, lng: 18.129868 },
    { lat: 48.935494, lng: 18.130411 },
    { lat: 48.934819, lng: 18.130907 },
    { lat: 48.93477, lng: 18.130946 },
    { lat: 48.934725, lng: 18.130841 },
    { lat: 48.934547, lng: 18.130451 },
    { lat: 48.934272, lng: 18.130069 },
    { lat: 48.933823, lng: 18.130822 },
    { lat: 48.933705, lng: 18.130716 },
    { lat: 48.933154, lng: 18.130614 },
    { lat: 48.932598, lng: 18.130562 },
    { lat: 48.932741, lng: 18.13121 },
    { lat: 48.932741, lng: 18.13172 },
    { lat: 48.93273, lng: 18.131754 },
    { lat: 48.932653, lng: 18.132004 },
    { lat: 48.932561, lng: 18.132222 },
    { lat: 48.932195, lng: 18.133142 },
    { lat: 48.931912, lng: 18.133864 },
    { lat: 48.931886, lng: 18.133883 },
    { lat: 48.931706, lng: 18.134055 },
    { lat: 48.931607, lng: 18.134193 },
    { lat: 48.93154, lng: 18.134296 },
    { lat: 48.931445, lng: 18.134489 },
    { lat: 48.931032, lng: 18.13542 },
    { lat: 48.930726, lng: 18.135915 },
    { lat: 48.930664, lng: 18.135959 },
    { lat: 48.930419, lng: 18.136161 },
    { lat: 48.930305, lng: 18.13633 },
    { lat: 48.930302, lng: 18.136336 },
    { lat: 48.930273, lng: 18.136367 },
    { lat: 48.93027, lng: 18.136371 },
    { lat: 48.930183, lng: 18.136487 },
    { lat: 48.93018, lng: 18.136492 },
    { lat: 48.930091, lng: 18.136687 },
    { lat: 48.930065, lng: 18.136751 },
    { lat: 48.930045, lng: 18.136815 },
    { lat: 48.930015, lng: 18.136952 },
    { lat: 48.930015, lng: 18.136953 },
    { lat: 48.929824, lng: 18.137553 },
    { lat: 48.929617, lng: 18.138135 },
    { lat: 48.929415, lng: 18.138737 },
    { lat: 48.929411, lng: 18.13875 },
    { lat: 48.929106, lng: 18.138914 },
    { lat: 48.928842, lng: 18.139075 },
    { lat: 48.928714, lng: 18.139147 },
    { lat: 48.928441, lng: 18.139464 },
    { lat: 48.928391, lng: 18.139511 },
    { lat: 48.928351, lng: 18.139553 },
    { lat: 48.928298, lng: 18.139607 },
    { lat: 48.928251, lng: 18.139668 },
    { lat: 48.928209, lng: 18.139729 },
    { lat: 48.928182, lng: 18.139776 },
    { lat: 48.928153, lng: 18.139818 },
    { lat: 48.928122, lng: 18.13985 },
    { lat: 48.928088, lng: 18.139885 },
    { lat: 48.928051, lng: 18.139923 },
    { lat: 48.928022, lng: 18.139963 },
    { lat: 48.928, lng: 18.139984 },
    { lat: 48.927934, lng: 18.140055 },
    { lat: 48.927903, lng: 18.140109 },
    { lat: 48.927841, lng: 18.140186 },
    { lat: 48.927779, lng: 18.140267 },
    { lat: 48.927728, lng: 18.140332 },
    { lat: 48.927688, lng: 18.140389 },
    { lat: 48.927683, lng: 18.140397 },
    { lat: 48.927622, lng: 18.140454 },
    { lat: 48.927693, lng: 18.140478 },
    { lat: 48.928457, lng: 18.141284 },
    { lat: 48.928395, lng: 18.141556 },
    { lat: 48.928375, lng: 18.141672 },
    { lat: 48.928331, lng: 18.141839 },
    { lat: 48.928256, lng: 18.142156 },
    { lat: 48.928218, lng: 18.142302 },
    { lat: 48.928184, lng: 18.142449 },
    { lat: 48.928155, lng: 18.142558 },
    { lat: 48.928136, lng: 18.142675 },
    { lat: 48.9281, lng: 18.142808 },
    { lat: 48.928101, lng: 18.14283 },
    { lat: 48.928369, lng: 18.143124 },
    { lat: 48.928372, lng: 18.143127 },
    { lat: 48.928245, lng: 18.143135 },
    { lat: 48.928227, lng: 18.1434 },
    { lat: 48.928223, lng: 18.143407 },
    { lat: 48.927406, lng: 18.144574 },
    { lat: 48.927371, lng: 18.144602 },
    { lat: 48.927447, lng: 18.144662 },
    { lat: 48.927543, lng: 18.144733 },
    { lat: 48.927595, lng: 18.144776 },
    { lat: 48.927877, lng: 18.144989 },
    { lat: 48.92717, lng: 18.14615 },
    { lat: 48.927158, lng: 18.146169 },
    { lat: 48.927148, lng: 18.146185 },
    { lat: 48.927881, lng: 18.146719 },
    { lat: 48.927921, lng: 18.146749 },
    { lat: 48.927919, lng: 18.146793 },
    { lat: 48.927912, lng: 18.146882 },
    { lat: 48.9279, lng: 18.147066 },
    { lat: 48.927877, lng: 18.147351 },
    { lat: 48.927855, lng: 18.147618 },
    { lat: 48.927806, lng: 18.148229 },
    { lat: 48.927884, lng: 18.148493 },
    { lat: 48.927895, lng: 18.148529 },
    { lat: 48.927904, lng: 18.14856 },
    { lat: 48.928232, lng: 18.149767 },
    { lat: 48.928342, lng: 18.150169 },
    { lat: 48.928311, lng: 18.150195 },
    { lat: 48.92788, lng: 18.150551 },
    { lat: 48.927952, lng: 18.151663 },
    { lat: 48.927956, lng: 18.151733 },
    { lat: 48.927777, lng: 18.15246 },
    { lat: 48.927878, lng: 18.152488 },
    { lat: 48.927935, lng: 18.152563 },
    { lat: 48.927969, lng: 18.152689 },
    { lat: 48.928466, lng: 18.152756 },
    { lat: 48.928909, lng: 18.152815 },
    { lat: 48.928998, lng: 18.152836 },
    { lat: 48.929003, lng: 18.152865 },
    { lat: 48.929018, lng: 18.152952 },
    { lat: 48.929035, lng: 18.153048 },
    { lat: 48.929532, lng: 18.153338 },
    { lat: 48.929598, lng: 18.153504 },
    { lat: 48.929662, lng: 18.153567 },
    { lat: 48.929711, lng: 18.153619 },
    { lat: 48.93024, lng: 18.153946 },
    { lat: 48.930293, lng: 18.154059 },
    { lat: 48.930193, lng: 18.154407 },
    { lat: 48.930084, lng: 18.154797 },
    { lat: 48.929782, lng: 18.15495 },
    { lat: 48.929153, lng: 18.155342 },
    { lat: 48.929379, lng: 18.155639 },
    { lat: 48.929401, lng: 18.155598 },
    { lat: 48.929541, lng: 18.155711 },
    { lat: 48.929452, lng: 18.155899 },
    { lat: 48.929355, lng: 18.155962 },
    { lat: 48.929308, lng: 18.156084 },
    { lat: 48.929156, lng: 18.156151 },
    { lat: 48.929013, lng: 18.156338 },
    { lat: 48.928852, lng: 18.156475 },
    { lat: 48.928811, lng: 18.156587 },
    { lat: 48.928524, lng: 18.156795 },
    { lat: 48.928386, lng: 18.156788 },
    { lat: 48.928228, lng: 18.156897 },
    { lat: 48.928125, lng: 18.15692 },
    { lat: 48.928006, lng: 18.157097 },
    { lat: 48.92791, lng: 18.157142 },
    { lat: 48.927652, lng: 18.157486 },
    { lat: 48.927477, lng: 18.157776 },
    { lat: 48.927038, lng: 18.158162 },
    { lat: 48.926952, lng: 18.158496 },
    { lat: 48.926833, lng: 18.158592 },
    { lat: 48.926338, lng: 18.159133 },
    { lat: 48.926153, lng: 18.159412 },
    { lat: 48.926102, lng: 18.159488 },
    { lat: 48.926019, lng: 18.159613 },
    { lat: 48.926013, lng: 18.159624 },
    { lat: 48.925975, lng: 18.159689 },
    { lat: 48.925958, lng: 18.159719 },
    { lat: 48.925926, lng: 18.159776 },
    { lat: 48.925386, lng: 18.160709 },
    { lat: 48.924819, lng: 18.162 },
    { lat: 48.924372, lng: 18.163327 },
    { lat: 48.924068, lng: 18.163815 },
    { lat: 48.923723, lng: 18.164661 },
    { lat: 48.923298, lng: 18.16545 },
    { lat: 48.922735, lng: 18.165809 },
    { lat: 48.921704, lng: 18.16616 },
    { lat: 48.921231, lng: 18.16631 },
    { lat: 48.920846, lng: 18.166524 },
    { lat: 48.920603, lng: 18.166728 },
    { lat: 48.919916, lng: 18.167314 },
    { lat: 48.919435, lng: 18.167755 },
    { lat: 48.919013, lng: 18.168193 },
    { lat: 48.91862, lng: 18.169096 },
    { lat: 48.918575, lng: 18.169052 },
    { lat: 48.918565, lng: 18.169028 },
    { lat: 48.918217, lng: 18.169137 },
    { lat: 48.917953, lng: 18.169304 },
    { lat: 48.917884, lng: 18.169377 },
    { lat: 48.917789, lng: 18.169572 },
    { lat: 48.917733, lng: 18.169764 },
    { lat: 48.917694, lng: 18.169879 },
    { lat: 48.917599, lng: 18.170005 },
    { lat: 48.917455, lng: 18.170202 },
    { lat: 48.917291, lng: 18.170432 },
    { lat: 48.917131, lng: 18.170709 },
    { lat: 48.916635, lng: 18.171365 },
    { lat: 48.916412, lng: 18.171946 },
    { lat: 48.916012, lng: 18.172748 },
    { lat: 48.915771, lng: 18.173181 },
    { lat: 48.915742, lng: 18.174194 },
    { lat: 48.915687, lng: 18.174718 },
    { lat: 48.915618, lng: 18.175176 },
    { lat: 48.91536, lng: 18.175767 },
    { lat: 48.914808, lng: 18.176368 },
    { lat: 48.914751, lng: 18.176518 },
    { lat: 48.914724, lng: 18.176745 },
    { lat: 48.914893, lng: 18.177615 },
    { lat: 48.914948, lng: 18.178049 },
    { lat: 48.914893, lng: 18.178321 },
    { lat: 48.914658, lng: 18.17879 },
    { lat: 48.91461, lng: 18.178929 },
    { lat: 48.914572, lng: 18.179051 },
    { lat: 48.914568, lng: 18.179257 },
    { lat: 48.914552, lng: 18.179261 },
    { lat: 48.914342, lng: 18.179509 },
    { lat: 48.91417, lng: 18.179639 },
    { lat: 48.913595, lng: 18.180216 },
    { lat: 48.91339, lng: 18.180454 },
    { lat: 48.913075, lng: 18.180736 },
    { lat: 48.912977, lng: 18.181065 },
    { lat: 48.912812, lng: 18.18165 },
    { lat: 48.912567, lng: 18.182257 },
    { lat: 48.912313, lng: 18.182856 },
    { lat: 48.912209, lng: 18.183024 },
    { lat: 48.912082, lng: 18.183255 },
    { lat: 48.911939, lng: 18.183402 },
    { lat: 48.9117, lng: 18.183838 },
    { lat: 48.911713, lng: 18.183855 },
    { lat: 48.91222, lng: 18.184211 },
    { lat: 48.912741, lng: 18.18498 },
    { lat: 48.91294, lng: 18.185556 },
    { lat: 48.91337, lng: 18.186348 },
    { lat: 48.913472, lng: 18.186633 },
    { lat: 48.913582, lng: 18.186984 },
    { lat: 48.914073, lng: 18.188045 },
    { lat: 48.914181, lng: 18.188278 },
    { lat: 48.914263, lng: 18.188451 },
    { lat: 48.91429, lng: 18.18883 },
    { lat: 48.914288, lng: 18.18916 },
    { lat: 48.914178, lng: 18.189576 },
    { lat: 48.91409, lng: 18.189721 },
    { lat: 48.914053, lng: 18.189824 },
    { lat: 48.913782, lng: 18.19038 },
    { lat: 48.913729, lng: 18.1905 },
    { lat: 48.913811, lng: 18.190784 },
    { lat: 48.913826, lng: 18.191398 },
    { lat: 48.914194, lng: 18.191525 },
    { lat: 48.914235, lng: 18.191753 },
    { lat: 48.914672, lng: 18.191937 },
    { lat: 48.914727, lng: 18.192154 },
    { lat: 48.91489, lng: 18.192601 },
    { lat: 48.914967, lng: 18.192707 },
    { lat: 48.915072, lng: 18.192821 },
    { lat: 48.915457, lng: 18.193243 },
    { lat: 48.915747, lng: 18.193744 },
    { lat: 48.91565, lng: 18.194474 },
    { lat: 48.915396, lng: 18.195596 },
    { lat: 48.915344, lng: 18.195842 },
    { lat: 48.915294, lng: 18.196205 },
    { lat: 48.915828, lng: 18.197064 },
    { lat: 48.916129, lng: 18.197317 },
    { lat: 48.916684, lng: 18.197895 },
    { lat: 48.916965, lng: 18.198236 },
    { lat: 48.917279, lng: 18.19846 },
    { lat: 48.917536, lng: 18.198806 },
    { lat: 48.917759, lng: 18.199149 },
    { lat: 48.918096, lng: 18.19971 },
    { lat: 48.91827, lng: 18.199974 },
    { lat: 48.918951, lng: 18.201179 },
    { lat: 48.919196, lng: 18.20149 },
    { lat: 48.919541, lng: 18.201866 },
    { lat: 48.919809, lng: 18.202309 },
    { lat: 48.919953, lng: 18.202548 },
    { lat: 48.920117, lng: 18.202874 },
    { lat: 48.920636, lng: 18.204304 },
    { lat: 48.920672, lng: 18.204543 },
    { lat: 48.920776, lng: 18.205178 },
    { lat: 48.9208602, lng: 18.2058434 },
    { lat: 48.921046, lng: 18.207312 },
    { lat: 48.920518, lng: 18.207944 },
    { lat: 48.919939, lng: 18.208167 },
    { lat: 48.919229, lng: 18.208559 },
    { lat: 48.919066, lng: 18.208905 },
    { lat: 48.918869, lng: 18.210397 },
    { lat: 48.918603, lng: 18.21088 },
    { lat: 48.918026, lng: 18.212076 },
    { lat: 48.917965, lng: 18.212782 },
    { lat: 48.917448, lng: 18.213506 },
    { lat: 48.917281, lng: 18.214015 },
    { lat: 48.918221, lng: 18.217197 },
    { lat: 48.91844, lng: 18.217837 },
    { lat: 48.918844, lng: 18.218872 },
    { lat: 48.918871, lng: 18.218944 },
    { lat: 48.919043, lng: 18.219392 },
    { lat: 48.919333, lng: 18.220248 },
    { lat: 48.9193805, lng: 18.2204175 },
    { lat: 48.919508, lng: 18.220903 },
    { lat: 48.9196449, lng: 18.2195377 },
    { lat: 48.9198524, lng: 18.2182515 },
    { lat: 48.9203316, lng: 18.2175735 },
    { lat: 48.9209728, lng: 18.21588 },
    { lat: 48.9219036, lng: 18.2151485 },
    { lat: 48.9223931, lng: 18.2149285 },
    { lat: 48.9225955, lng: 18.2145395 },
    { lat: 48.9226081, lng: 18.2132596 },
    { lat: 48.9224326, lng: 18.2112963 },
    { lat: 48.9224737, lng: 18.2106533 },
    { lat: 48.9219144, lng: 18.2092404 },
    { lat: 48.9217054, lng: 18.2083677 },
    { lat: 48.9218935, lng: 18.2076503 },
    { lat: 48.9219177, lng: 18.2076008 },
    { lat: 48.92194, lng: 18.2075557 },
    { lat: 48.9222853, lng: 18.2068651 },
    { lat: 48.9228419, lng: 18.2063786 },
    { lat: 48.9232977, lng: 18.2059646 },
    { lat: 48.923545, lng: 18.2053755 },
    { lat: 48.923834, lng: 18.204688 },
    { lat: 48.9241764, lng: 18.2038287 },
    { lat: 48.9244813, lng: 18.2031832 },
    { lat: 48.9246621, lng: 18.2027539 },
    { lat: 48.9249226, lng: 18.2022197 },
    { lat: 48.9254094, lng: 18.2011253 },
    { lat: 48.9257484, lng: 18.2005815 },
    { lat: 48.9261823, lng: 18.199803 },
    { lat: 48.92637, lng: 18.1994955 },
    { lat: 48.9266602, lng: 18.1987898 },
    { lat: 48.9269017, lng: 18.19814 },
    { lat: 48.9271257, lng: 18.1977964 },
    { lat: 48.927246, lng: 18.1976128 },
    { lat: 48.9278195, lng: 18.1975368 },
    { lat: 48.9278689, lng: 18.1975413 },
    { lat: 48.9286736, lng: 18.1976153 },
    { lat: 48.9288717, lng: 18.1975669 },
    { lat: 48.9291002, lng: 18.1975368 },
    { lat: 48.9292771, lng: 18.1975915 },
    { lat: 48.9297065, lng: 18.1974261 },
    { lat: 48.9300874, lng: 18.1970816 },
    { lat: 48.930445, lng: 18.1969624 },
    { lat: 48.9305709, lng: 18.1968161 },
    { lat: 48.9308484, lng: 18.1966289 },
    { lat: 48.9311077, lng: 18.1963964 },
    { lat: 48.931288, lng: 18.1964533 },
    { lat: 48.9314368, lng: 18.1965679 },
    { lat: 48.931693, lng: 18.1967656 },
    { lat: 48.9320107, lng: 18.1968367 },
    { lat: 48.9320748, lng: 18.1968514 },
    { lat: 48.9324336, lng: 18.1971871 },
    { lat: 48.93249, lng: 18.197245 },
    { lat: 48.9327313, lng: 18.196396 },
    { lat: 48.9328325, lng: 18.1951492 },
    { lat: 48.9327504, lng: 18.1948574 },
    { lat: 48.9327108, lng: 18.1944044 },
    { lat: 48.9326198, lng: 18.1937015 },
    { lat: 48.9325779, lng: 18.1929701 },
    { lat: 48.9325955, lng: 18.1921372 },
    { lat: 48.9326723, lng: 18.1905844 },
    { lat: 48.9327975, lng: 18.1898743 },
    { lat: 48.9330385, lng: 18.1892575 },
    { lat: 48.9337486, lng: 18.1882007 },
    { lat: 48.9341131, lng: 18.1876508 },
    { lat: 48.9344304, lng: 18.1873986 },
    { lat: 48.9353285, lng: 18.1862753 },
    { lat: 48.9360376, lng: 18.1856357 },
  ],
  KošeckéPodhradie: [
    { lat: 48.9777581, lng: 18.2836216 },
    { lat: 48.9771859, lng: 18.2828195 },
    { lat: 48.9770673, lng: 18.2826577 },
    { lat: 48.9769525, lng: 18.2824828 },
    { lat: 48.9769181, lng: 18.2824333 },
    { lat: 48.9767488, lng: 18.2821932 },
    { lat: 48.976206, lng: 18.2814524 },
    { lat: 48.9757296, lng: 18.2808296 },
    { lat: 48.9753827, lng: 18.2803591 },
    { lat: 48.9742981, lng: 18.2785382 },
    { lat: 48.9741211, lng: 18.2782408 },
    { lat: 48.9737532, lng: 18.2787976 },
    { lat: 48.9735374, lng: 18.2788735 },
    { lat: 48.9732792, lng: 18.2788771 },
    { lat: 48.972998, lng: 18.2792309 },
    { lat: 48.9727651, lng: 18.2796987 },
    { lat: 48.972247, lng: 18.2801947 },
    { lat: 48.9718561, lng: 18.2801473 },
    { lat: 48.9717034, lng: 18.2800799 },
    { lat: 48.9714315, lng: 18.280319 },
    { lat: 48.9711223, lng: 18.2804793 },
    { lat: 48.9710469, lng: 18.2805214 },
    { lat: 48.9704159, lng: 18.2806752 },
    { lat: 48.9701152, lng: 18.2806842 },
    { lat: 48.9697916, lng: 18.280628 },
    { lat: 48.9691223, lng: 18.2810048 },
    { lat: 48.9687982, lng: 18.2813393 },
    { lat: 48.9685644, lng: 18.2815745 },
    { lat: 48.9683306, lng: 18.2817881 },
    { lat: 48.967889, lng: 18.2822608 },
    { lat: 48.9675327, lng: 18.2827206 },
    { lat: 48.9672564, lng: 18.283082 },
    { lat: 48.9669621, lng: 18.283706 },
    { lat: 48.9671707, lng: 18.2843812 },
    { lat: 48.9675318, lng: 18.2847008 },
    { lat: 48.9673616, lng: 18.2852243 },
    { lat: 48.9670672, lng: 18.2856048 },
    { lat: 48.967024, lng: 18.2856604 },
    { lat: 48.9666864, lng: 18.2860961 },
    { lat: 48.9657019, lng: 18.2880198 },
    { lat: 48.9656135, lng: 18.2878751 },
    { lat: 48.9652033, lng: 18.2890431 },
    { lat: 48.9650127, lng: 18.2888238 },
    { lat: 48.9647425, lng: 18.2887541 },
    { lat: 48.964486, lng: 18.2887455 },
    { lat: 48.9643008, lng: 18.2886852 },
    { lat: 48.9642151, lng: 18.2885657 },
    { lat: 48.9641521, lng: 18.2883682 },
    { lat: 48.9641205, lng: 18.2881252 },
    { lat: 48.9639843, lng: 18.287636 },
    { lat: 48.9639283, lng: 18.2876817 },
    { lat: 48.9634622, lng: 18.2881194 },
    { lat: 48.9633068, lng: 18.2883034 },
    { lat: 48.9632553, lng: 18.2883112 },
    { lat: 48.9630911, lng: 18.2884194 },
    { lat: 48.9628213, lng: 18.2895562 },
    { lat: 48.9626871, lng: 18.2903747 },
    { lat: 48.9622855, lng: 18.2904988 },
    { lat: 48.9617414, lng: 18.2911542 },
    { lat: 48.961892, lng: 18.2922214 },
    { lat: 48.9618764, lng: 18.2926245 },
    { lat: 48.9612882, lng: 18.2940371 },
    { lat: 48.9607007, lng: 18.29474 },
    { lat: 48.9605668, lng: 18.29497 },
    { lat: 48.96076, lng: 18.2954533 },
    { lat: 48.9607223, lng: 18.2959454 },
    { lat: 48.9608362, lng: 18.2964849 },
    { lat: 48.9607864, lng: 18.2972415 },
    { lat: 48.9606845, lng: 18.297496 },
    { lat: 48.9607053, lng: 18.2975633 },
    { lat: 48.9608642, lng: 18.2980801 },
    { lat: 48.960853, lng: 18.298502 },
    { lat: 48.9611635, lng: 18.2996903 },
    { lat: 48.9611705, lng: 18.2997303 },
    { lat: 48.9608878, lng: 18.2998407 },
    { lat: 48.9601505, lng: 18.2999922 },
    { lat: 48.9600143, lng: 18.3000971 },
    { lat: 48.9598105, lng: 18.3006043 },
    { lat: 48.95966, lng: 18.3008752 },
    { lat: 48.9595496, lng: 18.3013613 },
    { lat: 48.958963, lng: 18.302184 },
    { lat: 48.9587936, lng: 18.3023179 },
    { lat: 48.9583437, lng: 18.302663 },
    { lat: 48.9578485, lng: 18.3030688 },
    { lat: 48.9576075, lng: 18.3032555 },
    { lat: 48.9569902, lng: 18.3039723 },
    { lat: 48.9568526, lng: 18.3040738 },
    { lat: 48.9565953, lng: 18.3043531 },
    { lat: 48.9564928, lng: 18.3046371 },
    { lat: 48.9562563, lng: 18.3051432 },
    { lat: 48.9559321, lng: 18.3055357 },
    { lat: 48.955466, lng: 18.3060166 },
    { lat: 48.9552601, lng: 18.3061058 },
    { lat: 48.9550102, lng: 18.3063357 },
    { lat: 48.9554228, lng: 18.3071721 },
    { lat: 48.9551667, lng: 18.3076113 },
    { lat: 48.9551162, lng: 18.3079582 },
    { lat: 48.9549384, lng: 18.3080244 },
    { lat: 48.955194, lng: 18.3089795 },
    { lat: 48.9549091, lng: 18.3093906 },
    { lat: 48.9546959, lng: 18.3113945 },
    { lat: 48.954532, lng: 18.3123301 },
    { lat: 48.9545166, lng: 18.3127417 },
    { lat: 48.9545893, lng: 18.312929 },
    { lat: 48.9545635, lng: 18.3132339 },
    { lat: 48.9545394, lng: 18.3135253 },
    { lat: 48.9545085, lng: 18.3139005 },
    { lat: 48.9542002, lng: 18.3149959 },
    { lat: 48.9534908, lng: 18.3150018 },
    { lat: 48.9527711, lng: 18.3152828 },
    { lat: 48.9525165, lng: 18.3152929 },
    { lat: 48.9514307, lng: 18.3154649 },
    { lat: 48.9503863, lng: 18.3155899 },
    { lat: 48.9503701, lng: 18.315662 },
    { lat: 48.9502104, lng: 18.316434 },
    { lat: 48.9504789, lng: 18.3170162 },
    { lat: 48.9501636, lng: 18.3184897 },
    { lat: 48.9500688, lng: 18.3190533 },
    { lat: 48.949904, lng: 18.3197725 },
    { lat: 48.9500089, lng: 18.3203566 },
    { lat: 48.9499043, lng: 18.3214262 },
    { lat: 48.9497669, lng: 18.3225528 },
    { lat: 48.9497482, lng: 18.3228851 },
    { lat: 48.9496478, lng: 18.323388 },
    { lat: 48.9491847, lng: 18.3237973 },
    { lat: 48.9491419, lng: 18.3238182 },
    { lat: 48.9483051, lng: 18.3242274 },
    { lat: 48.9470579, lng: 18.3244779 },
    { lat: 48.9446367, lng: 18.3250404 },
    { lat: 48.9442647, lng: 18.3251855 },
    { lat: 48.9437767, lng: 18.324954 },
    { lat: 48.9417939, lng: 18.3264112 },
    { lat: 48.9392006, lng: 18.3263384 },
    { lat: 48.937494, lng: 18.330886 },
    { lat: 48.9364785, lng: 18.3324308 },
    { lat: 48.9360928, lng: 18.3329393 },
    { lat: 48.9348623, lng: 18.3339499 },
    { lat: 48.9344414, lng: 18.3341095 },
    { lat: 48.9338153, lng: 18.3342298 },
    { lat: 48.9329158, lng: 18.334424 },
    { lat: 48.9326312, lng: 18.3347037 },
    { lat: 48.9320567, lng: 18.3349175 },
    { lat: 48.931725, lng: 18.335462 },
    { lat: 48.931592, lng: 18.335663 },
    { lat: 48.931343, lng: 18.335997 },
    { lat: 48.931254, lng: 18.336138 },
    { lat: 48.93113, lng: 18.336473 },
    { lat: 48.931099, lng: 18.336613 },
    { lat: 48.930717, lng: 18.338052 },
    { lat: 48.930729, lng: 18.338303 },
    { lat: 48.930739, lng: 18.33847 },
    { lat: 48.930665, lng: 18.339415 },
    { lat: 48.930567, lng: 18.340522 },
    { lat: 48.930389, lng: 18.341156 },
    { lat: 48.930376, lng: 18.341378 },
    { lat: 48.930263, lng: 18.341702 },
    { lat: 48.930271, lng: 18.341887 },
    { lat: 48.93031, lng: 18.341945 },
    { lat: 48.930191, lng: 18.343092 },
    { lat: 48.929914, lng: 18.343572 },
    { lat: 48.929716, lng: 18.344179 },
    { lat: 48.929577, lng: 18.344881 },
    { lat: 48.929306, lng: 18.345927 },
    { lat: 48.928914, lng: 18.346734 },
    { lat: 48.928555, lng: 18.347285 },
    { lat: 48.928328, lng: 18.347432 },
    { lat: 48.927908, lng: 18.348027 },
    { lat: 48.927798, lng: 18.348438 },
    { lat: 48.927517, lng: 18.348712 },
    { lat: 48.927382, lng: 18.349093 },
    { lat: 48.927139, lng: 18.349559 },
    { lat: 48.926983, lng: 18.349925 },
    { lat: 48.926729, lng: 18.350603 },
    { lat: 48.92656, lng: 18.351073 },
    { lat: 48.926283, lng: 18.351758 },
    { lat: 48.926146, lng: 18.352112 },
    { lat: 48.926057, lng: 18.352426 },
    { lat: 48.926158, lng: 18.353822 },
    { lat: 48.926119, lng: 18.354355 },
    { lat: 48.925921, lng: 18.354489 },
    { lat: 48.925822, lng: 18.354636 },
    { lat: 48.925571, lng: 18.355693 },
    { lat: 48.925349, lng: 18.355958 },
    { lat: 48.925559, lng: 18.356275 },
    { lat: 48.925602, lng: 18.356296 },
    { lat: 48.925776, lng: 18.356553 },
    { lat: 48.925958, lng: 18.356923 },
    { lat: 48.926377, lng: 18.3576 },
    { lat: 48.926941, lng: 18.358977 },
    { lat: 48.927253, lng: 18.359604 },
    { lat: 48.9276592, lng: 18.3603659 },
    { lat: 48.927817, lng: 18.360662 },
    { lat: 48.92856, lng: 18.361679 },
    { lat: 48.928999, lng: 18.362414 },
    { lat: 48.929143, lng: 18.362761 },
    { lat: 48.929437, lng: 18.363742 },
    { lat: 48.929608, lng: 18.363647 },
    { lat: 48.929765, lng: 18.364082 },
    { lat: 48.929859, lng: 18.364315 },
    { lat: 48.929967, lng: 18.364481 },
    { lat: 48.930096, lng: 18.364702 },
    { lat: 48.930348, lng: 18.365164 },
    { lat: 48.930722, lng: 18.365528 },
    { lat: 48.930971, lng: 18.36572 },
    { lat: 48.931333, lng: 18.365779 },
    { lat: 48.93182, lng: 18.366463 },
    { lat: 48.932024, lng: 18.366965 },
    { lat: 48.932274, lng: 18.367455 },
    { lat: 48.932293, lng: 18.367469 },
    { lat: 48.932681, lng: 18.367752 },
    { lat: 48.932917, lng: 18.367666 },
    { lat: 48.933019, lng: 18.367617 },
    { lat: 48.933145, lng: 18.367591 },
    { lat: 48.933425, lng: 18.367707 },
    { lat: 48.9338, lng: 18.367791 },
    { lat: 48.934019, lng: 18.367914 },
    { lat: 48.934374, lng: 18.368075 },
    { lat: 48.934511, lng: 18.368199 },
    { lat: 48.934642, lng: 18.368355 },
    { lat: 48.93492, lng: 18.368612 },
    { lat: 48.935831, lng: 18.368853 },
    { lat: 48.936156, lng: 18.368979 },
    { lat: 48.936562, lng: 18.368974 },
    { lat: 48.936716, lng: 18.369066 },
    { lat: 48.93695, lng: 18.369504 },
    { lat: 48.937241, lng: 18.369718 },
    { lat: 48.937361, lng: 18.369887 },
    { lat: 48.937554, lng: 18.370037 },
    { lat: 48.937758, lng: 18.370308 },
    { lat: 48.938242, lng: 18.371565 },
    { lat: 48.938374, lng: 18.371817 },
    { lat: 48.93862, lng: 18.372373 },
    { lat: 48.9395427, lng: 18.3720253 },
    { lat: 48.9411264, lng: 18.3727498 },
    { lat: 48.9425698, lng: 18.3719686 },
    { lat: 48.943492, lng: 18.3708036 },
    { lat: 48.9439777, lng: 18.3702283 },
    { lat: 48.945235, lng: 18.370117 },
    { lat: 48.9460114, lng: 18.3698973 },
    { lat: 48.9473808, lng: 18.3678104 },
    { lat: 48.9486247, lng: 18.3669537 },
    { lat: 48.9505435, lng: 18.3653544 },
    { lat: 48.9514336, lng: 18.3653343 },
    { lat: 48.9529595, lng: 18.3643533 },
    { lat: 48.9539966, lng: 18.363372 },
    { lat: 48.954867, lng: 18.3634007 },
    { lat: 48.9561934, lng: 18.3639798 },
    { lat: 48.9570398, lng: 18.3652178 },
    { lat: 48.9580299, lng: 18.3667256 },
    { lat: 48.9589329, lng: 18.3682562 },
    { lat: 48.9592296, lng: 18.3682945 },
    { lat: 48.9593273, lng: 18.3683071 },
    { lat: 48.9593665, lng: 18.3683415 },
    { lat: 48.959385, lng: 18.3682864 },
    { lat: 48.9595383, lng: 18.3680046 },
    { lat: 48.9598142, lng: 18.3677512 },
    { lat: 48.9601574, lng: 18.367534 },
    { lat: 48.9604768, lng: 18.367233 },
    { lat: 48.9614209, lng: 18.3661379 },
    { lat: 48.9617717, lng: 18.3657728 },
    { lat: 48.9626993, lng: 18.3650474 },
    { lat: 48.9635143, lng: 18.3645925 },
    { lat: 48.9636682, lng: 18.3644772 },
    { lat: 48.9639768, lng: 18.3639836 },
    { lat: 48.964277, lng: 18.3634628 },
    { lat: 48.9648028, lng: 18.3626537 },
    { lat: 48.9650609, lng: 18.3621864 },
    { lat: 48.9654492, lng: 18.361885 },
    { lat: 48.9659767, lng: 18.3614922 },
    { lat: 48.9660199, lng: 18.3614783 },
    { lat: 48.9663794, lng: 18.3608669 },
    { lat: 48.9664715, lng: 18.3606579 },
    { lat: 48.9666718, lng: 18.3602013 },
    { lat: 48.9667447, lng: 18.3600335 },
    { lat: 48.9670664, lng: 18.3606053 },
    { lat: 48.9668527, lng: 18.3610207 },
    { lat: 48.9666656, lng: 18.3618526 },
    { lat: 48.9667723, lng: 18.3625696 },
    { lat: 48.9667755, lng: 18.3631735 },
    { lat: 48.967014, lng: 18.364253 },
    { lat: 48.9671317, lng: 18.3645412 },
    { lat: 48.9672934, lng: 18.365338 },
    { lat: 48.9677112, lng: 18.3669244 },
    { lat: 48.9677532, lng: 18.3669054 },
    { lat: 48.9681505, lng: 18.366725 },
    { lat: 48.9681821, lng: 18.3669098 },
    { lat: 48.9683079, lng: 18.3676491 },
    { lat: 48.9684121, lng: 18.3676346 },
    { lat: 48.9693706, lng: 18.3693888 },
    { lat: 48.9697676, lng: 18.3703784 },
    { lat: 48.9701864, lng: 18.3714747 },
    { lat: 48.9704774, lng: 18.3721611 },
    { lat: 48.9705043, lng: 18.3722618 },
    { lat: 48.9705102, lng: 18.3722837 },
    { lat: 48.9706265, lng: 18.3727115 },
    { lat: 48.9706415, lng: 18.372841 },
    { lat: 48.9706645, lng: 18.3730506 },
    { lat: 48.9707144, lng: 18.3734995 },
    { lat: 48.9707503, lng: 18.3738224 },
    { lat: 48.9707844, lng: 18.3741293 },
    { lat: 48.9708577, lng: 18.3744132 },
    { lat: 48.9709965, lng: 18.3745742 },
    { lat: 48.9713421, lng: 18.3749654 },
    { lat: 48.971549, lng: 18.3756448 },
    { lat: 48.9717866, lng: 18.3767105 },
    { lat: 48.9720216, lng: 18.3781336 },
    { lat: 48.9720448, lng: 18.3781252 },
    { lat: 48.9720958, lng: 18.3781071 },
    { lat: 48.9721521, lng: 18.3780877 },
    { lat: 48.9734174, lng: 18.3736404 },
    { lat: 48.9746861, lng: 18.3720128 },
    { lat: 48.979414, lng: 18.3687917 },
    { lat: 48.9797099, lng: 18.3682519 },
    { lat: 48.9801513, lng: 18.3682993 },
    { lat: 48.9801209, lng: 18.3700993 },
    { lat: 48.9805057, lng: 18.3721257 },
    { lat: 48.9804507, lng: 18.3727006 },
    { lat: 48.9804907, lng: 18.3730108 },
    { lat: 48.980464, lng: 18.373267 },
    { lat: 48.980544, lng: 18.373248 },
    { lat: 48.98058, lng: 18.373071 },
    { lat: 48.981093, lng: 18.372772 },
    { lat: 48.981488, lng: 18.372894 },
    { lat: 48.9815466, lng: 18.3728837 },
    { lat: 48.981947, lng: 18.372813 },
    { lat: 48.984756, lng: 18.375872 },
    { lat: 48.9848, lng: 18.37592 },
    { lat: 48.98683, lng: 18.378131 },
    { lat: 48.990315, lng: 18.37536 },
    { lat: 48.990432, lng: 18.375267 },
    { lat: 48.991191, lng: 18.374664 },
    { lat: 48.993416, lng: 18.372896 },
    { lat: 48.99476, lng: 18.37179 },
    { lat: 48.996241, lng: 18.370572 },
    { lat: 48.997752, lng: 18.36933 },
    { lat: 48.999618, lng: 18.368219 },
    { lat: 49.000102, lng: 18.367901 },
    { lat: 49.000154, lng: 18.367826 },
    { lat: 49.000867, lng: 18.366987 },
    { lat: 49.001602, lng: 18.366294 },
    { lat: 49.003504, lng: 18.364745 },
    { lat: 49.004063, lng: 18.363781 },
    { lat: 49.004521, lng: 18.363286 },
    { lat: 49.004559, lng: 18.363175 },
    { lat: 49.004577, lng: 18.363068 },
    { lat: 49.00461, lng: 18.362968 },
    { lat: 49.004684, lng: 18.362821 },
    { lat: 49.004699, lng: 18.362764 },
    { lat: 49.005316, lng: 18.363246 },
    { lat: 49.005676, lng: 18.363768 },
    { lat: 49.006009, lng: 18.364311 },
    { lat: 49.006115, lng: 18.364538 },
    { lat: 49.006232, lng: 18.364725 },
    { lat: 49.006713, lng: 18.363843 },
    { lat: 49.00679, lng: 18.363648 },
    { lat: 49.00698, lng: 18.363371 },
    { lat: 49.006998, lng: 18.363321 },
    { lat: 49.007126, lng: 18.362844 },
    { lat: 49.007155, lng: 18.362526 },
    { lat: 49.00677, lng: 18.360933 },
    { lat: 49.006547, lng: 18.359627 },
    { lat: 49.006281, lng: 18.35877 },
    { lat: 49.005414, lng: 18.355978 },
    { lat: 49.005593, lng: 18.355908 },
    { lat: 49.006209, lng: 18.355043 },
    { lat: 49.006081, lng: 18.354535 },
    { lat: 49.0057085, lng: 18.3535816 },
    { lat: 49.0047386, lng: 18.3517287 },
    { lat: 49.0039059, lng: 18.3497898 },
    { lat: 49.0023532, lng: 18.3467758 },
    { lat: 49.0021795, lng: 18.3462301 },
    { lat: 49.0014829, lng: 18.3455562 },
    { lat: 49.0014016, lng: 18.3457176 },
    { lat: 49.0007679, lng: 18.3455207 },
    { lat: 49.0008896, lng: 18.3452786 },
    { lat: 49.0010072, lng: 18.3451197 },
    { lat: 49.0015871, lng: 18.3447566 },
    { lat: 49.0021224, lng: 18.345092 },
    { lat: 49.0022692, lng: 18.3450526 },
    { lat: 49.0023729, lng: 18.3449292 },
    { lat: 49.0025201, lng: 18.3444962 },
    { lat: 49.0026854, lng: 18.3446283 },
    { lat: 49.0027039, lng: 18.3448796 },
    { lat: 49.0026752, lng: 18.3450935 },
    { lat: 49.0027304, lng: 18.3451917 },
    { lat: 49.0027709, lng: 18.3455914 },
    { lat: 49.0028668, lng: 18.3455308 },
    { lat: 49.0027841, lng: 18.3452645 },
    { lat: 49.0028601, lng: 18.3449281 },
    { lat: 49.0033029, lng: 18.344937 },
    { lat: 49.0034377, lng: 18.3445627 },
    { lat: 49.0035109, lng: 18.3446244 },
    { lat: 49.0035378, lng: 18.3445386 },
    { lat: 49.0037149, lng: 18.3445981 },
    { lat: 49.0037064, lng: 18.343842 },
    { lat: 49.0038076, lng: 18.3437535 },
    { lat: 49.0038688, lng: 18.3436385 },
    { lat: 49.0036991, lng: 18.3435508 },
    { lat: 49.0035587, lng: 18.3437034 },
    { lat: 49.0034868, lng: 18.3436261 },
    { lat: 49.003441, lng: 18.3424868 },
    { lat: 49.0035229, lng: 18.3424967 },
    { lat: 49.0037254, lng: 18.3424062 },
    { lat: 49.0039687, lng: 18.3415541 },
    { lat: 49.0038105, lng: 18.3413086 },
    { lat: 49.0032211, lng: 18.3409155 },
    { lat: 49.0031041, lng: 18.3402943 },
    { lat: 49.0032576, lng: 18.3395317 },
    { lat: 49.0034862, lng: 18.339021 },
    { lat: 49.0035357, lng: 18.3387562 },
    { lat: 49.0030398, lng: 18.3385638 },
    { lat: 49.0025628, lng: 18.3384836 },
    { lat: 49.0017661, lng: 18.3384199 },
    { lat: 49.0018206, lng: 18.335244 },
    { lat: 49.0019961, lng: 18.3338868 },
    { lat: 49.0019538, lng: 18.333212 },
    { lat: 49.0022464, lng: 18.3324771 },
    { lat: 49.0022699, lng: 18.3321701 },
    { lat: 49.0021856, lng: 18.3317929 },
    { lat: 49.0018622, lng: 18.3319616 },
    { lat: 49.0016939, lng: 18.3320761 },
    { lat: 49.0012271, lng: 18.331783 },
    { lat: 49.0011776, lng: 18.3316417 },
    { lat: 49.0009702, lng: 18.3314041 },
    { lat: 49.0005787, lng: 18.3313628 },
    { lat: 49.0002869, lng: 18.3312098 },
    { lat: 49.0001761, lng: 18.3309025 },
    { lat: 49.0000275, lng: 18.3308065 },
    { lat: 48.9997769, lng: 18.3301206 },
    { lat: 48.9996106, lng: 18.3294307 },
    { lat: 48.9994736, lng: 18.3289397 },
    { lat: 48.9992863, lng: 18.3292212 },
    { lat: 48.9991676, lng: 18.3294781 },
    { lat: 48.9988538, lng: 18.3290293 },
    { lat: 48.9988291, lng: 18.3285504 },
    { lat: 48.9988226, lng: 18.3282533 },
    { lat: 48.9986016, lng: 18.3280516 },
    { lat: 48.9981708, lng: 18.3279964 },
    { lat: 48.9981878, lng: 18.3278785 },
    { lat: 48.9982479, lng: 18.3273821 },
    { lat: 48.99823, lng: 18.326557 },
    { lat: 48.9984375, lng: 18.3261762 },
    { lat: 48.9982479, lng: 18.3256489 },
    { lat: 48.9982359, lng: 18.3251945 },
    { lat: 48.9983981, lng: 18.3251706 },
    { lat: 48.9984095, lng: 18.3250534 },
    { lat: 48.9979139, lng: 18.3249042 },
    { lat: 48.9976993, lng: 18.3243798 },
    { lat: 48.9974994, lng: 18.323529 },
    { lat: 48.9971467, lng: 18.3227721 },
    { lat: 48.9969088, lng: 18.3224767 },
    { lat: 48.9965041, lng: 18.3221785 },
    { lat: 48.9960865, lng: 18.321814 },
    { lat: 48.9954834, lng: 18.3215441 },
    { lat: 48.9949377, lng: 18.3215103 },
    { lat: 48.9941412, lng: 18.3218496 },
    { lat: 48.9940811, lng: 18.3216422 },
    { lat: 48.9940545, lng: 18.3212106 },
    { lat: 48.9941131, lng: 18.3202635 },
    { lat: 48.9938593, lng: 18.3201268 },
    { lat: 48.9930973, lng: 18.3194733 },
    { lat: 48.9929163, lng: 18.3190247 },
    { lat: 48.9927998, lng: 18.3182549 },
    { lat: 48.9927128, lng: 18.3177718 },
    { lat: 48.9925081, lng: 18.3175832 },
    { lat: 48.9923028, lng: 18.3170806 },
    { lat: 48.9920179, lng: 18.3166455 },
    { lat: 48.991651, lng: 18.316082 },
    { lat: 48.9904408, lng: 18.3154638 },
    { lat: 48.9901129, lng: 18.3151697 },
    { lat: 48.9899236, lng: 18.3149349 },
    { lat: 48.9894577, lng: 18.3144741 },
    { lat: 48.9892999, lng: 18.3142959 },
    { lat: 48.9891516, lng: 18.3139448 },
    { lat: 48.9889729, lng: 18.3132725 },
    { lat: 48.9888932, lng: 18.3128015 },
    { lat: 48.9888732, lng: 18.312207 },
    { lat: 48.9889369, lng: 18.3117911 },
    { lat: 48.9888644, lng: 18.3114067 },
    { lat: 48.988639, lng: 18.3112111 },
    { lat: 48.9883388, lng: 18.3107253 },
    { lat: 48.9883244, lng: 18.3102508 },
    { lat: 48.9883006, lng: 18.3091633 },
    { lat: 48.9884553, lng: 18.3082063 },
    { lat: 48.9882322, lng: 18.3076289 },
    { lat: 48.9879221, lng: 18.3064693 },
    { lat: 48.9877694, lng: 18.3056001 },
    { lat: 48.987579, lng: 18.3051625 },
    { lat: 48.9874275, lng: 18.3050802 },
    { lat: 48.9872056, lng: 18.3049851 },
    { lat: 48.9871107, lng: 18.3044783 },
    { lat: 48.9870046, lng: 18.303136 },
    { lat: 48.9870287, lng: 18.3028419 },
    { lat: 48.9871224, lng: 18.3026627 },
    { lat: 48.987098, lng: 18.3025114 },
    { lat: 48.9869213, lng: 18.3015393 },
    { lat: 48.9868312, lng: 18.3010395 },
    { lat: 48.9867638, lng: 18.300725 },
    { lat: 48.9866804, lng: 18.3003274 },
    { lat: 48.9865935, lng: 18.2999259 },
    { lat: 48.9865137, lng: 18.2998095 },
    { lat: 48.9862341, lng: 18.2996307 },
    { lat: 48.9859383, lng: 18.2994712 },
    { lat: 48.9857098, lng: 18.2993609 },
    { lat: 48.9848954, lng: 18.2986025 },
    { lat: 48.9843861, lng: 18.2982112 },
    { lat: 48.983906, lng: 18.2975617 },
    { lat: 48.9838373, lng: 18.2969434 },
    { lat: 48.9828216, lng: 18.2942756 },
    { lat: 48.9824645, lng: 18.2927057 },
    { lat: 48.9823612, lng: 18.2918934 },
    { lat: 48.9822861, lng: 18.2908414 },
    { lat: 48.982344, lng: 18.2906697 },
    { lat: 48.9825942, lng: 18.2899252 },
    { lat: 48.982597, lng: 18.2899165 },
    { lat: 48.9826694, lng: 18.2896991 },
    { lat: 48.9826802, lng: 18.2896686 },
    { lat: 48.982685, lng: 18.2896529 },
    { lat: 48.9827487, lng: 18.2894632 },
    { lat: 48.9827516, lng: 18.289456 },
    { lat: 48.9828259, lng: 18.2892341 },
    { lat: 48.9829833, lng: 18.2887649 },
    { lat: 48.9829892, lng: 18.2887476 },
    { lat: 48.9830365, lng: 18.2886057 },
    { lat: 48.9830539, lng: 18.2885531 },
    { lat: 48.9830771, lng: 18.2884862 },
    { lat: 48.9831098, lng: 18.2883867 },
    { lat: 48.9831331, lng: 18.2883183 },
    { lat: 48.9831397, lng: 18.2882977 },
    { lat: 48.9832247, lng: 18.2880445 },
    { lat: 48.9831652, lng: 18.2879876 },
    { lat: 48.9831545, lng: 18.2879768 },
    { lat: 48.9830448, lng: 18.2878725 },
    { lat: 48.9830303, lng: 18.2878586 },
    { lat: 48.9830198, lng: 18.2878485 },
    { lat: 48.9830575, lng: 18.2877592 },
    { lat: 48.9837264, lng: 18.2860584 },
    { lat: 48.9841069, lng: 18.2852021 },
    { lat: 48.9842065, lng: 18.2845716 },
    { lat: 48.9845504, lng: 18.2834696 },
    { lat: 48.9849105, lng: 18.2825314 },
    { lat: 48.9849229, lng: 18.2823922 },
    { lat: 48.9850126, lng: 18.2814023 },
    { lat: 48.9850803, lng: 18.2802656 },
    { lat: 48.9851674, lng: 18.2798483 },
    { lat: 48.9854655, lng: 18.2792375 },
    { lat: 48.9854187, lng: 18.2791869 },
    { lat: 48.9853753, lng: 18.2791393 },
    { lat: 48.985367, lng: 18.27913 },
    { lat: 48.985001, lng: 18.2784029 },
    { lat: 48.9849856, lng: 18.2783884 },
    { lat: 48.9849312, lng: 18.2783262 },
    { lat: 48.9849119, lng: 18.2782651 },
    { lat: 48.9835463, lng: 18.2762816 },
    { lat: 48.9831474, lng: 18.278046 },
    { lat: 48.9829967, lng: 18.2787126 },
    { lat: 48.9826744, lng: 18.2797576 },
    { lat: 48.9824771, lng: 18.2803584 },
    { lat: 48.9822034, lng: 18.2804681 },
    { lat: 48.9822815, lng: 18.2801946 },
    { lat: 48.9821128, lng: 18.2803793 },
    { lat: 48.9819478, lng: 18.2810754 },
    { lat: 48.9818524, lng: 18.2811224 },
    { lat: 48.9817327, lng: 18.2818826 },
    { lat: 48.9814732, lng: 18.2820389 },
    { lat: 48.981393, lng: 18.2826582 },
    { lat: 48.9813743, lng: 18.282967 },
    { lat: 48.9813224, lng: 18.2831067 },
    { lat: 48.981228, lng: 18.283193 },
    { lat: 48.981218, lng: 18.2834558 },
    { lat: 48.9812038, lng: 18.283824 },
    { lat: 48.9795844, lng: 18.2842948 },
    { lat: 48.9779085, lng: 18.2847274 },
    { lat: 48.9781573, lng: 18.2839389 },
    { lat: 48.9777581, lng: 18.2836216 },
  ],
  Zliechov: [
    { lat: 48.982226, lng: 18.437509 },
    { lat: 48.981789, lng: 18.436873 },
    { lat: 48.981502, lng: 18.436398 },
    { lat: 48.981211, lng: 18.435938 },
    { lat: 48.978733, lng: 18.432028 },
    { lat: 48.978413, lng: 18.431525 },
    { lat: 48.977739, lng: 18.430465 },
    { lat: 48.977353, lng: 18.429793 },
    { lat: 48.976772, lng: 18.428933 },
    { lat: 48.976423, lng: 18.428409 },
    { lat: 48.97615, lng: 18.428054 },
    { lat: 48.975739, lng: 18.427451 },
    { lat: 48.975692, lng: 18.427392 },
    { lat: 48.975398, lng: 18.427 },
    { lat: 48.975162, lng: 18.426717 },
    { lat: 48.975137, lng: 18.426685 },
    { lat: 48.974852, lng: 18.426324 },
    { lat: 48.974145, lng: 18.425357 },
    { lat: 48.973989, lng: 18.425183 },
    { lat: 48.973957, lng: 18.425139 },
    { lat: 48.973864, lng: 18.425011 },
    { lat: 48.973473, lng: 18.424525 },
    { lat: 48.973281, lng: 18.424318 },
    { lat: 48.973236, lng: 18.424253 },
    { lat: 48.972864, lng: 18.423788 },
    { lat: 48.972457, lng: 18.42331 },
    { lat: 48.972182, lng: 18.423005 },
    { lat: 48.971579, lng: 18.422278 },
    { lat: 48.971947, lng: 18.419218 },
    { lat: 48.972204, lng: 18.418139 },
    { lat: 48.97209, lng: 18.417487 },
    { lat: 48.971914, lng: 18.416684 },
    { lat: 48.971846, lng: 18.415981 },
    { lat: 48.971331, lng: 18.414182 },
    { lat: 48.971562, lng: 18.413093 },
    { lat: 48.971867, lng: 18.412442 },
    { lat: 48.971764, lng: 18.410366 },
    { lat: 48.971694, lng: 18.409724 },
    { lat: 48.971605, lng: 18.40911 },
    { lat: 48.971544, lng: 18.408719 },
    { lat: 48.971369, lng: 18.407871 },
    { lat: 48.971428, lng: 18.407071 },
    { lat: 48.971631, lng: 18.406367 },
    { lat: 48.971874, lng: 18.405179 },
    { lat: 48.97191, lng: 18.404422 },
    { lat: 48.971443, lng: 18.404412 },
    { lat: 48.971485, lng: 18.404108 },
    { lat: 48.971405, lng: 18.403736 },
    { lat: 48.971249, lng: 18.403006 },
    { lat: 48.970877, lng: 18.401717 },
    { lat: 48.970839, lng: 18.400667 },
    { lat: 48.970994, lng: 18.399884 },
    { lat: 48.971497, lng: 18.398639 },
    { lat: 48.971571, lng: 18.398573 },
    { lat: 48.971925, lng: 18.397277 },
    { lat: 48.971883, lng: 18.39681 },
    { lat: 48.972259, lng: 18.39694 },
    { lat: 48.972656, lng: 18.396625 },
    { lat: 48.973459, lng: 18.396052 },
    { lat: 48.973552, lng: 18.395754 },
    { lat: 48.974126, lng: 18.394939 },
    { lat: 48.97429, lng: 18.394368 },
    { lat: 48.974973, lng: 18.393461 },
    { lat: 48.975088, lng: 18.392571 },
    { lat: 48.975931, lng: 18.392293 },
    { lat: 48.976543, lng: 18.391266 },
    { lat: 48.976546, lng: 18.390818 },
    { lat: 48.976697, lng: 18.390284 },
    { lat: 48.976881, lng: 18.388119 },
    { lat: 48.977031, lng: 18.387652 },
    { lat: 48.977631, lng: 18.386129 },
    { lat: 48.977845, lng: 18.385681 },
    { lat: 48.978103, lng: 18.385062 },
    { lat: 48.978263, lng: 18.384629 },
    { lat: 48.978306, lng: 18.384388 },
    { lat: 48.978959, lng: 18.382856 },
    { lat: 48.979097, lng: 18.382623 },
    { lat: 48.979217, lng: 18.382323 },
    { lat: 48.979338, lng: 18.38165 },
    { lat: 48.979464, lng: 18.380552 },
    { lat: 48.979472, lng: 18.380156 },
    { lat: 48.979557, lng: 18.379381 },
    { lat: 48.979452, lng: 18.378861 },
    { lat: 48.979543, lng: 18.37831 },
    { lat: 48.979506, lng: 18.377605 },
    { lat: 48.980295, lng: 18.375853 },
    { lat: 48.980436, lng: 18.375072 },
    { lat: 48.980438, lng: 18.37442 },
    { lat: 48.980464, lng: 18.373267 },
    { lat: 48.9804907, lng: 18.3730108 },
    { lat: 48.9804507, lng: 18.3727006 },
    { lat: 48.9805057, lng: 18.3721257 },
    { lat: 48.9801209, lng: 18.3700993 },
    { lat: 48.9801513, lng: 18.3682993 },
    { lat: 48.9797099, lng: 18.3682519 },
    { lat: 48.979414, lng: 18.3687917 },
    { lat: 48.9746861, lng: 18.3720128 },
    { lat: 48.9734174, lng: 18.3736404 },
    { lat: 48.9721521, lng: 18.3780877 },
    { lat: 48.9720958, lng: 18.3781071 },
    { lat: 48.9720448, lng: 18.3781252 },
    { lat: 48.9720216, lng: 18.3781336 },
    { lat: 48.9717866, lng: 18.3767105 },
    { lat: 48.971549, lng: 18.3756448 },
    { lat: 48.9713421, lng: 18.3749654 },
    { lat: 48.9709965, lng: 18.3745742 },
    { lat: 48.9708577, lng: 18.3744132 },
    { lat: 48.9707844, lng: 18.3741293 },
    { lat: 48.9707503, lng: 18.3738224 },
    { lat: 48.9707144, lng: 18.3734995 },
    { lat: 48.9706645, lng: 18.3730506 },
    { lat: 48.9706415, lng: 18.372841 },
    { lat: 48.9706265, lng: 18.3727115 },
    { lat: 48.9705102, lng: 18.3722837 },
    { lat: 48.9705043, lng: 18.3722618 },
    { lat: 48.9704774, lng: 18.3721611 },
    { lat: 48.9701864, lng: 18.3714747 },
    { lat: 48.9697676, lng: 18.3703784 },
    { lat: 48.9693706, lng: 18.3693888 },
    { lat: 48.9684121, lng: 18.3676346 },
    { lat: 48.9683079, lng: 18.3676491 },
    { lat: 48.9681821, lng: 18.3669098 },
    { lat: 48.9681505, lng: 18.366725 },
    { lat: 48.9677532, lng: 18.3669054 },
    { lat: 48.9677112, lng: 18.3669244 },
    { lat: 48.9672934, lng: 18.365338 },
    { lat: 48.9671317, lng: 18.3645412 },
    { lat: 48.967014, lng: 18.364253 },
    { lat: 48.9667755, lng: 18.3631735 },
    { lat: 48.9667723, lng: 18.3625696 },
    { lat: 48.9666656, lng: 18.3618526 },
    { lat: 48.9668527, lng: 18.3610207 },
    { lat: 48.9670664, lng: 18.3606053 },
    { lat: 48.9667447, lng: 18.3600335 },
    { lat: 48.9666718, lng: 18.3602013 },
    { lat: 48.9664715, lng: 18.3606579 },
    { lat: 48.9663794, lng: 18.3608669 },
    { lat: 48.9660199, lng: 18.3614783 },
    { lat: 48.9659767, lng: 18.3614922 },
    { lat: 48.9654492, lng: 18.361885 },
    { lat: 48.9650609, lng: 18.3621864 },
    { lat: 48.9648028, lng: 18.3626537 },
    { lat: 48.964277, lng: 18.3634628 },
    { lat: 48.9639768, lng: 18.3639836 },
    { lat: 48.9636682, lng: 18.3644772 },
    { lat: 48.9635143, lng: 18.3645925 },
    { lat: 48.9626993, lng: 18.3650474 },
    { lat: 48.9617717, lng: 18.3657728 },
    { lat: 48.9614209, lng: 18.3661379 },
    { lat: 48.9604768, lng: 18.367233 },
    { lat: 48.9601574, lng: 18.367534 },
    { lat: 48.9598142, lng: 18.3677512 },
    { lat: 48.9595383, lng: 18.3680046 },
    { lat: 48.959385, lng: 18.3682864 },
    { lat: 48.9593665, lng: 18.3683415 },
    { lat: 48.9593273, lng: 18.3683071 },
    { lat: 48.9592296, lng: 18.3682945 },
    { lat: 48.9589329, lng: 18.3682562 },
    { lat: 48.9580299, lng: 18.3667256 },
    { lat: 48.9570398, lng: 18.3652178 },
    { lat: 48.9561934, lng: 18.3639798 },
    { lat: 48.954867, lng: 18.3634007 },
    { lat: 48.9539966, lng: 18.363372 },
    { lat: 48.9529595, lng: 18.3643533 },
    { lat: 48.9514336, lng: 18.3653343 },
    { lat: 48.9505435, lng: 18.3653544 },
    { lat: 48.9486247, lng: 18.3669537 },
    { lat: 48.9473808, lng: 18.3678104 },
    { lat: 48.9460114, lng: 18.3698973 },
    { lat: 48.945235, lng: 18.370117 },
    { lat: 48.9439777, lng: 18.3702283 },
    { lat: 48.943492, lng: 18.3708036 },
    { lat: 48.9425698, lng: 18.3719686 },
    { lat: 48.9411264, lng: 18.3727498 },
    { lat: 48.9395427, lng: 18.3720253 },
    { lat: 48.93862, lng: 18.372373 },
    { lat: 48.938512, lng: 18.372857 },
    { lat: 48.938451, lng: 18.373368 },
    { lat: 48.938432, lng: 18.373458 },
    { lat: 48.9384, lng: 18.373918 },
    { lat: 48.938362, lng: 18.374138 },
    { lat: 48.938347, lng: 18.374511 },
    { lat: 48.937904, lng: 18.376634 },
    { lat: 48.937879, lng: 18.378028 },
    { lat: 48.938044, lng: 18.378276 },
    { lat: 48.938206, lng: 18.378898 },
    { lat: 48.938394, lng: 18.37918 },
    { lat: 48.938428, lng: 18.379312 },
    { lat: 48.938632, lng: 18.379874 },
    { lat: 48.938723, lng: 18.380082 },
    { lat: 48.938985, lng: 18.380911 },
    { lat: 48.93869, lng: 18.381254 },
    { lat: 48.938431, lng: 18.381409 },
    { lat: 48.938245, lng: 18.38166 },
    { lat: 48.938052, lng: 18.381885 },
    { lat: 48.937481, lng: 18.382907 },
    { lat: 48.937057, lng: 18.383705 },
    { lat: 48.936803, lng: 18.384125 },
    { lat: 48.936487, lng: 18.384295 },
    { lat: 48.936185, lng: 18.384256 },
    { lat: 48.935796, lng: 18.38442 },
    { lat: 48.935538, lng: 18.384832 },
    { lat: 48.935119, lng: 18.385149 },
    { lat: 48.933773, lng: 18.386167 },
    { lat: 48.933635, lng: 18.386271 },
    { lat: 48.933174, lng: 18.387042 },
    { lat: 48.932504, lng: 18.38816 },
    { lat: 48.932379, lng: 18.388825 },
    { lat: 48.932265, lng: 18.389429 },
    { lat: 48.932204, lng: 18.389818 },
    { lat: 48.932187, lng: 18.389936 },
    { lat: 48.932173, lng: 18.390037 },
    { lat: 48.932175, lng: 18.390772 },
    { lat: 48.932178, lng: 18.390785 },
    { lat: 48.932221, lng: 18.390953 },
    { lat: 48.932327, lng: 18.391364 },
    { lat: 48.932378, lng: 18.391562 },
    { lat: 48.932685, lng: 18.392757 },
    { lat: 48.932689, lng: 18.393083 },
    { lat: 48.932703, lng: 18.39396 },
    { lat: 48.93271, lng: 18.393977 },
    { lat: 48.93327, lng: 18.395409 },
    { lat: 48.933283, lng: 18.39544 },
    { lat: 48.933375, lng: 18.395676 },
    { lat: 48.93337, lng: 18.395685 },
    { lat: 48.932943, lng: 18.396348 },
    { lat: 48.932661, lng: 18.39705 },
    { lat: 48.932179, lng: 18.398039 },
    { lat: 48.931521, lng: 18.398826 },
    { lat: 48.931039, lng: 18.399537 },
    { lat: 48.93031, lng: 18.400615 },
    { lat: 48.929251, lng: 18.402047 },
    { lat: 48.929131, lng: 18.402227 },
    { lat: 48.929111, lng: 18.402257 },
    { lat: 48.928651, lng: 18.402945 },
    { lat: 48.928045, lng: 18.403171 },
    { lat: 48.928042, lng: 18.403172 },
    { lat: 48.927287, lng: 18.403745 },
    { lat: 48.92685, lng: 18.403669 },
    { lat: 48.926227, lng: 18.40318 },
    { lat: 48.925601, lng: 18.402923 },
    { lat: 48.925099, lng: 18.402842 },
    { lat: 48.924962, lng: 18.40282 },
    { lat: 48.924665, lng: 18.402704 },
    { lat: 48.924603, lng: 18.40268 },
    { lat: 48.924291, lng: 18.402558 },
    { lat: 48.924193, lng: 18.402539 },
    { lat: 48.923418, lng: 18.402392 },
    { lat: 48.92338, lng: 18.402401 },
    { lat: 48.923091, lng: 18.402472 },
    { lat: 48.922845, lng: 18.402532 },
    { lat: 48.922642, lng: 18.402819 },
    { lat: 48.922334, lng: 18.403254 },
    { lat: 48.921942, lng: 18.403654 },
    { lat: 48.921938, lng: 18.403657 },
    { lat: 48.921604, lng: 18.403887 },
    { lat: 48.921572, lng: 18.403909 },
    { lat: 48.921505, lng: 18.40393 },
    { lat: 48.920934, lng: 18.404109 },
    { lat: 48.920346, lng: 18.404294 },
    { lat: 48.920066, lng: 18.404351 },
    { lat: 48.919987, lng: 18.404368 },
    { lat: 48.919592, lng: 18.404449 },
    { lat: 48.91947, lng: 18.404475 },
    { lat: 48.918956, lng: 18.404896 },
    { lat: 48.918225, lng: 18.405498 },
    { lat: 48.918113, lng: 18.40559 },
    { lat: 48.917871, lng: 18.406188 },
    { lat: 48.917748, lng: 18.406492 },
    { lat: 48.917598, lng: 18.406864 },
    { lat: 48.917477, lng: 18.407707 },
    { lat: 48.917406, lng: 18.409435 },
    { lat: 48.917405, lng: 18.409461 },
    { lat: 48.916929, lng: 18.410856 },
    { lat: 48.916913, lng: 18.410912 },
    { lat: 48.916451, lng: 18.412485 },
    { lat: 48.915922, lng: 18.413503 },
    { lat: 48.915234, lng: 18.414622 },
    { lat: 48.914919, lng: 18.415747 },
    { lat: 48.914912, lng: 18.416259 },
    { lat: 48.914911, lng: 18.416349 },
    { lat: 48.9149, lng: 18.416382 },
    { lat: 48.914295, lng: 18.41846 },
    { lat: 48.913484, lng: 18.420544 },
    { lat: 48.913024, lng: 18.421958 },
    { lat: 48.912975, lng: 18.422309 },
    { lat: 48.912878, lng: 18.423531 },
    { lat: 48.912618, lng: 18.424289 },
    { lat: 48.912442, lng: 18.42499 },
    { lat: 48.912472, lng: 18.426105 },
    { lat: 48.912581, lng: 18.428656 },
    { lat: 48.912373, lng: 18.43187 },
    { lat: 48.912363, lng: 18.432031 },
    { lat: 48.912387, lng: 18.432198 },
    { lat: 48.912572, lng: 18.433488 },
    { lat: 48.912586, lng: 18.433587 },
    { lat: 48.912596, lng: 18.43366 },
    { lat: 48.912609, lng: 18.433722 },
    { lat: 48.912618, lng: 18.433776 },
    { lat: 48.91262, lng: 18.43378 },
    { lat: 48.912639, lng: 18.433886 },
    { lat: 48.912636, lng: 18.434011 },
    { lat: 48.912633, lng: 18.434117 },
    { lat: 48.912626, lng: 18.434327 },
    { lat: 48.912093, lng: 18.43478 },
    { lat: 48.910714, lng: 18.435235 },
    { lat: 48.910631, lng: 18.435332 },
    { lat: 48.909984, lng: 18.435538 },
    { lat: 48.909755, lng: 18.435605 },
    { lat: 48.909065, lng: 18.435804 },
    { lat: 48.907595, lng: 18.435838 },
    { lat: 48.906622, lng: 18.436316 },
    { lat: 48.905803, lng: 18.436522 },
    { lat: 48.905644, lng: 18.436562 },
    { lat: 48.904952, lng: 18.436735 },
    { lat: 48.904753, lng: 18.436785 },
    { lat: 48.904511, lng: 18.436846 },
    { lat: 48.904206, lng: 18.43721 },
    { lat: 48.903516, lng: 18.437728 },
    { lat: 48.902264, lng: 18.437701 },
    { lat: 48.901588, lng: 18.438126 },
    { lat: 48.90103, lng: 18.438323 },
    { lat: 48.901038, lng: 18.438336 },
    { lat: 48.901316, lng: 18.43886 },
    { lat: 48.901316, lng: 18.438866 },
    { lat: 48.901335, lng: 18.439117 },
    { lat: 48.901119, lng: 18.439353 },
    { lat: 48.900857, lng: 18.439703 },
    { lat: 48.900494, lng: 18.440442 },
    { lat: 48.900265, lng: 18.440998 },
    { lat: 48.899904, lng: 18.441338 },
    { lat: 48.899716, lng: 18.442146 },
    { lat: 48.899551, lng: 18.442337 },
    { lat: 48.899318, lng: 18.442606 },
    { lat: 48.899272, lng: 18.442652 },
    { lat: 48.899128, lng: 18.442794 },
    { lat: 48.898993, lng: 18.44298 },
    { lat: 48.89896, lng: 18.443025 },
    { lat: 48.89884, lng: 18.443189 },
    { lat: 48.898707, lng: 18.443443 },
    { lat: 48.898587, lng: 18.443713 },
    { lat: 48.898495, lng: 18.443972 },
    { lat: 48.89829, lng: 18.444754 },
    { lat: 48.898284, lng: 18.444775 },
    { lat: 48.898209, lng: 18.444943 },
    { lat: 48.897942, lng: 18.445538 },
    { lat: 48.897561, lng: 18.446064 },
    { lat: 48.897452, lng: 18.446215 },
    { lat: 48.897251, lng: 18.446395 },
    { lat: 48.897148, lng: 18.446488 },
    { lat: 48.896887, lng: 18.446723 },
    { lat: 48.896524, lng: 18.447218 },
    { lat: 48.896435, lng: 18.447333 },
    { lat: 48.896016, lng: 18.447877 },
    { lat: 48.896014, lng: 18.447972 },
    { lat: 48.896087, lng: 18.448745 },
    { lat: 48.896114, lng: 18.448972 },
    { lat: 48.896389, lng: 18.449662 },
    { lat: 48.8965068, lng: 18.4498821 },
    { lat: 48.896663, lng: 18.450174 },
    { lat: 48.897033, lng: 18.450767 },
    { lat: 48.897892, lng: 18.45241 },
    { lat: 48.899288, lng: 18.454323 },
    { lat: 48.899798, lng: 18.454547 },
    { lat: 48.900224, lng: 18.454793 },
    { lat: 48.900771, lng: 18.455277 },
    { lat: 48.900821, lng: 18.455306 },
    { lat: 48.900863, lng: 18.455331 },
    { lat: 48.901021, lng: 18.455422 },
    { lat: 48.902089, lng: 18.456355 },
    { lat: 48.902592, lng: 18.45666 },
    { lat: 48.902884, lng: 18.45702 },
    { lat: 48.903149, lng: 18.457226 },
    { lat: 48.903275, lng: 18.457336 },
    { lat: 48.903396, lng: 18.457532 },
    { lat: 48.903548, lng: 18.457902 },
    { lat: 48.903812, lng: 18.458649 },
    { lat: 48.904324, lng: 18.459677 },
    { lat: 48.904648, lng: 18.460253 },
    { lat: 48.904906, lng: 18.460787 },
    { lat: 48.905207, lng: 18.461249 },
    { lat: 48.905358, lng: 18.46134 },
    { lat: 48.90617, lng: 18.461369 },
    { lat: 48.906316, lng: 18.461415 },
    { lat: 48.906486, lng: 18.461416 },
    { lat: 48.906784, lng: 18.461401 },
    { lat: 48.907123, lng: 18.461376 },
    { lat: 48.907305, lng: 18.461396 },
    { lat: 48.907717, lng: 18.461988 },
    { lat: 48.907734, lng: 18.462051 },
    { lat: 48.907787, lng: 18.462249 },
    { lat: 48.907799, lng: 18.462281 },
    { lat: 48.907645, lng: 18.46244 },
    { lat: 48.907468, lng: 18.462623 },
    { lat: 48.907451, lng: 18.462633 },
    { lat: 48.906977, lng: 18.462926 },
    { lat: 48.906942, lng: 18.462965 },
    { lat: 48.906837, lng: 18.463084 },
    { lat: 48.906699, lng: 18.463298 },
    { lat: 48.906697, lng: 18.463301 },
    { lat: 48.906697, lng: 18.463302 },
    { lat: 48.906681, lng: 18.463383 },
    { lat: 48.906687, lng: 18.463423 },
    { lat: 48.907172, lng: 18.464383 },
    { lat: 48.907359, lng: 18.464857 },
    { lat: 48.907428, lng: 18.465014 },
    { lat: 48.907592, lng: 18.465387 },
    { lat: 48.907622, lng: 18.465452 },
    { lat: 48.9077, lng: 18.465632 },
    { lat: 48.908004, lng: 18.466643 },
    { lat: 48.908202, lng: 18.467352 },
    { lat: 48.908404, lng: 18.468071 },
    { lat: 48.90866, lng: 18.470224 },
    { lat: 48.908787, lng: 18.470914 },
    { lat: 48.908873, lng: 18.471383 },
    { lat: 48.908903, lng: 18.471545 },
    { lat: 48.908922, lng: 18.471685 },
    { lat: 48.908963, lng: 18.471988 },
    { lat: 48.908982, lng: 18.472455 },
    { lat: 48.908991, lng: 18.472678 },
    { lat: 48.908987, lng: 18.473548 },
    { lat: 48.909298, lng: 18.475725 },
    { lat: 48.90934, lng: 18.476019 },
    { lat: 48.909498, lng: 18.476838 },
    { lat: 48.909547, lng: 18.477089 },
    { lat: 48.909658, lng: 18.478166 },
    { lat: 48.909648, lng: 18.478257 },
    { lat: 48.909616, lng: 18.478574 },
    { lat: 48.909245, lng: 18.480136 },
    { lat: 48.909199, lng: 18.480316 },
    { lat: 48.909221, lng: 18.480413 },
    { lat: 48.909393, lng: 18.481172 },
    { lat: 48.909864, lng: 18.483261 },
    { lat: 48.909865, lng: 18.483267 },
    { lat: 48.910069, lng: 18.484095 },
    { lat: 48.910503, lng: 18.485726 },
    { lat: 48.91064, lng: 18.48596 },
    { lat: 48.910932, lng: 18.486375 },
    { lat: 48.911555, lng: 18.487065 },
    { lat: 48.91157, lng: 18.487092 },
    { lat: 48.91181, lng: 18.48751 },
    { lat: 48.911857, lng: 18.487674 },
    { lat: 48.911867, lng: 18.487702 },
    { lat: 48.912077, lng: 18.487319 },
    { lat: 48.912169, lng: 18.487329 },
    { lat: 48.912393, lng: 18.487354 },
    { lat: 48.912394, lng: 18.487354 },
    { lat: 48.912464, lng: 18.487723 },
    { lat: 48.912476, lng: 18.487769 },
    { lat: 48.912529, lng: 18.487927 },
    { lat: 48.912539, lng: 18.487956 },
    { lat: 48.91255, lng: 18.487973 },
    { lat: 48.913171, lng: 18.488952 },
    { lat: 48.913684, lng: 18.489689 },
    { lat: 48.914132, lng: 18.4898 },
    { lat: 48.914215, lng: 18.48982 },
    { lat: 48.914216, lng: 18.489823 },
    { lat: 48.91422, lng: 18.490029 },
    { lat: 48.914232, lng: 18.490583 },
    { lat: 48.9153, lng: 18.491995 },
    { lat: 48.916295, lng: 18.49315 },
    { lat: 48.916359, lng: 18.493241 },
    { lat: 48.91672, lng: 18.493742 },
    { lat: 48.91672, lng: 18.493744 },
    { lat: 48.916754, lng: 18.493967 },
    { lat: 48.916754, lng: 18.493973 },
    { lat: 48.916787, lng: 18.493986 },
    { lat: 48.917988, lng: 18.494454 },
    { lat: 48.918723, lng: 18.494471 },
    { lat: 48.918757, lng: 18.494471 },
    { lat: 48.919068, lng: 18.494479 },
    { lat: 48.920378, lng: 18.494263 },
    { lat: 48.92038, lng: 18.494262 },
    { lat: 48.9211, lng: 18.494079 },
    { lat: 48.921781, lng: 18.493905 },
    { lat: 48.921962, lng: 18.49387 },
    { lat: 48.923065, lng: 18.493519 },
    { lat: 48.923245, lng: 18.493485 },
    { lat: 48.923336, lng: 18.493529 },
    { lat: 48.924859, lng: 18.494256 },
    { lat: 48.926153, lng: 18.495269 },
    { lat: 48.926296, lng: 18.495429 },
    { lat: 48.927283, lng: 18.496601 },
    { lat: 48.9275189, lng: 18.4972717 },
    { lat: 48.927684, lng: 18.497741 },
    { lat: 48.9278201, lng: 18.4985012 },
    { lat: 48.928023, lng: 18.499634 },
    { lat: 48.9280531, lng: 18.4998713 },
    { lat: 48.9281638, lng: 18.5007434 },
    { lat: 48.928273, lng: 18.501604 },
    { lat: 48.928371, lng: 18.501774 },
    { lat: 48.928926, lng: 18.502738 },
    { lat: 48.928979, lng: 18.502904 },
    { lat: 48.929122, lng: 18.503353 },
    { lat: 48.929626, lng: 18.503836 },
    { lat: 48.9299473, lng: 18.5040452 },
    { lat: 48.930159, lng: 18.504183 },
    { lat: 48.930832, lng: 18.50439 },
    { lat: 48.931031, lng: 18.504381 },
    { lat: 48.93122, lng: 18.504393 },
    { lat: 48.931874, lng: 18.50457 },
    { lat: 48.932065, lng: 18.504717 },
    { lat: 48.932233, lng: 18.504847 },
    { lat: 48.932237, lng: 18.504855 },
    { lat: 48.93261, lng: 18.505588 },
    { lat: 48.932988, lng: 18.505957 },
    { lat: 48.932991, lng: 18.505959 },
    { lat: 48.932991, lng: 18.50596 },
    { lat: 48.933423, lng: 18.507074 },
    { lat: 48.933701, lng: 18.5070983 },
    { lat: 48.933783, lng: 18.507102 },
    { lat: 48.9338243, lng: 18.5071124 },
    { lat: 48.933874, lng: 18.507125 },
    { lat: 48.934733, lng: 18.507192 },
    { lat: 48.935997, lng: 18.507673 },
    { lat: 48.936292, lng: 18.507493 },
    { lat: 48.936395, lng: 18.507386 },
    { lat: 48.936475, lng: 18.50729 },
    { lat: 48.936541, lng: 18.507224 },
    { lat: 48.936563, lng: 18.507202 },
    { lat: 48.936623, lng: 18.507167 },
    { lat: 48.93673, lng: 18.507128 },
    { lat: 48.936743, lng: 18.507129 },
    { lat: 48.93729, lng: 18.507167 },
    { lat: 48.937663, lng: 18.506971 },
    { lat: 48.93829, lng: 18.506271 },
    { lat: 48.938332, lng: 18.506231 },
    { lat: 48.938653, lng: 18.505927 },
    { lat: 48.938959, lng: 18.505197 },
    { lat: 48.939166, lng: 18.504786 },
    { lat: 48.939234, lng: 18.504649 },
    { lat: 48.939535, lng: 18.504511 },
    { lat: 48.940135, lng: 18.504237 },
    { lat: 48.940201, lng: 18.503696 },
    { lat: 48.940102, lng: 18.503134 },
    { lat: 48.93999, lng: 18.50245 },
    { lat: 48.940297, lng: 18.501632 },
    { lat: 48.940571, lng: 18.500791 },
    { lat: 48.940603, lng: 18.500282 },
    { lat: 48.940623, lng: 18.500295 },
    { lat: 48.940734, lng: 18.498318 },
    { lat: 48.940195, lng: 18.498234 },
    { lat: 48.93988, lng: 18.49672 },
    { lat: 48.940141, lng: 18.496616 },
    { lat: 48.940197, lng: 18.496594 },
    { lat: 48.940336, lng: 18.496538 },
    { lat: 48.940475, lng: 18.495814 },
    { lat: 48.94072, lng: 18.495134 },
    { lat: 48.940667, lng: 18.495004 },
    { lat: 48.940707, lng: 18.494742 },
    { lat: 48.940699, lng: 18.494468 },
    { lat: 48.940892, lng: 18.493888 },
    { lat: 48.941025, lng: 18.493708 },
    { lat: 48.941132, lng: 18.49349 },
    { lat: 48.94121, lng: 18.493089 },
    { lat: 48.94121, lng: 18.492693 },
    { lat: 48.941209, lng: 18.491688 },
    { lat: 48.941176, lng: 18.490724 },
    { lat: 48.941197, lng: 18.490215 },
    { lat: 48.941212, lng: 18.489819 },
    { lat: 48.941216, lng: 18.489712 },
    { lat: 48.941219, lng: 18.489637 },
    { lat: 48.941215, lng: 18.489548 },
    { lat: 48.941206, lng: 18.489367 },
    { lat: 48.94118, lng: 18.488826 },
    { lat: 48.940916, lng: 18.488085 },
    { lat: 48.940682, lng: 18.487691 },
    { lat: 48.940415, lng: 18.487529 },
    { lat: 48.940108, lng: 18.48717 },
    { lat: 48.939941, lng: 18.486681 },
    { lat: 48.939946, lng: 18.485976 },
    { lat: 48.939994, lng: 18.485375 },
    { lat: 48.939638, lng: 18.484644 },
    { lat: 48.939702, lng: 18.483936 },
    { lat: 48.939664, lng: 18.483572 },
    { lat: 48.939792, lng: 18.482925 },
    { lat: 48.939823, lng: 18.482577 },
    { lat: 48.939722, lng: 18.481597 },
    { lat: 48.939769, lng: 18.481134 },
    { lat: 48.93982, lng: 18.480801 },
    { lat: 48.939722, lng: 18.480265 },
    { lat: 48.939562, lng: 18.479578 },
    { lat: 48.939627, lng: 18.479377 },
    { lat: 48.939608, lng: 18.479362 },
    { lat: 48.939829, lng: 18.479078 },
    { lat: 48.940411, lng: 18.479207 },
    { lat: 48.940837, lng: 18.479377 },
    { lat: 48.940983, lng: 18.47936 },
    { lat: 48.941149, lng: 18.479341 },
    { lat: 48.941321, lng: 18.479251 },
    { lat: 48.94147, lng: 18.479022 },
    { lat: 48.941377, lng: 18.478865 },
    { lat: 48.941235, lng: 18.478384 },
    { lat: 48.940963, lng: 18.478203 },
    { lat: 48.94089, lng: 18.478015 },
    { lat: 48.940753, lng: 18.477744 },
    { lat: 48.940224, lng: 18.47694 },
    { lat: 48.940197, lng: 18.476642 },
    { lat: 48.940321, lng: 18.476326 },
    { lat: 48.940552, lng: 18.475965 },
    { lat: 48.940855, lng: 18.475363 },
    { lat: 48.940951, lng: 18.475164 },
    { lat: 48.941103, lng: 18.47445 },
    { lat: 48.941217, lng: 18.473941 },
    { lat: 48.941257, lng: 18.473835 },
    { lat: 48.941281, lng: 18.473775 },
    { lat: 48.941331, lng: 18.47378 },
    { lat: 48.941646, lng: 18.473694 },
    { lat: 48.942579, lng: 18.473174 },
    { lat: 48.942916, lng: 18.473008 },
    { lat: 48.943186, lng: 18.472783 },
    { lat: 48.943571, lng: 18.472021 },
    { lat: 48.943894, lng: 18.471321 },
    { lat: 48.944235, lng: 18.471025 },
    { lat: 48.944442, lng: 18.470757 },
    { lat: 48.945257, lng: 18.469651 },
    { lat: 48.945428, lng: 18.469404 },
    { lat: 48.945722, lng: 18.469114 },
    { lat: 48.946261, lng: 18.468527 },
    { lat: 48.946895, lng: 18.46761 },
    { lat: 48.947282, lng: 18.467135 },
    { lat: 48.947447, lng: 18.466638 },
    { lat: 48.947736, lng: 18.466237 },
    { lat: 48.948036, lng: 18.46594 },
    { lat: 48.948559, lng: 18.46555 },
    { lat: 48.949042, lng: 18.46511 },
    { lat: 48.950029, lng: 18.464238 },
    { lat: 48.950263, lng: 18.464184 },
    { lat: 48.950601, lng: 18.463922 },
    { lat: 48.951002, lng: 18.463878 },
    { lat: 48.951602, lng: 18.464051 },
    { lat: 48.95188, lng: 18.463884 },
    { lat: 48.952495, lng: 18.463899 },
    { lat: 48.952696, lng: 18.463855 },
    { lat: 48.95292, lng: 18.463792 },
    { lat: 48.953158, lng: 18.463747 },
    { lat: 48.953661, lng: 18.463439 },
    { lat: 48.95385, lng: 18.463338 },
    { lat: 48.954125, lng: 18.463037 },
    { lat: 48.954199, lng: 18.462987 },
    { lat: 48.954348, lng: 18.462887 },
    { lat: 48.954569, lng: 18.46314 },
    { lat: 48.954925, lng: 18.463108 },
    { lat: 48.955203, lng: 18.463475 },
    { lat: 48.955632, lng: 18.463733 },
    { lat: 48.955799, lng: 18.463954 },
    { lat: 48.955922, lng: 18.464203 },
    { lat: 48.95636, lng: 18.464963 },
    { lat: 48.957961, lng: 18.463892 },
    { lat: 48.95879, lng: 18.463365 },
    { lat: 48.960969, lng: 18.462157 },
    { lat: 48.961976, lng: 18.46061 },
    { lat: 48.963737, lng: 18.459846 },
    { lat: 48.965685, lng: 18.459535 },
    { lat: 48.966022, lng: 18.459679 },
    { lat: 48.967742, lng: 18.46041 },
    { lat: 48.968044, lng: 18.460483 },
    { lat: 48.969064, lng: 18.461017 },
    { lat: 48.970663, lng: 18.461182 },
    { lat: 48.971068, lng: 18.46122 },
    { lat: 48.973271, lng: 18.460211 },
    { lat: 48.973342, lng: 18.460179 },
    { lat: 48.973438, lng: 18.460135 },
    { lat: 48.973617, lng: 18.460025 },
    { lat: 48.974656, lng: 18.460095 },
    { lat: 48.975618, lng: 18.460622 },
    { lat: 48.975984, lng: 18.46062 },
    { lat: 48.975954, lng: 18.459966 },
    { lat: 48.976054, lng: 18.459467 },
    { lat: 48.976189, lng: 18.458381 },
    { lat: 48.976243, lng: 18.458092 },
    { lat: 48.976341, lng: 18.457793 },
    { lat: 48.976637, lng: 18.457314 },
    { lat: 48.976737, lng: 18.45707 },
    { lat: 48.976903, lng: 18.456585 },
    { lat: 48.977426, lng: 18.455355 },
    { lat: 48.977737, lng: 18.454757 },
    { lat: 48.977769, lng: 18.454425 },
    { lat: 48.978148, lng: 18.452815 },
    { lat: 48.978695, lng: 18.452054 },
    { lat: 48.978907, lng: 18.451446 },
    { lat: 48.979247, lng: 18.451147 },
    { lat: 48.979824, lng: 18.450524 },
    { lat: 48.980482, lng: 18.449755 },
    { lat: 48.981162, lng: 18.449035 },
    { lat: 48.981418, lng: 18.448855 },
    { lat: 48.982156, lng: 18.448436 },
    { lat: 48.98254, lng: 18.448298 },
    { lat: 48.98285, lng: 18.448086 },
    { lat: 48.983037, lng: 18.44781 },
    { lat: 48.983129, lng: 18.447351 },
    { lat: 48.98327, lng: 18.446883 },
    { lat: 48.98346, lng: 18.446143 },
    { lat: 48.983547, lng: 18.445415 },
    { lat: 48.983728, lng: 18.44458 },
    { lat: 48.983858, lng: 18.443718 },
    { lat: 48.983952, lng: 18.442923 },
    { lat: 48.984024, lng: 18.442165 },
    { lat: 48.984315, lng: 18.440631 },
    { lat: 48.982306, lng: 18.437624 },
    { lat: 48.98227, lng: 18.437579 },
    { lat: 48.982226, lng: 18.437509 },
  ],
  HornáPoruba: [
    { lat: 48.923227, lng: 18.327083 },
    { lat: 48.924165, lng: 18.328458 },
    { lat: 48.924539, lng: 18.329007 },
    { lat: 48.924772, lng: 18.329349 },
    { lat: 48.924908, lng: 18.329376 },
    { lat: 48.924962, lng: 18.329387 },
    { lat: 48.92529, lng: 18.329453 },
    { lat: 48.925695, lng: 18.329788 },
    { lat: 48.926036, lng: 18.329879 },
    { lat: 48.926289, lng: 18.32997 },
    { lat: 48.92652, lng: 18.329924 },
    { lat: 48.926714, lng: 18.329765 },
    { lat: 48.92688, lng: 18.3298 },
    { lat: 48.926949, lng: 18.329945 },
    { lat: 48.927008, lng: 18.330097 },
    { lat: 48.92723, lng: 18.330074 },
    { lat: 48.927334, lng: 18.330017 },
    { lat: 48.927511, lng: 18.329829 },
    { lat: 48.927953, lng: 18.329857 },
    { lat: 48.928403, lng: 18.330056 },
    { lat: 48.928798, lng: 18.329982 },
    { lat: 48.929237, lng: 18.330029 },
    { lat: 48.92956, lng: 18.330258 },
    { lat: 48.92997, lng: 18.330531 },
    { lat: 48.930134, lng: 18.33086 },
    { lat: 48.930321, lng: 18.331204 },
    { lat: 48.930827, lng: 18.331871 },
    { lat: 48.930981, lng: 18.332077 },
    { lat: 48.931061, lng: 18.332277 },
    { lat: 48.931047, lng: 18.333531 },
    { lat: 48.9312412, lng: 18.333984 },
    { lat: 48.931445, lng: 18.334193 },
    { lat: 48.931725, lng: 18.335462 },
    { lat: 48.9320567, lng: 18.3349175 },
    { lat: 48.9326312, lng: 18.3347037 },
    { lat: 48.9329158, lng: 18.334424 },
    { lat: 48.9338153, lng: 18.3342298 },
    { lat: 48.9344414, lng: 18.3341095 },
    { lat: 48.9348623, lng: 18.3339499 },
    { lat: 48.9360928, lng: 18.3329393 },
    { lat: 48.9364785, lng: 18.3324308 },
    { lat: 48.937494, lng: 18.330886 },
    { lat: 48.9392006, lng: 18.3263384 },
    { lat: 48.9417939, lng: 18.3264112 },
    { lat: 48.9437767, lng: 18.324954 },
    { lat: 48.9442647, lng: 18.3251855 },
    { lat: 48.9446367, lng: 18.3250404 },
    { lat: 48.9470579, lng: 18.3244779 },
    { lat: 48.9483051, lng: 18.3242274 },
    { lat: 48.9491419, lng: 18.3238182 },
    { lat: 48.9491847, lng: 18.3237973 },
    { lat: 48.9496478, lng: 18.323388 },
    { lat: 48.9497482, lng: 18.3228851 },
    { lat: 48.9497669, lng: 18.3225528 },
    { lat: 48.9499043, lng: 18.3214262 },
    { lat: 48.9500089, lng: 18.3203566 },
    { lat: 48.949904, lng: 18.3197725 },
    { lat: 48.9500688, lng: 18.3190533 },
    { lat: 48.9501636, lng: 18.3184897 },
    { lat: 48.9504789, lng: 18.3170162 },
    { lat: 48.9502104, lng: 18.316434 },
    { lat: 48.9503701, lng: 18.315662 },
    { lat: 48.9503863, lng: 18.3155899 },
    { lat: 48.9514307, lng: 18.3154649 },
    { lat: 48.9525165, lng: 18.3152929 },
    { lat: 48.9527711, lng: 18.3152828 },
    { lat: 48.9534908, lng: 18.3150018 },
    { lat: 48.9542002, lng: 18.3149959 },
    { lat: 48.9545085, lng: 18.3139005 },
    { lat: 48.9545394, lng: 18.3135253 },
    { lat: 48.9545635, lng: 18.3132339 },
    { lat: 48.9545893, lng: 18.312929 },
    { lat: 48.9545166, lng: 18.3127417 },
    { lat: 48.954532, lng: 18.3123301 },
    { lat: 48.9546959, lng: 18.3113945 },
    { lat: 48.9549091, lng: 18.3093906 },
    { lat: 48.955194, lng: 18.3089795 },
    { lat: 48.9549384, lng: 18.3080244 },
    { lat: 48.9551162, lng: 18.3079582 },
    { lat: 48.9551667, lng: 18.3076113 },
    { lat: 48.9554228, lng: 18.3071721 },
    { lat: 48.9550102, lng: 18.3063357 },
    { lat: 48.9552601, lng: 18.3061058 },
    { lat: 48.955466, lng: 18.3060166 },
    { lat: 48.9559321, lng: 18.3055357 },
    { lat: 48.9562563, lng: 18.3051432 },
    { lat: 48.9564928, lng: 18.3046371 },
    { lat: 48.9565953, lng: 18.3043531 },
    { lat: 48.9568526, lng: 18.3040738 },
    { lat: 48.9569902, lng: 18.3039723 },
    { lat: 48.9576075, lng: 18.3032555 },
    { lat: 48.9578485, lng: 18.3030688 },
    { lat: 48.9583437, lng: 18.302663 },
    { lat: 48.9587936, lng: 18.3023179 },
    { lat: 48.958963, lng: 18.302184 },
    { lat: 48.9595496, lng: 18.3013613 },
    { lat: 48.95966, lng: 18.3008752 },
    { lat: 48.9598105, lng: 18.3006043 },
    { lat: 48.9600143, lng: 18.3000971 },
    { lat: 48.9601505, lng: 18.2999922 },
    { lat: 48.9608878, lng: 18.2998407 },
    { lat: 48.9611705, lng: 18.2997303 },
    { lat: 48.9611635, lng: 18.2996903 },
    { lat: 48.960853, lng: 18.298502 },
    { lat: 48.9608642, lng: 18.2980801 },
    { lat: 48.9607053, lng: 18.2975633 },
    { lat: 48.9606845, lng: 18.297496 },
    { lat: 48.9607864, lng: 18.2972415 },
    { lat: 48.9608362, lng: 18.2964849 },
    { lat: 48.9607223, lng: 18.2959454 },
    { lat: 48.96076, lng: 18.2954533 },
    { lat: 48.9605668, lng: 18.29497 },
    { lat: 48.9607007, lng: 18.29474 },
    { lat: 48.9612882, lng: 18.2940371 },
    { lat: 48.9618764, lng: 18.2926245 },
    { lat: 48.961892, lng: 18.2922214 },
    { lat: 48.9617414, lng: 18.2911542 },
    { lat: 48.9622855, lng: 18.2904988 },
    { lat: 48.9626871, lng: 18.2903747 },
    { lat: 48.9628213, lng: 18.2895562 },
    { lat: 48.9630911, lng: 18.2884194 },
    { lat: 48.9632553, lng: 18.2883112 },
    { lat: 48.9633068, lng: 18.2883034 },
    { lat: 48.9634622, lng: 18.2881194 },
    { lat: 48.9639283, lng: 18.2876817 },
    { lat: 48.9639843, lng: 18.287636 },
    { lat: 48.9641205, lng: 18.2881252 },
    { lat: 48.9641521, lng: 18.2883682 },
    { lat: 48.9642151, lng: 18.2885657 },
    { lat: 48.9643008, lng: 18.2886852 },
    { lat: 48.964486, lng: 18.2887455 },
    { lat: 48.9647425, lng: 18.2887541 },
    { lat: 48.9650127, lng: 18.2888238 },
    { lat: 48.9652033, lng: 18.2890431 },
    { lat: 48.9656135, lng: 18.2878751 },
    { lat: 48.9657019, lng: 18.2880198 },
    { lat: 48.9666864, lng: 18.2860961 },
    { lat: 48.967024, lng: 18.2856604 },
    { lat: 48.9670672, lng: 18.2856048 },
    { lat: 48.9673616, lng: 18.2852243 },
    { lat: 48.9675318, lng: 18.2847008 },
    { lat: 48.9671707, lng: 18.2843812 },
    { lat: 48.9669621, lng: 18.283706 },
    { lat: 48.9672564, lng: 18.283082 },
    { lat: 48.9675327, lng: 18.2827206 },
    { lat: 48.967889, lng: 18.2822608 },
    { lat: 48.9683306, lng: 18.2817881 },
    { lat: 48.9685644, lng: 18.2815745 },
    { lat: 48.9687982, lng: 18.2813393 },
    { lat: 48.9691223, lng: 18.2810048 },
    { lat: 48.9697916, lng: 18.280628 },
    { lat: 48.9701152, lng: 18.2806842 },
    { lat: 48.9704159, lng: 18.2806752 },
    { lat: 48.9710469, lng: 18.2805214 },
    { lat: 48.9711223, lng: 18.2804793 },
    { lat: 48.9714315, lng: 18.280319 },
    { lat: 48.9717034, lng: 18.2800799 },
    { lat: 48.9718561, lng: 18.2801473 },
    { lat: 48.972247, lng: 18.2801947 },
    { lat: 48.9727651, lng: 18.2796987 },
    { lat: 48.972998, lng: 18.2792309 },
    { lat: 48.9732792, lng: 18.2788771 },
    { lat: 48.9735374, lng: 18.2788735 },
    { lat: 48.9737532, lng: 18.2787976 },
    { lat: 48.9741211, lng: 18.2782408 },
    { lat: 48.9746436, lng: 18.2776237 },
    { lat: 48.9748538, lng: 18.2775303 },
    { lat: 48.9749695, lng: 18.2773617 },
    { lat: 48.9750572, lng: 18.2771576 },
    { lat: 48.975208, lng: 18.2762718 },
    { lat: 48.9750165, lng: 18.276017 },
    { lat: 48.9749817, lng: 18.2759817 },
    { lat: 48.9749409, lng: 18.2759382 },
    { lat: 48.9749002, lng: 18.2758961 },
    { lat: 48.9749707, lng: 18.2756671 },
    { lat: 48.9749946, lng: 18.275451 },
    { lat: 48.9749581, lng: 18.2751849 },
    { lat: 48.9748438, lng: 18.2750002 },
    { lat: 48.9748436, lng: 18.2745969 },
    { lat: 48.974914, lng: 18.274165 },
    { lat: 48.9747394, lng: 18.2744097 },
    { lat: 48.9746553, lng: 18.2744983 },
    { lat: 48.9746383, lng: 18.2747566 },
    { lat: 48.9745323, lng: 18.2750584 },
    { lat: 48.9746166, lng: 18.2753911 },
    { lat: 48.9745709, lng: 18.2756609 },
    { lat: 48.9745008, lng: 18.2758182 },
    { lat: 48.9742585, lng: 18.2762009 },
    { lat: 48.9741655, lng: 18.2766118 },
    { lat: 48.9741434, lng: 18.2766598 },
    { lat: 48.9739356, lng: 18.2770542 },
    { lat: 48.9736825, lng: 18.2772684 },
    { lat: 48.9736912, lng: 18.2775402 },
    { lat: 48.9731233, lng: 18.2782541 },
    { lat: 48.9729206, lng: 18.2781196 },
    { lat: 48.9724051, lng: 18.2782217 },
    { lat: 48.9721331, lng: 18.2782186 },
    { lat: 48.972078, lng: 18.2782439 },
    { lat: 48.9718988, lng: 18.2783255 },
    { lat: 48.9718415, lng: 18.2783513 },
    { lat: 48.9716094, lng: 18.2784575 },
    { lat: 48.9703538, lng: 18.2784179 },
    { lat: 48.9690392, lng: 18.2789542 },
    { lat: 48.9684388, lng: 18.2790103 },
    { lat: 48.9681584, lng: 18.2791399 },
    { lat: 48.9674309, lng: 18.2790433 },
    { lat: 48.9662036, lng: 18.2797663 },
    { lat: 48.9661137, lng: 18.279434 },
    { lat: 48.9653682, lng: 18.2796388 },
    { lat: 48.9639982, lng: 18.2780169 },
    { lat: 48.9641233, lng: 18.2776955 },
    { lat: 48.9636344, lng: 18.2773302 },
    { lat: 48.9627827, lng: 18.2768329 },
    { lat: 48.9624293, lng: 18.2776077 },
    { lat: 48.9622566, lng: 18.2779861 },
    { lat: 48.9618605, lng: 18.2779024 },
    { lat: 48.9618239, lng: 18.2778953 },
    { lat: 48.9617623, lng: 18.2778818 },
    { lat: 48.9616104, lng: 18.2778499 },
    { lat: 48.9611696, lng: 18.2799914 },
    { lat: 48.9604994, lng: 18.2811085 },
    { lat: 48.9600673, lng: 18.2803498 },
    { lat: 48.959925, lng: 18.2784913 },
    { lat: 48.9596838, lng: 18.2773207 },
    { lat: 48.9595439, lng: 18.2760239 },
    { lat: 48.9590164, lng: 18.2758327 },
    { lat: 48.9592431, lng: 18.2779857 },
    { lat: 48.9583011, lng: 18.2778263 },
    { lat: 48.957523, lng: 18.2778562 },
    { lat: 48.9573171, lng: 18.2778642 },
    { lat: 48.9572395, lng: 18.2775295 },
    { lat: 48.9572986, lng: 18.2769402 },
    { lat: 48.957307, lng: 18.2768875 },
    { lat: 48.9573166, lng: 18.2768192 },
    { lat: 48.9573401, lng: 18.2766551 },
    { lat: 48.956936, lng: 18.2767317 },
    { lat: 48.956116, lng: 18.276198 },
    { lat: 48.9551358, lng: 18.2763034 },
    { lat: 48.9544759, lng: 18.2769833 },
    { lat: 48.9541114, lng: 18.2771622 },
    { lat: 48.9532233, lng: 18.2758604 },
    { lat: 48.9525041, lng: 18.2759316 },
    { lat: 48.9522815, lng: 18.275947 },
    { lat: 48.9511571, lng: 18.2757965 },
    { lat: 48.9508175, lng: 18.2759564 },
    { lat: 48.9499491, lng: 18.2766212 },
    { lat: 48.9493044, lng: 18.2770917 },
    { lat: 48.9480027, lng: 18.2774349 },
    { lat: 48.9472741, lng: 18.2773192 },
    { lat: 48.9465956, lng: 18.2772131 },
    { lat: 48.945767, lng: 18.2772442 },
    { lat: 48.9448993, lng: 18.2782617 },
    { lat: 48.9445131, lng: 18.2785938 },
    { lat: 48.9438346, lng: 18.2784867 },
    { lat: 48.9435765, lng: 18.2787116 },
    { lat: 48.9417737, lng: 18.278236 },
    { lat: 48.9416488, lng: 18.2789789 },
    { lat: 48.9408084, lng: 18.2798596 },
    { lat: 48.940243, lng: 18.2806141 },
    { lat: 48.9397653, lng: 18.281883 },
    { lat: 48.9394285, lng: 18.2826917 },
    { lat: 48.9390172, lng: 18.2834401 },
    { lat: 48.9387012, lng: 18.2837583 },
    { lat: 48.9381024, lng: 18.2842017 },
    { lat: 48.9374967, lng: 18.2845634 },
    { lat: 48.9371001, lng: 18.2850326 },
    { lat: 48.936274, lng: 18.2849672 },
    { lat: 48.9361719, lng: 18.2849591 },
    { lat: 48.93588, lng: 18.2848245 },
    { lat: 48.9357408, lng: 18.2847967 },
    { lat: 48.935261, lng: 18.2845125 },
    { lat: 48.9347082, lng: 18.2844543 },
    { lat: 48.9346048, lng: 18.2844606 },
    { lat: 48.9345311, lng: 18.2844745 },
    { lat: 48.9340227, lng: 18.2845419 },
    { lat: 48.9336604, lng: 18.2843605 },
    { lat: 48.9327353, lng: 18.2836623 },
    { lat: 48.932508, lng: 18.2833607 },
    { lat: 48.9324332, lng: 18.2831763 },
    { lat: 48.9323925, lng: 18.2827539 },
    { lat: 48.9324308, lng: 18.2824347 },
    { lat: 48.9323366, lng: 18.2815652 },
    { lat: 48.9322954, lng: 18.2815777 },
    { lat: 48.9319738, lng: 18.2808782 },
    { lat: 48.930596, lng: 18.2797767 },
    { lat: 48.9299364, lng: 18.2793252 },
    { lat: 48.9294739, lng: 18.2790083 },
    { lat: 48.9294244, lng: 18.2789308 },
    { lat: 48.9288522, lng: 18.2778114 },
    { lat: 48.9284154, lng: 18.2776701 },
    { lat: 48.928, lng: 18.2781649 },
    { lat: 48.9275293, lng: 18.2785781 },
    { lat: 48.92722, lng: 18.2786548 },
    { lat: 48.9267786, lng: 18.2785322 },
    { lat: 48.9260902, lng: 18.2784102 },
    { lat: 48.9254918, lng: 18.2783612 },
    { lat: 48.924891, lng: 18.278471 },
    { lat: 48.924873, lng: 18.279006 },
    { lat: 48.924814, lng: 18.280452 },
    { lat: 48.924974, lng: 18.282104 },
    { lat: 48.92502, lng: 18.283106 },
    { lat: 48.925036, lng: 18.28336 },
    { lat: 48.924591, lng: 18.285105 },
    { lat: 48.923858, lng: 18.285355 },
    { lat: 48.923873, lng: 18.286017 },
    { lat: 48.923664, lng: 18.286194 },
    { lat: 48.924026, lng: 18.287051 },
    { lat: 48.924258, lng: 18.287578 },
    { lat: 48.924284, lng: 18.287746 },
    { lat: 48.924226, lng: 18.287833 },
    { lat: 48.924306, lng: 18.288299 },
    { lat: 48.924138, lng: 18.28868 },
    { lat: 48.924284, lng: 18.28926 },
    { lat: 48.924418, lng: 18.290333 },
    { lat: 48.924652, lng: 18.291218 },
    { lat: 48.924537, lng: 18.291556 },
    { lat: 48.924435, lng: 18.291739 },
    { lat: 48.924251, lng: 18.291813 },
    { lat: 48.924047, lng: 18.291844 },
    { lat: 48.92398, lng: 18.291827 },
    { lat: 48.923692, lng: 18.292013 },
    { lat: 48.923589, lng: 18.292194 },
    { lat: 48.923381, lng: 18.292556 },
    { lat: 48.923516, lng: 18.292554 },
    { lat: 48.923751, lng: 18.292885 },
    { lat: 48.923867, lng: 18.293082 },
    { lat: 48.923761, lng: 18.2933 },
    { lat: 48.923932, lng: 18.293795 },
    { lat: 48.924128, lng: 18.294165 },
    { lat: 48.924499, lng: 18.2943 },
    { lat: 48.925049, lng: 18.295246 },
    { lat: 48.924803, lng: 18.295941 },
    { lat: 48.924748, lng: 18.296066 },
    { lat: 48.924722, lng: 18.296257 },
    { lat: 48.924677, lng: 18.296586 },
    { lat: 48.924664, lng: 18.296682 },
    { lat: 48.924392, lng: 18.296747 },
    { lat: 48.92456, lng: 18.297447 },
    { lat: 48.924607, lng: 18.297798 },
    { lat: 48.924647, lng: 18.298042 },
    { lat: 48.924883, lng: 18.299233 },
    { lat: 48.924902, lng: 18.299535 },
    { lat: 48.924909, lng: 18.299646 },
    { lat: 48.924913, lng: 18.299711 },
    { lat: 48.924919, lng: 18.299808 },
    { lat: 48.924936, lng: 18.30008 },
    { lat: 48.924767, lng: 18.300411 },
    { lat: 48.924762, lng: 18.300866 },
    { lat: 48.924482, lng: 18.303669 },
    { lat: 48.924497, lng: 18.304261 },
    { lat: 48.924502, lng: 18.304756 },
    { lat: 48.923677, lng: 18.306112 },
    { lat: 48.923928, lng: 18.30742 },
    { lat: 48.923577, lng: 18.307923 },
    { lat: 48.923311, lng: 18.308299 },
    { lat: 48.923335, lng: 18.309235 },
    { lat: 48.923343, lng: 18.309583 },
    { lat: 48.92352, lng: 18.310218 },
    { lat: 48.923576, lng: 18.311058 },
    { lat: 48.923637, lng: 18.311616 },
    { lat: 48.923754, lng: 18.311823 },
    { lat: 48.923832, lng: 18.311956 },
    { lat: 48.923703, lng: 18.312947 },
    { lat: 48.923694, lng: 18.313015 },
    { lat: 48.923665, lng: 18.313116 },
    { lat: 48.923641, lng: 18.313205 },
    { lat: 48.923697, lng: 18.313438 },
    { lat: 48.923721, lng: 18.313563 },
    { lat: 48.923794, lng: 18.314057 },
    { lat: 48.923656, lng: 18.314119 },
    { lat: 48.923661, lng: 18.314158 },
    { lat: 48.923785, lng: 18.315328 },
    { lat: 48.923754, lng: 18.315397 },
    { lat: 48.923704, lng: 18.31551 },
    { lat: 48.923689, lng: 18.315544 },
    { lat: 48.923627, lng: 18.316 },
    { lat: 48.923637, lng: 18.316323 },
    { lat: 48.923721, lng: 18.316711 },
    { lat: 48.923791, lng: 18.317411 },
    { lat: 48.923706, lng: 18.31739 },
    { lat: 48.923589, lng: 18.318031 },
    { lat: 48.923561, lng: 18.318257 },
    { lat: 48.923555, lng: 18.318303 },
    { lat: 48.923511, lng: 18.31875 },
    { lat: 48.923886, lng: 18.319416 },
    { lat: 48.923917, lng: 18.319473 },
    { lat: 48.923961, lng: 18.31955 },
    { lat: 48.924043, lng: 18.319692 },
    { lat: 48.924631, lng: 18.32121 },
    { lat: 48.924482, lng: 18.32181 },
    { lat: 48.924465, lng: 18.321878 },
    { lat: 48.924535, lng: 18.322739 },
    { lat: 48.924561, lng: 18.323052 },
    { lat: 48.924647, lng: 18.323534 },
    { lat: 48.924587, lng: 18.323886 },
    { lat: 48.924587, lng: 18.323916 },
    { lat: 48.924572, lng: 18.324039 },
    { lat: 48.924525, lng: 18.32434 },
    { lat: 48.924283, lng: 18.325118 },
    { lat: 48.923227, lng: 18.327083 },
  ],
  Košeca: [
    { lat: 49.0137696, lng: 18.2951698 },
    { lat: 49.0136978, lng: 18.2946766 },
    { lat: 49.0138611, lng: 18.291932 },
    { lat: 49.0141045, lng: 18.2905841 },
    { lat: 49.0143027, lng: 18.2897966 },
    { lat: 49.0149485, lng: 18.2879466 },
    { lat: 49.0153913, lng: 18.2866736 },
    { lat: 49.015703, lng: 18.28618 },
    { lat: 49.0163593, lng: 18.285657 },
    { lat: 49.0170886, lng: 18.2856859 },
    { lat: 49.0177902, lng: 18.2851602 },
    { lat: 49.0186649, lng: 18.2846855 },
    { lat: 49.0186396, lng: 18.2818786 },
    { lat: 49.0187047, lng: 18.2812737 },
    { lat: 49.019535, lng: 18.2819145 },
    { lat: 49.0200429, lng: 18.2822371 },
    { lat: 49.0202314, lng: 18.2825565 },
    { lat: 49.020411, lng: 18.2828609 },
    { lat: 49.0214929, lng: 18.2833183 },
    { lat: 49.0218661, lng: 18.2834752 },
    { lat: 49.0219343, lng: 18.2833653 },
    { lat: 49.0229592, lng: 18.2817321 },
    { lat: 49.0230477, lng: 18.2815832 },
    { lat: 49.0234095, lng: 18.2809766 },
    { lat: 49.0235024, lng: 18.2806837 },
    { lat: 49.0235343, lng: 18.2804484 },
    { lat: 49.0235199, lng: 18.2802529 },
    { lat: 49.0235128, lng: 18.2801899 },
    { lat: 49.0234991, lng: 18.280064 },
    { lat: 49.0234963, lng: 18.280036 },
    { lat: 49.0234887, lng: 18.2799583 },
    { lat: 49.0235672, lng: 18.2798513 },
    { lat: 49.0236669, lng: 18.2795611 },
    { lat: 49.0236821, lng: 18.2795113 },
    { lat: 49.0237016, lng: 18.2794552 },
    { lat: 49.0238029, lng: 18.2792306 },
    { lat: 49.0238259, lng: 18.2791773 },
    { lat: 49.0238986, lng: 18.2790294 },
    { lat: 49.0235177, lng: 18.2786761 },
    { lat: 49.023457, lng: 18.2786197 },
    { lat: 49.0230268, lng: 18.278156 },
    { lat: 49.0229913, lng: 18.2781474 },
    { lat: 49.023016, lng: 18.2779435 },
    { lat: 49.023022, lng: 18.2778906 },
    { lat: 49.0230275, lng: 18.2778412 },
    { lat: 49.0230333, lng: 18.2777892 },
    { lat: 49.0230583, lng: 18.2775753 },
    { lat: 49.0230836, lng: 18.2775418 },
    { lat: 49.0231056, lng: 18.2775123 },
    { lat: 49.0233807, lng: 18.2771507 },
    { lat: 49.0235645, lng: 18.276889 },
    { lat: 49.0238307, lng: 18.2765458 },
    { lat: 49.0239374, lng: 18.2764172 },
    { lat: 49.0239671, lng: 18.2764068 },
    { lat: 49.024055, lng: 18.2763026 },
    { lat: 49.0241183, lng: 18.2762264 },
    { lat: 49.0241965, lng: 18.2761324 },
    { lat: 49.0242441, lng: 18.2760762 },
    { lat: 49.0243445, lng: 18.2759563 },
    { lat: 49.024392, lng: 18.2758987 },
    { lat: 49.0244364, lng: 18.2758462 },
    { lat: 49.0244767, lng: 18.2757981 },
    { lat: 49.0245537, lng: 18.2757054 },
    { lat: 49.02467, lng: 18.2755676 },
    { lat: 49.0247513, lng: 18.27547 },
    { lat: 49.0249225, lng: 18.2752653 },
    { lat: 49.0249785, lng: 18.2751983 },
    { lat: 49.0250802, lng: 18.2750772 },
    { lat: 49.0252123, lng: 18.2749219 },
    { lat: 49.0253181, lng: 18.2747981 },
    { lat: 49.0253191, lng: 18.2747958 },
    { lat: 49.0268213, lng: 18.2717499 },
    { lat: 49.0283011, lng: 18.2687477 },
    { lat: 49.0284052, lng: 18.2685356 },
    { lat: 49.0284549, lng: 18.2684364 },
    { lat: 49.0284678, lng: 18.2684032 },
    { lat: 49.0284755, lng: 18.2683833 },
    { lat: 49.0284985, lng: 18.2683293 },
    { lat: 49.0285162, lng: 18.2682831 },
    { lat: 49.028528, lng: 18.2682542 },
    { lat: 49.0287229, lng: 18.2678289 },
    { lat: 49.028739, lng: 18.2677966 },
    { lat: 49.028758, lng: 18.2677514 },
    { lat: 49.0287584, lng: 18.267679 },
    { lat: 49.0287836, lng: 18.2676236 },
    { lat: 49.0272257, lng: 18.2660539 },
    { lat: 49.0276355, lng: 18.2657004 },
    { lat: 49.0279894, lng: 18.2653945 },
    { lat: 49.0292706, lng: 18.2642884 },
    { lat: 49.0293127, lng: 18.2642545 },
    { lat: 49.0293515, lng: 18.2642224 },
    { lat: 49.0294345, lng: 18.2641559 },
    { lat: 49.0299325, lng: 18.2637441 },
    { lat: 49.0300157, lng: 18.2636764 },
    { lat: 49.0301106, lng: 18.2635985 },
    { lat: 49.0297514, lng: 18.2623788 },
    { lat: 49.0294186, lng: 18.2616188 },
    { lat: 49.0291763, lng: 18.2611698 },
    { lat: 49.0289178, lng: 18.2606874 },
    { lat: 49.0285959, lng: 18.2601629 },
    { lat: 49.0282736, lng: 18.2597118 },
    { lat: 49.0279125, lng: 18.2592723 },
    { lat: 49.0272339, lng: 18.2586457 },
    { lat: 49.027197, lng: 18.2586117 },
    { lat: 49.0266406, lng: 18.2582093 },
    { lat: 49.0262566, lng: 18.2580249 },
    { lat: 49.0257394, lng: 18.2577763 },
    { lat: 49.0250522, lng: 18.2575787 },
    { lat: 49.0245213, lng: 18.2574095 },
    { lat: 49.0241956, lng: 18.2574144 },
    { lat: 49.023765, lng: 18.2574216 },
    { lat: 49.0238875, lng: 18.2572715 },
    { lat: 49.0242314, lng: 18.2568493 },
    { lat: 49.0245984, lng: 18.2563992 },
    { lat: 49.0255249, lng: 18.2552855 },
    { lat: 49.0243098, lng: 18.2532773 },
    { lat: 49.0242066, lng: 18.2531078 },
    { lat: 49.024117, lng: 18.2529628 },
    { lat: 49.0238932, lng: 18.2525971 },
    { lat: 49.0236293, lng: 18.2521665 },
    { lat: 49.0232702, lng: 18.2515805 },
    { lat: 49.0231983, lng: 18.2514633 },
    { lat: 49.0229891, lng: 18.2511173 },
    { lat: 49.0226608, lng: 18.2506027 },
    { lat: 49.0223252, lng: 18.2500744 },
    { lat: 49.0220577, lng: 18.2496576 },
    { lat: 49.0216914, lng: 18.2490837 },
    { lat: 49.0207663, lng: 18.2476765 },
    { lat: 49.0206638, lng: 18.2475203 },
    { lat: 49.0205194, lng: 18.2473005 },
    { lat: 49.0203894, lng: 18.2471021 },
    { lat: 49.0202921, lng: 18.2469536 },
    { lat: 49.0196582, lng: 18.2459246 },
    { lat: 49.0186587, lng: 18.2442983 },
    { lat: 49.0177251, lng: 18.2427392 },
    { lat: 49.0167286, lng: 18.2411245 },
    { lat: 49.0159835, lng: 18.2399294 },
    { lat: 49.0158284, lng: 18.2396787 },
    { lat: 49.0155729, lng: 18.2392522 },
    { lat: 49.0148414, lng: 18.2405172 },
    { lat: 49.0148167, lng: 18.2405618 },
    { lat: 49.0134171, lng: 18.2393983 },
    { lat: 49.0132644, lng: 18.2395298 },
    { lat: 49.0132052, lng: 18.2395809 },
    { lat: 49.0126593, lng: 18.24006 },
    { lat: 49.0125706, lng: 18.240127 },
    { lat: 49.012536, lng: 18.2401563 },
    { lat: 49.0124896, lng: 18.2401925 },
    { lat: 49.0113959, lng: 18.2410393 },
    { lat: 49.0110897, lng: 18.2412691 },
    { lat: 49.0109375, lng: 18.2413866 },
    { lat: 49.0107081, lng: 18.2415636 },
    { lat: 49.0105405, lng: 18.2416924 },
    { lat: 49.010322, lng: 18.2418587 },
    { lat: 49.010307, lng: 18.2418706 },
    { lat: 49.0102886, lng: 18.2418845 },
    { lat: 49.010268, lng: 18.2419005 },
    { lat: 49.0101632, lng: 18.24198 },
    { lat: 49.010092, lng: 18.2420189 },
    { lat: 49.0099694, lng: 18.2420856 },
    { lat: 49.0099354, lng: 18.2421034 },
    { lat: 49.0099026, lng: 18.2421214 },
    { lat: 49.0095644, lng: 18.2423052 },
    { lat: 49.0095326, lng: 18.2423221 },
    { lat: 49.0095129, lng: 18.2423328 },
    { lat: 49.0093532, lng: 18.2424196 },
    { lat: 49.0093017, lng: 18.2424472 },
    { lat: 49.0092509, lng: 18.2424851 },
    { lat: 49.0081116, lng: 18.2433251 },
    { lat: 49.0075434, lng: 18.2442469 },
    { lat: 49.0075082, lng: 18.2443038 },
    { lat: 49.0074783, lng: 18.2443527 },
    { lat: 49.0074473, lng: 18.2444031 },
    { lat: 49.0074091, lng: 18.2444653 },
    { lat: 49.0073668, lng: 18.2445341 },
    { lat: 49.0073486, lng: 18.2445637 },
    { lat: 49.0073348, lng: 18.2445861 },
    { lat: 49.0073099, lng: 18.2446253 },
    { lat: 49.007189, lng: 18.2448216 },
    { lat: 49.0071508, lng: 18.2448834 },
    { lat: 49.0068056, lng: 18.2444669 },
    { lat: 49.0068089, lng: 18.2453196 },
    { lat: 49.0066993, lng: 18.2468878 },
    { lat: 49.0065306, lng: 18.247412 },
    { lat: 49.0039776, lng: 18.2486915 },
    { lat: 49.0039545, lng: 18.2487031 },
    { lat: 49.0038756, lng: 18.2487426 },
    { lat: 49.0038459, lng: 18.2487576 },
    { lat: 49.0034229, lng: 18.2497528 },
    { lat: 49.0030426, lng: 18.250764 },
    { lat: 49.0029047, lng: 18.2510989 },
    { lat: 49.0028869, lng: 18.2511436 },
    { lat: 49.0024895, lng: 18.2521109 },
    { lat: 49.0024235, lng: 18.2522716 },
    { lat: 49.0022505, lng: 18.2526928 },
    { lat: 49.0012822, lng: 18.2550748 },
    { lat: 49.0008014, lng: 18.2560279 },
    { lat: 49.0007791, lng: 18.2560718 },
    { lat: 49.0001139, lng: 18.2571952 },
    { lat: 48.9993063, lng: 18.2582955 },
    { lat: 48.9991315, lng: 18.2585331 },
    { lat: 48.9987775, lng: 18.259016 },
    { lat: 48.998764, lng: 18.2590386 },
    { lat: 48.9986635, lng: 18.2591559 },
    { lat: 48.9985641, lng: 18.2592728 },
    { lat: 48.9985356, lng: 18.2593082 },
    { lat: 48.9985029, lng: 18.2593486 },
    { lat: 48.998466, lng: 18.2593936 },
    { lat: 48.9983847, lng: 18.2595101 },
    { lat: 48.9983074, lng: 18.2596219 },
    { lat: 48.9981538, lng: 18.2597985 },
    { lat: 48.9980379, lng: 18.2599439 },
    { lat: 48.9978527, lng: 18.2601593 },
    { lat: 48.9976995, lng: 18.2603429 },
    { lat: 48.997668, lng: 18.2602839 },
    { lat: 48.9962146, lng: 18.261853 },
    { lat: 48.9959531, lng: 18.261261 },
    { lat: 48.9957496, lng: 18.2615114 },
    { lat: 48.9957401, lng: 18.2615242 },
    { lat: 48.9954637, lng: 18.261864 },
    { lat: 48.9953846, lng: 18.2619609 },
    { lat: 48.9953392, lng: 18.2620157 },
    { lat: 48.9953277, lng: 18.2620305 },
    { lat: 48.9953055, lng: 18.2620575 },
    { lat: 48.9952654, lng: 18.2621069 },
    { lat: 48.9951569, lng: 18.2622419 },
    { lat: 48.9951164, lng: 18.2623037 },
    { lat: 48.9948148, lng: 18.2627162 },
    { lat: 48.9942534, lng: 18.2633018 },
    { lat: 48.9938796, lng: 18.263673 },
    { lat: 48.9937909, lng: 18.2637812 },
    { lat: 48.9934095, lng: 18.2641979 },
    { lat: 48.9928581, lng: 18.264724 },
    { lat: 48.9922439, lng: 18.2653919 },
    { lat: 48.9917603, lng: 18.265838 },
    { lat: 48.9914296, lng: 18.266176 },
    { lat: 48.9905069, lng: 18.2672141 },
    { lat: 48.990153, lng: 18.2675598 },
    { lat: 48.9892464, lng: 18.2680693 },
    { lat: 48.9886387, lng: 18.2684531 },
    { lat: 48.9876458, lng: 18.2692907 },
    { lat: 48.987051, lng: 18.2702644 },
    { lat: 48.9869366, lng: 18.2705348 },
    { lat: 48.9868339, lng: 18.2707554 },
    { lat: 48.9868074, lng: 18.2708045 },
    { lat: 48.986749, lng: 18.2709081 },
    { lat: 48.986623, lng: 18.2709558 },
    { lat: 48.9865715, lng: 18.2710012 },
    { lat: 48.9865294, lng: 18.2710744 },
    { lat: 48.9864269, lng: 18.2713195 },
    { lat: 48.9863527, lng: 18.2714617 },
    { lat: 48.9860192, lng: 18.2719888 },
    { lat: 48.9859377, lng: 18.2721238 },
    { lat: 48.9857289, lng: 18.2725611 },
    { lat: 48.9857061, lng: 18.2725353 },
    { lat: 48.9846265, lng: 18.271323 },
    { lat: 48.9834432, lng: 18.2698323 },
    { lat: 48.9832664, lng: 18.2703573 },
    { lat: 48.9830217, lng: 18.2707183 },
    { lat: 48.9827946, lng: 18.2713505 },
    { lat: 48.9824465, lng: 18.2716185 },
    { lat: 48.9821499, lng: 18.2718796 },
    { lat: 48.9816502, lng: 18.2727188 },
    { lat: 48.9811528, lng: 18.2733584 },
    { lat: 48.9807608, lng: 18.2743893 },
    { lat: 48.9805375, lng: 18.275227 },
    { lat: 48.9801559, lng: 18.2765218 },
    { lat: 48.9798497, lng: 18.2772905 },
    { lat: 48.9797138, lng: 18.2776412 },
    { lat: 48.979388, lng: 18.2784032 },
    { lat: 48.9785223, lng: 18.2807924 },
    { lat: 48.9781109, lng: 18.2827173 },
    { lat: 48.9777581, lng: 18.2836216 },
    { lat: 48.9781573, lng: 18.2839389 },
    { lat: 48.9779085, lng: 18.2847274 },
    { lat: 48.9795844, lng: 18.2842948 },
    { lat: 48.9812038, lng: 18.283824 },
    { lat: 48.981218, lng: 18.2834558 },
    { lat: 48.981228, lng: 18.283193 },
    { lat: 48.9813224, lng: 18.2831067 },
    { lat: 48.9813743, lng: 18.282967 },
    { lat: 48.981393, lng: 18.2826582 },
    { lat: 48.9814732, lng: 18.2820389 },
    { lat: 48.9817327, lng: 18.2818826 },
    { lat: 48.9818524, lng: 18.2811224 },
    { lat: 48.9819478, lng: 18.2810754 },
    { lat: 48.9821128, lng: 18.2803793 },
    { lat: 48.9822815, lng: 18.2801946 },
    { lat: 48.9822034, lng: 18.2804681 },
    { lat: 48.9824771, lng: 18.2803584 },
    { lat: 48.9826744, lng: 18.2797576 },
    { lat: 48.9829967, lng: 18.2787126 },
    { lat: 48.9831474, lng: 18.278046 },
    { lat: 48.9835463, lng: 18.2762816 },
    { lat: 48.9849119, lng: 18.2782651 },
    { lat: 48.9849312, lng: 18.2783262 },
    { lat: 48.9849856, lng: 18.2783884 },
    { lat: 48.985001, lng: 18.2784029 },
    { lat: 48.985367, lng: 18.27913 },
    { lat: 48.9853753, lng: 18.2791393 },
    { lat: 48.9854187, lng: 18.2791869 },
    { lat: 48.9854655, lng: 18.2792375 },
    { lat: 48.9851674, lng: 18.2798483 },
    { lat: 48.9850803, lng: 18.2802656 },
    { lat: 48.9850126, lng: 18.2814023 },
    { lat: 48.9849229, lng: 18.2823922 },
    { lat: 48.9849105, lng: 18.2825314 },
    { lat: 48.9845504, lng: 18.2834696 },
    { lat: 48.9842065, lng: 18.2845716 },
    { lat: 48.9841069, lng: 18.2852021 },
    { lat: 48.9837264, lng: 18.2860584 },
    { lat: 48.9830575, lng: 18.2877592 },
    { lat: 48.9830198, lng: 18.2878485 },
    { lat: 48.9830303, lng: 18.2878586 },
    { lat: 48.9830448, lng: 18.2878725 },
    { lat: 48.9831545, lng: 18.2879768 },
    { lat: 48.9831652, lng: 18.2879876 },
    { lat: 48.9832247, lng: 18.2880445 },
    { lat: 48.9831397, lng: 18.2882977 },
    { lat: 48.9831331, lng: 18.2883183 },
    { lat: 48.9831098, lng: 18.2883867 },
    { lat: 48.9830771, lng: 18.2884862 },
    { lat: 48.9830539, lng: 18.2885531 },
    { lat: 48.9830365, lng: 18.2886057 },
    { lat: 48.9829892, lng: 18.2887476 },
    { lat: 48.9829833, lng: 18.2887649 },
    { lat: 48.9828259, lng: 18.2892341 },
    { lat: 48.9827516, lng: 18.289456 },
    { lat: 48.9827487, lng: 18.2894632 },
    { lat: 48.982685, lng: 18.2896529 },
    { lat: 48.9826802, lng: 18.2896686 },
    { lat: 48.9826694, lng: 18.2896991 },
    { lat: 48.982597, lng: 18.2899165 },
    { lat: 48.9825942, lng: 18.2899252 },
    { lat: 48.982344, lng: 18.2906697 },
    { lat: 48.9822861, lng: 18.2908414 },
    { lat: 48.9823612, lng: 18.2918934 },
    { lat: 48.9824645, lng: 18.2927057 },
    { lat: 48.9828216, lng: 18.2942756 },
    { lat: 48.9838373, lng: 18.2969434 },
    { lat: 48.983906, lng: 18.2975617 },
    { lat: 48.9843861, lng: 18.2982112 },
    { lat: 48.9848954, lng: 18.2986025 },
    { lat: 48.9857098, lng: 18.2993609 },
    { lat: 48.9859383, lng: 18.2994712 },
    { lat: 48.9862341, lng: 18.2996307 },
    { lat: 48.9865137, lng: 18.2998095 },
    { lat: 48.9865935, lng: 18.2999259 },
    { lat: 48.9866804, lng: 18.3003274 },
    { lat: 48.9867638, lng: 18.300725 },
    { lat: 48.9868312, lng: 18.3010395 },
    { lat: 48.9869213, lng: 18.3015393 },
    { lat: 48.987098, lng: 18.3025114 },
    { lat: 48.9871224, lng: 18.3026627 },
    { lat: 48.9870287, lng: 18.3028419 },
    { lat: 48.9870046, lng: 18.303136 },
    { lat: 48.9871107, lng: 18.3044783 },
    { lat: 48.9872056, lng: 18.3049851 },
    { lat: 48.9874275, lng: 18.3050802 },
    { lat: 48.987579, lng: 18.3051625 },
    { lat: 48.9877694, lng: 18.3056001 },
    { lat: 48.9879221, lng: 18.3064693 },
    { lat: 48.9882322, lng: 18.3076289 },
    { lat: 48.9884553, lng: 18.3082063 },
    { lat: 48.9883006, lng: 18.3091633 },
    { lat: 48.9883244, lng: 18.3102508 },
    { lat: 48.9883388, lng: 18.3107253 },
    { lat: 48.988639, lng: 18.3112111 },
    { lat: 48.9888644, lng: 18.3114067 },
    { lat: 48.9889369, lng: 18.3117911 },
    { lat: 48.9888732, lng: 18.312207 },
    { lat: 48.9888932, lng: 18.3128015 },
    { lat: 48.9889729, lng: 18.3132725 },
    { lat: 48.9891516, lng: 18.3139448 },
    { lat: 48.9892999, lng: 18.3142959 },
    { lat: 48.9894577, lng: 18.3144741 },
    { lat: 48.9899236, lng: 18.3149349 },
    { lat: 48.9901129, lng: 18.3151697 },
    { lat: 48.9904408, lng: 18.3154638 },
    { lat: 48.991651, lng: 18.316082 },
    { lat: 48.9920179, lng: 18.3166455 },
    { lat: 48.9923028, lng: 18.3170806 },
    { lat: 48.9925081, lng: 18.3175832 },
    { lat: 48.9927128, lng: 18.3177718 },
    { lat: 48.9927998, lng: 18.3182549 },
    { lat: 48.9929163, lng: 18.3190247 },
    { lat: 48.9930973, lng: 18.3194733 },
    { lat: 48.9938593, lng: 18.3201268 },
    { lat: 48.9941131, lng: 18.3202635 },
    { lat: 48.9940545, lng: 18.3212106 },
    { lat: 48.9940811, lng: 18.3216422 },
    { lat: 48.9941412, lng: 18.3218496 },
    { lat: 48.9949377, lng: 18.3215103 },
    { lat: 48.9954834, lng: 18.3215441 },
    { lat: 48.9960865, lng: 18.321814 },
    { lat: 48.9965041, lng: 18.3221785 },
    { lat: 48.9969088, lng: 18.3224767 },
    { lat: 48.9971467, lng: 18.3227721 },
    { lat: 48.9974994, lng: 18.323529 },
    { lat: 48.9976993, lng: 18.3243798 },
    { lat: 48.9979139, lng: 18.3249042 },
    { lat: 48.9984095, lng: 18.3250534 },
    { lat: 48.9983981, lng: 18.3251706 },
    { lat: 48.9982359, lng: 18.3251945 },
    { lat: 48.9982479, lng: 18.3256489 },
    { lat: 48.9984375, lng: 18.3261762 },
    { lat: 48.99823, lng: 18.326557 },
    { lat: 48.9982479, lng: 18.3273821 },
    { lat: 48.9981878, lng: 18.3278785 },
    { lat: 48.9981708, lng: 18.3279964 },
    { lat: 48.9986016, lng: 18.3280516 },
    { lat: 48.9988226, lng: 18.3282533 },
    { lat: 48.9988291, lng: 18.3285504 },
    { lat: 48.9988538, lng: 18.3290293 },
    { lat: 48.9991676, lng: 18.3294781 },
    { lat: 48.9992863, lng: 18.3292212 },
    { lat: 48.9994736, lng: 18.3289397 },
    { lat: 48.9996106, lng: 18.3294307 },
    { lat: 48.9997769, lng: 18.3301206 },
    { lat: 49.0000275, lng: 18.3308065 },
    { lat: 49.0001761, lng: 18.3309025 },
    { lat: 49.0002869, lng: 18.3312098 },
    { lat: 49.0005787, lng: 18.3313628 },
    { lat: 49.0009702, lng: 18.3314041 },
    { lat: 49.0011776, lng: 18.3316417 },
    { lat: 49.0012271, lng: 18.331783 },
    { lat: 49.0016939, lng: 18.3320761 },
    { lat: 49.0018622, lng: 18.3319616 },
    { lat: 49.0021856, lng: 18.3317929 },
    { lat: 49.0022699, lng: 18.3321701 },
    { lat: 49.0022464, lng: 18.3324771 },
    { lat: 49.0019538, lng: 18.333212 },
    { lat: 49.0019961, lng: 18.3338868 },
    { lat: 49.0018206, lng: 18.335244 },
    { lat: 49.0017661, lng: 18.3384199 },
    { lat: 49.0025628, lng: 18.3384836 },
    { lat: 49.0030398, lng: 18.3385638 },
    { lat: 49.0035357, lng: 18.3387562 },
    { lat: 49.0034862, lng: 18.339021 },
    { lat: 49.0032576, lng: 18.3395317 },
    { lat: 49.0031041, lng: 18.3402943 },
    { lat: 49.0032211, lng: 18.3409155 },
    { lat: 49.0038105, lng: 18.3413086 },
    { lat: 49.0039687, lng: 18.3415541 },
    { lat: 49.0044707, lng: 18.341713 },
    { lat: 49.004519, lng: 18.3416892 },
    { lat: 49.0051088, lng: 18.3414649 },
    { lat: 49.0052702, lng: 18.3419694 },
    { lat: 49.0054159, lng: 18.3418524 },
    { lat: 49.0058719, lng: 18.3421194 },
    { lat: 49.00615, lng: 18.341322 },
    { lat: 49.006317, lng: 18.3408584 },
    { lat: 49.0062127, lng: 18.3406096 },
    { lat: 49.006041, lng: 18.3399569 },
    { lat: 49.0061066, lng: 18.3397996 },
    { lat: 49.00618, lng: 18.3387117 },
    { lat: 49.0065091, lng: 18.3380446 },
    { lat: 49.0064967, lng: 18.3375787 },
    { lat: 49.0060735, lng: 18.3371562 },
    { lat: 49.0051029, lng: 18.336363 },
    { lat: 49.005057, lng: 18.3363085 },
    { lat: 49.0051528, lng: 18.3361661 },
    { lat: 49.0055459, lng: 18.3357724 },
    { lat: 49.005819, lng: 18.3355915 },
    { lat: 49.0059152, lng: 18.335477 },
    { lat: 49.0060686, lng: 18.3351733 },
    { lat: 49.0061694, lng: 18.3349385 },
    { lat: 49.0063, lng: 18.3346528 },
    { lat: 49.0064776, lng: 18.3342568 },
    { lat: 49.0065662, lng: 18.3340075 },
    { lat: 49.0066654, lng: 18.3337025 },
    { lat: 49.0067426, lng: 18.3334675 },
    { lat: 49.0068485, lng: 18.333143 },
    { lat: 49.0069563, lng: 18.3328146 },
    { lat: 49.0070525, lng: 18.3325179 },
    { lat: 49.0071051, lng: 18.3322879 },
    { lat: 49.0065713, lng: 18.3319847 },
    { lat: 49.0059263, lng: 18.3316075 },
    { lat: 49.0061374, lng: 18.3309837 },
    { lat: 49.0063063, lng: 18.3303351 },
    { lat: 49.0063181, lng: 18.3299433 },
    { lat: 49.0062525, lng: 18.329435 },
    { lat: 49.0061975, lng: 18.3291371 },
    { lat: 49.0062256, lng: 18.3286519 },
    { lat: 49.006168, lng: 18.3281512 },
    { lat: 49.0061143, lng: 18.3279361 },
    { lat: 49.0062014, lng: 18.3274778 },
    { lat: 49.006402, lng: 18.3264693 },
    { lat: 49.0064445, lng: 18.3256585 },
    { lat: 49.0065276, lng: 18.3253063 },
    { lat: 49.0065336, lng: 18.324432 },
    { lat: 49.0064776, lng: 18.3241959 },
    { lat: 49.0062206, lng: 18.3236194 },
    { lat: 49.0057657, lng: 18.3233518 },
    { lat: 49.0054594, lng: 18.3227173 },
    { lat: 49.004836, lng: 18.3220442 },
    { lat: 49.0046798, lng: 18.3216333 },
    { lat: 49.0045809, lng: 18.3210751 },
    { lat: 49.0043871, lng: 18.3198932 },
    { lat: 49.0042218, lng: 18.3194033 },
    { lat: 49.0042371, lng: 18.3185306 },
    { lat: 49.004365, lng: 18.3184565 },
    { lat: 49.0046184, lng: 18.3176054 },
    { lat: 49.0048046, lng: 18.317145 },
    { lat: 49.0049631, lng: 18.3164101 },
    { lat: 49.0050836, lng: 18.3160038 },
    { lat: 49.0055846, lng: 18.3142002 },
    { lat: 49.005623, lng: 18.3141018 },
    { lat: 49.0056313, lng: 18.3140094 },
    { lat: 49.0061035, lng: 18.3136959 },
    { lat: 49.0064695, lng: 18.3134439 },
    { lat: 49.0067632, lng: 18.313028 },
    { lat: 49.0066473, lng: 18.3122119 },
    { lat: 49.0068407, lng: 18.3111349 },
    { lat: 49.0072323, lng: 18.3108961 },
    { lat: 49.0074243, lng: 18.3105826 },
    { lat: 49.0078164, lng: 18.3098064 },
    { lat: 49.0079073, lng: 18.3097407 },
    { lat: 49.0082025, lng: 18.3096934 },
    { lat: 49.0083541, lng: 18.3096392 },
    { lat: 49.0084203, lng: 18.3096151 },
    { lat: 49.0086537, lng: 18.309532 },
    { lat: 49.0090435, lng: 18.30932 },
    { lat: 49.0093086, lng: 18.3093017 },
    { lat: 49.0096681, lng: 18.3090145 },
    { lat: 49.0100345, lng: 18.3083236 },
    { lat: 49.0102565, lng: 18.3074797 },
    { lat: 49.0105347, lng: 18.3062672 },
    { lat: 49.010914, lng: 18.3048662 },
    { lat: 49.0117117, lng: 18.303846 },
    { lat: 49.0127541, lng: 18.3023587 },
    { lat: 49.0133944, lng: 18.3007698 },
    { lat: 49.0133447, lng: 18.3005462 },
    { lat: 49.0135629, lng: 18.2991309 },
    { lat: 49.0135513, lng: 18.297644 },
    { lat: 49.0136086, lng: 18.2969785 },
    { lat: 49.0137696, lng: 18.2951698 },
  ],
  Ladce: [
    { lat: 49.0230275, lng: 18.2778412 },
    { lat: 49.023022, lng: 18.2778906 },
    { lat: 49.023016, lng: 18.2779435 },
    { lat: 49.0229913, lng: 18.2781474 },
    { lat: 49.0230268, lng: 18.278156 },
    { lat: 49.023457, lng: 18.2786197 },
    { lat: 49.0235177, lng: 18.2786761 },
    { lat: 49.0238986, lng: 18.2790294 },
    { lat: 49.0238259, lng: 18.2791773 },
    { lat: 49.0238029, lng: 18.2792306 },
    { lat: 49.0237016, lng: 18.2794552 },
    { lat: 49.0236821, lng: 18.2795113 },
    { lat: 49.0236669, lng: 18.2795611 },
    { lat: 49.0235672, lng: 18.2798513 },
    { lat: 49.0234887, lng: 18.2799583 },
    { lat: 49.0234963, lng: 18.280036 },
    { lat: 49.0234991, lng: 18.280064 },
    { lat: 49.0235128, lng: 18.2801899 },
    { lat: 49.0235199, lng: 18.2802529 },
    { lat: 49.0235343, lng: 18.2804484 },
    { lat: 49.0235024, lng: 18.2806837 },
    { lat: 49.0234095, lng: 18.2809766 },
    { lat: 49.0230477, lng: 18.2815832 },
    { lat: 49.0229592, lng: 18.2817321 },
    { lat: 49.0219343, lng: 18.2833653 },
    { lat: 49.0218661, lng: 18.2834752 },
    { lat: 49.0214929, lng: 18.2833183 },
    { lat: 49.020411, lng: 18.2828609 },
    { lat: 49.0202314, lng: 18.2825565 },
    { lat: 49.0200429, lng: 18.2822371 },
    { lat: 49.019535, lng: 18.2819145 },
    { lat: 49.0187047, lng: 18.2812737 },
    { lat: 49.0186396, lng: 18.2818786 },
    { lat: 49.0186649, lng: 18.2846855 },
    { lat: 49.0177902, lng: 18.2851602 },
    { lat: 49.0170886, lng: 18.2856859 },
    { lat: 49.0163593, lng: 18.285657 },
    { lat: 49.015703, lng: 18.28618 },
    { lat: 49.0153913, lng: 18.2866736 },
    { lat: 49.0149485, lng: 18.2879466 },
    { lat: 49.0143027, lng: 18.2897966 },
    { lat: 49.0141045, lng: 18.2905841 },
    { lat: 49.0138611, lng: 18.291932 },
    { lat: 49.0136978, lng: 18.2946766 },
    { lat: 49.0137696, lng: 18.2951698 },
    { lat: 49.0136086, lng: 18.2969785 },
    { lat: 49.0135513, lng: 18.297644 },
    { lat: 49.0135629, lng: 18.2991309 },
    { lat: 49.0133447, lng: 18.3005462 },
    { lat: 49.0133944, lng: 18.3007698 },
    { lat: 49.0127541, lng: 18.3023587 },
    { lat: 49.0117117, lng: 18.303846 },
    { lat: 49.010914, lng: 18.3048662 },
    { lat: 49.0105347, lng: 18.3062672 },
    { lat: 49.0102565, lng: 18.3074797 },
    { lat: 49.0100345, lng: 18.3083236 },
    { lat: 49.0096681, lng: 18.3090145 },
    { lat: 49.0093086, lng: 18.3093017 },
    { lat: 49.0090435, lng: 18.30932 },
    { lat: 49.0086537, lng: 18.309532 },
    { lat: 49.0084203, lng: 18.3096151 },
    { lat: 49.0083541, lng: 18.3096392 },
    { lat: 49.0082025, lng: 18.3096934 },
    { lat: 49.0079073, lng: 18.3097407 },
    { lat: 49.0078164, lng: 18.3098064 },
    { lat: 49.0074243, lng: 18.3105826 },
    { lat: 49.0072323, lng: 18.3108961 },
    { lat: 49.0068407, lng: 18.3111349 },
    { lat: 49.0066473, lng: 18.3122119 },
    { lat: 49.0067632, lng: 18.313028 },
    { lat: 49.0064695, lng: 18.3134439 },
    { lat: 49.0061035, lng: 18.3136959 },
    { lat: 49.0056313, lng: 18.3140094 },
    { lat: 49.005623, lng: 18.3141018 },
    { lat: 49.0055846, lng: 18.3142002 },
    { lat: 49.0050836, lng: 18.3160038 },
    { lat: 49.0049631, lng: 18.3164101 },
    { lat: 49.0048046, lng: 18.317145 },
    { lat: 49.0046184, lng: 18.3176054 },
    { lat: 49.004365, lng: 18.3184565 },
    { lat: 49.0042371, lng: 18.3185306 },
    { lat: 49.0042218, lng: 18.3194033 },
    { lat: 49.0043871, lng: 18.3198932 },
    { lat: 49.0045809, lng: 18.3210751 },
    { lat: 49.0046798, lng: 18.3216333 },
    { lat: 49.004836, lng: 18.3220442 },
    { lat: 49.0054594, lng: 18.3227173 },
    { lat: 49.0057657, lng: 18.3233518 },
    { lat: 49.0062206, lng: 18.3236194 },
    { lat: 49.0064776, lng: 18.3241959 },
    { lat: 49.0065336, lng: 18.324432 },
    { lat: 49.0065276, lng: 18.3253063 },
    { lat: 49.0064445, lng: 18.3256585 },
    { lat: 49.006402, lng: 18.3264693 },
    { lat: 49.0062014, lng: 18.3274778 },
    { lat: 49.0061143, lng: 18.3279361 },
    { lat: 49.006168, lng: 18.3281512 },
    { lat: 49.0062256, lng: 18.3286519 },
    { lat: 49.0061975, lng: 18.3291371 },
    { lat: 49.0062525, lng: 18.329435 },
    { lat: 49.0063181, lng: 18.3299433 },
    { lat: 49.0063063, lng: 18.3303351 },
    { lat: 49.0061374, lng: 18.3309837 },
    { lat: 49.0059263, lng: 18.3316075 },
    { lat: 49.0065713, lng: 18.3319847 },
    { lat: 49.0071051, lng: 18.3322879 },
    { lat: 49.0070525, lng: 18.3325179 },
    { lat: 49.0069563, lng: 18.3328146 },
    { lat: 49.0068485, lng: 18.333143 },
    { lat: 49.0067426, lng: 18.3334675 },
    { lat: 49.0066654, lng: 18.3337025 },
    { lat: 49.0065662, lng: 18.3340075 },
    { lat: 49.0064776, lng: 18.3342568 },
    { lat: 49.0063, lng: 18.3346528 },
    { lat: 49.0061694, lng: 18.3349385 },
    { lat: 49.0060686, lng: 18.3351733 },
    { lat: 49.0059152, lng: 18.335477 },
    { lat: 49.005819, lng: 18.3355915 },
    { lat: 49.0055459, lng: 18.3357724 },
    { lat: 49.0051528, lng: 18.3361661 },
    { lat: 49.005057, lng: 18.3363085 },
    { lat: 49.0051029, lng: 18.336363 },
    { lat: 49.0060735, lng: 18.3371562 },
    { lat: 49.0064967, lng: 18.3375787 },
    { lat: 49.0065091, lng: 18.3380446 },
    { lat: 49.00618, lng: 18.3387117 },
    { lat: 49.0061066, lng: 18.3397996 },
    { lat: 49.006041, lng: 18.3399569 },
    { lat: 49.0062127, lng: 18.3406096 },
    { lat: 49.006317, lng: 18.3408584 },
    { lat: 49.00615, lng: 18.341322 },
    { lat: 49.0058719, lng: 18.3421194 },
    { lat: 49.0054159, lng: 18.3418524 },
    { lat: 49.0052702, lng: 18.3419694 },
    { lat: 49.0051088, lng: 18.3414649 },
    { lat: 49.004519, lng: 18.3416892 },
    { lat: 49.0044707, lng: 18.341713 },
    { lat: 49.0039687, lng: 18.3415541 },
    { lat: 49.0037254, lng: 18.3424062 },
    { lat: 49.0035229, lng: 18.3424967 },
    { lat: 49.003441, lng: 18.3424868 },
    { lat: 49.0034868, lng: 18.3436261 },
    { lat: 49.0035587, lng: 18.3437034 },
    { lat: 49.0036991, lng: 18.3435508 },
    { lat: 49.0038688, lng: 18.3436385 },
    { lat: 49.0038076, lng: 18.3437535 },
    { lat: 49.0037064, lng: 18.343842 },
    { lat: 49.0037149, lng: 18.3445981 },
    { lat: 49.0035378, lng: 18.3445386 },
    { lat: 49.0035109, lng: 18.3446244 },
    { lat: 49.0034377, lng: 18.3445627 },
    { lat: 49.0033029, lng: 18.344937 },
    { lat: 49.0028601, lng: 18.3449281 },
    { lat: 49.0027841, lng: 18.3452645 },
    { lat: 49.0028668, lng: 18.3455308 },
    { lat: 49.0027709, lng: 18.3455914 },
    { lat: 49.0027304, lng: 18.3451917 },
    { lat: 49.0026752, lng: 18.3450935 },
    { lat: 49.0027039, lng: 18.3448796 },
    { lat: 49.0026854, lng: 18.3446283 },
    { lat: 49.0025201, lng: 18.3444962 },
    { lat: 49.0023729, lng: 18.3449292 },
    { lat: 49.0022692, lng: 18.3450526 },
    { lat: 49.0021224, lng: 18.345092 },
    { lat: 49.0015871, lng: 18.3447566 },
    { lat: 49.0010072, lng: 18.3451197 },
    { lat: 49.0008896, lng: 18.3452786 },
    { lat: 49.0007679, lng: 18.3455207 },
    { lat: 49.0014016, lng: 18.3457176 },
    { lat: 49.0014829, lng: 18.3455562 },
    { lat: 49.0021795, lng: 18.3462301 },
    { lat: 49.0023532, lng: 18.3467758 },
    { lat: 49.0039059, lng: 18.3497898 },
    { lat: 49.0047386, lng: 18.3517287 },
    { lat: 49.0057085, lng: 18.3535816 },
    { lat: 49.006081, lng: 18.354535 },
    { lat: 49.006608, lng: 18.353865 },
    { lat: 49.007668, lng: 18.353634 },
    { lat: 49.007847, lng: 18.354124 },
    { lat: 49.007991, lng: 18.355084 },
    { lat: 49.008102, lng: 18.356069 },
    { lat: 49.008493, lng: 18.356933 },
    { lat: 49.009298, lng: 18.3567 },
    { lat: 49.009716, lng: 18.356579 },
    { lat: 49.009805, lng: 18.356298 },
    { lat: 49.009919, lng: 18.356241 },
    { lat: 49.010196, lng: 18.355791 },
    { lat: 49.010053, lng: 18.355104 },
    { lat: 49.009858, lng: 18.353379 },
    { lat: 49.0101, lng: 18.353478 },
    { lat: 49.010134, lng: 18.353299 },
    { lat: 49.01019, lng: 18.35327 },
    { lat: 49.010226, lng: 18.353251 },
    { lat: 49.010187, lng: 18.352794 },
    { lat: 49.010101, lng: 18.352326 },
    { lat: 49.010144, lng: 18.352052 },
    { lat: 49.010048, lng: 18.351761 },
    { lat: 49.00968, lng: 18.351329 },
    { lat: 49.009315, lng: 18.350957 },
    { lat: 49.00911, lng: 18.350709 },
    { lat: 49.009409, lng: 18.349941 },
    { lat: 49.00991, lng: 18.349147 },
    { lat: 49.0104, lng: 18.34876 },
    { lat: 49.010617, lng: 18.347857 },
    { lat: 49.010645, lng: 18.347786 },
    { lat: 49.010769, lng: 18.347463 },
    { lat: 49.010885, lng: 18.347219 },
    { lat: 49.010919, lng: 18.346263 },
    { lat: 49.011375, lng: 18.345997 },
    { lat: 49.011909, lng: 18.346345 },
    { lat: 49.012364, lng: 18.347618 },
    { lat: 49.012557, lng: 18.347666 },
    { lat: 49.012722, lng: 18.347868 },
    { lat: 49.012701, lng: 18.349091 },
    { lat: 49.013693, lng: 18.349625 },
    { lat: 49.014669, lng: 18.350151 },
    { lat: 49.01472, lng: 18.349452 },
    { lat: 49.014699, lng: 18.348873 },
    { lat: 49.014774, lng: 18.348885 },
    { lat: 49.014618, lng: 18.347594 },
    { lat: 49.014731, lng: 18.347143 },
    { lat: 49.014826, lng: 18.34672 },
    { lat: 49.014257, lng: 18.34644 },
    { lat: 49.014566, lng: 18.345324 },
    { lat: 49.014872, lng: 18.345642 },
    { lat: 49.01497, lng: 18.345697 },
    { lat: 49.015109, lng: 18.345775 },
    { lat: 49.015708, lng: 18.346112 },
    { lat: 49.015977, lng: 18.346016 },
    { lat: 49.015873, lng: 18.344157 },
    { lat: 49.015795, lng: 18.342781 },
    { lat: 49.015618, lng: 18.341119 },
    { lat: 49.015524, lng: 18.340675 },
    { lat: 49.015272, lng: 18.3395 },
    { lat: 49.015231, lng: 18.337294 },
    { lat: 49.014761, lng: 18.335559 },
    { lat: 49.014899, lng: 18.33538 },
    { lat: 49.018237, lng: 18.331038 },
    { lat: 49.021641, lng: 18.326611 },
    { lat: 49.022041, lng: 18.326035 },
    { lat: 49.023503, lng: 18.323928 },
    { lat: 49.023773, lng: 18.323599 },
    { lat: 49.023797, lng: 18.32357 },
    { lat: 49.023971, lng: 18.323359 },
    { lat: 49.02654, lng: 18.320363 },
    { lat: 49.026633, lng: 18.320256 },
    { lat: 49.026933, lng: 18.319582 },
    { lat: 49.027789, lng: 18.318182 },
    { lat: 49.02857, lng: 18.316865 },
    { lat: 49.028875, lng: 18.315926 },
    { lat: 49.028999, lng: 18.315545 },
    { lat: 49.029026, lng: 18.315444 },
    { lat: 49.029267, lng: 18.315075 },
    { lat: 49.029474, lng: 18.314477 },
    { lat: 49.029536, lng: 18.314156 },
    { lat: 49.029606, lng: 18.313857 },
    { lat: 49.029861, lng: 18.31358 },
    { lat: 49.030471, lng: 18.313125 },
    { lat: 49.031105, lng: 18.312626 },
    { lat: 49.0317, lng: 18.312244 },
    { lat: 49.031924, lng: 18.311979 },
    { lat: 49.032458, lng: 18.311827 },
    { lat: 49.032851, lng: 18.31163 },
    { lat: 49.03337, lng: 18.311207 },
    { lat: 49.034123, lng: 18.310456 },
    { lat: 49.034304, lng: 18.31005 },
    { lat: 49.034267, lng: 18.309587 },
    { lat: 49.035092, lng: 18.308815 },
    { lat: 49.03615, lng: 18.30807 },
    { lat: 49.037301, lng: 18.306526 },
    { lat: 49.038415, lng: 18.305032 },
    { lat: 49.0391, lng: 18.30415 },
    { lat: 49.039798, lng: 18.303043 },
    { lat: 49.041255, lng: 18.300735 },
    { lat: 49.041346, lng: 18.30057 },
    { lat: 49.041376, lng: 18.300513 },
    { lat: 49.041407, lng: 18.300452 },
    { lat: 49.041549, lng: 18.30057 },
    { lat: 49.041809, lng: 18.300789 },
    { lat: 49.04197, lng: 18.301019 },
    { lat: 49.042299, lng: 18.301349 },
    { lat: 49.042572, lng: 18.301588 },
    { lat: 49.043139, lng: 18.30222 },
    { lat: 49.043343, lng: 18.302493 },
    { lat: 49.043723, lng: 18.30294 },
    { lat: 49.044089, lng: 18.303302 },
    { lat: 49.044439, lng: 18.303699 },
    { lat: 49.045345, lng: 18.304537 },
    { lat: 49.045353, lng: 18.30449 },
    { lat: 49.045368, lng: 18.304458 },
    { lat: 49.045397, lng: 18.304375 },
    { lat: 49.046029, lng: 18.302907 },
    { lat: 49.046127, lng: 18.302678 },
    { lat: 49.046168, lng: 18.302583 },
    { lat: 49.046189, lng: 18.302533 },
    { lat: 49.046225, lng: 18.302451 },
    { lat: 49.046346, lng: 18.302104 },
    { lat: 49.046436, lng: 18.301847 },
    { lat: 49.046533, lng: 18.301663 },
    { lat: 49.04656, lng: 18.301595 },
    { lat: 49.046907, lng: 18.30075 },
    { lat: 49.046918, lng: 18.300688 },
    { lat: 49.046951, lng: 18.300624 },
    { lat: 49.046975, lng: 18.300584 },
    { lat: 49.047933, lng: 18.297586 },
    { lat: 49.048043, lng: 18.297242 },
    { lat: 49.048153, lng: 18.296936 },
    { lat: 49.04828, lng: 18.296586 },
    { lat: 49.048353, lng: 18.296387 },
    { lat: 49.048457, lng: 18.296101 },
    { lat: 49.048499, lng: 18.296139 },
    { lat: 49.048676, lng: 18.296204 },
    { lat: 49.049302, lng: 18.296425 },
    { lat: 49.049403, lng: 18.296489 },
    { lat: 49.049623, lng: 18.296516 },
    { lat: 49.049668, lng: 18.29651 },
    { lat: 49.049695, lng: 18.296429 },
    { lat: 49.049784, lng: 18.296119 },
    { lat: 49.049817, lng: 18.296004 },
    { lat: 49.050491, lng: 18.293664 },
    { lat: 49.048726, lng: 18.29247 },
    { lat: 49.049684, lng: 18.290138 },
    { lat: 49.049746, lng: 18.290087 },
    { lat: 49.049788, lng: 18.290053 },
    { lat: 49.049837, lng: 18.290015 },
    { lat: 49.050411, lng: 18.289554 },
    { lat: 49.050824, lng: 18.289222 },
    { lat: 49.051033, lng: 18.289055 },
    { lat: 49.051182, lng: 18.288935 },
    { lat: 49.051886, lng: 18.287151 },
    { lat: 49.052564, lng: 18.284764 },
    { lat: 49.052601, lng: 18.284657 },
    { lat: 49.052468, lng: 18.284034 },
    { lat: 49.051918, lng: 18.282295 },
    { lat: 49.051694, lng: 18.28159 },
    { lat: 49.051573, lng: 18.281206 },
    { lat: 49.051349, lng: 18.280498 },
    { lat: 49.050792, lng: 18.278444 },
    { lat: 49.050776, lng: 18.278386 },
    { lat: 49.050272, lng: 18.2766 },
    { lat: 49.050023, lng: 18.275807 },
    { lat: 49.049693, lng: 18.274754 },
    { lat: 49.049032, lng: 18.272272 },
    { lat: 49.04897, lng: 18.272055 },
    { lat: 49.048831, lng: 18.271571 },
    { lat: 49.048499, lng: 18.270468 },
    { lat: 49.048406, lng: 18.27016 },
    { lat: 49.048316, lng: 18.269858 },
    { lat: 49.047982, lng: 18.269395 },
    { lat: 49.047597, lng: 18.268361 },
    { lat: 49.047158, lng: 18.267842 },
    { lat: 49.046894, lng: 18.267566 },
    { lat: 49.045816, lng: 18.26685 },
    { lat: 49.045715, lng: 18.266784 },
    { lat: 49.044646, lng: 18.266122 },
    { lat: 49.044248, lng: 18.265724 },
    { lat: 49.044018, lng: 18.265568 },
    { lat: 49.04374, lng: 18.265445 },
    { lat: 49.043486, lng: 18.26536 },
    { lat: 49.043041, lng: 18.265302 },
    { lat: 49.043, lng: 18.265297 },
    { lat: 49.042092, lng: 18.265539 },
    { lat: 49.040493, lng: 18.266437 },
    { lat: 49.039479, lng: 18.26688 },
    { lat: 49.038988, lng: 18.267094 },
    { lat: 49.038454, lng: 18.26806 },
    { lat: 49.038106, lng: 18.267615 },
    { lat: 49.03778, lng: 18.267782 },
    { lat: 49.037206, lng: 18.267931 },
    { lat: 49.037163, lng: 18.267943 },
    { lat: 49.036356, lng: 18.268153 },
    { lat: 49.03344, lng: 18.268082 },
    { lat: 49.0317591, lng: 18.2673907 },
    { lat: 49.0316574, lng: 18.2672337 },
    { lat: 49.0309731, lng: 18.2655829 },
    { lat: 49.0309642, lng: 18.265565 },
    { lat: 49.0301106, lng: 18.2635985 },
    { lat: 49.0300157, lng: 18.2636764 },
    { lat: 49.0299325, lng: 18.2637441 },
    { lat: 49.0294345, lng: 18.2641559 },
    { lat: 49.0293515, lng: 18.2642224 },
    { lat: 49.0293127, lng: 18.2642545 },
    { lat: 49.0292706, lng: 18.2642884 },
    { lat: 49.0279894, lng: 18.2653945 },
    { lat: 49.0276355, lng: 18.2657004 },
    { lat: 49.0272257, lng: 18.2660539 },
    { lat: 49.0287836, lng: 18.2676236 },
    { lat: 49.0287584, lng: 18.267679 },
    { lat: 49.028758, lng: 18.2677514 },
    { lat: 49.028739, lng: 18.2677966 },
    { lat: 49.0287229, lng: 18.2678289 },
    { lat: 49.028528, lng: 18.2682542 },
    { lat: 49.0285162, lng: 18.2682831 },
    { lat: 49.0284985, lng: 18.2683293 },
    { lat: 49.0284755, lng: 18.2683833 },
    { lat: 49.0284678, lng: 18.2684032 },
    { lat: 49.0284549, lng: 18.2684364 },
    { lat: 49.0284052, lng: 18.2685356 },
    { lat: 49.0283011, lng: 18.2687477 },
    { lat: 49.0268213, lng: 18.2717499 },
    { lat: 49.0253191, lng: 18.2747958 },
    { lat: 49.0253181, lng: 18.2747981 },
    { lat: 49.0252123, lng: 18.2749219 },
    { lat: 49.0250802, lng: 18.2750772 },
    { lat: 49.0249785, lng: 18.2751983 },
    { lat: 49.0249225, lng: 18.2752653 },
    { lat: 49.0247513, lng: 18.27547 },
    { lat: 49.02467, lng: 18.2755676 },
    { lat: 49.0245537, lng: 18.2757054 },
    { lat: 49.0244767, lng: 18.2757981 },
    { lat: 49.0244364, lng: 18.2758462 },
    { lat: 49.024392, lng: 18.2758987 },
    { lat: 49.0243445, lng: 18.2759563 },
    { lat: 49.0242441, lng: 18.2760762 },
    { lat: 49.0241965, lng: 18.2761324 },
    { lat: 49.0241183, lng: 18.2762264 },
    { lat: 49.024055, lng: 18.2763026 },
    { lat: 49.0239671, lng: 18.2764068 },
    { lat: 49.0239374, lng: 18.2764172 },
    { lat: 49.0238307, lng: 18.2765458 },
    { lat: 49.0235645, lng: 18.276889 },
    { lat: 49.0233807, lng: 18.2771507 },
    { lat: 49.0231056, lng: 18.2775123 },
    { lat: 49.0230836, lng: 18.2775418 },
    { lat: 49.0230583, lng: 18.2775753 },
    { lat: 49.0230333, lng: 18.2777892 },
    { lat: 49.0230275, lng: 18.2778412 },
  ],
  Dulov: [
    { lat: 49.057098, lng: 18.229099 },
    { lat: 49.056879, lng: 18.2282876 },
    { lat: 49.0565826, lng: 18.2280928 },
    { lat: 49.0564234, lng: 18.2280126 },
    { lat: 49.0560866, lng: 18.2277451 },
    { lat: 49.0558493, lng: 18.2276001 },
    { lat: 49.0556417, lng: 18.2274627 },
    { lat: 49.0555353, lng: 18.2273378 },
    { lat: 49.0552774, lng: 18.2269275 },
    { lat: 49.0550336, lng: 18.2273427 },
    { lat: 49.0547584, lng: 18.2278204 },
    { lat: 49.0545382, lng: 18.2279576 },
    { lat: 49.0541184, lng: 18.2282324 },
    { lat: 49.0537637, lng: 18.2284442 },
    { lat: 49.053186, lng: 18.2288203 },
    { lat: 49.0526266, lng: 18.2291616 },
    { lat: 49.052194, lng: 18.2294131 },
    { lat: 49.0516606, lng: 18.2297373 },
    { lat: 49.0510609, lng: 18.2301414 },
    { lat: 49.0506872, lng: 18.2303939 },
    { lat: 49.0496417, lng: 18.2310588 },
    { lat: 49.0492209, lng: 18.231334 },
    { lat: 49.0489591, lng: 18.23169 },
    { lat: 49.0481479, lng: 18.2310792 },
    { lat: 49.0479945, lng: 18.230922 },
    { lat: 49.0478234, lng: 18.2308701 },
    { lat: 49.0474468, lng: 18.2323796 },
    { lat: 49.0472933, lng: 18.2322987 },
    { lat: 49.0468189, lng: 18.2318731 },
    { lat: 49.0464979, lng: 18.2315254 },
    { lat: 49.0462789, lng: 18.2313251 },
    { lat: 49.046114, lng: 18.2312652 },
    { lat: 49.0454945, lng: 18.2310428 },
    { lat: 49.045145, lng: 18.2316455 },
    { lat: 49.0448055, lng: 18.2325987 },
    { lat: 49.0440106, lng: 18.2338413 },
    { lat: 49.0433801, lng: 18.2331481 },
    { lat: 49.0427192, lng: 18.2344032 },
    { lat: 49.0418945, lng: 18.2336917 },
    { lat: 49.0414682, lng: 18.2344278 },
    { lat: 49.0411903, lng: 18.2347582 },
    { lat: 49.0384414, lng: 18.232889 },
    { lat: 49.0381709, lng: 18.2334291 },
    { lat: 49.0379033, lng: 18.2337243 },
    { lat: 49.0376384, lng: 18.2339302 },
    { lat: 49.037417, lng: 18.2341414 },
    { lat: 49.0372057, lng: 18.234513 },
    { lat: 49.0371045, lng: 18.2348535 },
    { lat: 49.037061, lng: 18.2351408 },
    { lat: 49.0370249, lng: 18.2355789 },
    { lat: 49.0368529, lng: 18.2359475 },
    { lat: 49.036732, lng: 18.2361824 },
    { lat: 49.036628, lng: 18.236396 },
    { lat: 49.0365686, lng: 18.2363236 },
    { lat: 49.0364164, lng: 18.236066 },
    { lat: 49.03617, lng: 18.235463 },
    { lat: 49.0356459, lng: 18.2344247 },
    { lat: 49.0352795, lng: 18.2338141 },
    { lat: 49.0351018, lng: 18.2340025 },
    { lat: 49.0350688, lng: 18.2339385 },
    { lat: 49.0350201, lng: 18.2338561 },
    { lat: 49.0349721, lng: 18.2337427 },
    { lat: 49.0349489, lng: 18.2336921 },
    { lat: 49.0349394, lng: 18.2336668 },
    { lat: 49.0347664, lng: 18.2334393 },
    { lat: 49.0343212, lng: 18.2326952 },
    { lat: 49.034266, lng: 18.2326349 },
    { lat: 49.0342375, lng: 18.2326116 },
    { lat: 49.0341979, lng: 18.2325694 },
    { lat: 49.0341774, lng: 18.2325286 },
    { lat: 49.0341189, lng: 18.2324517 },
    { lat: 49.0340275, lng: 18.2326112 },
    { lat: 49.0339845, lng: 18.2326863 },
    { lat: 49.0339393, lng: 18.2327645 },
    { lat: 49.0334874, lng: 18.233428 },
    { lat: 49.0334407, lng: 18.2335018 },
    { lat: 49.0333765, lng: 18.2336041 },
    { lat: 49.0333249, lng: 18.233686 },
    { lat: 49.0322011, lng: 18.235494 },
    { lat: 49.0309271, lng: 18.2374342 },
    { lat: 49.0298533, lng: 18.2391494 },
    { lat: 49.0296225, lng: 18.2394958 },
    { lat: 49.029361, lng: 18.2399782 },
    { lat: 49.0291689, lng: 18.240445 },
    { lat: 49.0290584, lng: 18.2407863 },
    { lat: 49.0288584, lng: 18.241715 },
    { lat: 49.0286732, lng: 18.2425081 },
    { lat: 49.0286182, lng: 18.2426721 },
    { lat: 49.0283242, lng: 18.2439896 },
    { lat: 49.0282536, lng: 18.2441797 },
    { lat: 49.0282352, lng: 18.2442289 },
    { lat: 49.0280326, lng: 18.244775 },
    { lat: 49.0274649, lng: 18.2460871 },
    { lat: 49.0271832, lng: 18.2465098 },
    { lat: 49.0267045, lng: 18.2468396 },
    { lat: 49.0259677, lng: 18.2465268 },
    { lat: 49.0252667, lng: 18.2461192 },
    { lat: 49.0247906, lng: 18.2464783 },
    { lat: 49.0244508, lng: 18.246735 },
    { lat: 49.0239089, lng: 18.2472225 },
    { lat: 49.0223535, lng: 18.2484662 },
    { lat: 49.0220465, lng: 18.2487636 },
    { lat: 49.0220251, lng: 18.2487822 },
    { lat: 49.0219938, lng: 18.248809 },
    { lat: 49.0219487, lng: 18.2488476 },
    { lat: 49.0216914, lng: 18.2490837 },
    { lat: 49.0220577, lng: 18.2496576 },
    { lat: 49.0223252, lng: 18.2500744 },
    { lat: 49.0226608, lng: 18.2506027 },
    { lat: 49.0229891, lng: 18.2511173 },
    { lat: 49.0231983, lng: 18.2514633 },
    { lat: 49.0232702, lng: 18.2515805 },
    { lat: 49.0236293, lng: 18.2521665 },
    { lat: 49.0238932, lng: 18.2525971 },
    { lat: 49.024117, lng: 18.2529628 },
    { lat: 49.0242066, lng: 18.2531078 },
    { lat: 49.0243098, lng: 18.2532773 },
    { lat: 49.0255249, lng: 18.2552855 },
    { lat: 49.0245984, lng: 18.2563992 },
    { lat: 49.0242314, lng: 18.2568493 },
    { lat: 49.0238875, lng: 18.2572715 },
    { lat: 49.023765, lng: 18.2574216 },
    { lat: 49.0241956, lng: 18.2574144 },
    { lat: 49.0245213, lng: 18.2574095 },
    { lat: 49.0250522, lng: 18.2575787 },
    { lat: 49.0257394, lng: 18.2577763 },
    { lat: 49.0262566, lng: 18.2580249 },
    { lat: 49.0266406, lng: 18.2582093 },
    { lat: 49.027197, lng: 18.2586117 },
    { lat: 49.0272339, lng: 18.2586457 },
    { lat: 49.0279125, lng: 18.2592723 },
    { lat: 49.0282736, lng: 18.2597118 },
    { lat: 49.0285959, lng: 18.2601629 },
    { lat: 49.0289178, lng: 18.2606874 },
    { lat: 49.0291763, lng: 18.2611698 },
    { lat: 49.0294186, lng: 18.2616188 },
    { lat: 49.0297514, lng: 18.2623788 },
    { lat: 49.0301106, lng: 18.2635985 },
    { lat: 49.0309642, lng: 18.265565 },
    { lat: 49.0309731, lng: 18.2655829 },
    { lat: 49.0316574, lng: 18.2672337 },
    { lat: 49.0317591, lng: 18.2673907 },
    { lat: 49.03344, lng: 18.268082 },
    { lat: 49.0342267, lng: 18.2675321 },
    { lat: 49.034234, lng: 18.267527 },
    { lat: 49.034282, lng: 18.267493 },
    { lat: 49.034355, lng: 18.267443 },
    { lat: 49.034521, lng: 18.267149 },
    { lat: 49.035481, lng: 18.265457 },
    { lat: 49.035768, lng: 18.264954 },
    { lat: 49.035793, lng: 18.264909 },
    { lat: 49.035823, lng: 18.264857 },
    { lat: 49.035898, lng: 18.264725 },
    { lat: 49.036017, lng: 18.264549 },
    { lat: 49.03604, lng: 18.264513 },
    { lat: 49.036632, lng: 18.263635 },
    { lat: 49.036807, lng: 18.263393 },
    { lat: 49.037156, lng: 18.262911 },
    { lat: 49.037408, lng: 18.26265 },
    { lat: 49.038256, lng: 18.261474 },
    { lat: 49.039349, lng: 18.259944 },
    { lat: 49.040185, lng: 18.258789 },
    { lat: 49.040296, lng: 18.258707 },
    { lat: 49.040843, lng: 18.257773 },
    { lat: 49.041217, lng: 18.257035 },
    { lat: 49.041824, lng: 18.255526 },
    { lat: 49.041844, lng: 18.255477 },
    { lat: 49.041954, lng: 18.255187 },
    { lat: 49.042099, lng: 18.254911 },
    { lat: 49.0425, lng: 18.254257 },
    { lat: 49.042698, lng: 18.253942 },
    { lat: 49.043012, lng: 18.253443 },
    { lat: 49.042979, lng: 18.253392 },
    { lat: 49.043062, lng: 18.253269 },
    { lat: 49.043111, lng: 18.253188 },
    { lat: 49.043129, lng: 18.253152 },
    { lat: 49.043154, lng: 18.253101 },
    { lat: 49.043194, lng: 18.253009 },
    { lat: 49.043221, lng: 18.252909 },
    { lat: 49.04324, lng: 18.252802 },
    { lat: 49.043242, lng: 18.252794 },
    { lat: 49.043244, lng: 18.252784 },
    { lat: 49.043279, lng: 18.252631 },
    { lat: 49.043309, lng: 18.252494 },
    { lat: 49.043363, lng: 18.252254 },
    { lat: 49.043366, lng: 18.252238 },
    { lat: 49.043377, lng: 18.252168 },
    { lat: 49.043401, lng: 18.252093 },
    { lat: 49.043428, lng: 18.252025 },
    { lat: 49.043461, lng: 18.251959 },
    { lat: 49.043516, lng: 18.251866 },
    { lat: 49.04359, lng: 18.251629 },
    { lat: 49.043601, lng: 18.251593 },
    { lat: 49.04364, lng: 18.251466 },
    { lat: 49.043717, lng: 18.251259 },
    { lat: 49.043866, lng: 18.250849 },
    { lat: 49.043926, lng: 18.250456 },
    { lat: 49.043915, lng: 18.250117 },
    { lat: 49.043852, lng: 18.249681 },
    { lat: 49.043833, lng: 18.249489 },
    { lat: 49.04386, lng: 18.249325 },
    { lat: 49.043919, lng: 18.24916 },
    { lat: 49.044031, lng: 18.248936 },
    { lat: 49.044114, lng: 18.248799 },
    { lat: 49.044369, lng: 18.248607 },
    { lat: 49.044423, lng: 18.248493 },
    { lat: 49.044432, lng: 18.248474 },
    { lat: 49.044446, lng: 18.248462 },
    { lat: 49.044513, lng: 18.248354 },
    { lat: 49.044692, lng: 18.248109 },
    { lat: 49.04482, lng: 18.247977 },
    { lat: 49.045035, lng: 18.247883 },
    { lat: 49.045263, lng: 18.247775 },
    { lat: 49.045408, lng: 18.247578 },
    { lat: 49.045585, lng: 18.247328 },
    { lat: 49.045955, lng: 18.247072 },
    { lat: 49.046621, lng: 18.24661 },
    { lat: 49.046906, lng: 18.246441 },
    { lat: 49.047196, lng: 18.246342 },
    { lat: 49.047485, lng: 18.246327 },
    { lat: 49.04793, lng: 18.246227 },
    { lat: 49.048256, lng: 18.246161 },
    { lat: 49.049061, lng: 18.246139 },
    { lat: 49.050253, lng: 18.24536 },
    { lat: 49.050431, lng: 18.245343 },
    { lat: 49.050569, lng: 18.245357 },
    { lat: 49.050815, lng: 18.245224 },
    { lat: 49.051075, lng: 18.245031 },
    { lat: 49.051213, lng: 18.244793 },
    { lat: 49.051358, lng: 18.244402 },
    { lat: 49.051433, lng: 18.244218 },
    { lat: 49.052268, lng: 18.243421 },
    { lat: 49.05243, lng: 18.243316 },
    { lat: 49.053055, lng: 18.242982 },
    { lat: 49.053527, lng: 18.242964 },
    { lat: 49.053808, lng: 18.242871 },
    { lat: 49.053999, lng: 18.242819 },
    { lat: 49.054395, lng: 18.24271 },
    { lat: 49.054981, lng: 18.242556 },
    { lat: 49.055473, lng: 18.242527 },
    { lat: 49.055712, lng: 18.242486 },
    { lat: 49.056001, lng: 18.242381 },
    { lat: 49.05608, lng: 18.242324 },
    { lat: 49.056489, lng: 18.242031 },
    { lat: 49.056572, lng: 18.241762 },
    { lat: 49.056725, lng: 18.241558 },
    { lat: 49.057009, lng: 18.241485 },
    { lat: 49.057315, lng: 18.240975 },
    { lat: 49.05767, lng: 18.240489 },
    { lat: 49.057888, lng: 18.240166 },
    { lat: 49.058161, lng: 18.23971 },
    { lat: 49.058336, lng: 18.239422 },
    { lat: 49.058761, lng: 18.238797 },
    { lat: 49.059077, lng: 18.238357 },
    { lat: 49.059251, lng: 18.2381 },
    { lat: 49.059213, lng: 18.238054 },
    { lat: 49.059178, lng: 18.237983 },
    { lat: 49.059486, lng: 18.237535 },
    { lat: 49.060075, lng: 18.237194 },
    { lat: 49.059765, lng: 18.236027 },
    { lat: 49.05927, lng: 18.234917 },
    { lat: 49.058814, lng: 18.234141 },
    { lat: 49.058356, lng: 18.232791 },
    { lat: 49.057818, lng: 18.232676 },
    { lat: 49.057187, lng: 18.231488 },
    { lat: 49.056525, lng: 18.230251 },
    { lat: 49.056716, lng: 18.229843 },
    { lat: 49.057098, lng: 18.229099 },
  ],
  Krivoklát: [
    { lat: 49.0380822, lng: 18.1150749 },
    { lat: 49.0379857, lng: 18.1154176 },
    { lat: 49.038042, lng: 18.115842 },
    { lat: 49.0384943, lng: 18.116658 },
    { lat: 49.0389724, lng: 18.1177813 },
    { lat: 49.039429, lng: 18.1191222 },
    { lat: 49.0398389, lng: 18.1201989 },
    { lat: 49.040187, lng: 18.1213975 },
    { lat: 49.0402796, lng: 18.1231746 },
    { lat: 49.0403746, lng: 18.1241553 },
    { lat: 49.0404652, lng: 18.1246507 },
    { lat: 49.0405333, lng: 18.1248884 },
    { lat: 49.0405489, lng: 18.1249456 },
    { lat: 49.0406704, lng: 18.12537 },
    { lat: 49.0407448, lng: 18.1261093 },
    { lat: 49.0407854, lng: 18.1262837 },
    { lat: 49.0408851, lng: 18.1268394 },
    { lat: 49.0408821, lng: 18.1274707 },
    { lat: 49.0409243, lng: 18.1276157 },
    { lat: 49.0410152, lng: 18.1277604 },
    { lat: 49.0410722, lng: 18.1278506 },
    { lat: 49.0411389, lng: 18.1284575 },
    { lat: 49.0411439, lng: 18.1285072 },
    { lat: 49.0412594, lng: 18.1295474 },
    { lat: 49.0410026, lng: 18.1305144 },
    { lat: 49.0414089, lng: 18.1311015 },
    { lat: 49.0416148, lng: 18.1317149 },
    { lat: 49.0417253, lng: 18.1322606 },
    { lat: 49.0417346, lng: 18.1323237 },
    { lat: 49.0413028, lng: 18.1332475 },
    { lat: 49.0410561, lng: 18.1337829 },
    { lat: 49.0412957, lng: 18.1345142 },
    { lat: 49.0412223, lng: 18.1347506 },
    { lat: 49.0400415, lng: 18.1364232 },
    { lat: 49.0400196, lng: 18.1364531 },
    { lat: 49.0398758, lng: 18.1368752 },
    { lat: 49.0395857, lng: 18.137634 },
    { lat: 49.0394633, lng: 18.1380003 },
    { lat: 49.0394476, lng: 18.1394288 },
    { lat: 49.0385594, lng: 18.1411881 },
    { lat: 49.0382728, lng: 18.1413432 },
    { lat: 49.0380004, lng: 18.1415896 },
    { lat: 49.0375882, lng: 18.1422121 },
    { lat: 49.0368158, lng: 18.1429067 },
    { lat: 49.0364748, lng: 18.1431569 },
    { lat: 49.0363392, lng: 18.1433889 },
    { lat: 49.0359582, lng: 18.1440813 },
    { lat: 49.0359085, lng: 18.1442176 },
    { lat: 49.0355372, lng: 18.1452351 },
    { lat: 49.035458, lng: 18.145465 },
    { lat: 49.0349937, lng: 18.1464149 },
    { lat: 49.0352101, lng: 18.1460595 },
    { lat: 49.0359598, lng: 18.1458117 },
    { lat: 49.0362705, lng: 18.1454247 },
    { lat: 49.0368027, lng: 18.1459857 },
    { lat: 49.0374361, lng: 18.1465026 },
    { lat: 49.0382279, lng: 18.1466358 },
    { lat: 49.0382918, lng: 18.1466662 },
    { lat: 49.0383905, lng: 18.146889 },
    { lat: 49.0385541, lng: 18.1472833 },
    { lat: 49.0386429, lng: 18.147589 },
    { lat: 49.0386972, lng: 18.1478094 },
    { lat: 49.038659, lng: 18.1479455 },
    { lat: 49.0384518, lng: 18.1482906 },
    { lat: 49.0380197, lng: 18.1491129 },
    { lat: 49.0375637, lng: 18.1497972 },
    { lat: 49.0371558, lng: 18.1502371 },
    { lat: 49.0382603, lng: 18.149703 },
    { lat: 49.037892, lng: 18.1507933 },
    { lat: 49.0378596, lng: 18.1510752 },
    { lat: 49.0379479, lng: 18.1513742 },
    { lat: 49.0378112, lng: 18.1522146 },
    { lat: 49.0377412, lng: 18.1528796 },
    { lat: 49.0376636, lng: 18.153648 },
    { lat: 49.0376067, lng: 18.1542084 },
    { lat: 49.0375677, lng: 18.1546682 },
    { lat: 49.0376634, lng: 18.1558434 },
    { lat: 49.0372942, lng: 18.1570337 },
    { lat: 49.0376999, lng: 18.1575138 },
    { lat: 49.0378555, lng: 18.1596312 },
    { lat: 49.0379541, lng: 18.1617616 },
    { lat: 49.0383385, lng: 18.1629498 },
    { lat: 49.0393113, lng: 18.1634851 },
    { lat: 49.0400987, lng: 18.163674 },
    { lat: 49.0401307, lng: 18.1641944 },
    { lat: 49.0401823, lng: 18.1644048 },
    { lat: 49.0402087, lng: 18.164511 },
    { lat: 49.0403579, lng: 18.1649062 },
    { lat: 49.0403995, lng: 18.1650036 },
    { lat: 49.0404089, lng: 18.1650275 },
    { lat: 49.0405583, lng: 18.1649368 },
    { lat: 49.0410125, lng: 18.1648128 },
    { lat: 49.0410217, lng: 18.1653431 },
    { lat: 49.0411419, lng: 18.1658059 },
    { lat: 49.0411726, lng: 18.1662611 },
    { lat: 49.0412905, lng: 18.1668198 },
    { lat: 49.0414143, lng: 18.1676004 },
    { lat: 49.041504, lng: 18.1690477 },
    { lat: 49.0415377, lng: 18.1692018 },
    { lat: 49.0415547, lng: 18.1692801 },
    { lat: 49.0415204, lng: 18.1697458 },
    { lat: 49.041573, lng: 18.1698546 },
    { lat: 49.0416328, lng: 18.1701502 },
    { lat: 49.0417446, lng: 18.1704465 },
    { lat: 49.0417514, lng: 18.1704654 },
    { lat: 49.0417779, lng: 18.1705363 },
    { lat: 49.0416332, lng: 18.1708659 },
    { lat: 49.0415604, lng: 18.1709731 },
    { lat: 49.0414231, lng: 18.1714527 },
    { lat: 49.0413969, lng: 18.1715447 },
    { lat: 49.0413451, lng: 18.1716257 },
    { lat: 49.0413741, lng: 18.1716995 },
    { lat: 49.0413929, lng: 18.1717499 },
    { lat: 49.0414153, lng: 18.1718063 },
    { lat: 49.0414603, lng: 18.171924 },
    { lat: 49.0410825, lng: 18.1720846 },
    { lat: 49.0410878, lng: 18.1721163 },
    { lat: 49.0411024, lng: 18.1721934 },
    { lat: 49.0411154, lng: 18.1722636 },
    { lat: 49.0411168, lng: 18.1722674 },
    { lat: 49.0411303, lng: 18.1723462 },
    { lat: 49.0411993, lng: 18.1727402 },
    { lat: 49.0412028, lng: 18.1727844 },
    { lat: 49.0414298, lng: 18.1727075 },
    { lat: 49.0414213, lng: 18.1725 },
    { lat: 49.0414165, lng: 18.1723794 },
    { lat: 49.0414133, lng: 18.1723032 },
    { lat: 49.0414123, lng: 18.1722656 },
    { lat: 49.041459, lng: 18.1722925 },
    { lat: 49.0415419, lng: 18.1723401 },
    { lat: 49.0417508, lng: 18.1724609 },
    { lat: 49.0419325, lng: 18.1726576 },
    { lat: 49.042085, lng: 18.1727032 },
    { lat: 49.0421885, lng: 18.1727341 },
    { lat: 49.0429806, lng: 18.1726757 },
    { lat: 49.0432763, lng: 18.1727152 },
    { lat: 49.0434862, lng: 18.1728751 },
    { lat: 49.0436197, lng: 18.1727048 },
    { lat: 49.0435487, lng: 18.1724269 },
    { lat: 49.043597, lng: 18.1723464 },
    { lat: 49.0436402, lng: 18.1722752 },
    { lat: 49.0437169, lng: 18.1723135 },
    { lat: 49.0438431, lng: 18.1723709 },
    { lat: 49.0439087, lng: 18.172314 },
    { lat: 49.044005, lng: 18.1723573 },
    { lat: 49.0441368, lng: 18.1723542 },
    { lat: 49.0443442, lng: 18.1722317 },
    { lat: 49.0443877, lng: 18.1722058 },
    { lat: 49.0444468, lng: 18.1721324 },
    { lat: 49.0445363, lng: 18.1720224 },
    { lat: 49.044697, lng: 18.1719093 },
    { lat: 49.0447024, lng: 18.17191 },
    { lat: 49.0447229, lng: 18.1719131 },
    { lat: 49.0447503, lng: 18.1719163 },
    { lat: 49.0452941, lng: 18.171993 },
    { lat: 49.0455438, lng: 18.1718613 },
    { lat: 49.0464419, lng: 18.1718596 },
    { lat: 49.0468937, lng: 18.1718665 },
    { lat: 49.0471832, lng: 18.171604 },
    { lat: 49.0473309, lng: 18.1714829 },
    { lat: 49.0475849, lng: 18.1712902 },
    { lat: 49.0478799, lng: 18.1712183 },
    { lat: 49.0478982, lng: 18.1712033 },
    { lat: 49.0479235, lng: 18.1712539 },
    { lat: 49.0480426, lng: 18.1712032 },
    { lat: 49.0481743, lng: 18.1711407 },
    { lat: 49.0482833, lng: 18.1710917 },
    { lat: 49.0483317, lng: 18.1710698 },
    { lat: 49.0483892, lng: 18.1710496 },
    { lat: 49.0484995, lng: 18.1710234 },
    { lat: 49.0485828, lng: 18.171003 },
    { lat: 49.0486074, lng: 18.1709994 },
    { lat: 49.0487191, lng: 18.1709964 },
    { lat: 49.048854, lng: 18.1709911 },
    { lat: 49.0488999, lng: 18.17114 },
    { lat: 49.049554, lng: 18.1708398 },
    { lat: 49.0498023, lng: 18.1707996 },
    { lat: 49.0499653, lng: 18.1707107 },
    { lat: 49.0501941, lng: 18.1705282 },
    { lat: 49.0504365, lng: 18.1709194 },
    { lat: 49.0506293, lng: 18.1707405 },
    { lat: 49.0509262, lng: 18.1705241 },
    { lat: 49.0510201, lng: 18.1703512 },
    { lat: 49.0510625, lng: 18.1701463 },
    { lat: 49.0512669, lng: 18.1694449 },
    { lat: 49.051351, lng: 18.1690402 },
    { lat: 49.0513856, lng: 18.1690738 },
    { lat: 49.0518342, lng: 18.1694998 },
    { lat: 49.0521022, lng: 18.1691714 },
    { lat: 49.0526941, lng: 18.168888 },
    { lat: 49.0529183, lng: 18.1681348 },
    { lat: 49.0530211, lng: 18.1680024 },
    { lat: 49.053181, lng: 18.1677196 },
    { lat: 49.0532442, lng: 18.1672302 },
    { lat: 49.0528662, lng: 18.1668567 },
    { lat: 49.0528376, lng: 18.1668281 },
    { lat: 49.0526714, lng: 18.1666638 },
    { lat: 49.0529777, lng: 18.1660471 },
    { lat: 49.0530558, lng: 18.1658353 },
    { lat: 49.0532805, lng: 18.1654622 },
    { lat: 49.0534597, lng: 18.1651851 },
    { lat: 49.0536558, lng: 18.1650418 },
    { lat: 49.0537077, lng: 18.1650032 },
    { lat: 49.0537381, lng: 18.1649812 },
    { lat: 49.0537998, lng: 18.1649356 },
    { lat: 49.0535879, lng: 18.1637254 },
    { lat: 49.0535145, lng: 18.1631347 },
    { lat: 49.0533993, lng: 18.1624629 },
    { lat: 49.0527817, lng: 18.1606891 },
    { lat: 49.0529594, lng: 18.1603661 },
    { lat: 49.0533581, lng: 18.1595641 },
    { lat: 49.0536493, lng: 18.1589418 },
    { lat: 49.0538613, lng: 18.1581347 },
    { lat: 49.054011, lng: 18.1575384 },
    { lat: 49.0542643, lng: 18.1567826 },
    { lat: 49.0547668, lng: 18.1565114 },
    { lat: 49.0555427, lng: 18.1564682 },
    { lat: 49.055781, lng: 18.1568822 },
    { lat: 49.0564458, lng: 18.1572956 },
    { lat: 49.0567763, lng: 18.1568418 },
    { lat: 49.0566358, lng: 18.1565576 },
    { lat: 49.055827, lng: 18.1558205 },
    { lat: 49.055442, lng: 18.1555976 },
    { lat: 49.0554474, lng: 18.1554983 },
    { lat: 49.0549862, lng: 18.1553233 },
    { lat: 49.0549212, lng: 18.1549787 },
    { lat: 49.0543641, lng: 18.1544143 },
    { lat: 49.0542812, lng: 18.1537007 },
    { lat: 49.0542792, lng: 18.1536873 },
    { lat: 49.0542733, lng: 18.1535064 },
    { lat: 49.0541743, lng: 18.1529041 },
    { lat: 49.0540932, lng: 18.1526688 },
    { lat: 49.054069, lng: 18.1524824 },
    { lat: 49.0541041, lng: 18.1521147 },
    { lat: 49.0539683, lng: 18.1513625 },
    { lat: 49.0539058, lng: 18.1511395 },
    { lat: 49.0538721, lng: 18.1508663 },
    { lat: 49.0537051, lng: 18.1498654 },
    { lat: 49.0537005, lng: 18.1497292 },
    { lat: 49.0538072, lng: 18.1490937 },
    { lat: 49.0538888, lng: 18.1488835 },
    { lat: 49.0539933, lng: 18.1486789 },
    { lat: 49.0542931, lng: 18.1482432 },
    { lat: 49.0544269, lng: 18.1481563 },
    { lat: 49.0552664, lng: 18.1485467 },
    { lat: 49.055347, lng: 18.149807 },
    { lat: 49.0562708, lng: 18.1487431 },
    { lat: 49.0564015, lng: 18.1486026 },
    { lat: 49.0564897, lng: 18.1485077 },
    { lat: 49.0568336, lng: 18.1481733 },
    { lat: 49.0574809, lng: 18.1481464 },
    { lat: 49.0584799, lng: 18.1485245 },
    { lat: 49.0584451, lng: 18.1478419 },
    { lat: 49.0590822, lng: 18.1454031 },
    { lat: 49.0596844, lng: 18.1432445 },
    { lat: 49.0598588, lng: 18.1409245 },
    { lat: 49.059954, lng: 18.1405204 },
    { lat: 49.0605596, lng: 18.1409224 },
    { lat: 49.0608986, lng: 18.1411478 },
    { lat: 49.0613166, lng: 18.1406652 },
    { lat: 49.0614505, lng: 18.1406009 },
    { lat: 49.0621715, lng: 18.1396418 },
    { lat: 49.0625263, lng: 18.1391814 },
    { lat: 49.062505, lng: 18.1389104 },
    { lat: 49.0626164, lng: 18.1386278 },
    { lat: 49.0627914, lng: 18.1385339 },
    { lat: 49.0630842, lng: 18.1384441 },
    { lat: 49.0630826, lng: 18.1383752 },
    { lat: 49.0630471, lng: 18.1381324 },
    { lat: 49.0615491, lng: 18.1379268 },
    { lat: 49.0608717, lng: 18.1373901 },
    { lat: 49.0608698, lng: 18.1359888 },
    { lat: 49.0608878, lng: 18.1355212 },
    { lat: 49.0613113, lng: 18.1348982 },
    { lat: 49.0616828, lng: 18.1344161 },
    { lat: 49.0618794, lng: 18.1341261 },
    { lat: 49.061945, lng: 18.1341695 },
    { lat: 49.0622582, lng: 18.1342158 },
    { lat: 49.0627153, lng: 18.1345329 },
    { lat: 49.0637826, lng: 18.1334947 },
    { lat: 49.0647951, lng: 18.132228 },
    { lat: 49.064829, lng: 18.1321885 },
    { lat: 49.0648764, lng: 18.1317799 },
    { lat: 49.0663987, lng: 18.130079 },
    { lat: 49.0660217, lng: 18.1274567 },
    { lat: 49.0660161, lng: 18.1274168 },
    { lat: 49.0668118, lng: 18.1275972 },
    { lat: 49.0674772, lng: 18.1266513 },
    { lat: 49.0675156, lng: 18.1265954 },
    { lat: 49.0666067, lng: 18.125885 },
    { lat: 49.0664329, lng: 18.1256675 },
    { lat: 49.0658355, lng: 18.1249164 },
    { lat: 49.0656346, lng: 18.1246644 },
    { lat: 49.0642595, lng: 18.123341 },
    { lat: 49.0645387, lng: 18.1226699 },
    { lat: 49.0638716, lng: 18.1222917 },
    { lat: 49.0632079, lng: 18.1211563 },
    { lat: 49.0632584, lng: 18.1210157 },
    { lat: 49.0631875, lng: 18.1206474 },
    { lat: 49.0625035, lng: 18.1202769 },
    { lat: 49.061708, lng: 18.1198465 },
    { lat: 49.0591555, lng: 18.1204707 },
    { lat: 49.058993, lng: 18.1205104 },
    { lat: 49.0589039, lng: 18.1205321 },
    { lat: 49.0582464, lng: 18.1207349 },
    { lat: 49.0573662, lng: 18.121006 },
    { lat: 49.0561553, lng: 18.1197148 },
    { lat: 49.0557276, lng: 18.1193637 },
    { lat: 49.055759, lng: 18.1191062 },
    { lat: 49.0556336, lng: 18.1187725 },
    { lat: 49.0555023, lng: 18.1184742 },
    { lat: 49.0549945, lng: 18.1175186 },
    { lat: 49.0548453, lng: 18.1172993 },
    { lat: 49.0545668, lng: 18.1169729 },
    { lat: 49.0540923, lng: 18.1165899 },
    { lat: 49.053818, lng: 18.1159272 },
    { lat: 49.0532559, lng: 18.1150282 },
    { lat: 49.0528653, lng: 18.1145348 },
    { lat: 49.0523721, lng: 18.1142259 },
    { lat: 49.0517978, lng: 18.1141852 },
    { lat: 49.0512903, lng: 18.1137229 },
    { lat: 49.0505318, lng: 18.1125111 },
    { lat: 49.0501874, lng: 18.1117297 },
    { lat: 49.0496881, lng: 18.1102382 },
    { lat: 49.0488791, lng: 18.107901 },
    { lat: 49.0490082, lng: 18.1078319 },
    { lat: 49.0488836, lng: 18.1072773 },
    { lat: 49.048684, lng: 18.106853 },
    { lat: 49.0487215, lng: 18.1063497 },
    { lat: 49.0485882, lng: 18.1058027 },
    { lat: 49.0485029, lng: 18.1044302 },
    { lat: 49.0483281, lng: 18.1029528 },
    { lat: 49.0482086, lng: 18.1023508 },
    { lat: 49.0480572, lng: 18.1021337 },
    { lat: 49.0473561, lng: 18.1014244 },
    { lat: 49.04674, lng: 18.1005146 },
    { lat: 49.046154, lng: 18.0996331 },
    { lat: 49.045659, lng: 18.0990377 },
    { lat: 49.0448613, lng: 18.0981994 },
    { lat: 49.04447, lng: 18.0976623 },
    { lat: 49.044003, lng: 18.097314 },
    { lat: 49.04398, lng: 18.097419 },
    { lat: 49.043638, lng: 18.098186 },
    { lat: 49.043402, lng: 18.09869 },
    { lat: 49.043213, lng: 18.099244 },
    { lat: 49.043068, lng: 18.099752 },
    { lat: 49.042889, lng: 18.100933 },
    { lat: 49.042849, lng: 18.10099 },
    { lat: 49.042447, lng: 18.100565 },
    { lat: 49.041274, lng: 18.099481 },
    { lat: 49.04067, lng: 18.098298 },
    { lat: 49.040332, lng: 18.097706 },
    { lat: 49.039839, lng: 18.09703 },
    { lat: 49.039646, lng: 18.096649 },
    { lat: 49.03932, lng: 18.096198 },
    { lat: 49.039009, lng: 18.095855 },
    { lat: 49.038731, lng: 18.095475 },
    { lat: 49.038332, lng: 18.094776 },
    { lat: 49.037741, lng: 18.094297 },
    { lat: 49.037277, lng: 18.094189 },
    { lat: 49.037085, lng: 18.094075 },
    { lat: 49.03654, lng: 18.093973 },
    { lat: 49.035795, lng: 18.094301 },
    { lat: 49.035253, lng: 18.094359 },
    { lat: 49.034984, lng: 18.094483 },
    { lat: 49.034454, lng: 18.09484 },
    { lat: 49.034419, lng: 18.094852 },
    { lat: 49.034167, lng: 18.095075 },
    { lat: 49.034099, lng: 18.095532 },
    { lat: 49.03312, lng: 18.096357 },
    { lat: 49.0331, lng: 18.096373 },
    { lat: 49.0332635, lng: 18.0965605 },
    { lat: 49.0335853, lng: 18.0969921 },
    { lat: 49.0335596, lng: 18.0970541 },
    { lat: 49.0338983, lng: 18.097328 },
    { lat: 49.0341066, lng: 18.097418 },
    { lat: 49.0343096, lng: 18.097513 },
    { lat: 49.0349741, lng: 18.0992002 },
    { lat: 49.0347714, lng: 18.0999294 },
    { lat: 49.0347649, lng: 18.1002439 },
    { lat: 49.034176, lng: 18.1013347 },
    { lat: 49.0343913, lng: 18.1027926 },
    { lat: 49.0344623, lng: 18.1034703 },
    { lat: 49.0344383, lng: 18.1040317 },
    { lat: 49.0344532, lng: 18.1050055 },
    { lat: 49.0346749, lng: 18.1058738 },
    { lat: 49.0347781, lng: 18.1067559 },
    { lat: 49.0349899, lng: 18.1075705 },
    { lat: 49.0350059, lng: 18.1076712 },
    { lat: 49.0348746, lng: 18.1085768 },
    { lat: 49.0359672, lng: 18.1095737 },
    { lat: 49.0364008, lng: 18.1101436 },
    { lat: 49.0372306, lng: 18.1110876 },
    { lat: 49.0374918, lng: 18.1117968 },
    { lat: 49.0377378, lng: 18.1132018 },
    { lat: 49.037943, lng: 18.1140551 },
    { lat: 49.037961, lng: 18.1141322 },
    { lat: 49.0379741, lng: 18.1141837 },
    { lat: 49.0379861, lng: 18.114235 },
    { lat: 49.0379873, lng: 18.1143139 },
    { lat: 49.0379877, lng: 18.1144387 },
    { lat: 49.0380822, lng: 18.1150749 },
  ],
  Mikušovce: [
    { lat: 49.057098, lng: 18.229099 },
    { lat: 49.057464, lng: 18.229127 },
    { lat: 49.057642, lng: 18.228691 },
    { lat: 49.057859, lng: 18.227808 },
    { lat: 49.058049, lng: 18.227215 },
    { lat: 49.058615, lng: 18.226129 },
    { lat: 49.05872, lng: 18.225599 },
    { lat: 49.058819, lng: 18.225004 },
    { lat: 49.058949, lng: 18.22426 },
    { lat: 49.059137, lng: 18.223607 },
    { lat: 49.059441, lng: 18.222653 },
    { lat: 49.059944, lng: 18.221212 },
    { lat: 49.059958, lng: 18.221169 },
    { lat: 49.059984, lng: 18.221096 },
    { lat: 49.060022, lng: 18.220988 },
    { lat: 49.06027, lng: 18.220604 },
    { lat: 49.060473, lng: 18.220474 },
    { lat: 49.060934, lng: 18.220256 },
    { lat: 49.061294, lng: 18.22 },
    { lat: 49.061421, lng: 18.21979 },
    { lat: 49.061694, lng: 18.218786 },
    { lat: 49.062015, lng: 18.218528 },
    { lat: 49.062343, lng: 18.218378 },
    { lat: 49.062676, lng: 18.21835 },
    { lat: 49.062914, lng: 18.218412 },
    { lat: 49.063126, lng: 18.218345 },
    { lat: 49.063671, lng: 18.218174 },
    { lat: 49.063993, lng: 18.217995 },
    { lat: 49.064461, lng: 18.217607 },
    { lat: 49.064523, lng: 18.217568 },
    { lat: 49.0651, lng: 18.217201 },
    { lat: 49.065296, lng: 18.217119 },
    { lat: 49.065598, lng: 18.217142 },
    { lat: 49.066237, lng: 18.217305 },
    { lat: 49.066564, lng: 18.217218 },
    { lat: 49.067142, lng: 18.216827 },
    { lat: 49.06763, lng: 18.216584 },
    { lat: 49.06801, lng: 18.21646 },
    { lat: 49.068397, lng: 18.216467 },
    { lat: 49.069126, lng: 18.216611 },
    { lat: 49.069607, lng: 18.216601 },
    { lat: 49.069723, lng: 18.216589 },
    { lat: 49.069766, lng: 18.216585 },
    { lat: 49.070551, lng: 18.216504 },
    { lat: 49.071042, lng: 18.216467 },
    { lat: 49.071738, lng: 18.216203 },
    { lat: 49.072404, lng: 18.216103 },
    { lat: 49.073141, lng: 18.21586 },
    { lat: 49.073916, lng: 18.21555 },
    { lat: 49.074155, lng: 18.215393 },
    { lat: 49.074207, lng: 18.215324 },
    { lat: 49.07458, lng: 18.214831 },
    { lat: 49.075146, lng: 18.214279 },
    { lat: 49.075208, lng: 18.214214 },
    { lat: 49.075784, lng: 18.213599 },
    { lat: 49.077579, lng: 18.212239 },
    { lat: 49.078285, lng: 18.211667 },
    { lat: 49.079119, lng: 18.210193 },
    { lat: 49.079218, lng: 18.210018 },
    { lat: 49.079502, lng: 18.20947 },
    { lat: 49.079761, lng: 18.20911 },
    { lat: 49.08018, lng: 18.20866 },
    { lat: 49.080578, lng: 18.208398 },
    { lat: 49.081122, lng: 18.208417 },
    { lat: 49.081814, lng: 18.20828 },
    { lat: 49.081799, lng: 18.208211 },
    { lat: 49.082999, lng: 18.207081 },
    { lat: 49.083615, lng: 18.20691 },
    { lat: 49.084647, lng: 18.205811 },
    { lat: 49.085014, lng: 18.205183 },
    { lat: 49.085696, lng: 18.204061 },
    { lat: 49.085859, lng: 18.202969 },
    { lat: 49.08586, lng: 18.202961 },
    { lat: 49.086377, lng: 18.201944 },
    { lat: 49.086619, lng: 18.201321 },
    { lat: 49.086724, lng: 18.201179 },
    { lat: 49.086841, lng: 18.201022 },
    { lat: 49.086923, lng: 18.200912 },
    { lat: 49.087257, lng: 18.200462 },
    { lat: 49.087608, lng: 18.199618 },
    { lat: 49.088393, lng: 18.199366 },
    { lat: 49.088544, lng: 18.199276 },
    { lat: 49.08892, lng: 18.199145 },
    { lat: 49.089298, lng: 18.198928 },
    { lat: 49.089562, lng: 18.19847 },
    { lat: 49.08987, lng: 18.19859 },
    { lat: 49.09009, lng: 18.198397 },
    { lat: 49.090289, lng: 18.198129 },
    { lat: 49.090544, lng: 18.197908 },
    { lat: 49.0903987, lng: 18.1973616 },
    { lat: 49.0899473, lng: 18.1973976 },
    { lat: 49.0875441, lng: 18.1974421 },
    { lat: 49.085052, lng: 18.194938 },
    { lat: 49.085041, lng: 18.1948663 },
    { lat: 49.0843546, lng: 18.1941283 },
    { lat: 49.0836999, lng: 18.1947956 },
    { lat: 49.0832757, lng: 18.1945389 },
    { lat: 49.0831077, lng: 18.1946015 },
    { lat: 49.0825337, lng: 18.1942099 },
    { lat: 49.0821607, lng: 18.1946135 },
    { lat: 49.0817082, lng: 18.1949287 },
    { lat: 49.0810862, lng: 18.1951039 },
    { lat: 49.0809, lng: 18.1947675 },
    { lat: 49.0796119, lng: 18.195446 },
    { lat: 49.0787223, lng: 18.194724 },
    { lat: 49.0793387, lng: 18.1943775 },
    { lat: 49.0791727, lng: 18.1937235 },
    { lat: 49.079267, lng: 18.1936553 },
    { lat: 49.0793939, lng: 18.1935629 },
    { lat: 49.079399, lng: 18.1935748 },
    { lat: 49.0795609, lng: 18.1934662 },
    { lat: 49.0796922, lng: 18.1933765 },
    { lat: 49.0796821, lng: 18.193357 },
    { lat: 49.0803711, lng: 18.1928554 },
    { lat: 49.0800564, lng: 18.1920703 },
    { lat: 49.0799694, lng: 18.1919871 },
    { lat: 49.0798536, lng: 18.1918761 },
    { lat: 49.0798204, lng: 18.1918456 },
    { lat: 49.0797868, lng: 18.191813 },
    { lat: 49.0797308, lng: 18.19176 },
    { lat: 49.0789833, lng: 18.1905096 },
    { lat: 49.0784892, lng: 18.1896832 },
    { lat: 49.0779759, lng: 18.1899801 },
    { lat: 49.0776369, lng: 18.1883323 },
    { lat: 49.0778144, lng: 18.1879053 },
    { lat: 49.0777769, lng: 18.1876832 },
    { lat: 49.0781281, lng: 18.1874703 },
    { lat: 49.0783817, lng: 18.1875051 },
    { lat: 49.0789804, lng: 18.1874779 },
    { lat: 49.0796191, lng: 18.1871434 },
    { lat: 49.080411, lng: 18.1868632 },
    { lat: 49.0804023, lng: 18.1868107 },
    { lat: 49.0800391, lng: 18.1848219 },
    { lat: 49.0790815, lng: 18.1824643 },
    { lat: 49.0778458, lng: 18.1816708 },
    { lat: 49.0768992, lng: 18.1810402 },
    { lat: 49.0758989, lng: 18.1801673 },
    { lat: 49.0748358, lng: 18.1792388 },
    { lat: 49.0741681, lng: 18.1790643 },
    { lat: 49.0724145, lng: 18.1781756 },
    { lat: 49.0709922, lng: 18.1762767 },
    { lat: 49.0709715, lng: 18.1762496 },
    { lat: 49.0709582, lng: 18.1762316 },
    { lat: 49.070917, lng: 18.1764584 },
    { lat: 49.0702884, lng: 18.1760636 },
    { lat: 49.0693616, lng: 18.1755457 },
    { lat: 49.0691649, lng: 18.1732161 },
    { lat: 49.0693253, lng: 18.172981 },
    { lat: 49.0695257, lng: 18.1727572 },
    { lat: 49.0697412, lng: 18.172639 },
    { lat: 49.0702192, lng: 18.172221 },
    { lat: 49.070502, lng: 18.17164 },
    { lat: 49.0707307, lng: 18.1714189 },
    { lat: 49.0710004, lng: 18.1710826 },
    { lat: 49.0713267, lng: 18.1709126 },
    { lat: 49.0714428, lng: 18.1708521 },
    { lat: 49.0714159, lng: 18.1706174 },
    { lat: 49.0708904, lng: 18.1703479 },
    { lat: 49.0706696, lng: 18.1703157 },
    { lat: 49.0705718, lng: 18.1703013 },
    { lat: 49.06998, lng: 18.1702152 },
    { lat: 49.0696739, lng: 18.1701669 },
    { lat: 49.0693698, lng: 18.169705 },
    { lat: 49.069306, lng: 18.1695997 },
    { lat: 49.0689122, lng: 18.1691061 },
    { lat: 49.0687701, lng: 18.1689505 },
    { lat: 49.0685625, lng: 18.1688726 },
    { lat: 49.0682553, lng: 18.1690646 },
    { lat: 49.0680895, lng: 18.1690068 },
    { lat: 49.0674066, lng: 18.1687661 },
    { lat: 49.0662991, lng: 18.1694221 },
    { lat: 49.0660408, lng: 18.1695655 },
    { lat: 49.065727, lng: 18.1697169 },
    { lat: 49.065282, lng: 18.1702813 },
    { lat: 49.0650526, lng: 18.1705691 },
    { lat: 49.0646802, lng: 18.1711799 },
    { lat: 49.064673, lng: 18.1711931 },
    { lat: 49.0644432, lng: 18.1716108 },
    { lat: 49.064054, lng: 18.1719691 },
    { lat: 49.0639687, lng: 18.1722305 },
    { lat: 49.0639043, lng: 18.1725244 },
    { lat: 49.0636661, lng: 18.172895 },
    { lat: 49.0634517, lng: 18.1731296 },
    { lat: 49.0634021, lng: 18.1732093 },
    { lat: 49.0633805, lng: 18.1733647 },
    { lat: 49.0633977, lng: 18.1737424 },
    { lat: 49.0633782, lng: 18.1741901 },
    { lat: 49.0630919, lng: 18.1753004 },
    { lat: 49.063101, lng: 18.1758519 },
    { lat: 49.0633213, lng: 18.1765264 },
    { lat: 49.0634686, lng: 18.1768725 },
    { lat: 49.0634291, lng: 18.177067 },
    { lat: 49.0628625, lng: 18.1773217 },
    { lat: 49.0627243, lng: 18.1774675 },
    { lat: 49.0626961, lng: 18.1777267 },
    { lat: 49.0626505, lng: 18.1793123 },
    { lat: 49.0627362, lng: 18.1808482 },
    { lat: 49.0627762, lng: 18.1812128 },
    { lat: 49.0627554, lng: 18.181733 },
    { lat: 49.062621, lng: 18.1818303 },
    { lat: 49.0624846, lng: 18.182029 },
    { lat: 49.0620636, lng: 18.1828725 },
    { lat: 49.0618204, lng: 18.1836715 },
    { lat: 49.061386, lng: 18.1844797 },
    { lat: 49.0609229, lng: 18.184907 },
    { lat: 49.0598568, lng: 18.1867832 },
    { lat: 49.0597868, lng: 18.1874686 },
    { lat: 49.0597257, lng: 18.1881776 },
    { lat: 49.0594772, lng: 18.1887232 },
    { lat: 49.0591209, lng: 18.1888477 },
    { lat: 49.0584687, lng: 18.1891223 },
    { lat: 49.0581456, lng: 18.1893151 },
    { lat: 49.0579082, lng: 18.1895992 },
    { lat: 49.0577822, lng: 18.1897871 },
    { lat: 49.0576241, lng: 18.1901765 },
    { lat: 49.0575732, lng: 18.1903937 },
    { lat: 49.0574869, lng: 18.1910358 },
    { lat: 49.0573895, lng: 18.1912836 },
    { lat: 49.0572282, lng: 18.1915799 },
    { lat: 49.0565372, lng: 18.1922639 },
    { lat: 49.0562036, lng: 18.1924876 },
    { lat: 49.0557338, lng: 18.192716 },
    { lat: 49.0553466, lng: 18.193068 },
    { lat: 49.0548774, lng: 18.1936054 },
    { lat: 49.0547559, lng: 18.1938496 },
    { lat: 49.0546689, lng: 18.1941633 },
    { lat: 49.0546111, lng: 18.1946127 },
    { lat: 49.0546211, lng: 18.1951794 },
    { lat: 49.0546088, lng: 18.1956401 },
    { lat: 49.0545308, lng: 18.1959915 },
    { lat: 49.0544757, lng: 18.1961322 },
    { lat: 49.0542057, lng: 18.1963633 },
    { lat: 49.0538406, lng: 18.1968635 },
    { lat: 49.0534541, lng: 18.1972704 },
    { lat: 49.0533194, lng: 18.1975364 },
    { lat: 49.053248, lng: 18.1977686 },
    { lat: 49.0532365, lng: 18.1980809 },
    { lat: 49.0532711, lng: 18.1982929 },
    { lat: 49.0533769, lng: 18.198662 },
    { lat: 49.0533251, lng: 18.1989189 },
    { lat: 49.0532205, lng: 18.1991043 },
    { lat: 49.0530641, lng: 18.1992711 },
    { lat: 49.0524016, lng: 18.1997352 },
    { lat: 49.0520582, lng: 18.2000103 },
    { lat: 49.0527034, lng: 18.2015661 },
    { lat: 49.0531927, lng: 18.2025489 },
    { lat: 49.0521022, lng: 18.2031528 },
    { lat: 49.0516875, lng: 18.2035757 },
    { lat: 49.0523305, lng: 18.2044814 },
    { lat: 49.0523709, lng: 18.2045386 },
    { lat: 49.0528164, lng: 18.2050481 },
    { lat: 49.0528586, lng: 18.2050955 },
    { lat: 49.052984, lng: 18.205239 },
    { lat: 49.0532196, lng: 18.2053316 },
    { lat: 49.0536282, lng: 18.2073695 },
    { lat: 49.0540058, lng: 18.2072038 },
    { lat: 49.0545559, lng: 18.2068789 },
    { lat: 49.0545733, lng: 18.2068691 },
    { lat: 49.0546071, lng: 18.2068491 },
    { lat: 49.0545787, lng: 18.207299 },
    { lat: 49.0546141, lng: 18.2082973 },
    { lat: 49.0545867, lng: 18.2084297 },
    { lat: 49.0545838, lng: 18.2084754 },
    { lat: 49.0545769, lng: 18.2085734 },
    { lat: 49.0545753, lng: 18.2086032 },
    { lat: 49.054876, lng: 18.2109687 },
    { lat: 49.0549219, lng: 18.2111192 },
    { lat: 49.0549454, lng: 18.2111952 },
    { lat: 49.0549965, lng: 18.2113615 },
    { lat: 49.0546842, lng: 18.211661 },
    { lat: 49.0543381, lng: 18.2117456 },
    { lat: 49.0540017, lng: 18.2116623 },
    { lat: 49.0535209, lng: 18.2119765 },
    { lat: 49.0538697, lng: 18.213445 },
    { lat: 49.0541119, lng: 18.2144656 },
    { lat: 49.0545807, lng: 18.2164356 },
    { lat: 49.0547143, lng: 18.2188016 },
    { lat: 49.0548809, lng: 18.2186966 },
    { lat: 49.0550837, lng: 18.2186907 },
    { lat: 49.0552461, lng: 18.2187283 },
    { lat: 49.0554725, lng: 18.2188592 },
    { lat: 49.0559981, lng: 18.2202045 },
    { lat: 49.056828, lng: 18.2211473 },
    { lat: 49.0571029, lng: 18.2210992 },
    { lat: 49.0572692, lng: 18.2224556 },
    { lat: 49.0574286, lng: 18.2236901 },
    { lat: 49.057137, lng: 18.2256234 },
    { lat: 49.0568416, lng: 18.2271927 },
    { lat: 49.0576474, lng: 18.2276514 },
    { lat: 49.057098, lng: 18.229099 },
  ],
  Pruské: [
    { lat: 49.0298533, lng: 18.2391494 },
    { lat: 49.0281462, lng: 18.2372431 },
    { lat: 49.0268629, lng: 18.2352106 },
    { lat: 49.0268409, lng: 18.2351808 },
    { lat: 49.0268169, lng: 18.2351329 },
    { lat: 49.0267999, lng: 18.2350931 },
    { lat: 49.0284009, lng: 18.2333861 },
    { lat: 49.0293041, lng: 18.2324253 },
    { lat: 49.0302279, lng: 18.2314293 },
    { lat: 49.0302536, lng: 18.2314022 },
    { lat: 49.0304353, lng: 18.2312053 },
    { lat: 49.0306213, lng: 18.2310043 },
    { lat: 49.031035, lng: 18.2304846 },
    { lat: 49.0310751, lng: 18.2304153 },
    { lat: 49.0311481, lng: 18.2302892 },
    { lat: 49.0311583, lng: 18.2302706 },
    { lat: 49.0312169, lng: 18.2301701 },
    { lat: 49.0312314, lng: 18.2301452 },
    { lat: 49.0309518, lng: 18.2296538 },
    { lat: 49.0307982, lng: 18.2293342 },
    { lat: 49.0304581, lng: 18.2283567 },
    { lat: 49.0303796, lng: 18.2280661 },
    { lat: 49.0302315, lng: 18.2276232 },
    { lat: 49.0302026, lng: 18.2275511 },
    { lat: 49.0301553, lng: 18.2274351 },
    { lat: 49.0306968, lng: 18.2268385 },
    { lat: 49.0307918, lng: 18.2267458 },
    { lat: 49.0312004, lng: 18.2262691 },
    { lat: 49.0312285, lng: 18.2253388 },
    { lat: 49.0316144, lng: 18.2237734 },
    { lat: 49.0317439, lng: 18.2234892 },
    { lat: 49.0326696, lng: 18.2223486 },
    { lat: 49.0334962, lng: 18.22066 },
    { lat: 49.0336873, lng: 18.2202712 },
    { lat: 49.0347789, lng: 18.2180421 },
    { lat: 49.0349244, lng: 18.2177453 },
    { lat: 49.035154, lng: 18.2172777 },
    { lat: 49.0356535, lng: 18.2162635 },
    { lat: 49.0356583, lng: 18.2162475 },
    { lat: 49.0356777, lng: 18.2162083 },
    { lat: 49.0356977, lng: 18.2161678 },
    { lat: 49.0357039, lng: 18.2161559 },
    { lat: 49.0362333, lng: 18.2162608 },
    { lat: 49.0365402, lng: 18.2162603 },
    { lat: 49.036796, lng: 18.215978 },
    { lat: 49.0371025, lng: 18.2155143 },
    { lat: 49.0373928, lng: 18.2149657 },
    { lat: 49.0377338, lng: 18.2143159 },
    { lat: 49.0385143, lng: 18.2130067 },
    { lat: 49.0379938, lng: 18.2121296 },
    { lat: 49.0369745, lng: 18.211114 },
    { lat: 49.0369977, lng: 18.211066 },
    { lat: 49.0370056, lng: 18.2110466 },
    { lat: 49.037618, lng: 18.2105871 },
    { lat: 49.0378465, lng: 18.2102987 },
    { lat: 49.0382765, lng: 18.2097079 },
    { lat: 49.0385732, lng: 18.2092724 },
    { lat: 49.0392818, lng: 18.2086016 },
    { lat: 49.0397317, lng: 18.2080735 },
    { lat: 49.0397671, lng: 18.2080279 },
    { lat: 49.0404597, lng: 18.2075534 },
    { lat: 49.0407257, lng: 18.2073712 },
    { lat: 49.040952, lng: 18.2072629 },
    { lat: 49.0413089, lng: 18.2069712 },
    { lat: 49.0422443, lng: 18.206123 },
    { lat: 49.0426848, lng: 18.205262 },
    { lat: 49.0427416, lng: 18.2052088 },
    { lat: 49.0428413, lng: 18.2051205 },
    { lat: 49.0430371, lng: 18.2049472 },
    { lat: 49.0431843, lng: 18.2046773 },
    { lat: 49.0432066, lng: 18.2046363 },
    { lat: 49.043334, lng: 18.2043997 },
    { lat: 49.0435178, lng: 18.2040611 },
    { lat: 49.0435942, lng: 18.2038172 },
    { lat: 49.0438683, lng: 18.2029444 },
    { lat: 49.0444842, lng: 18.2018566 },
    { lat: 49.0443687, lng: 18.2016503 },
    { lat: 49.0450379, lng: 18.20039 },
    { lat: 49.0464278, lng: 18.2016702 },
    { lat: 49.0464945, lng: 18.2017322 },
    { lat: 49.0467354, lng: 18.2012072 },
    { lat: 49.0471743, lng: 18.2007143 },
    { lat: 49.0475574, lng: 18.200191 },
    { lat: 49.0480433, lng: 18.1992146 },
    { lat: 49.0481685, lng: 18.198861 },
    { lat: 49.0482345, lng: 18.1986339 },
    { lat: 49.0482481, lng: 18.1978458 },
    { lat: 49.0483005, lng: 18.1975173 },
    { lat: 49.0481941, lng: 18.1962889 },
    { lat: 49.0488771, lng: 18.1945377 },
    { lat: 49.0493266, lng: 18.1939721 },
    { lat: 49.0495203, lng: 18.1937281 },
    { lat: 49.0503324, lng: 18.1932318 },
    { lat: 49.0511228, lng: 18.1921552 },
    { lat: 49.0513945, lng: 18.191817 },
    { lat: 49.0520477, lng: 18.1916748 },
    { lat: 49.0527279, lng: 18.191711 },
    { lat: 49.0535154, lng: 18.1918096 },
    { lat: 49.0546665, lng: 18.1908844 },
    { lat: 49.0551428, lng: 18.1900961 },
    { lat: 49.0547794, lng: 18.1883209 },
    { lat: 49.055208, lng: 18.1873309 },
    { lat: 49.0553332, lng: 18.1869373 },
    { lat: 49.0555262, lng: 18.1863275 },
    { lat: 49.0566299, lng: 18.1856203 },
    { lat: 49.0574908, lng: 18.185009 },
    { lat: 49.057393, lng: 18.1845442 },
    { lat: 49.0572404, lng: 18.1838279 },
    { lat: 49.0570505, lng: 18.1831332 },
    { lat: 49.0572641, lng: 18.1826278 },
    { lat: 49.0577117, lng: 18.1817733 },
    { lat: 49.0580474, lng: 18.1813719 },
    { lat: 49.0584348, lng: 18.1811219 },
    { lat: 49.0590149, lng: 18.1807888 },
    { lat: 49.0601712, lng: 18.1798993 },
    { lat: 49.0601975, lng: 18.1797684 },
    { lat: 49.0602257, lng: 18.1796294 },
    { lat: 49.0602362, lng: 18.1795765 },
    { lat: 49.0602619, lng: 18.1794508 },
    { lat: 49.0603023, lng: 18.1792522 },
    { lat: 49.0602629, lng: 18.1785814 },
    { lat: 49.0601685, lng: 18.1783341 },
    { lat: 49.0601101, lng: 18.1781814 },
    { lat: 49.059822, lng: 18.1784888 },
    { lat: 49.0597151, lng: 18.1780834 },
    { lat: 49.0595242, lng: 18.1778799 },
    { lat: 49.0594362, lng: 18.1778905 },
    { lat: 49.059318, lng: 18.1779048 },
    { lat: 49.058922, lng: 18.177826 },
    { lat: 49.0587614, lng: 18.1778621 },
    { lat: 49.0584551, lng: 18.1780849 },
    { lat: 49.0582697, lng: 18.1782198 },
    { lat: 49.0577989, lng: 18.1788669 },
    { lat: 49.0576521, lng: 18.1789836 },
    { lat: 49.0576204, lng: 18.1788795 },
    { lat: 49.0573893, lng: 18.1781193 },
    { lat: 49.0573092, lng: 18.1776043 },
    { lat: 49.0572877, lng: 18.1774687 },
    { lat: 49.0571634, lng: 18.1766774 },
    { lat: 49.0572045, lng: 18.1762309 },
    { lat: 49.0572894, lng: 18.175155 },
    { lat: 49.0574171, lng: 18.1748215 },
    { lat: 49.0574442, lng: 18.1742919 },
    { lat: 49.0578445, lng: 18.1737771 },
    { lat: 49.0578754, lng: 18.1737054 },
    { lat: 49.0580302, lng: 18.1731962 },
    { lat: 49.0580555, lng: 18.1727313 },
    { lat: 49.0581488, lng: 18.1724688 },
    { lat: 49.058297, lng: 18.1718091 },
    { lat: 49.058434, lng: 18.1714818 },
    { lat: 49.0586274, lng: 18.1712338 },
    { lat: 49.0587451, lng: 18.1708454 },
    { lat: 49.0588487, lng: 18.1703718 },
    { lat: 49.0588436, lng: 18.1703037 },
    { lat: 49.0588412, lng: 18.1702627 },
    { lat: 49.0588162, lng: 18.1699051 },
    { lat: 49.0589962, lng: 18.1689695 },
    { lat: 49.0585557, lng: 18.1685081 },
    { lat: 49.0582739, lng: 18.1681804 },
    { lat: 49.0581034, lng: 18.1681019 },
    { lat: 49.0579876, lng: 18.1680083 },
    { lat: 49.057799, lng: 18.1673386 },
    { lat: 49.0575267, lng: 18.1667009 },
    { lat: 49.0574439, lng: 18.1665767 },
    { lat: 49.0571114, lng: 18.1663499 },
    { lat: 49.0566932, lng: 18.1660425 },
    { lat: 49.0566026, lng: 18.1659754 },
    { lat: 49.0562725, lng: 18.165534 },
    { lat: 49.0565735, lng: 18.1652547 },
    { lat: 49.0568587, lng: 18.165152 },
    { lat: 49.0568574, lng: 18.165068 },
    { lat: 49.056857, lng: 18.165004 },
    { lat: 49.0568551, lng: 18.1649125 },
    { lat: 49.056524, lng: 18.1635645 },
    { lat: 49.0560775, lng: 18.1622395 },
    { lat: 49.0557149, lng: 18.1615041 },
    { lat: 49.0555773, lng: 18.1612896 },
    { lat: 49.0553466, lng: 18.1609055 },
    { lat: 49.054925, lng: 18.1596959 },
    { lat: 49.0546814, lng: 18.1588354 },
    { lat: 49.054304, lng: 18.1579052 },
    { lat: 49.0542643, lng: 18.1567826 },
    { lat: 49.054011, lng: 18.1575384 },
    { lat: 49.0538613, lng: 18.1581347 },
    { lat: 49.0536493, lng: 18.1589418 },
    { lat: 49.0533581, lng: 18.1595641 },
    { lat: 49.0529594, lng: 18.1603661 },
    { lat: 49.0527817, lng: 18.1606891 },
    { lat: 49.0533993, lng: 18.1624629 },
    { lat: 49.0535145, lng: 18.1631347 },
    { lat: 49.0535879, lng: 18.1637254 },
    { lat: 49.0537998, lng: 18.1649356 },
    { lat: 49.0537381, lng: 18.1649812 },
    { lat: 49.0537077, lng: 18.1650032 },
    { lat: 49.0536558, lng: 18.1650418 },
    { lat: 49.0534597, lng: 18.1651851 },
    { lat: 49.0532805, lng: 18.1654622 },
    { lat: 49.0530558, lng: 18.1658353 },
    { lat: 49.0529777, lng: 18.1660471 },
    { lat: 49.0526714, lng: 18.1666638 },
    { lat: 49.0528376, lng: 18.1668281 },
    { lat: 49.0528662, lng: 18.1668567 },
    { lat: 49.0532442, lng: 18.1672302 },
    { lat: 49.053181, lng: 18.1677196 },
    { lat: 49.0530211, lng: 18.1680024 },
    { lat: 49.0529183, lng: 18.1681348 },
    { lat: 49.0526941, lng: 18.168888 },
    { lat: 49.0521022, lng: 18.1691714 },
    { lat: 49.0518342, lng: 18.1694998 },
    { lat: 49.0513856, lng: 18.1690738 },
    { lat: 49.051351, lng: 18.1690402 },
    { lat: 49.0512669, lng: 18.1694449 },
    { lat: 49.0510625, lng: 18.1701463 },
    { lat: 49.0510201, lng: 18.1703512 },
    { lat: 49.0509262, lng: 18.1705241 },
    { lat: 49.0506293, lng: 18.1707405 },
    { lat: 49.0504365, lng: 18.1709194 },
    { lat: 49.0504461, lng: 18.1710455 },
    { lat: 49.0504437, lng: 18.1711231 },
    { lat: 49.0504051, lng: 18.1712747 },
    { lat: 49.0503702, lng: 18.171434 },
    { lat: 49.0503325, lng: 18.1716045 },
    { lat: 49.0502942, lng: 18.1717606 },
    { lat: 49.0502549, lng: 18.1719256 },
    { lat: 49.0501702, lng: 18.1723153 },
    { lat: 49.0500997, lng: 18.1725218 },
    { lat: 49.0498153, lng: 18.1731315 },
    { lat: 49.0495558, lng: 18.1738027 },
    { lat: 49.0492228, lng: 18.1746624 },
    { lat: 49.048964, lng: 18.1754637 },
    { lat: 49.0488241, lng: 18.1760337 },
    { lat: 49.0487025, lng: 18.1763754 },
    { lat: 49.0485512, lng: 18.1766685 },
    { lat: 49.0483221, lng: 18.1769989 },
    { lat: 49.0477108, lng: 18.1779706 },
    { lat: 49.0476705, lng: 18.1779562 },
    { lat: 49.0470599, lng: 18.1777368 },
    { lat: 49.0463421, lng: 18.1774411 },
    { lat: 49.0459189, lng: 18.1772231 },
    { lat: 49.0452153, lng: 18.1769359 },
    { lat: 49.0445569, lng: 18.1778326 },
    { lat: 49.0441372, lng: 18.1781656 },
    { lat: 49.0431528, lng: 18.1793191 },
    { lat: 49.0422525, lng: 18.1797745 },
    { lat: 49.0416273, lng: 18.1799113 },
    { lat: 49.0415858, lng: 18.1799125 },
    { lat: 49.0411507, lng: 18.1798425 },
    { lat: 49.0405478, lng: 18.1796981 },
    { lat: 49.0403153, lng: 18.1796976 },
    { lat: 49.0396435, lng: 18.1798493 },
    { lat: 49.0389891, lng: 18.1797098 },
    { lat: 49.03826, lng: 18.1796713 },
    { lat: 49.0381964, lng: 18.1807065 },
    { lat: 49.0378323, lng: 18.1817341 },
    { lat: 49.0375245, lng: 18.1826837 },
    { lat: 49.0372041, lng: 18.1836706 },
    { lat: 49.0365439, lng: 18.1841609 },
    { lat: 49.0361069, lng: 18.1846877 },
    { lat: 49.0360288, lng: 18.1846833 },
    { lat: 49.0360383, lng: 18.1847705 },
    { lat: 49.0356612, lng: 18.1849021 },
    { lat: 49.0352492, lng: 18.1852566 },
    { lat: 49.034336, lng: 18.186431 },
    { lat: 49.03426, lng: 18.1865043 },
    { lat: 49.033926, lng: 18.1866794 },
    { lat: 49.0336149, lng: 18.1867667 },
    { lat: 49.0334198, lng: 18.1869644 },
    { lat: 49.0330514, lng: 18.1872293 },
    { lat: 49.0336619, lng: 18.1880795 },
    { lat: 49.0334474, lng: 18.1894582 },
    { lat: 49.0334399, lng: 18.1895056 },
    { lat: 49.0331376, lng: 18.1902541 },
    { lat: 49.0328235, lng: 18.1901662 },
    { lat: 49.0324357, lng: 18.1905081 },
    { lat: 49.0318965, lng: 18.1898002 },
    { lat: 49.0309397, lng: 18.1903548 },
    { lat: 49.0309003, lng: 18.1903769 },
    { lat: 49.0297733, lng: 18.1922506 },
    { lat: 49.0289225, lng: 18.1930806 },
    { lat: 49.0286681, lng: 18.1933293 },
    { lat: 49.0285944, lng: 18.1933433 },
    { lat: 49.0283559, lng: 18.1934723 },
    { lat: 49.028267, lng: 18.1935341 },
    { lat: 49.0281904, lng: 18.1936165 },
    { lat: 49.0280504, lng: 18.1938105 },
    { lat: 49.027819, lng: 18.1940468 },
    { lat: 49.0276847, lng: 18.1942824 },
    { lat: 49.0273422, lng: 18.1951403 },
    { lat: 49.0271283, lng: 18.1954067 },
    { lat: 49.0269971, lng: 18.1957965 },
    { lat: 49.0269201, lng: 18.1961648 },
    { lat: 49.0268722, lng: 18.1967669 },
    { lat: 49.0267181, lng: 18.1971109 },
    { lat: 49.0265717, lng: 18.1974531 },
    { lat: 49.0264327, lng: 18.1975855 },
    { lat: 49.026593, lng: 18.1978634 },
    { lat: 49.026723, lng: 18.198007 },
    { lat: 49.0268401, lng: 18.1981365 },
    { lat: 49.0269169, lng: 18.1982215 },
    { lat: 49.0269267, lng: 18.1982325 },
    { lat: 49.0269281, lng: 18.1983189 },
    { lat: 49.0269046, lng: 18.1983623 },
    { lat: 49.0268274, lng: 18.1985088 },
    { lat: 49.0268222, lng: 18.1985174 },
    { lat: 49.0267763, lng: 18.198604 },
    { lat: 49.0267315, lng: 18.1986879 },
    { lat: 49.026704, lng: 18.1987782 },
    { lat: 49.0264191, lng: 18.1997176 },
    { lat: 49.0261765, lng: 18.2000323 },
    { lat: 49.0261089, lng: 18.200207 },
    { lat: 49.0259357, lng: 18.2006542 },
    { lat: 49.0257414, lng: 18.2008495 },
    { lat: 49.0254952, lng: 18.2012208 },
    { lat: 49.0252409, lng: 18.2014514 },
    { lat: 49.0249808, lng: 18.2015005 },
    { lat: 49.0246967, lng: 18.2022543 },
    { lat: 49.0246899, lng: 18.2022724 },
    { lat: 49.0246483, lng: 18.2023556 },
    { lat: 49.024395, lng: 18.20286 },
    { lat: 49.0240453, lng: 18.2038065 },
    { lat: 49.0239663, lng: 18.2039838 },
    { lat: 49.0239159, lng: 18.204049 },
    { lat: 49.0238059, lng: 18.2041391 },
    { lat: 49.0230761, lng: 18.2044601 },
    { lat: 49.0226099, lng: 18.204636 },
    { lat: 49.0224308, lng: 18.2047205 },
    { lat: 49.0222908, lng: 18.2048767 },
    { lat: 49.0222708, lng: 18.2048994 },
    { lat: 49.0222165, lng: 18.2049594 },
    { lat: 49.0221913, lng: 18.204989 },
    { lat: 49.0211546, lng: 18.2061994 },
    { lat: 49.0210318, lng: 18.2063433 },
    { lat: 49.0208912, lng: 18.2065276 },
    { lat: 49.0195329, lng: 18.2085274 },
    { lat: 49.0195298, lng: 18.2085314 },
    { lat: 49.0194735, lng: 18.2085928 },
    { lat: 49.0194206, lng: 18.2086522 },
    { lat: 49.0192496, lng: 18.2088416 },
    { lat: 49.018282, lng: 18.2103307 },
    { lat: 49.0175953, lng: 18.2113078 },
    { lat: 49.0165265, lng: 18.2118205 },
    { lat: 49.0146039, lng: 18.2127294 },
    { lat: 49.0130148, lng: 18.2134717 },
    { lat: 49.0096796, lng: 18.2114796 },
    { lat: 49.0084062, lng: 18.2098447 },
    { lat: 49.0080847, lng: 18.2105208 },
    { lat: 49.0074532, lng: 18.209854 },
    { lat: 49.0073507, lng: 18.2101342 },
    { lat: 49.0073271, lng: 18.2101136 },
    { lat: 49.0070561, lng: 18.2098884 },
    { lat: 49.0069823, lng: 18.209827 },
    { lat: 49.0060281, lng: 18.2091834 },
    { lat: 49.0045221, lng: 18.208871 },
    { lat: 49.0025114, lng: 18.2079051 },
    { lat: 49.0026108, lng: 18.2087139 },
    { lat: 49.0018028, lng: 18.2120139 },
    { lat: 49.0011093, lng: 18.2124568 },
    { lat: 49.0010952, lng: 18.2124655 },
    { lat: 49.0010821, lng: 18.2124736 },
    { lat: 49.0010582, lng: 18.2124887 },
    { lat: 49.001055, lng: 18.2124909 },
    { lat: 49.0009993, lng: 18.2125266 },
    { lat: 49.000984, lng: 18.2125362 },
    { lat: 49.0009318, lng: 18.2125693 },
    { lat: 49.0009066, lng: 18.2125798 },
    { lat: 48.9996719, lng: 18.212338 },
    { lat: 48.9999659, lng: 18.2151579 },
    { lat: 49.0008043, lng: 18.217413 },
    { lat: 49.0022945, lng: 18.2188896 },
    { lat: 49.003101, lng: 18.2193614 },
    { lat: 49.0039145, lng: 18.219836 },
    { lat: 49.0041171, lng: 18.2199644 },
    { lat: 49.0054899, lng: 18.2208291 },
    { lat: 49.0061096, lng: 18.2212689 },
    { lat: 49.0069387, lng: 18.2218584 },
    { lat: 49.0083495, lng: 18.2234312 },
    { lat: 49.0085338, lng: 18.2237355 },
    { lat: 49.0086533, lng: 18.2239311 },
    { lat: 49.0086583, lng: 18.2239394 },
    { lat: 49.008738, lng: 18.2240705 },
    { lat: 49.0087405, lng: 18.224074 },
    { lat: 49.008814, lng: 18.2241964 },
    { lat: 49.008941, lng: 18.2244046 },
    { lat: 49.0090428, lng: 18.2246083 },
    { lat: 49.0090772, lng: 18.2246784 },
    { lat: 49.0091014, lng: 18.2247253 },
    { lat: 49.0095635, lng: 18.2256495 },
    { lat: 49.0100758, lng: 18.227513 },
    { lat: 49.0101571, lng: 18.2278102 },
    { lat: 49.0102654, lng: 18.2282055 },
    { lat: 49.0108968, lng: 18.2311323 },
    { lat: 49.0108855, lng: 18.2338873 },
    { lat: 49.0111964, lng: 18.2356501 },
    { lat: 49.0112835, lng: 18.236144 },
    { lat: 49.0121018, lng: 18.2379351 },
    { lat: 49.0134171, lng: 18.2393983 },
    { lat: 49.0148167, lng: 18.2405618 },
    { lat: 49.0148414, lng: 18.2405172 },
    { lat: 49.0155729, lng: 18.2392522 },
    { lat: 49.0158284, lng: 18.2396787 },
    { lat: 49.0159835, lng: 18.2399294 },
    { lat: 49.0167286, lng: 18.2411245 },
    { lat: 49.0177251, lng: 18.2427392 },
    { lat: 49.0186587, lng: 18.2442983 },
    { lat: 49.0196582, lng: 18.2459246 },
    { lat: 49.0202921, lng: 18.2469536 },
    { lat: 49.0203894, lng: 18.2471021 },
    { lat: 49.0205194, lng: 18.2473005 },
    { lat: 49.0206638, lng: 18.2475203 },
    { lat: 49.0207663, lng: 18.2476765 },
    { lat: 49.0216914, lng: 18.2490837 },
    { lat: 49.0219487, lng: 18.2488476 },
    { lat: 49.0219938, lng: 18.248809 },
    { lat: 49.0220251, lng: 18.2487822 },
    { lat: 49.0220465, lng: 18.2487636 },
    { lat: 49.0223535, lng: 18.2484662 },
    { lat: 49.0239089, lng: 18.2472225 },
    { lat: 49.0244508, lng: 18.246735 },
    { lat: 49.0247906, lng: 18.2464783 },
    { lat: 49.0252667, lng: 18.2461192 },
    { lat: 49.0259677, lng: 18.2465268 },
    { lat: 49.0267045, lng: 18.2468396 },
    { lat: 49.0271832, lng: 18.2465098 },
    { lat: 49.0274649, lng: 18.2460871 },
    { lat: 49.0280326, lng: 18.244775 },
    { lat: 49.0282352, lng: 18.2442289 },
    { lat: 49.0282536, lng: 18.2441797 },
    { lat: 49.0283242, lng: 18.2439896 },
    { lat: 49.0286182, lng: 18.2426721 },
    { lat: 49.0286732, lng: 18.2425081 },
    { lat: 49.0288584, lng: 18.241715 },
    { lat: 49.0290584, lng: 18.2407863 },
    { lat: 49.0291689, lng: 18.240445 },
    { lat: 49.029361, lng: 18.2399782 },
    { lat: 49.0296225, lng: 18.2394958 },
    { lat: 49.0298533, lng: 18.2391494 },
  ],
  Tuchyňa: [
    { lat: 49.0464945, lng: 18.2017322 },
    { lat: 49.0464278, lng: 18.2016702 },
    { lat: 49.0450379, lng: 18.20039 },
    { lat: 49.0443687, lng: 18.2016503 },
    { lat: 49.0444842, lng: 18.2018566 },
    { lat: 49.0438683, lng: 18.2029444 },
    { lat: 49.0435942, lng: 18.2038172 },
    { lat: 49.0435178, lng: 18.2040611 },
    { lat: 49.043334, lng: 18.2043997 },
    { lat: 49.0432066, lng: 18.2046363 },
    { lat: 49.0431843, lng: 18.2046773 },
    { lat: 49.0430371, lng: 18.2049472 },
    { lat: 49.0428413, lng: 18.2051205 },
    { lat: 49.0427416, lng: 18.2052088 },
    { lat: 49.0426848, lng: 18.205262 },
    { lat: 49.0422443, lng: 18.206123 },
    { lat: 49.0413089, lng: 18.2069712 },
    { lat: 49.040952, lng: 18.2072629 },
    { lat: 49.0407257, lng: 18.2073712 },
    { lat: 49.0404597, lng: 18.2075534 },
    { lat: 49.0397671, lng: 18.2080279 },
    { lat: 49.0397317, lng: 18.2080735 },
    { lat: 49.0392818, lng: 18.2086016 },
    { lat: 49.0385732, lng: 18.2092724 },
    { lat: 49.0382765, lng: 18.2097079 },
    { lat: 49.0378465, lng: 18.2102987 },
    { lat: 49.037618, lng: 18.2105871 },
    { lat: 49.0370056, lng: 18.2110466 },
    { lat: 49.0369977, lng: 18.211066 },
    { lat: 49.0369745, lng: 18.211114 },
    { lat: 49.0379938, lng: 18.2121296 },
    { lat: 49.0385143, lng: 18.2130067 },
    { lat: 49.0377338, lng: 18.2143159 },
    { lat: 49.0373928, lng: 18.2149657 },
    { lat: 49.0371025, lng: 18.2155143 },
    { lat: 49.036796, lng: 18.215978 },
    { lat: 49.0365402, lng: 18.2162603 },
    { lat: 49.0362333, lng: 18.2162608 },
    { lat: 49.0357039, lng: 18.2161559 },
    { lat: 49.0356977, lng: 18.2161678 },
    { lat: 49.0356777, lng: 18.2162083 },
    { lat: 49.0356583, lng: 18.2162475 },
    { lat: 49.0356535, lng: 18.2162635 },
    { lat: 49.035154, lng: 18.2172777 },
    { lat: 49.0349244, lng: 18.2177453 },
    { lat: 49.0347789, lng: 18.2180421 },
    { lat: 49.0336873, lng: 18.2202712 },
    { lat: 49.0334962, lng: 18.22066 },
    { lat: 49.0326696, lng: 18.2223486 },
    { lat: 49.0317439, lng: 18.2234892 },
    { lat: 49.0316144, lng: 18.2237734 },
    { lat: 49.0312285, lng: 18.2253388 },
    { lat: 49.0312004, lng: 18.2262691 },
    { lat: 49.0307918, lng: 18.2267458 },
    { lat: 49.0306968, lng: 18.2268385 },
    { lat: 49.0301553, lng: 18.2274351 },
    { lat: 49.0302026, lng: 18.2275511 },
    { lat: 49.0302315, lng: 18.2276232 },
    { lat: 49.0303796, lng: 18.2280661 },
    { lat: 49.0304581, lng: 18.2283567 },
    { lat: 49.0307982, lng: 18.2293342 },
    { lat: 49.0309518, lng: 18.2296538 },
    { lat: 49.0312314, lng: 18.2301452 },
    { lat: 49.0312169, lng: 18.2301701 },
    { lat: 49.0311583, lng: 18.2302706 },
    { lat: 49.0311481, lng: 18.2302892 },
    { lat: 49.0310751, lng: 18.2304153 },
    { lat: 49.031035, lng: 18.2304846 },
    { lat: 49.0306213, lng: 18.2310043 },
    { lat: 49.0304353, lng: 18.2312053 },
    { lat: 49.0302536, lng: 18.2314022 },
    { lat: 49.0302279, lng: 18.2314293 },
    { lat: 49.0293041, lng: 18.2324253 },
    { lat: 49.0284009, lng: 18.2333861 },
    { lat: 49.0267999, lng: 18.2350931 },
    { lat: 49.0268169, lng: 18.2351329 },
    { lat: 49.0268409, lng: 18.2351808 },
    { lat: 49.0268629, lng: 18.2352106 },
    { lat: 49.0281462, lng: 18.2372431 },
    { lat: 49.0298533, lng: 18.2391494 },
    { lat: 49.0309271, lng: 18.2374342 },
    { lat: 49.0322011, lng: 18.235494 },
    { lat: 49.0333249, lng: 18.233686 },
    { lat: 49.0333765, lng: 18.2336041 },
    { lat: 49.0334407, lng: 18.2335018 },
    { lat: 49.0334874, lng: 18.233428 },
    { lat: 49.0339393, lng: 18.2327645 },
    { lat: 49.0339845, lng: 18.2326863 },
    { lat: 49.0340275, lng: 18.2326112 },
    { lat: 49.0341189, lng: 18.2324517 },
    { lat: 49.0341774, lng: 18.2325286 },
    { lat: 49.0341979, lng: 18.2325694 },
    { lat: 49.0342375, lng: 18.2326116 },
    { lat: 49.034266, lng: 18.2326349 },
    { lat: 49.0343212, lng: 18.2326952 },
    { lat: 49.0347664, lng: 18.2334393 },
    { lat: 49.0349394, lng: 18.2336668 },
    { lat: 49.0349489, lng: 18.2336921 },
    { lat: 49.0349721, lng: 18.2337427 },
    { lat: 49.0350201, lng: 18.2338561 },
    { lat: 49.0350688, lng: 18.2339385 },
    { lat: 49.0351018, lng: 18.2340025 },
    { lat: 49.0352795, lng: 18.2338141 },
    { lat: 49.0356459, lng: 18.2344247 },
    { lat: 49.03617, lng: 18.235463 },
    { lat: 49.0364164, lng: 18.236066 },
    { lat: 49.0365686, lng: 18.2363236 },
    { lat: 49.036628, lng: 18.236396 },
    { lat: 49.036732, lng: 18.2361824 },
    { lat: 49.0368529, lng: 18.2359475 },
    { lat: 49.0370249, lng: 18.2355789 },
    { lat: 49.037061, lng: 18.2351408 },
    { lat: 49.0371045, lng: 18.2348535 },
    { lat: 49.0372057, lng: 18.234513 },
    { lat: 49.037417, lng: 18.2341414 },
    { lat: 49.0376384, lng: 18.2339302 },
    { lat: 49.0379033, lng: 18.2337243 },
    { lat: 49.0381709, lng: 18.2334291 },
    { lat: 49.0384414, lng: 18.232889 },
    { lat: 49.0411903, lng: 18.2347582 },
    { lat: 49.0414682, lng: 18.2344278 },
    { lat: 49.0418945, lng: 18.2336917 },
    { lat: 49.0427192, lng: 18.2344032 },
    { lat: 49.0433801, lng: 18.2331481 },
    { lat: 49.0440106, lng: 18.2338413 },
    { lat: 49.0448055, lng: 18.2325987 },
    { lat: 49.045145, lng: 18.2316455 },
    { lat: 49.0454945, lng: 18.2310428 },
    { lat: 49.046114, lng: 18.2312652 },
    { lat: 49.0462789, lng: 18.2313251 },
    { lat: 49.0464979, lng: 18.2315254 },
    { lat: 49.0468189, lng: 18.2318731 },
    { lat: 49.0472933, lng: 18.2322987 },
    { lat: 49.0474468, lng: 18.2323796 },
    { lat: 49.0478234, lng: 18.2308701 },
    { lat: 49.0479945, lng: 18.230922 },
    { lat: 49.0481479, lng: 18.2310792 },
    { lat: 49.0489591, lng: 18.23169 },
    { lat: 49.0492209, lng: 18.231334 },
    { lat: 49.0496417, lng: 18.2310588 },
    { lat: 49.0506872, lng: 18.2303939 },
    { lat: 49.0510609, lng: 18.2301414 },
    { lat: 49.0516606, lng: 18.2297373 },
    { lat: 49.052194, lng: 18.2294131 },
    { lat: 49.0526266, lng: 18.2291616 },
    { lat: 49.053186, lng: 18.2288203 },
    { lat: 49.0537637, lng: 18.2284442 },
    { lat: 49.0541184, lng: 18.2282324 },
    { lat: 49.0545382, lng: 18.2279576 },
    { lat: 49.0547584, lng: 18.2278204 },
    { lat: 49.0550336, lng: 18.2273427 },
    { lat: 49.0552774, lng: 18.2269275 },
    { lat: 49.0555353, lng: 18.2273378 },
    { lat: 49.0556417, lng: 18.2274627 },
    { lat: 49.0558493, lng: 18.2276001 },
    { lat: 49.0560866, lng: 18.2277451 },
    { lat: 49.0564234, lng: 18.2280126 },
    { lat: 49.0565826, lng: 18.2280928 },
    { lat: 49.056879, lng: 18.2282876 },
    { lat: 49.057098, lng: 18.229099 },
    { lat: 49.0576474, lng: 18.2276514 },
    { lat: 49.0568416, lng: 18.2271927 },
    { lat: 49.057137, lng: 18.2256234 },
    { lat: 49.0574286, lng: 18.2236901 },
    { lat: 49.0572692, lng: 18.2224556 },
    { lat: 49.0571029, lng: 18.2210992 },
    { lat: 49.056828, lng: 18.2211473 },
    { lat: 49.0559981, lng: 18.2202045 },
    { lat: 49.0554725, lng: 18.2188592 },
    { lat: 49.0552461, lng: 18.2187283 },
    { lat: 49.0550837, lng: 18.2186907 },
    { lat: 49.0548809, lng: 18.2186966 },
    { lat: 49.0547143, lng: 18.2188016 },
    { lat: 49.0545807, lng: 18.2164356 },
    { lat: 49.0541119, lng: 18.2144656 },
    { lat: 49.0538697, lng: 18.213445 },
    { lat: 49.0535209, lng: 18.2119765 },
    { lat: 49.0540017, lng: 18.2116623 },
    { lat: 49.0543381, lng: 18.2117456 },
    { lat: 49.0546842, lng: 18.211661 },
    { lat: 49.0549965, lng: 18.2113615 },
    { lat: 49.0549454, lng: 18.2111952 },
    { lat: 49.0549219, lng: 18.2111192 },
    { lat: 49.054876, lng: 18.2109687 },
    { lat: 49.0545753, lng: 18.2086032 },
    { lat: 49.0545769, lng: 18.2085734 },
    { lat: 49.0545838, lng: 18.2084754 },
    { lat: 49.0545867, lng: 18.2084297 },
    { lat: 49.0546141, lng: 18.2082973 },
    { lat: 49.0545787, lng: 18.207299 },
    { lat: 49.0546071, lng: 18.2068491 },
    { lat: 49.0545733, lng: 18.2068691 },
    { lat: 49.0545559, lng: 18.2068789 },
    { lat: 49.0540058, lng: 18.2072038 },
    { lat: 49.0536282, lng: 18.2073695 },
    { lat: 49.0532196, lng: 18.2053316 },
    { lat: 49.052984, lng: 18.205239 },
    { lat: 49.0528586, lng: 18.2050955 },
    { lat: 49.0528164, lng: 18.2050481 },
    { lat: 49.0523709, lng: 18.2045386 },
    { lat: 49.0523305, lng: 18.2044814 },
    { lat: 49.0516875, lng: 18.2035757 },
    { lat: 49.0521022, lng: 18.2031528 },
    { lat: 49.0531927, lng: 18.2025489 },
    { lat: 49.0527034, lng: 18.2015661 },
    { lat: 49.0520582, lng: 18.2000103 },
    { lat: 49.0524016, lng: 18.1997352 },
    { lat: 49.0530641, lng: 18.1992711 },
    { lat: 49.0532205, lng: 18.1991043 },
    { lat: 49.0533251, lng: 18.1989189 },
    { lat: 49.0533769, lng: 18.198662 },
    { lat: 49.0532711, lng: 18.1982929 },
    { lat: 49.0532365, lng: 18.1980809 },
    { lat: 49.053248, lng: 18.1977686 },
    { lat: 49.0533194, lng: 18.1975364 },
    { lat: 49.0534541, lng: 18.1972704 },
    { lat: 49.0538406, lng: 18.1968635 },
    { lat: 49.0542057, lng: 18.1963633 },
    { lat: 49.0544757, lng: 18.1961322 },
    { lat: 49.0545308, lng: 18.1959915 },
    { lat: 49.0546088, lng: 18.1956401 },
    { lat: 49.0546211, lng: 18.1951794 },
    { lat: 49.0546111, lng: 18.1946127 },
    { lat: 49.0546689, lng: 18.1941633 },
    { lat: 49.0547559, lng: 18.1938496 },
    { lat: 49.0548774, lng: 18.1936054 },
    { lat: 49.0553466, lng: 18.193068 },
    { lat: 49.0557338, lng: 18.192716 },
    { lat: 49.0562036, lng: 18.1924876 },
    { lat: 49.0565372, lng: 18.1922639 },
    { lat: 49.0572282, lng: 18.1915799 },
    { lat: 49.0573895, lng: 18.1912836 },
    { lat: 49.0574869, lng: 18.1910358 },
    { lat: 49.0575732, lng: 18.1903937 },
    { lat: 49.0576241, lng: 18.1901765 },
    { lat: 49.0577822, lng: 18.1897871 },
    { lat: 49.0579082, lng: 18.1895992 },
    { lat: 49.0581456, lng: 18.1893151 },
    { lat: 49.0584687, lng: 18.1891223 },
    { lat: 49.0591209, lng: 18.1888477 },
    { lat: 49.0594772, lng: 18.1887232 },
    { lat: 49.0594471, lng: 18.1886732 },
    { lat: 49.0579601, lng: 18.1866223 },
    { lat: 49.0580432, lng: 18.1858899 },
    { lat: 49.0578946, lng: 18.1852069 },
    { lat: 49.0574908, lng: 18.185009 },
    { lat: 49.0566299, lng: 18.1856203 },
    { lat: 49.0555262, lng: 18.1863275 },
    { lat: 49.0553332, lng: 18.1869373 },
    { lat: 49.055208, lng: 18.1873309 },
    { lat: 49.0547794, lng: 18.1883209 },
    { lat: 49.0551428, lng: 18.1900961 },
    { lat: 49.0546665, lng: 18.1908844 },
    { lat: 49.0535154, lng: 18.1918096 },
    { lat: 49.0527279, lng: 18.191711 },
    { lat: 49.0520477, lng: 18.1916748 },
    { lat: 49.0513945, lng: 18.191817 },
    { lat: 49.0511228, lng: 18.1921552 },
    { lat: 49.0503324, lng: 18.1932318 },
    { lat: 49.0495203, lng: 18.1937281 },
    { lat: 49.0493266, lng: 18.1939721 },
    { lat: 49.0488771, lng: 18.1945377 },
    { lat: 49.0481941, lng: 18.1962889 },
    { lat: 49.0483005, lng: 18.1975173 },
    { lat: 49.0482481, lng: 18.1978458 },
    { lat: 49.0482345, lng: 18.1986339 },
    { lat: 49.0481685, lng: 18.198861 },
    { lat: 49.0480433, lng: 18.1992146 },
    { lat: 49.0475574, lng: 18.200191 },
    { lat: 49.0471743, lng: 18.2007143 },
    { lat: 49.0467354, lng: 18.2012072 },
    { lat: 49.0464945, lng: 18.2017322 },
  ],
  VršatskéPodhradie: [
    { lat: 49.0799552, lng: 18.1060426 },
    { lat: 49.079759, lng: 18.105909 },
    { lat: 49.07967, lng: 18.105841 },
    { lat: 49.079333, lng: 18.105582 },
    { lat: 49.078564, lng: 18.105013 },
    { lat: 49.078043, lng: 18.104627 },
    { lat: 49.077735, lng: 18.104404 },
    { lat: 49.077616, lng: 18.104317 },
    { lat: 49.077389, lng: 18.10415 },
    { lat: 49.077028, lng: 18.103886 },
    { lat: 49.076476, lng: 18.103479 },
    { lat: 49.075842, lng: 18.103019 },
    { lat: 49.07531, lng: 18.102627 },
    { lat: 49.074761, lng: 18.102221 },
    { lat: 49.074146, lng: 18.101769 },
    { lat: 49.073711, lng: 18.101448 },
    { lat: 49.073288, lng: 18.101133 },
    { lat: 49.072634, lng: 18.100649 },
    { lat: 49.072066, lng: 18.100198 },
    { lat: 49.071103, lng: 18.099543 },
    { lat: 49.070616, lng: 18.099191 },
    { lat: 49.070206, lng: 18.098894 },
    { lat: 49.070124, lng: 18.098835 },
    { lat: 49.069973, lng: 18.098726 },
    { lat: 49.069459, lng: 18.098366 },
    { lat: 49.068764, lng: 18.097886 },
    { lat: 49.068199, lng: 18.0975 },
    { lat: 49.067824, lng: 18.097213 },
    { lat: 49.067411, lng: 18.096897 },
    { lat: 49.067326, lng: 18.096848 },
    { lat: 49.067318, lng: 18.096842 },
    { lat: 49.06713, lng: 18.096721 },
    { lat: 49.066937, lng: 18.096594 },
    { lat: 49.066617, lng: 18.096383 },
    { lat: 49.066152, lng: 18.09605 },
    { lat: 49.065853, lng: 18.095842 },
    { lat: 49.065538, lng: 18.095625 },
    { lat: 49.065223, lng: 18.095473 },
    { lat: 49.064819, lng: 18.095069 },
    { lat: 49.064315, lng: 18.094781 },
    { lat: 49.063981, lng: 18.094568 },
    { lat: 49.063512, lng: 18.094191 },
    { lat: 49.063356, lng: 18.093845 },
    { lat: 49.062843, lng: 18.094073 },
    { lat: 49.062102, lng: 18.094411 },
    { lat: 49.061374, lng: 18.094641 },
    { lat: 49.060788, lng: 18.094991 },
    { lat: 49.060209, lng: 18.095322 },
    { lat: 49.059475, lng: 18.095714 },
    { lat: 49.059442, lng: 18.095732 },
    { lat: 49.059414, lng: 18.095743 },
    { lat: 49.059379, lng: 18.095758 },
    { lat: 49.059355, lng: 18.095768 },
    { lat: 49.059293, lng: 18.095798 },
    { lat: 49.059276, lng: 18.095806 },
    { lat: 49.059031, lng: 18.096128 },
    { lat: 49.05863, lng: 18.096653 },
    { lat: 49.058217, lng: 18.097147 },
    { lat: 49.057533, lng: 18.097598 },
    { lat: 49.056909, lng: 18.098153 },
    { lat: 49.056471, lng: 18.098535 },
    { lat: 49.055792, lng: 18.099063 },
    { lat: 49.055466, lng: 18.099393 },
    { lat: 49.055065, lng: 18.099759 },
    { lat: 49.054155, lng: 18.100591 },
    { lat: 49.053654, lng: 18.101104 },
    { lat: 49.051909, lng: 18.102274 },
    { lat: 49.051102, lng: 18.101394 },
    { lat: 49.050473, lng: 18.100938 },
    { lat: 49.049824, lng: 18.100526 },
    { lat: 49.049474, lng: 18.100242 },
    { lat: 49.04883, lng: 18.099842 },
    { lat: 49.048258, lng: 18.099389 },
    { lat: 49.047411, lng: 18.098774 },
    { lat: 49.046657, lng: 18.098382 },
    { lat: 49.045824, lng: 18.097984 },
    { lat: 49.044984, lng: 18.097523 },
    { lat: 49.044552, lng: 18.097011 },
    { lat: 49.044133, lng: 18.097031 },
    { lat: 49.044066, lng: 18.097028 },
    { lat: 49.044003, lng: 18.097314 },
    { lat: 49.04447, lng: 18.0976623 },
    { lat: 49.0448613, lng: 18.0981994 },
    { lat: 49.045659, lng: 18.0990377 },
    { lat: 49.046154, lng: 18.0996331 },
    { lat: 49.04674, lng: 18.1005146 },
    { lat: 49.0473561, lng: 18.1014244 },
    { lat: 49.0480572, lng: 18.1021337 },
    { lat: 49.0482086, lng: 18.1023508 },
    { lat: 49.0483281, lng: 18.1029528 },
    { lat: 49.0485029, lng: 18.1044302 },
    { lat: 49.0485882, lng: 18.1058027 },
    { lat: 49.0487215, lng: 18.1063497 },
    { lat: 49.048684, lng: 18.106853 },
    { lat: 49.0488836, lng: 18.1072773 },
    { lat: 49.0490082, lng: 18.1078319 },
    { lat: 49.0488791, lng: 18.107901 },
    { lat: 49.0496881, lng: 18.1102382 },
    { lat: 49.0501874, lng: 18.1117297 },
    { lat: 49.0505318, lng: 18.1125111 },
    { lat: 49.0512903, lng: 18.1137229 },
    { lat: 49.0517978, lng: 18.1141852 },
    { lat: 49.0523721, lng: 18.1142259 },
    { lat: 49.0528653, lng: 18.1145348 },
    { lat: 49.0532559, lng: 18.1150282 },
    { lat: 49.053818, lng: 18.1159272 },
    { lat: 49.0540923, lng: 18.1165899 },
    { lat: 49.0545668, lng: 18.1169729 },
    { lat: 49.0548453, lng: 18.1172993 },
    { lat: 49.0549945, lng: 18.1175186 },
    { lat: 49.0555023, lng: 18.1184742 },
    { lat: 49.0556336, lng: 18.1187725 },
    { lat: 49.055759, lng: 18.1191062 },
    { lat: 49.0557276, lng: 18.1193637 },
    { lat: 49.0561553, lng: 18.1197148 },
    { lat: 49.0573662, lng: 18.121006 },
    { lat: 49.0582464, lng: 18.1207349 },
    { lat: 49.0589039, lng: 18.1205321 },
    { lat: 49.058993, lng: 18.1205104 },
    { lat: 49.0591555, lng: 18.1204707 },
    { lat: 49.061708, lng: 18.1198465 },
    { lat: 49.0625035, lng: 18.1202769 },
    { lat: 49.0631875, lng: 18.1206474 },
    { lat: 49.0632584, lng: 18.1210157 },
    { lat: 49.0632079, lng: 18.1211563 },
    { lat: 49.0638716, lng: 18.1222917 },
    { lat: 49.0645387, lng: 18.1226699 },
    { lat: 49.0642595, lng: 18.123341 },
    { lat: 49.0656346, lng: 18.1246644 },
    { lat: 49.0658355, lng: 18.1249164 },
    { lat: 49.0664329, lng: 18.1256675 },
    { lat: 49.0666067, lng: 18.125885 },
    { lat: 49.0675156, lng: 18.1265954 },
    { lat: 49.0674772, lng: 18.1266513 },
    { lat: 49.0668118, lng: 18.1275972 },
    { lat: 49.0660161, lng: 18.1274168 },
    { lat: 49.0660217, lng: 18.1274567 },
    { lat: 49.0663987, lng: 18.130079 },
    { lat: 49.0648764, lng: 18.1317799 },
    { lat: 49.064829, lng: 18.1321885 },
    { lat: 49.0647951, lng: 18.132228 },
    { lat: 49.0637826, lng: 18.1334947 },
    { lat: 49.0627153, lng: 18.1345329 },
    { lat: 49.0622582, lng: 18.1342158 },
    { lat: 49.061945, lng: 18.1341695 },
    { lat: 49.0618794, lng: 18.1341261 },
    { lat: 49.0616828, lng: 18.1344161 },
    { lat: 49.0613113, lng: 18.1348982 },
    { lat: 49.0608878, lng: 18.1355212 },
    { lat: 49.0608698, lng: 18.1359888 },
    { lat: 49.0608717, lng: 18.1373901 },
    { lat: 49.0615491, lng: 18.1379268 },
    { lat: 49.0630471, lng: 18.1381324 },
    { lat: 49.0630826, lng: 18.1383752 },
    { lat: 49.0630842, lng: 18.1384441 },
    { lat: 49.0627914, lng: 18.1385339 },
    { lat: 49.0626164, lng: 18.1386278 },
    { lat: 49.062505, lng: 18.1389104 },
    { lat: 49.0625263, lng: 18.1391814 },
    { lat: 49.0621715, lng: 18.1396418 },
    { lat: 49.0614505, lng: 18.1406009 },
    { lat: 49.0613166, lng: 18.1406652 },
    { lat: 49.0608986, lng: 18.1411478 },
    { lat: 49.0605596, lng: 18.1409224 },
    { lat: 49.059954, lng: 18.1405204 },
    { lat: 49.0598588, lng: 18.1409245 },
    { lat: 49.0596844, lng: 18.1432445 },
    { lat: 49.0590822, lng: 18.1454031 },
    { lat: 49.0584451, lng: 18.1478419 },
    { lat: 49.0584799, lng: 18.1485245 },
    { lat: 49.0574809, lng: 18.1481464 },
    { lat: 49.0568336, lng: 18.1481733 },
    { lat: 49.0564897, lng: 18.1485077 },
    { lat: 49.0564015, lng: 18.1486026 },
    { lat: 49.0562708, lng: 18.1487431 },
    { lat: 49.055347, lng: 18.149807 },
    { lat: 49.0552664, lng: 18.1485467 },
    { lat: 49.0544269, lng: 18.1481563 },
    { lat: 49.0542931, lng: 18.1482432 },
    { lat: 49.0539933, lng: 18.1486789 },
    { lat: 49.0538888, lng: 18.1488835 },
    { lat: 49.0538072, lng: 18.1490937 },
    { lat: 49.0537005, lng: 18.1497292 },
    { lat: 49.0537051, lng: 18.1498654 },
    { lat: 49.0538721, lng: 18.1508663 },
    { lat: 49.0539058, lng: 18.1511395 },
    { lat: 49.0539683, lng: 18.1513625 },
    { lat: 49.0541041, lng: 18.1521147 },
    { lat: 49.054069, lng: 18.1524824 },
    { lat: 49.0540932, lng: 18.1526688 },
    { lat: 49.0541743, lng: 18.1529041 },
    { lat: 49.0542733, lng: 18.1535064 },
    { lat: 49.0542792, lng: 18.1536873 },
    { lat: 49.0542812, lng: 18.1537007 },
    { lat: 49.0543641, lng: 18.1544143 },
    { lat: 49.0549212, lng: 18.1549787 },
    { lat: 49.0549862, lng: 18.1553233 },
    { lat: 49.0554474, lng: 18.1554983 },
    { lat: 49.055442, lng: 18.1555976 },
    { lat: 49.055827, lng: 18.1558205 },
    { lat: 49.0566358, lng: 18.1565576 },
    { lat: 49.0567763, lng: 18.1568418 },
    { lat: 49.0564458, lng: 18.1572956 },
    { lat: 49.055781, lng: 18.1568822 },
    { lat: 49.0555427, lng: 18.1564682 },
    { lat: 49.0547668, lng: 18.1565114 },
    { lat: 49.0542643, lng: 18.1567826 },
    { lat: 49.054304, lng: 18.1579052 },
    { lat: 49.0546814, lng: 18.1588354 },
    { lat: 49.054925, lng: 18.1596959 },
    { lat: 49.0553466, lng: 18.1609055 },
    { lat: 49.0555773, lng: 18.1612896 },
    { lat: 49.0557149, lng: 18.1615041 },
    { lat: 49.0560775, lng: 18.1622395 },
    { lat: 49.056524, lng: 18.1635645 },
    { lat: 49.0568551, lng: 18.1649125 },
    { lat: 49.056857, lng: 18.165004 },
    { lat: 49.0568574, lng: 18.165068 },
    { lat: 49.0568587, lng: 18.165152 },
    { lat: 49.0565735, lng: 18.1652547 },
    { lat: 49.0562725, lng: 18.165534 },
    { lat: 49.0566026, lng: 18.1659754 },
    { lat: 49.0566932, lng: 18.1660425 },
    { lat: 49.0571114, lng: 18.1663499 },
    { lat: 49.0574439, lng: 18.1665767 },
    { lat: 49.0575267, lng: 18.1667009 },
    { lat: 49.057799, lng: 18.1673386 },
    { lat: 49.0579876, lng: 18.1680083 },
    { lat: 49.0581034, lng: 18.1681019 },
    { lat: 49.0582739, lng: 18.1681804 },
    { lat: 49.0585557, lng: 18.1685081 },
    { lat: 49.0589962, lng: 18.1689695 },
    { lat: 49.0588162, lng: 18.1699051 },
    { lat: 49.0588412, lng: 18.1702627 },
    { lat: 49.0588436, lng: 18.1703037 },
    { lat: 49.0588487, lng: 18.1703718 },
    { lat: 49.0587451, lng: 18.1708454 },
    { lat: 49.0586274, lng: 18.1712338 },
    { lat: 49.058434, lng: 18.1714818 },
    { lat: 49.058297, lng: 18.1718091 },
    { lat: 49.0581488, lng: 18.1724688 },
    { lat: 49.0580555, lng: 18.1727313 },
    { lat: 49.0580302, lng: 18.1731962 },
    { lat: 49.0578754, lng: 18.1737054 },
    { lat: 49.0578445, lng: 18.1737771 },
    { lat: 49.0574442, lng: 18.1742919 },
    { lat: 49.0574171, lng: 18.1748215 },
    { lat: 49.0572894, lng: 18.175155 },
    { lat: 49.0572045, lng: 18.1762309 },
    { lat: 49.0571634, lng: 18.1766774 },
    { lat: 49.0572877, lng: 18.1774687 },
    { lat: 49.0573092, lng: 18.1776043 },
    { lat: 49.0573893, lng: 18.1781193 },
    { lat: 49.0576204, lng: 18.1788795 },
    { lat: 49.0576521, lng: 18.1789836 },
    { lat: 49.0577989, lng: 18.1788669 },
    { lat: 49.0582697, lng: 18.1782198 },
    { lat: 49.0584551, lng: 18.1780849 },
    { lat: 49.0587614, lng: 18.1778621 },
    { lat: 49.058922, lng: 18.177826 },
    { lat: 49.059318, lng: 18.1779048 },
    { lat: 49.0594362, lng: 18.1778905 },
    { lat: 49.0595242, lng: 18.1778799 },
    { lat: 49.0597151, lng: 18.1780834 },
    { lat: 49.059822, lng: 18.1784888 },
    { lat: 49.0601101, lng: 18.1781814 },
    { lat: 49.0601685, lng: 18.1783341 },
    { lat: 49.0602629, lng: 18.1785814 },
    { lat: 49.0603023, lng: 18.1792522 },
    { lat: 49.0602619, lng: 18.1794508 },
    { lat: 49.0602362, lng: 18.1795765 },
    { lat: 49.0602257, lng: 18.1796294 },
    { lat: 49.0601975, lng: 18.1797684 },
    { lat: 49.0601712, lng: 18.1798993 },
    { lat: 49.0590149, lng: 18.1807888 },
    { lat: 49.0584348, lng: 18.1811219 },
    { lat: 49.0580474, lng: 18.1813719 },
    { lat: 49.0577117, lng: 18.1817733 },
    { lat: 49.0572641, lng: 18.1826278 },
    { lat: 49.0570505, lng: 18.1831332 },
    { lat: 49.0572404, lng: 18.1838279 },
    { lat: 49.057393, lng: 18.1845442 },
    { lat: 49.0574908, lng: 18.185009 },
    { lat: 49.0578946, lng: 18.1852069 },
    { lat: 49.0580432, lng: 18.1858899 },
    { lat: 49.0579601, lng: 18.1866223 },
    { lat: 49.0594471, lng: 18.1886732 },
    { lat: 49.0594772, lng: 18.1887232 },
    { lat: 49.0597257, lng: 18.1881776 },
    { lat: 49.0597868, lng: 18.1874686 },
    { lat: 49.0598568, lng: 18.1867832 },
    { lat: 49.0609229, lng: 18.184907 },
    { lat: 49.061386, lng: 18.1844797 },
    { lat: 49.0618204, lng: 18.1836715 },
    { lat: 49.0620636, lng: 18.1828725 },
    { lat: 49.0624846, lng: 18.182029 },
    { lat: 49.062621, lng: 18.1818303 },
    { lat: 49.0627554, lng: 18.181733 },
    { lat: 49.0627762, lng: 18.1812128 },
    { lat: 49.0627362, lng: 18.1808482 },
    { lat: 49.0626505, lng: 18.1793123 },
    { lat: 49.0626961, lng: 18.1777267 },
    { lat: 49.0627243, lng: 18.1774675 },
    { lat: 49.0628625, lng: 18.1773217 },
    { lat: 49.0634291, lng: 18.177067 },
    { lat: 49.0634686, lng: 18.1768725 },
    { lat: 49.0633213, lng: 18.1765264 },
    { lat: 49.063101, lng: 18.1758519 },
    { lat: 49.0630919, lng: 18.1753004 },
    { lat: 49.0633782, lng: 18.1741901 },
    { lat: 49.0633977, lng: 18.1737424 },
    { lat: 49.0633805, lng: 18.1733647 },
    { lat: 49.0634021, lng: 18.1732093 },
    { lat: 49.0634517, lng: 18.1731296 },
    { lat: 49.0636661, lng: 18.172895 },
    { lat: 49.0639043, lng: 18.1725244 },
    { lat: 49.0639687, lng: 18.1722305 },
    { lat: 49.064054, lng: 18.1719691 },
    { lat: 49.0644432, lng: 18.1716108 },
    { lat: 49.064673, lng: 18.1711931 },
    { lat: 49.0646802, lng: 18.1711799 },
    { lat: 49.0650526, lng: 18.1705691 },
    { lat: 49.065282, lng: 18.1702813 },
    { lat: 49.065727, lng: 18.1697169 },
    { lat: 49.0660408, lng: 18.1695655 },
    { lat: 49.0662991, lng: 18.1694221 },
    { lat: 49.0674066, lng: 18.1687661 },
    { lat: 49.0680895, lng: 18.1690068 },
    { lat: 49.0682553, lng: 18.1690646 },
    { lat: 49.0685625, lng: 18.1688726 },
    { lat: 49.0687701, lng: 18.1689505 },
    { lat: 49.0689122, lng: 18.1691061 },
    { lat: 49.069306, lng: 18.1695997 },
    { lat: 49.0693698, lng: 18.169705 },
    { lat: 49.0696739, lng: 18.1701669 },
    { lat: 49.06998, lng: 18.1702152 },
    { lat: 49.0705718, lng: 18.1703013 },
    { lat: 49.0706696, lng: 18.1703157 },
    { lat: 49.0708904, lng: 18.1703479 },
    { lat: 49.0714159, lng: 18.1706174 },
    { lat: 49.0714428, lng: 18.1708521 },
    { lat: 49.0714467, lng: 18.1708789 },
    { lat: 49.0716621, lng: 18.1709155 },
    { lat: 49.0719837, lng: 18.1709159 },
    { lat: 49.0722096, lng: 18.171095 },
    { lat: 49.0723601, lng: 18.1711059 },
    { lat: 49.0729324, lng: 18.171524 },
    { lat: 49.0732559, lng: 18.1712982 },
    { lat: 49.0733067, lng: 18.1701797 },
    { lat: 49.0740206, lng: 18.1704656 },
    { lat: 49.074221, lng: 18.1704592 },
    { lat: 49.0746796, lng: 18.1706268 },
    { lat: 49.0752168, lng: 18.1707458 },
    { lat: 49.0754935, lng: 18.1707677 },
    { lat: 49.0758733, lng: 18.1706823 },
    { lat: 49.0757055, lng: 18.1703536 },
    { lat: 49.0757711, lng: 18.1702395 },
    { lat: 49.0759372, lng: 18.1705176 },
    { lat: 49.0764909, lng: 18.170196 },
    { lat: 49.0765584, lng: 18.1701568 },
    { lat: 49.0774408, lng: 18.1697595 },
    { lat: 49.0769219, lng: 18.1663984 },
    { lat: 49.0754628, lng: 18.1619757 },
    { lat: 49.0746787, lng: 18.158757 },
    { lat: 49.074778, lng: 18.1580124 },
    { lat: 49.0748606, lng: 18.1574093 },
    { lat: 49.0750293, lng: 18.1559256 },
    { lat: 49.0753076, lng: 18.1558403 },
    { lat: 49.0759605, lng: 18.1549478 },
    { lat: 49.0762456, lng: 18.1544909 },
    { lat: 49.0763811, lng: 18.1542349 },
    { lat: 49.0762844, lng: 18.1536348 },
    { lat: 49.0762104, lng: 18.1532329 },
    { lat: 49.0760745, lng: 18.1530858 },
    { lat: 49.0775344, lng: 18.1523176 },
    { lat: 49.0777011, lng: 18.1522306 },
    { lat: 49.0789874, lng: 18.1508 },
    { lat: 49.0788298, lng: 18.150667 },
    { lat: 49.0787905, lng: 18.1506333 },
    { lat: 49.0787503, lng: 18.1505998 },
    { lat: 49.0786459, lng: 18.1505111 },
    { lat: 49.0784691, lng: 18.1503616 },
    { lat: 49.0782461, lng: 18.1501717 },
    { lat: 49.0776223, lng: 18.1491963 },
    { lat: 49.0773887, lng: 18.1488441 },
    { lat: 49.0773237, lng: 18.1489881 },
    { lat: 49.0764353, lng: 18.1474304 },
    { lat: 49.0769738, lng: 18.1466523 },
    { lat: 49.0770264, lng: 18.1466036 },
    { lat: 49.0779361, lng: 18.1458815 },
    { lat: 49.0785185, lng: 18.1462185 },
    { lat: 49.0795455, lng: 18.1441265 },
    { lat: 49.0795717, lng: 18.1440726 },
    { lat: 49.0794255, lng: 18.1440007 },
    { lat: 49.0788376, lng: 18.1437084 },
    { lat: 49.0781692, lng: 18.1437789 },
    { lat: 49.0775286, lng: 18.144098 },
    { lat: 49.0767197, lng: 18.1438859 },
    { lat: 49.0759568, lng: 18.1440813 },
    { lat: 49.0750831, lng: 18.1443109 },
    { lat: 49.0743051, lng: 18.1438701 },
    { lat: 49.0739698, lng: 18.143492 },
    { lat: 49.0748898, lng: 18.1409919 },
    { lat: 49.0753953, lng: 18.1404256 },
    { lat: 49.0762894, lng: 18.1398217 },
    { lat: 49.0771892, lng: 18.139577 },
    { lat: 49.0772469, lng: 18.1395618 },
    { lat: 49.0774488, lng: 18.1384607 },
    { lat: 49.0771063, lng: 18.1377502 },
    { lat: 49.0768568, lng: 18.1369406 },
    { lat: 49.0768234, lng: 18.1368335 },
    { lat: 49.0775231, lng: 18.1366777 },
    { lat: 49.0778223, lng: 18.1367 },
    { lat: 49.0778722, lng: 18.136626 },
    { lat: 49.0784518, lng: 18.1357578 },
    { lat: 49.0787916, lng: 18.1346268 },
    { lat: 49.0788174, lng: 18.1340974 },
    { lat: 49.0788262, lng: 18.1338979 },
    { lat: 49.078832, lng: 18.1337809 },
    { lat: 49.0786457, lng: 18.1332694 },
    { lat: 49.0786884, lng: 18.1320942 },
    { lat: 49.0787038, lng: 18.1314813 },
    { lat: 49.0787448, lng: 18.131487 },
    { lat: 49.0788986, lng: 18.131299 },
    { lat: 49.07904, lng: 18.1311268 },
    { lat: 49.0789532, lng: 18.1309134 },
    { lat: 49.0790595, lng: 18.1305684 },
    { lat: 49.0791121, lng: 18.1299718 },
    { lat: 49.079045, lng: 18.1298051 },
    { lat: 49.0787982, lng: 18.1297531 },
    { lat: 49.078617, lng: 18.1295866 },
    { lat: 49.0786785, lng: 18.1289887 },
    { lat: 49.0785967, lng: 18.1284727 },
    { lat: 49.0787356, lng: 18.1286478 },
    { lat: 49.078777, lng: 18.1287002 },
    { lat: 49.0788804, lng: 18.1288329 },
    { lat: 49.0790392, lng: 18.1285892 },
    { lat: 49.0792184, lng: 18.1283921 },
    { lat: 49.0792218, lng: 18.1280014 },
    { lat: 49.079436, lng: 18.127332 },
    { lat: 49.079625, lng: 18.1278125 },
    { lat: 49.079734, lng: 18.1280992 },
    { lat: 49.0800217, lng: 18.128213 },
    { lat: 49.0803173, lng: 18.1279596 },
    { lat: 49.080885, lng: 18.1277048 },
    { lat: 49.0815272, lng: 18.1281963 },
    { lat: 49.0811772, lng: 18.1289943 },
    { lat: 49.0819494, lng: 18.1291571 },
    { lat: 49.0822274, lng: 18.127915 },
    { lat: 49.0822457, lng: 18.1253394 },
    { lat: 49.0821004, lng: 18.1252073 },
    { lat: 49.0820057, lng: 18.1244269 },
    { lat: 49.0822504, lng: 18.1236029 },
    { lat: 49.0824248, lng: 18.123225 },
    { lat: 49.0826547, lng: 18.1222233 },
    { lat: 49.0827025, lng: 18.1219167 },
    { lat: 49.082746, lng: 18.1216333 },
    { lat: 49.0830556, lng: 18.1213694 },
    { lat: 49.0826626, lng: 18.1205217 },
    { lat: 49.0825981, lng: 18.1204612 },
    { lat: 49.0836847, lng: 18.1172182 },
    { lat: 49.0838299, lng: 18.1161803 },
    { lat: 49.0838368, lng: 18.1161243 },
    { lat: 49.0838679, lng: 18.1148054 },
    { lat: 49.0838623, lng: 18.1142226 },
    { lat: 49.0838643, lng: 18.1141788 },
    { lat: 49.0838859, lng: 18.1138119 },
    { lat: 49.0838925, lng: 18.1137091 },
    { lat: 49.0839023, lng: 18.1135077 },
    { lat: 49.0839202, lng: 18.113133 },
    { lat: 49.083942, lng: 18.1126732 },
    { lat: 49.0839783, lng: 18.111898 },
    { lat: 49.0839973, lng: 18.1118544 },
    { lat: 49.0841575, lng: 18.1114851 },
    { lat: 49.084536, lng: 18.1106132 },
    { lat: 49.084918, lng: 18.109745 },
    { lat: 49.084553, lng: 18.109526 },
    { lat: 49.084475, lng: 18.109461 },
    { lat: 49.084234, lng: 18.109257 },
    { lat: 49.083834, lng: 18.108909 },
    { lat: 49.083082, lng: 18.108398 },
    { lat: 49.082684, lng: 18.108091 },
    { lat: 49.081909, lng: 18.107494 },
    { lat: 49.081518, lng: 18.107211 },
    { lat: 49.081402, lng: 18.107127 },
    { lat: 49.080976, lng: 18.10682 },
    { lat: 49.080516, lng: 18.106475 },
    { lat: 49.080026, lng: 18.106091 },
    { lat: 49.0799552, lng: 18.1060426 },
  ],
  ČervenýKameň: [
    { lat: 49.143456, lng: 18.118356 },
    { lat: 49.143425, lng: 18.118265 },
    { lat: 49.143358, lng: 18.118296 },
    { lat: 49.143335, lng: 18.118206 },
    { lat: 49.143356, lng: 18.118101 },
    { lat: 49.143292, lng: 18.118084 },
    { lat: 49.14321, lng: 18.118102 },
    { lat: 49.143213, lng: 18.117957 },
    { lat: 49.143154, lng: 18.117851 },
    { lat: 49.143041, lng: 18.117941 },
    { lat: 49.142965, lng: 18.117952 },
    { lat: 49.14295, lng: 18.117929 },
    { lat: 49.142908, lng: 18.11771 },
    { lat: 49.142973, lng: 18.117644 },
    { lat: 49.143, lng: 18.117415 },
    { lat: 49.143053, lng: 18.117261 },
    { lat: 49.143003, lng: 18.11717 },
    { lat: 49.142998, lng: 18.11694 },
    { lat: 49.14297, lng: 18.116886 },
    { lat: 49.142965, lng: 18.116807 },
    { lat: 49.142914, lng: 18.116758 },
    { lat: 49.142889, lng: 18.116642 },
    { lat: 49.142764, lng: 18.116658 },
    { lat: 49.142683, lng: 18.116606 },
    { lat: 49.142707, lng: 18.116342 },
    { lat: 49.142549, lng: 18.116178 },
    { lat: 49.142468, lng: 18.116023 },
    { lat: 49.142225, lng: 18.115891 },
    { lat: 49.142122, lng: 18.115677 },
    { lat: 49.142116, lng: 18.115304 },
    { lat: 49.142296, lng: 18.115039 },
    { lat: 49.142231, lng: 18.114916 },
    { lat: 49.142254, lng: 18.114737 },
    { lat: 49.1422, lng: 18.114665 },
    { lat: 49.142225, lng: 18.114391 },
    { lat: 49.142109, lng: 18.114227 },
    { lat: 49.142078, lng: 18.114099 },
    { lat: 49.141986, lng: 18.113977 },
    { lat: 49.141807, lng: 18.113863 },
    { lat: 49.141729, lng: 18.113812 },
    { lat: 49.14149, lng: 18.113851 },
    { lat: 49.141331, lng: 18.113876 },
    { lat: 49.141228, lng: 18.113837 },
    { lat: 49.141188, lng: 18.113666 },
    { lat: 49.141079, lng: 18.11365 },
    { lat: 49.141092, lng: 18.113492 },
    { lat: 49.140987, lng: 18.113198 },
    { lat: 49.140894, lng: 18.113086 },
    { lat: 49.140888, lng: 18.113083 },
    { lat: 49.140855, lng: 18.113073 },
    { lat: 49.14085, lng: 18.113073 },
    { lat: 49.140793, lng: 18.113055 },
    { lat: 49.140776, lng: 18.112963 },
    { lat: 49.140527, lng: 18.112728 },
    { lat: 49.140434, lng: 18.112482 },
    { lat: 49.140393, lng: 18.112499 },
    { lat: 49.140349, lng: 18.112482 },
    { lat: 49.140275, lng: 18.112352 },
    { lat: 49.140062, lng: 18.112072 },
    { lat: 49.1399, lng: 18.111832 },
    { lat: 49.139767, lng: 18.111752 },
    { lat: 49.139723, lng: 18.111594 },
    { lat: 49.139594, lng: 18.11147 },
    { lat: 49.139444, lng: 18.111307 },
    { lat: 49.139253, lng: 18.111064 },
    { lat: 49.139112, lng: 18.110923 },
    { lat: 49.139035, lng: 18.111045 },
    { lat: 49.138801, lng: 18.11079 },
    { lat: 49.13866, lng: 18.110649 },
    { lat: 49.138541, lng: 18.110621 },
    { lat: 49.138449, lng: 18.110515 },
    { lat: 49.138338, lng: 18.110395 },
    { lat: 49.138264, lng: 18.11021 },
    { lat: 49.138146, lng: 18.110093 },
    { lat: 49.138125, lng: 18.110056 },
    { lat: 49.138102, lng: 18.109957 },
    { lat: 49.138117, lng: 18.109902 },
    { lat: 49.138115, lng: 18.109836 },
    { lat: 49.138031, lng: 18.109787 },
    { lat: 49.137985, lng: 18.109756 },
    { lat: 49.137955, lng: 18.109689 },
    { lat: 49.13768, lng: 18.109482 },
    { lat: 49.13721, lng: 18.109117 },
    { lat: 49.136825, lng: 18.108815 },
    { lat: 49.136745, lng: 18.108785 },
    { lat: 49.136678, lng: 18.108691 },
    { lat: 49.136589, lng: 18.108619 },
    { lat: 49.13644, lng: 18.108554 },
    { lat: 49.136374, lng: 18.108471 },
    { lat: 49.13628, lng: 18.108447 },
    { lat: 49.136227, lng: 18.108428 },
    { lat: 49.136066, lng: 18.108516 },
    { lat: 49.13598, lng: 18.108531 },
    { lat: 49.135783, lng: 18.10846 },
    { lat: 49.135691, lng: 18.108463 },
    { lat: 49.135573, lng: 18.108435 },
    { lat: 49.135529, lng: 18.108458 },
    { lat: 49.135395, lng: 18.108384 },
    { lat: 49.135239, lng: 18.108119 },
    { lat: 49.135201, lng: 18.107928 },
    { lat: 49.13516, lng: 18.10788 },
    { lat: 49.135042, lng: 18.107844 },
    { lat: 49.135021, lng: 18.107751 },
    { lat: 49.1350141, lng: 18.1077076 },
    { lat: 49.1349569, lng: 18.1075899 },
    { lat: 49.1349412, lng: 18.1075909 },
    { lat: 49.1349263, lng: 18.1076098 },
    { lat: 49.134909, lng: 18.1076671 },
    { lat: 49.1348199, lng: 18.1077472 },
    { lat: 49.1348013, lng: 18.1077573 },
    { lat: 49.13477, lng: 18.107686 },
    { lat: 49.134765, lng: 18.10761 },
    { lat: 49.134789, lng: 18.107576 },
    { lat: 49.134841, lng: 18.107572 },
    { lat: 49.13484, lng: 18.107527 },
    { lat: 49.134781, lng: 18.107419 },
    { lat: 49.134708, lng: 18.107271 },
    { lat: 49.13467, lng: 18.107271 },
    { lat: 49.1346, lng: 18.107331 },
    { lat: 49.134523, lng: 18.107422 },
    { lat: 49.134456, lng: 18.107438 },
    { lat: 49.134422, lng: 18.107399 },
    { lat: 49.134404, lng: 18.107264 },
    { lat: 49.134374, lng: 18.107159 },
    { lat: 49.134326, lng: 18.107064 },
    { lat: 49.134303, lng: 18.106994 },
    { lat: 49.134303, lng: 18.106966 },
    { lat: 49.134296, lng: 18.106885 },
    { lat: 49.134244, lng: 18.106797 },
    { lat: 49.134187, lng: 18.106749 },
    { lat: 49.134165, lng: 18.106749 },
    { lat: 49.134157, lng: 18.106789 },
    { lat: 49.134119, lng: 18.106819 },
    { lat: 49.134075, lng: 18.106834 },
    { lat: 49.133997, lng: 18.106934 },
    { lat: 49.133933, lng: 18.107026 },
    { lat: 49.133905, lng: 18.107111 },
    { lat: 49.133854, lng: 18.107231 },
    { lat: 49.133833, lng: 18.107255 },
    { lat: 49.133818, lng: 18.107241 },
    { lat: 49.133809, lng: 18.107202 },
    { lat: 49.133806, lng: 18.107173 },
    { lat: 49.133777, lng: 18.10715 },
    { lat: 49.133736, lng: 18.107172 },
    { lat: 49.13369, lng: 18.107139 },
    { lat: 49.133628, lng: 18.10713 },
    { lat: 49.133593, lng: 18.107171 },
    { lat: 49.133583, lng: 18.107217 },
    { lat: 49.133609, lng: 18.107307 },
    { lat: 49.133597, lng: 18.107324 },
    { lat: 49.133482, lng: 18.107458 },
    { lat: 49.133338, lng: 18.107544 },
    { lat: 49.133321, lng: 18.107526 },
    { lat: 49.133234, lng: 18.107459 },
    { lat: 49.133222, lng: 18.107454 },
    { lat: 49.133212, lng: 18.107481 },
    { lat: 49.13321, lng: 18.107519 },
    { lat: 49.133198, lng: 18.107555 },
    { lat: 49.133169, lng: 18.107562 },
    { lat: 49.133136, lng: 18.107543 },
    { lat: 49.133117, lng: 18.107564 },
    { lat: 49.133051, lng: 18.107675 },
    { lat: 49.132971, lng: 18.107733 },
    { lat: 49.132833, lng: 18.107704 },
    { lat: 49.132701, lng: 18.107718 },
    { lat: 49.132646, lng: 18.107703 },
    { lat: 49.132599, lng: 18.107703 },
    { lat: 49.132592, lng: 18.107696 },
    { lat: 49.13258, lng: 18.107707 },
    { lat: 49.132577, lng: 18.10772 },
    { lat: 49.132582, lng: 18.107738 },
    { lat: 49.132593, lng: 18.107743 },
    { lat: 49.132599, lng: 18.107765 },
    { lat: 49.132566, lng: 18.107974 },
    { lat: 49.132515, lng: 18.108071 },
    { lat: 49.132481, lng: 18.108068 },
    { lat: 49.132331, lng: 18.108132 },
    { lat: 49.132269, lng: 18.108215 },
    { lat: 49.132222, lng: 18.108212 },
    { lat: 49.132135, lng: 18.108231 },
    { lat: 49.132075, lng: 18.108234 },
    { lat: 49.132046, lng: 18.108243 },
    { lat: 49.132025, lng: 18.108225 },
    { lat: 49.131991, lng: 18.108264 },
    { lat: 49.131964, lng: 18.108268 },
    { lat: 49.131942, lng: 18.10828 },
    { lat: 49.131921, lng: 18.108291 },
    { lat: 49.131898, lng: 18.108275 },
    { lat: 49.131899, lng: 18.108263 },
    { lat: 49.131819, lng: 18.108177 },
    { lat: 49.131795, lng: 18.108211 },
    { lat: 49.131704, lng: 18.10824 },
    { lat: 49.131682, lng: 18.10831 },
    { lat: 49.131631, lng: 18.108318 },
    { lat: 49.131612, lng: 18.108331 },
    { lat: 49.131606, lng: 18.108361 },
    { lat: 49.131619, lng: 18.108414 },
    { lat: 49.131557, lng: 18.108455 },
    { lat: 49.131512, lng: 18.108451 },
    { lat: 49.131408, lng: 18.108375 },
    { lat: 49.13132, lng: 18.108284 },
    { lat: 49.131259, lng: 18.10831 },
    { lat: 49.131221, lng: 18.108305 },
    { lat: 49.131189, lng: 18.10834 },
    { lat: 49.131192, lng: 18.108455 },
    { lat: 49.131182, lng: 18.108642 },
    { lat: 49.131215, lng: 18.108796 },
    { lat: 49.131171, lng: 18.108846 },
    { lat: 49.131118, lng: 18.10877 },
    { lat: 49.130965, lng: 18.108719 },
    { lat: 49.130938, lng: 18.108751 },
    { lat: 49.130939, lng: 18.108792 },
    { lat: 49.130994, lng: 18.108889 },
    { lat: 49.130975, lng: 18.108951 },
    { lat: 49.130815, lng: 18.109162 },
    { lat: 49.130648, lng: 18.109189 },
    { lat: 49.130476, lng: 18.109292 },
    { lat: 49.13038, lng: 18.109283 },
    { lat: 49.130269, lng: 18.109573 },
    { lat: 49.13016, lng: 18.109572 },
    { lat: 49.130058, lng: 18.109668 },
    { lat: 49.129911, lng: 18.109919 },
    { lat: 49.129799, lng: 18.110036 },
    { lat: 49.129617, lng: 18.110212 },
    { lat: 49.129519, lng: 18.110426 },
    { lat: 49.129544, lng: 18.110605 },
    { lat: 49.129404, lng: 18.110679 },
    { lat: 49.129301, lng: 18.110686 },
    { lat: 49.129249, lng: 18.110766 },
    { lat: 49.129176, lng: 18.110986 },
    { lat: 49.129083, lng: 18.111006 },
    { lat: 49.128904, lng: 18.111099 },
    { lat: 49.128795, lng: 18.111228 },
    { lat: 49.128833, lng: 18.111336 },
    { lat: 49.128814, lng: 18.111414 },
    { lat: 49.128869, lng: 18.111478 },
    { lat: 49.128897, lng: 18.111548 },
    { lat: 49.128866, lng: 18.111614 },
    { lat: 49.128845, lng: 18.111625 },
    { lat: 49.128803, lng: 18.1116 },
    { lat: 49.12878, lng: 18.111612 },
    { lat: 49.128738, lng: 18.111716 },
    { lat: 49.12873, lng: 18.111804 },
    { lat: 49.128584, lng: 18.111882 },
    { lat: 49.1285458, lng: 18.1118857 },
    { lat: 49.128511, lng: 18.111889 },
    { lat: 49.128448, lng: 18.11191 },
    { lat: 49.128409, lng: 18.111969 },
    { lat: 49.128395, lng: 18.112098 },
    { lat: 49.128395, lng: 18.112272 },
    { lat: 49.128317, lng: 18.112641 },
    { lat: 49.128173, lng: 18.112796 },
    { lat: 49.128048, lng: 18.11313 },
    { lat: 49.127857, lng: 18.113259 },
    { lat: 49.127778, lng: 18.113364 },
    { lat: 49.127746, lng: 18.113566 },
    { lat: 49.127498, lng: 18.114124 },
    { lat: 49.127411, lng: 18.114214 },
    { lat: 49.12734, lng: 18.114437 },
    { lat: 49.127292, lng: 18.114574 },
    { lat: 49.127193, lng: 18.114679 },
    { lat: 49.127104, lng: 18.114866 },
    { lat: 49.127103, lng: 18.114967 },
    { lat: 49.126913, lng: 18.115351 },
    { lat: 49.126862, lng: 18.115493 },
    { lat: 49.126753, lng: 18.115627 },
    { lat: 49.126764, lng: 18.11573 },
    { lat: 49.12661, lng: 18.116038 },
    { lat: 49.126457, lng: 18.116357 },
    { lat: 49.126389, lng: 18.116535 },
    { lat: 49.12626, lng: 18.116674 },
    { lat: 49.12602, lng: 18.116867 },
    { lat: 49.125862, lng: 18.116983 },
    { lat: 49.12576, lng: 18.117138 },
    { lat: 49.125641, lng: 18.117201 },
    { lat: 49.125551, lng: 18.117156 },
    { lat: 49.125458, lng: 18.117261 },
    { lat: 49.125409, lng: 18.117316 },
    { lat: 49.125383, lng: 18.117445 },
    { lat: 49.125375, lng: 18.117484 },
    { lat: 49.125336, lng: 18.117497 },
    { lat: 49.125307, lng: 18.117442 },
    { lat: 49.125282, lng: 18.117465 },
    { lat: 49.125264, lng: 18.117517 },
    { lat: 49.125202, lng: 18.117578 },
    { lat: 49.125008, lng: 18.117707 },
    { lat: 49.124926, lng: 18.117795 },
    { lat: 49.124739, lng: 18.117957 },
    { lat: 49.124627, lng: 18.11795 },
    { lat: 49.124586, lng: 18.118086 },
    { lat: 49.124493, lng: 18.118195 },
    { lat: 49.124425, lng: 18.118188 },
    { lat: 49.124309, lng: 18.118279 },
    { lat: 49.124227, lng: 18.118402 },
    { lat: 49.12415, lng: 18.118416 },
    { lat: 49.123926, lng: 18.118607 },
    { lat: 49.12386, lng: 18.118669 },
    { lat: 49.123748, lng: 18.118636 },
    { lat: 49.12374, lng: 18.118711 },
    { lat: 49.123655, lng: 18.118793 },
    { lat: 49.123573, lng: 18.118793 },
    { lat: 49.123565, lng: 18.118883 },
    { lat: 49.123536, lng: 18.11892 },
    { lat: 49.123426, lng: 18.118987 },
    { lat: 49.123281, lng: 18.119076 },
    { lat: 49.123239, lng: 18.119148 },
    { lat: 49.123178, lng: 18.119182 },
    { lat: 49.123084, lng: 18.119271 },
    { lat: 49.123021, lng: 18.119298 },
    { lat: 49.122954, lng: 18.119382 },
    { lat: 49.122835, lng: 18.119477 },
    { lat: 49.122632, lng: 18.119532 },
    { lat: 49.122555, lng: 18.119553 },
    { lat: 49.122352, lng: 18.119648 },
    { lat: 49.122278, lng: 18.119717 },
    { lat: 49.122159, lng: 18.119728 },
    { lat: 49.122105, lng: 18.119758 },
    { lat: 49.122053, lng: 18.119777 },
    { lat: 49.12194, lng: 18.119883 },
    { lat: 49.12191, lng: 18.119871 },
    { lat: 49.121828, lng: 18.119887 },
    { lat: 49.121784, lng: 18.119959 },
    { lat: 49.121703, lng: 18.119992 },
    { lat: 49.121684, lng: 18.119932 },
    { lat: 49.12162, lng: 18.11995 },
    { lat: 49.121562, lng: 18.119943 },
    { lat: 49.12147, lng: 18.119999 },
    { lat: 49.1214, lng: 18.119954 },
    { lat: 49.1212162, lng: 18.1200888 },
    { lat: 49.121198, lng: 18.120105 },
    { lat: 49.121115, lng: 18.12019 },
    { lat: 49.120991, lng: 18.120234 },
    { lat: 49.120844, lng: 18.120195 },
    { lat: 49.120786, lng: 18.120187 },
    { lat: 49.120772, lng: 18.120198 },
    { lat: 49.120766, lng: 18.120254 },
    { lat: 49.120686, lng: 18.120364 },
    { lat: 49.12061, lng: 18.120366 },
    { lat: 49.120495, lng: 18.12042 },
    { lat: 49.120459, lng: 18.120437 },
    { lat: 49.120378, lng: 18.120431 },
    { lat: 49.120296, lng: 18.120498 },
    { lat: 49.120203, lng: 18.120549 },
    { lat: 49.12008, lng: 18.120619 },
    { lat: 49.119998, lng: 18.120634 },
    { lat: 49.119932, lng: 18.120595 },
    { lat: 49.119889, lng: 18.120593 },
    { lat: 49.119792, lng: 18.12072 },
    { lat: 49.119667, lng: 18.120757 },
    { lat: 49.119568, lng: 18.12067 },
    { lat: 49.119467, lng: 18.120696 },
    { lat: 49.11941, lng: 18.120764 },
    { lat: 49.119383, lng: 18.120867 },
    { lat: 49.119309, lng: 18.120973 },
    { lat: 49.119213, lng: 18.120971 },
    { lat: 49.119025, lng: 18.120904 },
    { lat: 49.118907, lng: 18.120943 },
    { lat: 49.118891, lng: 18.120962 },
    { lat: 49.118895, lng: 18.12105 },
    { lat: 49.118817, lng: 18.121146 },
    { lat: 49.11876, lng: 18.121165 },
    { lat: 49.118659, lng: 18.121267 },
    { lat: 49.118581, lng: 18.121397 },
    { lat: 49.118468, lng: 18.121532 },
    { lat: 49.118384, lng: 18.121698 },
    { lat: 49.118317, lng: 18.121803 },
    { lat: 49.118243, lng: 18.12192 },
    { lat: 49.118134, lng: 18.122016 },
    { lat: 49.118072, lng: 18.122122 },
    { lat: 49.118005, lng: 18.122208 },
    { lat: 49.117987, lng: 18.122184 },
    { lat: 49.117924, lng: 18.122103 },
    { lat: 49.117837, lng: 18.122031 },
    { lat: 49.11771, lng: 18.121938 },
    { lat: 49.117598, lng: 18.121747 },
    { lat: 49.117534, lng: 18.12168 },
    { lat: 49.117464, lng: 18.121624 },
    { lat: 49.117437, lng: 18.121571 },
    { lat: 49.117412, lng: 18.121555 },
    { lat: 49.11735, lng: 18.121453 },
    { lat: 49.117369, lng: 18.121398 },
    { lat: 49.117799, lng: 18.120177 },
    { lat: 49.117904, lng: 18.119971 },
    { lat: 49.117996, lng: 18.119791 },
    { lat: 49.11825, lng: 18.119306 },
    { lat: 49.118469, lng: 18.118887 },
    { lat: 49.11889, lng: 18.118137 },
    { lat: 49.119008, lng: 18.11791 },
    { lat: 49.11891, lng: 18.116883 },
    { lat: 49.118916, lng: 18.116185 },
    { lat: 49.118903, lng: 18.115564 },
    { lat: 49.11884, lng: 18.115327 },
    { lat: 49.118647, lng: 18.114648 },
    { lat: 49.118508, lng: 18.113779 },
    { lat: 49.117835, lng: 18.113517 },
    { lat: 49.117495, lng: 18.113385 },
    { lat: 49.117362, lng: 18.113333 },
    { lat: 49.117054, lng: 18.113268 },
    { lat: 49.116778, lng: 18.113158 },
    { lat: 49.116272, lng: 18.112955 },
    { lat: 49.115878, lng: 18.112795 },
    { lat: 49.115797, lng: 18.112762 },
    { lat: 49.115527, lng: 18.112642 },
    { lat: 49.115187, lng: 18.112483 },
    { lat: 49.114975, lng: 18.112304 },
    { lat: 49.114846, lng: 18.112067 },
    { lat: 49.114496, lng: 18.111547 },
    { lat: 49.114324, lng: 18.111302 },
    { lat: 49.11423, lng: 18.111169 },
    { lat: 49.114078, lng: 18.111001 },
    { lat: 49.113819, lng: 18.110713 },
    { lat: 49.113551, lng: 18.11027 },
    { lat: 49.1135026, lng: 18.1102883 },
    { lat: 49.113026, lng: 18.110464 },
    { lat: 49.112592, lng: 18.110557 },
    { lat: 49.112094, lng: 18.110695 },
    { lat: 49.111717, lng: 18.11081 },
    { lat: 49.111443, lng: 18.110896 },
    { lat: 49.111051, lng: 18.111047 },
    { lat: 49.110755, lng: 18.111145 },
    { lat: 49.110622, lng: 18.111189 },
    { lat: 49.110253, lng: 18.111309 },
    { lat: 49.109713, lng: 18.111462 },
    { lat: 49.109407, lng: 18.11156 },
    { lat: 49.108981, lng: 18.111706 },
    { lat: 49.108432, lng: 18.111949 },
    { lat: 49.108048, lng: 18.111862 },
    { lat: 49.107741, lng: 18.111769 },
    { lat: 49.107535, lng: 18.111789 },
    { lat: 49.107497, lng: 18.111793 },
    { lat: 49.107316, lng: 18.111811 },
    { lat: 49.106808, lng: 18.111861 },
    { lat: 49.106021, lng: 18.111939 },
    { lat: 49.105388, lng: 18.112008 },
    { lat: 49.10476, lng: 18.112095 },
    { lat: 49.104203, lng: 18.112419 },
    { lat: 49.103574, lng: 18.112807 },
    { lat: 49.102817, lng: 18.112901 },
    { lat: 49.102177, lng: 18.112976 },
    { lat: 49.10182, lng: 18.113044 },
    { lat: 49.101235, lng: 18.113155 },
    { lat: 49.100695, lng: 18.113288 },
    { lat: 49.099967, lng: 18.113452 },
    { lat: 49.099461, lng: 18.113619 },
    { lat: 49.099213, lng: 18.113696 },
    { lat: 49.098825, lng: 18.113815 },
    { lat: 49.098203, lng: 18.113995 },
    { lat: 49.097593, lng: 18.114155 },
    { lat: 49.097122, lng: 18.11429 },
    { lat: 49.0968765, lng: 18.1143767 },
    { lat: 49.096383, lng: 18.114551 },
    { lat: 49.095745, lng: 18.114757 },
    { lat: 49.095242, lng: 18.114951 },
    { lat: 49.094749, lng: 18.11506 },
    { lat: 49.094018, lng: 18.115329 },
    { lat: 49.093419, lng: 18.115513 },
    { lat: 49.092766, lng: 18.115735 },
    { lat: 49.092141, lng: 18.115938 },
    { lat: 49.09148, lng: 18.115839 },
    { lat: 49.091071, lng: 18.115769 },
    { lat: 49.090676, lng: 18.115709 },
    { lat: 49.090354, lng: 18.115622 },
    { lat: 49.090081, lng: 18.115548 },
    { lat: 49.089308, lng: 18.11502 },
    { lat: 49.089003, lng: 18.114809 },
    { lat: 49.088876, lng: 18.114526 },
    { lat: 49.088656, lng: 18.114034 },
    { lat: 49.088379, lng: 18.113428 },
    { lat: 49.087892, lng: 18.11237 },
    { lat: 49.08761, lng: 18.111796 },
    { lat: 49.08728, lng: 18.111519 },
    { lat: 49.086759, lng: 18.111175 },
    { lat: 49.086373, lng: 18.1108261 },
    { lat: 49.085969, lng: 18.110531 },
    { lat: 49.085456, lng: 18.110149 },
    { lat: 49.08518, lng: 18.109946 },
    { lat: 49.084918, lng: 18.109745 },
    { lat: 49.084536, lng: 18.1106132 },
    { lat: 49.0841575, lng: 18.1114851 },
    { lat: 49.0839973, lng: 18.1118544 },
    { lat: 49.0839783, lng: 18.111898 },
    { lat: 49.083942, lng: 18.1126732 },
    { lat: 49.0839202, lng: 18.113133 },
    { lat: 49.0839023, lng: 18.1135077 },
    { lat: 49.0838925, lng: 18.1137091 },
    { lat: 49.0838859, lng: 18.1138119 },
    { lat: 49.0838643, lng: 18.1141788 },
    { lat: 49.0838623, lng: 18.1142226 },
    { lat: 49.0838679, lng: 18.1148054 },
    { lat: 49.0838368, lng: 18.1161243 },
    { lat: 49.0838299, lng: 18.1161803 },
    { lat: 49.0836847, lng: 18.1172182 },
    { lat: 49.0825981, lng: 18.1204612 },
    { lat: 49.0826626, lng: 18.1205217 },
    { lat: 49.0830556, lng: 18.1213694 },
    { lat: 49.082746, lng: 18.1216333 },
    { lat: 49.0827025, lng: 18.1219167 },
    { lat: 49.0826547, lng: 18.1222233 },
    { lat: 49.0824248, lng: 18.123225 },
    { lat: 49.0822504, lng: 18.1236029 },
    { lat: 49.0820057, lng: 18.1244269 },
    { lat: 49.0821004, lng: 18.1252073 },
    { lat: 49.0822457, lng: 18.1253394 },
    { lat: 49.0822274, lng: 18.127915 },
    { lat: 49.0819494, lng: 18.1291571 },
    { lat: 49.0811772, lng: 18.1289943 },
    { lat: 49.0815272, lng: 18.1281963 },
    { lat: 49.080885, lng: 18.1277048 },
    { lat: 49.0803173, lng: 18.1279596 },
    { lat: 49.0800217, lng: 18.128213 },
    { lat: 49.079734, lng: 18.1280992 },
    { lat: 49.079625, lng: 18.1278125 },
    { lat: 49.079436, lng: 18.127332 },
    { lat: 49.0792218, lng: 18.1280014 },
    { lat: 49.0792184, lng: 18.1283921 },
    { lat: 49.0790392, lng: 18.1285892 },
    { lat: 49.0788804, lng: 18.1288329 },
    { lat: 49.078777, lng: 18.1287002 },
    { lat: 49.0787356, lng: 18.1286478 },
    { lat: 49.0785967, lng: 18.1284727 },
    { lat: 49.0786785, lng: 18.1289887 },
    { lat: 49.078617, lng: 18.1295866 },
    { lat: 49.0787982, lng: 18.1297531 },
    { lat: 49.079045, lng: 18.1298051 },
    { lat: 49.0791121, lng: 18.1299718 },
    { lat: 49.0790595, lng: 18.1305684 },
    { lat: 49.0789532, lng: 18.1309134 },
    { lat: 49.07904, lng: 18.1311268 },
    { lat: 49.0788986, lng: 18.131299 },
    { lat: 49.0787448, lng: 18.131487 },
    { lat: 49.0787038, lng: 18.1314813 },
    { lat: 49.0786884, lng: 18.1320942 },
    { lat: 49.0786457, lng: 18.1332694 },
    { lat: 49.078832, lng: 18.1337809 },
    { lat: 49.0788262, lng: 18.1338979 },
    { lat: 49.0788174, lng: 18.1340974 },
    { lat: 49.0787916, lng: 18.1346268 },
    { lat: 49.0784518, lng: 18.1357578 },
    { lat: 49.0778722, lng: 18.136626 },
    { lat: 49.0778223, lng: 18.1367 },
    { lat: 49.0775231, lng: 18.1366777 },
    { lat: 49.0768234, lng: 18.1368335 },
    { lat: 49.0768568, lng: 18.1369406 },
    { lat: 49.0771063, lng: 18.1377502 },
    { lat: 49.0774488, lng: 18.1384607 },
    { lat: 49.0772469, lng: 18.1395618 },
    { lat: 49.0771892, lng: 18.139577 },
    { lat: 49.0762894, lng: 18.1398217 },
    { lat: 49.0753953, lng: 18.1404256 },
    { lat: 49.0748898, lng: 18.1409919 },
    { lat: 49.0739698, lng: 18.143492 },
    { lat: 49.0743051, lng: 18.1438701 },
    { lat: 49.0750831, lng: 18.1443109 },
    { lat: 49.0759568, lng: 18.1440813 },
    { lat: 49.0767197, lng: 18.1438859 },
    { lat: 49.0775286, lng: 18.144098 },
    { lat: 49.0781692, lng: 18.1437789 },
    { lat: 49.0788376, lng: 18.1437084 },
    { lat: 49.0794255, lng: 18.1440007 },
    { lat: 49.0795717, lng: 18.1440726 },
    { lat: 49.0795455, lng: 18.1441265 },
    { lat: 49.0785185, lng: 18.1462185 },
    { lat: 49.0779361, lng: 18.1458815 },
    { lat: 49.0770264, lng: 18.1466036 },
    { lat: 49.0769738, lng: 18.1466523 },
    { lat: 49.0764353, lng: 18.1474304 },
    { lat: 49.0773237, lng: 18.1489881 },
    { lat: 49.0773887, lng: 18.1488441 },
    { lat: 49.0776223, lng: 18.1491963 },
    { lat: 49.0782461, lng: 18.1501717 },
    { lat: 49.0784691, lng: 18.1503616 },
    { lat: 49.0786459, lng: 18.1505111 },
    { lat: 49.0787503, lng: 18.1505998 },
    { lat: 49.0787905, lng: 18.1506333 },
    { lat: 49.0788298, lng: 18.150667 },
    { lat: 49.0789874, lng: 18.1508 },
    { lat: 49.0777011, lng: 18.1522306 },
    { lat: 49.0775344, lng: 18.1523176 },
    { lat: 49.0760745, lng: 18.1530858 },
    { lat: 49.0762104, lng: 18.1532329 },
    { lat: 49.0762844, lng: 18.1536348 },
    { lat: 49.0763811, lng: 18.1542349 },
    { lat: 49.0762456, lng: 18.1544909 },
    { lat: 49.0759605, lng: 18.1549478 },
    { lat: 49.0753076, lng: 18.1558403 },
    { lat: 49.0750293, lng: 18.1559256 },
    { lat: 49.0748606, lng: 18.1574093 },
    { lat: 49.074778, lng: 18.1580124 },
    { lat: 49.0746787, lng: 18.158757 },
    { lat: 49.0754628, lng: 18.1619757 },
    { lat: 49.0769219, lng: 18.1663984 },
    { lat: 49.0774408, lng: 18.1697595 },
    { lat: 49.0765584, lng: 18.1701568 },
    { lat: 49.0764909, lng: 18.170196 },
    { lat: 49.0759372, lng: 18.1705176 },
    { lat: 49.0757711, lng: 18.1702395 },
    { lat: 49.0757055, lng: 18.1703536 },
    { lat: 49.0758733, lng: 18.1706823 },
    { lat: 49.0754935, lng: 18.1707677 },
    { lat: 49.0752168, lng: 18.1707458 },
    { lat: 49.0746796, lng: 18.1706268 },
    { lat: 49.074221, lng: 18.1704592 },
    { lat: 49.0740206, lng: 18.1704656 },
    { lat: 49.0733067, lng: 18.1701797 },
    { lat: 49.0732559, lng: 18.1712982 },
    { lat: 49.0729324, lng: 18.171524 },
    { lat: 49.0723601, lng: 18.1711059 },
    { lat: 49.0722096, lng: 18.171095 },
    { lat: 49.0719837, lng: 18.1709159 },
    { lat: 49.0716621, lng: 18.1709155 },
    { lat: 49.0714467, lng: 18.1708789 },
    { lat: 49.0714428, lng: 18.1708521 },
    { lat: 49.0713267, lng: 18.1709126 },
    { lat: 49.0710004, lng: 18.1710826 },
    { lat: 49.0707307, lng: 18.1714189 },
    { lat: 49.070502, lng: 18.17164 },
    { lat: 49.0702192, lng: 18.172221 },
    { lat: 49.0697412, lng: 18.172639 },
    { lat: 49.0695257, lng: 18.1727572 },
    { lat: 49.0693253, lng: 18.172981 },
    { lat: 49.0691649, lng: 18.1732161 },
    { lat: 49.0693616, lng: 18.1755457 },
    { lat: 49.0702884, lng: 18.1760636 },
    { lat: 49.070917, lng: 18.1764584 },
    { lat: 49.0709582, lng: 18.1762316 },
    { lat: 49.0709715, lng: 18.1762496 },
    { lat: 49.0709922, lng: 18.1762767 },
    { lat: 49.0724145, lng: 18.1781756 },
    { lat: 49.0741681, lng: 18.1790643 },
    { lat: 49.0748358, lng: 18.1792388 },
    { lat: 49.0758989, lng: 18.1801673 },
    { lat: 49.0768992, lng: 18.1810402 },
    { lat: 49.0778458, lng: 18.1816708 },
    { lat: 49.0790815, lng: 18.1824643 },
    { lat: 49.0800391, lng: 18.1848219 },
    { lat: 49.0804023, lng: 18.1868107 },
    { lat: 49.080411, lng: 18.1868632 },
    { lat: 49.0796191, lng: 18.1871434 },
    { lat: 49.0789804, lng: 18.1874779 },
    { lat: 49.0783817, lng: 18.1875051 },
    { lat: 49.0781281, lng: 18.1874703 },
    { lat: 49.0777769, lng: 18.1876832 },
    { lat: 49.0778144, lng: 18.1879053 },
    { lat: 49.0776369, lng: 18.1883323 },
    { lat: 49.0779759, lng: 18.1899801 },
    { lat: 49.0784892, lng: 18.1896832 },
    { lat: 49.0789833, lng: 18.1905096 },
    { lat: 49.0797308, lng: 18.19176 },
    { lat: 49.0797868, lng: 18.191813 },
    { lat: 49.0798204, lng: 18.1918456 },
    { lat: 49.0798536, lng: 18.1918761 },
    { lat: 49.0799694, lng: 18.1919871 },
    { lat: 49.0800564, lng: 18.1920703 },
    { lat: 49.0803711, lng: 18.1928554 },
    { lat: 49.0796821, lng: 18.193357 },
    { lat: 49.0796922, lng: 18.1933765 },
    { lat: 49.0795609, lng: 18.1934662 },
    { lat: 49.079399, lng: 18.1935748 },
    { lat: 49.0793939, lng: 18.1935629 },
    { lat: 49.079267, lng: 18.1936553 },
    { lat: 49.0791727, lng: 18.1937235 },
    { lat: 49.0793387, lng: 18.1943775 },
    { lat: 49.0787223, lng: 18.194724 },
    { lat: 49.0796119, lng: 18.195446 },
    { lat: 49.0809, lng: 18.1947675 },
    { lat: 49.0810862, lng: 18.1951039 },
    { lat: 49.0817082, lng: 18.1949287 },
    { lat: 49.0821607, lng: 18.1946135 },
    { lat: 49.0825337, lng: 18.1942099 },
    { lat: 49.0831077, lng: 18.1946015 },
    { lat: 49.0832757, lng: 18.1945389 },
    { lat: 49.0836999, lng: 18.1947956 },
    { lat: 49.0843546, lng: 18.1941283 },
    { lat: 49.085041, lng: 18.1948663 },
    { lat: 49.085052, lng: 18.194938 },
    { lat: 49.0875441, lng: 18.1974421 },
    { lat: 49.0899473, lng: 18.1973976 },
    { lat: 49.0903987, lng: 18.1973616 },
    { lat: 49.090544, lng: 18.197908 },
    { lat: 49.090923, lng: 18.197555 },
    { lat: 49.091472, lng: 18.197213 },
    { lat: 49.091582, lng: 18.197107 },
    { lat: 49.092724, lng: 18.19797 },
    { lat: 49.093196, lng: 18.197857 },
    { lat: 49.093212, lng: 18.197852 },
    { lat: 49.093255, lng: 18.197842 },
    { lat: 49.093259, lng: 18.197841 },
    { lat: 49.093328, lng: 18.197824 },
    { lat: 49.093357, lng: 18.197761 },
    { lat: 49.093475, lng: 18.197552 },
    { lat: 49.094135, lng: 18.197046 },
    { lat: 49.094566, lng: 18.196933 },
    { lat: 49.094644, lng: 18.196888 },
    { lat: 49.094936, lng: 18.196496 },
    { lat: 49.095077, lng: 18.195922 },
    { lat: 49.095301, lng: 18.19545 },
    { lat: 49.095452, lng: 18.194925 },
    { lat: 49.095531, lng: 18.194747 },
    { lat: 49.095577, lng: 18.194625 },
    { lat: 49.096078, lng: 18.194278 },
    { lat: 49.096912, lng: 18.193902 },
    { lat: 49.097541, lng: 18.193824 },
    { lat: 49.098364, lng: 18.193328 },
    { lat: 49.098787, lng: 18.192842 },
    { lat: 49.099029, lng: 18.192363 },
    { lat: 49.099211, lng: 18.191772 },
    { lat: 49.099637, lng: 18.19052 },
    { lat: 49.099924, lng: 18.19011 },
    { lat: 49.100046, lng: 18.189823 },
    { lat: 49.100306, lng: 18.189317 },
    { lat: 49.100597, lng: 18.189212 },
    { lat: 49.100691, lng: 18.189136 },
    { lat: 49.101591, lng: 18.188874 },
    { lat: 49.10177, lng: 18.188947 },
    { lat: 49.102244, lng: 18.188868 },
    { lat: 49.102499, lng: 18.188574 },
    { lat: 49.102783, lng: 18.188341 },
    { lat: 49.103074, lng: 18.187706 },
    { lat: 49.103228, lng: 18.187318 },
    { lat: 49.10331, lng: 18.186718 },
    { lat: 49.103236, lng: 18.186234 },
    { lat: 49.103299, lng: 18.185468 },
    { lat: 49.103391, lng: 18.184808 },
    { lat: 49.103657, lng: 18.184171 },
    { lat: 49.10413, lng: 18.183539 },
    { lat: 49.104296, lng: 18.183263 },
    { lat: 49.104562, lng: 18.182767 },
    { lat: 49.10472, lng: 18.182128 },
    { lat: 49.104808, lng: 18.181074 },
    { lat: 49.10493, lng: 18.180675 },
    { lat: 49.105322, lng: 18.180248 },
    { lat: 49.105844, lng: 18.180064 },
    { lat: 49.10671, lng: 18.179941 },
    { lat: 49.107492, lng: 18.179977 },
    { lat: 49.107728, lng: 18.179963 },
    { lat: 49.108702, lng: 18.179894 },
    { lat: 49.109302, lng: 18.179712 },
    { lat: 49.10995, lng: 18.179639 },
    { lat: 49.109987, lng: 18.17964 },
    { lat: 49.1101, lng: 18.179619 },
    { lat: 49.110407, lng: 18.1792 },
    { lat: 49.110869, lng: 18.178676 },
    { lat: 49.111338, lng: 18.178172 },
    { lat: 49.111463, lng: 18.178066 },
    { lat: 49.111511, lng: 18.178024 },
    { lat: 49.111643, lng: 18.177886 },
    { lat: 49.112072, lng: 18.17768 },
    { lat: 49.112104, lng: 18.17765 },
    { lat: 49.112154, lng: 18.177551 },
    { lat: 49.112641, lng: 18.176612 },
    { lat: 49.11355, lng: 18.175701 },
    { lat: 49.114939, lng: 18.173651 },
    { lat: 49.115985, lng: 18.171875 },
    { lat: 49.116016, lng: 18.171818 },
    { lat: 49.116596, lng: 18.171127 },
    { lat: 49.116987, lng: 18.170521 },
    { lat: 49.117263, lng: 18.170275 },
    { lat: 49.118105, lng: 18.169347 },
    { lat: 49.119105, lng: 18.168224 },
    { lat: 49.119146, lng: 18.168167 },
    { lat: 49.119147, lng: 18.16722 },
    { lat: 49.119015, lng: 18.165999 },
    { lat: 49.118881, lng: 18.165105 },
    { lat: 49.118523, lng: 18.163442 },
    { lat: 49.118461, lng: 18.163189 },
    { lat: 49.118281, lng: 18.162473 },
    { lat: 49.11818, lng: 18.162008 },
    { lat: 49.118039, lng: 18.161255 },
    { lat: 49.117939, lng: 18.159717 },
    { lat: 49.117922, lng: 18.158984 },
    { lat: 49.118046, lng: 18.158459 },
    { lat: 49.118565, lng: 18.157698 },
    { lat: 49.118972, lng: 18.156923 },
    { lat: 49.119291, lng: 18.156106 },
    { lat: 49.119808, lng: 18.155094 },
    { lat: 49.121145, lng: 18.155106 },
    { lat: 49.121145, lng: 18.155099 },
    { lat: 49.121146, lng: 18.155058 },
    { lat: 49.121751, lng: 18.153136 },
    { lat: 49.122115, lng: 18.153402 },
    { lat: 49.123269, lng: 18.153451 },
    { lat: 49.125562, lng: 18.153288 },
    { lat: 49.126815, lng: 18.153236 },
    { lat: 49.128061, lng: 18.152989 },
    { lat: 49.128668, lng: 18.152444 },
    { lat: 49.1294084, lng: 18.1522018 },
    { lat: 49.130085, lng: 18.151794 },
    { lat: 49.130404, lng: 18.151905 },
    { lat: 49.130814, lng: 18.151951 },
    { lat: 49.131155, lng: 18.151813 },
    { lat: 49.131555, lng: 18.151773 },
    { lat: 49.133348, lng: 18.152103 },
    { lat: 49.134506, lng: 18.152237 },
    { lat: 49.135369, lng: 18.152509 },
    { lat: 49.136125, lng: 18.152883 },
    { lat: 49.136516, lng: 18.153048 },
    { lat: 49.1370298, lng: 18.1527424 },
    { lat: 49.137613, lng: 18.152635 },
    { lat: 49.138605, lng: 18.150921 },
    { lat: 49.138707, lng: 18.150314 },
    { lat: 49.139001, lng: 18.149279 },
    { lat: 49.139067, lng: 18.149045 },
    { lat: 49.139298, lng: 18.147992 },
    { lat: 49.139675, lng: 18.145968 },
    { lat: 49.139802, lng: 18.145457 },
    { lat: 49.139804, lng: 18.145054 },
    { lat: 49.139972, lng: 18.143442 },
    { lat: 49.140924, lng: 18.142061 },
    { lat: 49.141116, lng: 18.14185 },
    { lat: 49.14131, lng: 18.141519 },
    { lat: 49.141585, lng: 18.141052 },
    { lat: 49.14174, lng: 18.140912 },
    { lat: 49.141901, lng: 18.140877 },
    { lat: 49.142165, lng: 18.140888 },
    { lat: 49.1422239, lng: 18.1408756 },
    { lat: 49.142926, lng: 18.140728 },
    { lat: 49.143283, lng: 18.139387 },
    { lat: 49.14335, lng: 18.138929 },
    { lat: 49.143333, lng: 18.138449 },
    { lat: 49.14317, lng: 18.137502 },
    { lat: 49.14293, lng: 18.136371 },
    { lat: 49.142902, lng: 18.135992 },
    { lat: 49.142893, lng: 18.135495 },
    { lat: 49.142952, lng: 18.135266 },
    { lat: 49.143219, lng: 18.134745 },
    { lat: 49.143386, lng: 18.134283 },
    { lat: 49.143468, lng: 18.133805 },
    { lat: 49.143577, lng: 18.133168 },
    { lat: 49.143685, lng: 18.132454 },
    { lat: 49.143738, lng: 18.131785 },
    { lat: 49.143786, lng: 18.13119 },
    { lat: 49.143755, lng: 18.130922 },
    { lat: 49.143772, lng: 18.130241 },
    { lat: 49.143785, lng: 18.130041 },
    { lat: 49.144019, lng: 18.129472 },
    { lat: 49.144221, lng: 18.128914 },
    { lat: 49.144302, lng: 18.128511 },
    { lat: 49.144332, lng: 18.127993 },
    { lat: 49.14423, lng: 18.127149 },
    { lat: 49.144183, lng: 18.126795 },
    { lat: 49.14415, lng: 18.126391 },
    { lat: 49.144172, lng: 18.125988 },
    { lat: 49.144465, lng: 18.124867 },
    { lat: 49.144748, lng: 18.123787 },
    { lat: 49.145052, lng: 18.123187 },
    { lat: 49.145047, lng: 18.122896 },
    { lat: 49.144894, lng: 18.122289 },
    { lat: 49.144416, lng: 18.121441 },
    { lat: 49.143923, lng: 18.120563 },
    { lat: 49.143745, lng: 18.120189 },
    { lat: 49.143593, lng: 18.119451 },
    { lat: 49.143623, lng: 18.1191 },
    { lat: 49.143493, lng: 18.11875 },
    { lat: 49.14345, lng: 18.118399 },
    { lat: 49.143456, lng: 18.118356 },
  ],
  Bohunice: [
    { lat: 49.0504365, lng: 18.1709194 },
    { lat: 49.0501941, lng: 18.1705282 },
    { lat: 49.0499653, lng: 18.1707107 },
    { lat: 49.0498023, lng: 18.1707996 },
    { lat: 49.049554, lng: 18.1708398 },
    { lat: 49.0488999, lng: 18.17114 },
    { lat: 49.048854, lng: 18.1709911 },
    { lat: 49.0487191, lng: 18.1709964 },
    { lat: 49.0486074, lng: 18.1709994 },
    { lat: 49.0485828, lng: 18.171003 },
    { lat: 49.0484995, lng: 18.1710234 },
    { lat: 49.0483892, lng: 18.1710496 },
    { lat: 49.0483317, lng: 18.1710698 },
    { lat: 49.0482833, lng: 18.1710917 },
    { lat: 49.0481743, lng: 18.1711407 },
    { lat: 49.0480426, lng: 18.1712032 },
    { lat: 49.0479235, lng: 18.1712539 },
    { lat: 49.0478982, lng: 18.1712033 },
    { lat: 49.0478799, lng: 18.1712183 },
    { lat: 49.0475849, lng: 18.1712902 },
    { lat: 49.0473309, lng: 18.1714829 },
    { lat: 49.0471832, lng: 18.171604 },
    { lat: 49.0468937, lng: 18.1718665 },
    { lat: 49.0464419, lng: 18.1718596 },
    { lat: 49.0455438, lng: 18.1718613 },
    { lat: 49.0452941, lng: 18.171993 },
    { lat: 49.0447503, lng: 18.1719163 },
    { lat: 49.0447229, lng: 18.1719131 },
    { lat: 49.0447024, lng: 18.17191 },
    { lat: 49.044697, lng: 18.1719093 },
    { lat: 49.0445363, lng: 18.1720224 },
    { lat: 49.0444468, lng: 18.1721324 },
    { lat: 49.0443877, lng: 18.1722058 },
    { lat: 49.0443442, lng: 18.1722317 },
    { lat: 49.0441368, lng: 18.1723542 },
    { lat: 49.044005, lng: 18.1723573 },
    { lat: 49.0439087, lng: 18.172314 },
    { lat: 49.0438431, lng: 18.1723709 },
    { lat: 49.0437169, lng: 18.1723135 },
    { lat: 49.0436402, lng: 18.1722752 },
    { lat: 49.043597, lng: 18.1723464 },
    { lat: 49.0435487, lng: 18.1724269 },
    { lat: 49.0436197, lng: 18.1727048 },
    { lat: 49.0434862, lng: 18.1728751 },
    { lat: 49.0432763, lng: 18.1727152 },
    { lat: 49.0429806, lng: 18.1726757 },
    { lat: 49.0421885, lng: 18.1727341 },
    { lat: 49.042085, lng: 18.1727032 },
    { lat: 49.0419325, lng: 18.1726576 },
    { lat: 49.0417508, lng: 18.1724609 },
    { lat: 49.0415419, lng: 18.1723401 },
    { lat: 49.041459, lng: 18.1722925 },
    { lat: 49.0414123, lng: 18.1722656 },
    { lat: 49.0414133, lng: 18.1723032 },
    { lat: 49.0414165, lng: 18.1723794 },
    { lat: 49.0414213, lng: 18.1725 },
    { lat: 49.0414298, lng: 18.1727075 },
    { lat: 49.0412028, lng: 18.1727844 },
    { lat: 49.0411993, lng: 18.1727402 },
    { lat: 49.0411303, lng: 18.1723462 },
    { lat: 49.0411168, lng: 18.1722674 },
    { lat: 49.0411154, lng: 18.1722636 },
    { lat: 49.0411024, lng: 18.1721934 },
    { lat: 49.0410878, lng: 18.1721163 },
    { lat: 49.0410825, lng: 18.1720846 },
    { lat: 49.0414603, lng: 18.171924 },
    { lat: 49.0414153, lng: 18.1718063 },
    { lat: 49.0413929, lng: 18.1717499 },
    { lat: 49.0413741, lng: 18.1716995 },
    { lat: 49.0413451, lng: 18.1716257 },
    { lat: 49.0413969, lng: 18.1715447 },
    { lat: 49.0414231, lng: 18.1714527 },
    { lat: 49.0415604, lng: 18.1709731 },
    { lat: 49.0416332, lng: 18.1708659 },
    { lat: 49.0417779, lng: 18.1705363 },
    { lat: 49.0417514, lng: 18.1704654 },
    { lat: 49.0417446, lng: 18.1704465 },
    { lat: 49.0416328, lng: 18.1701502 },
    { lat: 49.041573, lng: 18.1698546 },
    { lat: 49.0415204, lng: 18.1697458 },
    { lat: 49.0415547, lng: 18.1692801 },
    { lat: 49.0415377, lng: 18.1692018 },
    { lat: 49.041504, lng: 18.1690477 },
    { lat: 49.0414143, lng: 18.1676004 },
    { lat: 49.0412905, lng: 18.1668198 },
    { lat: 49.0411726, lng: 18.1662611 },
    { lat: 49.0411419, lng: 18.1658059 },
    { lat: 49.0410217, lng: 18.1653431 },
    { lat: 49.0410125, lng: 18.1648128 },
    { lat: 49.0405583, lng: 18.1649368 },
    { lat: 49.0404089, lng: 18.1650275 },
    { lat: 49.0403995, lng: 18.1650036 },
    { lat: 49.0403579, lng: 18.1649062 },
    { lat: 49.0402087, lng: 18.164511 },
    { lat: 49.0401823, lng: 18.1644048 },
    { lat: 49.0401307, lng: 18.1641944 },
    { lat: 49.0400987, lng: 18.163674 },
    { lat: 49.0393113, lng: 18.1634851 },
    { lat: 49.0383385, lng: 18.1629498 },
    { lat: 49.0379541, lng: 18.1617616 },
    { lat: 49.0378555, lng: 18.1596312 },
    { lat: 49.0373948, lng: 18.1595472 },
    { lat: 49.0369768, lng: 18.159383 },
    { lat: 49.0365202, lng: 18.1594078 },
    { lat: 49.0360399, lng: 18.1602784 },
    { lat: 49.0353887, lng: 18.1611432 },
    { lat: 49.0351597, lng: 18.161379 },
    { lat: 49.0350723, lng: 18.1615654 },
    { lat: 49.0345827, lng: 18.1618794 },
    { lat: 49.0343794, lng: 18.1620528 },
    { lat: 49.0341413, lng: 18.1621938 },
    { lat: 49.0337164, lng: 18.1622384 },
    { lat: 49.0336703, lng: 18.1622434 },
    { lat: 49.0329247, lng: 18.1623444 },
    { lat: 49.0327969, lng: 18.162932 },
    { lat: 49.032435, lng: 18.1634638 },
    { lat: 49.0322992, lng: 18.1634952 },
    { lat: 49.0322465, lng: 18.1635256 },
    { lat: 49.031901, lng: 18.1639113 },
    { lat: 49.031658, lng: 18.1639246 },
    { lat: 49.0311006, lng: 18.1641403 },
    { lat: 49.0309093, lng: 18.1640133 },
    { lat: 49.0307074, lng: 18.1639941 },
    { lat: 49.0299272, lng: 18.1644969 },
    { lat: 49.0297672, lng: 18.164758 },
    { lat: 49.0296248, lng: 18.1649492 },
    { lat: 49.0290964, lng: 18.1657875 },
    { lat: 49.0284633, lng: 18.1667896 },
    { lat: 49.0280517, lng: 18.167461 },
    { lat: 49.028127, lng: 18.1675898 },
    { lat: 49.0288704, lng: 18.1688661 },
    { lat: 49.0299003, lng: 18.1706272 },
    { lat: 49.0291727, lng: 18.1722829 },
    { lat: 49.0282637, lng: 18.1743572 },
    { lat: 49.0282157, lng: 18.1742726 },
    { lat: 49.0269053, lng: 18.1719608 },
    { lat: 49.0268919, lng: 18.172023 },
    { lat: 49.0262883, lng: 18.1748551 },
    { lat: 49.0256454, lng: 18.1765699 },
    { lat: 49.0253677, lng: 18.177396 },
    { lat: 49.0248102, lng: 18.1781229 },
    { lat: 49.0245855, lng: 18.1783156 },
    { lat: 49.0239521, lng: 18.1788601 },
    { lat: 49.0234618, lng: 18.179381 },
    { lat: 49.0220447, lng: 18.1805842 },
    { lat: 49.0216077, lng: 18.1805386 },
    { lat: 49.0208494, lng: 18.1808307 },
    { lat: 49.0204614, lng: 18.1809343 },
    { lat: 49.0200491, lng: 18.1813398 },
    { lat: 49.0196121, lng: 18.1818672 },
    { lat: 49.0195238, lng: 18.1819972 },
    { lat: 49.0186068, lng: 18.1833443 },
    { lat: 49.0188061, lng: 18.183905 },
    { lat: 49.019448, lng: 18.1857163 },
    { lat: 49.0177901, lng: 18.1868929 },
    { lat: 49.0181112, lng: 18.1879659 },
    { lat: 49.0171845, lng: 18.1881971 },
    { lat: 49.0171334, lng: 18.1880144 },
    { lat: 49.0166131, lng: 18.1882893 },
    { lat: 49.0160741, lng: 18.1871302 },
    { lat: 49.0156066, lng: 18.1874228 },
    { lat: 49.0143481, lng: 18.1880068 },
    { lat: 49.0144296, lng: 18.1891744 },
    { lat: 49.0144481, lng: 18.1894361 },
    { lat: 49.0139411, lng: 18.1896862 },
    { lat: 49.0132179, lng: 18.1900432 },
    { lat: 49.0124488, lng: 18.1902457 },
    { lat: 49.0122871, lng: 18.190202 },
    { lat: 49.0121783, lng: 18.1901722 },
    { lat: 49.0111517, lng: 18.1910855 },
    { lat: 49.0108815, lng: 18.1906612 },
    { lat: 49.0108546, lng: 18.190682 },
    { lat: 49.0107906, lng: 18.1907297 },
    { lat: 49.0107594, lng: 18.1907539 },
    { lat: 49.0105475, lng: 18.1910914 },
    { lat: 49.0105116, lng: 18.1911375 },
    { lat: 49.0105073, lng: 18.191142 },
    { lat: 49.0104917, lng: 18.1911621 },
    { lat: 49.0104864, lng: 18.1911693 },
    { lat: 49.0104822, lng: 18.1911741 },
    { lat: 49.0104727, lng: 18.1911865 },
    { lat: 49.0104851, lng: 18.1912079 },
    { lat: 49.0104318, lng: 18.1912606 },
    { lat: 49.0025211, lng: 18.2053695 },
    { lat: 49.0025109, lng: 18.2054061 },
    { lat: 49.0025007, lng: 18.2054462 },
    { lat: 49.0024934, lng: 18.2054752 },
    { lat: 49.0023642, lng: 18.2065125 },
    { lat: 49.0025114, lng: 18.2079051 },
    { lat: 49.0045221, lng: 18.208871 },
    { lat: 49.0060281, lng: 18.2091834 },
    { lat: 49.0069823, lng: 18.209827 },
    { lat: 49.0070561, lng: 18.2098884 },
    { lat: 49.0073271, lng: 18.2101136 },
    { lat: 49.0073507, lng: 18.2101342 },
    { lat: 49.0074532, lng: 18.209854 },
    { lat: 49.0080847, lng: 18.2105208 },
    { lat: 49.0084062, lng: 18.2098447 },
    { lat: 49.0096796, lng: 18.2114796 },
    { lat: 49.0130148, lng: 18.2134717 },
    { lat: 49.0146039, lng: 18.2127294 },
    { lat: 49.0165265, lng: 18.2118205 },
    { lat: 49.0175953, lng: 18.2113078 },
    { lat: 49.018282, lng: 18.2103307 },
    { lat: 49.0192496, lng: 18.2088416 },
    { lat: 49.0194206, lng: 18.2086522 },
    { lat: 49.0194735, lng: 18.2085928 },
    { lat: 49.0195298, lng: 18.2085314 },
    { lat: 49.0195329, lng: 18.2085274 },
    { lat: 49.0208912, lng: 18.2065276 },
    { lat: 49.0210318, lng: 18.2063433 },
    { lat: 49.0211546, lng: 18.2061994 },
    { lat: 49.0221913, lng: 18.204989 },
    { lat: 49.0222165, lng: 18.2049594 },
    { lat: 49.0222708, lng: 18.2048994 },
    { lat: 49.0222908, lng: 18.2048767 },
    { lat: 49.0224308, lng: 18.2047205 },
    { lat: 49.0226099, lng: 18.204636 },
    { lat: 49.0230761, lng: 18.2044601 },
    { lat: 49.0238059, lng: 18.2041391 },
    { lat: 49.0239159, lng: 18.204049 },
    { lat: 49.0239663, lng: 18.2039838 },
    { lat: 49.0240453, lng: 18.2038065 },
    { lat: 49.024395, lng: 18.20286 },
    { lat: 49.0246483, lng: 18.2023556 },
    { lat: 49.0246899, lng: 18.2022724 },
    { lat: 49.0246967, lng: 18.2022543 },
    { lat: 49.0249808, lng: 18.2015005 },
    { lat: 49.0252409, lng: 18.2014514 },
    { lat: 49.0254952, lng: 18.2012208 },
    { lat: 49.0257414, lng: 18.2008495 },
    { lat: 49.0259357, lng: 18.2006542 },
    { lat: 49.0261089, lng: 18.200207 },
    { lat: 49.0261765, lng: 18.2000323 },
    { lat: 49.0264191, lng: 18.1997176 },
    { lat: 49.026704, lng: 18.1987782 },
    { lat: 49.0267315, lng: 18.1986879 },
    { lat: 49.0267763, lng: 18.198604 },
    { lat: 49.0268222, lng: 18.1985174 },
    { lat: 49.0268274, lng: 18.1985088 },
    { lat: 49.0269046, lng: 18.1983623 },
    { lat: 49.0269281, lng: 18.1983189 },
    { lat: 49.0269267, lng: 18.1982325 },
    { lat: 49.0269169, lng: 18.1982215 },
    { lat: 49.0268401, lng: 18.1981365 },
    { lat: 49.026723, lng: 18.198007 },
    { lat: 49.026593, lng: 18.1978634 },
    { lat: 49.0264327, lng: 18.1975855 },
    { lat: 49.0265717, lng: 18.1974531 },
    { lat: 49.0267181, lng: 18.1971109 },
    { lat: 49.0268722, lng: 18.1967669 },
    { lat: 49.0269201, lng: 18.1961648 },
    { lat: 49.0269971, lng: 18.1957965 },
    { lat: 49.0271283, lng: 18.1954067 },
    { lat: 49.0273422, lng: 18.1951403 },
    { lat: 49.0276847, lng: 18.1942824 },
    { lat: 49.027819, lng: 18.1940468 },
    { lat: 49.0280504, lng: 18.1938105 },
    { lat: 49.0281904, lng: 18.1936165 },
    { lat: 49.028267, lng: 18.1935341 },
    { lat: 49.0283559, lng: 18.1934723 },
    { lat: 49.0285944, lng: 18.1933433 },
    { lat: 49.0286681, lng: 18.1933293 },
    { lat: 49.0289225, lng: 18.1930806 },
    { lat: 49.0297733, lng: 18.1922506 },
    { lat: 49.0309003, lng: 18.1903769 },
    { lat: 49.0309397, lng: 18.1903548 },
    { lat: 49.0318965, lng: 18.1898002 },
    { lat: 49.0324357, lng: 18.1905081 },
    { lat: 49.0328235, lng: 18.1901662 },
    { lat: 49.0331376, lng: 18.1902541 },
    { lat: 49.0334399, lng: 18.1895056 },
    { lat: 49.0334474, lng: 18.1894582 },
    { lat: 49.0336619, lng: 18.1880795 },
    { lat: 49.0330514, lng: 18.1872293 },
    { lat: 49.0334198, lng: 18.1869644 },
    { lat: 49.0336149, lng: 18.1867667 },
    { lat: 49.033926, lng: 18.1866794 },
    { lat: 49.03426, lng: 18.1865043 },
    { lat: 49.034336, lng: 18.186431 },
    { lat: 49.0352492, lng: 18.1852566 },
    { lat: 49.0356612, lng: 18.1849021 },
    { lat: 49.0360383, lng: 18.1847705 },
    { lat: 49.0360288, lng: 18.1846833 },
    { lat: 49.0361069, lng: 18.1846877 },
    { lat: 49.0365439, lng: 18.1841609 },
    { lat: 49.0372041, lng: 18.1836706 },
    { lat: 49.0375245, lng: 18.1826837 },
    { lat: 49.0378323, lng: 18.1817341 },
    { lat: 49.0381964, lng: 18.1807065 },
    { lat: 49.03826, lng: 18.1796713 },
    { lat: 49.0389891, lng: 18.1797098 },
    { lat: 49.0396435, lng: 18.1798493 },
    { lat: 49.0403153, lng: 18.1796976 },
    { lat: 49.0405478, lng: 18.1796981 },
    { lat: 49.0411507, lng: 18.1798425 },
    { lat: 49.0415858, lng: 18.1799125 },
    { lat: 49.0416273, lng: 18.1799113 },
    { lat: 49.0422525, lng: 18.1797745 },
    { lat: 49.0431528, lng: 18.1793191 },
    { lat: 49.0441372, lng: 18.1781656 },
    { lat: 49.0445569, lng: 18.1778326 },
    { lat: 49.0452153, lng: 18.1769359 },
    { lat: 49.0459189, lng: 18.1772231 },
    { lat: 49.0463421, lng: 18.1774411 },
    { lat: 49.0470599, lng: 18.1777368 },
    { lat: 49.0476705, lng: 18.1779562 },
    { lat: 49.0477108, lng: 18.1779706 },
    { lat: 49.0483221, lng: 18.1769989 },
    { lat: 49.0485512, lng: 18.1766685 },
    { lat: 49.0487025, lng: 18.1763754 },
    { lat: 49.0488241, lng: 18.1760337 },
    { lat: 49.048964, lng: 18.1754637 },
    { lat: 49.0492228, lng: 18.1746624 },
    { lat: 49.0495558, lng: 18.1738027 },
    { lat: 49.0498153, lng: 18.1731315 },
    { lat: 49.0500997, lng: 18.1725218 },
    { lat: 49.0501702, lng: 18.1723153 },
    { lat: 49.0502549, lng: 18.1719256 },
    { lat: 49.0502942, lng: 18.1717606 },
    { lat: 49.0503325, lng: 18.1716045 },
    { lat: 49.0503702, lng: 18.171434 },
    { lat: 49.0504051, lng: 18.1712747 },
    { lat: 49.0504437, lng: 18.1711231 },
    { lat: 49.0504461, lng: 18.1710455 },
    { lat: 49.0504365, lng: 18.1709194 },
  ],
  Bolešov: [
    { lat: 49.0380822, lng: 18.1150749 },
    { lat: 49.0379877, lng: 18.1144387 },
    { lat: 49.0379873, lng: 18.1143139 },
    { lat: 49.0379861, lng: 18.114235 },
    { lat: 49.0379741, lng: 18.1141837 },
    { lat: 49.037961, lng: 18.1141322 },
    { lat: 49.037943, lng: 18.1140551 },
    { lat: 49.0377378, lng: 18.1132018 },
    { lat: 49.0374918, lng: 18.1117968 },
    { lat: 49.0372306, lng: 18.1110876 },
    { lat: 49.0364008, lng: 18.1101436 },
    { lat: 49.0359672, lng: 18.1095737 },
    { lat: 49.0348746, lng: 18.1085768 },
    { lat: 49.0350059, lng: 18.1076712 },
    { lat: 49.0349899, lng: 18.1075705 },
    { lat: 49.0347781, lng: 18.1067559 },
    { lat: 49.0346749, lng: 18.1058738 },
    { lat: 49.0344532, lng: 18.1050055 },
    { lat: 49.0344383, lng: 18.1040317 },
    { lat: 49.0344623, lng: 18.1034703 },
    { lat: 49.0343913, lng: 18.1027926 },
    { lat: 49.034176, lng: 18.1013347 },
    { lat: 49.0347649, lng: 18.1002439 },
    { lat: 49.0347714, lng: 18.0999294 },
    { lat: 49.0349741, lng: 18.0992002 },
    { lat: 49.0343096, lng: 18.097513 },
    { lat: 49.0341066, lng: 18.097418 },
    { lat: 49.0338983, lng: 18.097328 },
    { lat: 49.0335596, lng: 18.0970541 },
    { lat: 49.0335853, lng: 18.0969921 },
    { lat: 49.0332635, lng: 18.0965605 },
    { lat: 49.0331, lng: 18.096373 },
    { lat: 49.032353, lng: 18.096589 },
    { lat: 49.032013, lng: 18.096434 },
    { lat: 49.0317, lng: 18.096285 },
    { lat: 49.03145, lng: 18.097009 },
    { lat: 49.030838, lng: 18.097953 },
    { lat: 49.030568, lng: 18.098236 },
    { lat: 49.030366, lng: 18.098433 },
    { lat: 49.029799, lng: 18.098965 },
    { lat: 49.029686, lng: 18.099104 },
    { lat: 49.029433, lng: 18.09932 },
    { lat: 49.029145, lng: 18.099585 },
    { lat: 49.028648, lng: 18.099606 },
    { lat: 49.02821, lng: 18.099418 },
    { lat: 49.02808, lng: 18.099417 },
    { lat: 49.027576, lng: 18.099684 },
    { lat: 49.026897, lng: 18.100302 },
    { lat: 49.025774, lng: 18.09998 },
    { lat: 49.025479, lng: 18.099893 },
    { lat: 49.023897, lng: 18.099533 },
    { lat: 49.0241, lng: 18.099021 },
    { lat: 49.024405, lng: 18.09829 },
    { lat: 49.025183, lng: 18.097107 },
    { lat: 49.026138, lng: 18.095835 },
    { lat: 49.02568, lng: 18.095289 },
    { lat: 49.025616, lng: 18.095191 },
    { lat: 49.025418, lng: 18.094885 },
    { lat: 49.024576, lng: 18.094065 },
    { lat: 49.024441, lng: 18.093976 },
    { lat: 49.024106, lng: 18.093851 },
    { lat: 49.023766, lng: 18.093694 },
    { lat: 49.023154, lng: 18.093347 },
    { lat: 49.022605, lng: 18.092876 },
    { lat: 49.022324, lng: 18.092615 },
    { lat: 49.021821, lng: 18.092097 },
    { lat: 49.021619, lng: 18.091942 },
    { lat: 49.021295, lng: 18.091676 },
    { lat: 49.021182, lng: 18.091666 },
    { lat: 49.021107, lng: 18.091733 },
    { lat: 49.021002, lng: 18.092044 },
    { lat: 49.020751, lng: 18.092642 },
    { lat: 49.020675, lng: 18.092769 },
    { lat: 49.020384, lng: 18.093108 },
    { lat: 49.019929, lng: 18.093634 },
    { lat: 49.01966, lng: 18.094028 },
    { lat: 49.019583, lng: 18.09425 },
    { lat: 49.019521, lng: 18.094635 },
    { lat: 49.019476, lng: 18.094998 },
    { lat: 49.019497, lng: 18.095078 },
    { lat: 49.019484, lng: 18.0953 },
    { lat: 49.019545, lng: 18.095824 },
    { lat: 49.019516, lng: 18.09634 },
    { lat: 49.019517, lng: 18.09667 },
    { lat: 49.019648, lng: 18.097175 },
    { lat: 49.019881, lng: 18.097908 },
    { lat: 49.019945, lng: 18.098419 },
    { lat: 49.019966, lng: 18.098825 },
    { lat: 49.020008, lng: 18.099257 },
    { lat: 49.019993, lng: 18.099629 },
    { lat: 49.01992, lng: 18.099783 },
    { lat: 49.019466, lng: 18.100217 },
    { lat: 49.018647, lng: 18.100928 },
    { lat: 49.018449, lng: 18.101066 },
    { lat: 49.018074, lng: 18.101184 },
    { lat: 49.017845, lng: 18.101148 },
    { lat: 49.017743, lng: 18.101094 },
    { lat: 49.017369, lng: 18.100721 },
    { lat: 49.017141, lng: 18.100604 },
    { lat: 49.016866, lng: 18.100554 },
    { lat: 49.016562, lng: 18.100559 },
    { lat: 49.016293, lng: 18.100615 },
    { lat: 49.015877, lng: 18.100804 },
    { lat: 49.01543, lng: 18.101059 },
    { lat: 49.014702, lng: 18.101295 },
    { lat: 49.013974, lng: 18.101504 },
    { lat: 49.013783, lng: 18.101505 },
    { lat: 49.013022, lng: 18.101674 },
    { lat: 49.012892, lng: 18.101779 },
    { lat: 49.012605, lng: 18.102454 },
    { lat: 49.012495, lng: 18.102645 },
    { lat: 49.012056, lng: 18.103128 },
    { lat: 49.012025, lng: 18.103182 },
    { lat: 49.011897, lng: 18.103365 },
    { lat: 49.011719, lng: 18.103795 },
    { lat: 49.011656, lng: 18.10428 },
    { lat: 49.011649, lng: 18.104337 },
    { lat: 49.011681, lng: 18.105531 },
    { lat: 49.0116818, lng: 18.1064439 },
    { lat: 49.0117375, lng: 18.1068829 },
    { lat: 49.012231, lng: 18.1082681 },
    { lat: 49.0126146, lng: 18.1090658 },
    { lat: 49.0126319, lng: 18.1090326 },
    { lat: 49.0126675, lng: 18.109141 },
    { lat: 49.0124485, lng: 18.1103074 },
    { lat: 49.0121658, lng: 18.1115054 },
    { lat: 49.012115, lng: 18.1116046 },
    { lat: 49.0120501, lng: 18.1117279 },
    { lat: 49.0119725, lng: 18.1118713 },
    { lat: 49.011638, lng: 18.1120556 },
    { lat: 49.0113327, lng: 18.1123938 },
    { lat: 49.0110954, lng: 18.1126815 },
    { lat: 49.0108797, lng: 18.113379 },
    { lat: 49.0107881, lng: 18.1136875 },
    { lat: 49.0107295, lng: 18.1138855 },
    { lat: 49.0106522, lng: 18.1141468 },
    { lat: 49.0108304, lng: 18.1146136 },
    { lat: 49.0110195, lng: 18.1151106 },
    { lat: 49.010182, lng: 18.1164359 },
    { lat: 49.0099231, lng: 18.1167384 },
    { lat: 49.0089631, lng: 18.1176979 },
    { lat: 49.0085301, lng: 18.1182296 },
    { lat: 49.0080291, lng: 18.1191661 },
    { lat: 49.0074583, lng: 18.1195585 },
    { lat: 49.0063954, lng: 18.1204975 },
    { lat: 49.0052074, lng: 18.1212049 },
    { lat: 49.0039123, lng: 18.1221044 },
    { lat: 49.0031446, lng: 18.1222937 },
    { lat: 49.0012232, lng: 18.1235518 },
    { lat: 48.9996409, lng: 18.1236432 },
    { lat: 48.9993991, lng: 18.1238542 },
    { lat: 48.9988467, lng: 18.1242547 },
    { lat: 48.9986901, lng: 18.1244125 },
    { lat: 48.9983923, lng: 18.1245501 },
    { lat: 48.998235, lng: 18.1246225 },
    { lat: 48.9979898, lng: 18.1244378 },
    { lat: 48.9977863, lng: 18.1246698 },
    { lat: 48.9975365, lng: 18.1249537 },
    { lat: 48.9972123, lng: 18.1249855 },
    { lat: 48.9969889, lng: 18.1250071 },
    { lat: 48.9967298, lng: 18.1252462 },
    { lat: 48.9962693, lng: 18.1253264 },
    { lat: 48.9960293, lng: 18.125563 },
    { lat: 48.99589, lng: 18.1253624 },
    { lat: 48.9957137, lng: 18.1253762 },
    { lat: 48.9954978, lng: 18.1255858 },
    { lat: 48.9952449, lng: 18.1254439 },
    { lat: 48.9948342, lng: 18.1255056 },
    { lat: 48.9943577, lng: 18.1252081 },
    { lat: 48.9939998, lng: 18.1249307 },
    { lat: 48.9940036, lng: 18.1244907 },
    { lat: 48.993782, lng: 18.1241498 },
    { lat: 48.9935264, lng: 18.1240819 },
    { lat: 48.9930101, lng: 18.124443 },
    { lat: 48.9925899, lng: 18.1252362 },
    { lat: 48.9923552, lng: 18.1251364 },
    { lat: 48.9917443, lng: 18.1254246 },
    { lat: 48.9915849, lng: 18.1259842 },
    { lat: 48.9911419, lng: 18.1262273 },
    { lat: 48.9908739, lng: 18.1267252 },
    { lat: 48.9907386, lng: 18.1269409 },
    { lat: 48.9901551, lng: 18.127016 },
    { lat: 48.9899309, lng: 18.1271994 },
    { lat: 48.9898479, lng: 18.127481 },
    { lat: 48.9896134, lng: 18.1277976 },
    { lat: 48.9892402, lng: 18.1280174 },
    { lat: 48.9891906, lng: 18.1280761 },
    { lat: 48.9891759, lng: 18.128095 },
    { lat: 48.9890704, lng: 18.1282215 },
    { lat: 48.9889164, lng: 18.1282324 },
    { lat: 48.9888061, lng: 18.1284932 },
    { lat: 48.9885801, lng: 18.1286618 },
    { lat: 48.9882563, lng: 18.1287219 },
    { lat: 48.9881002, lng: 18.1288879 },
    { lat: 48.9879852, lng: 18.1291846 },
    { lat: 48.9877149, lng: 18.1296425 },
    { lat: 48.9874981, lng: 18.1299341 },
    { lat: 48.987274, lng: 18.1301767 },
    { lat: 48.9871777, lng: 18.130343 },
    { lat: 48.9869662, lng: 18.130844 },
    { lat: 48.9864223, lng: 18.1318012 },
    { lat: 48.9857019, lng: 18.1330636 },
    { lat: 48.9852603, lng: 18.1338339 },
    { lat: 48.9850342, lng: 18.1341605 },
    { lat: 48.9847586, lng: 18.134605 },
    { lat: 48.9845768, lng: 18.1349163 },
    { lat: 48.9842561, lng: 18.1354599 },
    { lat: 48.984079, lng: 18.1358335 },
    { lat: 48.9839347, lng: 18.1362454 },
    { lat: 48.9837482, lng: 18.1366277 },
    { lat: 48.9831852, lng: 18.1376057 },
    { lat: 48.9830484, lng: 18.1378377 },
    { lat: 48.9830032, lng: 18.1379137 },
    { lat: 48.9824787, lng: 18.1388973 },
    { lat: 48.9817783, lng: 18.1401129 },
    { lat: 48.9816684, lng: 18.1403022 },
    { lat: 48.9811635, lng: 18.141231 },
    { lat: 48.981062, lng: 18.1414676 },
    { lat: 48.9808406, lng: 18.1418922 },
    { lat: 48.9806876, lng: 18.1421745 },
    { lat: 48.9806477, lng: 18.1422459 },
    { lat: 48.9804935, lng: 18.1425244 },
    { lat: 48.9804739, lng: 18.1425576 },
    { lat: 48.9804607, lng: 18.1425816 },
    { lat: 48.9803707, lng: 18.142724 },
    { lat: 48.980202, lng: 18.1429882 },
    { lat: 48.9801203, lng: 18.1431162 },
    { lat: 48.9799756, lng: 18.1433464 },
    { lat: 48.9795396, lng: 18.144064 },
    { lat: 48.9794893, lng: 18.1440908 },
    { lat: 48.979429, lng: 18.1441222 },
    { lat: 48.9794081, lng: 18.144134 },
    { lat: 48.9783135, lng: 18.1459329 },
    { lat: 48.9778385, lng: 18.1466948 },
    { lat: 48.9775554, lng: 18.1471456 },
    { lat: 48.9774358, lng: 18.1472866 },
    { lat: 48.9767259, lng: 18.1485356 },
    { lat: 48.976672, lng: 18.1486376 },
    { lat: 48.976607, lng: 18.1487615 },
    { lat: 48.9765934, lng: 18.1487598 },
    { lat: 48.9765284, lng: 18.148887 },
    { lat: 48.9764716, lng: 18.1489771 },
    { lat: 48.9761751, lng: 18.149452 },
    { lat: 48.9754694, lng: 18.1505793 },
    { lat: 48.9752813, lng: 18.1508795 },
    { lat: 48.9752182, lng: 18.1509798 },
    { lat: 48.9751924, lng: 18.1510199 },
    { lat: 48.9751288, lng: 18.1511106 },
    { lat: 48.9745947, lng: 18.1519848 },
    { lat: 48.9745419, lng: 18.1520694 },
    { lat: 48.9744904, lng: 18.1521521 },
    { lat: 48.9743829, lng: 18.1523259 },
    { lat: 48.9743046, lng: 18.1524523 },
    { lat: 48.9742332, lng: 18.152564 },
    { lat: 48.9743545, lng: 18.1526332 },
    { lat: 48.9744272, lng: 18.1529346 },
    { lat: 48.9747877, lng: 18.1538243 },
    { lat: 48.9751444, lng: 18.1546695 },
    { lat: 48.9752007, lng: 18.1548032 },
    { lat: 48.9756094, lng: 18.1557473 },
    { lat: 48.97607, lng: 18.1568888 },
    { lat: 48.9761828, lng: 18.1571616 },
    { lat: 48.9761973, lng: 18.1571977 },
    { lat: 48.9766769, lng: 18.1583549 },
    { lat: 48.9766902, lng: 18.158388 },
    { lat: 48.9767267, lng: 18.1584748 },
    { lat: 48.9767346, lng: 18.1584942 },
    { lat: 48.9767489, lng: 18.1585271 },
    { lat: 48.9775249, lng: 18.1604227 },
    { lat: 48.9782555, lng: 18.1622173 },
    { lat: 48.9790159, lng: 18.1640568 },
    { lat: 48.9795171, lng: 18.165277 },
    { lat: 48.9795663, lng: 18.1653466 },
    { lat: 48.9796389, lng: 18.1654523 },
    { lat: 48.9801461, lng: 18.1661877 },
    { lat: 48.9801855, lng: 18.166243 },
    { lat: 48.9808194, lng: 18.1671625 },
    { lat: 48.9808427, lng: 18.1671957 },
    { lat: 48.9812859, lng: 18.1678387 },
    { lat: 48.9815161, lng: 18.1681712 },
    { lat: 48.9815563, lng: 18.1682291 },
    { lat: 48.9816661, lng: 18.1683873 },
    { lat: 48.9816982, lng: 18.1684337 },
    { lat: 48.9818928, lng: 18.1687161 },
    { lat: 48.9821856, lng: 18.1691405 },
    { lat: 48.9822508, lng: 18.1692329 },
    { lat: 48.9824342, lng: 18.1694992 },
    { lat: 48.9825795, lng: 18.1697113 },
    { lat: 48.9827962, lng: 18.1700249 },
    { lat: 48.9828208, lng: 18.1700605 },
    { lat: 48.9830288, lng: 18.1703619 },
    { lat: 48.9834645, lng: 18.1709921 },
    { lat: 48.9835493, lng: 18.1711145 },
    { lat: 48.9836269, lng: 18.1712273 },
    { lat: 48.9836367, lng: 18.1712396 },
    { lat: 48.9837437, lng: 18.1713948 },
    { lat: 48.9837968, lng: 18.1714729 },
    { lat: 48.9838434, lng: 18.1715396 },
    { lat: 48.9842888, lng: 18.1711208 },
    { lat: 48.9843081, lng: 18.1711025 },
    { lat: 48.9845293, lng: 18.1708903 },
    { lat: 48.9846407, lng: 18.1707832 },
    { lat: 48.985386, lng: 18.1700681 },
    { lat: 48.9866007, lng: 18.1689376 },
    { lat: 48.9866232, lng: 18.1689167 },
    { lat: 48.9871982, lng: 18.1683809 },
    { lat: 48.9872271, lng: 18.168354 },
    { lat: 48.987309, lng: 18.1682853 },
    { lat: 48.9901658, lng: 18.1658657 },
    { lat: 48.9901763, lng: 18.1658506 },
    { lat: 48.9902271, lng: 18.1657731 },
    { lat: 48.9902549, lng: 18.1657302 },
    { lat: 48.9905611, lng: 18.1652652 },
    { lat: 48.9905809, lng: 18.1652342 },
    { lat: 48.9906266, lng: 18.165166 },
    { lat: 48.9906504, lng: 18.1651287 },
    { lat: 48.9911267, lng: 18.1658204 },
    { lat: 48.9913735, lng: 18.1655397 },
    { lat: 48.991764, lng: 18.1650484 },
    { lat: 48.9921361, lng: 18.1646102 },
    { lat: 48.9925945, lng: 18.1641659 },
    { lat: 48.9930497, lng: 18.1637828 },
    { lat: 48.9935989, lng: 18.1634411 },
    { lat: 48.994197, lng: 18.1629897 },
    { lat: 48.9945607, lng: 18.1627243 },
    { lat: 48.9952153, lng: 18.1622942 },
    { lat: 48.9953027, lng: 18.1622255 },
    { lat: 48.9953307, lng: 18.1622034 },
    { lat: 48.9953718, lng: 18.1621901 },
    { lat: 48.9954023, lng: 18.1621702 },
    { lat: 48.9957443, lng: 18.1619167 },
    { lat: 48.9961377, lng: 18.1615568 },
    { lat: 48.9962086, lng: 18.1614949 },
    { lat: 48.996574, lng: 18.1611754 },
    { lat: 48.9971346, lng: 18.1608023 },
    { lat: 48.9976177, lng: 18.1604521 },
    { lat: 48.9979509, lng: 18.1601616 },
    { lat: 48.9981733, lng: 18.1599301 },
    { lat: 48.9984053, lng: 18.1596459 },
    { lat: 48.9984871, lng: 18.1595401 },
    { lat: 48.9985682, lng: 18.1594359 },
    { lat: 48.9987142, lng: 18.1592494 },
    { lat: 48.9987299, lng: 18.1592258 },
    { lat: 48.9988881, lng: 18.158996 },
    { lat: 48.999114, lng: 18.1586691 },
    { lat: 48.9994487, lng: 18.15817 },
    { lat: 48.9997048, lng: 18.1578337 },
    { lat: 48.9999535, lng: 18.1575699 },
    { lat: 49.000266, lng: 18.1572358 },
    { lat: 49.0009568, lng: 18.156473 },
    { lat: 49.0015657, lng: 18.1557422 },
    { lat: 49.0020362, lng: 18.1550924 },
    { lat: 49.0028744, lng: 18.1536389 },
    { lat: 49.003551, lng: 18.1523589 },
    { lat: 49.0038793, lng: 18.1518893 },
    { lat: 49.0038958, lng: 18.1518228 },
    { lat: 49.0039049, lng: 18.1517838 },
    { lat: 49.0039232, lng: 18.1517113 },
    { lat: 49.0040977, lng: 18.1510167 },
    { lat: 49.0046774, lng: 18.1503846 },
    { lat: 49.0047163, lng: 18.1503525 },
    { lat: 49.0048878, lng: 18.1499648 },
    { lat: 49.0050123, lng: 18.1497948 },
    { lat: 49.0052427, lng: 18.1496196 },
    { lat: 49.0054767, lng: 18.1492934 },
    { lat: 49.0057169, lng: 18.1490382 },
    { lat: 49.0063403, lng: 18.1480875 },
    { lat: 49.0071388, lng: 18.1480419 },
    { lat: 49.0073949, lng: 18.1476861 },
    { lat: 49.0076548, lng: 18.1472853 },
    { lat: 49.0078561, lng: 18.1469163 },
    { lat: 49.0080279, lng: 18.146471 },
    { lat: 49.0081382, lng: 18.1459977 },
    { lat: 49.0081354, lng: 18.1459499 },
    { lat: 49.008148, lng: 18.1458925 },
    { lat: 49.00841, lng: 18.1446826 },
    { lat: 49.0085593, lng: 18.1441649 },
    { lat: 49.0088603, lng: 18.143454 },
    { lat: 49.0090257, lng: 18.1431484 },
    { lat: 49.0094143, lng: 18.1424744 },
    { lat: 49.009485, lng: 18.142351 },
    { lat: 49.0096922, lng: 18.1419916 },
    { lat: 49.0098475, lng: 18.1415324 },
    { lat: 49.0102811, lng: 18.140128 },
    { lat: 49.0106838, lng: 18.1393834 },
    { lat: 49.0112122, lng: 18.1390373 },
    { lat: 49.0114638, lng: 18.1388815 },
    { lat: 49.0114846, lng: 18.138869 },
    { lat: 49.0118615, lng: 18.1386358 },
    { lat: 49.0119271, lng: 18.1385957 },
    { lat: 49.0123921, lng: 18.1383083 },
    { lat: 49.0138854, lng: 18.1371619 },
    { lat: 49.0143038, lng: 18.1369001 },
    { lat: 49.0153382, lng: 18.1362519 },
    { lat: 49.0161511, lng: 18.1361597 },
    { lat: 49.0165746, lng: 18.1358116 },
    { lat: 49.016529, lng: 18.1362142 },
    { lat: 49.0167157, lng: 18.1362461 },
    { lat: 49.0170848, lng: 18.1366955 },
    { lat: 49.0172753, lng: 18.1359888 },
    { lat: 49.0173283, lng: 18.1357326 },
    { lat: 49.017613, lng: 18.1359933 },
    { lat: 49.0179113, lng: 18.1349638 },
    { lat: 49.0180741, lng: 18.1349705 },
    { lat: 49.0181797, lng: 18.1350214 },
    { lat: 49.0185009, lng: 18.1348966 },
    { lat: 49.0187125, lng: 18.1347105 },
    { lat: 49.0190701, lng: 18.1347286 },
    { lat: 49.019456, lng: 18.1343355 },
    { lat: 49.0195382, lng: 18.1340226 },
    { lat: 49.0196236, lng: 18.1337041 },
    { lat: 49.0196931, lng: 18.1337322 },
    { lat: 49.0196883, lng: 18.1334733 },
    { lat: 49.0197338, lng: 18.1332083 },
    { lat: 49.0196952, lng: 18.1330276 },
    { lat: 49.0196557, lng: 18.1326554 },
    { lat: 49.0196134, lng: 18.1325862 },
    { lat: 49.0193862, lng: 18.1321327 },
    { lat: 49.0194145, lng: 18.1321152 },
    { lat: 49.0196605, lng: 18.1319975 },
    { lat: 49.0202948, lng: 18.1316475 },
    { lat: 49.0205427, lng: 18.1314919 },
    { lat: 49.0206782, lng: 18.1314068 },
    { lat: 49.0209922, lng: 18.1313322 },
    { lat: 49.0214076, lng: 18.1309844 },
    { lat: 49.0214713, lng: 18.1309363 },
    { lat: 49.0214983, lng: 18.1309158 },
    { lat: 49.0212322, lng: 18.1307809 },
    { lat: 49.0209155, lng: 18.1302997 },
    { lat: 49.0212573, lng: 18.1297496 },
    { lat: 49.0213818, lng: 18.1295629 },
    { lat: 49.0215833, lng: 18.1292617 },
    { lat: 49.0216318, lng: 18.1292034 },
    { lat: 49.0216402, lng: 18.1291894 },
    { lat: 49.0220268, lng: 18.1287933 },
    { lat: 49.022782, lng: 18.1280189 },
    { lat: 49.0234972, lng: 18.1271334 },
    { lat: 49.0239298, lng: 18.1263611 },
    { lat: 49.0240217, lng: 18.1261561 },
    { lat: 49.0241612, lng: 18.1259922 },
    { lat: 49.0245662, lng: 18.125754 },
    { lat: 49.0249496, lng: 18.125514 },
    { lat: 49.0254047, lng: 18.1255811 },
    { lat: 49.0260351, lng: 18.1253771 },
    { lat: 49.0262683, lng: 18.1252924 },
    { lat: 49.026708, lng: 18.1254404 },
    { lat: 49.0270851, lng: 18.125583 },
    { lat: 49.0274548, lng: 18.1254783 },
    { lat: 49.0277066, lng: 18.1252856 },
    { lat: 49.028122, lng: 18.1252679 },
    { lat: 49.028366, lng: 18.125173 },
    { lat: 49.0287062, lng: 18.1247349 },
    { lat: 49.0292656, lng: 18.1246928 },
    { lat: 49.0294723, lng: 18.1247547 },
    { lat: 49.0296992, lng: 18.1246582 },
    { lat: 49.0298859, lng: 18.1236091 },
    { lat: 49.029939, lng: 18.1232604 },
    { lat: 49.0303348, lng: 18.1228259 },
    { lat: 49.0307834, lng: 18.1227206 },
    { lat: 49.0312284, lng: 18.1227272 },
    { lat: 49.0313606, lng: 18.1227124 },
    { lat: 49.0315725, lng: 18.1226107 },
    { lat: 49.031884, lng: 18.1222393 },
    { lat: 49.0324487, lng: 18.1215855 },
    { lat: 49.0331663, lng: 18.1207249 },
    { lat: 49.0334876, lng: 18.120251 },
    { lat: 49.0336182, lng: 18.1198354 },
    { lat: 49.0336694, lng: 18.1194528 },
    { lat: 49.0336854, lng: 18.1189112 },
    { lat: 49.0337643, lng: 18.1183417 },
    { lat: 49.0338674, lng: 18.1180363 },
    { lat: 49.0339622, lng: 18.1179006 },
    { lat: 49.0341101, lng: 18.1177265 },
    { lat: 49.0342179, lng: 18.1175991 },
    { lat: 49.0348057, lng: 18.1174234 },
    { lat: 49.0352068, lng: 18.1169053 },
    { lat: 49.0354745, lng: 18.1164034 },
    { lat: 49.0361792, lng: 18.1157293 },
    { lat: 49.0368668, lng: 18.1154063 },
    { lat: 49.0371085, lng: 18.1152923 },
    { lat: 49.0373989, lng: 18.1148887 },
    { lat: 49.0380822, lng: 18.1150749 },
  ],
  Borčice: [
    { lat: 49.011681, lng: 18.105531 },
    { lat: 49.011484, lng: 18.105751 },
    { lat: 49.011244, lng: 18.106044 },
    { lat: 49.011029, lng: 18.106372 },
    { lat: 49.010923, lng: 18.106846 },
    { lat: 49.010242, lng: 18.107077 },
    { lat: 49.009779, lng: 18.106713 },
    { lat: 49.009227, lng: 18.106517 },
    { lat: 49.009188, lng: 18.106503 },
    { lat: 49.008786, lng: 18.106776 },
    { lat: 49.008643, lng: 18.106873 },
    { lat: 49.007851, lng: 18.107442 },
    { lat: 49.007151, lng: 18.108493 },
    { lat: 49.007142, lng: 18.108513 },
    { lat: 49.00689, lng: 18.109024 },
    { lat: 49.006792, lng: 18.109223 },
    { lat: 49.006656, lng: 18.109497 },
    { lat: 49.006001, lng: 18.109332 },
    { lat: 49.005826, lng: 18.109412 },
    { lat: 49.005719, lng: 18.109624 },
    { lat: 49.005643, lng: 18.109859 },
    { lat: 49.00503, lng: 18.109562 },
    { lat: 49.004716, lng: 18.110411 },
    { lat: 49.004277, lng: 18.111658 },
    { lat: 49.003956, lng: 18.112606 },
    { lat: 49.003839, lng: 18.112945 },
    { lat: 49.003728, lng: 18.113268 },
    { lat: 49.003594, lng: 18.113659 },
    { lat: 49.0032, lng: 18.114086 },
    { lat: 49.002877, lng: 18.114279 },
    { lat: 49.0026, lng: 18.114302 },
    { lat: 49.001717, lng: 18.114072 },
    { lat: 49.00106, lng: 18.114354 },
    { lat: 48.999996, lng: 18.115198 },
    { lat: 48.999645, lng: 18.115759 },
    { lat: 48.999154, lng: 18.116631 },
    { lat: 48.997783, lng: 18.117042 },
    { lat: 48.99765, lng: 18.117082 },
    { lat: 48.997427, lng: 18.117148 },
    { lat: 48.99708, lng: 18.117308 },
    { lat: 48.996522, lng: 18.117565 },
    { lat: 48.995502, lng: 18.117874 },
    { lat: 48.994272, lng: 18.117744 },
    { lat: 48.993607, lng: 18.117272 },
    { lat: 48.991989, lng: 18.117502 },
    { lat: 48.990272, lng: 18.117909 },
    { lat: 48.989824, lng: 18.118111 },
    { lat: 48.987654, lng: 18.118672 },
    { lat: 48.985716, lng: 18.12015 },
    { lat: 48.985663, lng: 18.120191 },
    { lat: 48.985477, lng: 18.120331 },
    { lat: 48.985146, lng: 18.120467 },
    { lat: 48.98509, lng: 18.120553 },
    { lat: 48.985037, lng: 18.120572 },
    { lat: 48.984814, lng: 18.120649 },
    { lat: 48.984642, lng: 18.120754 },
    { lat: 48.98441, lng: 18.121029 },
    { lat: 48.98425, lng: 18.120702 },
    { lat: 48.984139, lng: 18.120453 },
    { lat: 48.982846, lng: 18.121476 },
    { lat: 48.982537, lng: 18.121732 },
    { lat: 48.982217, lng: 18.121967 },
    { lat: 48.982044, lng: 18.122065 },
    { lat: 48.981854, lng: 18.12216 },
    { lat: 48.981281, lng: 18.122418 },
    { lat: 48.980742, lng: 18.122689 },
    { lat: 48.980576, lng: 18.122782 },
    { lat: 48.980429, lng: 18.122881 },
    { lat: 48.980157, lng: 18.123068 },
    { lat: 48.979837, lng: 18.123314 },
    { lat: 48.979591, lng: 18.123502 },
    { lat: 48.979081, lng: 18.12391 },
    { lat: 48.979079, lng: 18.123945 },
    { lat: 48.979065, lng: 18.124337 },
    { lat: 48.978974, lng: 18.124468 },
    { lat: 48.978813, lng: 18.124915 },
    { lat: 48.978099, lng: 18.126048 },
    { lat: 48.978035, lng: 18.126163 },
    { lat: 48.977927, lng: 18.12628 },
    { lat: 48.977871, lng: 18.126354 },
    { lat: 48.977832, lng: 18.126391 },
    { lat: 48.977743, lng: 18.126538 },
    { lat: 48.977653, lng: 18.126686 },
    { lat: 48.977502, lng: 18.126934 },
    { lat: 48.977296, lng: 18.127272 },
    { lat: 48.977269, lng: 18.127317 },
    { lat: 48.976848, lng: 18.128009 },
    { lat: 48.976233, lng: 18.12902 },
    { lat: 48.975914, lng: 18.129545 },
    { lat: 48.975839, lng: 18.129673 },
    { lat: 48.975545, lng: 18.130177 },
    { lat: 48.975137, lng: 18.130876 },
    { lat: 48.974258, lng: 18.132454 },
    { lat: 48.974192, lng: 18.132599 },
    { lat: 48.97419, lng: 18.132602 },
    { lat: 48.974114, lng: 18.132734 },
    { lat: 48.973689, lng: 18.133573 },
    { lat: 48.973416, lng: 18.134129 },
    { lat: 48.973148, lng: 18.134718 },
    { lat: 48.973042, lng: 18.134619 },
    { lat: 48.973008, lng: 18.134588 },
    { lat: 48.972935, lng: 18.13452 },
    { lat: 48.972644, lng: 18.13483 },
    { lat: 48.972611, lng: 18.134864 },
    { lat: 48.972248, lng: 18.135276 },
    { lat: 48.971955, lng: 18.135658 },
    { lat: 48.971824, lng: 18.135886 },
    { lat: 48.971646, lng: 18.136287 },
    { lat: 48.971409, lng: 18.13742 },
    { lat: 48.97139, lng: 18.137511 },
    { lat: 48.971357, lng: 18.13767 },
    { lat: 48.971352, lng: 18.137692 },
    { lat: 48.971329, lng: 18.137803 },
    { lat: 48.971301, lng: 18.137934 },
    { lat: 48.971296, lng: 18.137957 },
    { lat: 48.971212, lng: 18.138381 },
    { lat: 48.971189, lng: 18.138472 },
    { lat: 48.971165, lng: 18.138584 },
    { lat: 48.971105, lng: 18.138874 },
    { lat: 48.970948, lng: 18.139628 },
    { lat: 48.97092, lng: 18.139757 },
    { lat: 48.970812, lng: 18.140273 },
    { lat: 48.970465, lng: 18.141938 },
    { lat: 48.970581, lng: 18.144325 },
    { lat: 48.970636, lng: 18.145395 },
    { lat: 48.970653, lng: 18.145729 },
    { lat: 48.970753, lng: 18.147768 },
    { lat: 48.9719276, lng: 18.1493094 },
    { lat: 48.9733832, lng: 18.1513838 },
    { lat: 48.973615, lng: 18.1517055 },
    { lat: 48.9740995, lng: 18.1523789 },
    { lat: 48.9741375, lng: 18.1524306 },
    { lat: 48.974162, lng: 18.1524647 },
    { lat: 48.9741657, lng: 18.1524706 },
    { lat: 48.9742332, lng: 18.152564 },
    { lat: 48.9743046, lng: 18.1524523 },
    { lat: 48.9743829, lng: 18.1523259 },
    { lat: 48.9744904, lng: 18.1521521 },
    { lat: 48.9745419, lng: 18.1520694 },
    { lat: 48.9745947, lng: 18.1519848 },
    { lat: 48.9751288, lng: 18.1511106 },
    { lat: 48.9751924, lng: 18.1510199 },
    { lat: 48.9752182, lng: 18.1509798 },
    { lat: 48.9752813, lng: 18.1508795 },
    { lat: 48.9754694, lng: 18.1505793 },
    { lat: 48.9761751, lng: 18.149452 },
    { lat: 48.9764716, lng: 18.1489771 },
    { lat: 48.9765284, lng: 18.148887 },
    { lat: 48.9765934, lng: 18.1487598 },
    { lat: 48.976607, lng: 18.1487615 },
    { lat: 48.976672, lng: 18.1486376 },
    { lat: 48.9767259, lng: 18.1485356 },
    { lat: 48.9774358, lng: 18.1472866 },
    { lat: 48.9775554, lng: 18.1471456 },
    { lat: 48.9778385, lng: 18.1466948 },
    { lat: 48.9783135, lng: 18.1459329 },
    { lat: 48.9794081, lng: 18.144134 },
    { lat: 48.979429, lng: 18.1441222 },
    { lat: 48.9794893, lng: 18.1440908 },
    { lat: 48.9795396, lng: 18.144064 },
    { lat: 48.9799756, lng: 18.1433464 },
    { lat: 48.9801203, lng: 18.1431162 },
    { lat: 48.980202, lng: 18.1429882 },
    { lat: 48.9803707, lng: 18.142724 },
    { lat: 48.9804607, lng: 18.1425816 },
    { lat: 48.9804739, lng: 18.1425576 },
    { lat: 48.9804935, lng: 18.1425244 },
    { lat: 48.9806477, lng: 18.1422459 },
    { lat: 48.9806876, lng: 18.1421745 },
    { lat: 48.9808406, lng: 18.1418922 },
    { lat: 48.981062, lng: 18.1414676 },
    { lat: 48.9811635, lng: 18.141231 },
    { lat: 48.9816684, lng: 18.1403022 },
    { lat: 48.9817783, lng: 18.1401129 },
    { lat: 48.9824787, lng: 18.1388973 },
    { lat: 48.9830032, lng: 18.1379137 },
    { lat: 48.9830484, lng: 18.1378377 },
    { lat: 48.9831852, lng: 18.1376057 },
    { lat: 48.9837482, lng: 18.1366277 },
    { lat: 48.9839347, lng: 18.1362454 },
    { lat: 48.984079, lng: 18.1358335 },
    { lat: 48.9842561, lng: 18.1354599 },
    { lat: 48.9845768, lng: 18.1349163 },
    { lat: 48.9847586, lng: 18.134605 },
    { lat: 48.9850342, lng: 18.1341605 },
    { lat: 48.9852603, lng: 18.1338339 },
    { lat: 48.9857019, lng: 18.1330636 },
    { lat: 48.9864223, lng: 18.1318012 },
    { lat: 48.9869662, lng: 18.130844 },
    { lat: 48.9871777, lng: 18.130343 },
    { lat: 48.987274, lng: 18.1301767 },
    { lat: 48.9874981, lng: 18.1299341 },
    { lat: 48.9877149, lng: 18.1296425 },
    { lat: 48.9879852, lng: 18.1291846 },
    { lat: 48.9881002, lng: 18.1288879 },
    { lat: 48.9882563, lng: 18.1287219 },
    { lat: 48.9885801, lng: 18.1286618 },
    { lat: 48.9888061, lng: 18.1284932 },
    { lat: 48.9889164, lng: 18.1282324 },
    { lat: 48.9890704, lng: 18.1282215 },
    { lat: 48.9891759, lng: 18.128095 },
    { lat: 48.9891906, lng: 18.1280761 },
    { lat: 48.9892402, lng: 18.1280174 },
    { lat: 48.9896134, lng: 18.1277976 },
    { lat: 48.9898479, lng: 18.127481 },
    { lat: 48.9899309, lng: 18.1271994 },
    { lat: 48.9901551, lng: 18.127016 },
    { lat: 48.9907386, lng: 18.1269409 },
    { lat: 48.9908739, lng: 18.1267252 },
    { lat: 48.9911419, lng: 18.1262273 },
    { lat: 48.9915849, lng: 18.1259842 },
    { lat: 48.9917443, lng: 18.1254246 },
    { lat: 48.9923552, lng: 18.1251364 },
    { lat: 48.9925899, lng: 18.1252362 },
    { lat: 48.9930101, lng: 18.124443 },
    { lat: 48.9935264, lng: 18.1240819 },
    { lat: 48.993782, lng: 18.1241498 },
    { lat: 48.9940036, lng: 18.1244907 },
    { lat: 48.9939998, lng: 18.1249307 },
    { lat: 48.9943577, lng: 18.1252081 },
    { lat: 48.9948342, lng: 18.1255056 },
    { lat: 48.9952449, lng: 18.1254439 },
    { lat: 48.9954978, lng: 18.1255858 },
    { lat: 48.9957137, lng: 18.1253762 },
    { lat: 48.99589, lng: 18.1253624 },
    { lat: 48.9960293, lng: 18.125563 },
    { lat: 48.9962693, lng: 18.1253264 },
    { lat: 48.9967298, lng: 18.1252462 },
    { lat: 48.9969889, lng: 18.1250071 },
    { lat: 48.9972123, lng: 18.1249855 },
    { lat: 48.9975365, lng: 18.1249537 },
    { lat: 48.9977863, lng: 18.1246698 },
    { lat: 48.9979898, lng: 18.1244378 },
    { lat: 48.998235, lng: 18.1246225 },
    { lat: 48.9983923, lng: 18.1245501 },
    { lat: 48.9986901, lng: 18.1244125 },
    { lat: 48.9988467, lng: 18.1242547 },
    { lat: 48.9993991, lng: 18.1238542 },
    { lat: 48.9996409, lng: 18.1236432 },
    { lat: 49.0012232, lng: 18.1235518 },
    { lat: 49.0031446, lng: 18.1222937 },
    { lat: 49.0039123, lng: 18.1221044 },
    { lat: 49.0052074, lng: 18.1212049 },
    { lat: 49.0063954, lng: 18.1204975 },
    { lat: 49.0074583, lng: 18.1195585 },
    { lat: 49.0080291, lng: 18.1191661 },
    { lat: 49.0085301, lng: 18.1182296 },
    { lat: 49.0089631, lng: 18.1176979 },
    { lat: 49.0099231, lng: 18.1167384 },
    { lat: 49.010182, lng: 18.1164359 },
    { lat: 49.0110195, lng: 18.1151106 },
    { lat: 49.0108304, lng: 18.1146136 },
    { lat: 49.0106522, lng: 18.1141468 },
    { lat: 49.0107295, lng: 18.1138855 },
    { lat: 49.0107881, lng: 18.1136875 },
    { lat: 49.0108797, lng: 18.113379 },
    { lat: 49.0110954, lng: 18.1126815 },
    { lat: 49.0113327, lng: 18.1123938 },
    { lat: 49.011638, lng: 18.1120556 },
    { lat: 49.0119725, lng: 18.1118713 },
    { lat: 49.0120501, lng: 18.1117279 },
    { lat: 49.012115, lng: 18.1116046 },
    { lat: 49.0121658, lng: 18.1115054 },
    { lat: 49.0124485, lng: 18.1103074 },
    { lat: 49.0126675, lng: 18.109141 },
    { lat: 49.0126319, lng: 18.1090326 },
    { lat: 49.0126146, lng: 18.1090658 },
    { lat: 49.012231, lng: 18.1082681 },
    { lat: 49.0117375, lng: 18.1068829 },
    { lat: 49.0116818, lng: 18.1064439 },
    { lat: 49.011681, lng: 18.105531 },
  ],
  Kameničany: [
    { lat: 48.9838434, lng: 18.1715396 },
    { lat: 48.9841068, lng: 18.1719407 },
    { lat: 48.984526, lng: 18.1725768 },
    { lat: 48.9852006, lng: 18.1735966 },
    { lat: 48.9858058, lng: 18.175155 },
    { lat: 48.9859305, lng: 18.175421 },
    { lat: 48.9860205, lng: 18.1756117 },
    { lat: 48.9861748, lng: 18.1759415 },
    { lat: 48.9861954, lng: 18.1759855 },
    { lat: 48.9864972, lng: 18.176626 },
    { lat: 48.986877, lng: 18.1774806 },
    { lat: 48.9868951, lng: 18.1775214 },
    { lat: 48.9871051, lng: 18.1779938 },
    { lat: 48.9878765, lng: 18.1795405 },
    { lat: 48.9880397, lng: 18.179867 },
    { lat: 48.9884307, lng: 18.1807445 },
    { lat: 48.9891622, lng: 18.182381 },
    { lat: 48.9894268, lng: 18.1828849 },
    { lat: 48.9896382, lng: 18.1826556 },
    { lat: 48.9896965, lng: 18.1825868 },
    { lat: 48.9906223, lng: 18.181534 },
    { lat: 48.9906564, lng: 18.1814813 },
    { lat: 48.9916887, lng: 18.1799225 },
    { lat: 48.9926914, lng: 18.1785977 },
    { lat: 48.9927195, lng: 18.1785563 },
    { lat: 48.9927372, lng: 18.1785317 },
    { lat: 48.9927508, lng: 18.1785126 },
    { lat: 48.9927632, lng: 18.1784948 },
    { lat: 48.9928321, lng: 18.1783966 },
    { lat: 48.99468, lng: 18.1756572 },
    { lat: 48.9946869, lng: 18.175643 },
    { lat: 48.9947143, lng: 18.1756045 },
    { lat: 48.9947244, lng: 18.1755862 },
    { lat: 48.9949898, lng: 18.1752187 },
    { lat: 48.9954418, lng: 18.1745996 },
    { lat: 48.9961911, lng: 18.1735566 },
    { lat: 48.9977743, lng: 18.1713407 },
    { lat: 48.9978183, lng: 18.1713194 },
    { lat: 48.9978896, lng: 18.1712848 },
    { lat: 48.9979457, lng: 18.1712576 },
    { lat: 48.9990479, lng: 18.1697809 },
    { lat: 48.9990834, lng: 18.1697324 },
    { lat: 48.9991338, lng: 18.1696682 },
    { lat: 48.9992023, lng: 18.1695833 },
    { lat: 48.9997968, lng: 18.1687745 },
    { lat: 49.0002142, lng: 18.1682605 },
    { lat: 49.0006785, lng: 18.1677202 },
    { lat: 49.0017968, lng: 18.1664849 },
    { lat: 49.0026271, lng: 18.1656588 },
    { lat: 49.0043733, lng: 18.1638616 },
    { lat: 49.0048684, lng: 18.1634045 },
    { lat: 49.0053736, lng: 18.1629704 },
    { lat: 49.0061503, lng: 18.1623486 },
    { lat: 49.0064537, lng: 18.1620696 },
    { lat: 49.0067286, lng: 18.1618461 },
    { lat: 49.0073582, lng: 18.161476 },
    { lat: 49.0080884, lng: 18.1609257 },
    { lat: 49.0082875, lng: 18.16074 },
    { lat: 49.0092978, lng: 18.1597653 },
    { lat: 49.0098581, lng: 18.1593235 },
    { lat: 49.010342, lng: 18.1589673 },
    { lat: 49.0108777, lng: 18.1584525 },
    { lat: 49.0111256, lng: 18.1581542 },
    { lat: 49.0112059, lng: 18.1580569 },
    { lat: 49.0114579, lng: 18.1577531 },
    { lat: 49.0117187, lng: 18.1574395 },
    { lat: 49.0121859, lng: 18.1569503 },
    { lat: 49.0128315, lng: 18.1561498 },
    { lat: 49.0130516, lng: 18.1556615 },
    { lat: 49.0138697, lng: 18.1546421 },
    { lat: 49.0139615, lng: 18.1545321 },
    { lat: 49.0139774, lng: 18.1545124 },
    { lat: 49.0141143, lng: 18.1543428 },
    { lat: 49.014859, lng: 18.1536175 },
    { lat: 49.0149028, lng: 18.1535746 },
    { lat: 49.0150963, lng: 18.153386 },
    { lat: 49.0158384, lng: 18.1524964 },
    { lat: 49.0158635, lng: 18.1524657 },
    { lat: 49.0162088, lng: 18.1520523 },
    { lat: 49.0163524, lng: 18.1518804 },
    { lat: 49.0173331, lng: 18.1501997 },
    { lat: 49.0174861, lng: 18.1503479 },
    { lat: 49.0179398, lng: 18.1496228 },
    { lat: 49.018479, lng: 18.1488363 },
    { lat: 49.0187096, lng: 18.1484521 },
    { lat: 49.0191757, lng: 18.1476121 },
    { lat: 49.0198349, lng: 18.1461246 },
    { lat: 49.020075, lng: 18.1456925 },
    { lat: 49.020198, lng: 18.144655 },
    { lat: 49.0202841, lng: 18.1446594 },
    { lat: 49.0202961, lng: 18.1444162 },
    { lat: 49.0203634, lng: 18.1440991 },
    { lat: 49.020481, lng: 18.1432608 },
    { lat: 49.0205637, lng: 18.142993 },
    { lat: 49.0206874, lng: 18.142575 },
    { lat: 49.020669, lng: 18.142215 },
    { lat: 49.0207159, lng: 18.1414663 },
    { lat: 49.0207272, lng: 18.1412886 },
    { lat: 49.0208447, lng: 18.1412726 },
    { lat: 49.0211459, lng: 18.1412303 },
    { lat: 49.0214733, lng: 18.1410565 },
    { lat: 49.0214743, lng: 18.1409593 },
    { lat: 49.0214547, lng: 18.1398358 },
    { lat: 49.0216463, lng: 18.1399102 },
    { lat: 49.0216592, lng: 18.1398394 },
    { lat: 49.0221332, lng: 18.1381205 },
    { lat: 49.0223107, lng: 18.1374791 },
    { lat: 49.022326, lng: 18.1374124 },
    { lat: 49.0222785, lng: 18.1373313 },
    { lat: 49.0222254, lng: 18.1372518 },
    { lat: 49.022081, lng: 18.1370201 },
    { lat: 49.0220043, lng: 18.1369034 },
    { lat: 49.0218792, lng: 18.1367105 },
    { lat: 49.0217891, lng: 18.1365751 },
    { lat: 49.0216917, lng: 18.1364327 },
    { lat: 49.0216017, lng: 18.1363024 },
    { lat: 49.0214399, lng: 18.1360399 },
    { lat: 49.0215061, lng: 18.135408 },
    { lat: 49.0215487, lng: 18.1351474 },
    { lat: 49.0215987, lng: 18.1349969 },
    { lat: 49.0218426, lng: 18.1346681 },
    { lat: 49.0219281, lng: 18.1345679 },
    { lat: 49.0221184, lng: 18.1342066 },
    { lat: 49.0222417, lng: 18.1342725 },
    { lat: 49.0227669, lng: 18.134553 },
    { lat: 49.0227695, lng: 18.1344624 },
    { lat: 49.0229678, lng: 18.1343209 },
    { lat: 49.0231468, lng: 18.1341366 },
    { lat: 49.0235982, lng: 18.133747 },
    { lat: 49.0241726, lng: 18.1335114 },
    { lat: 49.0250183, lng: 18.1328368 },
    { lat: 49.0256476, lng: 18.1323422 },
    { lat: 49.0259977, lng: 18.1320908 },
    { lat: 49.0264509, lng: 18.1317135 },
    { lat: 49.0276301, lng: 18.1309512 },
    { lat: 49.0281741, lng: 18.1305635 },
    { lat: 49.0291552, lng: 18.1298689 },
    { lat: 49.0295619, lng: 18.1295819 },
    { lat: 49.0298216, lng: 18.1293905 },
    { lat: 49.0305164, lng: 18.1288963 },
    { lat: 49.0306114, lng: 18.1295831 },
    { lat: 49.0308775, lng: 18.1299528 },
    { lat: 49.0310788, lng: 18.1294508 },
    { lat: 49.0312138, lng: 18.1290746 },
    { lat: 49.0312627, lng: 18.1284143 },
    { lat: 49.0310405, lng: 18.127286 },
    { lat: 49.031553, lng: 18.1272093 },
    { lat: 49.0327448, lng: 18.1293214 },
    { lat: 49.0332391, lng: 18.1302972 },
    { lat: 49.0334037, lng: 18.1299059 },
    { lat: 49.0337065, lng: 18.1290724 },
    { lat: 49.0339775, lng: 18.1277473 },
    { lat: 49.0341016, lng: 18.1273976 },
    { lat: 49.0343028, lng: 18.1265634 },
    { lat: 49.0351358, lng: 18.1254123 },
    { lat: 49.0356233, lng: 18.1241469 },
    { lat: 49.0361499, lng: 18.1238071 },
    { lat: 49.0361923, lng: 18.1237799 },
    { lat: 49.0364276, lng: 18.1237304 },
    { lat: 49.0368591, lng: 18.123308 },
    { lat: 49.0380571, lng: 18.1228518 },
    { lat: 49.0385781, lng: 18.1225548 },
    { lat: 49.0390596, lng: 18.1222933 },
    { lat: 49.0391495, lng: 18.1222291 },
    { lat: 49.0392504, lng: 18.1221577 },
    { lat: 49.0395353, lng: 18.1219534 },
    { lat: 49.040187, lng: 18.1213975 },
    { lat: 49.0398389, lng: 18.1201989 },
    { lat: 49.039429, lng: 18.1191222 },
    { lat: 49.0389724, lng: 18.1177813 },
    { lat: 49.0384943, lng: 18.116658 },
    { lat: 49.038042, lng: 18.115842 },
    { lat: 49.0379857, lng: 18.1154176 },
    { lat: 49.0380822, lng: 18.1150749 },
    { lat: 49.0373989, lng: 18.1148887 },
    { lat: 49.0371085, lng: 18.1152923 },
    { lat: 49.0368668, lng: 18.1154063 },
    { lat: 49.0361792, lng: 18.1157293 },
    { lat: 49.0354745, lng: 18.1164034 },
    { lat: 49.0352068, lng: 18.1169053 },
    { lat: 49.0348057, lng: 18.1174234 },
    { lat: 49.0342179, lng: 18.1175991 },
    { lat: 49.0341101, lng: 18.1177265 },
    { lat: 49.0339622, lng: 18.1179006 },
    { lat: 49.0338674, lng: 18.1180363 },
    { lat: 49.0337643, lng: 18.1183417 },
    { lat: 49.0336854, lng: 18.1189112 },
    { lat: 49.0336694, lng: 18.1194528 },
    { lat: 49.0336182, lng: 18.1198354 },
    { lat: 49.0334876, lng: 18.120251 },
    { lat: 49.0331663, lng: 18.1207249 },
    { lat: 49.0324487, lng: 18.1215855 },
    { lat: 49.031884, lng: 18.1222393 },
    { lat: 49.0315725, lng: 18.1226107 },
    { lat: 49.0313606, lng: 18.1227124 },
    { lat: 49.0312284, lng: 18.1227272 },
    { lat: 49.0307834, lng: 18.1227206 },
    { lat: 49.0303348, lng: 18.1228259 },
    { lat: 49.029939, lng: 18.1232604 },
    { lat: 49.0298859, lng: 18.1236091 },
    { lat: 49.0296992, lng: 18.1246582 },
    { lat: 49.0294723, lng: 18.1247547 },
    { lat: 49.0292656, lng: 18.1246928 },
    { lat: 49.0287062, lng: 18.1247349 },
    { lat: 49.028366, lng: 18.125173 },
    { lat: 49.028122, lng: 18.1252679 },
    { lat: 49.0277066, lng: 18.1252856 },
    { lat: 49.0274548, lng: 18.1254783 },
    { lat: 49.0270851, lng: 18.125583 },
    { lat: 49.026708, lng: 18.1254404 },
    { lat: 49.0262683, lng: 18.1252924 },
    { lat: 49.0260351, lng: 18.1253771 },
    { lat: 49.0254047, lng: 18.1255811 },
    { lat: 49.0249496, lng: 18.125514 },
    { lat: 49.0245662, lng: 18.125754 },
    { lat: 49.0241612, lng: 18.1259922 },
    { lat: 49.0240217, lng: 18.1261561 },
    { lat: 49.0239298, lng: 18.1263611 },
    { lat: 49.0234972, lng: 18.1271334 },
    { lat: 49.022782, lng: 18.1280189 },
    { lat: 49.0220268, lng: 18.1287933 },
    { lat: 49.0216402, lng: 18.1291894 },
    { lat: 49.0216318, lng: 18.1292034 },
    { lat: 49.0215833, lng: 18.1292617 },
    { lat: 49.0213818, lng: 18.1295629 },
    { lat: 49.0212573, lng: 18.1297496 },
    { lat: 49.0209155, lng: 18.1302997 },
    { lat: 49.0212322, lng: 18.1307809 },
    { lat: 49.0214983, lng: 18.1309158 },
    { lat: 49.0214713, lng: 18.1309363 },
    { lat: 49.0214076, lng: 18.1309844 },
    { lat: 49.0209922, lng: 18.1313322 },
    { lat: 49.0206782, lng: 18.1314068 },
    { lat: 49.0205427, lng: 18.1314919 },
    { lat: 49.0202948, lng: 18.1316475 },
    { lat: 49.0196605, lng: 18.1319975 },
    { lat: 49.0194145, lng: 18.1321152 },
    { lat: 49.0193862, lng: 18.1321327 },
    { lat: 49.0196134, lng: 18.1325862 },
    { lat: 49.0196557, lng: 18.1326554 },
    { lat: 49.0196952, lng: 18.1330276 },
    { lat: 49.0197338, lng: 18.1332083 },
    { lat: 49.0196883, lng: 18.1334733 },
    { lat: 49.0196931, lng: 18.1337322 },
    { lat: 49.0196236, lng: 18.1337041 },
    { lat: 49.0195382, lng: 18.1340226 },
    { lat: 49.019456, lng: 18.1343355 },
    { lat: 49.0190701, lng: 18.1347286 },
    { lat: 49.0187125, lng: 18.1347105 },
    { lat: 49.0185009, lng: 18.1348966 },
    { lat: 49.0181797, lng: 18.1350214 },
    { lat: 49.0180741, lng: 18.1349705 },
    { lat: 49.0179113, lng: 18.1349638 },
    { lat: 49.017613, lng: 18.1359933 },
    { lat: 49.0173283, lng: 18.1357326 },
    { lat: 49.0172753, lng: 18.1359888 },
    { lat: 49.0170848, lng: 18.1366955 },
    { lat: 49.0167157, lng: 18.1362461 },
    { lat: 49.016529, lng: 18.1362142 },
    { lat: 49.0165746, lng: 18.1358116 },
    { lat: 49.0161511, lng: 18.1361597 },
    { lat: 49.0153382, lng: 18.1362519 },
    { lat: 49.0143038, lng: 18.1369001 },
    { lat: 49.0138854, lng: 18.1371619 },
    { lat: 49.0123921, lng: 18.1383083 },
    { lat: 49.0119271, lng: 18.1385957 },
    { lat: 49.0118615, lng: 18.1386358 },
    { lat: 49.0114846, lng: 18.138869 },
    { lat: 49.0114638, lng: 18.1388815 },
    { lat: 49.0112122, lng: 18.1390373 },
    { lat: 49.0106838, lng: 18.1393834 },
    { lat: 49.0102811, lng: 18.140128 },
    { lat: 49.0098475, lng: 18.1415324 },
    { lat: 49.0096922, lng: 18.1419916 },
    { lat: 49.009485, lng: 18.142351 },
    { lat: 49.0094143, lng: 18.1424744 },
    { lat: 49.0090257, lng: 18.1431484 },
    { lat: 49.0088603, lng: 18.143454 },
    { lat: 49.0085593, lng: 18.1441649 },
    { lat: 49.00841, lng: 18.1446826 },
    { lat: 49.008148, lng: 18.1458925 },
    { lat: 49.0081354, lng: 18.1459499 },
    { lat: 49.0081382, lng: 18.1459977 },
    { lat: 49.0080279, lng: 18.146471 },
    { lat: 49.0078561, lng: 18.1469163 },
    { lat: 49.0076548, lng: 18.1472853 },
    { lat: 49.0073949, lng: 18.1476861 },
    { lat: 49.0071388, lng: 18.1480419 },
    { lat: 49.0063403, lng: 18.1480875 },
    { lat: 49.0057169, lng: 18.1490382 },
    { lat: 49.0054767, lng: 18.1492934 },
    { lat: 49.0052427, lng: 18.1496196 },
    { lat: 49.0050123, lng: 18.1497948 },
    { lat: 49.0048878, lng: 18.1499648 },
    { lat: 49.0047163, lng: 18.1503525 },
    { lat: 49.0046774, lng: 18.1503846 },
    { lat: 49.0040977, lng: 18.1510167 },
    { lat: 49.0039232, lng: 18.1517113 },
    { lat: 49.0039049, lng: 18.1517838 },
    { lat: 49.0038958, lng: 18.1518228 },
    { lat: 49.0038793, lng: 18.1518893 },
    { lat: 49.003551, lng: 18.1523589 },
    { lat: 49.0028744, lng: 18.1536389 },
    { lat: 49.0020362, lng: 18.1550924 },
    { lat: 49.0015657, lng: 18.1557422 },
    { lat: 49.0009568, lng: 18.156473 },
    { lat: 49.000266, lng: 18.1572358 },
    { lat: 48.9999535, lng: 18.1575699 },
    { lat: 48.9997048, lng: 18.1578337 },
    { lat: 48.9994487, lng: 18.15817 },
    { lat: 48.999114, lng: 18.1586691 },
    { lat: 48.9988881, lng: 18.158996 },
    { lat: 48.9987299, lng: 18.1592258 },
    { lat: 48.9987142, lng: 18.1592494 },
    { lat: 48.9985682, lng: 18.1594359 },
    { lat: 48.9984871, lng: 18.1595401 },
    { lat: 48.9984053, lng: 18.1596459 },
    { lat: 48.9981733, lng: 18.1599301 },
    { lat: 48.9979509, lng: 18.1601616 },
    { lat: 48.9976177, lng: 18.1604521 },
    { lat: 48.9971346, lng: 18.1608023 },
    { lat: 48.996574, lng: 18.1611754 },
    { lat: 48.9962086, lng: 18.1614949 },
    { lat: 48.9961377, lng: 18.1615568 },
    { lat: 48.9957443, lng: 18.1619167 },
    { lat: 48.9954023, lng: 18.1621702 },
    { lat: 48.9953718, lng: 18.1621901 },
    { lat: 48.9953307, lng: 18.1622034 },
    { lat: 48.9953027, lng: 18.1622255 },
    { lat: 48.9952153, lng: 18.1622942 },
    { lat: 48.9945607, lng: 18.1627243 },
    { lat: 48.994197, lng: 18.1629897 },
    { lat: 48.9935989, lng: 18.1634411 },
    { lat: 48.9930497, lng: 18.1637828 },
    { lat: 48.9925945, lng: 18.1641659 },
    { lat: 48.9921361, lng: 18.1646102 },
    { lat: 48.991764, lng: 18.1650484 },
    { lat: 48.9913735, lng: 18.1655397 },
    { lat: 48.9911267, lng: 18.1658204 },
    { lat: 48.9906504, lng: 18.1651287 },
    { lat: 48.9906266, lng: 18.165166 },
    { lat: 48.9905809, lng: 18.1652342 },
    { lat: 48.9905611, lng: 18.1652652 },
    { lat: 48.9902549, lng: 18.1657302 },
    { lat: 48.9902271, lng: 18.1657731 },
    { lat: 48.9901763, lng: 18.1658506 },
    { lat: 48.9901658, lng: 18.1658657 },
    { lat: 48.987309, lng: 18.1682853 },
    { lat: 48.9872271, lng: 18.168354 },
    { lat: 48.9871982, lng: 18.1683809 },
    { lat: 48.9866232, lng: 18.1689167 },
    { lat: 48.9866007, lng: 18.1689376 },
    { lat: 48.985386, lng: 18.1700681 },
    { lat: 48.9846407, lng: 18.1707832 },
    { lat: 48.9845293, lng: 18.1708903 },
    { lat: 48.9843081, lng: 18.1711025 },
    { lat: 48.9842888, lng: 18.1711208 },
    { lat: 48.9838434, lng: 18.1715396 },
  ],
  Sedmerovec: [
    { lat: 49.0009066, lng: 18.2125798 },
    { lat: 49.0009318, lng: 18.2125693 },
    { lat: 49.000984, lng: 18.2125362 },
    { lat: 49.0009993, lng: 18.2125266 },
    { lat: 49.001055, lng: 18.2124909 },
    { lat: 49.0010582, lng: 18.2124887 },
    { lat: 49.0010821, lng: 18.2124736 },
    { lat: 49.0010952, lng: 18.2124655 },
    { lat: 49.0011093, lng: 18.2124568 },
    { lat: 49.0018028, lng: 18.2120139 },
    { lat: 49.0026108, lng: 18.2087139 },
    { lat: 49.0025114, lng: 18.2079051 },
    { lat: 49.0023642, lng: 18.2065125 },
    { lat: 49.0024934, lng: 18.2054752 },
    { lat: 49.0025007, lng: 18.2054462 },
    { lat: 49.0025109, lng: 18.2054061 },
    { lat: 49.0025211, lng: 18.2053695 },
    { lat: 49.0104318, lng: 18.1912606 },
    { lat: 49.0104851, lng: 18.1912079 },
    { lat: 49.0104727, lng: 18.1911865 },
    { lat: 49.0104822, lng: 18.1911741 },
    { lat: 49.0104864, lng: 18.1911693 },
    { lat: 49.0104917, lng: 18.1911621 },
    { lat: 49.0105073, lng: 18.191142 },
    { lat: 49.0105116, lng: 18.1911375 },
    { lat: 49.0105475, lng: 18.1910914 },
    { lat: 49.0107594, lng: 18.1907539 },
    { lat: 49.0107906, lng: 18.1907297 },
    { lat: 49.0108546, lng: 18.190682 },
    { lat: 49.0108815, lng: 18.1906612 },
    { lat: 49.0111517, lng: 18.1910855 },
    { lat: 49.0121783, lng: 18.1901722 },
    { lat: 49.0122871, lng: 18.190202 },
    { lat: 49.0124488, lng: 18.1902457 },
    { lat: 49.0132179, lng: 18.1900432 },
    { lat: 49.0139411, lng: 18.1896862 },
    { lat: 49.0144481, lng: 18.1894361 },
    { lat: 49.0144296, lng: 18.1891744 },
    { lat: 49.0143481, lng: 18.1880068 },
    { lat: 49.0156066, lng: 18.1874228 },
    { lat: 49.0160741, lng: 18.1871302 },
    { lat: 49.0166131, lng: 18.1882893 },
    { lat: 49.0171334, lng: 18.1880144 },
    { lat: 49.0171845, lng: 18.1881971 },
    { lat: 49.0181112, lng: 18.1879659 },
    { lat: 49.0177901, lng: 18.1868929 },
    { lat: 49.019448, lng: 18.1857163 },
    { lat: 49.0188061, lng: 18.183905 },
    { lat: 49.0186068, lng: 18.1833443 },
    { lat: 49.0195238, lng: 18.1819972 },
    { lat: 49.0196121, lng: 18.1818672 },
    { lat: 49.0200491, lng: 18.1813398 },
    { lat: 49.0204614, lng: 18.1809343 },
    { lat: 49.0208494, lng: 18.1808307 },
    { lat: 49.0216077, lng: 18.1805386 },
    { lat: 49.0220447, lng: 18.1805842 },
    { lat: 49.0234618, lng: 18.179381 },
    { lat: 49.0239521, lng: 18.1788601 },
    { lat: 49.0245855, lng: 18.1783156 },
    { lat: 49.0248102, lng: 18.1781229 },
    { lat: 49.0253677, lng: 18.177396 },
    { lat: 49.0256454, lng: 18.1765699 },
    { lat: 49.0262883, lng: 18.1748551 },
    { lat: 49.0268919, lng: 18.172023 },
    { lat: 49.0269053, lng: 18.1719608 },
    { lat: 49.0282157, lng: 18.1742726 },
    { lat: 49.0282637, lng: 18.1743572 },
    { lat: 49.0291727, lng: 18.1722829 },
    { lat: 49.0299003, lng: 18.1706272 },
    { lat: 49.0288704, lng: 18.1688661 },
    { lat: 49.028127, lng: 18.1675898 },
    { lat: 49.0280517, lng: 18.167461 },
    { lat: 49.0284633, lng: 18.1667896 },
    { lat: 49.0290964, lng: 18.1657875 },
    { lat: 49.0296248, lng: 18.1649492 },
    { lat: 49.0297672, lng: 18.164758 },
    { lat: 49.0299272, lng: 18.1644969 },
    { lat: 49.0307074, lng: 18.1639941 },
    { lat: 49.0309093, lng: 18.1640133 },
    { lat: 49.0311006, lng: 18.1641403 },
    { lat: 49.031658, lng: 18.1639246 },
    { lat: 49.031901, lng: 18.1639113 },
    { lat: 49.0322465, lng: 18.1635256 },
    { lat: 49.0322992, lng: 18.1634952 },
    { lat: 49.032435, lng: 18.1634638 },
    { lat: 49.0327969, lng: 18.162932 },
    { lat: 49.0329247, lng: 18.1623444 },
    { lat: 49.0336703, lng: 18.1622434 },
    { lat: 49.0337164, lng: 18.1622384 },
    { lat: 49.0341413, lng: 18.1621938 },
    { lat: 49.0343794, lng: 18.1620528 },
    { lat: 49.0345827, lng: 18.1618794 },
    { lat: 49.0350723, lng: 18.1615654 },
    { lat: 49.0351597, lng: 18.161379 },
    { lat: 49.0353887, lng: 18.1611432 },
    { lat: 49.0360399, lng: 18.1602784 },
    { lat: 49.0365202, lng: 18.1594078 },
    { lat: 49.0369768, lng: 18.159383 },
    { lat: 49.0373948, lng: 18.1595472 },
    { lat: 49.0378555, lng: 18.1596312 },
    { lat: 49.0376999, lng: 18.1575138 },
    { lat: 49.0372942, lng: 18.1570337 },
    { lat: 49.0376634, lng: 18.1558434 },
    { lat: 49.0375677, lng: 18.1546682 },
    { lat: 49.0376067, lng: 18.1542084 },
    { lat: 49.0376636, lng: 18.153648 },
    { lat: 49.0377412, lng: 18.1528796 },
    { lat: 49.0378112, lng: 18.1522146 },
    { lat: 49.0379479, lng: 18.1513742 },
    { lat: 49.0378596, lng: 18.1510752 },
    { lat: 49.037892, lng: 18.1507933 },
    { lat: 49.0382603, lng: 18.149703 },
    { lat: 49.0371558, lng: 18.1502371 },
    { lat: 49.0375637, lng: 18.1497972 },
    { lat: 49.0380197, lng: 18.1491129 },
    { lat: 49.0384518, lng: 18.1482906 },
    { lat: 49.038659, lng: 18.1479455 },
    { lat: 49.0386972, lng: 18.1478094 },
    { lat: 49.0386429, lng: 18.147589 },
    { lat: 49.0385541, lng: 18.1472833 },
    { lat: 49.0383905, lng: 18.146889 },
    { lat: 49.0382918, lng: 18.1466662 },
    { lat: 49.0382279, lng: 18.1466358 },
    { lat: 49.0374361, lng: 18.1465026 },
    { lat: 49.0368027, lng: 18.1459857 },
    { lat: 49.0362705, lng: 18.1454247 },
    { lat: 49.0359598, lng: 18.1458117 },
    { lat: 49.0352101, lng: 18.1460595 },
    { lat: 49.0349937, lng: 18.1464149 },
    { lat: 49.0343421, lng: 18.1474846 },
    { lat: 49.0342661, lng: 18.1475353 },
    { lat: 49.0341996, lng: 18.1476172 },
    { lat: 49.0338008, lng: 18.1481364 },
    { lat: 49.0337294, lng: 18.1482312 },
    { lat: 49.0337047, lng: 18.1482701 },
    { lat: 49.0334133, lng: 18.1486789 },
    { lat: 49.0330835, lng: 18.1487128 },
    { lat: 49.0327908, lng: 18.1487831 },
    { lat: 49.032258, lng: 18.1490112 },
    { lat: 49.0321299, lng: 18.1491603 },
    { lat: 49.0320105, lng: 18.1493419 },
    { lat: 49.0318679, lng: 18.1496447 },
    { lat: 49.0314568, lng: 18.1505783 },
    { lat: 49.031219, lng: 18.1509005 },
    { lat: 49.0309379, lng: 18.1512098 },
    { lat: 49.0307135, lng: 18.1517216 },
    { lat: 49.0304434, lng: 18.1520496 },
    { lat: 49.0295324, lng: 18.1523546 },
    { lat: 49.0293642, lng: 18.1524924 },
    { lat: 49.0291263, lng: 18.1528082 },
    { lat: 49.0290112, lng: 18.1529087 },
    { lat: 49.0287668, lng: 18.1532271 },
    { lat: 49.028469, lng: 18.1534466 },
    { lat: 49.0283384, lng: 18.1535887 },
    { lat: 49.0281366, lng: 18.153767 },
    { lat: 49.027931, lng: 18.1543129 },
    { lat: 49.0277603, lng: 18.154917 },
    { lat: 49.027545, lng: 18.1555264 },
    { lat: 49.027342, lng: 18.1560583 },
    { lat: 49.0272539, lng: 18.1562897 },
    { lat: 49.0270578, lng: 18.1567088 },
    { lat: 49.027017, lng: 18.1569202 },
    { lat: 49.0269475, lng: 18.1570636 },
    { lat: 49.0267891, lng: 18.1573925 },
    { lat: 49.0259893, lng: 18.1587469 },
    { lat: 49.0259573, lng: 18.1587839 },
    { lat: 49.0259046, lng: 18.1588445 },
    { lat: 49.0257627, lng: 18.159009 },
    { lat: 49.0257394, lng: 18.1590358 },
    { lat: 49.0256401, lng: 18.1591511 },
    { lat: 49.0251062, lng: 18.1594231 },
    { lat: 49.0239284, lng: 18.1597642 },
    { lat: 49.023776, lng: 18.1599384 },
    { lat: 49.0231698, lng: 18.1604249 },
    { lat: 49.0225955, lng: 18.1608741 },
    { lat: 49.0225479, lng: 18.1609092 },
    { lat: 49.0222478, lng: 18.161167 },
    { lat: 49.0215273, lng: 18.1619617 },
    { lat: 49.0209402, lng: 18.162524 },
    { lat: 49.0205085, lng: 18.1629661 },
    { lat: 49.0202177, lng: 18.1631845 },
    { lat: 49.0200046, lng: 18.1634433 },
    { lat: 49.0198544, lng: 18.1637181 },
    { lat: 49.0196697, lng: 18.1640587 },
    { lat: 49.0193409, lng: 18.1644626 },
    { lat: 49.0192967, lng: 18.1645174 },
    { lat: 49.019204, lng: 18.1646318 },
    { lat: 49.0190197, lng: 18.1648612 },
    { lat: 49.018856, lng: 18.1650771 },
    { lat: 49.0185932, lng: 18.1657825 },
    { lat: 49.0184773, lng: 18.1661621 },
    { lat: 49.0180717, lng: 18.1667649 },
    { lat: 49.0179313, lng: 18.1669302 },
    { lat: 49.0175102, lng: 18.1674363 },
    { lat: 49.0174455, lng: 18.1675502 },
    { lat: 49.0171538, lng: 18.1680696 },
    { lat: 49.0162309, lng: 18.1694471 },
    { lat: 49.0156197, lng: 18.1702382 },
    { lat: 49.0155745, lng: 18.1702586 },
    { lat: 49.0154987, lng: 18.1702935 },
    { lat: 49.0152437, lng: 18.1704107 },
    { lat: 49.0151096, lng: 18.1706126 },
    { lat: 49.0148226, lng: 18.1711572 },
    { lat: 49.0146638, lng: 18.1714141 },
    { lat: 49.0144915, lng: 18.17158 },
    { lat: 49.0143962, lng: 18.171629 },
    { lat: 49.0142488, lng: 18.1716554 },
    { lat: 49.0139013, lng: 18.1717558 },
    { lat: 49.0137127, lng: 18.1717545 },
    { lat: 49.013566, lng: 18.1717309 },
    { lat: 49.0132249, lng: 18.1717093 },
    { lat: 49.0129952, lng: 18.1717718 },
    { lat: 49.0129764, lng: 18.1717628 },
    { lat: 49.0127764, lng: 18.1719155 },
    { lat: 49.012606, lng: 18.172052 },
    { lat: 49.0124996, lng: 18.1721233 },
    { lat: 49.0124104, lng: 18.1721837 },
    { lat: 49.0122717, lng: 18.1722804 },
    { lat: 49.0121286, lng: 18.1723814 },
    { lat: 49.0120502, lng: 18.1724529 },
    { lat: 49.011971, lng: 18.1725288 },
    { lat: 49.0119658, lng: 18.1725371 },
    { lat: 49.0118919, lng: 18.1726047 },
    { lat: 49.0118147, lng: 18.1726768 },
    { lat: 49.0117379, lng: 18.1727514 },
    { lat: 49.0116672, lng: 18.1728179 },
    { lat: 49.0115938, lng: 18.1728941 },
    { lat: 49.01152, lng: 18.1729672 },
    { lat: 49.0114422, lng: 18.1730454 },
    { lat: 49.0114294, lng: 18.1730579 },
    { lat: 49.0112917, lng: 18.1731948 },
    { lat: 49.0112158, lng: 18.1732696 },
    { lat: 49.0111412, lng: 18.1733467 },
    { lat: 49.0110538, lng: 18.1734345 },
    { lat: 49.0109577, lng: 18.1735317 },
    { lat: 49.0108468, lng: 18.1736428 },
    { lat: 49.0107764, lng: 18.1737139 },
    { lat: 49.0107064, lng: 18.1737922 },
    { lat: 49.0106353, lng: 18.1738707 },
    { lat: 49.0105654, lng: 18.17395 },
    { lat: 49.0105136, lng: 18.1740087 },
    { lat: 49.0104604, lng: 18.1740657 },
    { lat: 49.0103599, lng: 18.1741804 },
    { lat: 49.0102876, lng: 18.1742594 },
    { lat: 49.0102188, lng: 18.1743389 },
    { lat: 49.0101499, lng: 18.1744149 },
    { lat: 49.0100418, lng: 18.1745385 },
    { lat: 49.0099359, lng: 18.1746579 },
    { lat: 49.0098332, lng: 18.1747754 },
    { lat: 49.0097399, lng: 18.1748802 },
    { lat: 49.0096517, lng: 18.1749756 },
    { lat: 49.0095774, lng: 18.1750577 },
    { lat: 49.009502, lng: 18.1751407 },
    { lat: 49.009468, lng: 18.1751782 },
    { lat: 49.009435, lng: 18.1752121 },
    { lat: 49.0093692, lng: 18.1752848 },
    { lat: 49.0092599, lng: 18.1754035 },
    { lat: 49.0092321, lng: 18.175431 },
    { lat: 49.0075206, lng: 18.1773045 },
    { lat: 49.0065114, lng: 18.1785144 },
    { lat: 49.0064164, lng: 18.1786272 },
    { lat: 49.006246, lng: 18.1782954 },
    { lat: 49.0061901, lng: 18.1783625 },
    { lat: 49.0051344, lng: 18.1796087 },
    { lat: 49.005074, lng: 18.1796778 },
    { lat: 49.0050465, lng: 18.1797091 },
    { lat: 49.0049758, lng: 18.1797954 },
    { lat: 49.0049516, lng: 18.1798263 },
    { lat: 49.004939, lng: 18.1798416 },
    { lat: 49.0048714, lng: 18.1798836 },
    { lat: 49.0048442, lng: 18.1799005 },
    { lat: 49.0048214, lng: 18.1799147 },
    { lat: 49.0047409, lng: 18.1799699 },
    { lat: 49.0036169, lng: 18.1812251 },
    { lat: 49.0032562, lng: 18.1816269 },
    { lat: 49.0031831, lng: 18.1817095 },
    { lat: 49.0030962, lng: 18.1818066 },
    { lat: 49.0029658, lng: 18.1819518 },
    { lat: 49.0029169, lng: 18.1820065 },
    { lat: 49.0028744, lng: 18.1820531 },
    { lat: 49.0027673, lng: 18.1821736 },
    { lat: 49.0027293, lng: 18.1822157 },
    { lat: 49.0026663, lng: 18.1822995 },
    { lat: 49.0024837, lng: 18.1825389 },
    { lat: 49.0023872, lng: 18.182666 },
    { lat: 49.0023684, lng: 18.1826919 },
    { lat: 49.0023107, lng: 18.1827667 },
    { lat: 49.0022477, lng: 18.1828509 },
    { lat: 49.0022109, lng: 18.1828989 },
    { lat: 49.0020988, lng: 18.1830464 },
    { lat: 49.0020577, lng: 18.1831007 },
    { lat: 49.0019319, lng: 18.1832661 },
    { lat: 49.001826, lng: 18.1834063 },
    { lat: 49.0010129, lng: 18.1845368 },
    { lat: 49.0006879, lng: 18.1849888 },
    { lat: 49.0003671, lng: 18.1854352 },
    { lat: 49.000363, lng: 18.1854418 },
    { lat: 49.0001946, lng: 18.1857347 },
    { lat: 49.0000513, lng: 18.186048 },
    { lat: 48.9997878, lng: 18.1864505 },
    { lat: 48.9998125, lng: 18.1864893 },
    { lat: 49.0014968, lng: 18.1891022 },
    { lat: 49.0002912, lng: 18.1905321 },
    { lat: 48.9987894, lng: 18.1928168 },
    { lat: 48.9974015, lng: 18.1946095 },
    { lat: 48.9973667, lng: 18.1946547 },
    { lat: 48.9973213, lng: 18.1948444 },
    { lat: 48.9969703, lng: 18.196658 },
    { lat: 48.9968022, lng: 18.197487 },
    { lat: 48.9965774, lng: 18.1986053 },
    { lat: 48.9960729, lng: 18.2011066 },
    { lat: 48.996063, lng: 18.2011528 },
    { lat: 48.9960551, lng: 18.2011929 },
    { lat: 48.9960509, lng: 18.201217 },
    { lat: 48.9960436, lng: 18.2012485 },
    { lat: 48.9960394, lng: 18.201272 },
    { lat: 48.995976, lng: 18.2015844 },
    { lat: 48.9956994, lng: 18.2029627 },
    { lat: 48.9957575, lng: 18.2039201 },
    { lat: 48.9957601, lng: 18.2039647 },
    { lat: 48.9957661, lng: 18.2040509 },
    { lat: 48.9957682, lng: 18.2040876 },
    { lat: 48.9957724, lng: 18.2041625 },
    { lat: 48.9957744, lng: 18.2041969 },
    { lat: 48.9957788, lng: 18.2042734 },
    { lat: 48.995794, lng: 18.2045194 },
    { lat: 48.9958114, lng: 18.2045268 },
    { lat: 48.9964577, lng: 18.2049765 },
    { lat: 48.9970696, lng: 18.2055968 },
    { lat: 48.9975189, lng: 18.2061315 },
    { lat: 48.9980293, lng: 18.2068895 },
    { lat: 48.9990196, lng: 18.2090554 },
    { lat: 48.9996782, lng: 18.2105707 },
    { lat: 49.0003924, lng: 18.2117941 },
    { lat: 49.0009066, lng: 18.2125798 },
  ],
  Slavnica: [
    { lat: 49.0349937, lng: 18.1464149 },
    { lat: 49.035458, lng: 18.145465 },
    { lat: 49.0355372, lng: 18.1452351 },
    { lat: 49.0359085, lng: 18.1442176 },
    { lat: 49.0359582, lng: 18.1440813 },
    { lat: 49.0363392, lng: 18.1433889 },
    { lat: 49.0364748, lng: 18.1431569 },
    { lat: 49.0368158, lng: 18.1429067 },
    { lat: 49.0375882, lng: 18.1422121 },
    { lat: 49.0380004, lng: 18.1415896 },
    { lat: 49.0382728, lng: 18.1413432 },
    { lat: 49.0385594, lng: 18.1411881 },
    { lat: 49.0394476, lng: 18.1394288 },
    { lat: 49.0394633, lng: 18.1380003 },
    { lat: 49.0395857, lng: 18.137634 },
    { lat: 49.0398758, lng: 18.1368752 },
    { lat: 49.0400196, lng: 18.1364531 },
    { lat: 49.0400415, lng: 18.1364232 },
    { lat: 49.0412223, lng: 18.1347506 },
    { lat: 49.0412957, lng: 18.1345142 },
    { lat: 49.0410561, lng: 18.1337829 },
    { lat: 49.0413028, lng: 18.1332475 },
    { lat: 49.0417346, lng: 18.1323237 },
    { lat: 49.0417253, lng: 18.1322606 },
    { lat: 49.0416148, lng: 18.1317149 },
    { lat: 49.0414089, lng: 18.1311015 },
    { lat: 49.0410026, lng: 18.1305144 },
    { lat: 49.0412594, lng: 18.1295474 },
    { lat: 49.0411439, lng: 18.1285072 },
    { lat: 49.0411389, lng: 18.1284575 },
    { lat: 49.0410722, lng: 18.1278506 },
    { lat: 49.0410152, lng: 18.1277604 },
    { lat: 49.0409243, lng: 18.1276157 },
    { lat: 49.0408821, lng: 18.1274707 },
    { lat: 49.0408851, lng: 18.1268394 },
    { lat: 49.0407854, lng: 18.1262837 },
    { lat: 49.0407448, lng: 18.1261093 },
    { lat: 49.0406704, lng: 18.12537 },
    { lat: 49.0405489, lng: 18.1249456 },
    { lat: 49.0405333, lng: 18.1248884 },
    { lat: 49.0404652, lng: 18.1246507 },
    { lat: 49.0403746, lng: 18.1241553 },
    { lat: 49.0402796, lng: 18.1231746 },
    { lat: 49.040187, lng: 18.1213975 },
    { lat: 49.0395353, lng: 18.1219534 },
    { lat: 49.0392504, lng: 18.1221577 },
    { lat: 49.0391495, lng: 18.1222291 },
    { lat: 49.0390596, lng: 18.1222933 },
    { lat: 49.0385781, lng: 18.1225548 },
    { lat: 49.0380571, lng: 18.1228518 },
    { lat: 49.0368591, lng: 18.123308 },
    { lat: 49.0364276, lng: 18.1237304 },
    { lat: 49.0361923, lng: 18.1237799 },
    { lat: 49.0361499, lng: 18.1238071 },
    { lat: 49.0356233, lng: 18.1241469 },
    { lat: 49.0351358, lng: 18.1254123 },
    { lat: 49.0343028, lng: 18.1265634 },
    { lat: 49.0341016, lng: 18.1273976 },
    { lat: 49.0339775, lng: 18.1277473 },
    { lat: 49.0337065, lng: 18.1290724 },
    { lat: 49.0334037, lng: 18.1299059 },
    { lat: 49.0332391, lng: 18.1302972 },
    { lat: 49.0327448, lng: 18.1293214 },
    { lat: 49.031553, lng: 18.1272093 },
    { lat: 49.0310405, lng: 18.127286 },
    { lat: 49.0312627, lng: 18.1284143 },
    { lat: 49.0312138, lng: 18.1290746 },
    { lat: 49.0310788, lng: 18.1294508 },
    { lat: 49.0308775, lng: 18.1299528 },
    { lat: 49.0306114, lng: 18.1295831 },
    { lat: 49.0305164, lng: 18.1288963 },
    { lat: 49.0298216, lng: 18.1293905 },
    { lat: 49.0295619, lng: 18.1295819 },
    { lat: 49.0291552, lng: 18.1298689 },
    { lat: 49.0281741, lng: 18.1305635 },
    { lat: 49.0276301, lng: 18.1309512 },
    { lat: 49.0264509, lng: 18.1317135 },
    { lat: 49.0259977, lng: 18.1320908 },
    { lat: 49.0256476, lng: 18.1323422 },
    { lat: 49.0250183, lng: 18.1328368 },
    { lat: 49.0241726, lng: 18.1335114 },
    { lat: 49.0235982, lng: 18.133747 },
    { lat: 49.0231468, lng: 18.1341366 },
    { lat: 49.0229678, lng: 18.1343209 },
    { lat: 49.0227695, lng: 18.1344624 },
    { lat: 49.0227669, lng: 18.134553 },
    { lat: 49.0222417, lng: 18.1342725 },
    { lat: 49.0221184, lng: 18.1342066 },
    { lat: 49.0219281, lng: 18.1345679 },
    { lat: 49.0218426, lng: 18.1346681 },
    { lat: 49.0215987, lng: 18.1349969 },
    { lat: 49.0215487, lng: 18.1351474 },
    { lat: 49.0215061, lng: 18.135408 },
    { lat: 49.0214399, lng: 18.1360399 },
    { lat: 49.0216017, lng: 18.1363024 },
    { lat: 49.0216917, lng: 18.1364327 },
    { lat: 49.0217891, lng: 18.1365751 },
    { lat: 49.0218792, lng: 18.1367105 },
    { lat: 49.0220043, lng: 18.1369034 },
    { lat: 49.022081, lng: 18.1370201 },
    { lat: 49.0222254, lng: 18.1372518 },
    { lat: 49.0222785, lng: 18.1373313 },
    { lat: 49.022326, lng: 18.1374124 },
    { lat: 49.0223107, lng: 18.1374791 },
    { lat: 49.0221332, lng: 18.1381205 },
    { lat: 49.0216592, lng: 18.1398394 },
    { lat: 49.0216463, lng: 18.1399102 },
    { lat: 49.0214547, lng: 18.1398358 },
    { lat: 49.0214743, lng: 18.1409593 },
    { lat: 49.0214733, lng: 18.1410565 },
    { lat: 49.0211459, lng: 18.1412303 },
    { lat: 49.0208447, lng: 18.1412726 },
    { lat: 49.0207272, lng: 18.1412886 },
    { lat: 49.0207159, lng: 18.1414663 },
    { lat: 49.020669, lng: 18.142215 },
    { lat: 49.0206874, lng: 18.142575 },
    { lat: 49.0205637, lng: 18.142993 },
    { lat: 49.020481, lng: 18.1432608 },
    { lat: 49.0203634, lng: 18.1440991 },
    { lat: 49.0202961, lng: 18.1444162 },
    { lat: 49.0202841, lng: 18.1446594 },
    { lat: 49.020198, lng: 18.144655 },
    { lat: 49.020075, lng: 18.1456925 },
    { lat: 49.0198349, lng: 18.1461246 },
    { lat: 49.0191757, lng: 18.1476121 },
    { lat: 49.0187096, lng: 18.1484521 },
    { lat: 49.018479, lng: 18.1488363 },
    { lat: 49.0179398, lng: 18.1496228 },
    { lat: 49.0174861, lng: 18.1503479 },
    { lat: 49.0173331, lng: 18.1501997 },
    { lat: 49.0163524, lng: 18.1518804 },
    { lat: 49.0162088, lng: 18.1520523 },
    { lat: 49.0158635, lng: 18.1524657 },
    { lat: 49.0158384, lng: 18.1524964 },
    { lat: 49.0150963, lng: 18.153386 },
    { lat: 49.0149028, lng: 18.1535746 },
    { lat: 49.014859, lng: 18.1536175 },
    { lat: 49.0141143, lng: 18.1543428 },
    { lat: 49.0139774, lng: 18.1545124 },
    { lat: 49.0139615, lng: 18.1545321 },
    { lat: 49.0138697, lng: 18.1546421 },
    { lat: 49.0130516, lng: 18.1556615 },
    { lat: 49.0128315, lng: 18.1561498 },
    { lat: 49.0121859, lng: 18.1569503 },
    { lat: 49.0117187, lng: 18.1574395 },
    { lat: 49.0114579, lng: 18.1577531 },
    { lat: 49.0112059, lng: 18.1580569 },
    { lat: 49.0111256, lng: 18.1581542 },
    { lat: 49.0108777, lng: 18.1584525 },
    { lat: 49.010342, lng: 18.1589673 },
    { lat: 49.0098581, lng: 18.1593235 },
    { lat: 49.0092978, lng: 18.1597653 },
    { lat: 49.0082875, lng: 18.16074 },
    { lat: 49.0080884, lng: 18.1609257 },
    { lat: 49.0073582, lng: 18.161476 },
    { lat: 49.0067286, lng: 18.1618461 },
    { lat: 49.0064537, lng: 18.1620696 },
    { lat: 49.0061503, lng: 18.1623486 },
    { lat: 49.0053736, lng: 18.1629704 },
    { lat: 49.0048684, lng: 18.1634045 },
    { lat: 49.0043733, lng: 18.1638616 },
    { lat: 49.0026271, lng: 18.1656588 },
    { lat: 49.0017968, lng: 18.1664849 },
    { lat: 49.0006785, lng: 18.1677202 },
    { lat: 49.0002142, lng: 18.1682605 },
    { lat: 48.9997968, lng: 18.1687745 },
    { lat: 48.9992023, lng: 18.1695833 },
    { lat: 48.9991338, lng: 18.1696682 },
    { lat: 48.9990834, lng: 18.1697324 },
    { lat: 48.9990479, lng: 18.1697809 },
    { lat: 48.9979457, lng: 18.1712576 },
    { lat: 48.9978896, lng: 18.1712848 },
    { lat: 48.9978183, lng: 18.1713194 },
    { lat: 48.9977743, lng: 18.1713407 },
    { lat: 48.9961911, lng: 18.1735566 },
    { lat: 48.9954418, lng: 18.1745996 },
    { lat: 48.9949898, lng: 18.1752187 },
    { lat: 48.9947244, lng: 18.1755862 },
    { lat: 48.9947143, lng: 18.1756045 },
    { lat: 48.9946869, lng: 18.175643 },
    { lat: 48.99468, lng: 18.1756572 },
    { lat: 48.9928321, lng: 18.1783966 },
    { lat: 48.9927632, lng: 18.1784948 },
    { lat: 48.9927508, lng: 18.1785126 },
    { lat: 48.9927372, lng: 18.1785317 },
    { lat: 48.9927195, lng: 18.1785563 },
    { lat: 48.9926914, lng: 18.1785977 },
    { lat: 48.9916887, lng: 18.1799225 },
    { lat: 48.9906564, lng: 18.1814813 },
    { lat: 48.9906223, lng: 18.181534 },
    { lat: 48.9896965, lng: 18.1825868 },
    { lat: 48.9896382, lng: 18.1826556 },
    { lat: 48.9894268, lng: 18.1828849 },
    { lat: 48.9896692, lng: 18.1833916 },
    { lat: 48.989723, lng: 18.1835035 },
    { lat: 48.9898818, lng: 18.1838339 },
    { lat: 48.9901636, lng: 18.1846145 },
    { lat: 48.9901865, lng: 18.1846789 },
    { lat: 48.9903887, lng: 18.1852356 },
    { lat: 48.990657, lng: 18.185978 },
    { lat: 48.9908966, lng: 18.1866745 },
    { lat: 48.99117, lng: 18.1874673 },
    { lat: 48.9911969, lng: 18.1875431 },
    { lat: 48.9914912, lng: 18.1884069 },
    { lat: 48.9915068, lng: 18.188441 },
    { lat: 48.9917859, lng: 18.1890609 },
    { lat: 48.9918314, lng: 18.1891619 },
    { lat: 48.9918792, lng: 18.1892695 },
    { lat: 48.9919724, lng: 18.1894774 },
    { lat: 48.9924891, lng: 18.1904428 },
    { lat: 48.9927709, lng: 18.1909687 },
    { lat: 48.9941664, lng: 18.1934649 },
    { lat: 48.9941809, lng: 18.1934815 },
    { lat: 48.9942098, lng: 18.1935145 },
    { lat: 48.9951577, lng: 18.1945986 },
    { lat: 48.9958515, lng: 18.1953847 },
    { lat: 48.9960856, lng: 18.1956501 },
    { lat: 48.9969703, lng: 18.196658 },
    { lat: 48.9973213, lng: 18.1948444 },
    { lat: 48.9973667, lng: 18.1946547 },
    { lat: 48.9974015, lng: 18.1946095 },
    { lat: 48.9987894, lng: 18.1928168 },
    { lat: 49.0002912, lng: 18.1905321 },
    { lat: 49.0014968, lng: 18.1891022 },
    { lat: 48.9998125, lng: 18.1864893 },
    { lat: 48.9997878, lng: 18.1864505 },
    { lat: 49.0000513, lng: 18.186048 },
    { lat: 49.0001946, lng: 18.1857347 },
    { lat: 49.000363, lng: 18.1854418 },
    { lat: 49.0003671, lng: 18.1854352 },
    { lat: 49.0006879, lng: 18.1849888 },
    { lat: 49.0010129, lng: 18.1845368 },
    { lat: 49.001826, lng: 18.1834063 },
    { lat: 49.0019319, lng: 18.1832661 },
    { lat: 49.0020577, lng: 18.1831007 },
    { lat: 49.0020988, lng: 18.1830464 },
    { lat: 49.0022109, lng: 18.1828989 },
    { lat: 49.0022477, lng: 18.1828509 },
    { lat: 49.0023107, lng: 18.1827667 },
    { lat: 49.0023684, lng: 18.1826919 },
    { lat: 49.0023872, lng: 18.182666 },
    { lat: 49.0024837, lng: 18.1825389 },
    { lat: 49.0026663, lng: 18.1822995 },
    { lat: 49.0027293, lng: 18.1822157 },
    { lat: 49.0027673, lng: 18.1821736 },
    { lat: 49.0028744, lng: 18.1820531 },
    { lat: 49.0029169, lng: 18.1820065 },
    { lat: 49.0029658, lng: 18.1819518 },
    { lat: 49.0030962, lng: 18.1818066 },
    { lat: 49.0031831, lng: 18.1817095 },
    { lat: 49.0032562, lng: 18.1816269 },
    { lat: 49.0036169, lng: 18.1812251 },
    { lat: 49.0047409, lng: 18.1799699 },
    { lat: 49.0048214, lng: 18.1799147 },
    { lat: 49.0048442, lng: 18.1799005 },
    { lat: 49.0048714, lng: 18.1798836 },
    { lat: 49.004939, lng: 18.1798416 },
    { lat: 49.0049516, lng: 18.1798263 },
    { lat: 49.0049758, lng: 18.1797954 },
    { lat: 49.0050465, lng: 18.1797091 },
    { lat: 49.005074, lng: 18.1796778 },
    { lat: 49.0051344, lng: 18.1796087 },
    { lat: 49.0061901, lng: 18.1783625 },
    { lat: 49.006246, lng: 18.1782954 },
    { lat: 49.0064164, lng: 18.1786272 },
    { lat: 49.0065114, lng: 18.1785144 },
    { lat: 49.0075206, lng: 18.1773045 },
    { lat: 49.0092321, lng: 18.175431 },
    { lat: 49.0092599, lng: 18.1754035 },
    { lat: 49.0093692, lng: 18.1752848 },
    { lat: 49.009435, lng: 18.1752121 },
    { lat: 49.009468, lng: 18.1751782 },
    { lat: 49.009502, lng: 18.1751407 },
    { lat: 49.0095774, lng: 18.1750577 },
    { lat: 49.0096517, lng: 18.1749756 },
    { lat: 49.0097399, lng: 18.1748802 },
    { lat: 49.0098332, lng: 18.1747754 },
    { lat: 49.0099359, lng: 18.1746579 },
    { lat: 49.0100418, lng: 18.1745385 },
    { lat: 49.0101499, lng: 18.1744149 },
    { lat: 49.0102188, lng: 18.1743389 },
    { lat: 49.0102876, lng: 18.1742594 },
    { lat: 49.0103599, lng: 18.1741804 },
    { lat: 49.0104604, lng: 18.1740657 },
    { lat: 49.0105136, lng: 18.1740087 },
    { lat: 49.0105654, lng: 18.17395 },
    { lat: 49.0106353, lng: 18.1738707 },
    { lat: 49.0107064, lng: 18.1737922 },
    { lat: 49.0107764, lng: 18.1737139 },
    { lat: 49.0108468, lng: 18.1736428 },
    { lat: 49.0109577, lng: 18.1735317 },
    { lat: 49.0110538, lng: 18.1734345 },
    { lat: 49.0111412, lng: 18.1733467 },
    { lat: 49.0112158, lng: 18.1732696 },
    { lat: 49.0112917, lng: 18.1731948 },
    { lat: 49.0114294, lng: 18.1730579 },
    { lat: 49.0114422, lng: 18.1730454 },
    { lat: 49.01152, lng: 18.1729672 },
    { lat: 49.0115938, lng: 18.1728941 },
    { lat: 49.0116672, lng: 18.1728179 },
    { lat: 49.0117379, lng: 18.1727514 },
    { lat: 49.0118147, lng: 18.1726768 },
    { lat: 49.0118919, lng: 18.1726047 },
    { lat: 49.0119658, lng: 18.1725371 },
    { lat: 49.011971, lng: 18.1725288 },
    { lat: 49.0120502, lng: 18.1724529 },
    { lat: 49.0121286, lng: 18.1723814 },
    { lat: 49.0122717, lng: 18.1722804 },
    { lat: 49.0124104, lng: 18.1721837 },
    { lat: 49.0124996, lng: 18.1721233 },
    { lat: 49.012606, lng: 18.172052 },
    { lat: 49.0127764, lng: 18.1719155 },
    { lat: 49.0129764, lng: 18.1717628 },
    { lat: 49.0129952, lng: 18.1717718 },
    { lat: 49.0132249, lng: 18.1717093 },
    { lat: 49.013566, lng: 18.1717309 },
    { lat: 49.0137127, lng: 18.1717545 },
    { lat: 49.0139013, lng: 18.1717558 },
    { lat: 49.0142488, lng: 18.1716554 },
    { lat: 49.0143962, lng: 18.171629 },
    { lat: 49.0144915, lng: 18.17158 },
    { lat: 49.0146638, lng: 18.1714141 },
    { lat: 49.0148226, lng: 18.1711572 },
    { lat: 49.0151096, lng: 18.1706126 },
    { lat: 49.0152437, lng: 18.1704107 },
    { lat: 49.0154987, lng: 18.1702935 },
    { lat: 49.0155745, lng: 18.1702586 },
    { lat: 49.0156197, lng: 18.1702382 },
    { lat: 49.0162309, lng: 18.1694471 },
    { lat: 49.0171538, lng: 18.1680696 },
    { lat: 49.0174455, lng: 18.1675502 },
    { lat: 49.0175102, lng: 18.1674363 },
    { lat: 49.0179313, lng: 18.1669302 },
    { lat: 49.0180717, lng: 18.1667649 },
    { lat: 49.0184773, lng: 18.1661621 },
    { lat: 49.0185932, lng: 18.1657825 },
    { lat: 49.018856, lng: 18.1650771 },
    { lat: 49.0190197, lng: 18.1648612 },
    { lat: 49.019204, lng: 18.1646318 },
    { lat: 49.0192967, lng: 18.1645174 },
    { lat: 49.0193409, lng: 18.1644626 },
    { lat: 49.0196697, lng: 18.1640587 },
    { lat: 49.0198544, lng: 18.1637181 },
    { lat: 49.0200046, lng: 18.1634433 },
    { lat: 49.0202177, lng: 18.1631845 },
    { lat: 49.0205085, lng: 18.1629661 },
    { lat: 49.0209402, lng: 18.162524 },
    { lat: 49.0215273, lng: 18.1619617 },
    { lat: 49.0222478, lng: 18.161167 },
    { lat: 49.0225479, lng: 18.1609092 },
    { lat: 49.0225955, lng: 18.1608741 },
    { lat: 49.0231698, lng: 18.1604249 },
    { lat: 49.023776, lng: 18.1599384 },
    { lat: 49.0239284, lng: 18.1597642 },
    { lat: 49.0251062, lng: 18.1594231 },
    { lat: 49.0256401, lng: 18.1591511 },
    { lat: 49.0257394, lng: 18.1590358 },
    { lat: 49.0257627, lng: 18.159009 },
    { lat: 49.0259046, lng: 18.1588445 },
    { lat: 49.0259573, lng: 18.1587839 },
    { lat: 49.0259893, lng: 18.1587469 },
    { lat: 49.0267891, lng: 18.1573925 },
    { lat: 49.0269475, lng: 18.1570636 },
    { lat: 49.027017, lng: 18.1569202 },
    { lat: 49.0270578, lng: 18.1567088 },
    { lat: 49.0272539, lng: 18.1562897 },
    { lat: 49.027342, lng: 18.1560583 },
    { lat: 49.027545, lng: 18.1555264 },
    { lat: 49.0277603, lng: 18.154917 },
    { lat: 49.027931, lng: 18.1543129 },
    { lat: 49.0281366, lng: 18.153767 },
    { lat: 49.0283384, lng: 18.1535887 },
    { lat: 49.028469, lng: 18.1534466 },
    { lat: 49.0287668, lng: 18.1532271 },
    { lat: 49.0290112, lng: 18.1529087 },
    { lat: 49.0291263, lng: 18.1528082 },
    { lat: 49.0293642, lng: 18.1524924 },
    { lat: 49.0295324, lng: 18.1523546 },
    { lat: 49.0304434, lng: 18.1520496 },
    { lat: 49.0307135, lng: 18.1517216 },
    { lat: 49.0309379, lng: 18.1512098 },
    { lat: 49.031219, lng: 18.1509005 },
    { lat: 49.0314568, lng: 18.1505783 },
    { lat: 49.0318679, lng: 18.1496447 },
    { lat: 49.0320105, lng: 18.1493419 },
    { lat: 49.0321299, lng: 18.1491603 },
    { lat: 49.032258, lng: 18.1490112 },
    { lat: 49.0327908, lng: 18.1487831 },
    { lat: 49.0330835, lng: 18.1487128 },
    { lat: 49.0334133, lng: 18.1486789 },
    { lat: 49.0337047, lng: 18.1482701 },
    { lat: 49.0337294, lng: 18.1482312 },
    { lat: 49.0338008, lng: 18.1481364 },
    { lat: 49.0341996, lng: 18.1476172 },
    { lat: 49.0342661, lng: 18.1475353 },
    { lat: 49.0343421, lng: 18.1474846 },
    { lat: 49.0349937, lng: 18.1464149 },
  ],
};

export default UnitsIlava;
