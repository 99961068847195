const UnitsSenica = {
  Smolinské: [
    { lat: 48.6629951, lng: 17.1154979 },
    { lat: 48.6628721, lng: 17.1161247 },
    { lat: 48.6638042, lng: 17.1169833 },
    { lat: 48.6639658, lng: 17.1172017 },
    { lat: 48.6642124, lng: 17.1177459 },
    { lat: 48.6640866, lng: 17.1181615 },
    { lat: 48.6642314, lng: 17.1181948 },
    { lat: 48.6642343, lng: 17.1183223 },
    { lat: 48.6637249, lng: 17.1206152 },
    { lat: 48.6642238, lng: 17.1208449 },
    { lat: 48.6643646, lng: 17.1203322 },
    { lat: 48.6647647, lng: 17.1206206 },
    { lat: 48.6645814, lng: 17.1214071 },
    { lat: 48.6643694, lng: 17.1226948 },
    { lat: 48.6650012, lng: 17.1227134 },
    { lat: 48.6647615, lng: 17.1243117 },
    { lat: 48.665037, lng: 17.1244272 },
    { lat: 48.6647711, lng: 17.1261088 },
    { lat: 48.6645224, lng: 17.1290073 },
    { lat: 48.6644144, lng: 17.1308875 },
    { lat: 48.6642447, lng: 17.1308815 },
    { lat: 48.6639095, lng: 17.1328968 },
    { lat: 48.6638735, lng: 17.1334048 },
    { lat: 48.6644685, lng: 17.1337351 },
    { lat: 48.6644608, lng: 17.1353236 },
    { lat: 48.6645457, lng: 17.1372994 },
    { lat: 48.66506, lng: 17.1376577 },
    { lat: 48.6654196, lng: 17.1376965 },
    { lat: 48.6656282, lng: 17.1376221 },
    { lat: 48.66622, lng: 17.1371995 },
    { lat: 48.6665963, lng: 17.1370795 },
    { lat: 48.6668061, lng: 17.1382238 },
    { lat: 48.6670129, lng: 17.1382749 },
    { lat: 48.6670259, lng: 17.1389742 },
    { lat: 48.6682519, lng: 17.1393288 },
    { lat: 48.6691751, lng: 17.1405653 },
    { lat: 48.6695276, lng: 17.1408511 },
    { lat: 48.6696163, lng: 17.1405073 },
    { lat: 48.6697299, lng: 17.1405318 },
    { lat: 48.6693248, lng: 17.1427694 },
    { lat: 48.6693699, lng: 17.1428018 },
    { lat: 48.6700372, lng: 17.1427112 },
    { lat: 48.6704214, lng: 17.142831 },
    { lat: 48.6703605, lng: 17.1425443 },
    { lat: 48.6705252, lng: 17.1425735 },
    { lat: 48.6706811, lng: 17.1431811 },
    { lat: 48.670841, lng: 17.1433451 },
    { lat: 48.6710196, lng: 17.1433958 },
    { lat: 48.6718815, lng: 17.1431893 },
    { lat: 48.6719602, lng: 17.1432685 },
    { lat: 48.6719879, lng: 17.1435474 },
    { lat: 48.6725105, lng: 17.1434388 },
    { lat: 48.6728541, lng: 17.1449943 },
    { lat: 48.673139, lng: 17.1449817 },
    { lat: 48.6733316, lng: 17.1448883 },
    { lat: 48.6732455, lng: 17.144259 },
    { lat: 48.6738284, lng: 17.1441198 },
    { lat: 48.6739328, lng: 17.144638 },
    { lat: 48.6741326, lng: 17.1445164 },
    { lat: 48.6745795, lng: 17.1444411 },
    { lat: 48.6749356, lng: 17.1444408 },
    { lat: 48.6752341, lng: 17.1445283 },
    { lat: 48.6761155, lng: 17.1453453 },
    { lat: 48.676664, lng: 17.1457103 },
    { lat: 48.6763989, lng: 17.1474081 },
    { lat: 48.6762943, lng: 17.149223 },
    { lat: 48.677416, lng: 17.1491691 },
    { lat: 48.6779359, lng: 17.1492211 },
    { lat: 48.6793672, lng: 17.1494886 },
    { lat: 48.6797171, lng: 17.1495847 },
    { lat: 48.6800114, lng: 17.1497469 },
    { lat: 48.6805888, lng: 17.1502718 },
    { lat: 48.681539, lng: 17.151269 },
    { lat: 48.683267, lng: 17.15344 },
    { lat: 48.68333, lng: 17.153526 },
    { lat: 48.68337, lng: 17.15359 },
    { lat: 48.683412, lng: 17.153615 },
    { lat: 48.683502, lng: 17.153665 },
    { lat: 48.683633, lng: 17.153753 },
    { lat: 48.684105, lng: 17.154108 },
    { lat: 48.685238, lng: 17.154588 },
    { lat: 48.686555, lng: 17.155301 },
    { lat: 48.686701, lng: 17.155347 },
    { lat: 48.688274, lng: 17.15572 },
    { lat: 48.688907, lng: 17.155835 },
    { lat: 48.689289, lng: 17.155894 },
    { lat: 48.689998, lng: 17.156006 },
    { lat: 48.690039, lng: 17.15602 },
    { lat: 48.690202, lng: 17.156078 },
    { lat: 48.691192, lng: 17.156399 },
    { lat: 48.691487, lng: 17.156655 },
    { lat: 48.6916, lng: 17.156709 },
    { lat: 48.691666, lng: 17.156741 },
    { lat: 48.692051, lng: 17.156284 },
    { lat: 48.692538, lng: 17.155811 },
    { lat: 48.692642, lng: 17.155653 },
    { lat: 48.693268, lng: 17.155093 },
    { lat: 48.693648, lng: 17.154703 },
    { lat: 48.694166, lng: 17.154077 },
    { lat: 48.69431, lng: 17.153856 },
    { lat: 48.694512, lng: 17.15356 },
    { lat: 48.69456, lng: 17.153499 },
    { lat: 48.694776, lng: 17.153188 },
    { lat: 48.6949, lng: 17.15297 },
    { lat: 48.695211, lng: 17.15268 },
    { lat: 48.695642, lng: 17.151907 },
    { lat: 48.696581, lng: 17.150578 },
    { lat: 48.696715, lng: 17.150421 },
    { lat: 48.697148, lng: 17.149829 },
    { lat: 48.697537, lng: 17.149374 },
    { lat: 48.698063, lng: 17.148848 },
    { lat: 48.698259, lng: 17.1487 },
    { lat: 48.698621, lng: 17.148523 },
    { lat: 48.698919, lng: 17.148452 },
    { lat: 48.699228, lng: 17.148419 },
    { lat: 48.699928, lng: 17.148296 },
    { lat: 48.700542, lng: 17.148141 },
    { lat: 48.701095, lng: 17.148059 },
    { lat: 48.701224, lng: 17.148088 },
    { lat: 48.701356, lng: 17.14816 },
    { lat: 48.701523, lng: 17.148213 },
    { lat: 48.701819, lng: 17.148265 },
    { lat: 48.702141, lng: 17.148061 },
    { lat: 48.702585, lng: 17.147852 },
    { lat: 48.703235, lng: 17.147612 },
    { lat: 48.703225, lng: 17.147376 },
    { lat: 48.703206, lng: 17.147264 },
    { lat: 48.703205, lng: 17.147249 },
    { lat: 48.703191, lng: 17.147206 },
    { lat: 48.70307, lng: 17.146945 },
    { lat: 48.702982, lng: 17.146629 },
    { lat: 48.702901, lng: 17.146378 },
    { lat: 48.702746, lng: 17.145885 },
    { lat: 48.702631, lng: 17.145549 },
    { lat: 48.702441, lng: 17.145097 },
    { lat: 48.702344, lng: 17.14486 },
    { lat: 48.702138, lng: 17.144504 },
    { lat: 48.701966, lng: 17.144167 },
    { lat: 48.701881, lng: 17.144036 },
    { lat: 48.701734, lng: 17.143803 },
    { lat: 48.701533, lng: 17.143425 },
    { lat: 48.701342, lng: 17.143042 },
    { lat: 48.70129, lng: 17.142947 },
    { lat: 48.701249, lng: 17.142896 },
    { lat: 48.701116, lng: 17.142598 },
    { lat: 48.701042, lng: 17.142478 },
    { lat: 48.700887, lng: 17.142283 },
    { lat: 48.700779, lng: 17.14197 },
    { lat: 48.700445, lng: 17.141622 },
    { lat: 48.700083, lng: 17.141263 },
    { lat: 48.699593, lng: 17.140318 },
    { lat: 48.699326, lng: 17.139753 },
    { lat: 48.699057, lng: 17.139275 },
    { lat: 48.698712, lng: 17.138818 },
    { lat: 48.698554, lng: 17.138924 },
    { lat: 48.698452, lng: 17.138728 },
    { lat: 48.698455, lng: 17.138534 },
    { lat: 48.698245, lng: 17.138041 },
    { lat: 48.697978, lng: 17.137654 },
    { lat: 48.697866, lng: 17.137525 },
    { lat: 48.698143, lng: 17.137151 },
    { lat: 48.697499, lng: 17.13616 },
    { lat: 48.697459, lng: 17.136026 },
    { lat: 48.697526, lng: 17.135902 },
    { lat: 48.697166, lng: 17.135074 },
    { lat: 48.696979, lng: 17.134736 },
    { lat: 48.696476, lng: 17.133734 },
    { lat: 48.696251, lng: 17.133241 },
    { lat: 48.696066, lng: 17.132741 },
    { lat: 48.695914, lng: 17.132391 },
    { lat: 48.695805, lng: 17.132062 },
    { lat: 48.695671, lng: 17.131805 },
    { lat: 48.695577, lng: 17.131738 },
    { lat: 48.695491, lng: 17.131487 },
    { lat: 48.695311, lng: 17.131008 },
    { lat: 48.695132, lng: 17.130683 },
    { lat: 48.694995, lng: 17.130302 },
    { lat: 48.694822, lng: 17.129718 },
    { lat: 48.694557, lng: 17.128861 },
    { lat: 48.694266, lng: 17.127948 },
    { lat: 48.69423, lng: 17.127665 },
    { lat: 48.69449, lng: 17.127115 },
    { lat: 48.694513, lng: 17.126755 },
    { lat: 48.694487, lng: 17.126182 },
    { lat: 48.694487, lng: 17.126123 },
    { lat: 48.694484, lng: 17.126029 },
    { lat: 48.694494, lng: 17.126004 },
    { lat: 48.694322, lng: 17.125605 },
    { lat: 48.694137, lng: 17.125186 },
    { lat: 48.693791, lng: 17.124374 },
    { lat: 48.693709, lng: 17.124143 },
    { lat: 48.693519, lng: 17.123656 },
    { lat: 48.693414, lng: 17.123516 },
    { lat: 48.693186, lng: 17.123112 },
    { lat: 48.692963, lng: 17.122715 },
    { lat: 48.692751, lng: 17.122271 },
    { lat: 48.692815, lng: 17.122002 },
    { lat: 48.692735, lng: 17.121506 },
    { lat: 48.692741, lng: 17.121368 },
    { lat: 48.692706, lng: 17.121215 },
    { lat: 48.692693, lng: 17.121026 },
    { lat: 48.692637, lng: 17.120731 },
    { lat: 48.692535, lng: 17.120399 },
    { lat: 48.692341, lng: 17.119854 },
    { lat: 48.692155, lng: 17.119656 },
    { lat: 48.692048, lng: 17.119343 },
    { lat: 48.691877, lng: 17.119054 },
    { lat: 48.691917, lng: 17.118913 },
    { lat: 48.691818, lng: 17.118695 },
    { lat: 48.691676, lng: 17.118267 },
    { lat: 48.69163, lng: 17.118312 },
    { lat: 48.691605, lng: 17.118266 },
    { lat: 48.691647, lng: 17.118151 },
    { lat: 48.691661, lng: 17.11807 },
    { lat: 48.69203, lng: 17.117012 },
    { lat: 48.692278, lng: 17.116222 },
    { lat: 48.692251, lng: 17.116209 },
    { lat: 48.692195, lng: 17.116181 },
    { lat: 48.692041, lng: 17.116103 },
    { lat: 48.691838, lng: 17.116008 },
    { lat: 48.692035, lng: 17.115414 },
    { lat: 48.692334, lng: 17.114406 },
    { lat: 48.692506, lng: 17.113822 },
    { lat: 48.692518, lng: 17.113629 },
    { lat: 48.692722, lng: 17.112776 },
    { lat: 48.692819, lng: 17.112278 },
    { lat: 48.692984, lng: 17.111622 },
    { lat: 48.693071, lng: 17.110982 },
    { lat: 48.693108, lng: 17.110417 },
    { lat: 48.6931, lng: 17.110121 },
    { lat: 48.693181, lng: 17.108776 },
    { lat: 48.694134, lng: 17.108012 },
    { lat: 48.69461, lng: 17.107242 },
    { lat: 48.694636, lng: 17.1072 },
    { lat: 48.694667, lng: 17.107149 },
    { lat: 48.694912, lng: 17.10616 },
    { lat: 48.694925, lng: 17.106109 },
    { lat: 48.695452, lng: 17.104664 },
    { lat: 48.695629, lng: 17.103603 },
    { lat: 48.695684, lng: 17.103388 },
    { lat: 48.6957, lng: 17.103329 },
    { lat: 48.695908, lng: 17.102527 },
    { lat: 48.695866, lng: 17.101822 },
    { lat: 48.695577, lng: 17.101106 },
    { lat: 48.695533, lng: 17.100599 },
    { lat: 48.695524, lng: 17.100552 },
    { lat: 48.695378, lng: 17.099846 },
    { lat: 48.695405, lng: 17.099339 },
    { lat: 48.695304, lng: 17.098732 },
    { lat: 48.695345, lng: 17.097978 },
    { lat: 48.6953, lng: 17.097785 },
    { lat: 48.695238, lng: 17.097516 },
    { lat: 48.695046, lng: 17.097015 },
    { lat: 48.694842, lng: 17.096509 },
    { lat: 48.694731, lng: 17.09599 },
    { lat: 48.69481, lng: 17.095593 },
    { lat: 48.695146, lng: 17.095341 },
    { lat: 48.695105, lng: 17.09491 },
    { lat: 48.695023, lng: 17.094805 },
    { lat: 48.694972, lng: 17.094739 },
    { lat: 48.694653, lng: 17.09433 },
    { lat: 48.694271, lng: 17.092733 },
    { lat: 48.693947, lng: 17.091766 },
    { lat: 48.69393, lng: 17.091712 },
    { lat: 48.693767, lng: 17.091236 },
    { lat: 48.692864, lng: 17.089444 },
    { lat: 48.692865, lng: 17.089437 },
    { lat: 48.692867, lng: 17.089381 },
    { lat: 48.692961, lng: 17.086869 },
    { lat: 48.692959, lng: 17.086767 },
    { lat: 48.692994, lng: 17.085972 },
    { lat: 48.693006, lng: 17.085553 },
    { lat: 48.693008, lng: 17.085497 },
    { lat: 48.693022, lng: 17.084958 },
    { lat: 48.693075, lng: 17.084323 },
    { lat: 48.693063, lng: 17.084067 },
    { lat: 48.692984, lng: 17.083165 },
    { lat: 48.692923, lng: 17.082331 },
    { lat: 48.692888, lng: 17.081584 },
    { lat: 48.692886, lng: 17.08153 },
    { lat: 48.692812, lng: 17.079956 },
    { lat: 48.691929, lng: 17.079115 },
    { lat: 48.691337, lng: 17.078022 },
    { lat: 48.691321, lng: 17.078038 },
    { lat: 48.69076, lng: 17.076732 },
    { lat: 48.690441, lng: 17.076189 },
    { lat: 48.690405, lng: 17.076127 },
    { lat: 48.690086, lng: 17.075568 },
    { lat: 48.689529, lng: 17.074832 },
    { lat: 48.689255, lng: 17.074354 },
    { lat: 48.689188, lng: 17.074129 },
    { lat: 48.68902, lng: 17.073658 },
    { lat: 48.689004, lng: 17.073618 },
    { lat: 48.688796, lng: 17.073031 },
    { lat: 48.68872, lng: 17.072851 },
    { lat: 48.688151, lng: 17.071156 },
    { lat: 48.687954, lng: 17.070639 },
    { lat: 48.687788, lng: 17.070141 },
    { lat: 48.687755, lng: 17.070043 },
    { lat: 48.68764, lng: 17.069689 },
    { lat: 48.687496, lng: 17.069184 },
    { lat: 48.687212, lng: 17.067967 },
    { lat: 48.687148, lng: 17.067738 },
    { lat: 48.686882, lng: 17.067333 },
    { lat: 48.686605, lng: 17.066993 },
    { lat: 48.68561, lng: 17.066473 },
    { lat: 48.685523, lng: 17.066406 },
    { lat: 48.685392, lng: 17.066261 },
    { lat: 48.685272, lng: 17.066113 },
    { lat: 48.685157, lng: 17.065916 },
    { lat: 48.685127, lng: 17.065871 },
    { lat: 48.685057, lng: 17.065701 },
    { lat: 48.684768, lng: 17.064746 },
    { lat: 48.683091, lng: 17.0666471 },
    { lat: 48.6829362, lng: 17.0674687 },
    { lat: 48.6826787, lng: 17.0681406 },
    { lat: 48.6821384, lng: 17.0690069 },
    { lat: 48.6818843, lng: 17.0691926 },
    { lat: 48.6817253, lng: 17.0694331 },
    { lat: 48.6816846, lng: 17.0699003 },
    { lat: 48.6818267, lng: 17.0704337 },
    { lat: 48.6819209, lng: 17.0715592 },
    { lat: 48.6820545, lng: 17.0717492 },
    { lat: 48.6821502, lng: 17.0720091 },
    { lat: 48.6820884, lng: 17.0726033 },
    { lat: 48.6821328, lng: 17.0739631 },
    { lat: 48.6820563, lng: 17.0744365 },
    { lat: 48.6817995, lng: 17.07482 },
    { lat: 48.6816701, lng: 17.0755798 },
    { lat: 48.6809419, lng: 17.0771489 },
    { lat: 48.6809601, lng: 17.0772323 },
    { lat: 48.6808316, lng: 17.0775388 },
    { lat: 48.6808452, lng: 17.077807 },
    { lat: 48.6807465, lng: 17.0778537 },
    { lat: 48.6807451, lng: 17.0780681 },
    { lat: 48.6806439, lng: 17.0781752 },
    { lat: 48.6805711, lng: 17.0781488 },
    { lat: 48.6804695, lng: 17.0784194 },
    { lat: 48.6803758, lng: 17.0785046 },
    { lat: 48.6804142, lng: 17.0787232 },
    { lat: 48.6803851, lng: 17.0787095 },
    { lat: 48.6802911, lng: 17.0789588 },
    { lat: 48.6801871, lng: 17.0789927 },
    { lat: 48.6800759, lng: 17.0791693 },
    { lat: 48.6800234, lng: 17.0796476 },
    { lat: 48.6799288, lng: 17.079685 },
    { lat: 48.6798648, lng: 17.0800592 },
    { lat: 48.6797442, lng: 17.0802304 },
    { lat: 48.6797877, lng: 17.0802748 },
    { lat: 48.6797526, lng: 17.0803732 },
    { lat: 48.6796835, lng: 17.0803559 },
    { lat: 48.6796236, lng: 17.0805032 },
    { lat: 48.6795446, lng: 17.0805578 },
    { lat: 48.6795506, lng: 17.0807125 },
    { lat: 48.6794525, lng: 17.080818 },
    { lat: 48.6795086, lng: 17.0808766 },
    { lat: 48.6793824, lng: 17.081368 },
    { lat: 48.6792112, lng: 17.0817619 },
    { lat: 48.6791867, lng: 17.0820513 },
    { lat: 48.6789198, lng: 17.0827052 },
    { lat: 48.6787815, lng: 17.0839729 },
    { lat: 48.6786586, lng: 17.0840607 },
    { lat: 48.6785695, lng: 17.0845657 },
    { lat: 48.6784909, lng: 17.0845906 },
    { lat: 48.6784885, lng: 17.0846883 },
    { lat: 48.6783855, lng: 17.0847688 },
    { lat: 48.6783722, lng: 17.0848742 },
    { lat: 48.6782994, lng: 17.0848829 },
    { lat: 48.6782612, lng: 17.0850532 },
    { lat: 48.6782787, lng: 17.085264 },
    { lat: 48.6781065, lng: 17.0857767 },
    { lat: 48.6779058, lng: 17.085933 },
    { lat: 48.6779022, lng: 17.0860648 },
    { lat: 48.6777337, lng: 17.0864643 },
    { lat: 48.6776108, lng: 17.0865193 },
    { lat: 48.6775999, lng: 17.0866429 },
    { lat: 48.677469, lng: 17.086865 },
    { lat: 48.6774923, lng: 17.0869573 },
    { lat: 48.677447, lng: 17.0869563 },
    { lat: 48.6774332, lng: 17.0871869 },
    { lat: 48.6773874, lng: 17.087229 },
    { lat: 48.6774369, lng: 17.0873272 },
    { lat: 48.6774025, lng: 17.087621 },
    { lat: 48.6772571, lng: 17.0882122 },
    { lat: 48.6771161, lng: 17.0883851 },
    { lat: 48.6771577, lng: 17.0884477 },
    { lat: 48.6770313, lng: 17.0887539 },
    { lat: 48.676938, lng: 17.0892459 },
    { lat: 48.6768494, lng: 17.0892738 },
    { lat: 48.6768181, lng: 17.0894809 },
    { lat: 48.6768598, lng: 17.0895121 },
    { lat: 48.676806, lng: 17.0896355 },
    { lat: 48.6766663, lng: 17.0897088 },
    { lat: 48.6765963, lng: 17.0898615 },
    { lat: 48.6767383, lng: 17.0902583 },
    { lat: 48.6766562, lng: 17.0904304 },
    { lat: 48.676521, lng: 17.0905366 },
    { lat: 48.6765162, lng: 17.0907004 },
    { lat: 48.6764535, lng: 17.0908455 },
    { lat: 48.6763312, lng: 17.0909429 },
    { lat: 48.6764076, lng: 17.0909866 },
    { lat: 48.6763942, lng: 17.091073 },
    { lat: 48.6762446, lng: 17.0912844 },
    { lat: 48.6760881, lng: 17.0918791 },
    { lat: 48.6759922, lng: 17.0919496 },
    { lat: 48.675929, lng: 17.0921502 },
    { lat: 48.6758196, lng: 17.0922378 },
    { lat: 48.6758286, lng: 17.0923225 },
    { lat: 48.675723, lng: 17.0925332 },
    { lat: 48.6757529, lng: 17.0926756 },
    { lat: 48.6752375, lng: 17.093612 },
    { lat: 48.6747976, lng: 17.0948829 },
    { lat: 48.6744609, lng: 17.0953186 },
    { lat: 48.6742714, lng: 17.0954413 },
    { lat: 48.6739395, lng: 17.095948 },
    { lat: 48.6735605, lng: 17.0962107 },
    { lat: 48.6740289, lng: 17.0992099 },
    { lat: 48.6737388, lng: 17.0990956 },
    { lat: 48.6732029, lng: 17.099497 },
    { lat: 48.6730508, lng: 17.0991006 },
    { lat: 48.6729197, lng: 17.0981583 },
    { lat: 48.6727199, lng: 17.098328 },
    { lat: 48.6726362, lng: 17.0984638 },
    { lat: 48.6726253, lng: 17.0987351 },
    { lat: 48.6726881, lng: 17.0994498 },
    { lat: 48.6723502, lng: 17.1000525 },
    { lat: 48.672428, lng: 17.1002504 },
    { lat: 48.6723695, lng: 17.1004059 },
    { lat: 48.6716556, lng: 17.1020397 },
    { lat: 48.6707043, lng: 17.1045978 },
    { lat: 48.6705432, lng: 17.104486 },
    { lat: 48.669746, lng: 17.106107 },
    { lat: 48.6701635, lng: 17.1072924 },
    { lat: 48.6707505, lng: 17.1081606 },
    { lat: 48.6696707, lng: 17.1099142 },
    { lat: 48.6695087, lng: 17.1098246 },
    { lat: 48.6691617, lng: 17.1107646 },
    { lat: 48.6676405, lng: 17.1108781 },
    { lat: 48.6674668, lng: 17.1108468 },
    { lat: 48.6671095, lng: 17.1106245 },
    { lat: 48.6658303, lng: 17.112625 },
    { lat: 48.6656022, lng: 17.1123597 },
    { lat: 48.6655379, lng: 17.112446 },
    { lat: 48.6654586, lng: 17.1123902 },
    { lat: 48.6653485, lng: 17.1125876 },
    { lat: 48.6651036, lng: 17.1127233 },
    { lat: 48.6642383, lng: 17.1136693 },
    { lat: 48.663486, lng: 17.1147159 },
    { lat: 48.6633598, lng: 17.1150225 },
    { lat: 48.6630616, lng: 17.115363 },
    { lat: 48.6629951, lng: 17.1154979 },
  ],
  Smrdáky: [
    { lat: 48.7295347, lng: 17.315169 },
    { lat: 48.729898, lng: 17.3152509 },
    { lat: 48.7313866, lng: 17.3151938 },
    { lat: 48.7321147, lng: 17.3153367 },
    { lat: 48.732316, lng: 17.3153115 },
    { lat: 48.7326528, lng: 17.3153092 },
    { lat: 48.7334876, lng: 17.3149426 },
    { lat: 48.7336646, lng: 17.3150429 },
    { lat: 48.7337874, lng: 17.3150388 },
    { lat: 48.7341348, lng: 17.3148385 },
    { lat: 48.735718, lng: 17.3134651 },
    { lat: 48.736534, lng: 17.312979 },
    { lat: 48.73651, lng: 17.31277 },
    { lat: 48.736485, lng: 17.312535 },
    { lat: 48.736448, lng: 17.312362 },
    { lat: 48.73642, lng: 17.312172 },
    { lat: 48.736353, lng: 17.311857 },
    { lat: 48.736286, lng: 17.311508 },
    { lat: 48.736226, lng: 17.311175 },
    { lat: 48.736158, lng: 17.310823 },
    { lat: 48.736104, lng: 17.310558 },
    { lat: 48.736041, lng: 17.310225 },
    { lat: 48.735974, lng: 17.309897 },
    { lat: 48.735913, lng: 17.309564 },
    { lat: 48.73585, lng: 17.309219 },
    { lat: 48.735802, lng: 17.308949 },
    { lat: 48.735745, lng: 17.308585 },
    { lat: 48.735752, lng: 17.308426 },
    { lat: 48.735742, lng: 17.308203 },
    { lat: 48.735697, lng: 17.307994 },
    { lat: 48.735664, lng: 17.307759 },
    { lat: 48.735616, lng: 17.307454 },
    { lat: 48.735563, lng: 17.307088 },
    { lat: 48.735529, lng: 17.306767 },
    { lat: 48.735498, lng: 17.306469 },
    { lat: 48.735458, lng: 17.306199 },
    { lat: 48.735416, lng: 17.305914 },
    { lat: 48.735363, lng: 17.305564 },
    { lat: 48.735314, lng: 17.30524 },
    { lat: 48.73528, lng: 17.304996 },
    { lat: 48.735276, lng: 17.304812 },
    { lat: 48.735261, lng: 17.304393 },
    { lat: 48.735249, lng: 17.303979 },
    { lat: 48.735231, lng: 17.303606 },
    { lat: 48.73521, lng: 17.303235 },
    { lat: 48.735195, lng: 17.302842 },
    { lat: 48.735176, lng: 17.302542 },
    { lat: 48.735157, lng: 17.302222 },
    { lat: 48.735142, lng: 17.301938 },
    { lat: 48.735146, lng: 17.301616 },
    { lat: 48.735146, lng: 17.30129 },
    { lat: 48.735153, lng: 17.300939 },
    { lat: 48.735177, lng: 17.300567 },
    { lat: 48.735198, lng: 17.300193 },
    { lat: 48.735198, lng: 17.299915 },
    { lat: 48.735199, lng: 17.299608 },
    { lat: 48.735199, lng: 17.299311 },
    { lat: 48.735199, lng: 17.298988 },
    { lat: 48.735195, lng: 17.298712 },
    { lat: 48.73521, lng: 17.298515 },
    { lat: 48.735229, lng: 17.298158 },
    { lat: 48.735267, lng: 17.297923 },
    { lat: 48.735319, lng: 17.297747 },
    { lat: 48.73541, lng: 17.297428 },
    { lat: 48.735408, lng: 17.297333 },
    { lat: 48.735416, lng: 17.296977 },
    { lat: 48.735416, lng: 17.29676 },
    { lat: 48.735402, lng: 17.296423 },
    { lat: 48.73539, lng: 17.296097 },
    { lat: 48.735394, lng: 17.295859 },
    { lat: 48.735405, lng: 17.295542 },
    { lat: 48.735417, lng: 17.295277 },
    { lat: 48.735426, lng: 17.294871 },
    { lat: 48.735422, lng: 17.294779 },
    { lat: 48.735371, lng: 17.294283 },
    { lat: 48.735364, lng: 17.294172 },
    { lat: 48.735345, lng: 17.293796 },
    { lat: 48.735309, lng: 17.293578 },
    { lat: 48.735232, lng: 17.293171 },
    { lat: 48.735165, lng: 17.292779 },
    { lat: 48.735099, lng: 17.292406 },
    { lat: 48.735017, lng: 17.291938 },
    { lat: 48.734948, lng: 17.291522 },
    { lat: 48.734857, lng: 17.291237 },
    { lat: 48.734587, lng: 17.290776 },
    { lat: 48.734503, lng: 17.290613 },
    { lat: 48.7344486, lng: 17.2906566 },
    { lat: 48.7336921, lng: 17.2911635 },
    { lat: 48.7335189, lng: 17.291349 },
    { lat: 48.7333017, lng: 17.2914115 },
    { lat: 48.7327114, lng: 17.2913836 },
    { lat: 48.732093, lng: 17.2914797 },
    { lat: 48.7306711, lng: 17.2919683 },
    { lat: 48.7286536, lng: 17.2927822 },
    { lat: 48.725951, lng: 17.293687 },
    { lat: 48.7231777, lng: 17.2943962 },
    { lat: 48.7216231, lng: 17.2948762 },
    { lat: 48.7211369, lng: 17.2948498 },
    { lat: 48.720311, lng: 17.2945208 },
    { lat: 48.7189964, lng: 17.2933508 },
    { lat: 48.7183592, lng: 17.2929366 },
    { lat: 48.7176919, lng: 17.292664 },
    { lat: 48.7170849, lng: 17.292591 },
    { lat: 48.7164655, lng: 17.2923763 },
    { lat: 48.7158767, lng: 17.2916144 },
    { lat: 48.7155541, lng: 17.2910524 },
    { lat: 48.7152838, lng: 17.2926171 },
    { lat: 48.7152364, lng: 17.2926174 },
    { lat: 48.7151689, lng: 17.292934 },
    { lat: 48.7148098, lng: 17.2955747 },
    { lat: 48.7145793, lng: 17.296957 },
    { lat: 48.7142838, lng: 17.2982217 },
    { lat: 48.7138436, lng: 17.299489 },
    { lat: 48.7135598, lng: 17.3001914 },
    { lat: 48.7122841, lng: 17.3026411 },
    { lat: 48.7117693, lng: 17.3037667 },
    { lat: 48.7106345, lng: 17.3042735 },
    { lat: 48.7111617, lng: 17.3062053 },
    { lat: 48.7113922, lng: 17.3072618 },
    { lat: 48.7123213, lng: 17.3117155 },
    { lat: 48.7124048, lng: 17.3125078 },
    { lat: 48.7124033, lng: 17.31404 },
    { lat: 48.712295, lng: 17.3151829 },
    { lat: 48.7120886, lng: 17.3152605 },
    { lat: 48.7121446, lng: 17.3158384 },
    { lat: 48.711857, lng: 17.3159003 },
    { lat: 48.7119169, lng: 17.3161431 },
    { lat: 48.7118054, lng: 17.316198 },
    { lat: 48.7118345, lng: 17.316262 },
    { lat: 48.7113846, lng: 17.317073 },
    { lat: 48.7112529, lng: 17.3175827 },
    { lat: 48.7109494, lng: 17.318315 },
    { lat: 48.7108877, lng: 17.318493 },
    { lat: 48.7108211, lng: 17.3186335 },
    { lat: 48.710542, lng: 17.3203633 },
    { lat: 48.7118717, lng: 17.3210408 },
    { lat: 48.7121405, lng: 17.3210751 },
    { lat: 48.7126125, lng: 17.321003 },
    { lat: 48.7130455, lng: 17.3208327 },
    { lat: 48.7132259, lng: 17.3207786 },
    { lat: 48.7148535, lng: 17.3204463 },
    { lat: 48.718031, lng: 17.3195454 },
    { lat: 48.7196186, lng: 17.3189836 },
    { lat: 48.7203305, lng: 17.3188289 },
    { lat: 48.7208668, lng: 17.3187736 },
    { lat: 48.7215028, lng: 17.3188452 },
    { lat: 48.7225642, lng: 17.3190626 },
    { lat: 48.7232648, lng: 17.3193961 },
    { lat: 48.7238708, lng: 17.3194543 },
    { lat: 48.7250098, lng: 17.3192369 },
    { lat: 48.7262699, lng: 17.3187954 },
    { lat: 48.7268103, lng: 17.3184211 },
    { lat: 48.7273852, lng: 17.3178839 },
    { lat: 48.7276625, lng: 17.3175438 },
    { lat: 48.7289903, lng: 17.3155801 },
    { lat: 48.729433, lng: 17.315265 },
    { lat: 48.7295347, lng: 17.315169 },
  ],
  Sobotište: [
    { lat: 48.786385, lng: 17.419484 },
    { lat: 48.786375, lng: 17.419453 },
    { lat: 48.786323, lng: 17.419243 },
    { lat: 48.786278, lng: 17.419047 },
    { lat: 48.786198, lng: 17.418852 },
    { lat: 48.786277, lng: 17.41869 },
    { lat: 48.786413, lng: 17.418417 },
    { lat: 48.7865, lng: 17.418198 },
    { lat: 48.786578, lng: 17.417968 },
    { lat: 48.786657, lng: 17.417725 },
    { lat: 48.786657, lng: 17.417727 },
    { lat: 48.786782, lng: 17.4175 },
    { lat: 48.786883, lng: 17.417316 },
    { lat: 48.786965, lng: 17.41705 },
    { lat: 48.787007, lng: 17.416918 },
    { lat: 48.787104, lng: 17.41673 },
    { lat: 48.7872, lng: 17.416544 },
    { lat: 48.787191, lng: 17.416521 },
    { lat: 48.787168, lng: 17.416469 },
    { lat: 48.787142, lng: 17.41641 },
    { lat: 48.787095, lng: 17.416306 },
    { lat: 48.786995, lng: 17.416183 },
    { lat: 48.787095, lng: 17.415993 },
    { lat: 48.787213, lng: 17.415785 },
    { lat: 48.787175, lng: 17.415541 },
    { lat: 48.787116, lng: 17.415124 },
    { lat: 48.787041, lng: 17.415019 },
    { lat: 48.786989, lng: 17.414948 },
    { lat: 48.786902, lng: 17.414823 },
    { lat: 48.786838, lng: 17.414737 },
    { lat: 48.78679, lng: 17.414628 },
    { lat: 48.786737, lng: 17.414502 },
    { lat: 48.786581, lng: 17.414506 },
    { lat: 48.786508, lng: 17.414399 },
    { lat: 48.78645, lng: 17.414308 },
    { lat: 48.786328, lng: 17.41422 },
    { lat: 48.786103, lng: 17.414072 },
    { lat: 48.786018, lng: 17.413934 },
    { lat: 48.785917, lng: 17.413769 },
    { lat: 48.78584, lng: 17.413535 },
    { lat: 48.785804, lng: 17.413432 },
    { lat: 48.785691, lng: 17.413264 },
    { lat: 48.785592, lng: 17.413117 },
    { lat: 48.785599, lng: 17.4129 },
    { lat: 48.785618, lng: 17.412554 },
    { lat: 48.785658, lng: 17.412289 },
    { lat: 48.78568, lng: 17.412162 },
    { lat: 48.785708, lng: 17.411993 },
    { lat: 48.785793, lng: 17.411859 },
    { lat: 48.785888, lng: 17.411702 },
    { lat: 48.785997, lng: 17.411582 },
    { lat: 48.786137, lng: 17.411431 },
    { lat: 48.78635, lng: 17.411204 },
    { lat: 48.786509, lng: 17.411149 },
    { lat: 48.786602, lng: 17.411111 },
    { lat: 48.786746, lng: 17.411078 },
    { lat: 48.786924, lng: 17.411064 },
    { lat: 48.787113, lng: 17.411051 },
    { lat: 48.787286, lng: 17.411044 },
    { lat: 48.78744, lng: 17.411034 },
    { lat: 48.787563, lng: 17.411016 },
    { lat: 48.787672, lng: 17.410976 },
    { lat: 48.787849, lng: 17.410915 },
    { lat: 48.787841, lng: 17.410909 },
    { lat: 48.787853, lng: 17.410892 },
    { lat: 48.787748, lng: 17.410738 },
    { lat: 48.787611, lng: 17.410555 },
    { lat: 48.787499, lng: 17.410404 },
    { lat: 48.787373, lng: 17.410239 },
    { lat: 48.787255, lng: 17.410078 },
    { lat: 48.78727, lng: 17.410066 },
    { lat: 48.787165, lng: 17.409884 },
    { lat: 48.787093, lng: 17.40975 },
    { lat: 48.787035, lng: 17.409627 },
    { lat: 48.786927, lng: 17.409396 },
    { lat: 48.786861, lng: 17.409235 },
    { lat: 48.78675, lng: 17.40892 },
    { lat: 48.786651, lng: 17.408647 },
    { lat: 48.786554, lng: 17.408393 },
    { lat: 48.786436, lng: 17.408168 },
    { lat: 48.786335, lng: 17.407963 },
    { lat: 48.786166, lng: 17.407729 },
    { lat: 48.786041, lng: 17.407544 },
    { lat: 48.78598, lng: 17.40746 },
    { lat: 48.785869, lng: 17.407301 },
    { lat: 48.785805, lng: 17.407184 },
    { lat: 48.785787, lng: 17.407152 },
    { lat: 48.785678, lng: 17.406953 },
    { lat: 48.785574, lng: 17.406815 },
    { lat: 48.785411, lng: 17.406594 },
    { lat: 48.785312, lng: 17.406466 },
    { lat: 48.785211, lng: 17.406331 },
    { lat: 48.785086, lng: 17.406178 },
    { lat: 48.785019, lng: 17.406096 },
    { lat: 48.784902, lng: 17.405952 },
    { lat: 48.784764, lng: 17.405784 },
    { lat: 48.784742, lng: 17.405756 },
    { lat: 48.784623, lng: 17.405617 },
    { lat: 48.784491, lng: 17.405473 },
    { lat: 48.784346, lng: 17.405308 },
    { lat: 48.78422, lng: 17.405165 },
    { lat: 48.784051, lng: 17.404974 },
    { lat: 48.783907, lng: 17.404815 },
    { lat: 48.783773, lng: 17.404649 },
    { lat: 48.783582, lng: 17.404413 },
    { lat: 48.783384, lng: 17.404168 },
    { lat: 48.783295, lng: 17.404058 },
    { lat: 48.783202, lng: 17.403894 },
    { lat: 48.783061, lng: 17.403632 },
    { lat: 48.782947, lng: 17.403407 },
    { lat: 48.782797, lng: 17.40323 },
    { lat: 48.7826, lng: 17.403101 },
    { lat: 48.782481, lng: 17.403041 },
    { lat: 48.782294, lng: 17.402964 },
    { lat: 48.782139, lng: 17.402876 },
    { lat: 48.782063, lng: 17.402831 },
    { lat: 48.782023, lng: 17.402783 },
    { lat: 48.781927, lng: 17.402715 },
    { lat: 48.781802, lng: 17.402622 },
    { lat: 48.781787, lng: 17.402613 },
    { lat: 48.781779, lng: 17.402641 },
    { lat: 48.781665, lng: 17.402598 },
    { lat: 48.781527, lng: 17.402547 },
    { lat: 48.781416, lng: 17.402508 },
    { lat: 48.7811126, lng: 17.402406 },
    { lat: 48.7808436, lng: 17.4022079 },
    { lat: 48.7804676, lng: 17.4018422 },
    { lat: 48.7801668, lng: 17.4016635 },
    { lat: 48.7797257, lng: 17.401532 },
    { lat: 48.7793505, lng: 17.4013363 },
    { lat: 48.7789714, lng: 17.4011143 },
    { lat: 48.7788031, lng: 17.4011137 },
    { lat: 48.7784531, lng: 17.4014147 },
    { lat: 48.7782602, lng: 17.4015033 },
    { lat: 48.7781209, lng: 17.401516 },
    { lat: 48.7779345, lng: 17.4014811 },
    { lat: 48.7777455, lng: 17.4014055 },
    { lat: 48.7774956, lng: 17.4012963 },
    { lat: 48.7773546, lng: 17.4011581 },
    { lat: 48.7771447, lng: 17.4008457 },
    { lat: 48.776979, lng: 17.4005009 },
    { lat: 48.776626, lng: 17.39972 },
    { lat: 48.776559, lng: 17.3995591 },
    { lat: 48.7764485, lng: 17.3992284 },
    { lat: 48.7764007, lng: 17.3990629 },
    { lat: 48.7762401, lng: 17.3984491 },
    { lat: 48.7761263, lng: 17.3976817 },
    { lat: 48.7760833, lng: 17.3974341 },
    { lat: 48.7760056, lng: 17.3972268 },
    { lat: 48.7758808, lng: 17.3969906 },
    { lat: 48.7756888, lng: 17.3966788 },
    { lat: 48.7755497, lng: 17.3964995 },
    { lat: 48.7753427, lng: 17.3961967 },
    { lat: 48.7752846, lng: 17.3960996 },
    { lat: 48.7750443, lng: 17.3956685 },
    { lat: 48.7749281, lng: 17.3953899 },
    { lat: 48.774749, lng: 17.3950278 },
    { lat: 48.7745793, lng: 17.3946769 },
    { lat: 48.7744273, lng: 17.3944737 },
    { lat: 48.7742892, lng: 17.3942599 },
    { lat: 48.7740907, lng: 17.3938792 },
    { lat: 48.7737671, lng: 17.3935184 },
    { lat: 48.7736052, lng: 17.3933902 },
    { lat: 48.7732394, lng: 17.3930435 },
    { lat: 48.7731224, lng: 17.392943 },
    { lat: 48.7729759, lng: 17.3927927 },
    { lat: 48.7726314, lng: 17.392266 },
    { lat: 48.7723765, lng: 17.3918715 },
    { lat: 48.7723247, lng: 17.3918021 },
    { lat: 48.7721513, lng: 17.3916194 },
    { lat: 48.7720151, lng: 17.3914354 },
    { lat: 48.7719658, lng: 17.3913349 },
    { lat: 48.7718438, lng: 17.3910213 },
    { lat: 48.7716954, lng: 17.3903325 },
    { lat: 48.7716439, lng: 17.3900404 },
    { lat: 48.7715588, lng: 17.3896684 },
    { lat: 48.7714792, lng: 17.3892043 },
    { lat: 48.7714751, lng: 17.388599 },
    { lat: 48.7713746, lng: 17.3877946 },
    { lat: 48.7713359, lng: 17.387579 },
    { lat: 48.7712408, lng: 17.3874001 },
    { lat: 48.771197, lng: 17.3872648 },
    { lat: 48.7710873, lng: 17.3867897 },
    { lat: 48.7706736, lng: 17.3858937 },
    { lat: 48.7703397, lng: 17.3849753 },
    { lat: 48.7702927, lng: 17.3849983 },
    { lat: 48.7701524, lng: 17.3847867 },
    { lat: 48.7700349, lng: 17.3848725 },
    { lat: 48.76992, lng: 17.3847022 },
    { lat: 48.7696525, lng: 17.3844925 },
    { lat: 48.7696067, lng: 17.3843632 },
    { lat: 48.7696302, lng: 17.3843053 },
    { lat: 48.7691751, lng: 17.3839079 },
    { lat: 48.7692088, lng: 17.3837987 },
    { lat: 48.768673, lng: 17.3835138 },
    { lat: 48.7685279, lng: 17.3835574 },
    { lat: 48.7683446, lng: 17.3833257 },
    { lat: 48.7683421, lng: 17.3830919 },
    { lat: 48.768232, lng: 17.383261 },
    { lat: 48.7681937, lng: 17.3832098 },
    { lat: 48.7682421, lng: 17.3829803 },
    { lat: 48.7681633, lng: 17.3826521 },
    { lat: 48.7675135, lng: 17.3823125 },
    { lat: 48.7673081, lng: 17.3826136 },
    { lat: 48.767009, lng: 17.3824766 },
    { lat: 48.7669616, lng: 17.3822846 },
    { lat: 48.7666341, lng: 17.382323 },
    { lat: 48.7665573, lng: 17.3820239 },
    { lat: 48.7667638, lng: 17.3819347 },
    { lat: 48.7668544, lng: 17.3817462 },
    { lat: 48.7666734, lng: 17.3816892 },
    { lat: 48.7654765, lng: 17.3815218 },
    { lat: 48.7653318, lng: 17.3816918 },
    { lat: 48.7634402, lng: 17.3821538 },
    { lat: 48.7628586, lng: 17.3819262 },
    { lat: 48.761888, lng: 17.3817475 },
    { lat: 48.7611187, lng: 17.3813257 },
    { lat: 48.760891, lng: 17.381315 },
    { lat: 48.759453, lng: 17.3820812 },
    { lat: 48.7589488, lng: 17.3824941 },
    { lat: 48.758702, lng: 17.3817892 },
    { lat: 48.7582081, lng: 17.381365 },
    { lat: 48.7577573, lng: 17.3812065 },
    { lat: 48.7573916, lng: 17.3809452 },
    { lat: 48.7571687, lng: 17.3814113 },
    { lat: 48.756284, lng: 17.382543 },
    { lat: 48.7553846, lng: 17.3834123 },
    { lat: 48.7550864, lng: 17.3835366 },
    { lat: 48.7546874, lng: 17.3840903 },
    { lat: 48.7544431, lng: 17.3842435 },
    { lat: 48.7538509, lng: 17.384079 },
    { lat: 48.7522623, lng: 17.3842813 },
    { lat: 48.7505803, lng: 17.3836315 },
    { lat: 48.749968, lng: 17.3832106 },
    { lat: 48.7495282, lng: 17.3826095 },
    { lat: 48.7492257, lng: 17.3820905 },
    { lat: 48.7490334, lng: 17.3819267 },
    { lat: 48.7485191, lng: 17.3816978 },
    { lat: 48.7483544, lng: 17.3817154 },
    { lat: 48.7481251, lng: 17.3816111 },
    { lat: 48.748044, lng: 17.3816528 },
    { lat: 48.7477369, lng: 17.3812337 },
    { lat: 48.7473014, lng: 17.3808926 },
    { lat: 48.7469603, lng: 17.3800726 },
    { lat: 48.746731, lng: 17.3799494 },
    { lat: 48.746624, lng: 17.3797261 },
    { lat: 48.7464504, lng: 17.3795922 },
    { lat: 48.7463098, lng: 17.3792003 },
    { lat: 48.7454597, lng: 17.3781411 },
    { lat: 48.7453469, lng: 17.3778836 },
    { lat: 48.7451401, lng: 17.3776358 },
    { lat: 48.7451131, lng: 17.3774955 },
    { lat: 48.7449322, lng: 17.3771626 },
    { lat: 48.7446951, lng: 17.3769543 },
    { lat: 48.744462, lng: 17.3765815 },
    { lat: 48.744256, lng: 17.3765401 },
    { lat: 48.7430988, lng: 17.3767056 },
    { lat: 48.7421639, lng: 17.3770786 },
    { lat: 48.7422553, lng: 17.3774262 },
    { lat: 48.7413272, lng: 17.3784277 },
    { lat: 48.7400517, lng: 17.3794709 },
    { lat: 48.7400482, lng: 17.3792052 },
    { lat: 48.7395073, lng: 17.3797171 },
    { lat: 48.7394637, lng: 17.3796162 },
    { lat: 48.73909, lng: 17.3801077 },
    { lat: 48.7372195, lng: 17.3787994 },
    { lat: 48.7366835, lng: 17.3784937 },
    { lat: 48.7365692, lng: 17.3785579 },
    { lat: 48.7364554, lng: 17.3785987 },
    { lat: 48.7358228, lng: 17.3781612 },
    { lat: 48.7344986, lng: 17.3775767 },
    { lat: 48.7340263, lng: 17.3774175 },
    { lat: 48.7336458, lng: 17.3773876 },
    { lat: 48.7333207, lng: 17.3789939 },
    { lat: 48.7324504, lng: 17.3808522 },
    { lat: 48.7318607, lng: 17.382513 },
    { lat: 48.7316217, lng: 17.3826045 },
    { lat: 48.7313111, lng: 17.3828486 },
    { lat: 48.7312136, lng: 17.3830183 },
    { lat: 48.7305751, lng: 17.3844829 },
    { lat: 48.7302212, lng: 17.3855946 },
    { lat: 48.7300539, lng: 17.3858558 },
    { lat: 48.7298197, lng: 17.386074 },
    { lat: 48.7288625, lng: 17.3868018 },
    { lat: 48.7282744, lng: 17.3876818 },
    { lat: 48.7280562, lng: 17.3874511 },
    { lat: 48.7278706, lng: 17.3871472 },
    { lat: 48.7275898, lng: 17.3873099 },
    { lat: 48.7274817, lng: 17.3875606 },
    { lat: 48.727344, lng: 17.3881211 },
    { lat: 48.7272264, lng: 17.3883639 },
    { lat: 48.7263245, lng: 17.3899116 },
    { lat: 48.725936, lng: 17.390331 },
    { lat: 48.7256857, lng: 17.3907068 },
    { lat: 48.7260386, lng: 17.3916585 },
    { lat: 48.725534, lng: 17.3922417 },
    { lat: 48.7254653, lng: 17.3923842 },
    { lat: 48.7250241, lng: 17.3921266 },
    { lat: 48.7249002, lng: 17.3926229 },
    { lat: 48.7247631, lng: 17.3926331 },
    { lat: 48.7246985, lng: 17.3929445 },
    { lat: 48.7244075, lng: 17.3928816 },
    { lat: 48.7241784, lng: 17.3928993 },
    { lat: 48.7240886, lng: 17.3929648 },
    { lat: 48.7231101, lng: 17.3929049 },
    { lat: 48.7221625, lng: 17.393621 },
    { lat: 48.7219551, lng: 17.3937157 },
    { lat: 48.7219207, lng: 17.3940774 },
    { lat: 48.7215915, lng: 17.3943667 },
    { lat: 48.7216595, lng: 17.3938444 },
    { lat: 48.7219489, lng: 17.392623 },
    { lat: 48.7216601, lng: 17.3924721 },
    { lat: 48.7220475, lng: 17.3912992 },
    { lat: 48.7218372, lng: 17.3911056 },
    { lat: 48.7216592, lng: 17.3910253 },
    { lat: 48.7213311, lng: 17.3920979 },
    { lat: 48.7211259, lng: 17.3929963 },
    { lat: 48.7209142, lng: 17.3929535 },
    { lat: 48.7200329, lng: 17.3932123 },
    { lat: 48.7195898, lng: 17.3934866 },
    { lat: 48.7193104, lng: 17.3935689 },
    { lat: 48.7178271, lng: 17.3938082 },
    { lat: 48.7177098, lng: 17.3938827 },
    { lat: 48.717786, lng: 17.3947803 },
    { lat: 48.7159464, lng: 17.3948736 },
    { lat: 48.714371, lng: 17.394632 },
    { lat: 48.7135026, lng: 17.3945834 },
    { lat: 48.7118513, lng: 17.3946729 },
    { lat: 48.710854, lng: 17.3947949 },
    { lat: 48.7103332, lng: 17.3947762 },
    { lat: 48.7099446, lng: 17.3946705 },
    { lat: 48.7082982, lng: 17.3939774 },
    { lat: 48.7073854, lng: 17.3936929 },
    { lat: 48.7073519, lng: 17.3952917 },
    { lat: 48.7073308, lng: 17.3959264 },
    { lat: 48.7078325, lng: 17.3961424 },
    { lat: 48.7070438, lng: 17.3986375 },
    { lat: 48.7068125, lng: 17.3994977 },
    { lat: 48.7064152, lng: 17.4012012 },
    { lat: 48.7062219, lng: 17.4022836 },
    { lat: 48.706, lng: 17.4022226 },
    { lat: 48.7058963, lng: 17.4030142 },
    { lat: 48.7054285, lng: 17.4030652 },
    { lat: 48.7053101, lng: 17.4032404 },
    { lat: 48.7051809, lng: 17.4032186 },
    { lat: 48.7050571, lng: 17.4031003 },
    { lat: 48.7049504, lng: 17.4033402 },
    { lat: 48.7049851, lng: 17.4035257 },
    { lat: 48.7046722, lng: 17.4037892 },
    { lat: 48.7045615, lng: 17.4039814 },
    { lat: 48.704138, lng: 17.4043134 },
    { lat: 48.7040593, lng: 17.4045135 },
    { lat: 48.7043474, lng: 17.4050572 },
    { lat: 48.7044323, lng: 17.4053211 },
    { lat: 48.7044087, lng: 17.4054654 },
    { lat: 48.705264, lng: 17.4057132 },
    { lat: 48.7054742, lng: 17.4058385 },
    { lat: 48.7058052, lng: 17.4058219 },
    { lat: 48.706028, lng: 17.4059154 },
    { lat: 48.7061016, lng: 17.4063551 },
    { lat: 48.7062905, lng: 17.4069538 },
    { lat: 48.7062939, lng: 17.4073406 },
    { lat: 48.7061652, lng: 17.4079174 },
    { lat: 48.7066055, lng: 17.408036 },
    { lat: 48.7070785, lng: 17.4083498 },
    { lat: 48.7073709, lng: 17.4086447 },
    { lat: 48.7073524, lng: 17.4090772 },
    { lat: 48.7081259, lng: 17.4094226 },
    { lat: 48.7079929, lng: 17.4102153 },
    { lat: 48.7076233, lng: 17.4115804 },
    { lat: 48.7074448, lng: 17.4114915 },
    { lat: 48.7076892, lng: 17.4105161 },
    { lat: 48.7065366, lng: 17.4107343 },
    { lat: 48.7062194, lng: 17.4108618 },
    { lat: 48.7055946, lng: 17.4108915 },
    { lat: 48.7055591, lng: 17.4117439 },
    { lat: 48.7050668, lng: 17.4146374 },
    { lat: 48.7041588, lng: 17.4145192 },
    { lat: 48.7041269, lng: 17.4148096 },
    { lat: 48.7034742, lng: 17.414601 },
    { lat: 48.7032047, lng: 17.4171158 },
    { lat: 48.7031369, lng: 17.4193256 },
    { lat: 48.7034214, lng: 17.4193227 },
    { lat: 48.7032567, lng: 17.42101 },
    { lat: 48.7039815, lng: 17.4219537 },
    { lat: 48.704197, lng: 17.4191422 },
    { lat: 48.7049182, lng: 17.4191181 },
    { lat: 48.7053509, lng: 17.4189965 },
    { lat: 48.7055583, lng: 17.419024 },
    { lat: 48.7059416, lng: 17.4188747 },
    { lat: 48.7060877, lng: 17.4189676 },
    { lat: 48.7061006, lng: 17.4194667 },
    { lat: 48.7057934, lng: 17.4223959 },
    { lat: 48.7063379, lng: 17.4225562 },
    { lat: 48.7064027, lng: 17.4249318 },
    { lat: 48.7054555, lng: 17.4249726 },
    { lat: 48.7054339, lng: 17.4257618 },
    { lat: 48.7058828, lng: 17.4256991 },
    { lat: 48.7068387, lng: 17.4257057 },
    { lat: 48.7070126, lng: 17.4258636 },
    { lat: 48.7093483, lng: 17.4260015 },
    { lat: 48.7105623, lng: 17.4261937 },
    { lat: 48.7105873, lng: 17.4266346 },
    { lat: 48.7114441, lng: 17.4268381 },
    { lat: 48.7115783, lng: 17.4269779 },
    { lat: 48.7120723, lng: 17.4271915 },
    { lat: 48.7126559, lng: 17.4271866 },
    { lat: 48.7131451, lng: 17.4271023 },
    { lat: 48.7141008, lng: 17.4275438 },
    { lat: 48.7148495, lng: 17.4281056 },
    { lat: 48.7155403, lng: 17.428395 },
    { lat: 48.7158441, lng: 17.4284681 },
    { lat: 48.7170031, lng: 17.4284156 },
    { lat: 48.7171254, lng: 17.4285443 },
    { lat: 48.7171846, lng: 17.4284111 },
    { lat: 48.7173574, lng: 17.4283754 },
    { lat: 48.7174006, lng: 17.4281855 },
    { lat: 48.7173702, lng: 17.4278567 },
    { lat: 48.7177155, lng: 17.4265308 },
    { lat: 48.7178306, lng: 17.4262339 },
    { lat: 48.7190852, lng: 17.4279342 },
    { lat: 48.7195889, lng: 17.4284576 },
    { lat: 48.7201645, lng: 17.4288553 },
    { lat: 48.7210023, lng: 17.4291676 },
    { lat: 48.7212358, lng: 17.4291991 },
    { lat: 48.7215051, lng: 17.4291738 },
    { lat: 48.7224817, lng: 17.4286924 },
    { lat: 48.7230741, lng: 17.4283135 },
    { lat: 48.7242604, lng: 17.42788 },
    { lat: 48.7260708, lng: 17.4275082 },
    { lat: 48.7271748, lng: 17.4273596 },
    { lat: 48.7286475, lng: 17.4269537 },
    { lat: 48.728969, lng: 17.4269322 },
    { lat: 48.7303964, lng: 17.4261003 },
    { lat: 48.7307165, lng: 17.4260215 },
    { lat: 48.7311431, lng: 17.426016 },
    { lat: 48.731584, lng: 17.4261108 },
    { lat: 48.7322747, lng: 17.4264183 },
    { lat: 48.7325803, lng: 17.4264435 },
    { lat: 48.732946, lng: 17.4263898 },
    { lat: 48.734033, lng: 17.4260458 },
    { lat: 48.734139, lng: 17.426358 },
    { lat: 48.7344847, lng: 17.4267287 },
    { lat: 48.7346862, lng: 17.4267336 },
    { lat: 48.735073, lng: 17.4267431 },
    { lat: 48.735208, lng: 17.4268403 },
    { lat: 48.7352723, lng: 17.426964 },
    { lat: 48.7354055, lng: 17.42749 },
    { lat: 48.7358009, lng: 17.4284052 },
    { lat: 48.7360061, lng: 17.4287149 },
    { lat: 48.7361528, lng: 17.429204 },
    { lat: 48.7368895, lng: 17.4306505 },
    { lat: 48.7374519, lng: 17.4329549 },
    { lat: 48.7375662, lng: 17.4339779 },
    { lat: 48.7376625, lng: 17.4343499 },
    { lat: 48.7379378, lng: 17.4348369 },
    { lat: 48.7384363, lng: 17.4353647 },
    { lat: 48.738642, lng: 17.4355447 },
    { lat: 48.7387962, lng: 17.4356996 },
    { lat: 48.7392143, lng: 17.4363213 },
    { lat: 48.7392677, lng: 17.4362913 },
    { lat: 48.7390635, lng: 17.4360668 },
    { lat: 48.7388368, lng: 17.4356646 },
    { lat: 48.7394045, lng: 17.4350263 },
    { lat: 48.7398882, lng: 17.4343245 },
    { lat: 48.7400579, lng: 17.4339607 },
    { lat: 48.7407625, lng: 17.4330281 },
    { lat: 48.7412658, lng: 17.4320715 },
    { lat: 48.7412951, lng: 17.4321226 },
    { lat: 48.7416537, lng: 17.431637 },
    { lat: 48.7419417, lng: 17.4310064 },
    { lat: 48.7421247, lng: 17.4299686 },
    { lat: 48.7422989, lng: 17.4297433 },
    { lat: 48.7424574, lng: 17.4293117 },
    { lat: 48.742795, lng: 17.4292213 },
    { lat: 48.7428078, lng: 17.4291031 },
    { lat: 48.742939, lng: 17.4288609 },
    { lat: 48.742871, lng: 17.4285764 },
    { lat: 48.7430598, lng: 17.428384 },
    { lat: 48.7432179, lng: 17.4280523 },
    { lat: 48.7433159, lng: 17.4280115 },
    { lat: 48.743759, lng: 17.4274203 },
    { lat: 48.7440325, lng: 17.4280744 },
    { lat: 48.7440597, lng: 17.4284086 },
    { lat: 48.7442206, lng: 17.4287514 },
    { lat: 48.7448777, lng: 17.4292575 },
    { lat: 48.7446841, lng: 17.4298829 },
    { lat: 48.7455414, lng: 17.4305135 },
    { lat: 48.7459725, lng: 17.4307365 },
    { lat: 48.7460104, lng: 17.4306264 },
    { lat: 48.7462758, lng: 17.4308522 },
    { lat: 48.7458497, lng: 17.43206 },
    { lat: 48.7456489, lng: 17.4328912 },
    { lat: 48.746585, lng: 17.432413 },
    { lat: 48.7471463, lng: 17.4323606 },
    { lat: 48.7473241, lng: 17.4322293 },
    { lat: 48.7474371, lng: 17.4319464 },
    { lat: 48.7481714, lng: 17.4290113 },
    { lat: 48.7492329, lng: 17.4267433 },
    { lat: 48.7500389, lng: 17.4271245 },
    { lat: 48.7493826, lng: 17.4297957 },
    { lat: 48.7491596, lng: 17.431017 },
    { lat: 48.7496607, lng: 17.4312398 },
    { lat: 48.7497964, lng: 17.4328587 },
    { lat: 48.7500152, lng: 17.4330152 },
    { lat: 48.7502337, lng: 17.4330568 },
    { lat: 48.7504725, lng: 17.4339591 },
    { lat: 48.7507178, lng: 17.4341418 },
    { lat: 48.7507152, lng: 17.4341901 },
    { lat: 48.7508343, lng: 17.4341615 },
    { lat: 48.7511074, lng: 17.4342793 },
    { lat: 48.7515635, lng: 17.4342234 },
    { lat: 48.7515769, lng: 17.4345306 },
    { lat: 48.7516284, lng: 17.4357103 },
    { lat: 48.7515635, lng: 17.4360205 },
    { lat: 48.7517396, lng: 17.4361228 },
    { lat: 48.751487, lng: 17.4369043 },
    { lat: 48.7511244, lng: 17.4375384 },
    { lat: 48.7508599, lng: 17.4377788 },
    { lat: 48.7507299, lng: 17.4375681 },
    { lat: 48.7499131, lng: 17.4384778 },
    { lat: 48.7500225, lng: 17.4388297 },
    { lat: 48.7503544, lng: 17.4391775 },
    { lat: 48.7496074, lng: 17.4394868 },
    { lat: 48.7497606, lng: 17.4387428 },
    { lat: 48.7494151, lng: 17.4387438 },
    { lat: 48.7488346, lng: 17.438567 },
    { lat: 48.7484322, lng: 17.4386174 },
    { lat: 48.7483669, lng: 17.4378432 },
    { lat: 48.7465378, lng: 17.438506 },
    { lat: 48.7466872, lng: 17.4394772 },
    { lat: 48.7468583, lng: 17.4411087 },
    { lat: 48.7468437, lng: 17.4424121 },
    { lat: 48.7469331, lng: 17.4433298 },
    { lat: 48.7469283, lng: 17.4439085 },
    { lat: 48.7479927, lng: 17.4437918 },
    { lat: 48.7479407, lng: 17.4441437 },
    { lat: 48.7491736, lng: 17.444454 },
    { lat: 48.749122, lng: 17.443594 },
    { lat: 48.7499099, lng: 17.4439413 },
    { lat: 48.7500892, lng: 17.4439544 },
    { lat: 48.7496757, lng: 17.4452572 },
    { lat: 48.7494032, lng: 17.4456225 },
    { lat: 48.74913, lng: 17.4457463 },
    { lat: 48.7489566, lng: 17.4459503 },
    { lat: 48.7486639, lng: 17.446 },
    { lat: 48.7485844, lng: 17.4464873 },
    { lat: 48.7483996, lng: 17.4467724 },
    { lat: 48.7479952, lng: 17.4467913 },
    { lat: 48.7479377, lng: 17.4469511 },
    { lat: 48.747724, lng: 17.4471395 },
    { lat: 48.7477916, lng: 17.4474191 },
    { lat: 48.7472513, lng: 17.4484903 },
    { lat: 48.7469202, lng: 17.4490327 },
    { lat: 48.7466545, lng: 17.4492037 },
    { lat: 48.7464556, lng: 17.4488334 },
    { lat: 48.7459945, lng: 17.4482117 },
    { lat: 48.745859, lng: 17.4480881 },
    { lat: 48.7456498, lng: 17.4480479 },
    { lat: 48.7456032, lng: 17.4481138 },
    { lat: 48.7456439, lng: 17.4482735 },
    { lat: 48.7454819, lng: 17.4489872 },
    { lat: 48.7454464, lng: 17.4495267 },
    { lat: 48.7452149, lng: 17.4495445 },
    { lat: 48.74535, lng: 17.4524109 },
    { lat: 48.7455208, lng: 17.4533339 },
    { lat: 48.7455711, lng: 17.4539241 },
    { lat: 48.7455933, lng: 17.4540078 },
    { lat: 48.745667, lng: 17.4540457 },
    { lat: 48.7459344, lng: 17.4540084 },
    { lat: 48.7459953, lng: 17.4541283 },
    { lat: 48.7459803, lng: 17.4544404 },
    { lat: 48.7461089, lng: 17.4543868 },
    { lat: 48.7462084, lng: 17.4545294 },
    { lat: 48.7462103, lng: 17.4547184 },
    { lat: 48.7460325, lng: 17.4550461 },
    { lat: 48.7459863, lng: 17.4555249 },
    { lat: 48.7458804, lng: 17.4558972 },
    { lat: 48.7459637, lng: 17.4562107 },
    { lat: 48.7460688, lng: 17.4575015 },
    { lat: 48.7460051, lng: 17.4576869 },
    { lat: 48.7459414, lng: 17.459006 },
    { lat: 48.7457657, lng: 17.4602663 },
    { lat: 48.7456882, lng: 17.4603983 },
    { lat: 48.7455317, lng: 17.4613741 },
    { lat: 48.7453897, lng: 17.4618022 },
    { lat: 48.745271, lng: 17.4625964 },
    { lat: 48.7449648, lng: 17.4635813 },
    { lat: 48.7446124, lng: 17.4651032 },
    { lat: 48.7443734, lng: 17.4657443 },
    { lat: 48.7444626, lng: 17.4660985 },
    { lat: 48.7445903, lng: 17.4661704 },
    { lat: 48.7449297, lng: 17.4661608 },
    { lat: 48.745278, lng: 17.4663071 },
    { lat: 48.7455223, lng: 17.4662084 },
    { lat: 48.7456781, lng: 17.4663323 },
    { lat: 48.746473, lng: 17.4662752 },
    { lat: 48.7471653, lng: 17.4661168 },
    { lat: 48.7476718, lng: 17.4661587 },
    { lat: 48.7477148, lng: 17.4662122 },
    { lat: 48.7475068, lng: 17.4664759 },
    { lat: 48.7474617, lng: 17.466655 },
    { lat: 48.7472626, lng: 17.4668251 },
    { lat: 48.7463605, lng: 17.4669884 },
    { lat: 48.7462971, lng: 17.4669336 },
    { lat: 48.7459882, lng: 17.4669763 },
    { lat: 48.7459246, lng: 17.4668675 },
    { lat: 48.7457799, lng: 17.4670579 },
    { lat: 48.7446901, lng: 17.467025 },
    { lat: 48.744572, lng: 17.4670841 },
    { lat: 48.7449508, lng: 17.4675863 },
    { lat: 48.7452663, lng: 17.4679091 },
    { lat: 48.745717, lng: 17.469148 },
    { lat: 48.7455252, lng: 17.4692748 },
    { lat: 48.745373, lng: 17.4691042 },
    { lat: 48.7453172, lng: 17.4691313 },
    { lat: 48.7451669, lng: 17.4689013 },
    { lat: 48.7451627, lng: 17.4687985 },
    { lat: 48.7448735, lng: 17.4690465 },
    { lat: 48.7453027, lng: 17.4699486 },
    { lat: 48.7453085, lng: 17.4701621 },
    { lat: 48.7455063, lng: 17.4710128 },
    { lat: 48.7457013, lng: 17.4721917 },
    { lat: 48.7457102, lng: 17.4727906 },
    { lat: 48.7456758, lng: 17.4730117 },
    { lat: 48.7457533, lng: 17.4734463 },
    { lat: 48.745814, lng: 17.4735491 },
    { lat: 48.7458415, lng: 17.4742454 },
    { lat: 48.7459844, lng: 17.4749281 },
    { lat: 48.7461295, lng: 17.475273 },
    { lat: 48.7460502, lng: 17.4757809 },
    { lat: 48.7461064, lng: 17.4765718 },
    { lat: 48.7460956, lng: 17.4770926 },
    { lat: 48.7461155, lng: 17.4771402 },
    { lat: 48.7468467, lng: 17.4770582 },
    { lat: 48.7473936, lng: 17.476764 },
    { lat: 48.7477875, lng: 17.4766697 },
    { lat: 48.748128, lng: 17.4764689 },
    { lat: 48.748182, lng: 17.47644 },
    { lat: 48.748302, lng: 17.476455 },
    { lat: 48.748337, lng: 17.476487 },
    { lat: 48.748382, lng: 17.476465 },
    { lat: 48.748427, lng: 17.476388 },
    { lat: 48.748471, lng: 17.476367 },
    { lat: 48.748504, lng: 17.476383 },
    { lat: 48.748534, lng: 17.476332 },
    { lat: 48.748607, lng: 17.47633 },
    { lat: 48.748628, lng: 17.476308 },
    { lat: 48.748684, lng: 17.476176 },
    { lat: 48.748737, lng: 17.476144 },
    { lat: 48.748762, lng: 17.476065 },
    { lat: 48.748838, lng: 17.476015 },
    { lat: 48.748912, lng: 17.475937 },
    { lat: 48.748991, lng: 17.475907 },
    { lat: 48.749171, lng: 17.475835 },
    { lat: 48.749286, lng: 17.475893 },
    { lat: 48.749323, lng: 17.475866 },
    { lat: 48.74935, lng: 17.47576 },
    { lat: 48.749421, lng: 17.475688 },
    { lat: 48.749449, lng: 17.475656 },
    { lat: 48.74942, lng: 17.475614 },
    { lat: 48.749519, lng: 17.475547 },
    { lat: 48.749703, lng: 17.475544 },
    { lat: 48.749763, lng: 17.475582 },
    { lat: 48.749849, lng: 17.475528 },
    { lat: 48.749922, lng: 17.475431 },
    { lat: 48.750009, lng: 17.475303 },
    { lat: 48.750063, lng: 17.475285 },
    { lat: 48.750158, lng: 17.475303 },
    { lat: 48.750264, lng: 17.475412 },
    { lat: 48.75028, lng: 17.475348 },
    { lat: 48.750338, lng: 17.475305 },
    { lat: 48.750392, lng: 17.47528 },
    { lat: 48.750533, lng: 17.475233 },
    { lat: 48.750548, lng: 17.475185 },
    { lat: 48.750573, lng: 17.475177 },
    { lat: 48.750599, lng: 17.475107 },
    { lat: 48.750671, lng: 17.47508 },
    { lat: 48.750737, lng: 17.475175 },
    { lat: 48.750806, lng: 17.475157 },
    { lat: 48.75091, lng: 17.475058 },
    { lat: 48.751009, lng: 17.474975 },
    { lat: 48.75105, lng: 17.475059 },
    { lat: 48.751095, lng: 17.475111 },
    { lat: 48.751131, lng: 17.475133 },
    { lat: 48.751264, lng: 17.475038 },
    { lat: 48.751353, lng: 17.475085 },
    { lat: 48.75147, lng: 17.475005 },
    { lat: 48.751532, lng: 17.474872 },
    { lat: 48.751639, lng: 17.474884 },
    { lat: 48.751748, lng: 17.474896 },
    { lat: 48.751872, lng: 17.474867 },
    { lat: 48.751947, lng: 17.474948 },
    { lat: 48.75207, lng: 17.474946 },
    { lat: 48.752165, lng: 17.474868 },
    { lat: 48.752252, lng: 17.474938 },
    { lat: 48.752312, lng: 17.474892 },
    { lat: 48.752385, lng: 17.474936 },
    { lat: 48.752432, lng: 17.474887 },
    { lat: 48.752539, lng: 17.474869 },
    { lat: 48.752591, lng: 17.47483 },
    { lat: 48.752683, lng: 17.474893 },
    { lat: 48.752749, lng: 17.474998 },
    { lat: 48.752814, lng: 17.47502 },
    { lat: 48.752842, lng: 17.474976 },
    { lat: 48.752902, lng: 17.474966 },
    { lat: 48.752937, lng: 17.47492 },
    { lat: 48.752917, lng: 17.474849 },
    { lat: 48.753, lng: 17.474846 },
    { lat: 48.75311, lng: 17.474961 },
    { lat: 48.753169, lng: 17.474935 },
    { lat: 48.753191, lng: 17.474859 },
    { lat: 48.753159, lng: 17.474834 },
    { lat: 48.753224, lng: 17.474736 },
    { lat: 48.753278, lng: 17.474737 },
    { lat: 48.753368, lng: 17.474773 },
    { lat: 48.753437, lng: 17.474689 },
    { lat: 48.753553, lng: 17.474545 },
    { lat: 48.753664, lng: 17.474616 },
    { lat: 48.753714, lng: 17.474559 },
    { lat: 48.753807, lng: 17.47451 },
    { lat: 48.753924, lng: 17.474604 },
    { lat: 48.754136, lng: 17.474474 },
    { lat: 48.754208, lng: 17.474362 },
    { lat: 48.754317, lng: 17.47436 },
    { lat: 48.754452, lng: 17.474276 },
    { lat: 48.754465, lng: 17.474197 },
    { lat: 48.754555, lng: 17.474181 },
    { lat: 48.754587, lng: 17.47415 },
    { lat: 48.754636, lng: 17.474107 },
    { lat: 48.754649, lng: 17.474081 },
    { lat: 48.754667, lng: 17.473999 },
    { lat: 48.754736, lng: 17.4739 },
    { lat: 48.754818, lng: 17.473778 },
    { lat: 48.754867, lng: 17.473738 },
    { lat: 48.754965, lng: 17.473719 },
    { lat: 48.755044, lng: 17.47369 },
    { lat: 48.755127, lng: 17.473662 },
    { lat: 48.755151, lng: 17.473647 },
    { lat: 48.755182, lng: 17.473617 },
    { lat: 48.755265, lng: 17.473516 },
    { lat: 48.755294, lng: 17.473468 },
    { lat: 48.755326, lng: 17.47341 },
    { lat: 48.755368, lng: 17.473337 },
    { lat: 48.755316, lng: 17.473245 },
    { lat: 48.755317, lng: 17.473179 },
    { lat: 48.755294, lng: 17.47313 },
    { lat: 48.755315, lng: 17.473035 },
    { lat: 48.755365, lng: 17.472862 },
    { lat: 48.755409, lng: 17.472735 },
    { lat: 48.755538, lng: 17.472698 },
    { lat: 48.755592, lng: 17.472559 },
    { lat: 48.755652, lng: 17.472373 },
    { lat: 48.75576, lng: 17.472279 },
    { lat: 48.755845, lng: 17.472235 },
    { lat: 48.755924, lng: 17.472141 },
    { lat: 48.756031, lng: 17.472013 },
    { lat: 48.756072, lng: 17.471938 },
    { lat: 48.756167, lng: 17.471835 },
    { lat: 48.756202, lng: 17.471817 },
    { lat: 48.756225, lng: 17.471803 },
    { lat: 48.756279, lng: 17.47178 },
    { lat: 48.756308, lng: 17.47168 },
    { lat: 48.756318, lng: 17.471669 },
    { lat: 48.756364, lng: 17.471616 },
    { lat: 48.756459, lng: 17.471504 },
    { lat: 48.75655, lng: 17.471399 },
    { lat: 48.756614, lng: 17.471337 },
    { lat: 48.756696, lng: 17.471309 },
    { lat: 48.756748, lng: 17.471313 },
    { lat: 48.756822, lng: 17.471273 },
    { lat: 48.756938, lng: 17.471197 },
    { lat: 48.75706, lng: 17.471106 },
    { lat: 48.757163, lng: 17.471026 },
    { lat: 48.757221, lng: 17.471003 },
    { lat: 48.757345, lng: 17.470967 },
    { lat: 48.757458, lng: 17.470906 },
    { lat: 48.757523, lng: 17.470922 },
    { lat: 48.757638, lng: 17.470973 },
    { lat: 48.75779, lng: 17.471039 },
    { lat: 48.757828, lng: 17.471059 },
    { lat: 48.757911, lng: 17.471086 },
    { lat: 48.758089, lng: 17.471151 },
    { lat: 48.758236, lng: 17.471175 },
    { lat: 48.758319, lng: 17.471187 },
    { lat: 48.75837, lng: 17.471182 },
    { lat: 48.758461, lng: 17.47115 },
    { lat: 48.7585, lng: 17.471151 },
    { lat: 48.758619, lng: 17.471191 },
    { lat: 48.75865, lng: 17.47118 },
    { lat: 48.758843, lng: 17.47117 },
    { lat: 48.758987, lng: 17.471161 },
    { lat: 48.759136, lng: 17.471203 },
    { lat: 48.759201, lng: 17.47124 },
    { lat: 48.759345, lng: 17.471316 },
    { lat: 48.759369, lng: 17.471323 },
    { lat: 48.759382, lng: 17.471332 },
    { lat: 48.759479, lng: 17.471412 },
    { lat: 48.759674, lng: 17.471591 },
    { lat: 48.759883, lng: 17.471798 },
    { lat: 48.760065, lng: 17.471985 },
    { lat: 48.76029, lng: 17.472221 },
    { lat: 48.760465, lng: 17.472412 },
    { lat: 48.760594, lng: 17.472549 },
    { lat: 48.760705, lng: 17.472667 },
    { lat: 48.760816, lng: 17.472783 },
    { lat: 48.760837, lng: 17.472808 },
    { lat: 48.760877, lng: 17.472603 },
    { lat: 48.760928, lng: 17.472335 },
    { lat: 48.760957, lng: 17.472098 },
    { lat: 48.760967, lng: 17.471966 },
    { lat: 48.760976, lng: 17.471815 },
    { lat: 48.760988, lng: 17.471586 },
    { lat: 48.76101, lng: 17.471332 },
    { lat: 48.761025, lng: 17.471143 },
    { lat: 48.76104, lng: 17.470958 },
    { lat: 48.76108, lng: 17.470559 },
    { lat: 48.761091, lng: 17.470422 },
    { lat: 48.761104, lng: 17.470264 },
    { lat: 48.761113, lng: 17.470075 },
    { lat: 48.761133, lng: 17.469735 },
    { lat: 48.761141, lng: 17.469565 },
    { lat: 48.761148, lng: 17.469365 },
    { lat: 48.761156, lng: 17.469155 },
    { lat: 48.761147, lng: 17.468996 },
    { lat: 48.761132, lng: 17.468814 },
    { lat: 48.761125, lng: 17.468696 },
    { lat: 48.761113, lng: 17.468549 },
    { lat: 48.761099, lng: 17.468384 },
    { lat: 48.761094, lng: 17.468263 },
    { lat: 48.761083, lng: 17.468147 },
    { lat: 48.761069, lng: 17.467963 },
    { lat: 48.761048, lng: 17.467698 },
    { lat: 48.761036, lng: 17.467556 },
    { lat: 48.761008, lng: 17.467175 },
    { lat: 48.761012, lng: 17.466972 },
    { lat: 48.761022, lng: 17.466787 },
    { lat: 48.761029, lng: 17.466614 },
    { lat: 48.76104, lng: 17.466438 },
    { lat: 48.761051, lng: 17.466251 },
    { lat: 48.761064, lng: 17.466048 },
    { lat: 48.761074, lng: 17.465884 },
    { lat: 48.761087, lng: 17.465661 },
    { lat: 48.761092, lng: 17.465477 },
    { lat: 48.761095, lng: 17.465159 },
    { lat: 48.761103, lng: 17.465001 },
    { lat: 48.761111, lng: 17.464851 },
    { lat: 48.76112, lng: 17.464683 },
    { lat: 48.761128, lng: 17.46451 },
    { lat: 48.761138, lng: 17.464341 },
    { lat: 48.761147, lng: 17.464157 },
    { lat: 48.761153, lng: 17.463991 },
    { lat: 48.761156, lng: 17.463826 },
    { lat: 48.76116, lng: 17.463683 },
    { lat: 48.76118, lng: 17.46329 },
    { lat: 48.761188, lng: 17.463107 },
    { lat: 48.761196, lng: 17.46292 },
    { lat: 48.761206, lng: 17.462755 },
    { lat: 48.76122, lng: 17.46261 },
    { lat: 48.761235, lng: 17.46243 },
    { lat: 48.761253, lng: 17.462205 },
    { lat: 48.761257, lng: 17.462024 },
    { lat: 48.761265, lng: 17.46184 },
    { lat: 48.76127, lng: 17.46166 },
    { lat: 48.761276, lng: 17.461347 },
    { lat: 48.761286, lng: 17.461186 },
    { lat: 48.761272, lng: 17.461156 },
    { lat: 48.761271, lng: 17.461129 },
    { lat: 48.761286, lng: 17.460931 },
    { lat: 48.761333, lng: 17.460908 },
    { lat: 48.761344, lng: 17.460779 },
    { lat: 48.761348, lng: 17.46065 },
    { lat: 48.761359, lng: 17.460447 },
    { lat: 48.761365, lng: 17.460306 },
    { lat: 48.76137, lng: 17.4602 },
    { lat: 48.761385, lng: 17.459968 },
    { lat: 48.761392, lng: 17.459816 },
    { lat: 48.761408, lng: 17.459594 },
    { lat: 48.761413, lng: 17.459446 },
    { lat: 48.761426, lng: 17.45926 },
    { lat: 48.76143, lng: 17.459096 },
    { lat: 48.761437, lng: 17.458793 },
    { lat: 48.761446, lng: 17.458511 },
    { lat: 48.76145, lng: 17.458236 },
    { lat: 48.761461, lng: 17.45776 },
    { lat: 48.761465, lng: 17.45754 },
    { lat: 48.761469, lng: 17.457336 },
    { lat: 48.761475, lng: 17.457109 },
    { lat: 48.76148, lng: 17.456894 },
    { lat: 48.761483, lng: 17.456714 },
    { lat: 48.761484, lng: 17.456526 },
    { lat: 48.761488, lng: 17.456299 },
    { lat: 48.761495, lng: 17.456081 },
    { lat: 48.761523, lng: 17.4561 },
    { lat: 48.761556, lng: 17.455983 },
    { lat: 48.761597, lng: 17.45585 },
    { lat: 48.761656, lng: 17.455667 },
    { lat: 48.761723, lng: 17.455437 },
    { lat: 48.76181, lng: 17.455149 },
    { lat: 48.761863, lng: 17.454968 },
    { lat: 48.761906, lng: 17.454816 },
    { lat: 48.761951, lng: 17.454678 },
    { lat: 48.761997, lng: 17.454512 },
    { lat: 48.76204, lng: 17.454386 },
    { lat: 48.762082, lng: 17.454236 },
    { lat: 48.762133, lng: 17.454041 },
    { lat: 48.762193, lng: 17.453818 },
    { lat: 48.762237, lng: 17.453639 },
    { lat: 48.762279, lng: 17.453474 },
    { lat: 48.762327, lng: 17.453276 },
    { lat: 48.762379, lng: 17.453079 },
    { lat: 48.762429, lng: 17.452877 },
    { lat: 48.762442, lng: 17.452879 },
    { lat: 48.762587, lng: 17.452799 },
    { lat: 48.762723, lng: 17.452714 },
    { lat: 48.762826, lng: 17.452648 },
    { lat: 48.762922, lng: 17.45259 },
    { lat: 48.76307, lng: 17.452504 },
    { lat: 48.763137, lng: 17.452412 },
    { lat: 48.763247, lng: 17.452193 },
    { lat: 48.763297, lng: 17.452074 },
    { lat: 48.763354, lng: 17.451938 },
    { lat: 48.763421, lng: 17.451753 },
    { lat: 48.763476, lng: 17.451591 },
    { lat: 48.763553, lng: 17.451358 },
    { lat: 48.763594, lng: 17.451192 },
    { lat: 48.763641, lng: 17.451004 },
    { lat: 48.763786, lng: 17.45099 },
    { lat: 48.763852, lng: 17.450968 },
    { lat: 48.763978, lng: 17.450923 },
    { lat: 48.76401, lng: 17.450757 },
    { lat: 48.764058, lng: 17.450517 },
    { lat: 48.764106, lng: 17.450275 },
    { lat: 48.764142, lng: 17.450111 },
    { lat: 48.764216, lng: 17.44993 },
    { lat: 48.764268, lng: 17.449806 },
    { lat: 48.764335, lng: 17.449637 },
    { lat: 48.764423, lng: 17.449639 },
    { lat: 48.764474, lng: 17.449638 },
    { lat: 48.764495, lng: 17.449479 },
    { lat: 48.764529, lng: 17.449365 },
    { lat: 48.764561, lng: 17.449254 },
    { lat: 48.764663, lng: 17.449112 },
    { lat: 48.764898, lng: 17.448704 },
    { lat: 48.764988, lng: 17.448544 },
    { lat: 48.764982, lng: 17.448444 },
    { lat: 48.764959, lng: 17.44839 },
    { lat: 48.764955, lng: 17.448242 },
    { lat: 48.764946, lng: 17.448043 },
    { lat: 48.764952, lng: 17.447879 },
    { lat: 48.764954, lng: 17.447721 },
    { lat: 48.764961, lng: 17.447555 },
    { lat: 48.764966, lng: 17.447389 },
    { lat: 48.764986, lng: 17.447334 },
    { lat: 48.765147, lng: 17.447337 },
    { lat: 48.765272, lng: 17.447338 },
    { lat: 48.765409, lng: 17.447341 },
    { lat: 48.76554, lng: 17.447348 },
    { lat: 48.765692, lng: 17.447349 },
    { lat: 48.765762, lng: 17.447351 },
    { lat: 48.76587, lng: 17.447353 },
    { lat: 48.766006, lng: 17.447354 },
    { lat: 48.766048, lng: 17.447348 },
    { lat: 48.766117, lng: 17.447325 },
    { lat: 48.766251, lng: 17.447272 },
    { lat: 48.766388, lng: 17.447218 },
    { lat: 48.766639, lng: 17.447117 },
    { lat: 48.766747, lng: 17.447071 },
    { lat: 48.766856, lng: 17.447172 },
    { lat: 48.767003, lng: 17.447312 },
    { lat: 48.767126, lng: 17.447421 },
    { lat: 48.767247, lng: 17.447519 },
    { lat: 48.76737, lng: 17.447613 },
    { lat: 48.767448, lng: 17.447651 },
    { lat: 48.767607, lng: 17.44772 },
    { lat: 48.767638, lng: 17.447632 },
    { lat: 48.767706, lng: 17.447439 },
    { lat: 48.767785, lng: 17.447252 },
    { lat: 48.767862, lng: 17.447064 },
    { lat: 48.767899, lng: 17.446962 },
    { lat: 48.767964, lng: 17.446778 },
    { lat: 48.768015, lng: 17.446623 },
    { lat: 48.768033, lng: 17.446539 },
    { lat: 48.768073, lng: 17.446351 },
    { lat: 48.768115, lng: 17.44622 },
    { lat: 48.768155, lng: 17.446098 },
    { lat: 48.768233, lng: 17.445906 },
    { lat: 48.768239, lng: 17.44588 },
    { lat: 48.768265, lng: 17.445823 },
    { lat: 48.768307, lng: 17.445717 },
    { lat: 48.76834, lng: 17.445634 },
    { lat: 48.768359, lng: 17.445591 },
    { lat: 48.768371, lng: 17.44555 },
    { lat: 48.768283, lng: 17.445411 },
    { lat: 48.768214, lng: 17.445305 },
    { lat: 48.768148, lng: 17.445199 },
    { lat: 48.768079, lng: 17.445089 },
    { lat: 48.767986, lng: 17.444938 },
    { lat: 48.768012, lng: 17.444909 },
    { lat: 48.768039, lng: 17.444872 },
    { lat: 48.76808, lng: 17.444819 },
    { lat: 48.768178, lng: 17.44466 },
    { lat: 48.768196, lng: 17.444622 },
    { lat: 48.768274, lng: 17.444608 },
    { lat: 48.768326, lng: 17.444507 },
    { lat: 48.768353, lng: 17.4444 },
    { lat: 48.768426, lng: 17.444308 },
    { lat: 48.768334, lng: 17.444045 },
    { lat: 48.76827, lng: 17.44385 },
    { lat: 48.768323, lng: 17.443745 },
    { lat: 48.768269, lng: 17.443647 },
    { lat: 48.768186, lng: 17.443512 },
    { lat: 48.768092, lng: 17.443358 },
    { lat: 48.767996, lng: 17.443192 },
    { lat: 48.767858, lng: 17.443099 },
    { lat: 48.767845, lng: 17.443095 },
    { lat: 48.767688, lng: 17.443142 },
    { lat: 48.767565, lng: 17.44318 },
    { lat: 48.767469, lng: 17.443211 },
    { lat: 48.767382, lng: 17.44324 },
    { lat: 48.767383, lng: 17.443278 },
    { lat: 48.767381, lng: 17.443356 },
    { lat: 48.767255, lng: 17.443359 },
    { lat: 48.767088, lng: 17.443378 },
    { lat: 48.76699, lng: 17.443399 },
    { lat: 48.766859, lng: 17.443432 },
    { lat: 48.76674, lng: 17.443461 },
    { lat: 48.76673, lng: 17.443385 },
    { lat: 48.766719, lng: 17.44331 },
    { lat: 48.766663, lng: 17.443043 },
    { lat: 48.766599, lng: 17.442728 },
    { lat: 48.766542, lng: 17.442483 },
    { lat: 48.766527, lng: 17.442429 },
    { lat: 48.766513, lng: 17.442264 },
    { lat: 48.766501, lng: 17.442174 },
    { lat: 48.766473, lng: 17.441951 },
    { lat: 48.76647, lng: 17.441915 },
    { lat: 48.766449, lng: 17.441908 },
    { lat: 48.766425, lng: 17.441743 },
    { lat: 48.766456, lng: 17.441579 },
    { lat: 48.766483, lng: 17.441354 },
    { lat: 48.766488, lng: 17.441227 },
    { lat: 48.766488, lng: 17.441045 },
    { lat: 48.766498, lng: 17.440879 },
    { lat: 48.766522, lng: 17.440812 },
    { lat: 48.766558, lng: 17.440681 },
    { lat: 48.766618, lng: 17.440519 },
    { lat: 48.766673, lng: 17.440371 },
    { lat: 48.766702, lng: 17.440204 },
    { lat: 48.76671, lng: 17.440125 },
    { lat: 48.766736, lng: 17.440006 },
    { lat: 48.766781, lng: 17.439828 },
    { lat: 48.766838, lng: 17.439646 },
    { lat: 48.766885, lng: 17.439489 },
    { lat: 48.766913, lng: 17.439346 },
    { lat: 48.766917, lng: 17.439213 },
    { lat: 48.766919, lng: 17.439009 },
    { lat: 48.766922, lng: 17.438803 },
    { lat: 48.766967, lng: 17.438637 },
    { lat: 48.766975, lng: 17.438515 },
    { lat: 48.767006, lng: 17.438368 },
    { lat: 48.767039, lng: 17.438197 },
    { lat: 48.767054, lng: 17.438059 },
    { lat: 48.767063, lng: 17.437879 },
    { lat: 48.767065, lng: 17.437739 },
    { lat: 48.767066, lng: 17.437525 },
    { lat: 48.76707, lng: 17.437324 },
    { lat: 48.767068, lng: 17.43714 },
    { lat: 48.767114, lng: 17.437037 },
    { lat: 48.767193, lng: 17.436857 },
    { lat: 48.767248, lng: 17.436703 },
    { lat: 48.767314, lng: 17.436498 },
    { lat: 48.767354, lng: 17.436417 },
    { lat: 48.76744, lng: 17.436249 },
    { lat: 48.767516, lng: 17.4361 },
    { lat: 48.767593, lng: 17.435873 },
    { lat: 48.767675, lng: 17.435655 },
    { lat: 48.767735, lng: 17.435479 },
    { lat: 48.767812, lng: 17.435238 },
    { lat: 48.767872, lng: 17.435027 },
    { lat: 48.767922, lng: 17.434877 },
    { lat: 48.768002, lng: 17.434732 },
    { lat: 48.768093, lng: 17.434552 },
    { lat: 48.768193, lng: 17.434362 },
    { lat: 48.768281, lng: 17.434186 },
    { lat: 48.768385, lng: 17.434045 },
    { lat: 48.768481, lng: 17.433926 },
    { lat: 48.768578, lng: 17.43381 },
    { lat: 48.768601, lng: 17.433732 },
    { lat: 48.768663, lng: 17.433533 },
    { lat: 48.768738, lng: 17.433299 },
    { lat: 48.768803, lng: 17.433125 },
    { lat: 48.768833, lng: 17.432931 },
    { lat: 48.768859, lng: 17.432858 },
    { lat: 48.768911, lng: 17.432722 },
    { lat: 48.768946, lng: 17.432618 },
    { lat: 48.768988, lng: 17.432436 },
    { lat: 48.768992, lng: 17.432234 },
    { lat: 48.769, lng: 17.432011 },
    { lat: 48.769076, lng: 17.431846 },
    { lat: 48.7691, lng: 17.431724 },
    { lat: 48.7691, lng: 17.431632 },
    { lat: 48.769102, lng: 17.431454 },
    { lat: 48.769108, lng: 17.431281 },
    { lat: 48.769112, lng: 17.431101 },
    { lat: 48.76912, lng: 17.430926 },
    { lat: 48.769154, lng: 17.430719 },
    { lat: 48.769155, lng: 17.430612 },
    { lat: 48.769151, lng: 17.430428 },
    { lat: 48.769145, lng: 17.43025 },
    { lat: 48.769125, lng: 17.430054 },
    { lat: 48.7691, lng: 17.429884 },
    { lat: 48.769102, lng: 17.429726 },
    { lat: 48.769094, lng: 17.429591 },
    { lat: 48.769082, lng: 17.429459 },
    { lat: 48.769055, lng: 17.429284 },
    { lat: 48.769039, lng: 17.429121 },
    { lat: 48.769039, lng: 17.428759 },
    { lat: 48.769032, lng: 17.428288 },
    { lat: 48.768983, lng: 17.428292 },
    { lat: 48.768915, lng: 17.42805 },
    { lat: 48.768969, lng: 17.427896 },
    { lat: 48.769038, lng: 17.427698 },
    { lat: 48.769084, lng: 17.427657 },
    { lat: 48.769105, lng: 17.427637 },
    { lat: 48.769207, lng: 17.427545 },
    { lat: 48.769315, lng: 17.427432 },
    { lat: 48.769398, lng: 17.427342 },
    { lat: 48.769531, lng: 17.427211 },
    { lat: 48.769695, lng: 17.427041 },
    { lat: 48.769844, lng: 17.426885 },
    { lat: 48.769963, lng: 17.427122 },
    { lat: 48.770045, lng: 17.427285 },
    { lat: 48.770214, lng: 17.427143 },
    { lat: 48.770333, lng: 17.427043 },
    { lat: 48.770453, lng: 17.426947 },
    { lat: 48.770585, lng: 17.42686 },
    { lat: 48.770792, lng: 17.426658 },
    { lat: 48.770925, lng: 17.426529 },
    { lat: 48.770978, lng: 17.426373 },
    { lat: 48.771018, lng: 17.426217 },
    { lat: 48.771017, lng: 17.426106 },
    { lat: 48.771116, lng: 17.425906 },
    { lat: 48.771205, lng: 17.425733 },
    { lat: 48.771277, lng: 17.42548 },
    { lat: 48.771302, lng: 17.425371 },
    { lat: 48.771348, lng: 17.425168 },
    { lat: 48.771424, lng: 17.424948 },
    { lat: 48.771475, lng: 17.42481 },
    { lat: 48.77154, lng: 17.424642 },
    { lat: 48.771646, lng: 17.424379 },
    { lat: 48.77163, lng: 17.42435 },
    { lat: 48.771655, lng: 17.424106 },
    { lat: 48.771621, lng: 17.423742 },
    { lat: 48.771635, lng: 17.423699 },
    { lat: 48.771662, lng: 17.42352 },
    { lat: 48.771695, lng: 17.423327 },
    { lat: 48.771732, lng: 17.423113 },
    { lat: 48.771751, lng: 17.422955 },
    { lat: 48.771764, lng: 17.422748 },
    { lat: 48.771785, lng: 17.422569 },
    { lat: 48.7718, lng: 17.422389 },
    { lat: 48.771837, lng: 17.422323 },
    { lat: 48.771886, lng: 17.422228 },
    { lat: 48.771949, lng: 17.422154 },
    { lat: 48.772061, lng: 17.422005 },
    { lat: 48.77214, lng: 17.421928 },
    { lat: 48.772247, lng: 17.42182 },
    { lat: 48.772248, lng: 17.421792 },
    { lat: 48.772367, lng: 17.421744 },
    { lat: 48.77251, lng: 17.421697 },
    { lat: 48.772685, lng: 17.421675 },
    { lat: 48.772826, lng: 17.421661 },
    { lat: 48.772895, lng: 17.421688 },
    { lat: 48.773072, lng: 17.42176 },
    { lat: 48.773174, lng: 17.421805 },
    { lat: 48.773307, lng: 17.421867 },
    { lat: 48.773493, lng: 17.421957 },
    { lat: 48.773714, lng: 17.42211 },
    { lat: 48.773805, lng: 17.422163 },
    { lat: 48.773792, lng: 17.422283 },
    { lat: 48.773766, lng: 17.422488 },
    { lat: 48.77385, lng: 17.422574 },
    { lat: 48.773943, lng: 17.422664 },
    { lat: 48.774043, lng: 17.422761 },
    { lat: 48.774153, lng: 17.422869 },
    { lat: 48.774275, lng: 17.422889 },
    { lat: 48.774414, lng: 17.422896 },
    { lat: 48.774433, lng: 17.423128 },
    { lat: 48.774455, lng: 17.423319 },
    { lat: 48.774429, lng: 17.423562 },
    { lat: 48.774399, lng: 17.423803 },
    { lat: 48.774396, lng: 17.423949 },
    { lat: 48.774484, lng: 17.424208 },
    { lat: 48.774549, lng: 17.424403 },
    { lat: 48.774689, lng: 17.424421 },
    { lat: 48.77485, lng: 17.424449 },
    { lat: 48.775059, lng: 17.424478 },
    { lat: 48.775259, lng: 17.424512 },
    { lat: 48.775406, lng: 17.424536 },
    { lat: 48.775503, lng: 17.42455 },
    { lat: 48.775643, lng: 17.424571 },
    { lat: 48.775795, lng: 17.424595 },
    { lat: 48.775916, lng: 17.424599 },
    { lat: 48.776085, lng: 17.424601 },
    { lat: 48.776239, lng: 17.424603 },
    { lat: 48.776361, lng: 17.424608 },
    { lat: 48.776523, lng: 17.42461 },
    { lat: 48.776569, lng: 17.424387 },
    { lat: 48.77659, lng: 17.424401 },
    { lat: 48.776651, lng: 17.42444 },
    { lat: 48.776791, lng: 17.42452 },
    { lat: 48.776937, lng: 17.424611 },
    { lat: 48.777076, lng: 17.424703 },
    { lat: 48.777288, lng: 17.424838 },
    { lat: 48.777413, lng: 17.424915 },
    { lat: 48.777576, lng: 17.425016 },
    { lat: 48.777708, lng: 17.425104 },
    { lat: 48.777735, lng: 17.425136 },
    { lat: 48.777777, lng: 17.425039 },
    { lat: 48.777856, lng: 17.424889 },
    { lat: 48.777963, lng: 17.42472 },
    { lat: 48.778021, lng: 17.424646 },
    { lat: 48.778053, lng: 17.424577 },
    { lat: 48.778097, lng: 17.424513 },
    { lat: 48.778183, lng: 17.424394 },
    { lat: 48.77825, lng: 17.424268 },
    { lat: 48.778328, lng: 17.424113 },
    { lat: 48.778424, lng: 17.424069 },
    { lat: 48.778558, lng: 17.424012 },
    { lat: 48.778629, lng: 17.423837 },
    { lat: 48.778657, lng: 17.423839 },
    { lat: 48.778721, lng: 17.423839 },
    { lat: 48.778861, lng: 17.423844 },
    { lat: 48.779001, lng: 17.423858 },
    { lat: 48.779129, lng: 17.423868 },
    { lat: 48.779268, lng: 17.423874 },
    { lat: 48.779342, lng: 17.423885 },
    { lat: 48.779479, lng: 17.423908 },
    { lat: 48.779651, lng: 17.423943 },
    { lat: 48.779674, lng: 17.423841 },
    { lat: 48.779724, lng: 17.42363 },
    { lat: 48.779746, lng: 17.423513 },
    { lat: 48.779714, lng: 17.423198 },
    { lat: 48.779639, lng: 17.422949 },
    { lat: 48.779739, lng: 17.422969 },
    { lat: 48.779743, lng: 17.422954 },
    { lat: 48.779886, lng: 17.422922 },
    { lat: 48.779957, lng: 17.422901 },
    { lat: 48.780049, lng: 17.422895 },
    { lat: 48.780163, lng: 17.422891 },
    { lat: 48.780236, lng: 17.42287 },
    { lat: 48.780349, lng: 17.422838 },
    { lat: 48.780451, lng: 17.422813 },
    { lat: 48.780575, lng: 17.422779 },
    { lat: 48.780651, lng: 17.422759 },
    { lat: 48.780755, lng: 17.42275 },
    { lat: 48.780984, lng: 17.422729 },
    { lat: 48.781144, lng: 17.422699 },
    { lat: 48.781209, lng: 17.422671 },
    { lat: 48.781339, lng: 17.422617 },
    { lat: 48.781423, lng: 17.422569 },
    { lat: 48.781551, lng: 17.422502 },
    { lat: 48.781696, lng: 17.422356 },
    { lat: 48.78181, lng: 17.422251 },
    { lat: 48.781917, lng: 17.422201 },
    { lat: 48.782064, lng: 17.422138 },
    { lat: 48.782229, lng: 17.42206 },
    { lat: 48.782399, lng: 17.421986 },
    { lat: 48.782412, lng: 17.422167 },
    { lat: 48.782511, lng: 17.422156 },
    { lat: 48.782714, lng: 17.422126 },
    { lat: 48.782893, lng: 17.422106 },
    { lat: 48.783133, lng: 17.422069 },
    { lat: 48.783361, lng: 17.422043 },
    { lat: 48.783539, lng: 17.422019 },
    { lat: 48.783786, lng: 17.421982 },
    { lat: 48.78399, lng: 17.421957 },
    { lat: 48.783992, lng: 17.421628 },
    { lat: 48.783991, lng: 17.421434 },
    { lat: 48.783966, lng: 17.421243 },
    { lat: 48.783934, lng: 17.420973 },
    { lat: 48.783921, lng: 17.420715 },
    { lat: 48.783905, lng: 17.420503 },
    { lat: 48.783901, lng: 17.420477 },
    { lat: 48.784122, lng: 17.42044 },
    { lat: 48.784296, lng: 17.420406 },
    { lat: 48.784443, lng: 17.420384 },
    { lat: 48.784753, lng: 17.420475 },
    { lat: 48.784926, lng: 17.420523 },
    { lat: 48.785099, lng: 17.420514 },
    { lat: 48.785234, lng: 17.420436 },
    { lat: 48.785327, lng: 17.420382 },
    { lat: 48.785457, lng: 17.420314 },
    { lat: 48.785589, lng: 17.420239 },
    { lat: 48.78575, lng: 17.420148 },
    { lat: 48.78587, lng: 17.420068 },
    { lat: 48.785994, lng: 17.419979 },
    { lat: 48.786093, lng: 17.419906 },
    { lat: 48.78621, lng: 17.419822 },
    { lat: 48.786308, lng: 17.419673 },
    { lat: 48.786385, lng: 17.419484 },
  ],
  ŠajdíkoveHumence: [
    { lat: 48.6628504, lng: 17.243743 },
    { lat: 48.6623053, lng: 17.2440783 },
    { lat: 48.6591623, lng: 17.2460096 },
    { lat: 48.6589175, lng: 17.2450908 },
    { lat: 48.6577167, lng: 17.2467361 },
    { lat: 48.6568722, lng: 17.247995 },
    { lat: 48.6540752, lng: 17.2518179 },
    { lat: 48.6542643, lng: 17.2524733 },
    { lat: 48.6542387, lng: 17.2526526 },
    { lat: 48.6515734, lng: 17.2640674 },
    { lat: 48.6458973, lng: 17.2688512 },
    { lat: 48.645844, lng: 17.268987 },
    { lat: 48.6441402, lng: 17.2698924 },
    { lat: 48.6428283, lng: 17.2721526 },
    { lat: 48.6430535, lng: 17.2724521 },
    { lat: 48.6428249, lng: 17.273134 },
    { lat: 48.6426758, lng: 17.2734403 },
    { lat: 48.6424492, lng: 17.2736746 },
    { lat: 48.6421343, lng: 17.2736775 },
    { lat: 48.641601, lng: 17.2742431 },
    { lat: 48.6415238, lng: 17.2738932 },
    { lat: 48.6376707, lng: 17.2757508 },
    { lat: 48.6376141, lng: 17.2756289 },
    { lat: 48.6376681, lng: 17.2754701 },
    { lat: 48.6374415, lng: 17.2751172 },
    { lat: 48.6372618, lng: 17.2750484 },
    { lat: 48.6371676, lng: 17.2750778 },
    { lat: 48.6370635, lng: 17.2749377 },
    { lat: 48.6368421, lng: 17.2749544 },
    { lat: 48.6365819, lng: 17.2751562 },
    { lat: 48.6364316, lng: 17.2751891 },
    { lat: 48.6363757, lng: 17.2753857 },
    { lat: 48.6361039, lng: 17.2757024 },
    { lat: 48.635956, lng: 17.2757713 },
    { lat: 48.6358383, lng: 17.2759936 },
    { lat: 48.6354795, lng: 17.2763948 },
    { lat: 48.6352603, lng: 17.2769273 },
    { lat: 48.6340318, lng: 17.2844679 },
    { lat: 48.63391, lng: 17.2850204 },
    { lat: 48.6317448, lng: 17.2996487 },
    { lat: 48.6303094, lng: 17.3093464 },
    { lat: 48.6301575, lng: 17.3103575 },
    { lat: 48.6301138, lng: 17.3104133 },
    { lat: 48.6301919, lng: 17.3105534 },
    { lat: 48.6304957, lng: 17.3108992 },
    { lat: 48.6307989, lng: 17.3110798 },
    { lat: 48.6321277, lng: 17.3116214 },
    { lat: 48.633571, lng: 17.3120132 },
    { lat: 48.6350483, lng: 17.3122511 },
    { lat: 48.635537, lng: 17.3124336 },
    { lat: 48.6362322, lng: 17.3128908 },
    { lat: 48.6364162, lng: 17.3130798 },
    { lat: 48.6366958, lng: 17.3134993 },
    { lat: 48.6369648, lng: 17.3141428 },
    { lat: 48.6372516, lng: 17.3145212 },
    { lat: 48.6380197, lng: 17.3151761 },
    { lat: 48.638443, lng: 17.315311 },
    { lat: 48.6392039, lng: 17.3149759 },
    { lat: 48.6399158, lng: 17.3148939 },
    { lat: 48.6405091, lng: 17.314878 },
    { lat: 48.6416744, lng: 17.3149928 },
    { lat: 48.6423575, lng: 17.3152464 },
    { lat: 48.6434083, lng: 17.3157683 },
    { lat: 48.6447436, lng: 17.3161532 },
    { lat: 48.6455549, lng: 17.3166386 },
    { lat: 48.6465269, lng: 17.3174944 },
    { lat: 48.6477351, lng: 17.318213 },
    { lat: 48.6490053, lng: 17.3192574 },
    { lat: 48.6492268, lng: 17.3193523 },
    { lat: 48.6493667, lng: 17.3194123 },
    { lat: 48.6497139, lng: 17.3193495 },
    { lat: 48.6504014, lng: 17.3190318 },
    { lat: 48.6511856, lng: 17.3192609 },
    { lat: 48.6521787, lng: 17.3199033 },
    { lat: 48.6523601, lng: 17.3201732 },
    { lat: 48.6526585, lng: 17.320849 },
    { lat: 48.6528914, lng: 17.3210437 },
    { lat: 48.6531629, lng: 17.3211342 },
    { lat: 48.6537182, lng: 17.3211133 },
    { lat: 48.6548719, lng: 17.3215843 },
    { lat: 48.6552633, lng: 17.3220795 },
    { lat: 48.6552288, lng: 17.3221204 },
    { lat: 48.6561381, lng: 17.3246125 },
    { lat: 48.6568172, lng: 17.3254101 },
    { lat: 48.6588561, lng: 17.3268987 },
    { lat: 48.6597904, lng: 17.3270378 },
    { lat: 48.6601789, lng: 17.3272962 },
    { lat: 48.660266, lng: 17.3274734 },
    { lat: 48.6606223, lng: 17.3275647 },
    { lat: 48.6612092, lng: 17.3285651 },
    { lat: 48.6613626, lng: 17.3286992 },
    { lat: 48.6620934, lng: 17.3298397 },
    { lat: 48.6623994, lng: 17.3302205 },
    { lat: 48.6631813, lng: 17.3302065 },
    { lat: 48.6639807, lng: 17.3294202 },
    { lat: 48.6624934, lng: 17.3232483 },
    { lat: 48.6631243, lng: 17.3206613 },
    { lat: 48.663252, lng: 17.320662 },
    { lat: 48.6633818, lng: 17.3209725 },
    { lat: 48.6636154, lng: 17.3209867 },
    { lat: 48.6636504, lng: 17.3208993 },
    { lat: 48.6630114, lng: 17.3187071 },
    { lat: 48.6638554, lng: 17.3178982 },
    { lat: 48.6626239, lng: 17.3155956 },
    { lat: 48.6629717, lng: 17.3153323 },
    { lat: 48.6624071, lng: 17.3142815 },
    { lat: 48.6621138, lng: 17.3146493 },
    { lat: 48.6619791, lng: 17.3140776 },
    { lat: 48.6620469, lng: 17.3140761 },
    { lat: 48.6624714, lng: 17.3135558 },
    { lat: 48.6637724, lng: 17.3080569 },
    { lat: 48.6637272, lng: 17.307865 },
    { lat: 48.6639501, lng: 17.3077149 },
    { lat: 48.6639576, lng: 17.3076241 },
    { lat: 48.6636983, lng: 17.3072857 },
    { lat: 48.6636738, lng: 17.3071865 },
    { lat: 48.663726, lng: 17.3069168 },
    { lat: 48.6638956, lng: 17.3067092 },
    { lat: 48.6642484, lng: 17.3070111 },
    { lat: 48.6644778, lng: 17.3069751 },
    { lat: 48.664704, lng: 17.3066194 },
    { lat: 48.6646948, lng: 17.3062541 },
    { lat: 48.664587, lng: 17.3059843 },
    { lat: 48.664236, lng: 17.3054714 },
    { lat: 48.6642016, lng: 17.3048951 },
    { lat: 48.6643558, lng: 17.3046112 },
    { lat: 48.6648303, lng: 17.3044754 },
    { lat: 48.6651261, lng: 17.3039889 },
    { lat: 48.6647443, lng: 17.3031256 },
    { lat: 48.6646529, lng: 17.3027626 },
    { lat: 48.6646581, lng: 17.3023941 },
    { lat: 48.6647716, lng: 17.3018351 },
    { lat: 48.664953, lng: 17.3012574 },
    { lat: 48.6651928, lng: 17.3006219 },
    { lat: 48.6658137, lng: 17.300669 },
    { lat: 48.665875, lng: 17.3000538 },
    { lat: 48.6661269, lng: 17.2994151 },
    { lat: 48.6662784, lng: 17.2991766 },
    { lat: 48.6668196, lng: 17.2986934 },
    { lat: 48.666902, lng: 17.2987108 },
    { lat: 48.666951, lng: 17.2986652 },
    { lat: 48.6671067, lng: 17.2981245 },
    { lat: 48.6668064, lng: 17.2980611 },
    { lat: 48.6667004, lng: 17.2981662 },
    { lat: 48.6665378, lng: 17.2980668 },
    { lat: 48.6665684, lng: 17.297775 },
    { lat: 48.6666371, lng: 17.2976307 },
    { lat: 48.6669638, lng: 17.2976348 },
    { lat: 48.6670611, lng: 17.297552 },
    { lat: 48.6670511, lng: 17.2973995 },
    { lat: 48.666938, lng: 17.2973456 },
    { lat: 48.6668851, lng: 17.2971598 },
    { lat: 48.6670073, lng: 17.297128 },
    { lat: 48.6670524, lng: 17.2970394 },
    { lat: 48.6671306, lng: 17.2965099 },
    { lat: 48.6673761, lng: 17.2962031 },
    { lat: 48.6674618, lng: 17.2959934 },
    { lat: 48.6669642, lng: 17.2959176 },
    { lat: 48.6669191, lng: 17.2958321 },
    { lat: 48.6667551, lng: 17.2958609 },
    { lat: 48.6666562, lng: 17.2957002 },
    { lat: 48.666634, lng: 17.2955491 },
    { lat: 48.6666736, lng: 17.2953945 },
    { lat: 48.667175, lng: 17.2952721 },
    { lat: 48.6672154, lng: 17.2951807 },
    { lat: 48.6671651, lng: 17.2948616 },
    { lat: 48.6667306, lng: 17.2948335 },
    { lat: 48.6663727, lng: 17.2950439 },
    { lat: 48.6662825, lng: 17.2950113 },
    { lat: 48.6660605, lng: 17.2939523 },
    { lat: 48.6658041, lng: 17.2939709 },
    { lat: 48.6656837, lng: 17.2940436 },
    { lat: 48.6655182, lng: 17.2940187 },
    { lat: 48.6654013, lng: 17.2935101 },
    { lat: 48.6653733, lng: 17.2930819 },
    { lat: 48.665183, lng: 17.2926279 },
    { lat: 48.665194, lng: 17.292553 },
    { lat: 48.6655982, lng: 17.292345 },
    { lat: 48.6657576, lng: 17.2921914 },
    { lat: 48.6655796, lng: 17.2920254 },
    { lat: 48.6654507, lng: 17.2917316 },
    { lat: 48.665472, lng: 17.2916409 },
    { lat: 48.6658223, lng: 17.2914016 },
    { lat: 48.6662061, lng: 17.2913128 },
    { lat: 48.6665306, lng: 17.2909377 },
    { lat: 48.6665919, lng: 17.2908031 },
    { lat: 48.6666327, lng: 17.2903603 },
    { lat: 48.6664692, lng: 17.29021 },
    { lat: 48.6661951, lng: 17.2902817 },
    { lat: 48.6660713, lng: 17.2901194 },
    { lat: 48.6660242, lng: 17.2899184 },
    { lat: 48.666315, lng: 17.2891477 },
    { lat: 48.6663367, lng: 17.2889107 },
    { lat: 48.6664087, lng: 17.2888539 },
    { lat: 48.6665713, lng: 17.2889729 },
    { lat: 48.6666784, lng: 17.2891616 },
    { lat: 48.6668493, lng: 17.2891293 },
    { lat: 48.6669388, lng: 17.2890502 },
    { lat: 48.6670011, lng: 17.2886896 },
    { lat: 48.6669927, lng: 17.2885936 },
    { lat: 48.666733, lng: 17.2883383 },
    { lat: 48.6662894, lng: 17.2882084 },
    { lat: 48.6660006, lng: 17.2878499 },
    { lat: 48.6658846, lng: 17.287511 },
    { lat: 48.6658876, lng: 17.2873329 },
    { lat: 48.6660688, lng: 17.2867712 },
    { lat: 48.6663593, lng: 17.2864976 },
    { lat: 48.6663784, lng: 17.2862585 },
    { lat: 48.6663549, lng: 17.2861563 },
    { lat: 48.6661976, lng: 17.2860136 },
    { lat: 48.6660351, lng: 17.2861394 },
    { lat: 48.6658696, lng: 17.2859908 },
    { lat: 48.6657865, lng: 17.2858278 },
    { lat: 48.6658169, lng: 17.2854837 },
    { lat: 48.6658668, lng: 17.2853637 },
    { lat: 48.6661647, lng: 17.2852212 },
    { lat: 48.6663151, lng: 17.285034 },
    { lat: 48.6667678, lng: 17.284754 },
    { lat: 48.66706, lng: 17.2844058 },
    { lat: 48.6671407, lng: 17.2843591 },
    { lat: 48.66741, lng: 17.2844364 },
    { lat: 48.667465, lng: 17.2843438 },
    { lat: 48.6675089, lng: 17.2841503 },
    { lat: 48.6674726, lng: 17.2839256 },
    { lat: 48.6675442, lng: 17.2835021 },
    { lat: 48.6674523, lng: 17.2829276 },
    { lat: 48.6674772, lng: 17.2828085 },
    { lat: 48.6676095, lng: 17.2827762 },
    { lat: 48.6677655, lng: 17.2828815 },
    { lat: 48.6679459, lng: 17.2824442 },
    { lat: 48.6676056, lng: 17.2821149 },
    { lat: 48.6673143, lng: 17.2820648 },
    { lat: 48.6672578, lng: 17.2815837 },
    { lat: 48.6673641, lng: 17.2814963 },
    { lat: 48.6672082, lng: 17.2812748 },
    { lat: 48.6672005, lng: 17.2811209 },
    { lat: 48.6670462, lng: 17.2808556 },
    { lat: 48.66706, lng: 17.2806873 },
    { lat: 48.6670915, lng: 17.280564 },
    { lat: 48.6672904, lng: 17.2805292 },
    { lat: 48.6676419, lng: 17.2801907 },
    { lat: 48.6677722, lng: 17.2800043 },
    { lat: 48.6678389, lng: 17.2797145 },
    { lat: 48.6680194, lng: 17.2793676 },
    { lat: 48.6682285, lng: 17.2792809 },
    { lat: 48.668363, lng: 17.2790084 },
    { lat: 48.6681187, lng: 17.2780379 },
    { lat: 48.6679809, lng: 17.2779366 },
    { lat: 48.6678457, lng: 17.2776488 },
    { lat: 48.6679392, lng: 17.2771788 },
    { lat: 48.6676493, lng: 17.2769459 },
    { lat: 48.6675404, lng: 17.2770083 },
    { lat: 48.6674723, lng: 17.2771608 },
    { lat: 48.6673429, lng: 17.2770644 },
    { lat: 48.6673377, lng: 17.2770026 },
    { lat: 48.6664333, lng: 17.277128 },
    { lat: 48.6664428, lng: 17.2770337 },
    { lat: 48.6663594, lng: 17.2769497 },
    { lat: 48.666272, lng: 17.2766004 },
    { lat: 48.6661277, lng: 17.276345 },
    { lat: 48.6661074, lng: 17.2762412 },
    { lat: 48.6662781, lng: 17.2759862 },
    { lat: 48.6663179, lng: 17.2757169 },
    { lat: 48.6662983, lng: 17.2752806 },
    { lat: 48.6664144, lng: 17.2751567 },
    { lat: 48.6664631, lng: 17.275019 },
    { lat: 48.6666018, lng: 17.2742766 },
    { lat: 48.666196, lng: 17.2732085 },
    { lat: 48.6662281, lng: 17.2728684 },
    { lat: 48.6664142, lng: 17.2723156 },
    { lat: 48.6664111, lng: 17.2722171 },
    { lat: 48.6663428, lng: 17.2720532 },
    { lat: 48.666232, lng: 17.2719666 },
    { lat: 48.6659311, lng: 17.2720449 },
    { lat: 48.6658495, lng: 17.2719738 },
    { lat: 48.6657769, lng: 17.2717817 },
    { lat: 48.6657682, lng: 17.27158 },
    { lat: 48.6659356, lng: 17.2714295 },
    { lat: 48.6659605, lng: 17.2712067 },
    { lat: 48.6658748, lng: 17.2709655 },
    { lat: 48.6656613, lng: 17.2707287 },
    { lat: 48.665522, lng: 17.2703268 },
    { lat: 48.6654768, lng: 17.2699812 },
    { lat: 48.6655347, lng: 17.2696963 },
    { lat: 48.6654958, lng: 17.2695513 },
    { lat: 48.6653864, lng: 17.2693451 },
    { lat: 48.6651175, lng: 17.2691399 },
    { lat: 48.6651115, lng: 17.2689617 },
    { lat: 48.6655334, lng: 17.2682648 },
    { lat: 48.6653267, lng: 17.2679906 },
    { lat: 48.6653301, lng: 17.2676506 },
    { lat: 48.6654113, lng: 17.2674587 },
    { lat: 48.6657476, lng: 17.2672811 },
    { lat: 48.6659728, lng: 17.2668763 },
    { lat: 48.6660025, lng: 17.266691 },
    { lat: 48.665909, lng: 17.266075 },
    { lat: 48.6655641, lng: 17.2652622 },
    { lat: 48.665588, lng: 17.2647322 },
    { lat: 48.6657507, lng: 17.2644427 },
    { lat: 48.6659243, lng: 17.2638027 },
    { lat: 48.6657604, lng: 17.2630435 },
    { lat: 48.6657925, lng: 17.262811 },
    { lat: 48.6659635, lng: 17.262784 },
    { lat: 48.6663223, lng: 17.2628613 },
    { lat: 48.666332, lng: 17.2626501 },
    { lat: 48.6665975, lng: 17.2614652 },
    { lat: 48.666623, lng: 17.261219 },
    { lat: 48.6665582, lng: 17.2612127 },
    { lat: 48.6664785, lng: 17.2608937 },
    { lat: 48.6664883, lng: 17.2607507 },
    { lat: 48.6666515, lng: 17.2601061 },
    { lat: 48.6667844, lng: 17.2590527 },
    { lat: 48.6668658, lng: 17.2590386 },
    { lat: 48.6668225, lng: 17.2586538 },
    { lat: 48.6668449, lng: 17.2584449 },
    { lat: 48.6670528, lng: 17.2576907 },
    { lat: 48.6672032, lng: 17.2564223 },
    { lat: 48.6671272, lng: 17.2553369 },
    { lat: 48.6671777, lng: 17.2547164 },
    { lat: 48.6671547, lng: 17.2544536 },
    { lat: 48.6672599, lng: 17.2533735 },
    { lat: 48.6673436, lng: 17.2529799 },
    { lat: 48.6673346, lng: 17.2518351 },
    { lat: 48.667379, lng: 17.251599 },
    { lat: 48.6673275, lng: 17.2511589 },
    { lat: 48.6673395, lng: 17.250759 },
    { lat: 48.6672504, lng: 17.2507298 },
    { lat: 48.6671814, lng: 17.2505551 },
    { lat: 48.667089, lng: 17.2504717 },
    { lat: 48.6668, lng: 17.2503576 },
    { lat: 48.6657851, lng: 17.2496316 },
    { lat: 48.6656296, lng: 17.2496182 },
    { lat: 48.6651786, lng: 17.2500094 },
    { lat: 48.6650775, lng: 17.2499649 },
    { lat: 48.66504, lng: 17.2496224 },
    { lat: 48.664789, lng: 17.2495143 },
    { lat: 48.6646497, lng: 17.2493408 },
    { lat: 48.664516, lng: 17.2493345 },
    { lat: 48.6644869, lng: 17.249251 },
    { lat: 48.664521, lng: 17.2491333 },
    { lat: 48.6646094, lng: 17.2490196 },
    { lat: 48.6645688, lng: 17.2488677 },
    { lat: 48.6644189, lng: 17.2489928 },
    { lat: 48.6641928, lng: 17.2489703 },
    { lat: 48.6641428, lng: 17.2487109 },
    { lat: 48.6640488, lng: 17.2486535 },
    { lat: 48.6639247, lng: 17.2484334 },
    { lat: 48.6639257, lng: 17.2482796 },
    { lat: 48.6640113, lng: 17.2480162 },
    { lat: 48.6635983, lng: 17.2480747 },
    { lat: 48.6635276, lng: 17.2477241 },
    { lat: 48.6634631, lng: 17.2476877 },
    { lat: 48.6632533, lng: 17.24728 },
    { lat: 48.6631063, lng: 17.2472124 },
    { lat: 48.6631027, lng: 17.2471225 },
    { lat: 48.6632103, lng: 17.246987 },
    { lat: 48.6631023, lng: 17.2465522 },
    { lat: 48.6633452, lng: 17.2460904 },
    { lat: 48.6634589, lng: 17.2459794 },
    { lat: 48.6634571, lng: 17.2458842 },
    { lat: 48.6632763, lng: 17.2457988 },
    { lat: 48.6632368, lng: 17.2456275 },
    { lat: 48.6631698, lng: 17.2456555 },
    { lat: 48.6630801, lng: 17.2460074 },
    { lat: 48.6629291, lng: 17.2457931 },
    { lat: 48.6627436, lng: 17.2457374 },
    { lat: 48.6626616, lng: 17.2455885 },
    { lat: 48.6628121, lng: 17.245304 },
    { lat: 48.6630215, lng: 17.245241 },
    { lat: 48.6630684, lng: 17.2450267 },
    { lat: 48.6626506, lng: 17.2449266 },
    { lat: 48.6626984, lng: 17.244725 },
    { lat: 48.662691, lng: 17.2443759 },
    { lat: 48.6628741, lng: 17.2443423 },
    { lat: 48.6629273, lng: 17.2442406 },
    { lat: 48.6631135, lng: 17.2441347 },
    { lat: 48.6630968, lng: 17.2440011 },
    { lat: 48.6629171, lng: 17.2439316 },
    { lat: 48.6628637, lng: 17.2437903 },
    { lat: 48.6628504, lng: 17.243743 },
  ],
  Rovensko: [
    { lat: 48.7225177, lng: 17.3489125 },
    { lat: 48.7203069, lng: 17.3494328 },
    { lat: 48.7200481, lng: 17.349414 },
    { lat: 48.7191796, lng: 17.3495694 },
    { lat: 48.7189856, lng: 17.3494354 },
    { lat: 48.7174239, lng: 17.3500853 },
    { lat: 48.7168721, lng: 17.3503901 },
    { lat: 48.7163707, lng: 17.3505131 },
    { lat: 48.7154263, lng: 17.351085 },
    { lat: 48.7145775, lng: 17.351339 },
    { lat: 48.7140305, lng: 17.3514055 },
    { lat: 48.7133266, lng: 17.3510013 },
    { lat: 48.7129805, lng: 17.350994 },
    { lat: 48.7106744, lng: 17.3517993 },
    { lat: 48.7090398, lng: 17.3522153 },
    { lat: 48.7082765, lng: 17.3526382 },
    { lat: 48.7077351, lng: 17.3531366 },
    { lat: 48.7073068, lng: 17.3536747 },
    { lat: 48.7062182, lng: 17.3544606 },
    { lat: 48.7058374, lng: 17.3546476 },
    { lat: 48.7057462, lng: 17.3545656 },
    { lat: 48.7057409, lng: 17.3546415 },
    { lat: 48.7056901, lng: 17.3545918 },
    { lat: 48.7054246, lng: 17.3546251 },
    { lat: 48.7052228, lng: 17.3547509 },
    { lat: 48.7054236, lng: 17.3559845 },
    { lat: 48.7050424, lng: 17.3564262 },
    { lat: 48.7050885, lng: 17.356944 },
    { lat: 48.7050187, lng: 17.3587742 },
    { lat: 48.7048316, lng: 17.3598447 },
    { lat: 48.7049312, lng: 17.3610112 },
    { lat: 48.70534, lng: 17.363013 },
    { lat: 48.705833, lng: 17.3648299 },
    { lat: 48.7040411, lng: 17.3664963 },
    { lat: 48.7035978, lng: 17.3670286 },
    { lat: 48.7027449, lng: 17.368247 },
    { lat: 48.7025931, lng: 17.3686399 },
    { lat: 48.7029879, lng: 17.3700233 },
    { lat: 48.7027294, lng: 17.3702904 },
    { lat: 48.7015582, lng: 17.3710916 },
    { lat: 48.70041, lng: 17.3721472 },
    { lat: 48.7005951, lng: 17.3727731 },
    { lat: 48.7007733, lng: 17.3736743 },
    { lat: 48.7009059, lng: 17.3749898 },
    { lat: 48.7009352, lng: 17.3757722 },
    { lat: 48.7009053, lng: 17.3798275 },
    { lat: 48.7008006, lng: 17.3814896 },
    { lat: 48.7007917, lng: 17.3844066 },
    { lat: 48.7007255, lng: 17.3874893 },
    { lat: 48.6996928, lng: 17.3877816 },
    { lat: 48.6992991, lng: 17.3884164 },
    { lat: 48.6992086, lng: 17.3883965 },
    { lat: 48.699383, lng: 17.3905344 },
    { lat: 48.6996617, lng: 17.3931177 },
    { lat: 48.6997753, lng: 17.3947 },
    { lat: 48.7001552, lng: 17.3947777 },
    { lat: 48.7006609, lng: 17.3946477 },
    { lat: 48.7015236, lng: 17.394611 },
    { lat: 48.7018021, lng: 17.3946722 },
    { lat: 48.7022583, lng: 17.394896 },
    { lat: 48.7032154, lng: 17.3949912 },
    { lat: 48.7038326, lng: 17.3949642 },
    { lat: 48.7042889, lng: 17.3951758 },
    { lat: 48.7047454, lng: 17.3954761 },
    { lat: 48.7052515, lng: 17.3954401 },
    { lat: 48.7054654, lng: 17.395395 },
    { lat: 48.7054712, lng: 17.3953276 },
    { lat: 48.7063971, lng: 17.395342 },
    { lat: 48.7065857, lng: 17.3953918 },
    { lat: 48.7070957, lng: 17.3957314 },
    { lat: 48.7073308, lng: 17.3959264 },
    { lat: 48.7073519, lng: 17.3952917 },
    { lat: 48.7073854, lng: 17.3936929 },
    { lat: 48.7082982, lng: 17.3939774 },
    { lat: 48.7099446, lng: 17.3946705 },
    { lat: 48.7103332, lng: 17.3947762 },
    { lat: 48.710854, lng: 17.3947949 },
    { lat: 48.7118513, lng: 17.3946729 },
    { lat: 48.7135026, lng: 17.3945834 },
    { lat: 48.714371, lng: 17.394632 },
    { lat: 48.7159464, lng: 17.3948736 },
    { lat: 48.717786, lng: 17.3947803 },
    { lat: 48.7177098, lng: 17.3938827 },
    { lat: 48.7178271, lng: 17.3938082 },
    { lat: 48.7193104, lng: 17.3935689 },
    { lat: 48.7195898, lng: 17.3934866 },
    { lat: 48.7200329, lng: 17.3932123 },
    { lat: 48.7209142, lng: 17.3929535 },
    { lat: 48.7211259, lng: 17.3929963 },
    { lat: 48.7213311, lng: 17.3920979 },
    { lat: 48.7216592, lng: 17.3910253 },
    { lat: 48.7218372, lng: 17.3911056 },
    { lat: 48.7220475, lng: 17.3912992 },
    { lat: 48.7216601, lng: 17.3924721 },
    { lat: 48.7219489, lng: 17.392623 },
    { lat: 48.7216595, lng: 17.3938444 },
    { lat: 48.7215915, lng: 17.3943667 },
    { lat: 48.7219207, lng: 17.3940774 },
    { lat: 48.7219551, lng: 17.3937157 },
    { lat: 48.7221625, lng: 17.393621 },
    { lat: 48.7231101, lng: 17.3929049 },
    { lat: 48.7240886, lng: 17.3929648 },
    { lat: 48.7241784, lng: 17.3928993 },
    { lat: 48.7244075, lng: 17.3928816 },
    { lat: 48.7246985, lng: 17.3929445 },
    { lat: 48.7247631, lng: 17.3926331 },
    { lat: 48.7249002, lng: 17.3926229 },
    { lat: 48.7250241, lng: 17.3921266 },
    { lat: 48.7254653, lng: 17.3923842 },
    { lat: 48.725534, lng: 17.3922417 },
    { lat: 48.7260386, lng: 17.3916585 },
    { lat: 48.7256857, lng: 17.3907068 },
    { lat: 48.725936, lng: 17.390331 },
    { lat: 48.7263245, lng: 17.3899116 },
    { lat: 48.7272264, lng: 17.3883639 },
    { lat: 48.727344, lng: 17.3881211 },
    { lat: 48.7274817, lng: 17.3875606 },
    { lat: 48.7275898, lng: 17.3873099 },
    { lat: 48.7278706, lng: 17.3871472 },
    { lat: 48.7280562, lng: 17.3874511 },
    { lat: 48.7282744, lng: 17.3876818 },
    { lat: 48.7288625, lng: 17.3868018 },
    { lat: 48.7298197, lng: 17.386074 },
    { lat: 48.7300539, lng: 17.3858558 },
    { lat: 48.7302212, lng: 17.3855946 },
    { lat: 48.7305751, lng: 17.3844829 },
    { lat: 48.7312136, lng: 17.3830183 },
    { lat: 48.7313111, lng: 17.3828486 },
    { lat: 48.7316217, lng: 17.3826045 },
    { lat: 48.7318607, lng: 17.382513 },
    { lat: 48.7324504, lng: 17.3808522 },
    { lat: 48.7333207, lng: 17.3789939 },
    { lat: 48.7336458, lng: 17.3773876 },
    { lat: 48.7340263, lng: 17.3774175 },
    { lat: 48.7344986, lng: 17.3775767 },
    { lat: 48.7358228, lng: 17.3781612 },
    { lat: 48.7364554, lng: 17.3785987 },
    { lat: 48.7365692, lng: 17.3785579 },
    { lat: 48.7368008, lng: 17.3781768 },
    { lat: 48.7368359, lng: 17.3779702 },
    { lat: 48.7366868, lng: 17.3753511 },
    { lat: 48.7364983, lng: 17.3736936 },
    { lat: 48.7363602, lng: 17.3730456 },
    { lat: 48.7361996, lng: 17.3726049 },
    { lat: 48.736036, lng: 17.3722527 },
    { lat: 48.7358696, lng: 17.371888 },
    { lat: 48.7355847, lng: 17.3714135 },
    { lat: 48.735203, lng: 17.3701954 },
    { lat: 48.7353601, lng: 17.3693252 },
    { lat: 48.7352718, lng: 17.3692692 },
    { lat: 48.7352143, lng: 17.3690942 },
    { lat: 48.7352437, lng: 17.3684339 },
    { lat: 48.735215, lng: 17.3661799 },
    { lat: 48.7353243, lng: 17.3653397 },
    { lat: 48.7356048, lng: 17.3638271 },
    { lat: 48.7356002, lng: 17.3635812 },
    { lat: 48.7353565, lng: 17.362054 },
    { lat: 48.7351322, lng: 17.3616099 },
    { lat: 48.7345697, lng: 17.3610539 },
    { lat: 48.7340292, lng: 17.3600659 },
    { lat: 48.7337703, lng: 17.3602571 },
    { lat: 48.7334854, lng: 17.3603204 },
    { lat: 48.7333391, lng: 17.3600169 },
    { lat: 48.7332587, lng: 17.3591239 },
    { lat: 48.7331895, lng: 17.3590361 },
    { lat: 48.73305, lng: 17.3590224 },
    { lat: 48.7329615, lng: 17.359052 },
    { lat: 48.7328543, lng: 17.3592275 },
    { lat: 48.7326982, lng: 17.35912 },
    { lat: 48.7326461, lng: 17.3584558 },
    { lat: 48.7325534, lng: 17.3583003 },
    { lat: 48.7322586, lng: 17.3582842 },
    { lat: 48.7320239, lng: 17.3579033 },
    { lat: 48.7315386, lng: 17.3573691 },
    { lat: 48.731456, lng: 17.3570554 },
    { lat: 48.7313265, lng: 17.3569755 },
    { lat: 48.7311636, lng: 17.3570211 },
    { lat: 48.730818, lng: 17.3569529 },
    { lat: 48.7307698, lng: 17.3568874 },
    { lat: 48.730458, lng: 17.3568728 },
    { lat: 48.7302993, lng: 17.3567761 },
    { lat: 48.7301452, lng: 17.3565933 },
    { lat: 48.7299149, lng: 17.3561272 },
    { lat: 48.7296596, lng: 17.3558653 },
    { lat: 48.7294559, lng: 17.3558604 },
    { lat: 48.7291704, lng: 17.3560019 },
    { lat: 48.728916, lng: 17.3554951 },
    { lat: 48.7276651, lng: 17.3536592 },
    { lat: 48.7271419, lng: 17.3530306 },
    { lat: 48.7268726, lng: 17.3528154 },
    { lat: 48.7264689, lng: 17.3531895 },
    { lat: 48.7260885, lng: 17.3533167 },
    { lat: 48.725473, lng: 17.3533884 },
    { lat: 48.724879, lng: 17.3532837 },
    { lat: 48.7245624, lng: 17.3530702 },
    { lat: 48.7243807, lng: 17.3531261 },
    { lat: 48.724075, lng: 17.3519508 },
    { lat: 48.7235542, lng: 17.3506104 },
    { lat: 48.7235153, lng: 17.350394 },
    { lat: 48.7234332, lng: 17.3502076 },
    { lat: 48.7229588, lng: 17.34953 },
    { lat: 48.7227079, lng: 17.3489902 },
    { lat: 48.722628, lng: 17.3488725 },
    { lat: 48.7225324, lng: 17.3489311 },
    { lat: 48.7225177, lng: 17.3489125 },
  ],
  Rybky: [
    { lat: 48.737208, lng: 17.318707 },
    { lat: 48.737195, lng: 17.318575 },
    { lat: 48.73718, lng: 17.318328 },
    { lat: 48.737153, lng: 17.318051 },
    { lat: 48.737131, lng: 17.317745 },
    { lat: 48.7371, lng: 17.317413 },
    { lat: 48.737085, lng: 17.317201 },
    { lat: 48.737067, lng: 17.316984 },
    { lat: 48.73706, lng: 17.316801 },
    { lat: 48.737055, lng: 17.316553 },
    { lat: 48.737075, lng: 17.316371 },
    { lat: 48.73709, lng: 17.315981 },
    { lat: 48.737089, lng: 17.315728 },
    { lat: 48.737087, lng: 17.315445 },
    { lat: 48.737084, lng: 17.315216 },
    { lat: 48.73707, lng: 17.314985 },
    { lat: 48.737035, lng: 17.314748 },
    { lat: 48.736986, lng: 17.314565 },
    { lat: 48.736911, lng: 17.314313 },
    { lat: 48.736871, lng: 17.314172 },
    { lat: 48.736813, lng: 17.314033 },
    { lat: 48.736775, lng: 17.313862 },
    { lat: 48.736706, lng: 17.313666 },
    { lat: 48.736625, lng: 17.313397 },
    { lat: 48.736563, lng: 17.313163 },
    { lat: 48.736534, lng: 17.312979 },
    { lat: 48.735718, lng: 17.3134651 },
    { lat: 48.7341348, lng: 17.3148385 },
    { lat: 48.7337874, lng: 17.3150388 },
    { lat: 48.7336646, lng: 17.3150429 },
    { lat: 48.7334876, lng: 17.3149426 },
    { lat: 48.7326528, lng: 17.3153092 },
    { lat: 48.732316, lng: 17.3153115 },
    { lat: 48.7321147, lng: 17.3153367 },
    { lat: 48.7313866, lng: 17.3151938 },
    { lat: 48.729898, lng: 17.3152509 },
    { lat: 48.7295347, lng: 17.315169 },
    { lat: 48.729433, lng: 17.315265 },
    { lat: 48.7289903, lng: 17.3155801 },
    { lat: 48.7276625, lng: 17.3175438 },
    { lat: 48.7273852, lng: 17.3178839 },
    { lat: 48.7268103, lng: 17.3184211 },
    { lat: 48.7262699, lng: 17.3187954 },
    { lat: 48.7250098, lng: 17.3192369 },
    { lat: 48.7238708, lng: 17.3194543 },
    { lat: 48.7232648, lng: 17.3193961 },
    { lat: 48.7225642, lng: 17.3190626 },
    { lat: 48.7215028, lng: 17.3188452 },
    { lat: 48.7208668, lng: 17.3187736 },
    { lat: 48.7203305, lng: 17.3188289 },
    { lat: 48.7196186, lng: 17.3189836 },
    { lat: 48.718031, lng: 17.3195454 },
    { lat: 48.7148535, lng: 17.3204463 },
    { lat: 48.7132259, lng: 17.3207786 },
    { lat: 48.7130455, lng: 17.3208327 },
    { lat: 48.7126125, lng: 17.321003 },
    { lat: 48.7121405, lng: 17.3210751 },
    { lat: 48.7118717, lng: 17.3210408 },
    { lat: 48.710542, lng: 17.3203633 },
    { lat: 48.7108211, lng: 17.3186335 },
    { lat: 48.7108877, lng: 17.318493 },
    { lat: 48.7099924, lng: 17.3193665 },
    { lat: 48.7094931, lng: 17.319644 },
    { lat: 48.7083452, lng: 17.3201069 },
    { lat: 48.7080634, lng: 17.3203416 },
    { lat: 48.7073732, lng: 17.3206526 },
    { lat: 48.7064071, lng: 17.3212028 },
    { lat: 48.7055888, lng: 17.3219402 },
    { lat: 48.7049441, lng: 17.3230694 },
    { lat: 48.7056484, lng: 17.3250338 },
    { lat: 48.7063376, lng: 17.3272368 },
    { lat: 48.7062582, lng: 17.3272862 },
    { lat: 48.7059266, lng: 17.3282625 },
    { lat: 48.7052371, lng: 17.3298318 },
    { lat: 48.7045653, lng: 17.3308408 },
    { lat: 48.7038466, lng: 17.331723 },
    { lat: 48.7033768, lng: 17.332055 },
    { lat: 48.7033012, lng: 17.3321396 },
    { lat: 48.7033071, lng: 17.3321977 },
    { lat: 48.7027352, lng: 17.332782 },
    { lat: 48.7009605, lng: 17.3343761 },
    { lat: 48.6999384, lng: 17.3354689 },
    { lat: 48.6990931, lng: 17.3362432 },
    { lat: 48.6975196, lng: 17.3378584 },
    { lat: 48.697032, lng: 17.3382287 },
    { lat: 48.6962438, lng: 17.33856 },
    { lat: 48.6963676, lng: 17.3394226 },
    { lat: 48.6965365, lng: 17.3415296 },
    { lat: 48.6965722, lng: 17.3422888 },
    { lat: 48.6964633, lng: 17.3427512 },
    { lat: 48.6968278, lng: 17.3431076 },
    { lat: 48.6973072, lng: 17.3430968 },
    { lat: 48.6976871, lng: 17.3435199 },
    { lat: 48.6978533, lng: 17.3435918 },
    { lat: 48.6980145, lng: 17.3435532 },
    { lat: 48.6981434, lng: 17.3433796 },
    { lat: 48.6982764, lng: 17.3429758 },
    { lat: 48.6984392, lng: 17.3427539 },
    { lat: 48.6988394, lng: 17.3425493 },
    { lat: 48.6990006, lng: 17.3423701 },
    { lat: 48.699353, lng: 17.34237 },
    { lat: 48.7000865, lng: 17.3417866 },
    { lat: 48.700673, lng: 17.3434052 },
    { lat: 48.7007968, lng: 17.3435734 },
    { lat: 48.7011826, lng: 17.3437722 },
    { lat: 48.701751, lng: 17.3450256 },
    { lat: 48.7030327, lng: 17.3473321 },
    { lat: 48.7033503, lng: 17.3482015 },
    { lat: 48.7037862, lng: 17.3489321 },
    { lat: 48.7046045, lng: 17.3509027 },
    { lat: 48.7053512, lng: 17.3534226 },
    { lat: 48.7055581, lng: 17.3539662 },
    { lat: 48.7057462, lng: 17.3545656 },
    { lat: 48.7058374, lng: 17.3546476 },
    { lat: 48.7062182, lng: 17.3544606 },
    { lat: 48.7073068, lng: 17.3536747 },
    { lat: 48.7077351, lng: 17.3531366 },
    { lat: 48.7082765, lng: 17.3526382 },
    { lat: 48.7090398, lng: 17.3522153 },
    { lat: 48.7106744, lng: 17.3517993 },
    { lat: 48.7129805, lng: 17.350994 },
    { lat: 48.7133266, lng: 17.3510013 },
    { lat: 48.7140305, lng: 17.3514055 },
    { lat: 48.7145775, lng: 17.351339 },
    { lat: 48.7154263, lng: 17.351085 },
    { lat: 48.7163707, lng: 17.3505131 },
    { lat: 48.7168721, lng: 17.3503901 },
    { lat: 48.7174239, lng: 17.3500853 },
    { lat: 48.7189856, lng: 17.3494354 },
    { lat: 48.7191796, lng: 17.3495694 },
    { lat: 48.7200481, lng: 17.349414 },
    { lat: 48.7203069, lng: 17.3494328 },
    { lat: 48.7225177, lng: 17.3489125 },
    { lat: 48.7225113, lng: 17.3488691 },
    { lat: 48.7221231, lng: 17.3480921 },
    { lat: 48.722047, lng: 17.3476867 },
    { lat: 48.7220259, lng: 17.3471865 },
    { lat: 48.7218907, lng: 17.3463051 },
    { lat: 48.7219512, lng: 17.3457437 },
    { lat: 48.721871, lng: 17.3449071 },
    { lat: 48.7218927, lng: 17.3445633 },
    { lat: 48.7218224, lng: 17.3443526 },
    { lat: 48.7217203, lng: 17.3436516 },
    { lat: 48.7218479, lng: 17.3429723 },
    { lat: 48.7221627, lng: 17.3421496 },
    { lat: 48.7222171, lng: 17.3418245 },
    { lat: 48.7222374, lng: 17.3410595 },
    { lat: 48.7223096, lng: 17.3408166 },
    { lat: 48.7222443, lng: 17.339219 },
    { lat: 48.7219436, lng: 17.3390075 },
    { lat: 48.7217224, lng: 17.3389617 },
    { lat: 48.7217945, lng: 17.3381743 },
    { lat: 48.7217698, lng: 17.3377623 },
    { lat: 48.7218432, lng: 17.3373808 },
    { lat: 48.7213831, lng: 17.3349783 },
    { lat: 48.7209866, lng: 17.3332804 },
    { lat: 48.7217424, lng: 17.3328982 },
    { lat: 48.722275, lng: 17.3324044 },
    { lat: 48.7223575, lng: 17.3320387 },
    { lat: 48.7225088, lng: 17.3317632 },
    { lat: 48.7232491, lng: 17.3306939 },
    { lat: 48.7227384, lng: 17.3293407 },
    { lat: 48.722297, lng: 17.3278862 },
    { lat: 48.722245, lng: 17.3277148 },
    { lat: 48.7225715, lng: 17.3274047 },
    { lat: 48.7226856, lng: 17.3274546 },
    { lat: 48.722969, lng: 17.3270887 },
    { lat: 48.7230523, lng: 17.3268237 },
    { lat: 48.7235212, lng: 17.3264097 },
    { lat: 48.7238469, lng: 17.3258432 },
    { lat: 48.7241803, lng: 17.3254315 },
    { lat: 48.7251778, lng: 17.3250858 },
    { lat: 48.7253834, lng: 17.3247725 },
    { lat: 48.7254962, lng: 17.3248223 },
    { lat: 48.7255998, lng: 17.3243342 },
    { lat: 48.7258017, lng: 17.3243306 },
    { lat: 48.7259874, lng: 17.3239033 },
    { lat: 48.7264274, lng: 17.3237215 },
    { lat: 48.7267059, lng: 17.3234009 },
    { lat: 48.7270822, lng: 17.3231095 },
    { lat: 48.7275021, lng: 17.3232945 },
    { lat: 48.727661, lng: 17.3231674 },
    { lat: 48.7278219, lng: 17.3230179 },
    { lat: 48.7279013, lng: 17.3226278 },
    { lat: 48.728163, lng: 17.3224812 },
    { lat: 48.7285104, lng: 17.3221786 },
    { lat: 48.728821, lng: 17.3218164 },
    { lat: 48.7289243, lng: 17.3216139 },
    { lat: 48.7292951, lng: 17.3213947 },
    { lat: 48.7293382, lng: 17.3215371 },
    { lat: 48.7293986, lng: 17.3215074 },
    { lat: 48.729483, lng: 17.3219381 },
    { lat: 48.7295432, lng: 17.3220125 },
    { lat: 48.7309001, lng: 17.3216329 },
    { lat: 48.7314825, lng: 17.3214205 },
    { lat: 48.7321387, lng: 17.3208285 },
    { lat: 48.7324467, lng: 17.3207168 },
    { lat: 48.7329093, lng: 17.3204174 },
    { lat: 48.7338624, lng: 17.3201687 },
    { lat: 48.7353253, lng: 17.3199292 },
    { lat: 48.7361944, lng: 17.3192754 },
    { lat: 48.737208, lng: 17.318707 },
  ],
  Sekule: [
    { lat: 48.5833359, lng: 17.0431598 },
    { lat: 48.5830403, lng: 17.044017 },
    { lat: 48.5828302, lng: 17.0450483 },
    { lat: 48.582796, lng: 17.0455782 },
    { lat: 48.5829959, lng: 17.046077 },
    { lat: 48.584223, lng: 17.044092 },
    { lat: 48.5844631, lng: 17.0437852 },
    { lat: 48.5845025, lng: 17.0437484 },
    { lat: 48.5845983, lng: 17.0438264 },
    { lat: 48.5846849, lng: 17.0439901 },
    { lat: 48.5847656, lng: 17.0439605 },
    { lat: 48.585282, lng: 17.0434127 },
    { lat: 48.5858366, lng: 17.0425445 },
    { lat: 48.5873607, lng: 17.0426536 },
    { lat: 48.5883192, lng: 17.0425586 },
    { lat: 48.5884444, lng: 17.045252 },
    { lat: 48.5895674, lng: 17.0445174 },
    { lat: 48.5905773, lng: 17.0439794 },
    { lat: 48.590556, lng: 17.04378 },
    { lat: 48.593665, lng: 17.0433878 },
    { lat: 48.5953317, lng: 17.0430522 },
    { lat: 48.5961066, lng: 17.043098 },
    { lat: 48.597814, lng: 17.0424481 },
    { lat: 48.5985818, lng: 17.0422222 },
    { lat: 48.5991915, lng: 17.0417644 },
    { lat: 48.6000848, lng: 17.0408062 },
    { lat: 48.6012185, lng: 17.0403793 },
    { lat: 48.601369, lng: 17.0402318 },
    { lat: 48.6018114, lng: 17.0394931 },
    { lat: 48.6019417, lng: 17.0390649 },
    { lat: 48.6023267, lng: 17.0381747 },
    { lat: 48.6028039, lng: 17.0387647 },
    { lat: 48.6038268, lng: 17.0374347 },
    { lat: 48.6043753, lng: 17.0380392 },
    { lat: 48.6047551, lng: 17.0374114 },
    { lat: 48.6044993, lng: 17.0371385 },
    { lat: 48.6049879, lng: 17.0361633 },
    { lat: 48.6052563, lng: 17.0364857 },
    { lat: 48.6061052, lng: 17.0345255 },
    { lat: 48.6078035, lng: 17.0314952 },
    { lat: 48.6081571, lng: 17.031863 },
    { lat: 48.6086273, lng: 17.0310141 },
    { lat: 48.6088052, lng: 17.0308548 },
    { lat: 48.6109098, lng: 17.0269851 },
    { lat: 48.611326, lng: 17.0264101 },
    { lat: 48.6149005, lng: 17.0229373 },
    { lat: 48.617994, lng: 17.0188591 },
    { lat: 48.6190132, lng: 17.0176789 },
    { lat: 48.6190898, lng: 17.0173738 },
    { lat: 48.6203525, lng: 17.0163526 },
    { lat: 48.6206955, lng: 17.0155633 },
    { lat: 48.6211361, lng: 17.014999 },
    { lat: 48.6214001, lng: 17.0145252 },
    { lat: 48.6218947, lng: 17.0138732 },
    { lat: 48.6222041, lng: 17.0135731 },
    { lat: 48.6227988, lng: 17.0128434 },
    { lat: 48.6234195, lng: 17.0122522 },
    { lat: 48.6236863, lng: 17.0113575 },
    { lat: 48.6235828, lng: 17.0111307 },
    { lat: 48.6234585, lng: 17.0112328 },
    { lat: 48.6233426, lng: 17.0109736 },
    { lat: 48.6251989, lng: 17.0089603 },
    { lat: 48.625382, lng: 17.0085958 },
    { lat: 48.6257841, lng: 17.008192 },
    { lat: 48.6260129, lng: 17.0080372 },
    { lat: 48.6260348, lng: 17.0073783 },
    { lat: 48.6258324, lng: 17.0074097 },
    { lat: 48.6258301, lng: 17.0053021 },
    { lat: 48.6266085, lng: 17.0054158 },
    { lat: 48.6268806, lng: 17.004183 },
    { lat: 48.6269062, lng: 17.0039115 },
    { lat: 48.6270482, lng: 17.003459 },
    { lat: 48.6271903, lng: 17.0027575 },
    { lat: 48.6269511, lng: 17.0025831 },
    { lat: 48.6268526, lng: 17.0022983 },
    { lat: 48.626346, lng: 17.0016796 },
    { lat: 48.6260026, lng: 17.0011331 },
    { lat: 48.6254231, lng: 16.9994805 },
    { lat: 48.6250152, lng: 16.9986386 },
    { lat: 48.6241173, lng: 16.9970701 },
    { lat: 48.6237854, lng: 16.9961004 },
    { lat: 48.6245147, lng: 16.9945298 },
    { lat: 48.6264053, lng: 16.9907397 },
    { lat: 48.626709, lng: 16.9896076 },
    { lat: 48.6281975, lng: 16.985706 },
    { lat: 48.6285023, lng: 16.9847737 },
    { lat: 48.6288292, lng: 16.9836037 },
    { lat: 48.6289435, lng: 16.9819697 },
    { lat: 48.6289408, lng: 16.9813035 },
    { lat: 48.6289223, lng: 16.981182 },
    { lat: 48.6285041, lng: 16.9812957 },
    { lat: 48.6280959, lng: 16.9810759 },
    { lat: 48.6295475, lng: 16.9766895 },
    { lat: 48.6310742, lng: 16.977613 },
    { lat: 48.6311366, lng: 16.9776494 },
    { lat: 48.6314221, lng: 16.9773609 },
    { lat: 48.6321029, lng: 16.9763107 },
    { lat: 48.6321592, lng: 16.976109 },
    { lat: 48.6323929, lng: 16.9760222 },
    { lat: 48.6324862, lng: 16.9758519 },
    { lat: 48.6328108, lng: 16.975706 },
    { lat: 48.6330657, lng: 16.9757796 },
    { lat: 48.6331555, lng: 16.9755073 },
    { lat: 48.6332801, lng: 16.9753975 },
    { lat: 48.6332041, lng: 16.9749302 },
    { lat: 48.6329042, lng: 16.9746096 },
    { lat: 48.6329623, lng: 16.9742686 },
    { lat: 48.6329108, lng: 16.9739616 },
    { lat: 48.6330834, lng: 16.9735945 },
    { lat: 48.632817, lng: 16.9735506 },
    { lat: 48.6326524, lng: 16.973294 },
    { lat: 48.6331493, lng: 16.9727929 },
    { lat: 48.6331457, lng: 16.9726223 },
    { lat: 48.6326991, lng: 16.9726851 },
    { lat: 48.6324907, lng: 16.9724149 },
    { lat: 48.6324736, lng: 16.9719017 },
    { lat: 48.6321245, lng: 16.9721171 },
    { lat: 48.6318574, lng: 16.9717504 },
    { lat: 48.6322505, lng: 16.9710857 },
    { lat: 48.6320472, lng: 16.9710524 },
    { lat: 48.6318188, lng: 16.9707046 },
    { lat: 48.6318846, lng: 16.9705457 },
    { lat: 48.6317914, lng: 16.9701554 },
    { lat: 48.6319219, lng: 16.9697335 },
    { lat: 48.6319114, lng: 16.9694972 },
    { lat: 48.6319756, lng: 16.9692496 },
    { lat: 48.6318932, lng: 16.9688208 },
    { lat: 48.6319442, lng: 16.9683781 },
    { lat: 48.6320173, lng: 16.9681416 },
    { lat: 48.6324422, lng: 16.9675452 },
    { lat: 48.6320812, lng: 16.9671113 },
    { lat: 48.6317709, lng: 16.9669192 },
    { lat: 48.631693, lng: 16.9668052 },
    { lat: 48.6316159, lng: 16.9661745 },
    { lat: 48.6315071, lng: 16.9659381 },
    { lat: 48.6310419, lng: 16.9657116 },
    { lat: 48.6311912, lng: 16.9644275 },
    { lat: 48.6314083, lng: 16.9636687 },
    { lat: 48.6317161, lng: 16.9630191 },
    { lat: 48.6322668, lng: 16.9623637 },
    { lat: 48.63232, lng: 16.962314 },
    { lat: 48.631857, lng: 16.962006 },
    { lat: 48.631193, lng: 16.961402 },
    { lat: 48.630696, lng: 16.960865 },
    { lat: 48.6303, lng: 16.960399 },
    { lat: 48.629376, lng: 16.958927 },
    { lat: 48.6291245, lng: 16.9585657 },
    { lat: 48.629012, lng: 16.958404 },
    { lat: 48.628701, lng: 16.95797 },
    { lat: 48.628291, lng: 16.957586 },
    { lat: 48.627805, lng: 16.957326 },
    { lat: 48.627276, lng: 16.957196 },
    { lat: 48.626877, lng: 16.957185 },
    { lat: 48.626352, lng: 16.9572 },
    { lat: 48.625894, lng: 16.957256 },
    { lat: 48.625638, lng: 16.957296 },
    { lat: 48.625417, lng: 16.95733 },
    { lat: 48.625272, lng: 16.957323 },
    { lat: 48.625218, lng: 16.95732 },
    { lat: 48.625172, lng: 16.957317 },
    { lat: 48.62494, lng: 16.957304 },
    { lat: 48.624339, lng: 16.957054 },
    { lat: 48.624079, lng: 16.956873 },
    { lat: 48.623843, lng: 16.956709 },
    { lat: 48.623805, lng: 16.956665 },
    { lat: 48.623791, lng: 16.956649 },
    { lat: 48.623753, lng: 16.956604 },
    { lat: 48.623595, lng: 16.95642 },
    { lat: 48.623482, lng: 16.956288 },
    { lat: 48.623469, lng: 16.956274 },
    { lat: 48.623449, lng: 16.95625 },
    { lat: 48.62337, lng: 16.956158 },
    { lat: 48.623187, lng: 16.955853 },
    { lat: 48.622968, lng: 16.955386 },
    { lat: 48.622873, lng: 16.955185 },
    { lat: 48.622564, lng: 16.954392 },
    { lat: 48.622347, lng: 16.953422 },
    { lat: 48.622331, lng: 16.953177 },
    { lat: 48.622309, lng: 16.952794 },
    { lat: 48.622262, lng: 16.952118 },
    { lat: 48.622205, lng: 16.950868 },
    { lat: 48.622184, lng: 16.950325 },
    { lat: 48.622206, lng: 16.949724 },
    { lat: 48.622214, lng: 16.948876 },
    { lat: 48.6221444, lng: 16.9484964 },
    { lat: 48.622083, lng: 16.948158 },
    { lat: 48.62208, lng: 16.948143 },
    { lat: 48.621845, lng: 16.947504 },
    { lat: 48.621731, lng: 16.947195 },
    { lat: 48.621367, lng: 16.946515 },
    { lat: 48.621153, lng: 16.946082 },
    { lat: 48.620887, lng: 16.945601 },
    { lat: 48.620657, lng: 16.945298 },
    { lat: 48.620108, lng: 16.944609 },
    { lat: 48.61976, lng: 16.944171 },
    { lat: 48.619377, lng: 16.943609 },
    { lat: 48.618832, lng: 16.94281 },
    { lat: 48.618777, lng: 16.942732 },
    { lat: 48.618225, lng: 16.942087 },
    { lat: 48.6178955, lng: 16.9417024 },
    { lat: 48.617171, lng: 16.940888 },
    { lat: 48.617118, lng: 16.940829 },
    { lat: 48.616978, lng: 16.940676 },
    { lat: 48.616723, lng: 16.940396 },
    { lat: 48.6165408, lng: 16.9401953 },
    { lat: 48.6163212, lng: 16.9400677 },
    { lat: 48.615872, lng: 16.939788 },
    { lat: 48.615387, lng: 16.939518 },
    { lat: 48.615143, lng: 16.939428 },
    { lat: 48.614851, lng: 16.939358 },
    { lat: 48.614428, lng: 16.939355 },
    { lat: 48.614275, lng: 16.939409 },
    { lat: 48.613853, lng: 16.939533 },
    { lat: 48.613443, lng: 16.939714 },
    { lat: 48.613017, lng: 16.939966 },
    { lat: 48.612642, lng: 16.940244 },
    { lat: 48.611485, lng: 16.94133 },
    { lat: 48.611117, lng: 16.941635 },
    { lat: 48.610833, lng: 16.941809 },
    { lat: 48.610503, lng: 16.941971 },
    { lat: 48.610144, lng: 16.942092 },
    { lat: 48.609817, lng: 16.942171 },
    { lat: 48.609445, lng: 16.942208 },
    { lat: 48.609021, lng: 16.942205 },
    { lat: 48.608097, lng: 16.9422 },
    { lat: 48.606638, lng: 16.942087 },
    { lat: 48.606428, lng: 16.942042 },
    { lat: 48.606053, lng: 16.941923 },
    { lat: 48.605648, lng: 16.941707 },
    { lat: 48.605247, lng: 16.94145 },
    { lat: 48.604758, lng: 16.940993 },
    { lat: 48.604439, lng: 16.94065 },
    { lat: 48.604083, lng: 16.940196 },
    { lat: 48.603729, lng: 16.939682 },
    { lat: 48.603513, lng: 16.939331 },
    { lat: 48.603321, lng: 16.938934 },
    { lat: 48.603153, lng: 16.938531 },
    { lat: 48.602959, lng: 16.937968 },
    { lat: 48.6028157, lng: 16.9385813 },
    { lat: 48.6012435, lng: 16.9435786 },
    { lat: 48.5997286, lng: 16.9497746 },
    { lat: 48.598098, lng: 16.9576463 },
    { lat: 48.5980313, lng: 16.9576313 },
    { lat: 48.5975276, lng: 16.9606733 },
    { lat: 48.593648, lng: 16.9841032 },
    { lat: 48.5948725, lng: 16.9843924 },
    { lat: 48.5950691, lng: 16.984328 },
    { lat: 48.5950073, lng: 16.9854225 },
    { lat: 48.5946522, lng: 16.9881823 },
    { lat: 48.5944487, lng: 16.991835 },
    { lat: 48.5944571, lng: 16.9953527 },
    { lat: 48.5942906, lng: 16.9998974 },
    { lat: 48.5944789, lng: 17.0004883 },
    { lat: 48.594514, lng: 17.000787 },
    { lat: 48.5944657, lng: 17.0013359 },
    { lat: 48.5944465, lng: 17.0015539 },
    { lat: 48.5942179, lng: 17.0022291 },
    { lat: 48.5938076, lng: 17.0040016 },
    { lat: 48.5933294, lng: 17.0048925 },
    { lat: 48.5932485, lng: 17.0051656 },
    { lat: 48.5929725, lng: 17.0068344 },
    { lat: 48.5927126, lng: 17.0073984 },
    { lat: 48.5923749, lng: 17.0094405 },
    { lat: 48.5922002, lng: 17.0134453 },
    { lat: 48.591041, lng: 17.0190103 },
    { lat: 48.5904552, lng: 17.0210598 },
    { lat: 48.5900498, lng: 17.0229605 },
    { lat: 48.5900792, lng: 17.0257309 },
    { lat: 48.5884535, lng: 17.0326875 },
    { lat: 48.5886968, lng: 17.0327753 },
    { lat: 48.588389, lng: 17.0347842 },
    { lat: 48.587904, lng: 17.0362096 },
    { lat: 48.587737, lng: 17.0370938 },
    { lat: 48.5872401, lng: 17.0386821 },
    { lat: 48.5866729, lng: 17.0398946 },
    { lat: 48.5857308, lng: 17.0411181 },
    { lat: 48.5851885, lng: 17.0420519 },
    { lat: 48.5849857, lng: 17.0424415 },
    { lat: 48.5847251, lng: 17.0431834 },
    { lat: 48.5844718, lng: 17.043645 },
    { lat: 48.583714, lng: 17.0433196 },
    { lat: 48.5833359, lng: 17.0431598 },
  ],
  Senica: [
    { lat: 48.6992086, lng: 17.3883965 },
    { lat: 48.6992991, lng: 17.3884164 },
    { lat: 48.6996928, lng: 17.3877816 },
    { lat: 48.7007255, lng: 17.3874893 },
    { lat: 48.7007917, lng: 17.3844066 },
    { lat: 48.7008006, lng: 17.3814896 },
    { lat: 48.7009053, lng: 17.3798275 },
    { lat: 48.7009352, lng: 17.3757722 },
    { lat: 48.7009059, lng: 17.3749898 },
    { lat: 48.7007733, lng: 17.3736743 },
    { lat: 48.7005951, lng: 17.3727731 },
    { lat: 48.70041, lng: 17.3721472 },
    { lat: 48.7015582, lng: 17.3710916 },
    { lat: 48.7027294, lng: 17.3702904 },
    { lat: 48.7029879, lng: 17.3700233 },
    { lat: 48.7025931, lng: 17.3686399 },
    { lat: 48.7027449, lng: 17.368247 },
    { lat: 48.7035978, lng: 17.3670286 },
    { lat: 48.7040411, lng: 17.3664963 },
    { lat: 48.705833, lng: 17.3648299 },
    { lat: 48.70534, lng: 17.363013 },
    { lat: 48.7049312, lng: 17.3610112 },
    { lat: 48.7048316, lng: 17.3598447 },
    { lat: 48.7050187, lng: 17.3587742 },
    { lat: 48.7050885, lng: 17.356944 },
    { lat: 48.7050424, lng: 17.3564262 },
    { lat: 48.7054236, lng: 17.3559845 },
    { lat: 48.7052228, lng: 17.3547509 },
    { lat: 48.7054246, lng: 17.3546251 },
    { lat: 48.7056901, lng: 17.3545918 },
    { lat: 48.7057409, lng: 17.3546415 },
    { lat: 48.7057462, lng: 17.3545656 },
    { lat: 48.7055581, lng: 17.3539662 },
    { lat: 48.7053512, lng: 17.3534226 },
    { lat: 48.7046045, lng: 17.3509027 },
    { lat: 48.7037862, lng: 17.3489321 },
    { lat: 48.7033503, lng: 17.3482015 },
    { lat: 48.7030327, lng: 17.3473321 },
    { lat: 48.701751, lng: 17.3450256 },
    { lat: 48.7011826, lng: 17.3437722 },
    { lat: 48.7007968, lng: 17.3435734 },
    { lat: 48.700673, lng: 17.3434052 },
    { lat: 48.7000865, lng: 17.3417866 },
    { lat: 48.699353, lng: 17.34237 },
    { lat: 48.6990006, lng: 17.3423701 },
    { lat: 48.6988394, lng: 17.3425493 },
    { lat: 48.6984392, lng: 17.3427539 },
    { lat: 48.6982764, lng: 17.3429758 },
    { lat: 48.6981434, lng: 17.3433796 },
    { lat: 48.6980145, lng: 17.3435532 },
    { lat: 48.6978533, lng: 17.3435918 },
    { lat: 48.6976871, lng: 17.3435199 },
    { lat: 48.6973072, lng: 17.3430968 },
    { lat: 48.6968278, lng: 17.3431076 },
    { lat: 48.6964633, lng: 17.3427512 },
    { lat: 48.6965722, lng: 17.3422888 },
    { lat: 48.6965365, lng: 17.3415296 },
    { lat: 48.6963676, lng: 17.3394226 },
    { lat: 48.6962438, lng: 17.33856 },
    { lat: 48.697032, lng: 17.3382287 },
    { lat: 48.6975196, lng: 17.3378584 },
    { lat: 48.6990931, lng: 17.3362432 },
    { lat: 48.6999384, lng: 17.3354689 },
    { lat: 48.7009605, lng: 17.3343761 },
    { lat: 48.7027352, lng: 17.332782 },
    { lat: 48.7033071, lng: 17.3321977 },
    { lat: 48.7033012, lng: 17.3321396 },
    { lat: 48.7033768, lng: 17.332055 },
    { lat: 48.7038466, lng: 17.331723 },
    { lat: 48.7045653, lng: 17.3308408 },
    { lat: 48.7052371, lng: 17.3298318 },
    { lat: 48.7059266, lng: 17.3282625 },
    { lat: 48.7062582, lng: 17.3272862 },
    { lat: 48.7063376, lng: 17.3272368 },
    { lat: 48.7056484, lng: 17.3250338 },
    { lat: 48.7049441, lng: 17.3230694 },
    { lat: 48.7055888, lng: 17.3219402 },
    { lat: 48.7064071, lng: 17.3212028 },
    { lat: 48.7073732, lng: 17.3206526 },
    { lat: 48.7080634, lng: 17.3203416 },
    { lat: 48.7083452, lng: 17.3201069 },
    { lat: 48.7094931, lng: 17.319644 },
    { lat: 48.7099924, lng: 17.3193665 },
    { lat: 48.7108877, lng: 17.318493 },
    { lat: 48.7109494, lng: 17.318315 },
    { lat: 48.7112529, lng: 17.3175827 },
    { lat: 48.7113846, lng: 17.317073 },
    { lat: 48.7118345, lng: 17.316262 },
    { lat: 48.7118054, lng: 17.316198 },
    { lat: 48.7119169, lng: 17.3161431 },
    { lat: 48.711857, lng: 17.3159003 },
    { lat: 48.7121446, lng: 17.3158384 },
    { lat: 48.7120886, lng: 17.3152605 },
    { lat: 48.712295, lng: 17.3151829 },
    { lat: 48.7124033, lng: 17.31404 },
    { lat: 48.7124048, lng: 17.3125078 },
    { lat: 48.7123213, lng: 17.3117155 },
    { lat: 48.7113922, lng: 17.3072618 },
    { lat: 48.7111617, lng: 17.3062053 },
    { lat: 48.7106345, lng: 17.3042735 },
    { lat: 48.7105511, lng: 17.3042942 },
    { lat: 48.7099989, lng: 17.3041437 },
    { lat: 48.7093913, lng: 17.3038872 },
    { lat: 48.7077103, lng: 17.3036603 },
    { lat: 48.7062248, lng: 17.3035541 },
    { lat: 48.7058068, lng: 17.3036731 },
    { lat: 48.7042762, lng: 17.3037453 },
    { lat: 48.7033744, lng: 17.30367 },
    { lat: 48.7019743, lng: 17.3038887 },
    { lat: 48.6982625, lng: 17.3049605 },
    { lat: 48.6973577, lng: 17.3048038 },
    { lat: 48.6958612, lng: 17.3041339 },
    { lat: 48.6950269, lng: 17.3040196 },
    { lat: 48.6933508, lng: 17.3035246 },
    { lat: 48.6921972, lng: 17.3033489 },
    { lat: 48.6910845, lng: 17.303347 },
    { lat: 48.6900137, lng: 17.3032108 },
    { lat: 48.6895755, lng: 17.3029852 },
    { lat: 48.6888285, lng: 17.3029322 },
    { lat: 48.6884774, lng: 17.3028301 },
    { lat: 48.6853958, lng: 17.3012913 },
    { lat: 48.6847536, lng: 17.3010565 },
    { lat: 48.6839254, lng: 17.3010214 },
    { lat: 48.682939, lng: 17.3013274 },
    { lat: 48.6822721, lng: 17.3014599 },
    { lat: 48.6812808, lng: 17.3016568 },
    { lat: 48.6784779, lng: 17.3012555 },
    { lat: 48.6742302, lng: 17.3003712 },
    { lat: 48.6733766, lng: 17.3003053 },
    { lat: 48.6721631, lng: 17.2999575 },
    { lat: 48.66697, lng: 17.2987306 },
    { lat: 48.666902, lng: 17.2987108 },
    { lat: 48.6668196, lng: 17.2986934 },
    { lat: 48.6662784, lng: 17.2991766 },
    { lat: 48.6661269, lng: 17.2994151 },
    { lat: 48.665875, lng: 17.3000538 },
    { lat: 48.6658137, lng: 17.300669 },
    { lat: 48.6651928, lng: 17.3006219 },
    { lat: 48.664953, lng: 17.3012574 },
    { lat: 48.6647716, lng: 17.3018351 },
    { lat: 48.6646581, lng: 17.3023941 },
    { lat: 48.6646529, lng: 17.3027626 },
    { lat: 48.6647443, lng: 17.3031256 },
    { lat: 48.6651261, lng: 17.3039889 },
    { lat: 48.6648303, lng: 17.3044754 },
    { lat: 48.6643558, lng: 17.3046112 },
    { lat: 48.6642016, lng: 17.3048951 },
    { lat: 48.664236, lng: 17.3054714 },
    { lat: 48.664587, lng: 17.3059843 },
    { lat: 48.6646948, lng: 17.3062541 },
    { lat: 48.664704, lng: 17.3066194 },
    { lat: 48.6644778, lng: 17.3069751 },
    { lat: 48.6642484, lng: 17.3070111 },
    { lat: 48.6638956, lng: 17.3067092 },
    { lat: 48.663726, lng: 17.3069168 },
    { lat: 48.6636738, lng: 17.3071865 },
    { lat: 48.6636983, lng: 17.3072857 },
    { lat: 48.6639576, lng: 17.3076241 },
    { lat: 48.6639501, lng: 17.3077149 },
    { lat: 48.6637272, lng: 17.307865 },
    { lat: 48.6637724, lng: 17.3080569 },
    { lat: 48.6624714, lng: 17.3135558 },
    { lat: 48.6620469, lng: 17.3140761 },
    { lat: 48.6619791, lng: 17.3140776 },
    { lat: 48.6621138, lng: 17.3146493 },
    { lat: 48.6624071, lng: 17.3142815 },
    { lat: 48.6629717, lng: 17.3153323 },
    { lat: 48.6626239, lng: 17.3155956 },
    { lat: 48.6638554, lng: 17.3178982 },
    { lat: 48.6630114, lng: 17.3187071 },
    { lat: 48.6636504, lng: 17.3208993 },
    { lat: 48.6636154, lng: 17.3209867 },
    { lat: 48.6633818, lng: 17.3209725 },
    { lat: 48.663252, lng: 17.320662 },
    { lat: 48.6631243, lng: 17.3206613 },
    { lat: 48.6624934, lng: 17.3232483 },
    { lat: 48.6639807, lng: 17.3294202 },
    { lat: 48.6631813, lng: 17.3302065 },
    { lat: 48.663035, lng: 17.3310808 },
    { lat: 48.6628943, lng: 17.3319358 },
    { lat: 48.6623737, lng: 17.3343457 },
    { lat: 48.6624648, lng: 17.3358158 },
    { lat: 48.6618011, lng: 17.340572 },
    { lat: 48.6605703, lng: 17.3422261 },
    { lat: 48.6586668, lng: 17.3465803 },
    { lat: 48.657985, lng: 17.3478359 },
    { lat: 48.6560016, lng: 17.3510514 },
    { lat: 48.6544987, lng: 17.3533355 },
    { lat: 48.6543205, lng: 17.3538921 },
    { lat: 48.6540742, lng: 17.3543094 },
    { lat: 48.6490843, lng: 17.3569076 },
    { lat: 48.6489823, lng: 17.3581536 },
    { lat: 48.6478434, lng: 17.3582676 },
    { lat: 48.6468265, lng: 17.357848 },
    { lat: 48.6465749, lng: 17.3576074 },
    { lat: 48.6462672, lng: 17.3576504 },
    { lat: 48.6457715, lng: 17.3619477 },
    { lat: 48.6454594, lng: 17.3622046 },
    { lat: 48.6451514, lng: 17.3622945 },
    { lat: 48.6451089, lng: 17.3625622 },
    { lat: 48.6449083, lng: 17.3626226 },
    { lat: 48.6448298, lng: 17.363051 },
    { lat: 48.6449512, lng: 17.3639279 },
    { lat: 48.640369, lng: 17.3658449 },
    { lat: 48.6405743, lng: 17.3672672 },
    { lat: 48.640368, lng: 17.3676558 },
    { lat: 48.640043, lng: 17.3681846 },
    { lat: 48.639927, lng: 17.3682425 },
    { lat: 48.6394254, lng: 17.3687634 },
    { lat: 48.6388184, lng: 17.3691035 },
    { lat: 48.6383497, lng: 17.3691388 },
    { lat: 48.638051, lng: 17.3694973 },
    { lat: 48.6373214, lng: 17.3700533 },
    { lat: 48.6372911, lng: 17.370477 },
    { lat: 48.6373233, lng: 17.370798 },
    { lat: 48.6372724, lng: 17.3710748 },
    { lat: 48.6366682, lng: 17.3712694 },
    { lat: 48.6362966, lng: 17.3714957 },
    { lat: 48.6349635, lng: 17.3726912 },
    { lat: 48.6344268, lng: 17.3730211 },
    { lat: 48.634313, lng: 17.3731461 },
    { lat: 48.6342189, lng: 17.3731397 },
    { lat: 48.6339942, lng: 17.3729165 },
    { lat: 48.6337065, lng: 17.3728165 },
    { lat: 48.6333064, lng: 17.3733187 },
    { lat: 48.6331138, lng: 17.3733258 },
    { lat: 48.6324197, lng: 17.3741714 },
    { lat: 48.6324383, lng: 17.3742647 },
    { lat: 48.632171, lng: 17.374497 },
    { lat: 48.6312538, lng: 17.3747771 },
    { lat: 48.6310714, lng: 17.3746797 },
    { lat: 48.6307644, lng: 17.3747022 },
    { lat: 48.6307383, lng: 17.3746464 },
    { lat: 48.6304021, lng: 17.3756298 },
    { lat: 48.6298743, lng: 17.3766526 },
    { lat: 48.6299036, lng: 17.3766847 },
    { lat: 48.6298524, lng: 17.3767844 },
    { lat: 48.629465, lng: 17.3772393 },
    { lat: 48.6283178, lng: 17.3781671 },
    { lat: 48.6269881, lng: 17.3795901 },
    { lat: 48.6265881, lng: 17.3801126 },
    { lat: 48.6262327, lng: 17.3807452 },
    { lat: 48.626038, lng: 17.381331 },
    { lat: 48.6263025, lng: 17.3826919 },
    { lat: 48.625218, lng: 17.3841015 },
    { lat: 48.6244641, lng: 17.3852751 },
    { lat: 48.6244177, lng: 17.3853474 },
    { lat: 48.6318953, lng: 17.3943727 },
    { lat: 48.6343293, lng: 17.3974645 },
    { lat: 48.6349384, lng: 17.3958589 },
    { lat: 48.6351183, lng: 17.3956039 },
    { lat: 48.6348512, lng: 17.3949497 },
    { lat: 48.6363789, lng: 17.3927428 },
    { lat: 48.6366289, lng: 17.3918735 },
    { lat: 48.637269, lng: 17.3901925 },
    { lat: 48.6381058, lng: 17.388325 },
    { lat: 48.638597, lng: 17.3874855 },
    { lat: 48.6387741, lng: 17.3872704 },
    { lat: 48.6388657, lng: 17.3874137 },
    { lat: 48.6394019, lng: 17.3871804 },
    { lat: 48.6399986, lng: 17.3875542 },
    { lat: 48.6401891, lng: 17.3876157 },
    { lat: 48.6405711, lng: 17.3875491 },
    { lat: 48.6410951, lng: 17.3873739 },
    { lat: 48.6419743, lng: 17.3868061 },
    { lat: 48.6426121, lng: 17.3862049 },
    { lat: 48.6430658, lng: 17.3851375 },
    { lat: 48.6431486, lng: 17.3845485 },
    { lat: 48.6432848, lng: 17.3840888 },
    { lat: 48.6433651, lng: 17.3844552 },
    { lat: 48.6436285, lng: 17.3836909 },
    { lat: 48.6443094, lng: 17.3859385 },
    { lat: 48.6442496, lng: 17.3866752 },
    { lat: 48.644256, lng: 17.3871711 },
    { lat: 48.6442831, lng: 17.3874696 },
    { lat: 48.6443708, lng: 17.3876724 },
    { lat: 48.6446154, lng: 17.3880478 },
    { lat: 48.644931, lng: 17.3883881 },
    { lat: 48.6451682, lng: 17.3888068 },
    { lat: 48.6457053, lng: 17.3893051 },
    { lat: 48.6461701, lng: 17.3902903 },
    { lat: 48.6464476, lng: 17.3906505 },
    { lat: 48.6470642, lng: 17.3909476 },
    { lat: 48.6473563, lng: 17.3913246 },
    { lat: 48.6484917, lng: 17.3922688 },
    { lat: 48.6489875, lng: 17.392564 },
    { lat: 48.6513718, lng: 17.3936137 },
    { lat: 48.6539964, lng: 17.394463 },
    { lat: 48.6546784, lng: 17.3947383 },
    { lat: 48.6558521, lng: 17.3953167 },
    { lat: 48.6583954, lng: 17.3967097 },
    { lat: 48.6601662, lng: 17.3977372 },
    { lat: 48.6603546, lng: 17.3979106 },
    { lat: 48.6609177, lng: 17.3974822 },
    { lat: 48.6624345, lng: 17.3969375 },
    { lat: 48.6628987, lng: 17.3965876 },
    { lat: 48.66315, lng: 17.3961075 },
    { lat: 48.6632637, lng: 17.3959993 },
    { lat: 48.6635035, lng: 17.3959153 },
    { lat: 48.6635095, lng: 17.3959888 },
    { lat: 48.6649482, lng: 17.3967573 },
    { lat: 48.6650093, lng: 17.3968958 },
    { lat: 48.664997, lng: 17.3973879 },
    { lat: 48.6654936, lng: 17.3975559 },
    { lat: 48.6669928, lng: 17.3978603 },
    { lat: 48.6684142, lng: 17.3983055 },
    { lat: 48.6673865, lng: 17.4016107 },
    { lat: 48.6670469, lng: 17.4030847 },
    { lat: 48.6667525, lng: 17.4048078 },
    { lat: 48.6666199, lng: 17.4061122 },
    { lat: 48.6665592, lng: 17.4079549 },
    { lat: 48.6679789, lng: 17.4077768 },
    { lat: 48.6679969, lng: 17.4080232 },
    { lat: 48.667869, lng: 17.4082446 },
    { lat: 48.6678737, lng: 17.4084051 },
    { lat: 48.6680033, lng: 17.4088227 },
    { lat: 48.6678659, lng: 17.4101049 },
    { lat: 48.668326, lng: 17.4101291 },
    { lat: 48.6688248, lng: 17.4129234 },
    { lat: 48.668761, lng: 17.4133701 },
    { lat: 48.6685495, lng: 17.4142077 },
    { lat: 48.6683069, lng: 17.4145271 },
    { lat: 48.6682495, lng: 17.4152484 },
    { lat: 48.667992, lng: 17.4162814 },
    { lat: 48.668002, lng: 17.418893 },
    { lat: 48.6684567, lng: 17.4199365 },
    { lat: 48.6684129, lng: 17.4206411 },
    { lat: 48.6682584, lng: 17.4207863 },
    { lat: 48.6684289, lng: 17.4212212 },
    { lat: 48.6682929, lng: 17.4215317 },
    { lat: 48.6680754, lng: 17.4227285 },
    { lat: 48.6673881, lng: 17.4240707 },
    { lat: 48.6672541, lng: 17.4247093 },
    { lat: 48.6670351, lng: 17.4251166 },
    { lat: 48.667078, lng: 17.4255176 },
    { lat: 48.6674165, lng: 17.4261622 },
    { lat: 48.66803, lng: 17.4266561 },
    { lat: 48.6685112, lng: 17.4259205 },
    { lat: 48.6698472, lng: 17.4250422 },
    { lat: 48.6703874, lng: 17.4250098 },
    { lat: 48.6707538, lng: 17.424911 },
    { lat: 48.6711589, lng: 17.4269252 },
    { lat: 48.6738709, lng: 17.426048 },
    { lat: 48.6752522, lng: 17.4253138 },
    { lat: 48.6767298, lng: 17.4247185 },
    { lat: 48.6781173, lng: 17.4244994 },
    { lat: 48.6785052, lng: 17.4245618 },
    { lat: 48.6785791, lng: 17.4244641 },
    { lat: 48.6785882, lng: 17.4241822 },
    { lat: 48.6800753, lng: 17.4244925 },
    { lat: 48.6802017, lng: 17.4242642 },
    { lat: 48.6802685, lng: 17.4241435 },
    { lat: 48.6806057, lng: 17.4251874 },
    { lat: 48.6810795, lng: 17.4264081 },
    { lat: 48.6835417, lng: 17.4249669 },
    { lat: 48.6849975, lng: 17.4244418 },
    { lat: 48.6878066, lng: 17.4236175 },
    { lat: 48.6889924, lng: 17.4234748 },
    { lat: 48.6899031, lng: 17.4232907 },
    { lat: 48.6907253, lng: 17.4233047 },
    { lat: 48.6906729, lng: 17.4238064 },
    { lat: 48.6911282, lng: 17.4240038 },
    { lat: 48.6914616, lng: 17.4239702 },
    { lat: 48.6917429, lng: 17.4249534 },
    { lat: 48.6920882, lng: 17.4267556 },
    { lat: 48.6923128, lng: 17.4275106 },
    { lat: 48.692419, lng: 17.427722 },
    { lat: 48.6926842, lng: 17.427963 },
    { lat: 48.692829, lng: 17.4280032 },
    { lat: 48.6931821, lng: 17.4278191 },
    { lat: 48.6937692, lng: 17.427394 },
    { lat: 48.694049, lng: 17.4273547 },
    { lat: 48.694113, lng: 17.4276321 },
    { lat: 48.6940529, lng: 17.4279079 },
    { lat: 48.6940546, lng: 17.4283399 },
    { lat: 48.6938971, lng: 17.4293136 },
    { lat: 48.6939484, lng: 17.4296368 },
    { lat: 48.6940169, lng: 17.4297701 },
    { lat: 48.6939885, lng: 17.4302265 },
    { lat: 48.6950457, lng: 17.4300525 },
    { lat: 48.6954124, lng: 17.4299443 },
    { lat: 48.6956476, lng: 17.4297375 },
    { lat: 48.6961133, lng: 17.4298132 },
    { lat: 48.6966801, lng: 17.4298083 },
    { lat: 48.6974452, lng: 17.4295358 },
    { lat: 48.6980209, lng: 17.4296373 },
    { lat: 48.6986504, lng: 17.4298889 },
    { lat: 48.6989833, lng: 17.4299029 },
    { lat: 48.6998683, lng: 17.430349 },
    { lat: 48.7000632, lng: 17.4303076 },
    { lat: 48.7005036, lng: 17.4303581 },
    { lat: 48.7011975, lng: 17.4301029 },
    { lat: 48.7014197, lng: 17.4302204 },
    { lat: 48.7024081, lng: 17.430257 },
    { lat: 48.7030666, lng: 17.4299751 },
    { lat: 48.7033342, lng: 17.4299931 },
    { lat: 48.7033431, lng: 17.4300608 },
    { lat: 48.7036298, lng: 17.4298481 },
    { lat: 48.7041585, lng: 17.4297637 },
    { lat: 48.7045944, lng: 17.4297448 },
    { lat: 48.7049235, lng: 17.4298707 },
    { lat: 48.7051594, lng: 17.4297824 },
    { lat: 48.7054549, lng: 17.4295667 },
    { lat: 48.7056633, lng: 17.4295386 },
    { lat: 48.7056648, lng: 17.4294565 },
    { lat: 48.705738, lng: 17.4294525 },
    { lat: 48.7057099, lng: 17.4291752 },
    { lat: 48.7056323, lng: 17.4290754 },
    { lat: 48.705307, lng: 17.4290549 },
    { lat: 48.7052443, lng: 17.4287648 },
    { lat: 48.705299, lng: 17.4284742 },
    { lat: 48.7052413, lng: 17.4263479 },
    { lat: 48.7050287, lng: 17.4263287 },
    { lat: 48.7050208, lng: 17.4259086 },
    { lat: 48.7052377, lng: 17.4257845 },
    { lat: 48.7054339, lng: 17.4257618 },
    { lat: 48.7054555, lng: 17.4249726 },
    { lat: 48.7064027, lng: 17.4249318 },
    { lat: 48.7063379, lng: 17.4225562 },
    { lat: 48.7057934, lng: 17.4223959 },
    { lat: 48.7061006, lng: 17.4194667 },
    { lat: 48.7060877, lng: 17.4189676 },
    { lat: 48.7059416, lng: 17.4188747 },
    { lat: 48.7055583, lng: 17.419024 },
    { lat: 48.7053509, lng: 17.4189965 },
    { lat: 48.7049182, lng: 17.4191181 },
    { lat: 48.704197, lng: 17.4191422 },
    { lat: 48.7039815, lng: 17.4219537 },
    { lat: 48.7032567, lng: 17.42101 },
    { lat: 48.7034214, lng: 17.4193227 },
    { lat: 48.7031369, lng: 17.4193256 },
    { lat: 48.7032047, lng: 17.4171158 },
    { lat: 48.7034742, lng: 17.414601 },
    { lat: 48.7041269, lng: 17.4148096 },
    { lat: 48.7041588, lng: 17.4145192 },
    { lat: 48.7050668, lng: 17.4146374 },
    { lat: 48.7055591, lng: 17.4117439 },
    { lat: 48.7055946, lng: 17.4108915 },
    { lat: 48.7062194, lng: 17.4108618 },
    { lat: 48.7065366, lng: 17.4107343 },
    { lat: 48.7076892, lng: 17.4105161 },
    { lat: 48.7074448, lng: 17.4114915 },
    { lat: 48.7076233, lng: 17.4115804 },
    { lat: 48.7079929, lng: 17.4102153 },
    { lat: 48.7081259, lng: 17.4094226 },
    { lat: 48.7073524, lng: 17.4090772 },
    { lat: 48.7073709, lng: 17.4086447 },
    { lat: 48.7070785, lng: 17.4083498 },
    { lat: 48.7066055, lng: 17.408036 },
    { lat: 48.7061652, lng: 17.4079174 },
    { lat: 48.7062939, lng: 17.4073406 },
    { lat: 48.7062905, lng: 17.4069538 },
    { lat: 48.7061016, lng: 17.4063551 },
    { lat: 48.706028, lng: 17.4059154 },
    { lat: 48.7058052, lng: 17.4058219 },
    { lat: 48.7054742, lng: 17.4058385 },
    { lat: 48.705264, lng: 17.4057132 },
    { lat: 48.7044087, lng: 17.4054654 },
    { lat: 48.7044323, lng: 17.4053211 },
    { lat: 48.7043474, lng: 17.4050572 },
    { lat: 48.7040593, lng: 17.4045135 },
    { lat: 48.704138, lng: 17.4043134 },
    { lat: 48.7045615, lng: 17.4039814 },
    { lat: 48.7046722, lng: 17.4037892 },
    { lat: 48.7049851, lng: 17.4035257 },
    { lat: 48.7049504, lng: 17.4033402 },
    { lat: 48.7050571, lng: 17.4031003 },
    { lat: 48.7051809, lng: 17.4032186 },
    { lat: 48.7053101, lng: 17.4032404 },
    { lat: 48.7054285, lng: 17.4030652 },
    { lat: 48.7058963, lng: 17.4030142 },
    { lat: 48.706, lng: 17.4022226 },
    { lat: 48.7062219, lng: 17.4022836 },
    { lat: 48.7064152, lng: 17.4012012 },
    { lat: 48.7068125, lng: 17.3994977 },
    { lat: 48.7070438, lng: 17.3986375 },
    { lat: 48.7078325, lng: 17.3961424 },
    { lat: 48.7073308, lng: 17.3959264 },
    { lat: 48.7070957, lng: 17.3957314 },
    { lat: 48.7065857, lng: 17.3953918 },
    { lat: 48.7063971, lng: 17.395342 },
    { lat: 48.7054712, lng: 17.3953276 },
    { lat: 48.7054654, lng: 17.395395 },
    { lat: 48.7052515, lng: 17.3954401 },
    { lat: 48.7047454, lng: 17.3954761 },
    { lat: 48.7042889, lng: 17.3951758 },
    { lat: 48.7038326, lng: 17.3949642 },
    { lat: 48.7032154, lng: 17.3949912 },
    { lat: 48.7022583, lng: 17.394896 },
    { lat: 48.7018021, lng: 17.3946722 },
    { lat: 48.7015236, lng: 17.394611 },
    { lat: 48.7006609, lng: 17.3946477 },
    { lat: 48.7001552, lng: 17.3947777 },
    { lat: 48.6997753, lng: 17.3947 },
    { lat: 48.6996617, lng: 17.3931177 },
    { lat: 48.699383, lng: 17.3905344 },
    { lat: 48.6992086, lng: 17.3883965 },
  ],
  Podbranč: [
    { lat: 48.7054339, lng: 17.4257618 },
    { lat: 48.7052377, lng: 17.4257845 },
    { lat: 48.7050208, lng: 17.4259086 },
    { lat: 48.7050287, lng: 17.4263287 },
    { lat: 48.7052413, lng: 17.4263479 },
    { lat: 48.705299, lng: 17.4284742 },
    { lat: 48.7052443, lng: 17.4287648 },
    { lat: 48.705307, lng: 17.4290549 },
    { lat: 48.7056323, lng: 17.4290754 },
    { lat: 48.7057099, lng: 17.4291752 },
    { lat: 48.705738, lng: 17.4294525 },
    { lat: 48.7056648, lng: 17.4294565 },
    { lat: 48.7056633, lng: 17.4295386 },
    { lat: 48.7058182, lng: 17.4296993 },
    { lat: 48.7059238, lng: 17.4299023 },
    { lat: 48.7060588, lng: 17.4303312 },
    { lat: 48.7060128, lng: 17.4303369 },
    { lat: 48.7060774, lng: 17.4304665 },
    { lat: 48.7058918, lng: 17.4304759 },
    { lat: 48.7060284, lng: 17.4306497 },
    { lat: 48.7061496, lng: 17.432009 },
    { lat: 48.7063524, lng: 17.4319817 },
    { lat: 48.7064788, lng: 17.4317883 },
    { lat: 48.7070395, lng: 17.4315158 },
    { lat: 48.7072038, lng: 17.4313322 },
    { lat: 48.7072923, lng: 17.4308096 },
    { lat: 48.7073567, lng: 17.4307909 },
    { lat: 48.707343, lng: 17.4307214 },
    { lat: 48.7077324, lng: 17.4305376 },
    { lat: 48.7078914, lng: 17.4303708 },
    { lat: 48.707908, lng: 17.4303534 },
    { lat: 48.7081171, lng: 17.4303038 },
    { lat: 48.7084732, lng: 17.4305204 },
    { lat: 48.7085023, lng: 17.4307107 },
    { lat: 48.7086885, lng: 17.4310743 },
    { lat: 48.7086323, lng: 17.4313461 },
    { lat: 48.7087612, lng: 17.431521 },
    { lat: 48.7089753, lng: 17.4316565 },
    { lat: 48.7089915, lng: 17.4318888 },
    { lat: 48.7091238, lng: 17.4321365 },
    { lat: 48.709407, lng: 17.4324336 },
    { lat: 48.7097197, lng: 17.4320244 },
    { lat: 48.7098922, lng: 17.4320391 },
    { lat: 48.7099319, lng: 17.4321306 },
    { lat: 48.709902, lng: 17.4324389 },
    { lat: 48.7100572, lng: 17.4327062 },
    { lat: 48.7107125, lng: 17.4330631 },
    { lat: 48.7109157, lng: 17.4333259 },
    { lat: 48.7111312, lng: 17.4334276 },
    { lat: 48.7112712, lng: 17.4333037 },
    { lat: 48.7113844, lng: 17.4330439 },
    { lat: 48.7115297, lng: 17.433107 },
    { lat: 48.7116972, lng: 17.4333403 },
    { lat: 48.7118059, lng: 17.4333644 },
    { lat: 48.7118941, lng: 17.4332782 },
    { lat: 48.712085, lng: 17.4332441 },
    { lat: 48.7122284, lng: 17.4330667 },
    { lat: 48.7124308, lng: 17.4331411 },
    { lat: 48.7125422, lng: 17.433379 },
    { lat: 48.7125299, lng: 17.4337507 },
    { lat: 48.7128776, lng: 17.433837 },
    { lat: 48.712911, lng: 17.4339147 },
    { lat: 48.7128377, lng: 17.4340595 },
    { lat: 48.712582, lng: 17.4342973 },
    { lat: 48.7124192, lng: 17.4348942 },
    { lat: 48.7126738, lng: 17.4354097 },
    { lat: 48.7125552, lng: 17.4356224 },
    { lat: 48.7124861, lng: 17.4359876 },
    { lat: 48.7124863, lng: 17.4364297 },
    { lat: 48.7124273, lng: 17.4366704 },
    { lat: 48.7123584, lng: 17.4367403 },
    { lat: 48.7120093, lng: 17.4376036 },
    { lat: 48.7126983, lng: 17.4377765 },
    { lat: 48.7128685, lng: 17.437914 },
    { lat: 48.7124738, lng: 17.4394009 },
    { lat: 48.7132325, lng: 17.4395536 },
    { lat: 48.7135356, lng: 17.4397549 },
    { lat: 48.7140056, lng: 17.4403012 },
    { lat: 48.7143557, lng: 17.4408471 },
    { lat: 48.7158099, lng: 17.4439727 },
    { lat: 48.7168775, lng: 17.445685 },
    { lat: 48.7168451, lng: 17.4461311 },
    { lat: 48.7165736, lng: 17.4466512 },
    { lat: 48.716506, lng: 17.4470774 },
    { lat: 48.7175071, lng: 17.4471905 },
    { lat: 48.7181586, lng: 17.4474159 },
    { lat: 48.7182057, lng: 17.4474627 },
    { lat: 48.7184214, lng: 17.4483102 },
    { lat: 48.7185832, lng: 17.4486485 },
    { lat: 48.7188182, lng: 17.4490211 },
    { lat: 48.7191803, lng: 17.4494163 },
    { lat: 48.7193278, lng: 17.4503811 },
    { lat: 48.719339, lng: 17.4516997 },
    { lat: 48.7193991, lng: 17.4523719 },
    { lat: 48.7191667, lng: 17.4551096 },
    { lat: 48.7199401, lng: 17.4553523 },
    { lat: 48.7193893, lng: 17.4558503 },
    { lat: 48.718895, lng: 17.4561401 },
    { lat: 48.7188225, lng: 17.4568267 },
    { lat: 48.71863, lng: 17.4577859 },
    { lat: 48.7186327, lng: 17.4581135 },
    { lat: 48.7189652, lng: 17.4591067 },
    { lat: 48.7189796, lng: 17.4592617 },
    { lat: 48.7189234, lng: 17.4604287 },
    { lat: 48.7189529, lng: 17.4612252 },
    { lat: 48.7188153, lng: 17.4617572 },
    { lat: 48.7188576, lng: 17.4622589 },
    { lat: 48.7186931, lng: 17.4627804 },
    { lat: 48.7182502, lng: 17.4629339 },
    { lat: 48.7179461, lng: 17.4636561 },
    { lat: 48.7177216, lng: 17.4639936 },
    { lat: 48.7177058, lng: 17.4639573 },
    { lat: 48.7174525, lng: 17.4642718 },
    { lat: 48.71746, lng: 17.4644764 },
    { lat: 48.7176158, lng: 17.4648292 },
    { lat: 48.7177237, lng: 17.4652247 },
    { lat: 48.7177583, lng: 17.4662111 },
    { lat: 48.7176988, lng: 17.4671526 },
    { lat: 48.7177759, lng: 17.4674221 },
    { lat: 48.7187161, lng: 17.4681017 },
    { lat: 48.7189555, lng: 17.4684702 },
    { lat: 48.7189423, lng: 17.4690039 },
    { lat: 48.7187584, lng: 17.469777 },
    { lat: 48.7187015, lng: 17.4700101 },
    { lat: 48.7190252, lng: 17.4702683 },
    { lat: 48.7191846, lng: 17.470322 },
    { lat: 48.719558, lng: 17.4707249 },
    { lat: 48.7200255, lng: 17.4708112 },
    { lat: 48.7202522, lng: 17.4710177 },
    { lat: 48.7204564, lng: 17.4709804 },
    { lat: 48.7206629, lng: 17.4713455 },
    { lat: 48.7207265, lng: 17.4715998 },
    { lat: 48.7211038, lng: 17.4724153 },
    { lat: 48.7212155, lng: 17.4732406 },
    { lat: 48.7212016, lng: 17.4736794 },
    { lat: 48.7213442, lng: 17.4737552 },
    { lat: 48.7214368, lng: 17.4736673 },
    { lat: 48.7217189, lng: 17.4735749 },
    { lat: 48.7218781, lng: 17.4738547 },
    { lat: 48.7231087, lng: 17.474114 },
    { lat: 48.7232322, lng: 17.474332 },
    { lat: 48.7232769, lng: 17.4748914 },
    { lat: 48.723364, lng: 17.4752832 },
    { lat: 48.723446, lng: 17.475474 },
    { lat: 48.723462, lng: 17.475499 },
    { lat: 48.723559, lng: 17.475641 },
    { lat: 48.723696, lng: 17.475748 },
    { lat: 48.723917, lng: 17.475977 },
    { lat: 48.724339, lng: 17.476232 },
    { lat: 48.724757, lng: 17.47642 },
    { lat: 48.724936, lng: 17.47655 },
    { lat: 48.725281, lng: 17.477105 },
    { lat: 48.7253, lng: 17.477119 },
    { lat: 48.725239, lng: 17.478095 },
    { lat: 48.725201, lng: 17.478948 },
    { lat: 48.725208, lng: 17.479455 },
    { lat: 48.725211, lng: 17.479738 },
    { lat: 48.725186, lng: 17.480232 },
    { lat: 48.725168, lng: 17.480629 },
    { lat: 48.725101, lng: 17.480973 },
    { lat: 48.725044, lng: 17.481111 },
    { lat: 48.724753, lng: 17.481987 },
    { lat: 48.724484, lng: 17.4826 },
    { lat: 48.724363, lng: 17.482878 },
    { lat: 48.724178, lng: 17.483624 },
    { lat: 48.724139, lng: 17.48429 },
    { lat: 48.724075, lng: 17.485345 },
    { lat: 48.724077, lng: 17.485938 },
    { lat: 48.724075, lng: 17.486526 },
    { lat: 48.724045, lng: 17.487428 },
    { lat: 48.724035, lng: 17.487447 },
    { lat: 48.724153, lng: 17.487507 },
    { lat: 48.724277, lng: 17.487574 },
    { lat: 48.724384, lng: 17.487638 },
    { lat: 48.724442, lng: 17.48767 },
    { lat: 48.724498, lng: 17.487695 },
    { lat: 48.724662, lng: 17.487753 },
    { lat: 48.724864, lng: 17.487828 },
    { lat: 48.725208, lng: 17.487845 },
    { lat: 48.7254013, lng: 17.4878617 },
    { lat: 48.725533, lng: 17.487873 },
    { lat: 48.725776, lng: 17.487875 },
    { lat: 48.725992, lng: 17.487872 },
    { lat: 48.72621, lng: 17.487842 },
    { lat: 48.726449, lng: 17.487805 },
    { lat: 48.726708, lng: 17.487757 },
    { lat: 48.726878, lng: 17.487723 },
    { lat: 48.727062, lng: 17.48764 },
    { lat: 48.7272, lng: 17.487574 },
    { lat: 48.727348, lng: 17.487583 },
    { lat: 48.727478, lng: 17.487592 },
    { lat: 48.727621, lng: 17.487604 },
    { lat: 48.727719, lng: 17.487607 },
    { lat: 48.727792, lng: 17.487624 },
    { lat: 48.727915, lng: 17.487677 },
    { lat: 48.728019, lng: 17.487696 },
    { lat: 48.728089, lng: 17.487681 },
    { lat: 48.728216, lng: 17.487655 },
    { lat: 48.728324, lng: 17.487683 },
    { lat: 48.728502, lng: 17.487674 },
    { lat: 48.728546, lng: 17.487628 },
    { lat: 48.728609, lng: 17.487544 },
    { lat: 48.728678, lng: 17.487554 },
    { lat: 48.728729, lng: 17.487532 },
    { lat: 48.728835, lng: 17.487521 },
    { lat: 48.728897, lng: 17.487494 },
    { lat: 48.728989, lng: 17.487509 },
    { lat: 48.729097, lng: 17.487521 },
    { lat: 48.729225, lng: 17.487476 },
    { lat: 48.72928, lng: 17.48743 },
    { lat: 48.729321, lng: 17.487439 },
    { lat: 48.729365, lng: 17.487415 },
    { lat: 48.72943, lng: 17.487437 },
    { lat: 48.729491, lng: 17.487459 },
    { lat: 48.729606, lng: 17.487488 },
    { lat: 48.729687, lng: 17.487546 },
    { lat: 48.729786, lng: 17.487561 },
    { lat: 48.72985, lng: 17.487684 },
    { lat: 48.729905, lng: 17.487783 },
    { lat: 48.730043, lng: 17.487901 },
    { lat: 48.730097, lng: 17.487978 },
    { lat: 48.730132, lng: 17.488133 },
    { lat: 48.730148, lng: 17.488275 },
    { lat: 48.73027, lng: 17.488459 },
    { lat: 48.730324, lng: 17.488555 },
    { lat: 48.730396, lng: 17.488712 },
    { lat: 48.730474, lng: 17.488874 },
    { lat: 48.730474, lng: 17.488942 },
    { lat: 48.730576, lng: 17.489042 },
    { lat: 48.730607, lng: 17.489063 },
    { lat: 48.730726, lng: 17.489113 },
    { lat: 48.730788, lng: 17.489121 },
    { lat: 48.730953, lng: 17.489137 },
    { lat: 48.730996, lng: 17.489162 },
    { lat: 48.73111, lng: 17.489176 },
    { lat: 48.731178, lng: 17.489187 },
    { lat: 48.731349, lng: 17.489218 },
    { lat: 48.731473, lng: 17.489286 },
    { lat: 48.73159, lng: 17.489348 },
    { lat: 48.731608, lng: 17.489473 },
    { lat: 48.731615, lng: 17.489504 },
    { lat: 48.731709, lng: 17.489551 },
    { lat: 48.731808, lng: 17.489596 },
    { lat: 48.731869, lng: 17.489703 },
    { lat: 48.731977, lng: 17.489562 },
    { lat: 48.73199, lng: 17.489578 },
    { lat: 48.732078, lng: 17.489673 },
    { lat: 48.732125, lng: 17.489737 },
    { lat: 48.732195, lng: 17.489857 },
    { lat: 48.732301, lng: 17.489961 },
    { lat: 48.732361, lng: 17.489989 },
    { lat: 48.73245, lng: 17.490018 },
    { lat: 48.732494, lng: 17.490122 },
    { lat: 48.732602, lng: 17.490122 },
    { lat: 48.732638, lng: 17.49017 },
    { lat: 48.732678, lng: 17.490196 },
    { lat: 48.732715, lng: 17.490294 },
    { lat: 48.73276, lng: 17.490417 },
    { lat: 48.732814, lng: 17.490467 },
    { lat: 48.732899, lng: 17.490531 },
    { lat: 48.732953, lng: 17.490562 },
    { lat: 48.733071, lng: 17.490613 },
    { lat: 48.733202, lng: 17.490635 },
    { lat: 48.733221, lng: 17.490648 },
    { lat: 48.733345, lng: 17.490778 },
    { lat: 48.733404, lng: 17.490867 },
    { lat: 48.733386, lng: 17.490989 },
    { lat: 48.733458, lng: 17.491225 },
    { lat: 48.733507, lng: 17.491503 },
    { lat: 48.733552, lng: 17.491719 },
    { lat: 48.733718, lng: 17.492011 },
    { lat: 48.733841, lng: 17.492155 },
    { lat: 48.733975, lng: 17.492371 },
    { lat: 48.734067, lng: 17.49245 },
    { lat: 48.734143, lng: 17.492548 },
    { lat: 48.734274, lng: 17.492653 },
    { lat: 48.734273, lng: 17.492637 },
    { lat: 48.734297, lng: 17.492433 },
    { lat: 48.734308, lng: 17.49226 },
    { lat: 48.734295, lng: 17.492109 },
    { lat: 48.734518, lng: 17.492109 },
    { lat: 48.734752, lng: 17.49211 },
    { lat: 48.734992, lng: 17.492115 },
    { lat: 48.735187, lng: 17.492116 },
    { lat: 48.735294, lng: 17.492068 },
    { lat: 48.735423, lng: 17.491998 },
    { lat: 48.735565, lng: 17.491912 },
    { lat: 48.735701, lng: 17.491827 },
    { lat: 48.735748, lng: 17.491799 },
    { lat: 48.735975, lng: 17.491655 },
    { lat: 48.7361, lng: 17.491575 },
    { lat: 48.736106, lng: 17.491491 },
    { lat: 48.736112, lng: 17.49129 },
    { lat: 48.736264, lng: 17.491137 },
    { lat: 48.736368, lng: 17.491039 },
    { lat: 48.736614, lng: 17.490804 },
    { lat: 48.736667, lng: 17.490799 },
    { lat: 48.736783, lng: 17.490798 },
    { lat: 48.736917, lng: 17.490791 },
    { lat: 48.737023, lng: 17.490794 },
    { lat: 48.737136, lng: 17.490787 },
    { lat: 48.737242, lng: 17.49079 },
    { lat: 48.737301, lng: 17.490846 },
    { lat: 48.737415, lng: 17.490835 },
    { lat: 48.737496, lng: 17.490779 },
    { lat: 48.737591, lng: 17.490703 },
    { lat: 48.73764, lng: 17.490751 },
    { lat: 48.73779, lng: 17.490865 },
    { lat: 48.737992, lng: 17.490933 },
    { lat: 48.738181, lng: 17.490835 },
    { lat: 48.738295, lng: 17.490778 },
    { lat: 48.738365, lng: 17.490845 },
    { lat: 48.738429, lng: 17.490908 },
    { lat: 48.738491, lng: 17.490967 },
    { lat: 48.738645, lng: 17.491054 },
    { lat: 48.738761, lng: 17.491246 },
    { lat: 48.738893, lng: 17.491341 },
    { lat: 48.738951, lng: 17.491404 },
    { lat: 48.738966, lng: 17.491418 },
    { lat: 48.73911, lng: 17.491396 },
    { lat: 48.739183, lng: 17.491357 },
    { lat: 48.739271, lng: 17.491308 },
    { lat: 48.739377, lng: 17.491333 },
    { lat: 48.739415, lng: 17.491307 },
    { lat: 48.739535, lng: 17.491212 },
    { lat: 48.739672, lng: 17.491105 },
    { lat: 48.739734, lng: 17.49105 },
    { lat: 48.73981, lng: 17.490996 },
    { lat: 48.739915, lng: 17.490892 },
    { lat: 48.740031, lng: 17.490771 },
    { lat: 48.740125, lng: 17.490688 },
    { lat: 48.740182, lng: 17.490653 },
    { lat: 48.740245, lng: 17.490532 },
    { lat: 48.740314, lng: 17.490416 },
    { lat: 48.740392, lng: 17.490315 },
    { lat: 48.740394, lng: 17.490198 },
    { lat: 48.740394, lng: 17.489996 },
    { lat: 48.740415, lng: 17.489917 },
    { lat: 48.740484, lng: 17.489795 },
    { lat: 48.740548, lng: 17.489668 },
    { lat: 48.740603, lng: 17.489542 },
    { lat: 48.74068, lng: 17.489469 },
    { lat: 48.740779, lng: 17.489242 },
    { lat: 48.740861, lng: 17.489049 },
    { lat: 48.740875, lng: 17.489061 },
    { lat: 48.740987, lng: 17.489157 },
    { lat: 48.741096, lng: 17.489236 },
    { lat: 48.741215, lng: 17.489445 },
    { lat: 48.741265, lng: 17.489499 },
    { lat: 48.741368, lng: 17.48963 },
    { lat: 48.741431, lng: 17.489627 },
    { lat: 48.741627, lng: 17.48961 },
    { lat: 48.741777, lng: 17.489583 },
    { lat: 48.741898, lng: 17.489568 },
    { lat: 48.742061, lng: 17.489641 },
    { lat: 48.742266, lng: 17.48953 },
    { lat: 48.742353, lng: 17.48945 },
    { lat: 48.742525, lng: 17.489292 },
    { lat: 48.742694, lng: 17.489138 },
    { lat: 48.742899, lng: 17.488993 },
    { lat: 48.743044, lng: 17.488891 },
    { lat: 48.743186, lng: 17.488792 },
    { lat: 48.743169, lng: 17.4886 },
    { lat: 48.743154, lng: 17.488399 },
    { lat: 48.743136, lng: 17.488198 },
    { lat: 48.743109, lng: 17.487884 },
    { lat: 48.74308, lng: 17.487586 },
    { lat: 48.743057, lng: 17.487336 },
    { lat: 48.743038, lng: 17.487088 },
    { lat: 48.743019, lng: 17.486822 },
    { lat: 48.743004, lng: 17.486645 },
    { lat: 48.743003, lng: 17.486548 },
    { lat: 48.74308, lng: 17.48644 },
    { lat: 48.743168, lng: 17.486307 },
    { lat: 48.743323, lng: 17.486283 },
    { lat: 48.743523, lng: 17.48623 },
    { lat: 48.743608, lng: 17.486205 },
    { lat: 48.743709, lng: 17.486209 },
    { lat: 48.743811, lng: 17.486239 },
    { lat: 48.743858, lng: 17.486356 },
    { lat: 48.74388, lng: 17.486485 },
    { lat: 48.743968, lng: 17.486557 },
    { lat: 48.744095, lng: 17.486579 },
    { lat: 48.744181, lng: 17.486516 },
    { lat: 48.744236, lng: 17.486368 },
    { lat: 48.744327, lng: 17.486219 },
    { lat: 48.744267, lng: 17.486027 },
    { lat: 48.744236, lng: 17.485903 },
    { lat: 48.744224, lng: 17.485799 },
    { lat: 48.744298, lng: 17.48585 },
    { lat: 48.744341, lng: 17.48585 },
    { lat: 48.744394, lng: 17.485796 },
    { lat: 48.74443, lng: 17.485698 },
    { lat: 48.744436, lng: 17.48562 },
    { lat: 48.74447, lng: 17.485521 },
    { lat: 48.744507, lng: 17.485491 },
    { lat: 48.744541, lng: 17.485425 },
    { lat: 48.744534, lng: 17.485337 },
    { lat: 48.74448, lng: 17.485129 },
    { lat: 48.744469, lng: 17.484965 },
    { lat: 48.744442, lng: 17.484877 },
    { lat: 48.744389, lng: 17.484699 },
    { lat: 48.744454, lng: 17.48447 },
    { lat: 48.744523, lng: 17.484462 },
    { lat: 48.744644, lng: 17.484449 },
    { lat: 48.74475, lng: 17.484494 },
    { lat: 48.744845, lng: 17.484467 },
    { lat: 48.744908, lng: 17.484542 },
    { lat: 48.745039, lng: 17.48446 },
    { lat: 48.745023, lng: 17.484366 },
    { lat: 48.745002, lng: 17.484248 },
    { lat: 48.74498, lng: 17.484107 },
    { lat: 48.744948, lng: 17.483952 },
    { lat: 48.744966, lng: 17.483754 },
    { lat: 48.744968, lng: 17.483436 },
    { lat: 48.744974, lng: 17.483115 },
    { lat: 48.744975, lng: 17.482925 },
    { lat: 48.744996, lng: 17.482789 },
    { lat: 48.745036, lng: 17.482507 },
    { lat: 48.745057, lng: 17.482275 },
    { lat: 48.74504, lng: 17.482177 },
    { lat: 48.745003, lng: 17.481972 },
    { lat: 48.745015, lng: 17.48172 },
    { lat: 48.745027, lng: 17.481498 },
    { lat: 48.745043, lng: 17.481277 },
    { lat: 48.745061, lng: 17.481088 },
    { lat: 48.745126, lng: 17.481109 },
    { lat: 48.745203, lng: 17.481125 },
    { lat: 48.745277, lng: 17.481144 },
    { lat: 48.745379, lng: 17.481172 },
    { lat: 48.745507, lng: 17.481198 },
    { lat: 48.745609, lng: 17.481224 },
    { lat: 48.745705, lng: 17.481249 },
    { lat: 48.745827, lng: 17.481279 },
    { lat: 48.7459, lng: 17.481297 },
    { lat: 48.745916, lng: 17.481299 },
    { lat: 48.74595, lng: 17.481311 },
    { lat: 48.745992, lng: 17.481065 },
    { lat: 48.746042, lng: 17.480777 },
    { lat: 48.74609, lng: 17.480514 },
    { lat: 48.746124, lng: 17.480319 },
    { lat: 48.74613, lng: 17.480202 },
    { lat: 48.746123, lng: 17.479995 },
    { lat: 48.746096, lng: 17.479814 },
    { lat: 48.746017, lng: 17.479487 },
    { lat: 48.745965, lng: 17.479224 },
    { lat: 48.745928, lng: 17.478975 },
    { lat: 48.745883, lng: 17.478678 },
    { lat: 48.745844, lng: 17.478536 },
    { lat: 48.745772, lng: 17.478293 },
    { lat: 48.745808, lng: 17.478269 },
    { lat: 48.74582, lng: 17.478256 },
    { lat: 48.745909, lng: 17.478211 },
    { lat: 48.746062, lng: 17.478109 },
    { lat: 48.746084, lng: 17.478094 },
    { lat: 48.746237, lng: 17.478073 },
    { lat: 48.746359, lng: 17.47805 },
    { lat: 48.746499, lng: 17.478023 },
    { lat: 48.74669, lng: 17.477938 },
    { lat: 48.746786, lng: 17.477929 },
    { lat: 48.746886, lng: 17.477918 },
    { lat: 48.747029, lng: 17.47784 },
    { lat: 48.747186, lng: 17.477712 },
    { lat: 48.747311, lng: 17.477604 },
    { lat: 48.747435, lng: 17.477502 },
    { lat: 48.747614, lng: 17.477352 },
    { lat: 48.747693, lng: 17.477278 },
    { lat: 48.747826, lng: 17.477166 },
    { lat: 48.747898, lng: 17.477146 },
    { lat: 48.748066, lng: 17.477087 },
    { lat: 48.748222, lng: 17.477021 },
    { lat: 48.748355, lng: 17.476896 },
    { lat: 48.748442, lng: 17.476752 },
    { lat: 48.748384, lng: 17.476673 },
    { lat: 48.748258, lng: 17.476544 },
    { lat: 48.748213, lng: 17.476479 },
    { lat: 48.748182, lng: 17.47644 },
    { lat: 48.748128, lng: 17.4764689 },
    { lat: 48.7477875, lng: 17.4766697 },
    { lat: 48.7473936, lng: 17.476764 },
    { lat: 48.7468467, lng: 17.4770582 },
    { lat: 48.7461155, lng: 17.4771402 },
    { lat: 48.7460956, lng: 17.4770926 },
    { lat: 48.7461064, lng: 17.4765718 },
    { lat: 48.7460502, lng: 17.4757809 },
    { lat: 48.7461295, lng: 17.475273 },
    { lat: 48.7459844, lng: 17.4749281 },
    { lat: 48.7458415, lng: 17.4742454 },
    { lat: 48.745814, lng: 17.4735491 },
    { lat: 48.7457533, lng: 17.4734463 },
    { lat: 48.7456758, lng: 17.4730117 },
    { lat: 48.7457102, lng: 17.4727906 },
    { lat: 48.7457013, lng: 17.4721917 },
    { lat: 48.7455063, lng: 17.4710128 },
    { lat: 48.7453085, lng: 17.4701621 },
    { lat: 48.7453027, lng: 17.4699486 },
    { lat: 48.7448735, lng: 17.4690465 },
    { lat: 48.7451627, lng: 17.4687985 },
    { lat: 48.7451669, lng: 17.4689013 },
    { lat: 48.7453172, lng: 17.4691313 },
    { lat: 48.745373, lng: 17.4691042 },
    { lat: 48.7455252, lng: 17.4692748 },
    { lat: 48.745717, lng: 17.469148 },
    { lat: 48.7452663, lng: 17.4679091 },
    { lat: 48.7449508, lng: 17.4675863 },
    { lat: 48.744572, lng: 17.4670841 },
    { lat: 48.7446901, lng: 17.467025 },
    { lat: 48.7457799, lng: 17.4670579 },
    { lat: 48.7459246, lng: 17.4668675 },
    { lat: 48.7459882, lng: 17.4669763 },
    { lat: 48.7462971, lng: 17.4669336 },
    { lat: 48.7463605, lng: 17.4669884 },
    { lat: 48.7472626, lng: 17.4668251 },
    { lat: 48.7474617, lng: 17.466655 },
    { lat: 48.7475068, lng: 17.4664759 },
    { lat: 48.7477148, lng: 17.4662122 },
    { lat: 48.7476718, lng: 17.4661587 },
    { lat: 48.7471653, lng: 17.4661168 },
    { lat: 48.746473, lng: 17.4662752 },
    { lat: 48.7456781, lng: 17.4663323 },
    { lat: 48.7455223, lng: 17.4662084 },
    { lat: 48.745278, lng: 17.4663071 },
    { lat: 48.7449297, lng: 17.4661608 },
    { lat: 48.7445903, lng: 17.4661704 },
    { lat: 48.7444626, lng: 17.4660985 },
    { lat: 48.7443734, lng: 17.4657443 },
    { lat: 48.7446124, lng: 17.4651032 },
    { lat: 48.7449648, lng: 17.4635813 },
    { lat: 48.745271, lng: 17.4625964 },
    { lat: 48.7453897, lng: 17.4618022 },
    { lat: 48.7455317, lng: 17.4613741 },
    { lat: 48.7456882, lng: 17.4603983 },
    { lat: 48.7457657, lng: 17.4602663 },
    { lat: 48.7459414, lng: 17.459006 },
    { lat: 48.7460051, lng: 17.4576869 },
    { lat: 48.7460688, lng: 17.4575015 },
    { lat: 48.7459637, lng: 17.4562107 },
    { lat: 48.7458804, lng: 17.4558972 },
    { lat: 48.7459863, lng: 17.4555249 },
    { lat: 48.7460325, lng: 17.4550461 },
    { lat: 48.7462103, lng: 17.4547184 },
    { lat: 48.7462084, lng: 17.4545294 },
    { lat: 48.7461089, lng: 17.4543868 },
    { lat: 48.7459803, lng: 17.4544404 },
    { lat: 48.7459953, lng: 17.4541283 },
    { lat: 48.7459344, lng: 17.4540084 },
    { lat: 48.745667, lng: 17.4540457 },
    { lat: 48.7455933, lng: 17.4540078 },
    { lat: 48.7455711, lng: 17.4539241 },
    { lat: 48.7455208, lng: 17.4533339 },
    { lat: 48.74535, lng: 17.4524109 },
    { lat: 48.7452149, lng: 17.4495445 },
    { lat: 48.7454464, lng: 17.4495267 },
    { lat: 48.7454819, lng: 17.4489872 },
    { lat: 48.7456439, lng: 17.4482735 },
    { lat: 48.7456032, lng: 17.4481138 },
    { lat: 48.7456498, lng: 17.4480479 },
    { lat: 48.745859, lng: 17.4480881 },
    { lat: 48.7459945, lng: 17.4482117 },
    { lat: 48.7464556, lng: 17.4488334 },
    { lat: 48.7466545, lng: 17.4492037 },
    { lat: 48.7469202, lng: 17.4490327 },
    { lat: 48.7472513, lng: 17.4484903 },
    { lat: 48.7477916, lng: 17.4474191 },
    { lat: 48.747724, lng: 17.4471395 },
    { lat: 48.7479377, lng: 17.4469511 },
    { lat: 48.7479952, lng: 17.4467913 },
    { lat: 48.7483996, lng: 17.4467724 },
    { lat: 48.7485844, lng: 17.4464873 },
    { lat: 48.7486639, lng: 17.446 },
    { lat: 48.7489566, lng: 17.4459503 },
    { lat: 48.74913, lng: 17.4457463 },
    { lat: 48.7494032, lng: 17.4456225 },
    { lat: 48.7496757, lng: 17.4452572 },
    { lat: 48.7500892, lng: 17.4439544 },
    { lat: 48.7499099, lng: 17.4439413 },
    { lat: 48.749122, lng: 17.443594 },
    { lat: 48.7491736, lng: 17.444454 },
    { lat: 48.7479407, lng: 17.4441437 },
    { lat: 48.7479927, lng: 17.4437918 },
    { lat: 48.7469283, lng: 17.4439085 },
    { lat: 48.7469331, lng: 17.4433298 },
    { lat: 48.7468437, lng: 17.4424121 },
    { lat: 48.7468583, lng: 17.4411087 },
    { lat: 48.7466872, lng: 17.4394772 },
    { lat: 48.7465378, lng: 17.438506 },
    { lat: 48.7483669, lng: 17.4378432 },
    { lat: 48.7484322, lng: 17.4386174 },
    { lat: 48.7488346, lng: 17.438567 },
    { lat: 48.7494151, lng: 17.4387438 },
    { lat: 48.7497606, lng: 17.4387428 },
    { lat: 48.7496074, lng: 17.4394868 },
    { lat: 48.7503544, lng: 17.4391775 },
    { lat: 48.7500225, lng: 17.4388297 },
    { lat: 48.7499131, lng: 17.4384778 },
    { lat: 48.7507299, lng: 17.4375681 },
    { lat: 48.7508599, lng: 17.4377788 },
    { lat: 48.7511244, lng: 17.4375384 },
    { lat: 48.751487, lng: 17.4369043 },
    { lat: 48.7517396, lng: 17.4361228 },
    { lat: 48.7515635, lng: 17.4360205 },
    { lat: 48.7516284, lng: 17.4357103 },
    { lat: 48.7515769, lng: 17.4345306 },
    { lat: 48.7515635, lng: 17.4342234 },
    { lat: 48.7511074, lng: 17.4342793 },
    { lat: 48.7508343, lng: 17.4341615 },
    { lat: 48.7507152, lng: 17.4341901 },
    { lat: 48.7507178, lng: 17.4341418 },
    { lat: 48.7504725, lng: 17.4339591 },
    { lat: 48.7502337, lng: 17.4330568 },
    { lat: 48.7500152, lng: 17.4330152 },
    { lat: 48.7497964, lng: 17.4328587 },
    { lat: 48.7496607, lng: 17.4312398 },
    { lat: 48.7491596, lng: 17.431017 },
    { lat: 48.7493826, lng: 17.4297957 },
    { lat: 48.7500389, lng: 17.4271245 },
    { lat: 48.7492329, lng: 17.4267433 },
    { lat: 48.7481714, lng: 17.4290113 },
    { lat: 48.7474371, lng: 17.4319464 },
    { lat: 48.7473241, lng: 17.4322293 },
    { lat: 48.7471463, lng: 17.4323606 },
    { lat: 48.746585, lng: 17.432413 },
    { lat: 48.7456489, lng: 17.4328912 },
    { lat: 48.7458497, lng: 17.43206 },
    { lat: 48.7462758, lng: 17.4308522 },
    { lat: 48.7460104, lng: 17.4306264 },
    { lat: 48.7459725, lng: 17.4307365 },
    { lat: 48.7455414, lng: 17.4305135 },
    { lat: 48.7446841, lng: 17.4298829 },
    { lat: 48.7448777, lng: 17.4292575 },
    { lat: 48.7442206, lng: 17.4287514 },
    { lat: 48.7440597, lng: 17.4284086 },
    { lat: 48.7440325, lng: 17.4280744 },
    { lat: 48.743759, lng: 17.4274203 },
    { lat: 48.7433159, lng: 17.4280115 },
    { lat: 48.7432179, lng: 17.4280523 },
    { lat: 48.7430598, lng: 17.428384 },
    { lat: 48.742871, lng: 17.4285764 },
    { lat: 48.742939, lng: 17.4288609 },
    { lat: 48.7428078, lng: 17.4291031 },
    { lat: 48.742795, lng: 17.4292213 },
    { lat: 48.7424574, lng: 17.4293117 },
    { lat: 48.7422989, lng: 17.4297433 },
    { lat: 48.7421247, lng: 17.4299686 },
    { lat: 48.7419417, lng: 17.4310064 },
    { lat: 48.7416537, lng: 17.431637 },
    { lat: 48.7412951, lng: 17.4321226 },
    { lat: 48.7412658, lng: 17.4320715 },
    { lat: 48.7407625, lng: 17.4330281 },
    { lat: 48.7400579, lng: 17.4339607 },
    { lat: 48.7398882, lng: 17.4343245 },
    { lat: 48.7394045, lng: 17.4350263 },
    { lat: 48.7388368, lng: 17.4356646 },
    { lat: 48.7390635, lng: 17.4360668 },
    { lat: 48.7392677, lng: 17.4362913 },
    { lat: 48.7392143, lng: 17.4363213 },
    { lat: 48.7387962, lng: 17.4356996 },
    { lat: 48.738642, lng: 17.4355447 },
    { lat: 48.7384363, lng: 17.4353647 },
    { lat: 48.7379378, lng: 17.4348369 },
    { lat: 48.7376625, lng: 17.4343499 },
    { lat: 48.7375662, lng: 17.4339779 },
    { lat: 48.7374519, lng: 17.4329549 },
    { lat: 48.7368895, lng: 17.4306505 },
    { lat: 48.7361528, lng: 17.429204 },
    { lat: 48.7360061, lng: 17.4287149 },
    { lat: 48.7358009, lng: 17.4284052 },
    { lat: 48.7354055, lng: 17.42749 },
    { lat: 48.7352723, lng: 17.426964 },
    { lat: 48.735208, lng: 17.4268403 },
    { lat: 48.735073, lng: 17.4267431 },
    { lat: 48.7346862, lng: 17.4267336 },
    { lat: 48.7344847, lng: 17.4267287 },
    { lat: 48.734139, lng: 17.426358 },
    { lat: 48.734033, lng: 17.4260458 },
    { lat: 48.732946, lng: 17.4263898 },
    { lat: 48.7325803, lng: 17.4264435 },
    { lat: 48.7322747, lng: 17.4264183 },
    { lat: 48.731584, lng: 17.4261108 },
    { lat: 48.7311431, lng: 17.426016 },
    { lat: 48.7307165, lng: 17.4260215 },
    { lat: 48.7303964, lng: 17.4261003 },
    { lat: 48.728969, lng: 17.4269322 },
    { lat: 48.7286475, lng: 17.4269537 },
    { lat: 48.7271748, lng: 17.4273596 },
    { lat: 48.7260708, lng: 17.4275082 },
    { lat: 48.7242604, lng: 17.42788 },
    { lat: 48.7230741, lng: 17.4283135 },
    { lat: 48.7224817, lng: 17.4286924 },
    { lat: 48.7215051, lng: 17.4291738 },
    { lat: 48.7212358, lng: 17.4291991 },
    { lat: 48.7210023, lng: 17.4291676 },
    { lat: 48.7201645, lng: 17.4288553 },
    { lat: 48.7195889, lng: 17.4284576 },
    { lat: 48.7190852, lng: 17.4279342 },
    { lat: 48.7178306, lng: 17.4262339 },
    { lat: 48.7177155, lng: 17.4265308 },
    { lat: 48.7173702, lng: 17.4278567 },
    { lat: 48.7174006, lng: 17.4281855 },
    { lat: 48.7173574, lng: 17.4283754 },
    { lat: 48.7171846, lng: 17.4284111 },
    { lat: 48.7171254, lng: 17.4285443 },
    { lat: 48.7170031, lng: 17.4284156 },
    { lat: 48.7158441, lng: 17.4284681 },
    { lat: 48.7155403, lng: 17.428395 },
    { lat: 48.7148495, lng: 17.4281056 },
    { lat: 48.7141008, lng: 17.4275438 },
    { lat: 48.7131451, lng: 17.4271023 },
    { lat: 48.7126559, lng: 17.4271866 },
    { lat: 48.7120723, lng: 17.4271915 },
    { lat: 48.7115783, lng: 17.4269779 },
    { lat: 48.7114441, lng: 17.4268381 },
    { lat: 48.7105873, lng: 17.4266346 },
    { lat: 48.7105623, lng: 17.4261937 },
    { lat: 48.7093483, lng: 17.4260015 },
    { lat: 48.7070126, lng: 17.4258636 },
    { lat: 48.7068387, lng: 17.4257057 },
    { lat: 48.7058828, lng: 17.4256991 },
    { lat: 48.7054339, lng: 17.4257618 },
  ],
  Prietrž: [
    { lat: 48.667078, lng: 17.4255176 },
    { lat: 48.6670966, lng: 17.4256303 },
    { lat: 48.6671075, lng: 17.4257309 },
    { lat: 48.666948, lng: 17.4259733 },
    { lat: 48.6666817, lng: 17.4262222 },
    { lat: 48.6664055, lng: 17.4274579 },
    { lat: 48.6663122, lng: 17.4284317 },
    { lat: 48.6656814, lng: 17.429704 },
    { lat: 48.6654022, lng: 17.4312285 },
    { lat: 48.6650257, lng: 17.4317475 },
    { lat: 48.6644967, lng: 17.4320741 },
    { lat: 48.6643409, lng: 17.4322359 },
    { lat: 48.6643472, lng: 17.4326834 },
    { lat: 48.6642662, lng: 17.4328103 },
    { lat: 48.6641003, lng: 17.4334102 },
    { lat: 48.663995, lng: 17.4335807 },
    { lat: 48.663812, lng: 17.4344261 },
    { lat: 48.6635127, lng: 17.4353546 },
    { lat: 48.6634961, lng: 17.4357297 },
    { lat: 48.6633451, lng: 17.4365439 },
    { lat: 48.6629801, lng: 17.4379265 },
    { lat: 48.6627343, lng: 17.4386024 },
    { lat: 48.6623681, lng: 17.439213 },
    { lat: 48.6616294, lng: 17.4395802 },
    { lat: 48.6611292, lng: 17.4396885 },
    { lat: 48.6610466, lng: 17.4402301 },
    { lat: 48.6606193, lng: 17.4431432 },
    { lat: 48.6606223, lng: 17.4457408 },
    { lat: 48.6604252, lng: 17.4475623 },
    { lat: 48.660149, lng: 17.449398 },
    { lat: 48.6589346, lng: 17.4530748 },
    { lat: 48.6587624, lng: 17.4539474 },
    { lat: 48.6587461, lng: 17.4543791 },
    { lat: 48.6580017, lng: 17.4570368 },
    { lat: 48.6578547, lng: 17.4577735 },
    { lat: 48.657499, lng: 17.4601557 },
    { lat: 48.6576682, lng: 17.4620207 },
    { lat: 48.6577195, lng: 17.4640377 },
    { lat: 48.6575911, lng: 17.4647487 },
    { lat: 48.6575951, lng: 17.4651479 },
    { lat: 48.6575305, lng: 17.4652662 },
    { lat: 48.6575369, lng: 17.4654357 },
    { lat: 48.6574227, lng: 17.4659811 },
    { lat: 48.6568372, lng: 17.4672461 },
    { lat: 48.6543471, lng: 17.4698047 },
    { lat: 48.654185, lng: 17.470167 },
    { lat: 48.6537069, lng: 17.4722088 },
    { lat: 48.6533139, lng: 17.4733895 },
    { lat: 48.65316, lng: 17.4740213 },
    { lat: 48.6531177, lng: 17.4749665 },
    { lat: 48.653094, lng: 17.47564 },
    { lat: 48.6530508, lng: 17.4764286 },
    { lat: 48.6528835, lng: 17.4775217 },
    { lat: 48.6539984, lng: 17.4779661 },
    { lat: 48.6538836, lng: 17.4794574 },
    { lat: 48.6552271, lng: 17.4793796 },
    { lat: 48.6553838, lng: 17.4794086 },
    { lat: 48.6554141, lng: 17.4795135 },
    { lat: 48.6553784, lng: 17.4797837 },
    { lat: 48.6562282, lng: 17.4799164 },
    { lat: 48.657423, lng: 17.4798635 },
    { lat: 48.6576907, lng: 17.4804636 },
    { lat: 48.6579207, lng: 17.4807216 },
    { lat: 48.6581628, lng: 17.4812404 },
    { lat: 48.6582715, lng: 17.481564 },
    { lat: 48.6584946, lng: 17.4830076 },
    { lat: 48.6588164, lng: 17.4829889 },
    { lat: 48.6590368, lng: 17.4830601 },
    { lat: 48.6590203, lng: 17.4834212 },
    { lat: 48.6590889, lng: 17.4836645 },
    { lat: 48.6597354, lng: 17.4842044 },
    { lat: 48.6599984, lng: 17.484572 },
    { lat: 48.6601149, lng: 17.4849626 },
    { lat: 48.6601318, lng: 17.485087 },
    { lat: 48.6600945, lng: 17.4851214 },
    { lat: 48.6604323, lng: 17.4859385 },
    { lat: 48.6604797, lng: 17.486361 },
    { lat: 48.6604334, lng: 17.4865935 },
    { lat: 48.6609374, lng: 17.4869653 },
    { lat: 48.6611795, lng: 17.4870623 },
    { lat: 48.661534, lng: 17.4870772 },
    { lat: 48.6620915, lng: 17.4869314 },
    { lat: 48.6623837, lng: 17.4867677 },
    { lat: 48.6623995, lng: 17.4869076 },
    { lat: 48.6632622, lng: 17.4864974 },
    { lat: 48.66358, lng: 17.4864511 },
    { lat: 48.6634894, lng: 17.4867305 },
    { lat: 48.6634981, lng: 17.486885 },
    { lat: 48.6637263, lng: 17.4878057 },
    { lat: 48.6635483, lng: 17.4884693 },
    { lat: 48.6640951, lng: 17.4885962 },
    { lat: 48.6642339, lng: 17.4888393 },
    { lat: 48.6643938, lng: 17.4887786 },
    { lat: 48.6644822, lng: 17.4897743 },
    { lat: 48.6645109, lng: 17.4908101 },
    { lat: 48.664452, lng: 17.4914971 },
    { lat: 48.6644115, lng: 17.4914484 },
    { lat: 48.6643222, lng: 17.4916225 },
    { lat: 48.6639542, lng: 17.4926352 },
    { lat: 48.6639556, lng: 17.4927644 },
    { lat: 48.6641699, lng: 17.4929183 },
    { lat: 48.6647396, lng: 17.4931041 },
    { lat: 48.665729, lng: 17.4933336 },
    { lat: 48.6661661, lng: 17.4932111 },
    { lat: 48.6664432, lng: 17.4930404 },
    { lat: 48.667042, lng: 17.4925143 },
    { lat: 48.6670978, lng: 17.4926433 },
    { lat: 48.6670875, lng: 17.4928531 },
    { lat: 48.6671846, lng: 17.4931909 },
    { lat: 48.667139, lng: 17.4934302 },
    { lat: 48.6669003, lng: 17.4940633 },
    { lat: 48.668148, lng: 17.494806 },
    { lat: 48.668218, lng: 17.494663 },
    { lat: 48.668228, lng: 17.494623 },
    { lat: 48.668285, lng: 17.49453 },
    { lat: 48.668352, lng: 17.49429 },
    { lat: 48.668365, lng: 17.494146 },
    { lat: 48.668414, lng: 17.493956 },
    { lat: 48.668428, lng: 17.49382 },
    { lat: 48.668491, lng: 17.493607 },
    { lat: 48.668531, lng: 17.49355 },
    { lat: 48.668545, lng: 17.493483 },
    { lat: 48.668599, lng: 17.493396 },
    { lat: 48.66861, lng: 17.493322 },
    { lat: 48.66871, lng: 17.493058 },
    { lat: 48.668789, lng: 17.492742 },
    { lat: 48.668829, lng: 17.492725 },
    { lat: 48.668899, lng: 17.49269 },
    { lat: 48.668969, lng: 17.492555 },
    { lat: 48.669065, lng: 17.49249 },
    { lat: 48.669062, lng: 17.492305 },
    { lat: 48.669159, lng: 17.492237 },
    { lat: 48.669147, lng: 17.492117 },
    { lat: 48.669197, lng: 17.491945 },
    { lat: 48.669231, lng: 17.491787 },
    { lat: 48.669288, lng: 17.491659 },
    { lat: 48.669317, lng: 17.491516 },
    { lat: 48.669309, lng: 17.491459 },
    { lat: 48.669341, lng: 17.491303 },
    { lat: 48.669456, lng: 17.491196 },
    { lat: 48.669469, lng: 17.491147 },
    { lat: 48.669552, lng: 17.49114 },
    { lat: 48.669773, lng: 17.491168 },
    { lat: 48.669832, lng: 17.491178 },
    { lat: 48.670199, lng: 17.491234 },
    { lat: 48.670609, lng: 17.491298 },
    { lat: 48.670616, lng: 17.491171 },
    { lat: 48.670674, lng: 17.490483 },
    { lat: 48.67068, lng: 17.490171 },
    { lat: 48.670684, lng: 17.489971 },
    { lat: 48.670689, lng: 17.489389 },
    { lat: 48.670691, lng: 17.489264 },
    { lat: 48.670701, lng: 17.488624 },
    { lat: 48.670708, lng: 17.4883 },
    { lat: 48.670709, lng: 17.488198 },
    { lat: 48.670976, lng: 17.488329 },
    { lat: 48.671525, lng: 17.488717 },
    { lat: 48.671656, lng: 17.48882 },
    { lat: 48.671661, lng: 17.488808 },
    { lat: 48.671962, lng: 17.489028 },
    { lat: 48.672349, lng: 17.489309 },
    { lat: 48.67259, lng: 17.489448 },
    { lat: 48.672995, lng: 17.489708 },
    { lat: 48.673206, lng: 17.48981 },
    { lat: 48.673651, lng: 17.490004 },
    { lat: 48.673723, lng: 17.49003 },
    { lat: 48.673895, lng: 17.490068 },
    { lat: 48.674054, lng: 17.49009 },
    { lat: 48.674389, lng: 17.490052 },
    { lat: 48.674418, lng: 17.490052 },
    { lat: 48.674192, lng: 17.489339 },
    { lat: 48.674115, lng: 17.488964 },
    { lat: 48.674081, lng: 17.488613 },
    { lat: 48.674029, lng: 17.487726 },
    { lat: 48.674039, lng: 17.487145 },
    { lat: 48.674064, lng: 17.486906 },
    { lat: 48.674086, lng: 17.486711 },
    { lat: 48.674122, lng: 17.486549 },
    { lat: 48.674307, lng: 17.485927 },
    { lat: 48.674338, lng: 17.485679 },
    { lat: 48.674363, lng: 17.485685 },
    { lat: 48.674639, lng: 17.485769 },
    { lat: 48.675204, lng: 17.485949 },
    { lat: 48.675674, lng: 17.48606 },
    { lat: 48.676503, lng: 17.486245 },
    { lat: 48.67666, lng: 17.486324 },
    { lat: 48.676859, lng: 17.486343 },
    { lat: 48.677208, lng: 17.486263 },
    { lat: 48.677349, lng: 17.485443 },
    { lat: 48.677413, lng: 17.484921 },
    { lat: 48.677406, lng: 17.484683 },
    { lat: 48.677379, lng: 17.484383 },
    { lat: 48.677393, lng: 17.484163 },
    { lat: 48.677398, lng: 17.484074 },
    { lat: 48.677436, lng: 17.483985 },
    { lat: 48.677533, lng: 17.483858 },
    { lat: 48.677581, lng: 17.483698 },
    { lat: 48.677623, lng: 17.483684 },
    { lat: 48.677673, lng: 17.483666 },
    { lat: 48.677722, lng: 17.48361 },
    { lat: 48.677784, lng: 17.483513 },
    { lat: 48.677883, lng: 17.483498 },
    { lat: 48.67799, lng: 17.483357 },
    { lat: 48.678011, lng: 17.482983 },
    { lat: 48.678042, lng: 17.482968 },
    { lat: 48.678342, lng: 17.48281 },
    { lat: 48.678358, lng: 17.482802 },
    { lat: 48.678383, lng: 17.482808 },
    { lat: 48.678453, lng: 17.482822 },
    { lat: 48.678566, lng: 17.482702 },
    { lat: 48.678612, lng: 17.482708 },
    { lat: 48.678853, lng: 17.482527 },
    { lat: 48.678894, lng: 17.482454 },
    { lat: 48.678982, lng: 17.482423 },
    { lat: 48.679129, lng: 17.482253 },
    { lat: 48.679256, lng: 17.482203 },
    { lat: 48.679343, lng: 17.482109 },
    { lat: 48.679419, lng: 17.482071 },
    { lat: 48.679464, lng: 17.482045 },
    { lat: 48.679532, lng: 17.481914 },
    { lat: 48.679605, lng: 17.481827 },
    { lat: 48.679742, lng: 17.481757 },
    { lat: 48.679794, lng: 17.481701 },
    { lat: 48.679803, lng: 17.481689 },
    { lat: 48.679913, lng: 17.481934 },
    { lat: 48.679914, lng: 17.482176 },
    { lat: 48.679948, lng: 17.482284 },
    { lat: 48.679958, lng: 17.4823 },
    { lat: 48.679965, lng: 17.482309 },
    { lat: 48.680009, lng: 17.482332 },
    { lat: 48.68006, lng: 17.482346 },
    { lat: 48.680072, lng: 17.482374 },
    { lat: 48.680088, lng: 17.482468 },
    { lat: 48.680094, lng: 17.482521 },
    { lat: 48.680059, lng: 17.482624 },
    { lat: 48.680071, lng: 17.482635 },
    { lat: 48.680109, lng: 17.482664 },
    { lat: 48.680205, lng: 17.482733 },
    { lat: 48.68035, lng: 17.482738 },
    { lat: 48.680392, lng: 17.48274 },
    { lat: 48.680657, lng: 17.482636 },
    { lat: 48.680699, lng: 17.482605 },
    { lat: 48.681188, lng: 17.482637 },
    { lat: 48.681586, lng: 17.482678 },
    { lat: 48.681781, lng: 17.482719 },
    { lat: 48.682299, lng: 17.48284 },
    { lat: 48.682539, lng: 17.4829 },
    { lat: 48.68257, lng: 17.482885 },
    { lat: 48.682639, lng: 17.482747 },
    { lat: 48.682153, lng: 17.48052 },
    { lat: 48.682139, lng: 17.480461 },
    { lat: 48.682149, lng: 17.480462 },
    { lat: 48.682585, lng: 17.48052 },
    { lat: 48.682924, lng: 17.480562 },
    { lat: 48.683176, lng: 17.480602 },
    { lat: 48.683222, lng: 17.480601 },
    { lat: 48.68324, lng: 17.480396 },
    { lat: 48.683311, lng: 17.480264 },
    { lat: 48.683383, lng: 17.480156 },
    { lat: 48.683388, lng: 17.480008 },
    { lat: 48.68343, lng: 17.479875 },
    { lat: 48.68345, lng: 17.479726 },
    { lat: 48.683532, lng: 17.4796 },
    { lat: 48.683613, lng: 17.479454 },
    { lat: 48.683715, lng: 17.479178 },
    { lat: 48.68374, lng: 17.47892 },
    { lat: 48.683768, lng: 17.478817 },
    { lat: 48.683733, lng: 17.478722 },
    { lat: 48.683767, lng: 17.478652 },
    { lat: 48.683757, lng: 17.478343 },
    { lat: 48.683775, lng: 17.478343 },
    { lat: 48.683517, lng: 17.47798 },
    { lat: 48.683408, lng: 17.477877 },
    { lat: 48.683286, lng: 17.477796 },
    { lat: 48.683196, lng: 17.477706 },
    { lat: 48.683109, lng: 17.47758 },
    { lat: 48.683081, lng: 17.477421 },
    { lat: 48.683091, lng: 17.477382 },
    { lat: 48.68317, lng: 17.477295 },
    { lat: 48.68318, lng: 17.477251 },
    { lat: 48.68317, lng: 17.477251 },
    { lat: 48.683135, lng: 17.477252 },
    { lat: 48.68304, lng: 17.476616 },
    { lat: 48.68301, lng: 17.476222 },
    { lat: 48.683031, lng: 17.47573 },
    { lat: 48.683033, lng: 17.475695 },
    { lat: 48.683034, lng: 17.475675 },
    { lat: 48.683018, lng: 17.475609 },
    { lat: 48.683024, lng: 17.475545 },
    { lat: 48.682972, lng: 17.475458 },
    { lat: 48.682958, lng: 17.475351 },
    { lat: 48.682956, lng: 17.475256 },
    { lat: 48.683041, lng: 17.475007 },
    { lat: 48.683048, lng: 17.474965 },
    { lat: 48.683045, lng: 17.474908 },
    { lat: 48.683068, lng: 17.474903 },
    { lat: 48.683322, lng: 17.475183 },
    { lat: 48.683687, lng: 17.475888 },
    { lat: 48.683753, lng: 17.475882 },
    { lat: 48.683885, lng: 17.475868 },
    { lat: 48.684404, lng: 17.475813 },
    { lat: 48.684658, lng: 17.476502 },
    { lat: 48.684677, lng: 17.476558 },
    { lat: 48.684748, lng: 17.476748 },
    { lat: 48.684854, lng: 17.476898 },
    { lat: 48.684977, lng: 17.477002 },
    { lat: 48.685121, lng: 17.477048 },
    { lat: 48.685147, lng: 17.476984 },
    { lat: 48.685534, lng: 17.477166 },
    { lat: 48.68555, lng: 17.47709 },
    { lat: 48.685748, lng: 17.477184 },
    { lat: 48.685883, lng: 17.477212 },
    { lat: 48.686038, lng: 17.47731 },
    { lat: 48.686066, lng: 17.477328 },
    { lat: 48.686253, lng: 17.477455 },
    { lat: 48.686615, lng: 17.47767 },
    { lat: 48.686885, lng: 17.477867 },
    { lat: 48.687149, lng: 17.478022 },
    { lat: 48.687186, lng: 17.47809 },
    { lat: 48.68742, lng: 17.478275 },
    { lat: 48.687543, lng: 17.478375 },
    { lat: 48.687752, lng: 17.478589 },
    { lat: 48.687881, lng: 17.478724 },
    { lat: 48.687997, lng: 17.478819 },
    { lat: 48.688196, lng: 17.478986 },
    { lat: 48.688234, lng: 17.479032 },
    { lat: 48.688386, lng: 17.479209 },
    { lat: 48.688518, lng: 17.47927 },
    { lat: 48.688943, lng: 17.47963 },
    { lat: 48.68902, lng: 17.479692 },
    { lat: 48.689619, lng: 17.480135 },
    { lat: 48.689909, lng: 17.480437 },
    { lat: 48.690048, lng: 17.480458 },
    { lat: 48.690075, lng: 17.480462 },
    { lat: 48.690508, lng: 17.480218 },
    { lat: 48.690926, lng: 17.479999 },
    { lat: 48.690954, lng: 17.479982 },
    { lat: 48.691145, lng: 17.479885 },
    { lat: 48.691389, lng: 17.479762 },
    { lat: 48.691665, lng: 17.479637 },
    { lat: 48.691966, lng: 17.479535 },
    { lat: 48.692437, lng: 17.479447 },
    { lat: 48.692425, lng: 17.47942 },
    { lat: 48.692793, lng: 17.479391 },
    { lat: 48.693327, lng: 17.47926 },
    { lat: 48.693765, lng: 17.479111 },
    { lat: 48.693883, lng: 17.479086 },
    { lat: 48.693814, lng: 17.478749 },
    { lat: 48.693607, lng: 17.47701 },
    { lat: 48.693626, lng: 17.477007 },
    { lat: 48.693849, lng: 17.477015 },
    { lat: 48.694117, lng: 17.477065 },
    { lat: 48.694513, lng: 17.47719 },
    { lat: 48.694576, lng: 17.476823 },
    { lat: 48.694661, lng: 17.476397 },
    { lat: 48.694732, lng: 17.476086 },
    { lat: 48.694797, lng: 17.475769 },
    { lat: 48.694827, lng: 17.475619 },
    { lat: 48.694862, lng: 17.475504 },
    { lat: 48.694907, lng: 17.475367 },
    { lat: 48.69496, lng: 17.475196 },
    { lat: 48.695188, lng: 17.474505 },
    { lat: 48.695255, lng: 17.474467 },
    { lat: 48.695247, lng: 17.47439 },
    { lat: 48.695223, lng: 17.474339 },
    { lat: 48.69522, lng: 17.474283 },
    { lat: 48.695224, lng: 17.474247 },
    { lat: 48.695261, lng: 17.474114 },
    { lat: 48.695301, lng: 17.474028 },
    { lat: 48.695335, lng: 17.474006 },
    { lat: 48.695358, lng: 17.473951 },
    { lat: 48.695405, lng: 17.473752 },
    { lat: 48.695452, lng: 17.473648 },
    { lat: 48.695507, lng: 17.473565 },
    { lat: 48.695519, lng: 17.473517 },
    { lat: 48.695573, lng: 17.473474 },
    { lat: 48.695594, lng: 17.47345 },
    { lat: 48.695615, lng: 17.473449 },
    { lat: 48.695639, lng: 17.473417 },
    { lat: 48.695717, lng: 17.473374 },
    { lat: 48.695881, lng: 17.473237 },
    { lat: 48.695902, lng: 17.47319 },
    { lat: 48.695906, lng: 17.473155 },
    { lat: 48.695888, lng: 17.473089 },
    { lat: 48.695902, lng: 17.473002 },
    { lat: 48.695903, lng: 17.472874 },
    { lat: 48.695922, lng: 17.472824 },
    { lat: 48.695935, lng: 17.47281 },
    { lat: 48.695954, lng: 17.472789 },
    { lat: 48.696239, lng: 17.472484 },
    { lat: 48.696258, lng: 17.472448 },
    { lat: 48.696502, lng: 17.472058 },
    { lat: 48.696918, lng: 17.471271 },
    { lat: 48.697288, lng: 17.470405 },
    { lat: 48.6973, lng: 17.470378 },
    { lat: 48.696819, lng: 17.469564 },
    { lat: 48.696833, lng: 17.469543 },
    { lat: 48.696892, lng: 17.469466 },
    { lat: 48.6969655, lng: 17.4693584 },
    { lat: 48.697447, lng: 17.468654 },
    { lat: 48.697731, lng: 17.468263 },
    { lat: 48.697935, lng: 17.467996 },
    { lat: 48.698067, lng: 17.467867 },
    { lat: 48.698365, lng: 17.467644 },
    { lat: 48.698561, lng: 17.467525 },
    { lat: 48.698701, lng: 17.467442 },
    { lat: 48.699078, lng: 17.467215 },
    { lat: 48.699137, lng: 17.467177 },
    { lat: 48.699359, lng: 17.467033 },
    { lat: 48.699961, lng: 17.466654 },
    { lat: 48.699918, lng: 17.466202 },
    { lat: 48.699934, lng: 17.466154 },
    { lat: 48.699941, lng: 17.466135 },
    { lat: 48.700176, lng: 17.466286 },
    { lat: 48.700303, lng: 17.46638 },
    { lat: 48.700439, lng: 17.466449 },
    { lat: 48.700456, lng: 17.466461 },
    { lat: 48.700568, lng: 17.466678 },
    { lat: 48.700591, lng: 17.46677 },
    { lat: 48.700658, lng: 17.466875 },
    { lat: 48.700694, lng: 17.46692 },
    { lat: 48.700758, lng: 17.467003 },
    { lat: 48.70089, lng: 17.467225 },
    { lat: 48.70092, lng: 17.467304 },
    { lat: 48.700939, lng: 17.467414 },
    { lat: 48.700928, lng: 17.467481 },
    { lat: 48.700969, lng: 17.467626 },
    { lat: 48.700995, lng: 17.467771 },
    { lat: 48.701046, lng: 17.468096 },
    { lat: 48.701067, lng: 17.468125 },
    { lat: 48.701084, lng: 17.468151 },
    { lat: 48.701107, lng: 17.46821 },
    { lat: 48.701124, lng: 17.468428 },
    { lat: 48.701117, lng: 17.468506 },
    { lat: 48.701117, lng: 17.468521 },
    { lat: 48.701159, lng: 17.468655 },
    { lat: 48.701118, lng: 17.468828 },
    { lat: 48.701117, lng: 17.468846 },
    { lat: 48.701133, lng: 17.468881 },
    { lat: 48.70114, lng: 17.468892 },
    { lat: 48.70115, lng: 17.468902 },
    { lat: 48.701239, lng: 17.468957 },
    { lat: 48.701352, lng: 17.468989 },
    { lat: 48.701508, lng: 17.46913 },
    { lat: 48.701519, lng: 17.469112 },
    { lat: 48.701528, lng: 17.469095 },
    { lat: 48.701719, lng: 17.468713 },
    { lat: 48.701754, lng: 17.468725 },
    { lat: 48.702071, lng: 17.468827 },
    { lat: 48.702178, lng: 17.468872 },
    { lat: 48.702373, lng: 17.468949 },
    { lat: 48.702527, lng: 17.468998 },
    { lat: 48.702714, lng: 17.46912 },
    { lat: 48.702739, lng: 17.469136 },
    { lat: 48.702875, lng: 17.469382 },
    { lat: 48.703162, lng: 17.469213 },
    { lat: 48.7033, lng: 17.469242 },
    { lat: 48.70341, lng: 17.469423 },
    { lat: 48.703452, lng: 17.469383 },
    { lat: 48.70354, lng: 17.469295 },
    { lat: 48.703786, lng: 17.469276 },
    { lat: 48.703857, lng: 17.469199 },
    { lat: 48.703948, lng: 17.469232 },
    { lat: 48.704009, lng: 17.469154 },
    { lat: 48.704074, lng: 17.469157 },
    { lat: 48.70415, lng: 17.469104 },
    { lat: 48.704245, lng: 17.469159 },
    { lat: 48.704283, lng: 17.469141 },
    { lat: 48.704296, lng: 17.469088 },
    { lat: 48.704284, lng: 17.469041 },
    { lat: 48.70433, lng: 17.468916 },
    { lat: 48.70443, lng: 17.468964 },
    { lat: 48.704582, lng: 17.468825 },
    { lat: 48.704656, lng: 17.4688 },
    { lat: 48.704728, lng: 17.468692 },
    { lat: 48.704775, lng: 17.468618 },
    { lat: 48.70479, lng: 17.468594 },
    { lat: 48.704849, lng: 17.468621 },
    { lat: 48.704996, lng: 17.468492 },
    { lat: 48.705058, lng: 17.46847 },
    { lat: 48.705137, lng: 17.468514 },
    { lat: 48.705186, lng: 17.468423 },
    { lat: 48.705274, lng: 17.468447 },
    { lat: 48.705317, lng: 17.468395 },
    { lat: 48.705415, lng: 17.468399 },
    { lat: 48.705516, lng: 17.468397 },
    { lat: 48.70559, lng: 17.468452 },
    { lat: 48.705708, lng: 17.468407 },
    { lat: 48.705731, lng: 17.468357 },
    { lat: 48.70585, lng: 17.468335 },
    { lat: 48.705866, lng: 17.468316 },
    { lat: 48.705882, lng: 17.468296 },
    { lat: 48.705898, lng: 17.468292 },
    { lat: 48.705924, lng: 17.468297 },
    { lat: 48.70599, lng: 17.468276 },
    { lat: 48.706014, lng: 17.468262 },
    { lat: 48.706029, lng: 17.468259 },
    { lat: 48.706056, lng: 17.468268 },
    { lat: 48.706072, lng: 17.46826 },
    { lat: 48.706142, lng: 17.468215 },
    { lat: 48.706154, lng: 17.46813 },
    { lat: 48.706215, lng: 17.468158 },
    { lat: 48.706266, lng: 17.468092 },
    { lat: 48.7063, lng: 17.46805 },
    { lat: 48.706358, lng: 17.468058 },
    { lat: 48.7065, lng: 17.468007 },
    { lat: 48.706598, lng: 17.468 },
    { lat: 48.706654, lng: 17.46794 },
    { lat: 48.706692, lng: 17.467851 },
    { lat: 48.706803, lng: 17.467815 },
    { lat: 48.706958, lng: 17.467656 },
    { lat: 48.707094, lng: 17.467541 },
    { lat: 48.707142, lng: 17.467502 },
    { lat: 48.707169, lng: 17.467551 },
    { lat: 48.707711, lng: 17.468461 },
    { lat: 48.707844, lng: 17.468682 },
    { lat: 48.707855, lng: 17.468701 },
    { lat: 48.707476, lng: 17.468902 },
    { lat: 48.707205, lng: 17.469191 },
    { lat: 48.707129, lng: 17.469359 },
    { lat: 48.707111, lng: 17.469562 },
    { lat: 48.707144, lng: 17.469996 },
    { lat: 48.707281, lng: 17.470294 },
    { lat: 48.708091, lng: 17.470953 },
    { lat: 48.708414, lng: 17.471068 },
    { lat: 48.708562, lng: 17.471148 },
    { lat: 48.708665, lng: 17.471271 },
    { lat: 48.708727, lng: 17.471344 },
    { lat: 48.708841, lng: 17.471556 },
    { lat: 48.708945, lng: 17.472045 },
    { lat: 48.708984, lng: 17.47209 },
    { lat: 48.709217, lng: 17.472171 },
    { lat: 48.709429, lng: 17.472345 },
    { lat: 48.709543, lng: 17.472496 },
    { lat: 48.709679, lng: 17.472737 },
    { lat: 48.710007, lng: 17.473318 },
    { lat: 48.71008, lng: 17.47335 },
    { lat: 48.710405, lng: 17.473303 },
    { lat: 48.710504, lng: 17.473332 },
    { lat: 48.71065, lng: 17.473485 },
    { lat: 48.710911, lng: 17.473604 },
    { lat: 48.71132, lng: 17.473721 },
    { lat: 48.711988, lng: 17.473602 },
    { lat: 48.71233, lng: 17.473664 },
    { lat: 48.712689, lng: 17.473626 },
    { lat: 48.71293, lng: 17.473653 },
    { lat: 48.713018, lng: 17.473615 },
    { lat: 48.713296, lng: 17.47343 },
    { lat: 48.713873, lng: 17.473467 },
    { lat: 48.71411, lng: 17.473573 },
    { lat: 48.714312, lng: 17.473628 },
    { lat: 48.714446, lng: 17.473736 },
    { lat: 48.714515, lng: 17.473817 },
    { lat: 48.71461, lng: 17.474047 },
    { lat: 48.714833, lng: 17.474545 },
    { lat: 48.715269, lng: 17.474893 },
    { lat: 48.71537, lng: 17.475019 },
    { lat: 48.715613, lng: 17.475202 },
    { lat: 48.71588, lng: 17.475216 },
    { lat: 48.716432, lng: 17.475093 },
    { lat: 48.716647, lng: 17.475024 },
    { lat: 48.71701, lng: 17.475268 },
    { lat: 48.717232, lng: 17.475439 },
    { lat: 48.71754, lng: 17.475677 },
    { lat: 48.717804, lng: 17.475827 },
    { lat: 48.718001, lng: 17.475983 },
    { lat: 48.718101, lng: 17.47606 },
    { lat: 48.71865, lng: 17.476569 },
    { lat: 48.719039, lng: 17.476887 },
    { lat: 48.719391, lng: 17.477105 },
    { lat: 48.719861, lng: 17.476791 },
    { lat: 48.720053, lng: 17.476674 },
    { lat: 48.720357, lng: 17.476456 },
    { lat: 48.720788, lng: 17.47615 },
    { lat: 48.721098, lng: 17.475925 },
    { lat: 48.721551, lng: 17.475579 },
    { lat: 48.721726, lng: 17.4754 },
    { lat: 48.721909, lng: 17.475336 },
    { lat: 48.72241, lng: 17.475467 },
    { lat: 48.72269, lng: 17.475602 },
    { lat: 48.723046, lng: 17.475629 },
    { lat: 48.723446, lng: 17.475474 },
    { lat: 48.723364, lng: 17.4752832 },
    { lat: 48.7232769, lng: 17.4748914 },
    { lat: 48.7232322, lng: 17.474332 },
    { lat: 48.7231087, lng: 17.474114 },
    { lat: 48.7218781, lng: 17.4738547 },
    { lat: 48.7217189, lng: 17.4735749 },
    { lat: 48.7214368, lng: 17.4736673 },
    { lat: 48.7213442, lng: 17.4737552 },
    { lat: 48.7212016, lng: 17.4736794 },
    { lat: 48.7212155, lng: 17.4732406 },
    { lat: 48.7211038, lng: 17.4724153 },
    { lat: 48.7207265, lng: 17.4715998 },
    { lat: 48.7206629, lng: 17.4713455 },
    { lat: 48.7204564, lng: 17.4709804 },
    { lat: 48.7202522, lng: 17.4710177 },
    { lat: 48.7200255, lng: 17.4708112 },
    { lat: 48.719558, lng: 17.4707249 },
    { lat: 48.7191846, lng: 17.470322 },
    { lat: 48.7190252, lng: 17.4702683 },
    { lat: 48.7187015, lng: 17.4700101 },
    { lat: 48.7187584, lng: 17.469777 },
    { lat: 48.7189423, lng: 17.4690039 },
    { lat: 48.7189555, lng: 17.4684702 },
    { lat: 48.7187161, lng: 17.4681017 },
    { lat: 48.7177759, lng: 17.4674221 },
    { lat: 48.7176988, lng: 17.4671526 },
    { lat: 48.7177583, lng: 17.4662111 },
    { lat: 48.7177237, lng: 17.4652247 },
    { lat: 48.7176158, lng: 17.4648292 },
    { lat: 48.71746, lng: 17.4644764 },
    { lat: 48.7174525, lng: 17.4642718 },
    { lat: 48.7177058, lng: 17.4639573 },
    { lat: 48.7177216, lng: 17.4639936 },
    { lat: 48.7179461, lng: 17.4636561 },
    { lat: 48.7182502, lng: 17.4629339 },
    { lat: 48.7186931, lng: 17.4627804 },
    { lat: 48.7188576, lng: 17.4622589 },
    { lat: 48.7188153, lng: 17.4617572 },
    { lat: 48.7189529, lng: 17.4612252 },
    { lat: 48.7189234, lng: 17.4604287 },
    { lat: 48.7189796, lng: 17.4592617 },
    { lat: 48.7189652, lng: 17.4591067 },
    { lat: 48.7186327, lng: 17.4581135 },
    { lat: 48.71863, lng: 17.4577859 },
    { lat: 48.7188225, lng: 17.4568267 },
    { lat: 48.718895, lng: 17.4561401 },
    { lat: 48.7193893, lng: 17.4558503 },
    { lat: 48.7199401, lng: 17.4553523 },
    { lat: 48.7191667, lng: 17.4551096 },
    { lat: 48.7193991, lng: 17.4523719 },
    { lat: 48.719339, lng: 17.4516997 },
    { lat: 48.7193278, lng: 17.4503811 },
    { lat: 48.7191803, lng: 17.4494163 },
    { lat: 48.7188182, lng: 17.4490211 },
    { lat: 48.7185832, lng: 17.4486485 },
    { lat: 48.7184214, lng: 17.4483102 },
    { lat: 48.7182057, lng: 17.4474627 },
    { lat: 48.7181586, lng: 17.4474159 },
    { lat: 48.7175071, lng: 17.4471905 },
    { lat: 48.716506, lng: 17.4470774 },
    { lat: 48.7165736, lng: 17.4466512 },
    { lat: 48.7168451, lng: 17.4461311 },
    { lat: 48.7168775, lng: 17.445685 },
    { lat: 48.7158099, lng: 17.4439727 },
    { lat: 48.7143557, lng: 17.4408471 },
    { lat: 48.7140056, lng: 17.4403012 },
    { lat: 48.7135356, lng: 17.4397549 },
    { lat: 48.7132325, lng: 17.4395536 },
    { lat: 48.7124738, lng: 17.4394009 },
    { lat: 48.7128685, lng: 17.437914 },
    { lat: 48.7126983, lng: 17.4377765 },
    { lat: 48.7120093, lng: 17.4376036 },
    { lat: 48.7123584, lng: 17.4367403 },
    { lat: 48.7124273, lng: 17.4366704 },
    { lat: 48.7124863, lng: 17.4364297 },
    { lat: 48.7124861, lng: 17.4359876 },
    { lat: 48.7125552, lng: 17.4356224 },
    { lat: 48.7126738, lng: 17.4354097 },
    { lat: 48.7124192, lng: 17.4348942 },
    { lat: 48.712582, lng: 17.4342973 },
    { lat: 48.7128377, lng: 17.4340595 },
    { lat: 48.712911, lng: 17.4339147 },
    { lat: 48.7128776, lng: 17.433837 },
    { lat: 48.7125299, lng: 17.4337507 },
    { lat: 48.7125422, lng: 17.433379 },
    { lat: 48.7124308, lng: 17.4331411 },
    { lat: 48.7122284, lng: 17.4330667 },
    { lat: 48.712085, lng: 17.4332441 },
    { lat: 48.7118941, lng: 17.4332782 },
    { lat: 48.7118059, lng: 17.4333644 },
    { lat: 48.7116972, lng: 17.4333403 },
    { lat: 48.7115297, lng: 17.433107 },
    { lat: 48.7113844, lng: 17.4330439 },
    { lat: 48.7112712, lng: 17.4333037 },
    { lat: 48.7111312, lng: 17.4334276 },
    { lat: 48.7109157, lng: 17.4333259 },
    { lat: 48.7107125, lng: 17.4330631 },
    { lat: 48.7100572, lng: 17.4327062 },
    { lat: 48.709902, lng: 17.4324389 },
    { lat: 48.7099319, lng: 17.4321306 },
    { lat: 48.7098922, lng: 17.4320391 },
    { lat: 48.7097197, lng: 17.4320244 },
    { lat: 48.709407, lng: 17.4324336 },
    { lat: 48.7091238, lng: 17.4321365 },
    { lat: 48.7089915, lng: 17.4318888 },
    { lat: 48.7089753, lng: 17.4316565 },
    { lat: 48.7087612, lng: 17.431521 },
    { lat: 48.7086323, lng: 17.4313461 },
    { lat: 48.7086885, lng: 17.4310743 },
    { lat: 48.7085023, lng: 17.4307107 },
    { lat: 48.7084732, lng: 17.4305204 },
    { lat: 48.7081171, lng: 17.4303038 },
    { lat: 48.707908, lng: 17.4303534 },
    { lat: 48.7078914, lng: 17.4303708 },
    { lat: 48.7077324, lng: 17.4305376 },
    { lat: 48.707343, lng: 17.4307214 },
    { lat: 48.7073567, lng: 17.4307909 },
    { lat: 48.7072923, lng: 17.4308096 },
    { lat: 48.7072038, lng: 17.4313322 },
    { lat: 48.7070395, lng: 17.4315158 },
    { lat: 48.7064788, lng: 17.4317883 },
    { lat: 48.7063524, lng: 17.4319817 },
    { lat: 48.7061496, lng: 17.432009 },
    { lat: 48.7060284, lng: 17.4306497 },
    { lat: 48.7058918, lng: 17.4304759 },
    { lat: 48.7060774, lng: 17.4304665 },
    { lat: 48.7060128, lng: 17.4303369 },
    { lat: 48.7060588, lng: 17.4303312 },
    { lat: 48.7059238, lng: 17.4299023 },
    { lat: 48.7058182, lng: 17.4296993 },
    { lat: 48.7056633, lng: 17.4295386 },
    { lat: 48.7054549, lng: 17.4295667 },
    { lat: 48.7051594, lng: 17.4297824 },
    { lat: 48.7049235, lng: 17.4298707 },
    { lat: 48.7045944, lng: 17.4297448 },
    { lat: 48.7041585, lng: 17.4297637 },
    { lat: 48.7036298, lng: 17.4298481 },
    { lat: 48.7033431, lng: 17.4300608 },
    { lat: 48.7033342, lng: 17.4299931 },
    { lat: 48.7030666, lng: 17.4299751 },
    { lat: 48.7024081, lng: 17.430257 },
    { lat: 48.7014197, lng: 17.4302204 },
    { lat: 48.7011975, lng: 17.4301029 },
    { lat: 48.7005036, lng: 17.4303581 },
    { lat: 48.7000632, lng: 17.4303076 },
    { lat: 48.6998683, lng: 17.430349 },
    { lat: 48.6989833, lng: 17.4299029 },
    { lat: 48.6986504, lng: 17.4298889 },
    { lat: 48.6980209, lng: 17.4296373 },
    { lat: 48.6974452, lng: 17.4295358 },
    { lat: 48.6966801, lng: 17.4298083 },
    { lat: 48.6961133, lng: 17.4298132 },
    { lat: 48.6956476, lng: 17.4297375 },
    { lat: 48.6954124, lng: 17.4299443 },
    { lat: 48.6950457, lng: 17.4300525 },
    { lat: 48.6939885, lng: 17.4302265 },
    { lat: 48.6940169, lng: 17.4297701 },
    { lat: 48.6939484, lng: 17.4296368 },
    { lat: 48.6938971, lng: 17.4293136 },
    { lat: 48.6940546, lng: 17.4283399 },
    { lat: 48.6940529, lng: 17.4279079 },
    { lat: 48.694113, lng: 17.4276321 },
    { lat: 48.694049, lng: 17.4273547 },
    { lat: 48.6937692, lng: 17.427394 },
    { lat: 48.6931821, lng: 17.4278191 },
    { lat: 48.692829, lng: 17.4280032 },
    { lat: 48.6926842, lng: 17.427963 },
    { lat: 48.692419, lng: 17.427722 },
    { lat: 48.6923128, lng: 17.4275106 },
    { lat: 48.6920882, lng: 17.4267556 },
    { lat: 48.6917429, lng: 17.4249534 },
    { lat: 48.6914616, lng: 17.4239702 },
    { lat: 48.6911282, lng: 17.4240038 },
    { lat: 48.6906729, lng: 17.4238064 },
    { lat: 48.6907253, lng: 17.4233047 },
    { lat: 48.6899031, lng: 17.4232907 },
    { lat: 48.6889924, lng: 17.4234748 },
    { lat: 48.6878066, lng: 17.4236175 },
    { lat: 48.6849975, lng: 17.4244418 },
    { lat: 48.6835417, lng: 17.4249669 },
    { lat: 48.6810795, lng: 17.4264081 },
    { lat: 48.6806057, lng: 17.4251874 },
    { lat: 48.6802685, lng: 17.4241435 },
    { lat: 48.6802017, lng: 17.4242642 },
    { lat: 48.6800753, lng: 17.4244925 },
    { lat: 48.6785882, lng: 17.4241822 },
    { lat: 48.6785791, lng: 17.4244641 },
    { lat: 48.6785052, lng: 17.4245618 },
    { lat: 48.6781173, lng: 17.4244994 },
    { lat: 48.6767298, lng: 17.4247185 },
    { lat: 48.6752522, lng: 17.4253138 },
    { lat: 48.6738709, lng: 17.426048 },
    { lat: 48.6711589, lng: 17.4269252 },
    { lat: 48.6707538, lng: 17.424911 },
    { lat: 48.6703874, lng: 17.4250098 },
    { lat: 48.6698472, lng: 17.4250422 },
    { lat: 48.6685112, lng: 17.4259205 },
    { lat: 48.66803, lng: 17.4266561 },
    { lat: 48.6674165, lng: 17.4261622 },
    { lat: 48.667078, lng: 17.4255176 },
  ],
  Prievaly: [
    { lat: 48.5695705, lng: 17.3683991 },
    { lat: 48.569565, lng: 17.3682091 },
    { lat: 48.5696534, lng: 17.3678498 },
    { lat: 48.5695694, lng: 17.367635 },
    { lat: 48.5695837, lng: 17.3674217 },
    { lat: 48.5697113, lng: 17.3671051 },
    { lat: 48.5695474, lng: 17.3668087 },
    { lat: 48.5697539, lng: 17.3661294 },
    { lat: 48.5699911, lng: 17.3656425 },
    { lat: 48.5699446, lng: 17.3653466 },
    { lat: 48.5697038, lng: 17.3646135 },
    { lat: 48.5704523, lng: 17.3641007 },
    { lat: 48.5706711, lng: 17.3638701 },
    { lat: 48.5708247, lng: 17.3635927 },
    { lat: 48.5711354, lng: 17.3626056 },
    { lat: 48.5711204, lng: 17.3624259 },
    { lat: 48.5712792, lng: 17.361672 },
    { lat: 48.5714595, lng: 17.3614249 },
    { lat: 48.5722208, lng: 17.3609526 },
    { lat: 48.5728461, lng: 17.3603223 },
    { lat: 48.5732054, lng: 17.3600456 },
    { lat: 48.573717, lng: 17.3597852 },
    { lat: 48.5742396, lng: 17.359708 },
    { lat: 48.5750887, lng: 17.3598778 },
    { lat: 48.5754098, lng: 17.3592907 },
    { lat: 48.5757831, lng: 17.3588328 },
    { lat: 48.5763022, lng: 17.3579927 },
    { lat: 48.5766368, lng: 17.3572302 },
    { lat: 48.5767274, lng: 17.3568523 },
    { lat: 48.5771842, lng: 17.3557132 },
    { lat: 48.5795535, lng: 17.3568175 },
    { lat: 48.5805022, lng: 17.3574042 },
    { lat: 48.5818655, lng: 17.3528579 },
    { lat: 48.581276, lng: 17.3526711 },
    { lat: 48.5813362, lng: 17.3523957 },
    { lat: 48.5833473, lng: 17.3470426 },
    { lat: 48.5841165, lng: 17.3454615 },
    { lat: 48.5838895, lng: 17.3451955 },
    { lat: 48.5837805, lng: 17.3450615 },
    { lat: 48.5836705, lng: 17.3449255 },
    { lat: 48.5836395, lng: 17.3448885 },
    { lat: 48.5835965, lng: 17.3448365 },
    { lat: 48.5835915, lng: 17.3448315 },
    { lat: 48.5835365, lng: 17.3447625 },
    { lat: 48.5835195, lng: 17.3447415 },
    { lat: 48.5835055, lng: 17.3447255 },
    { lat: 48.5834875, lng: 17.3447025 },
    { lat: 48.5834585, lng: 17.3446665 },
    { lat: 48.5834215, lng: 17.3446445 },
    { lat: 48.582851, lng: 17.3442 },
    { lat: 48.582487, lng: 17.343925 },
    { lat: 48.58242, lng: 17.343873 },
    { lat: 48.582001, lng: 17.343556 },
    { lat: 48.581873, lng: 17.343459 },
    { lat: 48.581752, lng: 17.343366 },
    { lat: 48.58167, lng: 17.343304 },
    { lat: 48.581621, lng: 17.343266 },
    { lat: 48.581428, lng: 17.343119 },
    { lat: 48.581238, lng: 17.342973 },
    { lat: 48.581051, lng: 17.34283 },
    { lat: 48.580935, lng: 17.342742 },
    { lat: 48.580888, lng: 17.342707 },
    { lat: 48.580838, lng: 17.342668 },
    { lat: 48.580816, lng: 17.342653 },
    { lat: 48.580801, lng: 17.34264 },
    { lat: 48.580745, lng: 17.342599 },
    { lat: 48.580699, lng: 17.342563 },
    { lat: 48.57983, lng: 17.341472 },
    { lat: 48.57965, lng: 17.34128 },
    { lat: 48.579438, lng: 17.341051 },
    { lat: 48.579317, lng: 17.340846 },
    { lat: 48.579278, lng: 17.340779 },
    { lat: 48.579216, lng: 17.340673 },
    { lat: 48.579234, lng: 17.340649 },
    { lat: 48.579945, lng: 17.339755 },
    { lat: 48.579678, lng: 17.33944 },
    { lat: 48.579542, lng: 17.33928 },
    { lat: 48.579538, lng: 17.339274 },
    { lat: 48.579537, lng: 17.339273 },
    { lat: 48.579534, lng: 17.339267 },
    { lat: 48.579523, lng: 17.339254 },
    { lat: 48.579501, lng: 17.339225 },
    { lat: 48.579414, lng: 17.339113 },
    { lat: 48.579274, lng: 17.338947 },
    { lat: 48.57911, lng: 17.338751 },
    { lat: 48.57894, lng: 17.338548 },
    { lat: 48.578624, lng: 17.338177 },
    { lat: 48.578025, lng: 17.337459 },
    { lat: 48.577659, lng: 17.337022 },
    { lat: 48.577477, lng: 17.33673 },
    { lat: 48.577345, lng: 17.336518 },
    { lat: 48.577096, lng: 17.336118 },
    { lat: 48.576848, lng: 17.335721 },
    { lat: 48.57667, lng: 17.335437 },
    { lat: 48.576491, lng: 17.335153 },
    { lat: 48.576311, lng: 17.334865 },
    { lat: 48.57613, lng: 17.334578 },
    { lat: 48.575946, lng: 17.334286 },
    { lat: 48.575932, lng: 17.334265 },
    { lat: 48.575917, lng: 17.334079 },
    { lat: 48.575898, lng: 17.33386 },
    { lat: 48.575887, lng: 17.333741 },
    { lat: 48.575853, lng: 17.333342 },
    { lat: 48.575817, lng: 17.33295 },
    { lat: 48.575743, lng: 17.332105 },
    { lat: 48.575739, lng: 17.332062 },
    { lat: 48.575731, lng: 17.331967 },
    { lat: 48.575725, lng: 17.331897 },
    { lat: 48.575718, lng: 17.331827 },
    { lat: 48.575707, lng: 17.3317 },
    { lat: 48.575703, lng: 17.331654 },
    { lat: 48.575686, lng: 17.331515 },
    { lat: 48.57566, lng: 17.331303 },
    { lat: 48.575641, lng: 17.331146 },
    { lat: 48.575633, lng: 17.331079 },
    { lat: 48.575612, lng: 17.330911 },
    { lat: 48.575594, lng: 17.330758 },
    { lat: 48.575581, lng: 17.330655 },
    { lat: 48.575568, lng: 17.330553 },
    { lat: 48.575563, lng: 17.330512 },
    { lat: 48.575558, lng: 17.330469 },
    { lat: 48.575547, lng: 17.330383 },
    { lat: 48.575518, lng: 17.330164 },
    { lat: 48.575485, lng: 17.329909 },
    { lat: 48.575465, lng: 17.329757 },
    { lat: 48.575458, lng: 17.329704 },
    { lat: 48.575379, lng: 17.329073 },
    { lat: 48.575403, lng: 17.32906 },
    { lat: 48.575454, lng: 17.329033 },
    { lat: 48.575777, lng: 17.328873 },
    { lat: 48.575991, lng: 17.328767 },
    { lat: 48.576107, lng: 17.328758 },
    { lat: 48.57627, lng: 17.328745 },
    { lat: 48.576609, lng: 17.328391 },
    { lat: 48.576679, lng: 17.32816 },
    { lat: 48.576684, lng: 17.328144 },
    { lat: 48.57676, lng: 17.328027 },
    { lat: 48.576831, lng: 17.327921 },
    { lat: 48.576877, lng: 17.327849 },
    { lat: 48.577032, lng: 17.327616 },
    { lat: 48.577027, lng: 17.327451 },
    { lat: 48.577025, lng: 17.327362 },
    { lat: 48.577219, lng: 17.327191 },
    { lat: 48.577253, lng: 17.327136 },
    { lat: 48.577177, lng: 17.326993 },
    { lat: 48.577097, lng: 17.326846 },
    { lat: 48.577168, lng: 17.326666 },
    { lat: 48.577363, lng: 17.326768 },
    { lat: 48.577389, lng: 17.326727 },
    { lat: 48.577527, lng: 17.326511 },
    { lat: 48.577441, lng: 17.326348 },
    { lat: 48.577482, lng: 17.326066 },
    { lat: 48.577513, lng: 17.325981 },
    { lat: 48.577638, lng: 17.325635 },
    { lat: 48.577818, lng: 17.325248 },
    { lat: 48.577886, lng: 17.325069 },
    { lat: 48.578081, lng: 17.324775 },
    { lat: 48.577976, lng: 17.324677 },
    { lat: 48.577808, lng: 17.324744 },
    { lat: 48.577662, lng: 17.32497 },
    { lat: 48.577101, lng: 17.325515 },
    { lat: 48.576833, lng: 17.325776 },
    { lat: 48.576732, lng: 17.325717 },
    { lat: 48.57659, lng: 17.325689 },
    { lat: 48.57646, lng: 17.325659 },
    { lat: 48.57634, lng: 17.325632 },
    { lat: 48.576092, lng: 17.325578 },
    { lat: 48.575828, lng: 17.325519 },
    { lat: 48.575758, lng: 17.325474 },
    { lat: 48.57529, lng: 17.325179 },
    { lat: 48.575235, lng: 17.325144 },
    { lat: 48.575131, lng: 17.325133 },
    { lat: 48.575081, lng: 17.325127 },
    { lat: 48.575041, lng: 17.325103 },
    { lat: 48.57478, lng: 17.324949 },
    { lat: 48.574764, lng: 17.324939 },
    { lat: 48.574696, lng: 17.324898 },
    { lat: 48.574517, lng: 17.32479 },
    { lat: 48.574285, lng: 17.324906 },
    { lat: 48.57411, lng: 17.325254 },
    { lat: 48.573487, lng: 17.325383 },
    { lat: 48.573425, lng: 17.325395 },
    { lat: 48.573272, lng: 17.325424 },
    { lat: 48.573236, lng: 17.325428 },
    { lat: 48.573399, lng: 17.325154 },
    { lat: 48.573453, lng: 17.324899 },
    { lat: 48.573282, lng: 17.324339 },
    { lat: 48.573109, lng: 17.324389 },
    { lat: 48.57291, lng: 17.324236 },
    { lat: 48.572145, lng: 17.324879 },
    { lat: 48.571887, lng: 17.325212 },
    { lat: 48.57124, lng: 17.325633 },
    { lat: 48.570622, lng: 17.325838 },
    { lat: 48.570256, lng: 17.325874 },
    { lat: 48.570199, lng: 17.326184 },
    { lat: 48.570158, lng: 17.326365 },
    { lat: 48.570125, lng: 17.326505 },
    { lat: 48.570108, lng: 17.326557 },
    { lat: 48.57001, lng: 17.326441 },
    { lat: 48.569999, lng: 17.32639 },
    { lat: 48.569996, lng: 17.326372 },
    { lat: 48.569697, lng: 17.3261 },
    { lat: 48.569584, lng: 17.325999 },
    { lat: 48.569532, lng: 17.325896 },
    { lat: 48.569432, lng: 17.325693 },
    { lat: 48.569337, lng: 17.32547 },
    { lat: 48.569334, lng: 17.325465 },
    { lat: 48.569278, lng: 17.325351 },
    { lat: 48.569272, lng: 17.325342 },
    { lat: 48.569199, lng: 17.325557 },
    { lat: 48.569167, lng: 17.325651 },
    { lat: 48.56915, lng: 17.325703 },
    { lat: 48.569033, lng: 17.325915 },
    { lat: 48.568982, lng: 17.326007 },
    { lat: 48.568955, lng: 17.32608 },
    { lat: 48.5689, lng: 17.326221 },
    { lat: 48.568646, lng: 17.326862 },
    { lat: 48.568276, lng: 17.327775 },
    { lat: 48.567978, lng: 17.328501 },
    { lat: 48.567754, lng: 17.329045 },
    { lat: 48.567538, lng: 17.329561 },
    { lat: 48.567232, lng: 17.330261 },
    { lat: 48.567147, lng: 17.330474 },
    { lat: 48.567092, lng: 17.33066 },
    { lat: 48.567074, lng: 17.330768 },
    { lat: 48.567043, lng: 17.330855 },
    { lat: 48.567047, lng: 17.330927 },
    { lat: 48.567046, lng: 17.330978 },
    { lat: 48.567016, lng: 17.331002 },
    { lat: 48.566968, lng: 17.331042 },
    { lat: 48.566956, lng: 17.331153 },
    { lat: 48.566946, lng: 17.331244 },
    { lat: 48.566926, lng: 17.331371 },
    { lat: 48.566866, lng: 17.331374 },
    { lat: 48.566847, lng: 17.331374 },
    { lat: 48.566847, lng: 17.331422 },
    { lat: 48.566845, lng: 17.331524 },
    { lat: 48.566792, lng: 17.331593 },
    { lat: 48.566782, lng: 17.331578 },
    { lat: 48.566771, lng: 17.331561 },
    { lat: 48.566701, lng: 17.331644 },
    { lat: 48.566663, lng: 17.331695 },
    { lat: 48.566673, lng: 17.331847 },
    { lat: 48.566674, lng: 17.331851 },
    { lat: 48.566709, lng: 17.331917 },
    { lat: 48.5667, lng: 17.331944 },
    { lat: 48.566663, lng: 17.331965 },
    { lat: 48.566581, lng: 17.332249 },
    { lat: 48.566499, lng: 17.332345 },
    { lat: 48.566485, lng: 17.332363 },
    { lat: 48.566491, lng: 17.332483 },
    { lat: 48.566442, lng: 17.332595 },
    { lat: 48.566378, lng: 17.332645 },
    { lat: 48.566264, lng: 17.33292 },
    { lat: 48.565953, lng: 17.333697 },
    { lat: 48.565765, lng: 17.333838 },
    { lat: 48.565687, lng: 17.333875 },
    { lat: 48.565441, lng: 17.333951 },
    { lat: 48.565357, lng: 17.333964 },
    { lat: 48.565209, lng: 17.333963 },
    { lat: 48.565002, lng: 17.33395 },
    { lat: 48.564789, lng: 17.333888 },
    { lat: 48.564424, lng: 17.333645 },
    { lat: 48.564157, lng: 17.333434 },
    { lat: 48.563858, lng: 17.333198 },
    { lat: 48.563682, lng: 17.333014 },
    { lat: 48.563837, lng: 17.33256 },
    { lat: 48.564033, lng: 17.332164 },
    { lat: 48.564105, lng: 17.332014 },
    { lat: 48.56428, lng: 17.331746 },
    { lat: 48.564409, lng: 17.331584 },
    { lat: 48.564431, lng: 17.331559 },
    { lat: 48.56456, lng: 17.331427 },
    { lat: 48.564651, lng: 17.331383 },
    { lat: 48.564702, lng: 17.331358 },
    { lat: 48.564797, lng: 17.331356 },
    { lat: 48.565055, lng: 17.331352 },
    { lat: 48.565294, lng: 17.331383 },
    { lat: 48.565535, lng: 17.331481 },
    { lat: 48.565581, lng: 17.331486 },
    { lat: 48.565652, lng: 17.331475 },
    { lat: 48.565676, lng: 17.331468 },
    { lat: 48.565725, lng: 17.331454 },
    { lat: 48.565824, lng: 17.33135 },
    { lat: 48.565913, lng: 17.331256 },
    { lat: 48.565949, lng: 17.331219 },
    { lat: 48.566005, lng: 17.331109 },
    { lat: 48.566072, lng: 17.330977 },
    { lat: 48.566201, lng: 17.330735 },
    { lat: 48.566268, lng: 17.330607 },
    { lat: 48.566331, lng: 17.330492 },
    { lat: 48.566338, lng: 17.330464 },
    { lat: 48.566353, lng: 17.330414 },
    { lat: 48.566382, lng: 17.330313 },
    { lat: 48.56639, lng: 17.330286 },
    { lat: 48.566437, lng: 17.330213 },
    { lat: 48.566492, lng: 17.330128 },
    { lat: 48.566569, lng: 17.330008 },
    { lat: 48.566697, lng: 17.329826 },
    { lat: 48.566807, lng: 17.329768 },
    { lat: 48.566822, lng: 17.329761 },
    { lat: 48.566836, lng: 17.329717 },
    { lat: 48.56727, lng: 17.328481 },
    { lat: 48.567728, lng: 17.327208 },
    { lat: 48.567884, lng: 17.326775 },
    { lat: 48.568257, lng: 17.325733 },
    { lat: 48.568364, lng: 17.325367 },
    { lat: 48.568381, lng: 17.32532 },
    { lat: 48.568632, lng: 17.324691 },
    { lat: 48.568636, lng: 17.324679 },
    { lat: 48.568666, lng: 17.324603 },
    { lat: 48.568595, lng: 17.324368 },
    { lat: 48.568603, lng: 17.324295 },
    { lat: 48.568626, lng: 17.324095 },
    { lat: 48.568633, lng: 17.32405 },
    { lat: 48.568555, lng: 17.323924 },
    { lat: 48.568504, lng: 17.323661 },
    { lat: 48.568511, lng: 17.323475 },
    { lat: 48.568582, lng: 17.323136 },
    { lat: 48.568605, lng: 17.322899 },
    { lat: 48.568571, lng: 17.322706 },
    { lat: 48.568381, lng: 17.322456 },
    { lat: 48.568336, lng: 17.322183 },
    { lat: 48.568354, lng: 17.322036 },
    { lat: 48.568318, lng: 17.321739 },
    { lat: 48.56835, lng: 17.321552 },
    { lat: 48.568276, lng: 17.321527 },
    { lat: 48.568187, lng: 17.321338 },
    { lat: 48.568132, lng: 17.32131 },
    { lat: 48.56808, lng: 17.32119 },
    { lat: 48.568011, lng: 17.320712 },
    { lat: 48.567967, lng: 17.320475 },
    { lat: 48.567986, lng: 17.320235 },
    { lat: 48.567971, lng: 17.320117 },
    { lat: 48.56789, lng: 17.320126 },
    { lat: 48.567855, lng: 17.319983 },
    { lat: 48.567815, lng: 17.319897 },
    { lat: 48.567696, lng: 17.319883 },
    { lat: 48.567631, lng: 17.319951 },
    { lat: 48.567588, lng: 17.319969 },
    { lat: 48.567476, lng: 17.319937 },
    { lat: 48.567387, lng: 17.319926 },
    { lat: 48.567211, lng: 17.31998 },
    { lat: 48.567131, lng: 17.320042 },
    { lat: 48.567072, lng: 17.320046 },
    { lat: 48.567036, lng: 17.320017 },
    { lat: 48.566879, lng: 17.319999 },
    { lat: 48.566672, lng: 17.319907 },
    { lat: 48.56658, lng: 17.319728 },
    { lat: 48.56651, lng: 17.31972 },
    { lat: 48.566368, lng: 17.319793 },
    { lat: 48.566288, lng: 17.319758 },
    { lat: 48.566133, lng: 17.319618 },
    { lat: 48.565929, lng: 17.319646 },
    { lat: 48.56585, lng: 17.319727 },
    { lat: 48.565766, lng: 17.319876 },
    { lat: 48.565739, lng: 17.319882 },
    { lat: 48.565643, lng: 17.319855 },
    { lat: 48.565429, lng: 17.319797 },
    { lat: 48.565286, lng: 17.319757 },
    { lat: 48.565119, lng: 17.319819 },
    { lat: 48.564895, lng: 17.319735 },
    { lat: 48.56478, lng: 17.319844 },
    { lat: 48.564677, lng: 17.319857 },
    { lat: 48.564568, lng: 17.319847 },
    { lat: 48.56449, lng: 17.319906 },
    { lat: 48.564277, lng: 17.319898 },
    { lat: 48.564137, lng: 17.319835 },
    { lat: 48.564027, lng: 17.319688 },
    { lat: 48.563951, lng: 17.319688 },
    { lat: 48.563886, lng: 17.319687 },
    { lat: 48.563863, lng: 17.319489 },
    { lat: 48.563848, lng: 17.31937 },
    { lat: 48.56381, lng: 17.319085 },
    { lat: 48.563806, lng: 17.31905 },
    { lat: 48.563519, lng: 17.318979 },
    { lat: 48.563311, lng: 17.318764 },
    { lat: 48.563049, lng: 17.318507 },
    { lat: 48.563009, lng: 17.318449 },
    { lat: 48.562922, lng: 17.318492 },
    { lat: 48.562924, lng: 17.318511 },
    { lat: 48.562974, lng: 17.319154 },
    { lat: 48.562461, lng: 17.318938 },
    { lat: 48.562313, lng: 17.318788 },
    { lat: 48.562164, lng: 17.318637 },
    { lat: 48.562103, lng: 17.318573 },
    { lat: 48.562089, lng: 17.31856 },
    { lat: 48.562063, lng: 17.318535 },
    { lat: 48.562052, lng: 17.318524 },
    { lat: 48.561929, lng: 17.318402 },
    { lat: 48.561835, lng: 17.318358 },
    { lat: 48.561612, lng: 17.31777 },
    { lat: 48.561507, lng: 17.317519 },
    { lat: 48.561455, lng: 17.317415 },
    { lat: 48.561358, lng: 17.317257 },
    { lat: 48.561269, lng: 17.317173 },
    { lat: 48.561194, lng: 17.317075 },
    { lat: 48.561071, lng: 17.316801 },
    { lat: 48.561062, lng: 17.316415 },
    { lat: 48.561015, lng: 17.31622 },
    { lat: 48.560952, lng: 17.316135 },
    { lat: 48.560861, lng: 17.315997 },
    { lat: 48.560728, lng: 17.31587 },
    { lat: 48.560703, lng: 17.315785 },
    { lat: 48.56056, lng: 17.315345 },
    { lat: 48.56052, lng: 17.315266 },
    { lat: 48.560364, lng: 17.315004 },
    { lat: 48.560294, lng: 17.314855 },
    { lat: 48.560194, lng: 17.314465 },
    { lat: 48.56011, lng: 17.31418 },
    { lat: 48.56006, lng: 17.313979 },
    { lat: 48.560017, lng: 17.313742 },
    { lat: 48.559988, lng: 17.313611 },
    { lat: 48.56, lng: 17.313347 },
    { lat: 48.559942, lng: 17.313262 },
    { lat: 48.559892, lng: 17.313133 },
    { lat: 48.559874, lng: 17.312966 },
    { lat: 48.5598, lng: 17.312698 },
    { lat: 48.559717, lng: 17.312554 },
    { lat: 48.559675, lng: 17.312412 },
    { lat: 48.559618, lng: 17.31204 },
    { lat: 48.559621, lng: 17.311679 },
    { lat: 48.559593, lng: 17.311507 },
    { lat: 48.559584, lng: 17.311453 },
    { lat: 48.559563, lng: 17.311331 },
    { lat: 48.55964, lng: 17.3113 },
    { lat: 48.559847, lng: 17.311231 },
    { lat: 48.559999, lng: 17.311177 },
    { lat: 48.560035, lng: 17.311165 },
    { lat: 48.560085, lng: 17.311017 },
    { lat: 48.55996, lng: 17.310407 },
    { lat: 48.55975, lng: 17.309853 },
    { lat: 48.559636, lng: 17.310065 },
    { lat: 48.559551, lng: 17.310224 },
    { lat: 48.559194, lng: 17.310267 },
    { lat: 48.559076, lng: 17.309787 },
    { lat: 48.559069, lng: 17.30976 },
    { lat: 48.559064, lng: 17.309738 },
    { lat: 48.559053, lng: 17.309694 },
    { lat: 48.55904, lng: 17.309645 },
    { lat: 48.559017, lng: 17.309452 },
    { lat: 48.558961, lng: 17.309263 },
    { lat: 48.55891, lng: 17.309085 },
    { lat: 48.558876, lng: 17.308899 },
    { lat: 48.558834, lng: 17.30866 },
    { lat: 48.558814, lng: 17.308498 },
    { lat: 48.558801, lng: 17.308485 },
    { lat: 48.55856, lng: 17.308267 },
    { lat: 48.558554, lng: 17.308242 },
    { lat: 48.558471, lng: 17.307853 },
    { lat: 48.558392, lng: 17.307466 },
    { lat: 48.558244, lng: 17.306741 },
    { lat: 48.558207, lng: 17.306567 },
    { lat: 48.558179, lng: 17.306428 },
    { lat: 48.558161, lng: 17.306338 },
    { lat: 48.558065, lng: 17.305875 },
    { lat: 48.558038, lng: 17.305737 },
    { lat: 48.558027, lng: 17.305678 },
    { lat: 48.557998, lng: 17.305525 },
    { lat: 48.55799, lng: 17.305489 },
    { lat: 48.557984, lng: 17.305455 },
    { lat: 48.557977, lng: 17.305419 },
    { lat: 48.557945, lng: 17.305257 },
    { lat: 48.557931, lng: 17.305177 },
    { lat: 48.557912, lng: 17.305085 },
    { lat: 48.557899, lng: 17.305015 },
    { lat: 48.557889, lng: 17.304965 },
    { lat: 48.557877, lng: 17.304906 },
    { lat: 48.557843, lng: 17.304733 },
    { lat: 48.557811, lng: 17.304573 },
    { lat: 48.557777, lng: 17.304399 },
    { lat: 48.557733, lng: 17.304169 },
    { lat: 48.557821, lng: 17.304003 },
    { lat: 48.557809, lng: 17.303997 },
    { lat: 48.557714, lng: 17.3041763 },
    { lat: 48.556325, lng: 17.306888 },
    { lat: 48.5562722, lng: 17.307082 },
    { lat: 48.5551127, lng: 17.3083336 },
    { lat: 48.5549258, lng: 17.3088231 },
    { lat: 48.5544432, lng: 17.3097706 },
    { lat: 48.5542423, lng: 17.31079 },
    { lat: 48.553643, lng: 17.3117263 },
    { lat: 48.5534497, lng: 17.3121209 },
    { lat: 48.5527562, lng: 17.313918 },
    { lat: 48.5524402, lng: 17.3144898 },
    { lat: 48.5521839, lng: 17.3151301 },
    { lat: 48.5520683, lng: 17.3152621 },
    { lat: 48.552087, lng: 17.3155666 },
    { lat: 48.5520156, lng: 17.3156828 },
    { lat: 48.5519306, lng: 17.3161454 },
    { lat: 48.5519709, lng: 17.3163444 },
    { lat: 48.5518791, lng: 17.3166668 },
    { lat: 48.5517742, lng: 17.3167566 },
    { lat: 48.5515978, lng: 17.3170641 },
    { lat: 48.5515042, lng: 17.3175861 },
    { lat: 48.5515986, lng: 17.3178027 },
    { lat: 48.5516146, lng: 17.3179933 },
    { lat: 48.5516987, lng: 17.3181384 },
    { lat: 48.5516862, lng: 17.318551 },
    { lat: 48.5517593, lng: 17.3187383 },
    { lat: 48.5516937, lng: 17.3193054 },
    { lat: 48.5516869, lng: 17.3198059 },
    { lat: 48.5517615, lng: 17.3201357 },
    { lat: 48.5517474, lng: 17.3202996 },
    { lat: 48.5516691, lng: 17.3203808 },
    { lat: 48.5515186, lng: 17.3207565 },
    { lat: 48.5514273, lng: 17.3208456 },
    { lat: 48.5513439, lng: 17.3212274 },
    { lat: 48.5514402, lng: 17.3216466 },
    { lat: 48.5514235, lng: 17.321821 },
    { lat: 48.5512055, lng: 17.322032 },
    { lat: 48.5511115, lng: 17.3222146 },
    { lat: 48.5508587, lng: 17.3232893 },
    { lat: 48.550936, lng: 17.3236254 },
    { lat: 48.5508277, lng: 17.323782 },
    { lat: 48.5508653, lng: 17.3239771 },
    { lat: 48.5507596, lng: 17.3240541 },
    { lat: 48.5506973, lng: 17.3242064 },
    { lat: 48.5505569, lng: 17.3248066 },
    { lat: 48.5504918, lng: 17.3248451 },
    { lat: 48.5501919, lng: 17.3254255 },
    { lat: 48.5498133, lng: 17.3257523 },
    { lat: 48.5494637, lng: 17.3265885 },
    { lat: 48.5493577, lng: 17.3269872 },
    { lat: 48.5491139, lng: 17.3272843 },
    { lat: 48.5490556, lng: 17.3274324 },
    { lat: 48.5490589, lng: 17.3275698 },
    { lat: 48.5487216, lng: 17.3281771 },
    { lat: 48.5483728, lng: 17.3283886 },
    { lat: 48.5483163, lng: 17.3285282 },
    { lat: 48.5479623, lng: 17.3285543 },
    { lat: 48.5479141, lng: 17.3286142 },
    { lat: 48.5477993, lng: 17.3288455 },
    { lat: 48.5478117, lng: 17.3289816 },
    { lat: 48.5476948, lng: 17.3290763 },
    { lat: 48.5476367, lng: 17.3292261 },
    { lat: 48.5475756, lng: 17.3297942 },
    { lat: 48.5475104, lng: 17.3298691 },
    { lat: 48.5474619, lng: 17.3300756 },
    { lat: 48.5472295, lng: 17.3299101 },
    { lat: 48.5469351, lng: 17.3302771 },
    { lat: 48.5465943, lng: 17.3305921 },
    { lat: 48.5464136, lng: 17.3305943 },
    { lat: 48.5462549, lng: 17.3307922 },
    { lat: 48.5460715, lng: 17.3308404 },
    { lat: 48.5459844, lng: 17.3310797 },
    { lat: 48.5458406, lng: 17.3310048 },
    { lat: 48.5455628, lng: 17.3314919 },
    { lat: 48.5454744, lng: 17.3315051 },
    { lat: 48.5454511, lng: 17.3316989 },
    { lat: 48.5453036, lng: 17.3317411 },
    { lat: 48.5452267, lng: 17.3318794 },
    { lat: 48.5452186, lng: 17.3320481 },
    { lat: 48.5452518, lng: 17.3321947 },
    { lat: 48.545361, lng: 17.3323443 },
    { lat: 48.5454143, lng: 17.3326377 },
    { lat: 48.5455332, lng: 17.3327298 },
    { lat: 48.5455362, lng: 17.3329493 },
    { lat: 48.5454288, lng: 17.3332579 },
    { lat: 48.5453324, lng: 17.3332544 },
    { lat: 48.5451595, lng: 17.3336151 },
    { lat: 48.5450173, lng: 17.3336704 },
    { lat: 48.5446263, lng: 17.3344089 },
    { lat: 48.5446106, lng: 17.334531 },
    { lat: 48.5447747, lng: 17.3347288 },
    { lat: 48.5447257, lng: 17.3348765 },
    { lat: 48.5447612, lng: 17.3350064 },
    { lat: 48.5446851, lng: 17.3351717 },
    { lat: 48.5447531, lng: 17.3354193 },
    { lat: 48.5448632, lng: 17.3355288 },
    { lat: 48.5448755, lng: 17.3356664 },
    { lat: 48.5446522, lng: 17.3358781 },
    { lat: 48.5447163, lng: 17.3362914 },
    { lat: 48.5445917, lng: 17.3363872 },
    { lat: 48.5441639, lng: 17.3372053 },
    { lat: 48.5437768, lng: 17.3377118 },
    { lat: 48.5435231, lng: 17.3377187 },
    { lat: 48.5433857, lng: 17.3377933 },
    { lat: 48.5432246, lng: 17.3377483 },
    { lat: 48.5429439, lng: 17.3379157 },
    { lat: 48.5427057, lng: 17.3377938 },
    { lat: 48.5421862, lng: 17.3388507 },
    { lat: 48.5421358, lng: 17.3391701 },
    { lat: 48.5422288, lng: 17.3397459 },
    { lat: 48.5421312, lng: 17.3403974 },
    { lat: 48.5421485, lng: 17.340731 },
    { lat: 48.5420635, lng: 17.3411585 },
    { lat: 48.5413775, lng: 17.3428844 },
    { lat: 48.541248, lng: 17.3431667 },
    { lat: 48.540837, lng: 17.3436724 },
    { lat: 48.5406865, lng: 17.3441698 },
    { lat: 48.5405283, lng: 17.3459352 },
    { lat: 48.5402588, lng: 17.3480197 },
    { lat: 48.5401668, lng: 17.3490845 },
    { lat: 48.5400038, lng: 17.3497032 },
    { lat: 48.5398645, lng: 17.3500104 },
    { lat: 48.5390175, lng: 17.3510695 },
    { lat: 48.5381944, lng: 17.3522544 },
    { lat: 48.5368857, lng: 17.3534248 },
    { lat: 48.5367397, lng: 17.3536931 },
    { lat: 48.5362395, lng: 17.354132 },
    { lat: 48.5360665, lng: 17.3543728 },
    { lat: 48.5360198, lng: 17.354332 },
    { lat: 48.5359029, lng: 17.3548946 },
    { lat: 48.5361681, lng: 17.355601 },
    { lat: 48.5362486, lng: 17.3561793 },
    { lat: 48.536376, lng: 17.356414 },
    { lat: 48.5363689, lng: 17.3567231 },
    { lat: 48.536331, lng: 17.356819 },
    { lat: 48.536358, lng: 17.356846 },
    { lat: 48.53636, lng: 17.356972 },
    { lat: 48.536427, lng: 17.357148 },
    { lat: 48.53636, lng: 17.357376 },
    { lat: 48.536408, lng: 17.357582 },
    { lat: 48.53652, lng: 17.357668 },
    { lat: 48.53671, lng: 17.357707 },
    { lat: 48.536978, lng: 17.357725 },
    { lat: 48.537186, lng: 17.357661 },
    { lat: 48.537395, lng: 17.35783 },
    { lat: 48.53757, lng: 17.357909 },
    { lat: 48.537684, lng: 17.357922 },
    { lat: 48.537892, lng: 17.358067 },
    { lat: 48.538024, lng: 17.358116 },
    { lat: 48.538231, lng: 17.358189 },
    { lat: 48.538719, lng: 17.358179 },
    { lat: 48.538757, lng: 17.358219 },
    { lat: 48.539158, lng: 17.358542 },
    { lat: 48.539276, lng: 17.358597 },
    { lat: 48.539799, lng: 17.358834 },
    { lat: 48.540258, lng: 17.359043 },
    { lat: 48.540354, lng: 17.359091 },
    { lat: 48.540559, lng: 17.359278 },
    { lat: 48.540734, lng: 17.359478 },
    { lat: 48.540892, lng: 17.359785 },
    { lat: 48.540974, lng: 17.359883 },
    { lat: 48.541064, lng: 17.360026 },
    { lat: 48.541416, lng: 17.360363 },
    { lat: 48.54151, lng: 17.360381 },
    { lat: 48.541697, lng: 17.360575 },
    { lat: 48.54191, lng: 17.360827 },
    { lat: 48.542052, lng: 17.360992 },
    { lat: 48.542299, lng: 17.361205 },
    { lat: 48.542582, lng: 17.361383 },
    { lat: 48.542749, lng: 17.361546 },
    { lat: 48.543333, lng: 17.36202 },
    { lat: 48.543859, lng: 17.362451 },
    { lat: 48.544165, lng: 17.362539 },
    { lat: 48.544427, lng: 17.362795 },
    { lat: 48.544733, lng: 17.363549 },
    { lat: 48.544946, lng: 17.364082 },
    { lat: 48.545009, lng: 17.364244 },
    { lat: 48.545188, lng: 17.364831 },
    { lat: 48.545525, lng: 17.365228 },
    { lat: 48.545722, lng: 17.365372 },
    { lat: 48.545827, lng: 17.365557 },
    { lat: 48.545918, lng: 17.365848 },
    { lat: 48.545984, lng: 17.366204 },
    { lat: 48.546204, lng: 17.366483 },
    { lat: 48.54632, lng: 17.366645 },
    { lat: 48.546453, lng: 17.366819 },
    { lat: 48.54667, lng: 17.367105 },
    { lat: 48.546917, lng: 17.367005 },
    { lat: 48.547228, lng: 17.367186 },
    { lat: 48.547439, lng: 17.367353 },
    { lat: 48.54757, lng: 17.367524 },
    { lat: 48.548028, lng: 17.368123 },
    { lat: 48.548077, lng: 17.368202 },
    { lat: 48.548212, lng: 17.368186 },
    { lat: 48.548322, lng: 17.368811 },
    { lat: 48.548327, lng: 17.369091 },
    { lat: 48.548396, lng: 17.369376 },
    { lat: 48.548499, lng: 17.369677 },
    { lat: 48.548657, lng: 17.369768 },
    { lat: 48.548819, lng: 17.369969 },
    { lat: 48.549286, lng: 17.370215 },
    { lat: 48.549431, lng: 17.370029 },
    { lat: 48.549498, lng: 17.369943 },
    { lat: 48.549703, lng: 17.369684 },
    { lat: 48.549911, lng: 17.370311 },
    { lat: 48.55015, lng: 17.370793 },
    { lat: 48.55031, lng: 17.370717 },
    { lat: 48.550464, lng: 17.370782 },
    { lat: 48.550543, lng: 17.37089 },
    { lat: 48.550649, lng: 17.370938 },
    { lat: 48.550709, lng: 17.370831 },
    { lat: 48.550997, lng: 17.371022 },
    { lat: 48.55108, lng: 17.371031 },
    { lat: 48.551194, lng: 17.371219 },
    { lat: 48.551329, lng: 17.371237 },
    { lat: 48.551488, lng: 17.371791 },
    { lat: 48.551511, lng: 17.371903 },
    { lat: 48.551585, lng: 17.372046 },
    { lat: 48.551644, lng: 17.372188 },
    { lat: 48.551756, lng: 17.372362 },
    { lat: 48.551816, lng: 17.372618 },
    { lat: 48.551915, lng: 17.37282 },
    { lat: 48.551953, lng: 17.373123 },
    { lat: 48.552108, lng: 17.37335 },
    { lat: 48.552293, lng: 17.373697 },
    { lat: 48.552348, lng: 17.373726 },
    { lat: 48.55242, lng: 17.374135 },
    { lat: 48.552534, lng: 17.3743 },
    { lat: 48.552499, lng: 17.374537 },
    { lat: 48.552514, lng: 17.374733 },
    { lat: 48.552643, lng: 17.37484 },
    { lat: 48.55268, lng: 17.374889 },
    { lat: 48.552666, lng: 17.375006 },
    { lat: 48.552909, lng: 17.37544 },
    { lat: 48.553237, lng: 17.37574 },
    { lat: 48.553295, lng: 17.375718 },
    { lat: 48.553343, lng: 17.375997 },
    { lat: 48.5534, lng: 17.376119 },
    { lat: 48.553657, lng: 17.376422 },
    { lat: 48.553781, lng: 17.376524 },
    { lat: 48.554036, lng: 17.377094 },
    { lat: 48.55418, lng: 17.377309 },
    { lat: 48.554241, lng: 17.377559 },
    { lat: 48.554272, lng: 17.377587 },
    { lat: 48.554309, lng: 17.377633 },
    { lat: 48.554384, lng: 17.377957 },
    { lat: 48.554387, lng: 17.378139 },
    { lat: 48.554554, lng: 17.378743 },
    { lat: 48.554478, lng: 17.378871 },
    { lat: 48.554507, lng: 17.378963 },
    { lat: 48.554521, lng: 17.379244 },
    { lat: 48.554558, lng: 17.379305 },
    { lat: 48.554628, lng: 17.379369 },
    { lat: 48.554702, lng: 17.379568 },
    { lat: 48.554698, lng: 17.379632 },
    { lat: 48.554686, lng: 17.379708 },
    { lat: 48.554712, lng: 17.379856 },
    { lat: 48.554705, lng: 17.379933 },
    { lat: 48.554688, lng: 17.38006 },
    { lat: 48.554651, lng: 17.380211 },
    { lat: 48.554627, lng: 17.380367 },
    { lat: 48.554634, lng: 17.380443 },
    { lat: 48.554673, lng: 17.380485 },
    { lat: 48.554691, lng: 17.380557 },
    { lat: 48.55473, lng: 17.380617 },
    { lat: 48.554653, lng: 17.380787 },
    { lat: 48.55467, lng: 17.380875 },
    { lat: 48.554791, lng: 17.380966 },
    { lat: 48.554847, lng: 17.381127 },
    { lat: 48.554961, lng: 17.381254 },
    { lat: 48.555085, lng: 17.381355 },
    { lat: 48.555169, lng: 17.381475 },
    { lat: 48.555255, lng: 17.381776 },
    { lat: 48.555321, lng: 17.382052 },
    { lat: 48.555378, lng: 17.382318 },
    { lat: 48.55545, lng: 17.382381 },
    { lat: 48.555466, lng: 17.382546 },
    { lat: 48.55565, lng: 17.382791 },
    { lat: 48.555706, lng: 17.382802 },
    { lat: 48.555831, lng: 17.38281 },
    { lat: 48.555963, lng: 17.382835 },
    { lat: 48.55621, lng: 17.382877 },
    { lat: 48.556511, lng: 17.382696 },
    { lat: 48.556716, lng: 17.382705 },
    { lat: 48.556805, lng: 17.382612 },
    { lat: 48.556812, lng: 17.382443 },
    { lat: 48.556935, lng: 17.382235 },
    { lat: 48.557039, lng: 17.382101 },
    { lat: 48.557127, lng: 17.382041 },
    { lat: 48.557153, lng: 17.38201 },
    { lat: 48.557357, lng: 17.381691 },
    { lat: 48.557433, lng: 17.381591 },
    { lat: 48.557566, lng: 17.381414 },
    { lat: 48.557733, lng: 17.381188 },
    { lat: 48.557826, lng: 17.381101 },
    { lat: 48.557899, lng: 17.380969 },
    { lat: 48.557977, lng: 17.380814 },
    { lat: 48.55831, lng: 17.380963 },
    { lat: 48.558501, lng: 17.381001 },
    { lat: 48.558692, lng: 17.381123 },
    { lat: 48.558772, lng: 17.381219 },
    { lat: 48.55888, lng: 17.381357 },
    { lat: 48.5591261, lng: 17.3811336 },
    { lat: 48.5594791, lng: 17.3810032 },
    { lat: 48.5598951, lng: 17.3804876 },
    { lat: 48.5603053, lng: 17.3801601 },
    { lat: 48.5607061, lng: 17.3799317 },
    { lat: 48.5608678, lng: 17.3799681 },
    { lat: 48.561069, lng: 17.3797609 },
    { lat: 48.561374, lng: 17.3798315 },
    { lat: 48.560917, lng: 17.3784465 },
    { lat: 48.5615627, lng: 17.377361 },
    { lat: 48.5621687, lng: 17.3765528 },
    { lat: 48.5623646, lng: 17.3765254 },
    { lat: 48.562648, lng: 17.3762981 },
    { lat: 48.5627595, lng: 17.3754751 },
    { lat: 48.5628835, lng: 17.3751234 },
    { lat: 48.5631204, lng: 17.3746869 },
    { lat: 48.5634315, lng: 17.3742912 },
    { lat: 48.5634178, lng: 17.3740279 },
    { lat: 48.5635061, lng: 17.3737706 },
    { lat: 48.5641351, lng: 17.372883 },
    { lat: 48.5645625, lng: 17.3725436 },
    { lat: 48.5648563, lng: 17.3721621 },
    { lat: 48.5648866, lng: 17.3720011 },
    { lat: 48.5650876, lng: 17.3717572 },
    { lat: 48.5651909, lng: 17.3717301 },
    { lat: 48.5655645, lng: 17.3712221 },
    { lat: 48.5662178, lng: 17.370742 },
    { lat: 48.5667043, lng: 17.3706338 },
    { lat: 48.5676116, lng: 17.3702699 },
    { lat: 48.5677181, lng: 17.3700509 },
    { lat: 48.5678839, lng: 17.3700468 },
    { lat: 48.5682764, lng: 17.3696904 },
    { lat: 48.5684712, lng: 17.3696285 },
    { lat: 48.5689262, lng: 17.3692796 },
    { lat: 48.569118, lng: 17.3689781 },
    { lat: 48.5695556, lng: 17.368483 },
    { lat: 48.5695705, lng: 17.3683991 },
  ],
  Rohov: [
    { lat: 48.7225177, lng: 17.3489125 },
    { lat: 48.7225324, lng: 17.3489311 },
    { lat: 48.722628, lng: 17.3488725 },
    { lat: 48.7227079, lng: 17.3489902 },
    { lat: 48.7229588, lng: 17.34953 },
    { lat: 48.7234332, lng: 17.3502076 },
    { lat: 48.7235153, lng: 17.350394 },
    { lat: 48.7235542, lng: 17.3506104 },
    { lat: 48.724075, lng: 17.3519508 },
    { lat: 48.7243807, lng: 17.3531261 },
    { lat: 48.7245624, lng: 17.3530702 },
    { lat: 48.724879, lng: 17.3532837 },
    { lat: 48.725473, lng: 17.3533884 },
    { lat: 48.7260885, lng: 17.3533167 },
    { lat: 48.7264689, lng: 17.3531895 },
    { lat: 48.7268726, lng: 17.3528154 },
    { lat: 48.7271419, lng: 17.3530306 },
    { lat: 48.7276651, lng: 17.3536592 },
    { lat: 48.728916, lng: 17.3554951 },
    { lat: 48.7291704, lng: 17.3560019 },
    { lat: 48.7294559, lng: 17.3558604 },
    { lat: 48.7296596, lng: 17.3558653 },
    { lat: 48.7299149, lng: 17.3561272 },
    { lat: 48.7301452, lng: 17.3565933 },
    { lat: 48.7302993, lng: 17.3567761 },
    { lat: 48.730458, lng: 17.3568728 },
    { lat: 48.7307698, lng: 17.3568874 },
    { lat: 48.7308582, lng: 17.3568052 },
    { lat: 48.7325427, lng: 17.3555067 },
    { lat: 48.7326745, lng: 17.355466 },
    { lat: 48.7331265, lng: 17.3550154 },
    { lat: 48.7337609, lng: 17.3541605 },
    { lat: 48.7346653, lng: 17.3527968 },
    { lat: 48.7351923, lng: 17.351901 },
    { lat: 48.7357302, lng: 17.3511381 },
    { lat: 48.7371771, lng: 17.34926 },
    { lat: 48.7379298, lng: 17.3483968 },
    { lat: 48.7380268, lng: 17.3482038 },
    { lat: 48.7379558, lng: 17.3479635 },
    { lat: 48.7381933, lng: 17.3477428 },
    { lat: 48.7385599, lng: 17.3472461 },
    { lat: 48.7387052, lng: 17.346736 },
    { lat: 48.7387877, lng: 17.3467195 },
    { lat: 48.7389754, lng: 17.3463219 },
    { lat: 48.7389888, lng: 17.3461457 },
    { lat: 48.7391649, lng: 17.3457276 },
    { lat: 48.7393005, lng: 17.3456069 },
    { lat: 48.7393289, lng: 17.3452603 },
    { lat: 48.7394894, lng: 17.3447583 },
    { lat: 48.7396325, lng: 17.3445087 },
    { lat: 48.7398665, lng: 17.3435399 },
    { lat: 48.7406013, lng: 17.341014 },
    { lat: 48.7408691, lng: 17.3399711 },
    { lat: 48.7412102, lng: 17.3380973 },
    { lat: 48.7417155, lng: 17.3358992 },
    { lat: 48.7419434, lng: 17.3344538 },
    { lat: 48.7418262, lng: 17.3324975 },
    { lat: 48.741834, lng: 17.3314545 },
    { lat: 48.7419065, lng: 17.3309026 },
    { lat: 48.7414442, lng: 17.3294554 },
    { lat: 48.7409973, lng: 17.328424 },
    { lat: 48.7407645, lng: 17.3277107 },
    { lat: 48.7407051, lng: 17.3273223 },
    { lat: 48.7406964, lng: 17.326509 },
    { lat: 48.7405848, lng: 17.3258536 },
    { lat: 48.7404815, lng: 17.3256041 },
    { lat: 48.7395204, lng: 17.3242897 },
    { lat: 48.739432, lng: 17.324138 },
    { lat: 48.739412, lng: 17.32411 },
    { lat: 48.739334, lng: 17.323998 },
    { lat: 48.739125, lng: 17.3237 },
    { lat: 48.739042, lng: 17.323567 },
    { lat: 48.738624, lng: 17.32299 },
    { lat: 48.738275, lng: 17.321993 },
    { lat: 48.738219, lng: 17.321802 },
    { lat: 48.73811, lng: 17.321511 },
    { lat: 48.738053, lng: 17.321376 },
    { lat: 48.737793, lng: 17.320952 },
    { lat: 48.737756, lng: 17.320899 },
    { lat: 48.737564, lng: 17.320389 },
    { lat: 48.73747, lng: 17.319982 },
    { lat: 48.737271, lng: 17.319061 },
    { lat: 48.737208, lng: 17.318707 },
    { lat: 48.7361944, lng: 17.3192754 },
    { lat: 48.7353253, lng: 17.3199292 },
    { lat: 48.7338624, lng: 17.3201687 },
    { lat: 48.7329093, lng: 17.3204174 },
    { lat: 48.7324467, lng: 17.3207168 },
    { lat: 48.7321387, lng: 17.3208285 },
    { lat: 48.7314825, lng: 17.3214205 },
    { lat: 48.7309001, lng: 17.3216329 },
    { lat: 48.7295432, lng: 17.3220125 },
    { lat: 48.729483, lng: 17.3219381 },
    { lat: 48.7293986, lng: 17.3215074 },
    { lat: 48.7293382, lng: 17.3215371 },
    { lat: 48.7292951, lng: 17.3213947 },
    { lat: 48.7289243, lng: 17.3216139 },
    { lat: 48.728821, lng: 17.3218164 },
    { lat: 48.7285104, lng: 17.3221786 },
    { lat: 48.728163, lng: 17.3224812 },
    { lat: 48.7279013, lng: 17.3226278 },
    { lat: 48.7278219, lng: 17.3230179 },
    { lat: 48.727661, lng: 17.3231674 },
    { lat: 48.7275021, lng: 17.3232945 },
    { lat: 48.7270822, lng: 17.3231095 },
    { lat: 48.7267059, lng: 17.3234009 },
    { lat: 48.7264274, lng: 17.3237215 },
    { lat: 48.7259874, lng: 17.3239033 },
    { lat: 48.7258017, lng: 17.3243306 },
    { lat: 48.7255998, lng: 17.3243342 },
    { lat: 48.7254962, lng: 17.3248223 },
    { lat: 48.7253834, lng: 17.3247725 },
    { lat: 48.7251778, lng: 17.3250858 },
    { lat: 48.7241803, lng: 17.3254315 },
    { lat: 48.7238469, lng: 17.3258432 },
    { lat: 48.7235212, lng: 17.3264097 },
    { lat: 48.7230523, lng: 17.3268237 },
    { lat: 48.722969, lng: 17.3270887 },
    { lat: 48.7226856, lng: 17.3274546 },
    { lat: 48.7225715, lng: 17.3274047 },
    { lat: 48.722245, lng: 17.3277148 },
    { lat: 48.722297, lng: 17.3278862 },
    { lat: 48.7227384, lng: 17.3293407 },
    { lat: 48.7232491, lng: 17.3306939 },
    { lat: 48.7225088, lng: 17.3317632 },
    { lat: 48.7223575, lng: 17.3320387 },
    { lat: 48.722275, lng: 17.3324044 },
    { lat: 48.7217424, lng: 17.3328982 },
    { lat: 48.7209866, lng: 17.3332804 },
    { lat: 48.7213831, lng: 17.3349783 },
    { lat: 48.7218432, lng: 17.3373808 },
    { lat: 48.7217698, lng: 17.3377623 },
    { lat: 48.7217945, lng: 17.3381743 },
    { lat: 48.7217224, lng: 17.3389617 },
    { lat: 48.7219436, lng: 17.3390075 },
    { lat: 48.7222443, lng: 17.339219 },
    { lat: 48.7223096, lng: 17.3408166 },
    { lat: 48.7222374, lng: 17.3410595 },
    { lat: 48.7222171, lng: 17.3418245 },
    { lat: 48.7221627, lng: 17.3421496 },
    { lat: 48.7218479, lng: 17.3429723 },
    { lat: 48.7217203, lng: 17.3436516 },
    { lat: 48.7218224, lng: 17.3443526 },
    { lat: 48.7218927, lng: 17.3445633 },
    { lat: 48.721871, lng: 17.3449071 },
    { lat: 48.7219512, lng: 17.3457437 },
    { lat: 48.7218907, lng: 17.3463051 },
    { lat: 48.7220259, lng: 17.3471865 },
    { lat: 48.722047, lng: 17.3476867 },
    { lat: 48.7221231, lng: 17.3480921 },
    { lat: 48.7225113, lng: 17.3488691 },
    { lat: 48.7225177, lng: 17.3489125 },
  ],
  LakšárskaNováVes: [
    { lat: 48.562805, lng: 17.204128 },
    { lat: 48.56274, lng: 17.204169 },
    { lat: 48.562567, lng: 17.204298 },
    { lat: 48.562481, lng: 17.204414 },
    { lat: 48.562391, lng: 17.204591 },
    { lat: 48.562327, lng: 17.204775 },
    { lat: 48.562261, lng: 17.205044 },
    { lat: 48.562227, lng: 17.205476 },
    { lat: 48.56225, lng: 17.205495 },
    { lat: 48.562308, lng: 17.205544 },
    { lat: 48.562496, lng: 17.205703 },
    { lat: 48.562502, lng: 17.205709 },
    { lat: 48.562507, lng: 17.205713 },
    { lat: 48.562523, lng: 17.205726 },
    { lat: 48.562551, lng: 17.20575 },
    { lat: 48.562602, lng: 17.205792 },
    { lat: 48.56261, lng: 17.2058 },
    { lat: 48.563307, lng: 17.206389 },
    { lat: 48.5634, lng: 17.206122 },
    { lat: 48.563674, lng: 17.206187 },
    { lat: 48.563905, lng: 17.205561 },
    { lat: 48.563914, lng: 17.20557 },
    { lat: 48.563925, lng: 17.205577 },
    { lat: 48.563916, lng: 17.205602 },
    { lat: 48.563694, lng: 17.206202 },
    { lat: 48.563729, lng: 17.206251 },
    { lat: 48.563925, lng: 17.206435 },
    { lat: 48.563956, lng: 17.206459 },
    { lat: 48.564672, lng: 17.20643 },
    { lat: 48.564698, lng: 17.206418 },
    { lat: 48.564827, lng: 17.206556 },
    { lat: 48.56507, lng: 17.206813 },
    { lat: 48.565063, lng: 17.206857 },
    { lat: 48.564909, lng: 17.207089 },
    { lat: 48.564875, lng: 17.207299 },
    { lat: 48.564875, lng: 17.207395 },
    { lat: 48.564874, lng: 17.207553 },
    { lat: 48.564859, lng: 17.207645 },
    { lat: 48.564794, lng: 17.208046 },
    { lat: 48.565195, lng: 17.209445 },
    { lat: 48.565201, lng: 17.209476 },
    { lat: 48.565208, lng: 17.209516 },
    { lat: 48.565239, lng: 17.20968 },
    { lat: 48.565316, lng: 17.210085 },
    { lat: 48.565416, lng: 17.210622 },
    { lat: 48.565492, lng: 17.211025 },
    { lat: 48.565583, lng: 17.211511 },
    { lat: 48.56559, lng: 17.211551 },
    { lat: 48.565632, lng: 17.211765 },
    { lat: 48.565696, lng: 17.2121 },
    { lat: 48.565809, lng: 17.212687 },
    { lat: 48.565893, lng: 17.213121 },
    { lat: 48.565935, lng: 17.213334 },
    { lat: 48.565883, lng: 17.213342 },
    { lat: 48.565861, lng: 17.213345 },
    { lat: 48.565807, lng: 17.213353 },
    { lat: 48.565797, lng: 17.213355 },
    { lat: 48.565802, lng: 17.213404 },
    { lat: 48.566022, lng: 17.214002 },
    { lat: 48.56608, lng: 17.214724 },
    { lat: 48.566291, lng: 17.216133 },
    { lat: 48.566334, lng: 17.216122 },
    { lat: 48.566443, lng: 17.216093 },
    { lat: 48.566716, lng: 17.216077 },
    { lat: 48.566929, lng: 17.216045 },
    { lat: 48.567027, lng: 17.216067 },
    { lat: 48.566957, lng: 17.216698 },
    { lat: 48.566424, lng: 17.216809 },
    { lat: 48.566463, lng: 17.217313 },
    { lat: 48.566633, lng: 17.218004 },
    { lat: 48.566657, lng: 17.218453 },
    { lat: 48.566659, lng: 17.218492 },
    { lat: 48.566661, lng: 17.21851 },
    { lat: 48.566666, lng: 17.218584 },
    { lat: 48.566669, lng: 17.218609 },
    { lat: 48.566671, lng: 17.218641 },
    { lat: 48.567505, lng: 17.220412 },
    { lat: 48.568118, lng: 17.220733 },
    { lat: 48.568131, lng: 17.220739 },
    { lat: 48.568376, lng: 17.220898 },
    { lat: 48.568355, lng: 17.221173 },
    { lat: 48.568311, lng: 17.22173 },
    { lat: 48.568281, lng: 17.222011 },
    { lat: 48.568193, lng: 17.222867 },
    { lat: 48.568188, lng: 17.222922 },
    { lat: 48.568162, lng: 17.223759 },
    { lat: 48.568467, lng: 17.224377 },
    { lat: 48.568466, lng: 17.22439 },
    { lat: 48.568429, lng: 17.224881 },
    { lat: 48.568376, lng: 17.225487 },
    { lat: 48.568374, lng: 17.225506 },
    { lat: 48.568335, lng: 17.225869 },
    { lat: 48.568316, lng: 17.226292 },
    { lat: 48.568297, lng: 17.226884 },
    { lat: 48.568264, lng: 17.227542 },
    { lat: 48.568225, lng: 17.228195 },
    { lat: 48.568222, lng: 17.228253 },
    { lat: 48.568194, lng: 17.228417 },
    { lat: 48.567805, lng: 17.22959 },
    { lat: 48.567743, lng: 17.230158 },
    { lat: 48.567634, lng: 17.231701 },
    { lat: 48.567628, lng: 17.231751 },
    { lat: 48.567601, lng: 17.232132 },
    { lat: 48.567503, lng: 17.233499 },
    { lat: 48.567414, lng: 17.235787 },
    { lat: 48.567467, lng: 17.235787 },
    { lat: 48.568196, lng: 17.235785 },
    { lat: 48.568627, lng: 17.235784 },
    { lat: 48.568647, lng: 17.235751 },
    { lat: 48.569123, lng: 17.234523 },
    { lat: 48.569415, lng: 17.233772 },
    { lat: 48.569625, lng: 17.233893 },
    { lat: 48.569608, lng: 17.233779 },
    { lat: 48.569671, lng: 17.233823 },
    { lat: 48.570267, lng: 17.234235 },
    { lat: 48.570351, lng: 17.234014 },
    { lat: 48.570439, lng: 17.233784 },
    { lat: 48.571778, lng: 17.234113 },
    { lat: 48.571812, lng: 17.23412 },
    { lat: 48.571817, lng: 17.234122 },
    { lat: 48.571851, lng: 17.23413 },
    { lat: 48.572825, lng: 17.234354 },
    { lat: 48.573814, lng: 17.234578 },
    { lat: 48.574763, lng: 17.234793 },
    { lat: 48.574787, lng: 17.234799 },
    { lat: 48.574802, lng: 17.234803 },
    { lat: 48.574954, lng: 17.234982 },
    { lat: 48.575213, lng: 17.23521 },
    { lat: 48.575247, lng: 17.235241 },
    { lat: 48.575307, lng: 17.235232 },
    { lat: 48.576417, lng: 17.235067 },
    { lat: 48.577577, lng: 17.234897 },
    { lat: 48.577823, lng: 17.234873 },
    { lat: 48.578723, lng: 17.234752 },
    { lat: 48.57914, lng: 17.234702 },
    { lat: 48.579323, lng: 17.234681 },
    { lat: 48.579492, lng: 17.234656 },
    { lat: 48.579618, lng: 17.23464 },
    { lat: 48.57969, lng: 17.234631 },
    { lat: 48.579719, lng: 17.234628 },
    { lat: 48.580064, lng: 17.234584 },
    { lat: 48.580148, lng: 17.234573 },
    { lat: 48.580253, lng: 17.23456 },
    { lat: 48.580467, lng: 17.234532 },
    { lat: 48.580757, lng: 17.234492 },
    { lat: 48.5807264, lng: 17.2332518 },
    { lat: 48.580841, lng: 17.2325756 },
    { lat: 48.5810509, lng: 17.2303544 },
    { lat: 48.5807013, lng: 17.2277567 },
    { lat: 48.5807194, lng: 17.2277404 },
    { lat: 48.5811101, lng: 17.2273948 },
    { lat: 48.5811921, lng: 17.2272487 },
    { lat: 48.5812219, lng: 17.2272742 },
    { lat: 48.5818051, lng: 17.2261713 },
    { lat: 48.5826476, lng: 17.2249695 },
    { lat: 48.5830917, lng: 17.2244445 },
    { lat: 48.5844122, lng: 17.222158 },
    { lat: 48.5844872, lng: 17.2219594 },
    { lat: 48.5862928, lng: 17.2192346 },
    { lat: 48.5873118, lng: 17.2175477 },
    { lat: 48.5880612, lng: 17.2164285 },
    { lat: 48.5881648, lng: 17.2161021 },
    { lat: 48.5879602, lng: 17.2138662 },
    { lat: 48.5879993, lng: 17.2128037 },
    { lat: 48.5879372, lng: 17.2124316 },
    { lat: 48.5877307, lng: 17.2117712 },
    { lat: 48.5872505, lng: 17.2086202 },
    { lat: 48.5871267, lng: 17.2073692 },
    { lat: 48.5866983, lng: 17.2042698 },
    { lat: 48.5866853, lng: 17.204043 },
    { lat: 48.5867407, lng: 17.2037713 },
    { lat: 48.588586, lng: 17.2019918 },
    { lat: 48.5890029, lng: 17.2017847 },
    { lat: 48.5901938, lng: 17.2009196 },
    { lat: 48.5913045, lng: 17.1997819 },
    { lat: 48.5928621, lng: 17.1985042 },
    { lat: 48.5937013, lng: 17.1979909 },
    { lat: 48.5939891, lng: 17.1977286 },
    { lat: 48.5942021, lng: 17.197433 },
    { lat: 48.5951549, lng: 17.1966752 },
    { lat: 48.5965826, lng: 17.1952423 },
    { lat: 48.5973528, lng: 17.1946958 },
    { lat: 48.5982852, lng: 17.1938132 },
    { lat: 48.5985133, lng: 17.1934057 },
    { lat: 48.598847, lng: 17.1925778 },
    { lat: 48.6006022, lng: 17.1887522 },
    { lat: 48.6010506, lng: 17.187525 },
    { lat: 48.6013712, lng: 17.1867758 },
    { lat: 48.6015105, lng: 17.1865952 },
    { lat: 48.6015342, lng: 17.1863396 },
    { lat: 48.6019935, lng: 17.1851945 },
    { lat: 48.6062481, lng: 17.1758788 },
    { lat: 48.606518, lng: 17.1751649 },
    { lat: 48.6071238, lng: 17.1739496 },
    { lat: 48.6075408, lng: 17.1728329 },
    { lat: 48.60798, lng: 17.1716749 },
    { lat: 48.6079488, lng: 17.1715807 },
    { lat: 48.6084137, lng: 17.1706738 },
    { lat: 48.6072141, lng: 17.1677823 },
    { lat: 48.6025982, lng: 17.1548176 },
    { lat: 48.5933327, lng: 17.1284386 },
    { lat: 48.591367, lng: 17.1225869 },
    { lat: 48.5909856, lng: 17.1214341 },
    { lat: 48.5909445, lng: 17.1213765 },
    { lat: 48.5889442, lng: 17.1219295 },
    { lat: 48.5801041, lng: 17.1245375 },
    { lat: 48.5787663, lng: 17.1248575 },
    { lat: 48.5779487, lng: 17.1251531 },
    { lat: 48.5770705, lng: 17.1253815 },
    { lat: 48.5764597, lng: 17.125627 },
    { lat: 48.5763638, lng: 17.1254076 },
    { lat: 48.5762092, lng: 17.1244871 },
    { lat: 48.5760605, lng: 17.1239053 },
    { lat: 48.576092, lng: 17.1237328 },
    { lat: 48.5760661, lng: 17.1236508 },
    { lat: 48.5761064, lng: 17.1236446 },
    { lat: 48.5760719, lng: 17.1235535 },
    { lat: 48.5761042, lng: 17.1235129 },
    { lat: 48.5761152, lng: 17.1230039 },
    { lat: 48.5760494, lng: 17.1227634 },
    { lat: 48.5758319, lng: 17.1223353 },
    { lat: 48.5757749, lng: 17.1223611 },
    { lat: 48.5757037, lng: 17.122211 },
    { lat: 48.5755772, lng: 17.1222949 },
    { lat: 48.5754649, lng: 17.1220848 },
    { lat: 48.5751165, lng: 17.1216919 },
    { lat: 48.5751055, lng: 17.1213632 },
    { lat: 48.5751597, lng: 17.1213278 },
    { lat: 48.5751147, lng: 17.1212954 },
    { lat: 48.5751151, lng: 17.1211669 },
    { lat: 48.5751657, lng: 17.1210468 },
    { lat: 48.5752963, lng: 17.1209391 },
    { lat: 48.5753783, lng: 17.1209658 },
    { lat: 48.5755237, lng: 17.1207749 },
    { lat: 48.575488, lng: 17.1205302 },
    { lat: 48.5755533, lng: 17.120258 },
    { lat: 48.5754586, lng: 17.1200741 },
    { lat: 48.5754498, lng: 17.1198446 },
    { lat: 48.5753861, lng: 17.1198204 },
    { lat: 48.5753223, lng: 17.1196956 },
    { lat: 48.5753375, lng: 17.1196355 },
    { lat: 48.5752726, lng: 17.1196104 },
    { lat: 48.5751094, lng: 17.1192503 },
    { lat: 48.5751009, lng: 17.1190882 },
    { lat: 48.5751797, lng: 17.1189017 },
    { lat: 48.5751322, lng: 17.1186492 },
    { lat: 48.5749602, lng: 17.118542 },
    { lat: 48.5749649, lng: 17.1183436 },
    { lat: 48.5748998, lng: 17.1182847 },
    { lat: 48.5749092, lng: 17.1182194 },
    { lat: 48.5747807, lng: 17.1181923 },
    { lat: 48.574794, lng: 17.1179712 },
    { lat: 48.5747315, lng: 17.1179144 },
    { lat: 48.5747411, lng: 17.1173188 },
    { lat: 48.5746917, lng: 17.1172207 },
    { lat: 48.5747074, lng: 17.1167681 },
    { lat: 48.5744791, lng: 17.1164775 },
    { lat: 48.5745082, lng: 17.1162561 },
    { lat: 48.5743716, lng: 17.1162249 },
    { lat: 48.5742214, lng: 17.1163117 },
    { lat: 48.5741583, lng: 17.1161265 },
    { lat: 48.5742108, lng: 17.1159344 },
    { lat: 48.5741194, lng: 17.115836 },
    { lat: 48.5740201, lng: 17.1153848 },
    { lat: 48.5739471, lng: 17.1153727 },
    { lat: 48.5739162, lng: 17.1151495 },
    { lat: 48.5738037, lng: 17.1149872 },
    { lat: 48.5737999, lng: 17.1147969 },
    { lat: 48.5736685, lng: 17.114575 },
    { lat: 48.5737785, lng: 17.1142425 },
    { lat: 48.5737029, lng: 17.1141605 },
    { lat: 48.5737235, lng: 17.1139622 },
    { lat: 48.5738131, lng: 17.1139011 },
    { lat: 48.5737879, lng: 17.1138468 },
    { lat: 48.5738193, lng: 17.1137749 },
    { lat: 48.5735516, lng: 17.113642 },
    { lat: 48.5735385, lng: 17.1134799 },
    { lat: 48.573623, lng: 17.1134438 },
    { lat: 48.5735964, lng: 17.1131646 },
    { lat: 48.5737107, lng: 17.1130015 },
    { lat: 48.5735963, lng: 17.1130122 },
    { lat: 48.5735626, lng: 17.1128979 },
    { lat: 48.5734478, lng: 17.1129232 },
    { lat: 48.5733599, lng: 17.1127918 },
    { lat: 48.5733015, lng: 17.1128146 },
    { lat: 48.5733325, lng: 17.1126208 },
    { lat: 48.5732196, lng: 17.112501 },
    { lat: 48.5732311, lng: 17.1124047 },
    { lat: 48.5731504, lng: 17.1122783 },
    { lat: 48.5729875, lng: 17.1122928 },
    { lat: 48.5728416, lng: 17.1121413 },
    { lat: 48.5727577, lng: 17.1121356 },
    { lat: 48.5726531, lng: 17.1119575 },
    { lat: 48.5725081, lng: 17.1118858 },
    { lat: 48.5724522, lng: 17.1117762 },
    { lat: 48.5724651, lng: 17.111633 },
    { lat: 48.5724159, lng: 17.111592 },
    { lat: 48.5723913, lng: 17.1113428 },
    { lat: 48.5722686, lng: 17.1111478 },
    { lat: 48.5720754, lng: 17.1109789 },
    { lat: 48.5717735, lng: 17.110877 },
    { lat: 48.5716668, lng: 17.1109868 },
    { lat: 48.571668, lng: 17.1111571 },
    { lat: 48.5715417, lng: 17.111225 },
    { lat: 48.5714716, lng: 17.1111582 },
    { lat: 48.5713506, lng: 17.1111585 },
    { lat: 48.5712013, lng: 17.1110044 },
    { lat: 48.5711297, lng: 17.1111302 },
    { lat: 48.5707692, lng: 17.1110968 },
    { lat: 48.5707312, lng: 17.1111554 },
    { lat: 48.5705593, lng: 17.1110354 },
    { lat: 48.570485, lng: 17.1109061 },
    { lat: 48.5704593, lng: 17.1109593 },
    { lat: 48.5701018, lng: 17.1108505 },
    { lat: 48.5700627, lng: 17.1109246 },
    { lat: 48.5696442, lng: 17.1109504 },
    { lat: 48.569204, lng: 17.1105067 },
    { lat: 48.5689553, lng: 17.1101498 },
    { lat: 48.5686858, lng: 17.1093551 },
    { lat: 48.5678411, lng: 17.1099829 },
    { lat: 48.5670668, lng: 17.1071073 },
    { lat: 48.5661069, lng: 17.1084092 },
    { lat: 48.5652803, lng: 17.1090038 },
    { lat: 48.5643798, lng: 17.1088332 },
    { lat: 48.5636957, lng: 17.1081044 },
    { lat: 48.5626684, lng: 17.1064732 },
    { lat: 48.562654, lng: 17.106429 },
    { lat: 48.561949, lng: 17.107181 },
    { lat: 48.561441, lng: 17.107562 },
    { lat: 48.561222, lng: 17.107784 },
    { lat: 48.560935, lng: 17.108294 },
    { lat: 48.56052, lng: 17.10897 },
    { lat: 48.559986, lng: 17.110189 },
    { lat: 48.559555, lng: 17.110975 },
    { lat: 48.559338, lng: 17.11155 },
    { lat: 48.558877, lng: 17.113124 },
    { lat: 48.558543, lng: 17.114096 },
    { lat: 48.557958, lng: 17.11504 },
    { lat: 48.557773, lng: 17.115247 },
    { lat: 48.557402, lng: 17.115495 },
    { lat: 48.55705, lng: 17.115666 },
    { lat: 48.556716, lng: 17.115933 },
    { lat: 48.556614, lng: 17.116045 },
    { lat: 48.556388, lng: 17.116342 },
    { lat: 48.55605, lng: 17.116814 },
    { lat: 48.555659, lng: 17.117449 },
    { lat: 48.555407, lng: 17.117803 },
    { lat: 48.555237, lng: 17.117996 },
    { lat: 48.55506, lng: 17.118065 },
    { lat: 48.55463, lng: 17.118385 },
    { lat: 48.554606, lng: 17.11835 },
    { lat: 48.554113, lng: 17.117431 },
    { lat: 48.554082, lng: 17.117372 },
    { lat: 48.554002, lng: 17.117224 },
    { lat: 48.553683, lng: 17.117583 },
    { lat: 48.553574, lng: 17.117705 },
    { lat: 48.552721, lng: 17.11865 },
    { lat: 48.552674, lng: 17.1187 },
    { lat: 48.552199, lng: 17.119227 },
    { lat: 48.552382, lng: 17.119631 },
    { lat: 48.552399, lng: 17.119668 },
    { lat: 48.551949, lng: 17.119931 },
    { lat: 48.551538, lng: 17.120338 },
    { lat: 48.551237, lng: 17.120737 },
    { lat: 48.550956, lng: 17.121359 },
    { lat: 48.550728, lng: 17.121749 },
    { lat: 48.550515, lng: 17.122056 },
    { lat: 48.550408, lng: 17.122173 },
    { lat: 48.550397, lng: 17.122142 },
    { lat: 48.54982, lng: 17.120588 },
    { lat: 48.548245, lng: 17.122983 },
    { lat: 48.546777, lng: 17.125236 },
    { lat: 48.546733, lng: 17.125258 },
    { lat: 48.546914, lng: 17.12643 },
    { lat: 48.547034, lng: 17.127735 },
    { lat: 48.547429, lng: 17.130125 },
    { lat: 48.5475, lng: 17.130522 },
    { lat: 48.547685, lng: 17.131341 },
    { lat: 48.547922, lng: 17.132119 },
    { lat: 48.547958, lng: 17.132211 },
    { lat: 48.548137, lng: 17.132647 },
    { lat: 48.548217, lng: 17.132897 },
    { lat: 48.548372, lng: 17.133465 },
    { lat: 48.548405, lng: 17.13361 },
    { lat: 48.548437, lng: 17.133763 },
    { lat: 48.54854, lng: 17.134209 },
    { lat: 48.54877, lng: 17.136196 },
    { lat: 48.548972, lng: 17.137177 },
    { lat: 48.549154, lng: 17.137961 },
    { lat: 48.54927, lng: 17.138864 },
    { lat: 48.54936, lng: 17.139466 },
    { lat: 48.549419, lng: 17.140319 },
    { lat: 48.549499, lng: 17.141776 },
    { lat: 48.549523, lng: 17.142268 },
    { lat: 48.549521, lng: 17.142314 },
    { lat: 48.549461, lng: 17.143662 },
    { lat: 48.54948, lng: 17.143815 },
    { lat: 48.549511, lng: 17.144043 },
    { lat: 48.549654, lng: 17.14477 },
    { lat: 48.549782, lng: 17.145127 },
    { lat: 48.550236, lng: 17.145807 },
    { lat: 48.5504703, lng: 17.1463742 },
    { lat: 48.5506115, lng: 17.1468084 },
    { lat: 48.5509365, lng: 17.1474837 },
    { lat: 48.5510628, lng: 17.1477535 },
    { lat: 48.551148, lng: 17.147971 },
    { lat: 48.5513017, lng: 17.1483878 },
    { lat: 48.5514909, lng: 17.1493935 },
    { lat: 48.5515865, lng: 17.150068 },
    { lat: 48.5516989, lng: 17.1509407 },
    { lat: 48.5517292, lng: 17.1512161 },
    { lat: 48.5518384, lng: 17.1523613 },
    { lat: 48.551897, lng: 17.1528561 },
    { lat: 48.5519618, lng: 17.1535585 },
    { lat: 48.5519157, lng: 17.154468 },
    { lat: 48.5518247, lng: 17.1550767 },
    { lat: 48.5516891, lng: 17.1554724 },
    { lat: 48.5515495, lng: 17.1557514 },
    { lat: 48.5513628, lng: 17.1561249 },
    { lat: 48.5512584, lng: 17.1566089 },
    { lat: 48.551318, lng: 17.1569571 },
    { lat: 48.5513903, lng: 17.1570317 },
    { lat: 48.551798, lng: 17.157791 },
    { lat: 48.5519768, lng: 17.1582143 },
    { lat: 48.552148, lng: 17.158726 },
    { lat: 48.552556, lng: 17.159834 },
    { lat: 48.552566, lng: 17.159856 },
    { lat: 48.552576, lng: 17.159879 },
    { lat: 48.552679, lng: 17.160174 },
    { lat: 48.552892, lng: 17.160982 },
    { lat: 48.553129, lng: 17.161914 },
    { lat: 48.553211, lng: 17.162233 },
    { lat: 48.553361, lng: 17.162797 },
    { lat: 48.553508, lng: 17.163461 },
    { lat: 48.553558, lng: 17.163669 },
    { lat: 48.553677, lng: 17.16403 },
    { lat: 48.554075, lng: 17.165123 },
    { lat: 48.554208, lng: 17.165492 },
    { lat: 48.554633, lng: 17.166494 },
    { lat: 48.554765, lng: 17.166752 },
    { lat: 48.55514, lng: 17.16729 },
    { lat: 48.555358, lng: 17.167571 },
    { lat: 48.5554373, lng: 17.1676965 },
    { lat: 48.5558556, lng: 17.1681254 },
    { lat: 48.5560846, lng: 17.1683942 },
    { lat: 48.5562228, lng: 17.1685707 },
    { lat: 48.5565471, lng: 17.169166 },
    { lat: 48.55693, lng: 17.170049 },
    { lat: 48.5570794, lng: 17.1704707 },
    { lat: 48.557276, lng: 17.171499 },
    { lat: 48.557288, lng: 17.171692 },
    { lat: 48.557325, lng: 17.172942 },
    { lat: 48.557289, lng: 17.173718 },
    { lat: 48.55728, lng: 17.17406 },
    { lat: 48.557277, lng: 17.174479 },
    { lat: 48.557343, lng: 17.175131 },
    { lat: 48.557458, lng: 17.17588 },
    { lat: 48.557498, lng: 17.17617 },
    { lat: 48.557521, lng: 17.176798 },
    { lat: 48.557598, lng: 17.177809 },
    { lat: 48.557682, lng: 17.178741 },
    { lat: 48.557739, lng: 17.179331 },
    { lat: 48.557873, lng: 17.179912 },
    { lat: 48.558031, lng: 17.180614 },
    { lat: 48.558159, lng: 17.181185 },
    { lat: 48.558213, lng: 17.181424 },
    { lat: 48.558302, lng: 17.181803 },
    { lat: 48.558627, lng: 17.182847 },
    { lat: 48.558953, lng: 17.183879 },
    { lat: 48.558955, lng: 17.183929 },
    { lat: 48.558959, lng: 17.183969 },
    { lat: 48.558943, lng: 17.183974 },
    { lat: 48.558497, lng: 17.184116 },
    { lat: 48.558525, lng: 17.184658 },
    { lat: 48.558511, lng: 17.184923 },
    { lat: 48.558733, lng: 17.184863 },
    { lat: 48.558759, lng: 17.185031 },
    { lat: 48.559028, lng: 17.184791 },
    { lat: 48.559046, lng: 17.184775 },
    { lat: 48.559064, lng: 17.18496 },
    { lat: 48.559398, lng: 17.188016 },
    { lat: 48.559403, lng: 17.188095 },
    { lat: 48.559533, lng: 17.189248 },
    { lat: 48.559567, lng: 17.18965 },
    { lat: 48.559744, lng: 17.190834 },
    { lat: 48.55987, lng: 17.191484 },
    { lat: 48.560096, lng: 17.192372 },
    { lat: 48.560551, lng: 17.194022 },
    { lat: 48.56064, lng: 17.19491 },
    { lat: 48.560647, lng: 17.194982 },
    { lat: 48.560744, lng: 17.195921 },
    { lat: 48.560855, lng: 17.196606 },
    { lat: 48.56103, lng: 17.196964 },
    { lat: 48.561629, lng: 17.197752 },
    { lat: 48.561811, lng: 17.197986 },
    { lat: 48.562043, lng: 17.198257 },
    { lat: 48.562291, lng: 17.198569 },
    { lat: 48.562592, lng: 17.198975 },
    { lat: 48.562791, lng: 17.199263 },
    { lat: 48.562938, lng: 17.199537 },
    { lat: 48.563097, lng: 17.19985 },
    { lat: 48.563206, lng: 17.200024 },
    { lat: 48.563411, lng: 17.200327 },
    { lat: 48.563443, lng: 17.200375 },
    { lat: 48.563681, lng: 17.200725 },
    { lat: 48.563925, lng: 17.201171 },
    { lat: 48.563972, lng: 17.201277 },
    { lat: 48.564115, lng: 17.201625 },
    { lat: 48.564219, lng: 17.201804 },
    { lat: 48.564568, lng: 17.202346 },
    { lat: 48.564598, lng: 17.202395 },
    { lat: 48.564014, lng: 17.202735 },
    { lat: 48.56387, lng: 17.202847 },
    { lat: 48.563717, lng: 17.20302 },
    { lat: 48.56364, lng: 17.203138 },
    { lat: 48.563561, lng: 17.203293 },
    { lat: 48.563446, lng: 17.203578 },
    { lat: 48.563375, lng: 17.203692 },
    { lat: 48.563308, lng: 17.203773 },
    { lat: 48.563191, lng: 17.203903 },
    { lat: 48.562993, lng: 17.204061 },
    { lat: 48.562826, lng: 17.204136 },
    { lat: 48.562805, lng: 17.204128 },
  ],
  MoravskýSvätýJán: [
    { lat: 48.6022435, lng: 16.9348297 },
    { lat: 48.601965, lng: 16.934281 },
    { lat: 48.601721, lng: 16.933991 },
    { lat: 48.60143, lng: 16.933772 },
    { lat: 48.601119, lng: 16.933648 },
    { lat: 48.600626, lng: 16.933595 },
    { lat: 48.600191, lng: 16.933599 },
    { lat: 48.599736, lng: 16.933633 },
    { lat: 48.599343, lng: 16.933687 },
    { lat: 48.598935, lng: 16.933809 },
    { lat: 48.598515, lng: 16.933974 },
    { lat: 48.598096, lng: 16.934186 },
    { lat: 48.597776, lng: 16.93441 },
    { lat: 48.597503, lng: 16.934607 },
    { lat: 48.597108, lng: 16.934853 },
    { lat: 48.596673, lng: 16.935092 },
    { lat: 48.596339, lng: 16.935272 },
    { lat: 48.595944, lng: 16.935459 },
    { lat: 48.595503, lng: 16.935636 },
    { lat: 48.595011, lng: 16.935808 },
    { lat: 48.594602, lng: 16.935922 },
    { lat: 48.594137, lng: 16.936041 },
    { lat: 48.592149, lng: 16.936472 },
    { lat: 48.590123, lng: 16.936889 },
    { lat: 48.589254, lng: 16.937094 },
    { lat: 48.588443, lng: 16.93734 },
    { lat: 48.587534, lng: 16.937654 },
    { lat: 48.586485, lng: 16.938092 },
    { lat: 48.585526, lng: 16.938508 },
    { lat: 48.585066, lng: 16.938716 },
    { lat: 48.584648, lng: 16.938862 },
    { lat: 48.584249, lng: 16.938983 },
    { lat: 48.583601, lng: 16.93911 },
    { lat: 48.58313, lng: 16.939144 },
    { lat: 48.582641, lng: 16.939162 },
    { lat: 48.582238, lng: 16.939158 },
    { lat: 48.581832, lng: 16.939129 },
    { lat: 48.581405, lng: 16.939064 },
    { lat: 48.581011, lng: 16.938971 },
    { lat: 48.580605, lng: 16.93885 },
    { lat: 48.580167, lng: 16.938682 },
    { lat: 48.579674, lng: 16.938473 },
    { lat: 48.579185, lng: 16.938234 },
    { lat: 48.578712, lng: 16.937961 },
    { lat: 48.578685, lng: 16.9379435 },
    { lat: 48.578185, lng: 16.937619 },
    { lat: 48.577796, lng: 16.937331 },
    { lat: 48.577385, lng: 16.937037 },
    { lat: 48.57695, lng: 16.936752 },
    { lat: 48.576505, lng: 16.936481 },
    { lat: 48.57606, lng: 16.936229 },
    { lat: 48.575651, lng: 16.936026 },
    { lat: 48.5753, lng: 16.935866 },
    { lat: 48.574864, lng: 16.93571 },
    { lat: 48.574458, lng: 16.935591 },
    { lat: 48.57418, lng: 16.935534 },
    { lat: 48.573788, lng: 16.935525 },
    { lat: 48.573487, lng: 16.935504 },
    { lat: 48.57311, lng: 16.935486 },
    { lat: 48.572739, lng: 16.935487 },
    { lat: 48.572346, lng: 16.935514 },
    { lat: 48.572051, lng: 16.935544 },
    { lat: 48.571749, lng: 16.93559 },
    { lat: 48.571415, lng: 16.935647 },
    { lat: 48.570849, lng: 16.935799 },
    { lat: 48.5692604, lng: 16.9364597 },
    { lat: 48.568055, lng: 16.936961 },
    { lat: 48.567652, lng: 16.937149 },
    { lat: 48.566965, lng: 16.937525 },
    { lat: 48.566599, lng: 16.937741 },
    { lat: 48.56582, lng: 16.9382 },
    { lat: 48.5653861, lng: 16.938511 },
    { lat: 48.565234, lng: 16.93862 },
    { lat: 48.563811, lng: 16.939846 },
    { lat: 48.563538, lng: 16.940129 },
    { lat: 48.563262, lng: 16.940468 },
    { lat: 48.562967, lng: 16.940888 },
    { lat: 48.562746, lng: 16.941268 },
    { lat: 48.562557, lng: 16.941665 },
    { lat: 48.562359, lng: 16.94207 },
    { lat: 48.562184, lng: 16.942437 },
    { lat: 48.56199, lng: 16.942784 },
    { lat: 48.561793, lng: 16.943111 },
    { lat: 48.561543, lng: 16.943461 },
    { lat: 48.561431, lng: 16.943593 },
    { lat: 48.561173, lng: 16.943882 },
    { lat: 48.560908, lng: 16.944134 },
    { lat: 48.560634, lng: 16.944361 },
    { lat: 48.560489, lng: 16.944452 },
    { lat: 48.560193, lng: 16.9446 },
    { lat: 48.559908, lng: 16.944705 },
    { lat: 48.559652, lng: 16.944759 },
    { lat: 48.559321, lng: 16.944806 },
    { lat: 48.55863, lng: 16.944846 },
    { lat: 48.558279, lng: 16.944843 },
    { lat: 48.5578, lng: 16.944777 },
    { lat: 48.557497, lng: 16.944707 },
    { lat: 48.557145, lng: 16.944601 },
    { lat: 48.556834, lng: 16.944484 },
    { lat: 48.556493, lng: 16.944306 },
    { lat: 48.554344, lng: 16.942888 },
    { lat: 48.554063, lng: 16.942726 },
    { lat: 48.55378, lng: 16.942607 },
    { lat: 48.553509, lng: 16.942532 },
    { lat: 48.553226, lng: 16.942496 },
    { lat: 48.552964, lng: 16.942505 },
    { lat: 48.552687, lng: 16.942563 },
    { lat: 48.552416, lng: 16.942687 },
    { lat: 48.552169, lng: 16.942869 },
    { lat: 48.552055, lng: 16.942958 },
    { lat: 48.551829, lng: 16.943193 },
    { lat: 48.55162, lng: 16.943439 },
    { lat: 48.551339, lng: 16.943871 },
    { lat: 48.551, lng: 16.944441 },
    { lat: 48.550825, lng: 16.944701 },
    { lat: 48.550624, lng: 16.944948 },
    { lat: 48.550415, lng: 16.945184 },
    { lat: 48.550297, lng: 16.945291 },
    { lat: 48.549968, lng: 16.94555 },
    { lat: 48.549485, lng: 16.945894 },
    { lat: 48.54925, lng: 16.946067 },
    { lat: 48.549001, lng: 16.946285 },
    { lat: 48.548551, lng: 16.946759 },
    { lat: 48.548304, lng: 16.947063 },
    { lat: 48.548063, lng: 16.947383 },
    { lat: 48.54779, lng: 16.94779 },
    { lat: 48.54752, lng: 16.948278 },
    { lat: 48.547339, lng: 16.94864 },
    { lat: 48.547191, lng: 16.948983 },
    { lat: 48.547058, lng: 16.949336 },
    { lat: 48.546945, lng: 16.949706 },
    { lat: 48.54685, lng: 16.950089 },
    { lat: 48.546702, lng: 16.950833 },
    { lat: 48.546571, lng: 16.951369 },
    { lat: 48.546518, lng: 16.951542 },
    { lat: 48.546326, lng: 16.952065 },
    { lat: 48.546254, lng: 16.952209 },
    { lat: 48.546074, lng: 16.952537 },
    { lat: 48.545899, lng: 16.952794 },
    { lat: 48.54572, lng: 16.953008 },
    { lat: 48.545615, lng: 16.953121 },
    { lat: 48.545407, lng: 16.953331 },
    { lat: 48.54518, lng: 16.95351 },
    { lat: 48.544846, lng: 16.95372 },
    { lat: 48.544718, lng: 16.953775 },
    { lat: 48.544256, lng: 16.953931 },
    { lat: 48.54378, lng: 16.953982 },
    { lat: 48.54326, lng: 16.953933 },
    { lat: 48.542996, lng: 16.95388 },
    { lat: 48.542727, lng: 16.953795 },
    { lat: 48.54228, lng: 16.953612 },
    { lat: 48.541718, lng: 16.953276 },
    { lat: 48.541444, lng: 16.953074 },
    { lat: 48.539841, lng: 16.951785 },
    { lat: 48.539313, lng: 16.951379 },
    { lat: 48.539143, lng: 16.95126 },
    { lat: 48.538668, lng: 16.950933 },
    { lat: 48.538494, lng: 16.950836 },
    { lat: 48.538183, lng: 16.950656 },
    { lat: 48.53804, lng: 16.950587 },
    { lat: 48.537603, lng: 16.950371 },
    { lat: 48.536972, lng: 16.95011 },
    { lat: 48.536398, lng: 16.949922 },
    { lat: 48.535802, lng: 16.949779 },
    { lat: 48.53594, lng: 16.950425 },
    { lat: 48.536038, lng: 16.950876 },
    { lat: 48.536226, lng: 16.951481 },
    { lat: 48.536445, lng: 16.952251 },
    { lat: 48.536649, lng: 16.952995 },
    { lat: 48.537039, lng: 16.954684 },
    { lat: 48.537279, lng: 16.95575 },
    { lat: 48.537441, lng: 16.956447 },
    { lat: 48.537719, lng: 16.957718 },
    { lat: 48.537878, lng: 16.958395 },
    { lat: 48.537988, lng: 16.958944 },
    { lat: 48.53809, lng: 16.959648 },
    { lat: 48.538151, lng: 16.960096 },
    { lat: 48.538146, lng: 16.960425 },
    { lat: 48.538109, lng: 16.960612 },
    { lat: 48.538027, lng: 16.960911 },
    { lat: 48.537969, lng: 16.961148 },
    { lat: 48.53792, lng: 16.961464 },
    { lat: 48.537861, lng: 16.96241 },
    { lat: 48.537796, lng: 16.963582 },
    { lat: 48.537702, lng: 16.964764 },
    { lat: 48.537656, lng: 16.965451 },
    { lat: 48.537626, lng: 16.966763 },
    { lat: 48.537608, lng: 16.967802 },
    { lat: 48.5376, lng: 16.968572 },
    { lat: 48.537625, lng: 16.969408 },
    { lat: 48.537661, lng: 16.970857 },
    { lat: 48.537648, lng: 16.970887 },
    { lat: 48.537711, lng: 16.970885 },
    { lat: 48.537751, lng: 16.970878 },
    { lat: 48.538078, lng: 16.970815 },
    { lat: 48.539452, lng: 16.970192 },
    { lat: 48.541071, lng: 16.969373 },
    { lat: 48.542541, lng: 16.96855 },
    { lat: 48.543337, lng: 16.968133 },
    { lat: 48.543727, lng: 16.967956 },
    { lat: 48.544086, lng: 16.967832 },
    { lat: 48.544513, lng: 16.967724 },
    { lat: 48.545514, lng: 16.967336 },
    { lat: 48.545686, lng: 16.967207 },
    { lat: 48.546147, lng: 16.966862 },
    { lat: 48.546251, lng: 16.966834 },
    { lat: 48.546449, lng: 16.966782 },
    { lat: 48.547118, lng: 16.966604 },
    { lat: 48.547167, lng: 16.966597 },
    { lat: 48.54722, lng: 16.96658 },
    { lat: 48.547312, lng: 16.967119 },
    { lat: 48.547327, lng: 16.967215 },
    { lat: 48.547355, lng: 16.967382 },
    { lat: 48.547357, lng: 16.967739 },
    { lat: 48.5473118, lng: 16.9680557 },
    { lat: 48.547355, lng: 16.968206 },
    { lat: 48.547355, lng: 16.968321 },
    { lat: 48.547357, lng: 16.969018 },
    { lat: 48.547355, lng: 16.96906 },
    { lat: 48.547355, lng: 16.970024 },
    { lat: 48.547354, lng: 16.970207 },
    { lat: 48.547355, lng: 16.970382 },
    { lat: 48.547358, lng: 16.971774 },
    { lat: 48.547359, lng: 16.974038 },
    { lat: 48.547358, lng: 16.975201 },
    { lat: 48.54736, lng: 16.975867 },
    { lat: 48.5473075, lng: 16.9754941 },
    { lat: 48.547366, lng: 16.977923 },
    { lat: 48.547469, lng: 16.978371 },
    { lat: 48.54761, lng: 16.97878 },
    { lat: 48.547766, lng: 16.979015 },
    { lat: 48.547889, lng: 16.979165 },
    { lat: 48.548356, lng: 16.979746 },
    { lat: 48.548404, lng: 16.979805 },
    { lat: 48.548459, lng: 16.979874 },
    { lat: 48.548482, lng: 16.979903 },
    { lat: 48.548511, lng: 16.97994 },
    { lat: 48.548569, lng: 16.98001 },
    { lat: 48.548623, lng: 16.980071 },
    { lat: 48.548654, lng: 16.980106 },
    { lat: 48.548683, lng: 16.980139 },
    { lat: 48.548731, lng: 16.980193 },
    { lat: 48.548775, lng: 16.980244 },
    { lat: 48.548811, lng: 16.980284 },
    { lat: 48.548944, lng: 16.980435 },
    { lat: 48.549105, lng: 16.980618 },
    { lat: 48.549145, lng: 16.980663 },
    { lat: 48.549183, lng: 16.980707 },
    { lat: 48.549223, lng: 16.980752 },
    { lat: 48.549265, lng: 16.980799 },
    { lat: 48.549345, lng: 16.98089 },
    { lat: 48.54954, lng: 16.98111 },
    { lat: 48.549578, lng: 16.981154 },
    { lat: 48.549621, lng: 16.981202 },
    { lat: 48.549662, lng: 16.98125 },
    { lat: 48.549785, lng: 16.981389 },
    { lat: 48.549809, lng: 16.981417 },
    { lat: 48.549843, lng: 16.981455 },
    { lat: 48.549875, lng: 16.981491 },
    { lat: 48.549888, lng: 16.981505 },
    { lat: 48.549905, lng: 16.981524 },
    { lat: 48.550145, lng: 16.981796 },
    { lat: 48.550392, lng: 16.982006 },
    { lat: 48.551541, lng: 16.98298 },
    { lat: 48.551569, lng: 16.983452 },
    { lat: 48.551574, lng: 16.983528 },
    { lat: 48.551597, lng: 16.983874 },
    { lat: 48.551639, lng: 16.984507 },
    { lat: 48.551648, lng: 16.984633 },
    { lat: 48.551667, lng: 16.984911 },
    { lat: 48.551681, lng: 16.985126 },
    { lat: 48.551742, lng: 16.986 },
    { lat: 48.55204, lng: 16.990255 },
    { lat: 48.552145, lng: 16.991912 },
    { lat: 48.552159, lng: 16.992104 },
    { lat: 48.55225, lng: 16.993385 },
    { lat: 48.552429, lng: 16.996021 },
    { lat: 48.552611, lng: 16.998728 },
    { lat: 48.552821, lng: 17.002536 },
    { lat: 48.552834, lng: 17.002742 },
    { lat: 48.552922, lng: 17.004259 },
    { lat: 48.553138, lng: 17.00916 },
    { lat: 48.55315, lng: 17.009445 },
    { lat: 48.553161, lng: 17.009663 },
    { lat: 48.553164, lng: 17.009741 },
    { lat: 48.553167, lng: 17.009805 },
    { lat: 48.553169, lng: 17.009851 },
    { lat: 48.553175, lng: 17.00997 },
    { lat: 48.553177, lng: 17.010016 },
    { lat: 48.553181, lng: 17.010105 },
    { lat: 48.553185, lng: 17.01018 },
    { lat: 48.553189, lng: 17.01027 },
    { lat: 48.553194, lng: 17.01036 },
    { lat: 48.553202, lng: 17.010524 },
    { lat: 48.553206, lng: 17.010622 },
    { lat: 48.55321, lng: 17.010712 },
    { lat: 48.553218, lng: 17.010884 },
    { lat: 48.553222, lng: 17.010952 },
    { lat: 48.553223, lng: 17.010981 },
    { lat: 48.553226, lng: 17.011048 },
    { lat: 48.553228, lng: 17.011101 },
    { lat: 48.553253, lng: 17.011609 },
    { lat: 48.553257, lng: 17.011723 },
    { lat: 48.553262, lng: 17.01182 },
    { lat: 48.553267, lng: 17.011933 },
    { lat: 48.553277, lng: 17.012132 },
    { lat: 48.553281, lng: 17.012208 },
    { lat: 48.553284, lng: 17.01227 },
    { lat: 48.553291, lng: 17.012397 },
    { lat: 48.553297, lng: 17.012504 },
    { lat: 48.553303, lng: 17.012631 },
    { lat: 48.553312, lng: 17.0128 },
    { lat: 48.553321, lng: 17.012963 },
    { lat: 48.55333, lng: 17.013126 },
    { lat: 48.553338, lng: 17.013274 },
    { lat: 48.553355, lng: 17.01359 },
    { lat: 48.553365, lng: 17.013763 },
    { lat: 48.553372, lng: 17.013906 },
    { lat: 48.55338, lng: 17.014054 },
    { lat: 48.553387, lng: 17.014186 },
    { lat: 48.553394, lng: 17.014303 },
    { lat: 48.553399, lng: 17.014395 },
    { lat: 48.553402, lng: 17.014467 },
    { lat: 48.553409, lng: 17.014599 },
    { lat: 48.553416, lng: 17.014711 },
    { lat: 48.553429, lng: 17.014977 },
    { lat: 48.553433, lng: 17.015053 },
    { lat: 48.553438, lng: 17.015144 },
    { lat: 48.553444, lng: 17.015242 },
    { lat: 48.553449, lng: 17.015328 },
    { lat: 48.553453, lng: 17.015425 },
    { lat: 48.553458, lng: 17.015517 },
    { lat: 48.553462, lng: 17.015578 },
    { lat: 48.553467, lng: 17.015675 },
    { lat: 48.553473, lng: 17.015782 },
    { lat: 48.55348, lng: 17.015915 },
    { lat: 48.553487, lng: 17.016032 },
    { lat: 48.553496, lng: 17.016225 },
    { lat: 48.553507, lng: 17.016422 },
    { lat: 48.553514, lng: 17.01651 },
    { lat: 48.553524, lng: 17.016643 },
    { lat: 48.553532, lng: 17.016743 },
    { lat: 48.55354, lng: 17.016865 },
    { lat: 48.553558, lng: 17.017095 },
    { lat: 48.553576, lng: 17.017348 },
    { lat: 48.553595, lng: 17.017602 },
    { lat: 48.553611, lng: 17.01781 },
    { lat: 48.553627, lng: 17.018022 },
    { lat: 48.55364, lng: 17.018199 },
    { lat: 48.553655, lng: 17.018403 },
    { lat: 48.553662, lng: 17.018504 },
    { lat: 48.55367, lng: 17.018624 },
    { lat: 48.553676, lng: 17.018729 },
    { lat: 48.553684, lng: 17.01886 },
    { lat: 48.553686, lng: 17.018889 },
    { lat: 48.553688, lng: 17.018929 },
    { lat: 48.553697, lng: 17.019073 },
    { lat: 48.553707, lng: 17.019241 },
    { lat: 48.55373, lng: 17.0196 },
    { lat: 48.553751, lng: 17.019969 },
    { lat: 48.553765, lng: 17.020188 },
    { lat: 48.553775, lng: 17.020356 },
    { lat: 48.553779, lng: 17.020402 },
    { lat: 48.553779, lng: 17.020411 },
    { lat: 48.553782, lng: 17.020471 },
    { lat: 48.553823, lng: 17.021138 },
    { lat: 48.553844, lng: 17.021482 },
    { lat: 48.553854, lng: 17.02164 },
    { lat: 48.553859, lng: 17.021725 },
    { lat: 48.553865, lng: 17.021815 },
    { lat: 48.553875, lng: 17.021987 },
    { lat: 48.553886, lng: 17.022159 },
    { lat: 48.553892, lng: 17.022266 },
    { lat: 48.553903, lng: 17.022445 },
    { lat: 48.553909, lng: 17.022553 },
    { lat: 48.553922, lng: 17.022758 },
    { lat: 48.553935, lng: 17.022959 },
    { lat: 48.55394, lng: 17.023054 },
    { lat: 48.55394, lng: 17.023057 },
    { lat: 48.553946, lng: 17.023135 },
    { lat: 48.553957, lng: 17.023324 },
    { lat: 48.553974, lng: 17.023602 },
    { lat: 48.55398, lng: 17.023714 },
    { lat: 48.553987, lng: 17.023812 },
    { lat: 48.553998, lng: 17.023983 },
    { lat: 48.554002, lng: 17.024065 },
    { lat: 48.554011, lng: 17.024219 },
    { lat: 48.55402, lng: 17.024368 },
    { lat: 48.554057, lng: 17.024955 },
    { lat: 48.554066, lng: 17.025097 },
    { lat: 48.554074, lng: 17.025239 },
    { lat: 48.554086, lng: 17.025449 },
    { lat: 48.554098, lng: 17.02565 },
    { lat: 48.554123, lng: 17.026038 },
    { lat: 48.554131, lng: 17.02618 },
    { lat: 48.554145, lng: 17.0264 },
    { lat: 48.554158, lng: 17.026593 },
    { lat: 48.55418, lng: 17.026952 },
    { lat: 48.55424, lng: 17.02785 },
    { lat: 48.5543062, lng: 17.0289129 },
    { lat: 48.554319, lng: 17.029118 },
    { lat: 48.554327, lng: 17.029169 },
    { lat: 48.554356, lng: 17.029351 },
    { lat: 48.554969, lng: 17.033207 },
    { lat: 48.554978, lng: 17.033261 },
    { lat: 48.554988, lng: 17.03333 },
    { lat: 48.555715, lng: 17.037938 },
    { lat: 48.555729, lng: 17.038032 },
    { lat: 48.555732, lng: 17.038049 },
    { lat: 48.555583, lng: 17.041984 },
    { lat: 48.555579, lng: 17.042063 },
    { lat: 48.555567, lng: 17.042414 },
    { lat: 48.555553, lng: 17.042785 },
    { lat: 48.555538, lng: 17.04317 },
    { lat: 48.555524, lng: 17.043571 },
    { lat: 48.555508, lng: 17.043974 },
    { lat: 48.555491, lng: 17.044464 },
    { lat: 48.555465, lng: 17.045121 },
    { lat: 48.555441, lng: 17.045768 },
    { lat: 48.555441, lng: 17.045773 },
    { lat: 48.555433, lng: 17.045994 },
    { lat: 48.555422, lng: 17.046299 },
    { lat: 48.555415, lng: 17.046469 },
    { lat: 48.555408, lng: 17.04665 },
    { lat: 48.555395, lng: 17.046994 },
    { lat: 48.555382, lng: 17.047338 },
    { lat: 48.555369, lng: 17.047682 },
    { lat: 48.555355, lng: 17.048026 },
    { lat: 48.555341, lng: 17.048392 },
    { lat: 48.555327, lng: 17.048765 },
    { lat: 48.555312, lng: 17.049145 },
    { lat: 48.555297, lng: 17.049525 },
    { lat: 48.555281, lng: 17.049911 },
    { lat: 48.555264, lng: 17.050357 },
    { lat: 48.55525, lng: 17.050727 },
    { lat: 48.555243, lng: 17.050768 },
    { lat: 48.55524, lng: 17.050776 },
    { lat: 48.55523, lng: 17.0508 },
    { lat: 48.555214, lng: 17.050845 },
    { lat: 48.554297, lng: 17.049947 },
    { lat: 48.553879, lng: 17.049536 },
    { lat: 48.55384, lng: 17.049621 },
    { lat: 48.554042, lng: 17.050718 },
    { lat: 48.554075, lng: 17.050895 },
    { lat: 48.554106, lng: 17.051059 },
    { lat: 48.554846, lng: 17.054986 },
    { lat: 48.555214, lng: 17.056942 },
    { lat: 48.555554, lng: 17.058747 },
    { lat: 48.555588, lng: 17.058793 },
    { lat: 48.557515, lng: 17.055234 },
    { lat: 48.557552, lng: 17.055276 },
    { lat: 48.558894, lng: 17.05463 },
    { lat: 48.558927, lng: 17.054574 },
    { lat: 48.55974, lng: 17.055542 },
    { lat: 48.559937, lng: 17.055885 },
    { lat: 48.559983, lng: 17.055839 },
    { lat: 48.56163, lng: 17.054324 },
    { lat: 48.563816, lng: 17.05231 },
    { lat: 48.5638084, lng: 17.0523065 },
    { lat: 48.5634482, lng: 17.0517958 },
    { lat: 48.5661922, lng: 17.0474857 },
    { lat: 48.5661604, lng: 17.0465669 },
    { lat: 48.5661876, lng: 17.0463183 },
    { lat: 48.5660971, lng: 17.0462659 },
    { lat: 48.5660297, lng: 17.0460398 },
    { lat: 48.5659658, lng: 17.0460307 },
    { lat: 48.5658804, lng: 17.0458526 },
    { lat: 48.565384, lng: 17.0453322 },
    { lat: 48.5653565, lng: 17.0452266 },
    { lat: 48.5654076, lng: 17.0450664 },
    { lat: 48.5653161, lng: 17.0447997 },
    { lat: 48.5653388, lng: 17.0440352 },
    { lat: 48.5654633, lng: 17.0439707 },
    { lat: 48.5662033, lng: 17.0431695 },
    { lat: 48.5661601, lng: 17.042418 },
    { lat: 48.5661912, lng: 17.0422759 },
    { lat: 48.5663255, lng: 17.0420561 },
    { lat: 48.5665189, lng: 17.0413752 },
    { lat: 48.5665678, lng: 17.0410501 },
    { lat: 48.5666646, lng: 17.04091 },
    { lat: 48.5667361, lng: 17.0405985 },
    { lat: 48.5668998, lng: 17.040482 },
    { lat: 48.567039, lng: 17.0405329 },
    { lat: 48.5672551, lng: 17.0404868 },
    { lat: 48.5673548, lng: 17.0404068 },
    { lat: 48.5673669, lng: 17.0402005 },
    { lat: 48.5674284, lng: 17.0400601 },
    { lat: 48.5677887, lng: 17.0396588 },
    { lat: 48.5680795, lng: 17.0396133 },
    { lat: 48.5683499, lng: 17.0393868 },
    { lat: 48.5686972, lng: 17.039259 },
    { lat: 48.5689356, lng: 17.039259 },
    { lat: 48.569089, lng: 17.039387 },
    { lat: 48.5691733, lng: 17.0393797 },
    { lat: 48.5693675, lng: 17.0392102 },
    { lat: 48.5694909, lng: 17.039015 },
    { lat: 48.5707107, lng: 17.0394552 },
    { lat: 48.5709287, lng: 17.0384707 },
    { lat: 48.5717983, lng: 17.0388975 },
    { lat: 48.5720495, lng: 17.0393355 },
    { lat: 48.5721563, lng: 17.0394264 },
    { lat: 48.5728886, lng: 17.0399758 },
    { lat: 48.5734574, lng: 17.0401989 },
    { lat: 48.5737522, lng: 17.0404778 },
    { lat: 48.5748311, lng: 17.0418083 },
    { lat: 48.5749299, lng: 17.0417456 },
    { lat: 48.5750243, lng: 17.0417907 },
    { lat: 48.5759699, lng: 17.0424025 },
    { lat: 48.5762004, lng: 17.0430168 },
    { lat: 48.5763265, lng: 17.0430434 },
    { lat: 48.5765189, lng: 17.0433313 },
    { lat: 48.5766672, lng: 17.0434019 },
    { lat: 48.5769127, lng: 17.0437227 },
    { lat: 48.5777588, lng: 17.0434856 },
    { lat: 48.5782215, lng: 17.0431931 },
    { lat: 48.579048, lng: 17.0424015 },
    { lat: 48.5791855, lng: 17.0421948 },
    { lat: 48.5791595, lng: 17.0431941 },
    { lat: 48.5794755, lng: 17.0429887 },
    { lat: 48.5798361, lng: 17.0436544 },
    { lat: 48.581039, lng: 17.0429647 },
    { lat: 48.5811038, lng: 17.0430393 },
    { lat: 48.581444, lng: 17.0428401 },
    { lat: 48.5817914, lng: 17.0425475 },
    { lat: 48.5820235, lng: 17.0421362 },
    { lat: 48.582311, lng: 17.0425448 },
    { lat: 48.5829035, lng: 17.0429321 },
    { lat: 48.5833493, lng: 17.0431232 },
    { lat: 48.5833359, lng: 17.0431598 },
    { lat: 48.583714, lng: 17.0433196 },
    { lat: 48.5844718, lng: 17.043645 },
    { lat: 48.5847251, lng: 17.0431834 },
    { lat: 48.5849857, lng: 17.0424415 },
    { lat: 48.5851885, lng: 17.0420519 },
    { lat: 48.5857308, lng: 17.0411181 },
    { lat: 48.5866729, lng: 17.0398946 },
    { lat: 48.5872401, lng: 17.0386821 },
    { lat: 48.587737, lng: 17.0370938 },
    { lat: 48.587904, lng: 17.0362096 },
    { lat: 48.588389, lng: 17.0347842 },
    { lat: 48.5886968, lng: 17.0327753 },
    { lat: 48.5884535, lng: 17.0326875 },
    { lat: 48.5900792, lng: 17.0257309 },
    { lat: 48.5900498, lng: 17.0229605 },
    { lat: 48.5904552, lng: 17.0210598 },
    { lat: 48.591041, lng: 17.0190103 },
    { lat: 48.5922002, lng: 17.0134453 },
    { lat: 48.5923749, lng: 17.0094405 },
    { lat: 48.5927126, lng: 17.0073984 },
    { lat: 48.5929725, lng: 17.0068344 },
    { lat: 48.5932485, lng: 17.0051656 },
    { lat: 48.5933294, lng: 17.0048925 },
    { lat: 48.5938076, lng: 17.0040016 },
    { lat: 48.5942179, lng: 17.0022291 },
    { lat: 48.5944465, lng: 17.0015539 },
    { lat: 48.5944657, lng: 17.0013359 },
    { lat: 48.594514, lng: 17.000787 },
    { lat: 48.5944789, lng: 17.0004883 },
    { lat: 48.5942906, lng: 16.9998974 },
    { lat: 48.5944571, lng: 16.9953527 },
    { lat: 48.5944487, lng: 16.991835 },
    { lat: 48.5946522, lng: 16.9881823 },
    { lat: 48.5950073, lng: 16.9854225 },
    { lat: 48.5950691, lng: 16.984328 },
    { lat: 48.5948725, lng: 16.9843924 },
    { lat: 48.593648, lng: 16.9841032 },
    { lat: 48.5975276, lng: 16.9606733 },
    { lat: 48.5980313, lng: 16.9576313 },
    { lat: 48.598098, lng: 16.9576463 },
    { lat: 48.5997286, lng: 16.9497746 },
    { lat: 48.6012435, lng: 16.9435786 },
    { lat: 48.6028157, lng: 16.9385813 },
    { lat: 48.602959, lng: 16.937968 },
    { lat: 48.602808, lng: 16.93745 },
    { lat: 48.602719, lng: 16.937128 },
    { lat: 48.602616, lng: 16.936652 },
    { lat: 48.60256, lng: 16.93616 },
    { lat: 48.602466, lng: 16.93566 },
    { lat: 48.602405, lng: 16.935399 },
    { lat: 48.602305, lng: 16.935078 },
    { lat: 48.6022435, lng: 16.9348297 },
  ],
  Osuské: [
    { lat: 48.6213368, lng: 17.4637536 },
    { lat: 48.621354, lng: 17.4637204 },
    { lat: 48.621812, lng: 17.463307 },
    { lat: 48.6214975, lng: 17.4629834 },
    { lat: 48.6212389, lng: 17.4622979 },
    { lat: 48.6211264, lng: 17.4617895 },
    { lat: 48.6216788, lng: 17.4613887 },
    { lat: 48.6214661, lng: 17.4606062 },
    { lat: 48.6215689, lng: 17.4597991 },
    { lat: 48.6213926, lng: 17.4593904 },
    { lat: 48.6213771, lng: 17.4591856 },
    { lat: 48.6219715, lng: 17.458679 },
    { lat: 48.6225147, lng: 17.4583956 },
    { lat: 48.6236019, lng: 17.4609779 },
    { lat: 48.6237405, lng: 17.4618555 },
    { lat: 48.6241607, lng: 17.4634263 },
    { lat: 48.6245237, lng: 17.4653154 },
    { lat: 48.6246149, lng: 17.4656452 },
    { lat: 48.6248309, lng: 17.4661103 },
    { lat: 48.6256992, lng: 17.4650833 },
    { lat: 48.6262521, lng: 17.4645293 },
    { lat: 48.6277268, lng: 17.4638551 },
    { lat: 48.6279326, lng: 17.4647765 },
    { lat: 48.6281661, lng: 17.4665187 },
    { lat: 48.629531, lng: 17.4657512 },
    { lat: 48.6297292, lng: 17.4656902 },
    { lat: 48.6305446, lng: 17.4658276 },
    { lat: 48.6309386, lng: 17.4659858 },
    { lat: 48.6308237, lng: 17.4653199 },
    { lat: 48.6316106, lng: 17.465087 },
    { lat: 48.6318073, lng: 17.4662883 },
    { lat: 48.6331642, lng: 17.4656977 },
    { lat: 48.6338249, lng: 17.4678479 },
    { lat: 48.6339146, lng: 17.4678359 },
    { lat: 48.6338892, lng: 17.4710914 },
    { lat: 48.6342384, lng: 17.4711301 },
    { lat: 48.634452, lng: 17.4712393 },
    { lat: 48.6346805, lng: 17.4712089 },
    { lat: 48.6352205, lng: 17.4712816 },
    { lat: 48.6352863, lng: 17.4699805 },
    { lat: 48.6352415, lng: 17.4693507 },
    { lat: 48.6362616, lng: 17.4696219 },
    { lat: 48.6370194, lng: 17.4696221 },
    { lat: 48.6368295, lng: 17.4701629 },
    { lat: 48.6374476, lng: 17.4708395 },
    { lat: 48.6383885, lng: 17.4716183 },
    { lat: 48.638945, lng: 17.4712951 },
    { lat: 48.6392699, lng: 17.4725619 },
    { lat: 48.6406678, lng: 17.4717989 },
    { lat: 48.6409994, lng: 17.4726916 },
    { lat: 48.6414428, lng: 17.4723313 },
    { lat: 48.6420466, lng: 17.4719544 },
    { lat: 48.6421926, lng: 17.4727716 },
    { lat: 48.6425755, lng: 17.4734961 },
    { lat: 48.6429022, lng: 17.4736046 },
    { lat: 48.6436811, lng: 17.4744453 },
    { lat: 48.6450237, lng: 17.4760514 },
    { lat: 48.6452366, lng: 17.4755804 },
    { lat: 48.6455309, lng: 17.47515 },
    { lat: 48.6456985, lng: 17.4753864 },
    { lat: 48.6463962, lng: 17.475594 },
    { lat: 48.6468507, lng: 17.476081 },
    { lat: 48.6470871, lng: 17.476528 },
    { lat: 48.6481941, lng: 17.4776261 },
    { lat: 48.6484627, lng: 17.4780473 },
    { lat: 48.6485116, lng: 17.4779277 },
    { lat: 48.6491447, lng: 17.4772465 },
    { lat: 48.6501159, lng: 17.4763839 },
    { lat: 48.6512435, lng: 17.4756496 },
    { lat: 48.6517908, lng: 17.4754627 },
    { lat: 48.6516264, lng: 17.4743758 },
    { lat: 48.6531177, lng: 17.4749665 },
    { lat: 48.65316, lng: 17.4740213 },
    { lat: 48.6533139, lng: 17.4733895 },
    { lat: 48.6537069, lng: 17.4722088 },
    { lat: 48.654185, lng: 17.470167 },
    { lat: 48.6543471, lng: 17.4698047 },
    { lat: 48.6568372, lng: 17.4672461 },
    { lat: 48.6574227, lng: 17.4659811 },
    { lat: 48.6575369, lng: 17.4654357 },
    { lat: 48.6575305, lng: 17.4652662 },
    { lat: 48.6575951, lng: 17.4651479 },
    { lat: 48.6575911, lng: 17.4647487 },
    { lat: 48.6577195, lng: 17.4640377 },
    { lat: 48.6576682, lng: 17.4620207 },
    { lat: 48.657499, lng: 17.4601557 },
    { lat: 48.6578547, lng: 17.4577735 },
    { lat: 48.6580017, lng: 17.4570368 },
    { lat: 48.6587461, lng: 17.4543791 },
    { lat: 48.6587624, lng: 17.4539474 },
    { lat: 48.6589346, lng: 17.4530748 },
    { lat: 48.660149, lng: 17.449398 },
    { lat: 48.6604252, lng: 17.4475623 },
    { lat: 48.6606223, lng: 17.4457408 },
    { lat: 48.6606193, lng: 17.4431432 },
    { lat: 48.6610466, lng: 17.4402301 },
    { lat: 48.6611292, lng: 17.4396885 },
    { lat: 48.66081, lng: 17.4397684 },
    { lat: 48.6600955, lng: 17.4399156 },
    { lat: 48.6593916, lng: 17.440243 },
    { lat: 48.6593269, lng: 17.4405362 },
    { lat: 48.6593206, lng: 17.4414067 },
    { lat: 48.658848, lng: 17.4416616 },
    { lat: 48.6587822, lng: 17.4414658 },
    { lat: 48.6578505, lng: 17.4420824 },
    { lat: 48.657267, lng: 17.44235 },
    { lat: 48.6566266, lng: 17.4424752 },
    { lat: 48.6568831, lng: 17.441263 },
    { lat: 48.6568229, lng: 17.4409593 },
    { lat: 48.656583, lng: 17.4405296 },
    { lat: 48.6557739, lng: 17.4412414 },
    { lat: 48.6550824, lng: 17.4414681 },
    { lat: 48.6545175, lng: 17.4414832 },
    { lat: 48.6542242, lng: 17.4414197 },
    { lat: 48.6537039, lng: 17.4411405 },
    { lat: 48.653625, lng: 17.4416003 },
    { lat: 48.6533319, lng: 17.4418043 },
    { lat: 48.6532554, lng: 17.4422313 },
    { lat: 48.6524339, lng: 17.4420662 },
    { lat: 48.6522843, lng: 17.4419184 },
    { lat: 48.6519544, lng: 17.4418117 },
    { lat: 48.6519406, lng: 17.4411021 },
    { lat: 48.6508799, lng: 17.4404859 },
    { lat: 48.6510222, lng: 17.4400296 },
    { lat: 48.6508533, lng: 17.440034 },
    { lat: 48.6503464, lng: 17.4402164 },
    { lat: 48.6492539, lng: 17.440281 },
    { lat: 48.6482243, lng: 17.4407078 },
    { lat: 48.6481219, lng: 17.4400835 },
    { lat: 48.6485827, lng: 17.4393095 },
    { lat: 48.6479803, lng: 17.4386348 },
    { lat: 48.6473363, lng: 17.4377883 },
    { lat: 48.6476436, lng: 17.4374204 },
    { lat: 48.6477491, lng: 17.4368307 },
    { lat: 48.6481216, lng: 17.4360907 },
    { lat: 48.6484683, lng: 17.4358781 },
    { lat: 48.6485215, lng: 17.4356146 },
    { lat: 48.6486686, lng: 17.4354258 },
    { lat: 48.6489666, lng: 17.4351982 },
    { lat: 48.6493548, lng: 17.4346463 },
    { lat: 48.649553, lng: 17.4344605 },
    { lat: 48.6490442, lng: 17.4336306 },
    { lat: 48.6483021, lng: 17.432731 },
    { lat: 48.6473999, lng: 17.4337898 },
    { lat: 48.646775, lng: 17.4332217 },
    { lat: 48.6463354, lng: 17.4343054 },
    { lat: 48.6447581, lng: 17.4333143 },
    { lat: 48.644515, lng: 17.4338885 },
    { lat: 48.6435813, lng: 17.4328239 },
    { lat: 48.6433207, lng: 17.4324599 },
    { lat: 48.641996, lng: 17.4336398 },
    { lat: 48.6416042, lng: 17.4336393 },
    { lat: 48.6413348, lng: 17.4338766 },
    { lat: 48.6411052, lng: 17.4338906 },
    { lat: 48.6411094, lng: 17.4330258 },
    { lat: 48.6398404, lng: 17.4331888 },
    { lat: 48.6397956, lng: 17.4330225 },
    { lat: 48.639683, lng: 17.4330262 },
    { lat: 48.6396703, lng: 17.4329327 },
    { lat: 48.6395801, lng: 17.4329349 },
    { lat: 48.6395179, lng: 17.4318651 },
    { lat: 48.639484, lng: 17.4298642 },
    { lat: 48.6375413, lng: 17.4304071 },
    { lat: 48.6370458, lng: 17.430604 },
    { lat: 48.6364655, lng: 17.4309608 },
    { lat: 48.6363949, lng: 17.4310036 },
    { lat: 48.6361848, lng: 17.4312144 },
    { lat: 48.6359735, lng: 17.4304765 },
    { lat: 48.6351842, lng: 17.4306019 },
    { lat: 48.6335576, lng: 17.4304746 },
    { lat: 48.6338949, lng: 17.4323977 },
    { lat: 48.6329667, lng: 17.4329271 },
    { lat: 48.6325638, lng: 17.4330365 },
    { lat: 48.6324368, lng: 17.4310998 },
    { lat: 48.6319755, lng: 17.4311999 },
    { lat: 48.6318825, lng: 17.4311921 },
    { lat: 48.6318684, lng: 17.4311131 },
    { lat: 48.6313684, lng: 17.4310296 },
    { lat: 48.6280999, lng: 17.4316107 },
    { lat: 48.6274982, lng: 17.4314075 },
    { lat: 48.6271799, lng: 17.4314448 },
    { lat: 48.6271299, lng: 17.4301044 },
    { lat: 48.626284, lng: 17.4291933 },
    { lat: 48.6232635, lng: 17.4308908 },
    { lat: 48.6233041, lng: 17.4306796 },
    { lat: 48.6232453, lng: 17.4303612 },
    { lat: 48.6230244, lng: 17.4300373 },
    { lat: 48.6230568, lng: 17.4294706 },
    { lat: 48.62296, lng: 17.4290739 },
    { lat: 48.622181, lng: 17.4295169 },
    { lat: 48.6220837, lng: 17.4305825 },
    { lat: 48.6219952, lng: 17.4305758 },
    { lat: 48.6219924, lng: 17.4307593 },
    { lat: 48.6218868, lng: 17.4307705 },
    { lat: 48.6218626, lng: 17.4313577 },
    { lat: 48.621281, lng: 17.4314983 },
    { lat: 48.6215967, lng: 17.4321939 },
    { lat: 48.6206995, lng: 17.4330467 },
    { lat: 48.6206186, lng: 17.4332639 },
    { lat: 48.6204586, lng: 17.4332684 },
    { lat: 48.6204443, lng: 17.4337852 },
    { lat: 48.6202594, lng: 17.43428 },
    { lat: 48.6203267, lng: 17.4350469 },
    { lat: 48.6202668, lng: 17.4352045 },
    { lat: 48.6194803, lng: 17.435461 },
    { lat: 48.6194662, lng: 17.4360671 },
    { lat: 48.6196623, lng: 17.4364286 },
    { lat: 48.6196054, lng: 17.4368952 },
    { lat: 48.6191753, lng: 17.4369521 },
    { lat: 48.6189644, lng: 17.4373935 },
    { lat: 48.6187774, lng: 17.4376475 },
    { lat: 48.6187975, lng: 17.4378709 },
    { lat: 48.6182255, lng: 17.4379711 },
    { lat: 48.6182498, lng: 17.4382085 },
    { lat: 48.6183413, lng: 17.4384549 },
    { lat: 48.6182532, lng: 17.438737 },
    { lat: 48.6182096, lng: 17.4391624 },
    { lat: 48.6186068, lng: 17.4391239 },
    { lat: 48.6186095, lng: 17.4392681 },
    { lat: 48.6185434, lng: 17.4393299 },
    { lat: 48.6184322, lng: 17.4397099 },
    { lat: 48.6183145, lng: 17.4402858 },
    { lat: 48.6182694, lng: 17.4406747 },
    { lat: 48.6183266, lng: 17.4410522 },
    { lat: 48.6182297, lng: 17.4417053 },
    { lat: 48.6181959, lng: 17.4423575 },
    { lat: 48.6178506, lng: 17.4419939 },
    { lat: 48.6176757, lng: 17.4421551 },
    { lat: 48.6175858, lng: 17.442462 },
    { lat: 48.6176356, lng: 17.4433057 },
    { lat: 48.6174945, lng: 17.4437112 },
    { lat: 48.6175498, lng: 17.4439194 },
    { lat: 48.6174655, lng: 17.4444532 },
    { lat: 48.6174796, lng: 17.4452864 },
    { lat: 48.6175398, lng: 17.4456967 },
    { lat: 48.6174407, lng: 17.4459122 },
    { lat: 48.6174332, lng: 17.4460778 },
    { lat: 48.6176597, lng: 17.4468453 },
    { lat: 48.6178033, lng: 17.447021 },
    { lat: 48.6178979, lng: 17.4470336 },
    { lat: 48.6181202, lng: 17.4474191 },
    { lat: 48.6181172, lng: 17.4475306 },
    { lat: 48.617961, lng: 17.4476666 },
    { lat: 48.6178762, lng: 17.4478239 },
    { lat: 48.617752, lng: 17.4477185 },
    { lat: 48.6175906, lng: 17.4477276 },
    { lat: 48.6175557, lng: 17.4480878 },
    { lat: 48.6176531, lng: 17.4482713 },
    { lat: 48.6176814, lng: 17.4482543 },
    { lat: 48.6185333, lng: 17.4503679 },
    { lat: 48.6187426, lng: 17.4523655 },
    { lat: 48.6188541, lng: 17.4534291 },
    { lat: 48.6181425, lng: 17.4533228 },
    { lat: 48.6179984, lng: 17.4549126 },
    { lat: 48.6183752, lng: 17.4550136 },
    { lat: 48.6185894, lng: 17.4552197 },
    { lat: 48.6186942, lng: 17.4554072 },
    { lat: 48.6189905, lng: 17.4556245 },
    { lat: 48.619116, lng: 17.4556447 },
    { lat: 48.6182248, lng: 17.4569296 },
    { lat: 48.6183269, lng: 17.4571824 },
    { lat: 48.6186937, lng: 17.4586234 },
    { lat: 48.6188149, lng: 17.4589634 },
    { lat: 48.6190258, lng: 17.4593295 },
    { lat: 48.6194581, lng: 17.4599215 },
    { lat: 48.6202743, lng: 17.460058 },
    { lat: 48.6204205, lng: 17.4602597 },
    { lat: 48.6205956, lng: 17.4607718 },
    { lat: 48.6208163, lng: 17.4619397 },
    { lat: 48.6211816, lng: 17.4630948 },
    { lat: 48.6213368, lng: 17.4637536 },
  ],
  PlaveckýPeter: [
    { lat: 48.538639, lng: 17.271946 },
    { lat: 48.538612, lng: 17.272041 },
    { lat: 48.540997, lng: 17.274958 },
    { lat: 48.543507, lng: 17.279684 },
    { lat: 48.543459, lng: 17.279779 },
    { lat: 48.53852, lng: 17.288667 },
    { lat: 48.536347, lng: 17.300439 },
    { lat: 48.531761, lng: 17.308743 },
    { lat: 48.53228, lng: 17.309916 },
    { lat: 48.528996, lng: 17.311444 },
    { lat: 48.524975, lng: 17.313312 },
    { lat: 48.524956, lng: 17.313281 },
    { lat: 48.524823, lng: 17.313505 },
    { lat: 48.524618, lng: 17.313521 },
    { lat: 48.52443, lng: 17.313501 },
    { lat: 48.524245, lng: 17.313573 },
    { lat: 48.524119, lng: 17.313667 },
    { lat: 48.524041, lng: 17.313701 },
    { lat: 48.523602, lng: 17.313715 },
    { lat: 48.523167, lng: 17.313634 },
    { lat: 48.522997, lng: 17.313789 },
    { lat: 48.522935, lng: 17.313831 },
    { lat: 48.52287, lng: 17.31383 },
    { lat: 48.52275, lng: 17.313716 },
    { lat: 48.522673, lng: 17.31383 },
    { lat: 48.521834, lng: 17.315144 },
    { lat: 48.519981, lng: 17.319361 },
    { lat: 48.51826, lng: 17.322397 },
    { lat: 48.518064, lng: 17.322408 },
    { lat: 48.517914, lng: 17.322491 },
    { lat: 48.517527, lng: 17.32311 },
    { lat: 48.517307, lng: 17.323936 },
    { lat: 48.517413, lng: 17.324074 },
    { lat: 48.514822, lng: 17.326099 },
    { lat: 48.51468, lng: 17.326209 },
    { lat: 48.514684, lng: 17.32647 },
    { lat: 48.514789, lng: 17.326843 },
    { lat: 48.514835, lng: 17.327022 },
    { lat: 48.514875, lng: 17.32719 },
    { lat: 48.514872, lng: 17.327274 },
    { lat: 48.514975, lng: 17.327404 },
    { lat: 48.515058, lng: 17.327591 },
    { lat: 48.51513, lng: 17.327839 },
    { lat: 48.515224, lng: 17.327986 },
    { lat: 48.515338, lng: 17.328242 },
    { lat: 48.515391, lng: 17.32832 },
    { lat: 48.515445, lng: 17.328378 },
    { lat: 48.515526, lng: 17.328437 },
    { lat: 48.515535, lng: 17.328531 },
    { lat: 48.51547, lng: 17.328729 },
    { lat: 48.515352, lng: 17.328936 },
    { lat: 48.515393, lng: 17.329036 },
    { lat: 48.515485, lng: 17.329136 },
    { lat: 48.515544, lng: 17.32915 },
    { lat: 48.515658, lng: 17.329278 },
    { lat: 48.515685, lng: 17.329355 },
    { lat: 48.515788, lng: 17.329504 },
    { lat: 48.515795, lng: 17.32961 },
    { lat: 48.515721, lng: 17.330043 },
    { lat: 48.515606, lng: 17.330489 },
    { lat: 48.515497, lng: 17.330699 },
    { lat: 48.515664, lng: 17.33089 },
    { lat: 48.515687, lng: 17.331498 },
    { lat: 48.515723, lng: 17.331689 },
    { lat: 48.515739, lng: 17.33199 },
    { lat: 48.515881, lng: 17.332233 },
    { lat: 48.515839, lng: 17.332626 },
    { lat: 48.515855, lng: 17.332774 },
    { lat: 48.515702, lng: 17.333156 },
    { lat: 48.5157, lng: 17.333306 },
    { lat: 48.515725, lng: 17.333498 },
    { lat: 48.515773, lng: 17.333612 },
    { lat: 48.515648, lng: 17.334205 },
    { lat: 48.515807, lng: 17.334451 },
    { lat: 48.515681, lng: 17.335093 },
    { lat: 48.515284, lng: 17.335726 },
    { lat: 48.515326, lng: 17.335973 },
    { lat: 48.515293, lng: 17.33643 },
    { lat: 48.515144, lng: 17.336592 },
    { lat: 48.515133, lng: 17.336705 },
    { lat: 48.514757, lng: 17.336963 },
    { lat: 48.514601, lng: 17.337507 },
    { lat: 48.514181, lng: 17.338185 },
    { lat: 48.514073, lng: 17.338431 },
    { lat: 48.514017, lng: 17.338779 },
    { lat: 48.512367, lng: 17.334264 },
    { lat: 48.511833, lng: 17.334844 },
    { lat: 48.511783, lng: 17.335074 },
    { lat: 48.511883, lng: 17.335157 },
    { lat: 48.511895, lng: 17.33529 },
    { lat: 48.511389, lng: 17.335825 },
    { lat: 48.511325, lng: 17.336483 },
    { lat: 48.511239, lng: 17.336613 },
    { lat: 48.511023, lng: 17.337191 },
    { lat: 48.510781, lng: 17.337773 },
    { lat: 48.51064, lng: 17.338222 },
    { lat: 48.510662, lng: 17.338577 },
    { lat: 48.510616, lng: 17.338687 },
    { lat: 48.510507, lng: 17.339048 },
    { lat: 48.510091, lng: 17.339717 },
    { lat: 48.51003, lng: 17.339885 },
    { lat: 48.509756, lng: 17.340057 },
    { lat: 48.509507, lng: 17.340198 },
    { lat: 48.509243, lng: 17.340363 },
    { lat: 48.508971, lng: 17.340908 },
    { lat: 48.508851, lng: 17.341289 },
    { lat: 48.508726, lng: 17.341543 },
    { lat: 48.50864, lng: 17.34191 },
    { lat: 48.508474, lng: 17.34315 },
    { lat: 48.508519, lng: 17.343756 },
    { lat: 48.508525, lng: 17.344119 },
    { lat: 48.508674, lng: 17.344553 },
    { lat: 48.508895, lng: 17.345164 },
    { lat: 48.508879, lng: 17.345521 },
    { lat: 48.507804, lng: 17.346104 },
    { lat: 48.507813, lng: 17.346282 },
    { lat: 48.507709, lng: 17.346625 },
    { lat: 48.507396, lng: 17.348298 },
    { lat: 48.507303, lng: 17.348713 },
    { lat: 48.507208, lng: 17.349498 },
    { lat: 48.507149, lng: 17.34984 },
    { lat: 48.507181, lng: 17.350072 },
    { lat: 48.507127, lng: 17.350397 },
    { lat: 48.507117, lng: 17.350871 },
    { lat: 48.507174, lng: 17.35121 },
    { lat: 48.507169, lng: 17.351541 },
    { lat: 48.507191, lng: 17.351937 },
    { lat: 48.507166, lng: 17.352359 },
    { lat: 48.50715, lng: 17.353099 },
    { lat: 48.507032, lng: 17.353419 },
    { lat: 48.506878, lng: 17.354145 },
    { lat: 48.50673, lng: 17.3546 },
    { lat: 48.506645, lng: 17.354825 },
    { lat: 48.506589, lng: 17.355047 },
    { lat: 48.507185, lng: 17.355819 },
    { lat: 48.507706, lng: 17.356488 },
    { lat: 48.50815, lng: 17.357116 },
    { lat: 48.509011, lng: 17.358181 },
    { lat: 48.509662, lng: 17.358986 },
    { lat: 48.510299, lng: 17.359919 },
    { lat: 48.511019, lng: 17.360745 },
    { lat: 48.511864, lng: 17.360181 },
    { lat: 48.513432, lng: 17.360507 },
    { lat: 48.513168, lng: 17.359729 },
    { lat: 48.512806, lng: 17.358337 },
    { lat: 48.512453, lng: 17.357694 },
    { lat: 48.51221, lng: 17.356533 },
    { lat: 48.511691, lng: 17.355219 },
    { lat: 48.511912, lng: 17.353439 },
    { lat: 48.512183, lng: 17.351224 },
    { lat: 48.512245, lng: 17.350489 },
    { lat: 48.5123764, lng: 17.3491739 },
    { lat: 48.512395, lng: 17.348988 },
    { lat: 48.512496, lng: 17.347937 },
    { lat: 48.512517, lng: 17.347108 },
    { lat: 48.512579, lng: 17.346163 },
    { lat: 48.512576, lng: 17.346096 },
    { lat: 48.512615, lng: 17.345968 },
    { lat: 48.512703, lng: 17.34596 },
    { lat: 48.512812, lng: 17.34567 },
    { lat: 48.512979, lng: 17.345498 },
    { lat: 48.513101, lng: 17.345247 },
    { lat: 48.513176, lng: 17.345064 },
    { lat: 48.513291, lng: 17.344868 },
    { lat: 48.513332, lng: 17.344749 },
    { lat: 48.513314, lng: 17.344436 },
    { lat: 48.513335, lng: 17.344245 },
    { lat: 48.513181, lng: 17.344011 },
    { lat: 48.513281, lng: 17.343607 },
    { lat: 48.513384, lng: 17.343293 },
    { lat: 48.513352, lng: 17.343144 },
    { lat: 48.513322, lng: 17.342843 },
    { lat: 48.51346, lng: 17.342252 },
    { lat: 48.513527, lng: 17.342004 },
    { lat: 48.513619, lng: 17.34176 },
    { lat: 48.513759, lng: 17.341528 },
    { lat: 48.51382, lng: 17.341379 },
    { lat: 48.513775, lng: 17.341309 },
    { lat: 48.513719, lng: 17.341038 },
    { lat: 48.51364, lng: 17.340926 },
    { lat: 48.513606, lng: 17.340562 },
    { lat: 48.513626, lng: 17.340351 },
    { lat: 48.513644, lng: 17.339881 },
    { lat: 48.513714, lng: 17.339598 },
    { lat: 48.513755, lng: 17.339374 },
    { lat: 48.513951, lng: 17.339073 },
    { lat: 48.514024, lng: 17.339089 },
    { lat: 48.514203, lng: 17.339525 },
    { lat: 48.514353, lng: 17.339666 },
    { lat: 48.514367, lng: 17.34005 },
    { lat: 48.514245, lng: 17.340215 },
    { lat: 48.514371, lng: 17.340646 },
    { lat: 48.514567, lng: 17.340898 },
    { lat: 48.514658, lng: 17.341329 },
    { lat: 48.514772, lng: 17.342358 },
    { lat: 48.514587, lng: 17.342662 },
    { lat: 48.514577, lng: 17.342862 },
    { lat: 48.514612, lng: 17.343213 },
    { lat: 48.514576, lng: 17.34338 },
    { lat: 48.514591, lng: 17.343525 },
    { lat: 48.514643, lng: 17.343771 },
    { lat: 48.514825, lng: 17.344368 },
    { lat: 48.515004, lng: 17.344707 },
    { lat: 48.515217, lng: 17.344845 },
    { lat: 48.5156, lng: 17.345837 },
    { lat: 48.515843, lng: 17.346233 },
    { lat: 48.516084, lng: 17.346337 },
    { lat: 48.516194, lng: 17.34658 },
    { lat: 48.516528, lng: 17.346692 },
    { lat: 48.51662, lng: 17.34696 },
    { lat: 48.516632, lng: 17.347223 },
    { lat: 48.516466, lng: 17.347598 },
    { lat: 48.516464, lng: 17.347839 },
    { lat: 48.516372, lng: 17.348163 },
    { lat: 48.516447, lng: 17.348922 },
    { lat: 48.516629, lng: 17.34975 },
    { lat: 48.516642, lng: 17.350019 },
    { lat: 48.516742, lng: 17.350346 },
    { lat: 48.516784, lng: 17.35059 },
    { lat: 48.517027, lng: 17.350855 },
    { lat: 48.51711, lng: 17.351096 },
    { lat: 48.517413, lng: 17.35129 },
    { lat: 48.517513, lng: 17.351805 },
    { lat: 48.517533, lng: 17.352552 },
    { lat: 48.518143, lng: 17.352534 },
    { lat: 48.518737, lng: 17.35267 },
    { lat: 48.519161, lng: 17.352853 },
    { lat: 48.519867, lng: 17.353185 },
    { lat: 48.520018, lng: 17.353258 },
    { lat: 48.520177, lng: 17.353182 },
    { lat: 48.520485, lng: 17.353089 },
    { lat: 48.520781, lng: 17.353049 },
    { lat: 48.520932, lng: 17.35312 },
    { lat: 48.5211, lng: 17.35316 },
    { lat: 48.5213, lng: 17.353116 },
    { lat: 48.521506, lng: 17.352973 },
    { lat: 48.521745, lng: 17.352808 },
    { lat: 48.52187, lng: 17.352818 },
    { lat: 48.522932, lng: 17.352411 },
    { lat: 48.523738, lng: 17.35231 },
    { lat: 48.524232, lng: 17.352001 },
    { lat: 48.524658, lng: 17.351943 },
    { lat: 48.524853, lng: 17.352113 },
    { lat: 48.525368, lng: 17.352373 },
    { lat: 48.525422, lng: 17.352442 },
    { lat: 48.525733, lng: 17.352473 },
    { lat: 48.52591, lng: 17.352678 },
    { lat: 48.525962, lng: 17.352945 },
    { lat: 48.526182, lng: 17.353236 },
    { lat: 48.526471, lng: 17.353701 },
    { lat: 48.526645, lng: 17.353958 },
    { lat: 48.526834, lng: 17.354184 },
    { lat: 48.527067, lng: 17.354471 },
    { lat: 48.527225, lng: 17.354672 },
    { lat: 48.527382, lng: 17.354855 },
    { lat: 48.527576, lng: 17.354999 },
    { lat: 48.527718, lng: 17.354989 },
    { lat: 48.52783, lng: 17.354964 },
    { lat: 48.527982, lng: 17.354738 },
    { lat: 48.528147, lng: 17.354587 },
    { lat: 48.528391, lng: 17.354541 },
    { lat: 48.528652, lng: 17.354502 },
    { lat: 48.528747, lng: 17.354438 },
    { lat: 48.528875, lng: 17.354452 },
    { lat: 48.529119, lng: 17.354418 },
    { lat: 48.529278, lng: 17.354548 },
    { lat: 48.529402, lng: 17.354596 },
    { lat: 48.52949, lng: 17.354695 },
    { lat: 48.52943, lng: 17.354828 },
    { lat: 48.529746, lng: 17.355062 },
    { lat: 48.529847, lng: 17.354975 },
    { lat: 48.530038, lng: 17.354876 },
    { lat: 48.530188, lng: 17.354781 },
    { lat: 48.530324, lng: 17.354885 },
    { lat: 48.530535, lng: 17.354889 },
    { lat: 48.530744, lng: 17.354964 },
    { lat: 48.531137, lng: 17.354851 },
    { lat: 48.531579, lng: 17.354535 },
    { lat: 48.531694, lng: 17.354557 },
    { lat: 48.531699, lng: 17.354777 },
    { lat: 48.531852, lng: 17.354849 },
    { lat: 48.531977, lng: 17.354793 },
    { lat: 48.532005, lng: 17.35472 },
    { lat: 48.532047, lng: 17.354553 },
    { lat: 48.532163, lng: 17.354429 },
    { lat: 48.532267, lng: 17.354444 },
    { lat: 48.532396, lng: 17.354325 },
    { lat: 48.532504, lng: 17.354393 },
    { lat: 48.532583, lng: 17.354426 },
    { lat: 48.532632, lng: 17.354357 },
    { lat: 48.532698, lng: 17.354319 },
    { lat: 48.532726, lng: 17.354278 },
    { lat: 48.532753, lng: 17.354331 },
    { lat: 48.532871, lng: 17.354358 },
    { lat: 48.533008, lng: 17.354304 },
    { lat: 48.533179, lng: 17.354285 },
    { lat: 48.533744, lng: 17.354776 },
    { lat: 48.534365, lng: 17.355211 },
    { lat: 48.534533, lng: 17.35537 },
    { lat: 48.534799, lng: 17.355544 },
    { lat: 48.535185, lng: 17.355865 },
    { lat: 48.535327, lng: 17.355953 },
    { lat: 48.536123, lng: 17.356323 },
    { lat: 48.536326, lng: 17.356413 },
    { lat: 48.536376, lng: 17.356414 },
    { lat: 48.5362486, lng: 17.3561793 },
    { lat: 48.5361681, lng: 17.355601 },
    { lat: 48.5359029, lng: 17.3548946 },
    { lat: 48.5360198, lng: 17.354332 },
    { lat: 48.5360665, lng: 17.3543728 },
    { lat: 48.5362395, lng: 17.354132 },
    { lat: 48.5367397, lng: 17.3536931 },
    { lat: 48.5368857, lng: 17.3534248 },
    { lat: 48.5381944, lng: 17.3522544 },
    { lat: 48.5390175, lng: 17.3510695 },
    { lat: 48.5398645, lng: 17.3500104 },
    { lat: 48.5400038, lng: 17.3497032 },
    { lat: 48.5401668, lng: 17.3490845 },
    { lat: 48.5402588, lng: 17.3480197 },
    { lat: 48.5405283, lng: 17.3459352 },
    { lat: 48.5406865, lng: 17.3441698 },
    { lat: 48.540837, lng: 17.3436724 },
    { lat: 48.541248, lng: 17.3431667 },
    { lat: 48.5413775, lng: 17.3428844 },
    { lat: 48.5420635, lng: 17.3411585 },
    { lat: 48.5421485, lng: 17.340731 },
    { lat: 48.5421312, lng: 17.3403974 },
    { lat: 48.5422288, lng: 17.3397459 },
    { lat: 48.5421358, lng: 17.3391701 },
    { lat: 48.5421862, lng: 17.3388507 },
    { lat: 48.5427057, lng: 17.3377938 },
    { lat: 48.5429439, lng: 17.3379157 },
    { lat: 48.5432246, lng: 17.3377483 },
    { lat: 48.5433857, lng: 17.3377933 },
    { lat: 48.5435231, lng: 17.3377187 },
    { lat: 48.5437768, lng: 17.3377118 },
    { lat: 48.5441639, lng: 17.3372053 },
    { lat: 48.5445917, lng: 17.3363872 },
    { lat: 48.5447163, lng: 17.3362914 },
    { lat: 48.5446522, lng: 17.3358781 },
    { lat: 48.5448755, lng: 17.3356664 },
    { lat: 48.5448632, lng: 17.3355288 },
    { lat: 48.5447531, lng: 17.3354193 },
    { lat: 48.5446851, lng: 17.3351717 },
    { lat: 48.5447612, lng: 17.3350064 },
    { lat: 48.5447257, lng: 17.3348765 },
    { lat: 48.5447747, lng: 17.3347288 },
    { lat: 48.5446106, lng: 17.334531 },
    { lat: 48.5446263, lng: 17.3344089 },
    { lat: 48.5450173, lng: 17.3336704 },
    { lat: 48.5451595, lng: 17.3336151 },
    { lat: 48.5453324, lng: 17.3332544 },
    { lat: 48.5454288, lng: 17.3332579 },
    { lat: 48.5455362, lng: 17.3329493 },
    { lat: 48.5455332, lng: 17.3327298 },
    { lat: 48.5454143, lng: 17.3326377 },
    { lat: 48.545361, lng: 17.3323443 },
    { lat: 48.5452518, lng: 17.3321947 },
    { lat: 48.5452186, lng: 17.3320481 },
    { lat: 48.5452267, lng: 17.3318794 },
    { lat: 48.5453036, lng: 17.3317411 },
    { lat: 48.5454511, lng: 17.3316989 },
    { lat: 48.5454744, lng: 17.3315051 },
    { lat: 48.5455628, lng: 17.3314919 },
    { lat: 48.5458406, lng: 17.3310048 },
    { lat: 48.5459844, lng: 17.3310797 },
    { lat: 48.5460715, lng: 17.3308404 },
    { lat: 48.5462549, lng: 17.3307922 },
    { lat: 48.5464136, lng: 17.3305943 },
    { lat: 48.5465943, lng: 17.3305921 },
    { lat: 48.5469351, lng: 17.3302771 },
    { lat: 48.5472295, lng: 17.3299101 },
    { lat: 48.5474619, lng: 17.3300756 },
    { lat: 48.5475104, lng: 17.3298691 },
    { lat: 48.5475756, lng: 17.3297942 },
    { lat: 48.5476367, lng: 17.3292261 },
    { lat: 48.5476948, lng: 17.3290763 },
    { lat: 48.5478117, lng: 17.3289816 },
    { lat: 48.5477993, lng: 17.3288455 },
    { lat: 48.5479141, lng: 17.3286142 },
    { lat: 48.5479623, lng: 17.3285543 },
    { lat: 48.5483163, lng: 17.3285282 },
    { lat: 48.5483728, lng: 17.3283886 },
    { lat: 48.5487216, lng: 17.3281771 },
    { lat: 48.5490589, lng: 17.3275698 },
    { lat: 48.5490556, lng: 17.3274324 },
    { lat: 48.5491139, lng: 17.3272843 },
    { lat: 48.5493577, lng: 17.3269872 },
    { lat: 48.5494637, lng: 17.3265885 },
    { lat: 48.5498133, lng: 17.3257523 },
    { lat: 48.5501919, lng: 17.3254255 },
    { lat: 48.5504918, lng: 17.3248451 },
    { lat: 48.5505569, lng: 17.3248066 },
    { lat: 48.5506973, lng: 17.3242064 },
    { lat: 48.5507596, lng: 17.3240541 },
    { lat: 48.5508653, lng: 17.3239771 },
    { lat: 48.5508277, lng: 17.323782 },
    { lat: 48.550936, lng: 17.3236254 },
    { lat: 48.5508587, lng: 17.3232893 },
    { lat: 48.5511115, lng: 17.3222146 },
    { lat: 48.5512055, lng: 17.322032 },
    { lat: 48.5514235, lng: 17.321821 },
    { lat: 48.5514402, lng: 17.3216466 },
    { lat: 48.5513439, lng: 17.3212274 },
    { lat: 48.5514273, lng: 17.3208456 },
    { lat: 48.5515186, lng: 17.3207565 },
    { lat: 48.5516691, lng: 17.3203808 },
    { lat: 48.5517474, lng: 17.3202996 },
    { lat: 48.5517615, lng: 17.3201357 },
    { lat: 48.5516869, lng: 17.3198059 },
    { lat: 48.5516937, lng: 17.3193054 },
    { lat: 48.5517593, lng: 17.3187383 },
    { lat: 48.5516862, lng: 17.318551 },
    { lat: 48.5516987, lng: 17.3181384 },
    { lat: 48.5516146, lng: 17.3179933 },
    { lat: 48.5515986, lng: 17.3178027 },
    { lat: 48.5515042, lng: 17.3175861 },
    { lat: 48.5515978, lng: 17.3170641 },
    { lat: 48.5517742, lng: 17.3167566 },
    { lat: 48.5518791, lng: 17.3166668 },
    { lat: 48.5519709, lng: 17.3163444 },
    { lat: 48.5519306, lng: 17.3161454 },
    { lat: 48.5520156, lng: 17.3156828 },
    { lat: 48.552087, lng: 17.3155666 },
    { lat: 48.5520683, lng: 17.3152621 },
    { lat: 48.5521839, lng: 17.3151301 },
    { lat: 48.5524402, lng: 17.3144898 },
    { lat: 48.5527562, lng: 17.313918 },
    { lat: 48.5534497, lng: 17.3121209 },
    { lat: 48.553643, lng: 17.3117263 },
    { lat: 48.5542423, lng: 17.31079 },
    { lat: 48.5544432, lng: 17.3097706 },
    { lat: 48.5549258, lng: 17.3088231 },
    { lat: 48.5551127, lng: 17.3083336 },
    { lat: 48.5562722, lng: 17.307082 },
    { lat: 48.556325, lng: 17.306888 },
    { lat: 48.557714, lng: 17.3041763 },
    { lat: 48.557809, lng: 17.303997 },
    { lat: 48.555822, lng: 17.300468 },
    { lat: 48.55369, lng: 17.296625 },
    { lat: 48.552741, lng: 17.294912 },
    { lat: 48.552702, lng: 17.294841 },
    { lat: 48.551429, lng: 17.292537 },
    { lat: 48.551198, lng: 17.292092 },
    { lat: 48.550583, lng: 17.29095 },
    { lat: 48.549909, lng: 17.289707 },
    { lat: 48.548629, lng: 17.287363 },
    { lat: 48.548245, lng: 17.286572 },
    { lat: 48.548081, lng: 17.286026 },
    { lat: 48.548048, lng: 17.28592 },
    { lat: 48.547457, lng: 17.286918 },
    { lat: 48.547418, lng: 17.286984 },
    { lat: 48.543551, lng: 17.279602 },
    { lat: 48.541039, lng: 17.274886 },
    { lat: 48.538639, lng: 17.271946 },
  ],
  Kúty: [
    { lat: 48.686893, lng: 17.055128 },
    { lat: 48.687133, lng: 17.053854 },
    { lat: 48.687163, lng: 17.053696 },
    { lat: 48.687376, lng: 17.052542 },
    { lat: 48.687604, lng: 17.051283 },
    { lat: 48.687576, lng: 17.051195 },
    { lat: 48.687396, lng: 17.050646 },
    { lat: 48.687016, lng: 17.049487 },
    { lat: 48.686986, lng: 17.049427 },
    { lat: 48.686795, lng: 17.049049 },
    { lat: 48.686582, lng: 17.048625 },
    { lat: 48.686325, lng: 17.047654 },
    { lat: 48.685855, lng: 17.045874 },
    { lat: 48.685831, lng: 17.045783 },
    { lat: 48.685049, lng: 17.045092 },
    { lat: 48.68481, lng: 17.04488 },
    { lat: 48.684804, lng: 17.044875 },
    { lat: 48.684778, lng: 17.044858 },
    { lat: 48.684758, lng: 17.044846 },
    { lat: 48.684368, lng: 17.044591 },
    { lat: 48.68421, lng: 17.044489 },
    { lat: 48.684162, lng: 17.044456 },
    { lat: 48.683829, lng: 17.044231 },
    { lat: 48.683829, lng: 17.044232 },
    { lat: 48.683731, lng: 17.044165 },
    { lat: 48.682764, lng: 17.043747 },
    { lat: 48.681823, lng: 17.043333 },
    { lat: 48.681772, lng: 17.043292 },
    { lat: 48.68081, lng: 17.042474 },
    { lat: 48.680194, lng: 17.041939 },
    { lat: 48.679843, lng: 17.041638 },
    { lat: 48.679407, lng: 17.041176 },
    { lat: 48.679024, lng: 17.040764 },
    { lat: 48.679012, lng: 17.040744 },
    { lat: 48.678944, lng: 17.04062 },
    { lat: 48.678927, lng: 17.040588 },
    { lat: 48.678684, lng: 17.040133 },
    { lat: 48.678635, lng: 17.040044 },
    { lat: 48.678479, lng: 17.039763 },
    { lat: 48.678475, lng: 17.039751 },
    { lat: 48.678457, lng: 17.039708 },
    { lat: 48.678292, lng: 17.039305 },
    { lat: 48.678262, lng: 17.039232 },
    { lat: 48.678092, lng: 17.038813 },
    { lat: 48.678056, lng: 17.038724 },
    { lat: 48.678041, lng: 17.038688 },
    { lat: 48.677935, lng: 17.038433 },
    { lat: 48.677898, lng: 17.03834 },
    { lat: 48.677881, lng: 17.038299 },
    { lat: 48.677696, lng: 17.037831 },
    { lat: 48.677526, lng: 17.037411 },
    { lat: 48.67751, lng: 17.037374 },
    { lat: 48.677412, lng: 17.037133 },
    { lat: 48.677137, lng: 17.03645 },
    { lat: 48.677125, lng: 17.03642 },
    { lat: 48.677091, lng: 17.036335 },
    { lat: 48.67685, lng: 17.035732 },
    { lat: 48.676841, lng: 17.035708 },
    { lat: 48.676719, lng: 17.035389 },
    { lat: 48.676705, lng: 17.035352 },
    { lat: 48.676594, lng: 17.035058 },
    { lat: 48.676534, lng: 17.034828 },
    { lat: 48.676423, lng: 17.034489 },
    { lat: 48.676407, lng: 17.034444 },
    { lat: 48.67637, lng: 17.03433 },
    { lat: 48.676234, lng: 17.033914 },
    { lat: 48.676221, lng: 17.033875 },
    { lat: 48.676132, lng: 17.033603 },
    { lat: 48.676005, lng: 17.033197 },
    { lat: 48.675876, lng: 17.032884 },
    { lat: 48.675858, lng: 17.032839 },
    { lat: 48.675645, lng: 17.032229 },
    { lat: 48.675675, lng: 17.032077 },
    { lat: 48.675797, lng: 17.031469 },
    { lat: 48.675815, lng: 17.031379 },
    { lat: 48.675872, lng: 17.03111 },
    { lat: 48.675965, lng: 17.030818 },
    { lat: 48.676007, lng: 17.030691 },
    { lat: 48.67603, lng: 17.030625 },
    { lat: 48.676031, lng: 17.030624 },
    { lat: 48.675971, lng: 17.030262 },
    { lat: 48.675875, lng: 17.029828 },
    { lat: 48.675867, lng: 17.029796 },
    { lat: 48.675836, lng: 17.029651 },
    { lat: 48.675805, lng: 17.029112 },
    { lat: 48.675792, lng: 17.029004 },
    { lat: 48.675717, lng: 17.028353 },
    { lat: 48.675712, lng: 17.028315 },
    { lat: 48.675701, lng: 17.028211 },
    { lat: 48.675633, lng: 17.027595 },
    { lat: 48.675649, lng: 17.027241 },
    { lat: 48.67567, lng: 17.02664 },
    { lat: 48.67567, lng: 17.026605 },
    { lat: 48.675672, lng: 17.026471 },
    { lat: 48.675681, lng: 17.026435 },
    { lat: 48.6757, lng: 17.026365 },
    { lat: 48.67572, lng: 17.026289 },
    { lat: 48.675766, lng: 17.025937 },
    { lat: 48.675801, lng: 17.025809 },
    { lat: 48.675833, lng: 17.025636 },
    { lat: 48.675831, lng: 17.025437 },
    { lat: 48.67591, lng: 17.024912 },
    { lat: 48.675971, lng: 17.024587 },
    { lat: 48.676097, lng: 17.023929 },
    { lat: 48.676181, lng: 17.02351 },
    { lat: 48.676228, lng: 17.022873 },
    { lat: 48.676196, lng: 17.022856 },
    { lat: 48.676147, lng: 17.02283 },
    { lat: 48.675956, lng: 17.022729 },
    { lat: 48.67565, lng: 17.02257 },
    { lat: 48.675485, lng: 17.02248 },
    { lat: 48.67532, lng: 17.022403 },
    { lat: 48.675283, lng: 17.022373 },
    { lat: 48.675416, lng: 17.021833 },
    { lat: 48.67549, lng: 17.021528 },
    { lat: 48.675692, lng: 17.020703 },
    { lat: 48.675891, lng: 17.019897 },
    { lat: 48.676057, lng: 17.019224 },
    { lat: 48.676276, lng: 17.018341 },
    { lat: 48.676468, lng: 17.017571 },
    { lat: 48.676638, lng: 17.016873 },
    { lat: 48.676781, lng: 17.016297 },
    { lat: 48.676811, lng: 17.016175 },
    { lat: 48.676955, lng: 17.015595 },
    { lat: 48.677048, lng: 17.015204 },
    { lat: 48.677118, lng: 17.01491 },
    { lat: 48.67712, lng: 17.014909 },
    { lat: 48.677198, lng: 17.014688 },
    { lat: 48.677165, lng: 17.014663 },
    { lat: 48.676978, lng: 17.014553 },
    { lat: 48.676923, lng: 17.013668 },
    { lat: 48.6769, lng: 17.013015 },
    { lat: 48.676893, lng: 17.012718 },
    { lat: 48.676888, lng: 17.012586 },
    { lat: 48.676885, lng: 17.012454 },
    { lat: 48.676874, lng: 17.012059 },
    { lat: 48.676863, lng: 17.011585 },
    { lat: 48.676839, lng: 17.01113 },
    { lat: 48.676812, lng: 17.010866 },
    { lat: 48.676813, lng: 17.010866 },
    { lat: 48.676862, lng: 17.01086 },
    { lat: 48.676862, lng: 17.010859 },
    { lat: 48.676859, lng: 17.01081 },
    { lat: 48.67685, lng: 17.01081 },
    { lat: 48.676831, lng: 17.010498 },
    { lat: 48.676823, lng: 17.010041 },
    { lat: 48.676822, lng: 17.009403 },
    { lat: 48.676827, lng: 17.008765 },
    { lat: 48.67683, lng: 17.008569 },
    { lat: 48.676845, lng: 17.0078 },
    { lat: 48.676879, lng: 17.007267 },
    { lat: 48.67689, lng: 17.007085 },
    { lat: 48.676943, lng: 17.00635 },
    { lat: 48.676953, lng: 17.006254 },
    { lat: 48.676969, lng: 17.005962 },
    { lat: 48.676991, lng: 17.005585 },
    { lat: 48.677008, lng: 17.005344 },
    { lat: 48.67701, lng: 17.005148 },
    { lat: 48.677009, lng: 17.004936 },
    { lat: 48.677008, lng: 17.004885 },
    { lat: 48.677003, lng: 17.004886 },
    { lat: 48.676841, lng: 17.004918 },
    { lat: 48.676748, lng: 17.004926 },
    { lat: 48.676517, lng: 17.004936 },
    { lat: 48.676515, lng: 17.004908 },
    { lat: 48.676449, lng: 17.004258 },
    { lat: 48.67643, lng: 17.004049 },
    { lat: 48.676335, lng: 17.003237 },
    { lat: 48.676264, lng: 17.002668 },
    { lat: 48.676062, lng: 17.002692 },
    { lat: 48.676061, lng: 17.002692 },
    { lat: 48.676067, lng: 17.00283 },
    { lat: 48.676067, lng: 17.002831 },
    { lat: 48.675888, lng: 17.002856 },
    { lat: 48.675687, lng: 17.002868 },
    { lat: 48.67558, lng: 17.002418 },
    { lat: 48.675454, lng: 17.002394 },
    { lat: 48.675313, lng: 17.002396 },
    { lat: 48.675215, lng: 17.00215 },
    { lat: 48.675116, lng: 17.001887 },
    { lat: 48.675055, lng: 17.001725 },
    { lat: 48.675036, lng: 17.001672 },
    { lat: 48.674987, lng: 17.00155 },
    { lat: 48.674944, lng: 17.001416 },
    { lat: 48.67493, lng: 17.001323 },
    { lat: 48.674988, lng: 17.001147 },
    { lat: 48.675068, lng: 17.000952 },
    { lat: 48.675078, lng: 17.000928 },
    { lat: 48.675079, lng: 17.000925 },
    { lat: 48.674921, lng: 17.000939 },
    { lat: 48.674672, lng: 17.001 },
    { lat: 48.674671, lng: 17.001001 },
    { lat: 48.674684, lng: 17.001092 },
    { lat: 48.674669, lng: 17.00113 },
    { lat: 48.674643, lng: 17.001192 },
    { lat: 48.674644, lng: 17.001193 },
    { lat: 48.674583, lng: 17.001253 },
    { lat: 48.674456, lng: 17.001312 },
    { lat: 48.674361, lng: 17.000955 },
    { lat: 48.674316, lng: 17.000759 },
    { lat: 48.674316, lng: 17.000758 },
    { lat: 48.67438, lng: 17.000547 },
    { lat: 48.674373, lng: 17.000486 },
    { lat: 48.674349, lng: 17.000389 },
    { lat: 48.674317, lng: 17.000279 },
    { lat: 48.674191, lng: 16.999871 },
    { lat: 48.6741, lng: 16.999621 },
    { lat: 48.673997, lng: 16.99944 },
    { lat: 48.673997, lng: 16.999441 },
    { lat: 48.673904, lng: 16.99959 },
    { lat: 48.673868, lng: 16.999676 },
    { lat: 48.673835, lng: 16.999753 },
    { lat: 48.673818, lng: 16.999798 },
    { lat: 48.67378, lng: 16.999902 },
    { lat: 48.673634, lng: 17.000042 },
    { lat: 48.673522, lng: 16.999349 },
    { lat: 48.673524, lng: 16.999349 },
    { lat: 48.67364, lng: 16.999304 },
    { lat: 48.673656, lng: 16.999265 },
    { lat: 48.673661, lng: 16.999253 },
    { lat: 48.673665, lng: 16.999232 },
    { lat: 48.67367, lng: 16.999209 },
    { lat: 48.673683, lng: 16.999129 },
    { lat: 48.673688, lng: 16.998924 },
    { lat: 48.673651, lng: 16.998609 },
    { lat: 48.673584, lng: 16.99828 },
    { lat: 48.673476, lng: 16.998344 },
    { lat: 48.673427, lng: 16.998354 },
    { lat: 48.673394, lng: 16.998362 },
    { lat: 48.673387, lng: 16.998363 },
    { lat: 48.673386, lng: 16.998363 },
    { lat: 48.673312, lng: 16.998366 },
    { lat: 48.673231, lng: 16.998346 },
    { lat: 48.673167, lng: 16.99832 },
    { lat: 48.673125, lng: 16.998296 },
    { lat: 48.673083, lng: 16.998267 },
    { lat: 48.672904, lng: 16.998098 },
    { lat: 48.672791, lng: 16.998051 },
    { lat: 48.672736, lng: 16.997912 },
    { lat: 48.672689, lng: 16.99782 },
    { lat: 48.672623, lng: 16.997759 },
    { lat: 48.672576, lng: 16.997799 },
    { lat: 48.672557, lng: 16.99781 },
    { lat: 48.67251, lng: 16.997829 },
    { lat: 48.672481, lng: 16.997832 },
    { lat: 48.67248, lng: 16.997833 },
    { lat: 48.672469, lng: 16.997955 },
    { lat: 48.672464, lng: 16.99802 },
    { lat: 48.672464, lng: 16.998107 },
    { lat: 48.672464, lng: 16.998127 },
    { lat: 48.672464, lng: 16.99813 },
    { lat: 48.672476, lng: 16.998259 },
    { lat: 48.672543, lng: 16.99869 },
    { lat: 48.672532, lng: 16.998696 },
    { lat: 48.6725, lng: 16.998713 },
    { lat: 48.672436, lng: 16.998732 },
    { lat: 48.672389, lng: 16.998738 },
    { lat: 48.672318, lng: 16.99838 },
    { lat: 48.672075, lng: 16.997306 },
    { lat: 48.672193, lng: 16.997128 },
    { lat: 48.672196, lng: 16.997051 },
    { lat: 48.672189, lng: 16.996974 },
    { lat: 48.672159, lng: 16.996741 },
    { lat: 48.672082, lng: 16.99625 },
    { lat: 48.672005, lng: 16.995834 },
    { lat: 48.671877, lng: 16.995541 },
    { lat: 48.671832, lng: 16.995432 },
    { lat: 48.671628, lng: 16.995182 },
    { lat: 48.67155, lng: 16.995138 },
    { lat: 48.671503, lng: 16.995093 },
    { lat: 48.671379, lng: 16.994949 },
    { lat: 48.671373, lng: 16.994926 },
    { lat: 48.671485, lng: 16.994513 },
    { lat: 48.671566, lng: 16.994233 },
    { lat: 48.671587, lng: 16.994144 },
    { lat: 48.671426, lng: 16.994034 },
    { lat: 48.671273, lng: 16.993867 },
    { lat: 48.670967, lng: 16.993537 },
    { lat: 48.67096, lng: 16.993508 },
    { lat: 48.67097, lng: 16.99346 },
    { lat: 48.671058, lng: 16.992929 },
    { lat: 48.671087, lng: 16.992529 },
    { lat: 48.671104, lng: 16.991999 },
    { lat: 48.671255, lng: 16.990939 },
    { lat: 48.671228, lng: 16.990243 },
    { lat: 48.67123, lng: 16.990216 },
    { lat: 48.671228, lng: 16.990183 },
    { lat: 48.671254, lng: 16.989766 },
    { lat: 48.671271, lng: 16.989574 },
    { lat: 48.671321, lng: 16.988902 },
    { lat: 48.671364, lng: 16.988381 },
    { lat: 48.671423, lng: 16.987671 },
    { lat: 48.671447, lng: 16.987482 },
    { lat: 48.671454, lng: 16.987432 },
    { lat: 48.671491, lng: 16.987181 },
    { lat: 48.671503, lng: 16.987099 },
    { lat: 48.671507, lng: 16.987064 },
    { lat: 48.671632, lng: 16.987076 },
    { lat: 48.67188, lng: 16.987153 },
    { lat: 48.671923, lng: 16.987165 },
    { lat: 48.671963, lng: 16.98718 },
    { lat: 48.672002, lng: 16.987192 },
    { lat: 48.672033, lng: 16.987205 },
    { lat: 48.672172, lng: 16.987249 },
    { lat: 48.672221, lng: 16.987268 },
    { lat: 48.672205, lng: 16.987077 },
    { lat: 48.672201, lng: 16.987016 },
    { lat: 48.6722, lng: 16.986988 },
    { lat: 48.6722, lng: 16.986951 },
    { lat: 48.672198, lng: 16.986857 },
    { lat: 48.6722, lng: 16.986836 },
    { lat: 48.672223, lng: 16.986628 },
    { lat: 48.672239, lng: 16.986469 },
    { lat: 48.672262, lng: 16.986252 },
    { lat: 48.672265, lng: 16.986248 },
    { lat: 48.672377, lng: 16.986116 },
    { lat: 48.672534, lng: 16.986011 },
    { lat: 48.672831, lng: 16.985812 },
    { lat: 48.67285, lng: 16.985799 },
    { lat: 48.672886, lng: 16.98576 },
    { lat: 48.672931, lng: 16.985757 },
    { lat: 48.672933, lng: 16.985756 },
    { lat: 48.672934, lng: 16.985756 },
    { lat: 48.672946, lng: 16.985666 },
    { lat: 48.672949, lng: 16.985641 },
    { lat: 48.673229, lng: 16.985867 },
    { lat: 48.673459, lng: 16.985941 },
    { lat: 48.673753, lng: 16.986052 },
    { lat: 48.673972, lng: 16.986011 },
    { lat: 48.674347, lng: 16.986007 },
    { lat: 48.674466, lng: 16.986039 },
    { lat: 48.674866, lng: 16.986113 },
    { lat: 48.67504, lng: 16.98608 },
    { lat: 48.675272, lng: 16.986049 },
    { lat: 48.675392, lng: 16.986016 },
    { lat: 48.675585, lng: 16.985951 },
    { lat: 48.67562, lng: 16.985939 },
    { lat: 48.675626, lng: 16.985936 },
    { lat: 48.675702, lng: 16.985899 },
    { lat: 48.6757821, lng: 16.9858732 },
    { lat: 48.675932, lng: 16.985825 },
    { lat: 48.675926, lng: 16.985914 },
    { lat: 48.675944, lng: 16.985936 },
    { lat: 48.676165, lng: 16.985889 },
    { lat: 48.676248, lng: 16.985854 },
    { lat: 48.676356, lng: 16.985789 },
    { lat: 48.6764659, lng: 16.9857111 },
    { lat: 48.676541, lng: 16.985658 },
    { lat: 48.6766458, lng: 16.9855445 },
    { lat: 48.676747, lng: 16.985442 },
    { lat: 48.676813, lng: 16.985424 },
    { lat: 48.676831, lng: 16.985419 },
    { lat: 48.676872, lng: 16.985397 },
    { lat: 48.67693, lng: 16.985365 },
    { lat: 48.676967, lng: 16.985328 },
    { lat: 48.676998, lng: 16.985293 },
    { lat: 48.676998, lng: 16.985285 },
    { lat: 48.676982, lng: 16.985106 },
    { lat: 48.677049, lng: 16.984997 },
    { lat: 48.677064, lng: 16.984983 },
    { lat: 48.67732, lng: 16.98477 },
    { lat: 48.677415, lng: 16.984727 },
    { lat: 48.677526, lng: 16.984731 },
    { lat: 48.677536, lng: 16.984719 },
    { lat: 48.677722, lng: 16.984491 },
    { lat: 48.678009, lng: 16.984285 },
    { lat: 48.678013, lng: 16.984289 },
    { lat: 48.678073, lng: 16.984337 },
    { lat: 48.678158, lng: 16.984293 },
    { lat: 48.678389, lng: 16.984174 },
    { lat: 48.67854, lng: 16.98405 },
    { lat: 48.678652, lng: 16.98396 },
    { lat: 48.678706, lng: 16.983916 },
    { lat: 48.6787065, lng: 16.9838492 },
    { lat: 48.678715, lng: 16.983661 },
    { lat: 48.67881, lng: 16.983481 },
    { lat: 48.678809, lng: 16.983423 },
    { lat: 48.678808, lng: 16.98337 },
    { lat: 48.67885, lng: 16.983215 },
    { lat: 48.678834, lng: 16.98314 },
    { lat: 48.678771, lng: 16.983071 },
    { lat: 48.678666, lng: 16.983019 },
    { lat: 48.678548, lng: 16.982942 },
    { lat: 48.678433, lng: 16.982921 },
    { lat: 48.678624, lng: 16.982402 },
    { lat: 48.678848, lng: 16.981628 },
    { lat: 48.678873, lng: 16.98155 },
    { lat: 48.67897, lng: 16.981119 },
    { lat: 48.67902, lng: 16.980777 },
    { lat: 48.679036, lng: 16.980406 },
    { lat: 48.67903, lng: 16.980329 },
    { lat: 48.678959, lng: 16.979782 },
    { lat: 48.67891, lng: 16.979525 },
    { lat: 48.67883, lng: 16.979097 },
    { lat: 48.678712, lng: 16.978471 },
    { lat: 48.678682, lng: 16.978463 },
    { lat: 48.678408, lng: 16.978386 },
    { lat: 48.677758, lng: 16.978201 },
    { lat: 48.677302, lng: 16.978039 },
    { lat: 48.676855, lng: 16.97782 },
    { lat: 48.67644, lng: 16.977603 },
    { lat: 48.676158, lng: 16.977419 },
    { lat: 48.676019, lng: 16.977329 },
    { lat: 48.675585, lng: 16.976999 },
    { lat: 48.67509, lng: 16.976583 },
    { lat: 48.674705, lng: 16.976224 },
    { lat: 48.674358, lng: 16.975845 },
    { lat: 48.674269, lng: 16.975748 },
    { lat: 48.674246, lng: 16.975718 },
    { lat: 48.674067, lng: 16.975499 },
    { lat: 48.673927, lng: 16.975326 },
    { lat: 48.673652, lng: 16.97494 },
    { lat: 48.673332, lng: 16.974481 },
    { lat: 48.673087, lng: 16.974086 },
    { lat: 48.672308, lng: 16.97273 },
    { lat: 48.672284, lng: 16.972687 },
    { lat: 48.672033, lng: 16.972226 },
    { lat: 48.671919, lng: 16.972016 },
    { lat: 48.671894, lng: 16.971971 },
    { lat: 48.671669, lng: 16.971558 },
    { lat: 48.671465, lng: 16.971179 },
    { lat: 48.671402, lng: 16.971062 },
    { lat: 48.671396, lng: 16.971052 },
    { lat: 48.671245, lng: 16.97079 },
    { lat: 48.671157, lng: 16.970638 },
    { lat: 48.670893, lng: 16.970242 },
    { lat: 48.670627, lng: 16.969858 },
    { lat: 48.67033, lng: 16.969471 },
    { lat: 48.670054, lng: 16.969146 },
    { lat: 48.66972, lng: 16.968803 },
    { lat: 48.669342, lng: 16.968462 },
    { lat: 48.668941, lng: 16.968136 },
    { lat: 48.668566, lng: 16.967876 },
    { lat: 48.668171, lng: 16.967652 },
    { lat: 48.667841, lng: 16.967495 },
    { lat: 48.667441, lng: 16.967342 },
    { lat: 48.667043, lng: 16.967222 },
    { lat: 48.666691, lng: 16.967153 },
    { lat: 48.666244, lng: 16.967103 },
    { lat: 48.665842, lng: 16.967085 },
    { lat: 48.665396, lng: 16.967094 },
    { lat: 48.664881, lng: 16.967169 },
    { lat: 48.664335, lng: 16.967299 },
    { lat: 48.663828, lng: 16.967462 },
    { lat: 48.663262, lng: 16.967654 },
    { lat: 48.662278, lng: 16.968021 },
    { lat: 48.661754, lng: 16.968202 },
    { lat: 48.661249, lng: 16.968318 },
    { lat: 48.661015, lng: 16.968353 },
    { lat: 48.660868, lng: 16.968374 },
    { lat: 48.660431, lng: 16.968403 },
    { lat: 48.66034, lng: 16.968401 },
    { lat: 48.660099, lng: 16.968396 },
    { lat: 48.659688, lng: 16.968351 },
    { lat: 48.659302, lng: 16.968287 },
    { lat: 48.658908, lng: 16.968165 },
    { lat: 48.658422, lng: 16.967984 },
    { lat: 48.65781, lng: 16.967722 },
    { lat: 48.656629, lng: 16.967246 },
    { lat: 48.656084, lng: 16.967084 },
    { lat: 48.655517, lng: 16.96699 },
    { lat: 48.654967, lng: 16.96697 },
    { lat: 48.654285, lng: 16.96703 },
    { lat: 48.653701, lng: 16.967166 },
    { lat: 48.653048, lng: 16.967399 },
    { lat: 48.6521919, lng: 16.9677634 },
    { lat: 48.652099, lng: 16.967826 },
    { lat: 48.6512624, lng: 16.9681964 },
    { lat: 48.651202, lng: 16.968256 },
    { lat: 48.650607, lng: 16.968483 },
    { lat: 48.650078, lng: 16.968728 },
    { lat: 48.649626, lng: 16.968927 },
    { lat: 48.649424, lng: 16.969015 },
    { lat: 48.648935, lng: 16.969234 },
    { lat: 48.648826, lng: 16.969283 },
    { lat: 48.64865, lng: 16.969352 },
    { lat: 48.648515, lng: 16.969404 },
    { lat: 48.648263, lng: 16.96951 },
    { lat: 48.647988, lng: 16.969578 },
    { lat: 48.647745, lng: 16.969645 },
    { lat: 48.647665, lng: 16.969656 },
    { lat: 48.647238, lng: 16.969711 },
    { lat: 48.646603, lng: 16.969707 },
    { lat: 48.646349, lng: 16.969672 },
    { lat: 48.646103, lng: 16.969638 },
    { lat: 48.645999, lng: 16.969623 },
    { lat: 48.645465, lng: 16.969435 },
    { lat: 48.644944, lng: 16.969175 },
    { lat: 48.644422, lng: 16.968846 },
    { lat: 48.644129, lng: 16.968621 },
    { lat: 48.643945, lng: 16.968481 },
    { lat: 48.643937, lng: 16.968472 },
    { lat: 48.643535, lng: 16.96809 },
    { lat: 48.64316, lng: 16.967606 },
    { lat: 48.642736, lng: 16.967027 },
    { lat: 48.641989, lng: 16.96579 },
    { lat: 48.641534, lng: 16.965119 },
    { lat: 48.641036, lng: 16.964446 },
    { lat: 48.640655, lng: 16.964035 },
    { lat: 48.640073, lng: 16.963523 },
    { lat: 48.639589, lng: 16.96318 },
    { lat: 48.639131, lng: 16.962972 },
    { lat: 48.638586, lng: 16.962765 },
    { lat: 48.638047, lng: 16.962602 },
    { lat: 48.637561, lng: 16.962553 },
    { lat: 48.63751, lng: 16.962548 },
    { lat: 48.637352, lng: 16.962558 },
    { lat: 48.637015, lng: 16.96258 },
    { lat: 48.636498, lng: 16.962693 },
    { lat: 48.636472, lng: 16.962699 },
    { lat: 48.635944, lng: 16.962847 },
    { lat: 48.635804, lng: 16.962881 },
    { lat: 48.634814, lng: 16.963119 },
    { lat: 48.634467, lng: 16.963133 },
    { lat: 48.634041, lng: 16.963071 },
    { lat: 48.633676, lng: 16.962977 },
    { lat: 48.633095, lng: 16.962756 },
    { lat: 48.632738, lng: 16.962563 },
    { lat: 48.632718, lng: 16.962552 },
    { lat: 48.632661, lng: 16.962521 },
    { lat: 48.632574, lng: 16.962475 },
    { lat: 48.632505, lng: 16.962437 },
    { lat: 48.63232, lng: 16.962314 },
    { lat: 48.6322668, lng: 16.9623637 },
    { lat: 48.6317161, lng: 16.9630191 },
    { lat: 48.6314083, lng: 16.9636687 },
    { lat: 48.6311912, lng: 16.9644275 },
    { lat: 48.6310419, lng: 16.9657116 },
    { lat: 48.6315071, lng: 16.9659381 },
    { lat: 48.6316159, lng: 16.9661745 },
    { lat: 48.631693, lng: 16.9668052 },
    { lat: 48.6317709, lng: 16.9669192 },
    { lat: 48.6320812, lng: 16.9671113 },
    { lat: 48.6324422, lng: 16.9675452 },
    { lat: 48.6320173, lng: 16.9681416 },
    { lat: 48.6319442, lng: 16.9683781 },
    { lat: 48.6318932, lng: 16.9688208 },
    { lat: 48.6319756, lng: 16.9692496 },
    { lat: 48.6319114, lng: 16.9694972 },
    { lat: 48.6319219, lng: 16.9697335 },
    { lat: 48.6317914, lng: 16.9701554 },
    { lat: 48.6318846, lng: 16.9705457 },
    { lat: 48.6318188, lng: 16.9707046 },
    { lat: 48.6320472, lng: 16.9710524 },
    { lat: 48.6322505, lng: 16.9710857 },
    { lat: 48.6318574, lng: 16.9717504 },
    { lat: 48.6321245, lng: 16.9721171 },
    { lat: 48.6324736, lng: 16.9719017 },
    { lat: 48.6324907, lng: 16.9724149 },
    { lat: 48.6326991, lng: 16.9726851 },
    { lat: 48.6331457, lng: 16.9726223 },
    { lat: 48.6331493, lng: 16.9727929 },
    { lat: 48.6326524, lng: 16.973294 },
    { lat: 48.632817, lng: 16.9735506 },
    { lat: 48.6330834, lng: 16.9735945 },
    { lat: 48.6329108, lng: 16.9739616 },
    { lat: 48.6329623, lng: 16.9742686 },
    { lat: 48.6329042, lng: 16.9746096 },
    { lat: 48.6332041, lng: 16.9749302 },
    { lat: 48.6332801, lng: 16.9753975 },
    { lat: 48.6331555, lng: 16.9755073 },
    { lat: 48.6330657, lng: 16.9757796 },
    { lat: 48.6328108, lng: 16.975706 },
    { lat: 48.6324862, lng: 16.9758519 },
    { lat: 48.6323929, lng: 16.9760222 },
    { lat: 48.6321592, lng: 16.976109 },
    { lat: 48.6321029, lng: 16.9763107 },
    { lat: 48.6314221, lng: 16.9773609 },
    { lat: 48.6311366, lng: 16.9776494 },
    { lat: 48.6313966, lng: 16.9777675 },
    { lat: 48.6330644, lng: 16.9785216 },
    { lat: 48.6340422, lng: 16.9830913 },
    { lat: 48.6340827, lng: 16.9834159 },
    { lat: 48.6340133, lng: 16.9841241 },
    { lat: 48.633678, lng: 16.9853678 },
    { lat: 48.6336466, lng: 16.9857342 },
    { lat: 48.6338591, lng: 16.9875069 },
    { lat: 48.6338879, lng: 16.9886225 },
    { lat: 48.634045, lng: 16.9894831 },
    { lat: 48.6346333, lng: 16.9914128 },
    { lat: 48.6348574, lng: 16.9919665 },
    { lat: 48.6349813, lng: 16.9927058 },
    { lat: 48.6348882, lng: 16.9941379 },
    { lat: 48.6347188, lng: 16.9949706 },
    { lat: 48.6346658, lng: 16.9951447 },
    { lat: 48.6343718, lng: 16.9961107 },
    { lat: 48.6343662, lng: 16.9970642 },
    { lat: 48.6343581, lng: 16.99845 },
    { lat: 48.6343569, lng: 16.9986593 },
    { lat: 48.6347292, lng: 17.0013697 },
    { lat: 48.6353677, lng: 17.0032572 },
    { lat: 48.6357309, lng: 17.0039962 },
    { lat: 48.636279, lng: 17.0046765 },
    { lat: 48.6372031, lng: 17.0055847 },
    { lat: 48.6375697, lng: 17.0061209 },
    { lat: 48.6378003, lng: 17.0063042 },
    { lat: 48.6380832, lng: 17.0069256 },
    { lat: 48.6382166, lng: 17.007423 },
    { lat: 48.638332, lng: 17.0083873 },
    { lat: 48.6382555, lng: 17.0119108 },
    { lat: 48.6383853, lng: 17.0145497 },
    { lat: 48.6384093, lng: 17.0180253 },
    { lat: 48.6382805, lng: 17.0204349 },
    { lat: 48.6381758, lng: 17.021539 },
    { lat: 48.638183, lng: 17.0219266 },
    { lat: 48.638482, lng: 17.0246119 },
    { lat: 48.6384655, lng: 17.0249024 },
    { lat: 48.6383875, lng: 17.0252668 },
    { lat: 48.6381534, lng: 17.0259297 },
    { lat: 48.6378744, lng: 17.0265148 },
    { lat: 48.6374692, lng: 17.027187 },
    { lat: 48.6371862, lng: 17.02808 },
    { lat: 48.6371403, lng: 17.0283001 },
    { lat: 48.6371288, lng: 17.0285627 },
    { lat: 48.6372073, lng: 17.0288532 },
    { lat: 48.6374415, lng: 17.0294751 },
    { lat: 48.6376537, lng: 17.0303844 },
    { lat: 48.6378925, lng: 17.0310253 },
    { lat: 48.6384799, lng: 17.0331168 },
    { lat: 48.6395831, lng: 17.036037 },
    { lat: 48.6400892, lng: 17.0372479 },
    { lat: 48.6402704, lng: 17.0378216 },
    { lat: 48.6408956, lng: 17.0389613 },
    { lat: 48.6410556, lng: 17.0397038 },
    { lat: 48.6410848, lng: 17.0402006 },
    { lat: 48.6410428, lng: 17.040614 },
    { lat: 48.6412316, lng: 17.0407653 },
    { lat: 48.6415662, lng: 17.040898 },
    { lat: 48.6416094, lng: 17.0412358 },
    { lat: 48.6415875, lng: 17.0416987 },
    { lat: 48.641546, lng: 17.0418023 },
    { lat: 48.6412544, lng: 17.0418837 },
    { lat: 48.6412977, lng: 17.042572 },
    { lat: 48.6415459, lng: 17.0431168 },
    { lat: 48.6419193, lng: 17.0435573 },
    { lat: 48.642136, lng: 17.0440044 },
    { lat: 48.6423031, lng: 17.0441852 },
    { lat: 48.6424134, lng: 17.0445447 },
    { lat: 48.642732, lng: 17.0446621 },
    { lat: 48.6436855, lng: 17.0443735 },
    { lat: 48.6438943, lng: 17.0451391 },
    { lat: 48.6438634, lng: 17.0452489 },
    { lat: 48.6437388, lng: 17.0453593 },
    { lat: 48.6439737, lng: 17.0455771 },
    { lat: 48.6442611, lng: 17.0455481 },
    { lat: 48.6446973, lng: 17.0456802 },
    { lat: 48.6449137, lng: 17.0454917 },
    { lat: 48.6454972, lng: 17.0457129 },
    { lat: 48.6455521, lng: 17.045841 },
    { lat: 48.6455607, lng: 17.046034 },
    { lat: 48.6458286, lng: 17.0464512 },
    { lat: 48.6458442, lng: 17.0465495 },
    { lat: 48.64555, lng: 17.0473264 },
    { lat: 48.6453417, lng: 17.0475554 },
    { lat: 48.6460351, lng: 17.0472805 },
    { lat: 48.6467533, lng: 17.0471229 },
    { lat: 48.647154, lng: 17.0469006 },
    { lat: 48.6472398, lng: 17.0468834 },
    { lat: 48.6472802, lng: 17.047127 },
    { lat: 48.6474101, lng: 17.0471434 },
    { lat: 48.6480376, lng: 17.0469819 },
    { lat: 48.6480852, lng: 17.0464014 },
    { lat: 48.6484512, lng: 17.0465657 },
    { lat: 48.6485176, lng: 17.0466956 },
    { lat: 48.6484962, lng: 17.0469819 },
    { lat: 48.6486248, lng: 17.0471418 },
    { lat: 48.6488337, lng: 17.0471389 },
    { lat: 48.6489476, lng: 17.0472057 },
    { lat: 48.6490453, lng: 17.0474282 },
    { lat: 48.6488702, lng: 17.0476779 },
    { lat: 48.6488762, lng: 17.0478688 },
    { lat: 48.6489592, lng: 17.0479268 },
    { lat: 48.6488749, lng: 17.0480338 },
    { lat: 48.6486167, lng: 17.0481587 },
    { lat: 48.6483757, lng: 17.0485193 },
    { lat: 48.6483828, lng: 17.0490092 },
    { lat: 48.6485134, lng: 17.0495354 },
    { lat: 48.6488655, lng: 17.0499971 },
    { lat: 48.6492167, lng: 17.0502106 },
    { lat: 48.6494546, lng: 17.0501357 },
    { lat: 48.6496645, lng: 17.0505997 },
    { lat: 48.6497682, lng: 17.0511455 },
    { lat: 48.649819, lng: 17.0511955 },
    { lat: 48.6499766, lng: 17.0511877 },
    { lat: 48.6501467, lng: 17.0510281 },
    { lat: 48.650295, lng: 17.0512493 },
    { lat: 48.6503981, lng: 17.0510852 },
    { lat: 48.6504765, lng: 17.0511599 },
    { lat: 48.6506172, lng: 17.051636 },
    { lat: 48.6509556, lng: 17.0518108 },
    { lat: 48.6509955, lng: 17.0519318 },
    { lat: 48.6509486, lng: 17.0520395 },
    { lat: 48.6510804, lng: 17.0524337 },
    { lat: 48.6512734, lng: 17.052514 },
    { lat: 48.6514053, lng: 17.0525259 },
    { lat: 48.651514, lng: 17.052245 },
    { lat: 48.6516763, lng: 17.0520724 },
    { lat: 48.6519565, lng: 17.0522236 },
    { lat: 48.6521034, lng: 17.0526409 },
    { lat: 48.6522668, lng: 17.0526175 },
    { lat: 48.6524389, lng: 17.0527878 },
    { lat: 48.6526745, lng: 17.0534313 },
    { lat: 48.6526169, lng: 17.0545016 },
    { lat: 48.6534273, lng: 17.0546701 },
    { lat: 48.6534638, lng: 17.0551608 },
    { lat: 48.6540846, lng: 17.055216 },
    { lat: 48.6545435, lng: 17.0551838 },
    { lat: 48.6551328, lng: 17.0550262 },
    { lat: 48.655535, lng: 17.0551922 },
    { lat: 48.6553585, lng: 17.0557887 },
    { lat: 48.6553712, lng: 17.0565798 },
    { lat: 48.6555043, lng: 17.0567931 },
    { lat: 48.6558992, lng: 17.0570692 },
    { lat: 48.6556815, lng: 17.0579734 },
    { lat: 48.657099, lng: 17.0585488 },
    { lat: 48.6574094, lng: 17.0567068 },
    { lat: 48.6575019, lng: 17.0558515 },
    { lat: 48.6575517, lng: 17.0558524 },
    { lat: 48.6576309, lng: 17.0554728 },
    { lat: 48.6577647, lng: 17.0544936 },
    { lat: 48.6590782, lng: 17.0546331 },
    { lat: 48.6592642, lng: 17.0536237 },
    { lat: 48.6600576, lng: 17.0543788 },
    { lat: 48.6604862, lng: 17.0535818 },
    { lat: 48.6619508, lng: 17.0513457 },
    { lat: 48.6624077, lng: 17.0526357 },
    { lat: 48.6624909, lng: 17.0527626 },
    { lat: 48.6635749, lng: 17.0523849 },
    { lat: 48.6642171, lng: 17.0524238 },
    { lat: 48.6643919, lng: 17.0522521 },
    { lat: 48.6645999, lng: 17.0518028 },
    { lat: 48.6646858, lng: 17.0519698 },
    { lat: 48.6653342, lng: 17.0514319 },
    { lat: 48.6660831, lng: 17.0514768 },
    { lat: 48.6662758, lng: 17.0517874 },
    { lat: 48.6665442, lng: 17.0514921 },
    { lat: 48.6678934, lng: 17.0500075 },
    { lat: 48.6688062, lng: 17.0491052 },
    { lat: 48.6691472, lng: 17.0494834 },
    { lat: 48.6696139, lng: 17.0489062 },
    { lat: 48.6704363, lng: 17.0489337 },
    { lat: 48.6712126, lng: 17.048564 },
    { lat: 48.6722208, lng: 17.0479204 },
    { lat: 48.6728997, lng: 17.0472314 },
    { lat: 48.673197, lng: 17.0468176 },
    { lat: 48.6731626, lng: 17.0465748 },
    { lat: 48.6733153, lng: 17.0463618 },
    { lat: 48.6730648, lng: 17.0458649 },
    { lat: 48.6730343, lng: 17.0457151 },
    { lat: 48.673804, lng: 17.0445666 },
    { lat: 48.675215, lng: 17.0445936 },
    { lat: 48.6754701, lng: 17.0446726 },
    { lat: 48.6757357, lng: 17.0449898 },
    { lat: 48.6771109, lng: 17.0487961 },
    { lat: 48.6787962, lng: 17.049402 },
    { lat: 48.6793656, lng: 17.0495161 },
    { lat: 48.6798701, lng: 17.0500426 },
    { lat: 48.6801297, lng: 17.0500551 },
    { lat: 48.6806112, lng: 17.050225 },
    { lat: 48.6809161, lng: 17.0504218 },
    { lat: 48.6823027, lng: 17.050992 },
    { lat: 48.6829353, lng: 17.0509552 },
    { lat: 48.6835766, lng: 17.0511316 },
    { lat: 48.6839136, lng: 17.0513178 },
    { lat: 48.6843152, lng: 17.0512755 },
    { lat: 48.6844872, lng: 17.0514939 },
    { lat: 48.6842716, lng: 17.0522488 },
    { lat: 48.6868473, lng: 17.0550625 },
    { lat: 48.686893, lng: 17.055128 },
  ],
  Kuklov: [
    { lat: 48.6371862, lng: 17.02808 },
    { lat: 48.6353662, lng: 17.0288677 },
    { lat: 48.6353929, lng: 17.0283956 },
    { lat: 48.6345184, lng: 17.0293303 },
    { lat: 48.6340729, lng: 17.0296754 },
    { lat: 48.6340786, lng: 17.029573 },
    { lat: 48.632597, lng: 17.030757 },
    { lat: 48.6314875, lng: 17.0314911 },
    { lat: 48.630808, lng: 17.0329675 },
    { lat: 48.6308046, lng: 17.0337486 },
    { lat: 48.6298371, lng: 17.0382088 },
    { lat: 48.6283034, lng: 17.0435009 },
    { lat: 48.6277926, lng: 17.0457683 },
    { lat: 48.6277093, lng: 17.0466734 },
    { lat: 48.6274805, lng: 17.0474349 },
    { lat: 48.6273486, lng: 17.0477214 },
    { lat: 48.6272232, lng: 17.0478547 },
    { lat: 48.6273259, lng: 17.0481542 },
    { lat: 48.62691, lng: 17.0487367 },
    { lat: 48.627137, lng: 17.0492182 },
    { lat: 48.6263757, lng: 17.0525596 },
    { lat: 48.6267552, lng: 17.0534405 },
    { lat: 48.6267396, lng: 17.0537266 },
    { lat: 48.625616, lng: 17.0552406 },
    { lat: 48.6257888, lng: 17.0557926 },
    { lat: 48.6258729, lng: 17.0563319 },
    { lat: 48.6260993, lng: 17.0584773 },
    { lat: 48.625175, lng: 17.0591086 },
    { lat: 48.6253243, lng: 17.0596292 },
    { lat: 48.6256144, lng: 17.0610115 },
    { lat: 48.6258055, lng: 17.0621991 },
    { lat: 48.6258098, lng: 17.0623459 },
    { lat: 48.6256463, lng: 17.0625193 },
    { lat: 48.6258163, lng: 17.0635619 },
    { lat: 48.6243999, lng: 17.0650581 },
    { lat: 48.6234729, lng: 17.0664076 },
    { lat: 48.6231511, lng: 17.0655428 },
    { lat: 48.6229355, lng: 17.0658102 },
    { lat: 48.6227671, lng: 17.0654101 },
    { lat: 48.6219609, lng: 17.0664238 },
    { lat: 48.6218734, lng: 17.0662454 },
    { lat: 48.6216022, lng: 17.0665632 },
    { lat: 48.6211647, lng: 17.0672305 },
    { lat: 48.621473, lng: 17.0680821 },
    { lat: 48.6204734, lng: 17.070259 },
    { lat: 48.6202406, lng: 17.0706434 },
    { lat: 48.6198061, lng: 17.0717223 },
    { lat: 48.6187817, lng: 17.0730508 },
    { lat: 48.6183755, lng: 17.0734446 },
    { lat: 48.6178975, lng: 17.0737438 },
    { lat: 48.6168366, lng: 17.0747692 },
    { lat: 48.616148, lng: 17.0758482 },
    { lat: 48.6154662, lng: 17.0771977 },
    { lat: 48.6148667, lng: 17.0782433 },
    { lat: 48.6143505, lng: 17.0793153 },
    { lat: 48.6138413, lng: 17.0812321 },
    { lat: 48.6117334, lng: 17.0833157 },
    { lat: 48.6103691, lng: 17.0845341 },
    { lat: 48.6074611, lng: 17.087506 },
    { lat: 48.6063049, lng: 17.0893771 },
    { lat: 48.6054315, lng: 17.0903161 },
    { lat: 48.6050178, lng: 17.0909025 },
    { lat: 48.6042318, lng: 17.0918306 },
    { lat: 48.6032906, lng: 17.0940755 },
    { lat: 48.602199, lng: 17.0970612 },
    { lat: 48.6014133, lng: 17.1002592 },
    { lat: 48.6014677, lng: 17.1012858 },
    { lat: 48.6008063, lng: 17.1037636 },
    { lat: 48.6008525, lng: 17.1037958 },
    { lat: 48.6012519, lng: 17.1037357 },
    { lat: 48.6018087, lng: 17.1037655 },
    { lat: 48.6020276, lng: 17.1040319 },
    { lat: 48.6021294, lng: 17.1043655 },
    { lat: 48.6023359, lng: 17.1047326 },
    { lat: 48.6025353, lng: 17.1049431 },
    { lat: 48.602749, lng: 17.1050118 },
    { lat: 48.6028683, lng: 17.105156 },
    { lat: 48.6034132, lng: 17.10452 },
    { lat: 48.6040213, lng: 17.104036 },
    { lat: 48.6043016, lng: 17.1042748 },
    { lat: 48.6050808, lng: 17.1045869 },
    { lat: 48.6055851, lng: 17.1050788 },
    { lat: 48.60634, lng: 17.1054496 },
    { lat: 48.6072466, lng: 17.106698 },
    { lat: 48.6076443, lng: 17.1068492 },
    { lat: 48.6085051, lng: 17.1068615 },
    { lat: 48.6102429, lng: 17.1070148 },
    { lat: 48.6110957, lng: 17.1077492 },
    { lat: 48.6113247, lng: 17.1085661 },
    { lat: 48.6113478, lng: 17.1094127 },
    { lat: 48.6115094, lng: 17.1102528 },
    { lat: 48.6115538, lng: 17.1117029 },
    { lat: 48.6119734, lng: 17.1128017 },
    { lat: 48.6123764, lng: 17.113351 },
    { lat: 48.6127817, lng: 17.1141368 },
    { lat: 48.6132645, lng: 17.1148982 },
    { lat: 48.6135911, lng: 17.1152689 },
    { lat: 48.6142069, lng: 17.1163789 },
    { lat: 48.6148158, lng: 17.1171489 },
    { lat: 48.6154133, lng: 17.1181243 },
    { lat: 48.6156382, lng: 17.118257 },
    { lat: 48.6158575, lng: 17.1181239 },
    { lat: 48.6162327, lng: 17.1181441 },
    { lat: 48.6170791, lng: 17.1184151 },
    { lat: 48.6177336, lng: 17.1184183 },
    { lat: 48.6182474, lng: 17.118327 },
    { lat: 48.6184831, lng: 17.118171 },
    { lat: 48.6188419, lng: 17.1176073 },
    { lat: 48.6190632, lng: 17.1173878 },
    { lat: 48.6200175, lng: 17.1168577 },
    { lat: 48.6215814, lng: 17.1162866 },
    { lat: 48.62268, lng: 17.1162087 },
    { lat: 48.6241467, lng: 17.1155364 },
    { lat: 48.625092, lng: 17.1151933 },
    { lat: 48.6255491, lng: 17.1151031 },
    { lat: 48.6258909, lng: 17.1149195 },
    { lat: 48.6263409, lng: 17.1146778 },
    { lat: 48.6269749, lng: 17.1145101 },
    { lat: 48.6275827, lng: 17.114074 },
    { lat: 48.6281731, lng: 17.1134594 },
    { lat: 48.6284035, lng: 17.1133213 },
    { lat: 48.6294048, lng: 17.1131732 },
    { lat: 48.630553, lng: 17.1133784 },
    { lat: 48.6309119, lng: 17.1128677 },
    { lat: 48.6310249, lng: 17.1128683 },
    { lat: 48.6327085, lng: 17.1119936 },
    { lat: 48.6334259, lng: 17.11391 },
    { lat: 48.6359666, lng: 17.1114971 },
    { lat: 48.6360187, lng: 17.1114337 },
    { lat: 48.6359578, lng: 17.1110494 },
    { lat: 48.6360823, lng: 17.110985 },
    { lat: 48.6362072, lng: 17.1106736 },
    { lat: 48.6368247, lng: 17.1102119 },
    { lat: 48.6371626, lng: 17.1100116 },
    { lat: 48.6372522, lng: 17.1100311 },
    { lat: 48.6373973, lng: 17.1096853 },
    { lat: 48.6377055, lng: 17.1085385 },
    { lat: 48.6380023, lng: 17.1082127 },
    { lat: 48.6380957, lng: 17.1079222 },
    { lat: 48.6381171, lng: 17.1075495 },
    { lat: 48.6380456, lng: 17.1072786 },
    { lat: 48.6380816, lng: 17.1069558 },
    { lat: 48.6383966, lng: 17.1063646 },
    { lat: 48.6385708, lng: 17.1062161 },
    { lat: 48.6388149, lng: 17.1051916 },
    { lat: 48.6387336, lng: 17.1048058 },
    { lat: 48.6386247, lng: 17.1046974 },
    { lat: 48.6385348, lng: 17.1043189 },
    { lat: 48.6385846, lng: 17.1039497 },
    { lat: 48.6387479, lng: 17.1037228 },
    { lat: 48.6396251, lng: 17.103546 },
    { lat: 48.6397091, lng: 17.1033845 },
    { lat: 48.6397664, lng: 17.1027927 },
    { lat: 48.6396433, lng: 17.1025225 },
    { lat: 48.6396405, lng: 17.102414 },
    { lat: 48.6399321, lng: 17.101793 },
    { lat: 48.6401167, lng: 17.1015976 },
    { lat: 48.6405779, lng: 17.1014673 },
    { lat: 48.6406222, lng: 17.1011682 },
    { lat: 48.6409642, lng: 17.10086 },
    { lat: 48.6410289, lng: 17.1010991 },
    { lat: 48.6411418, lng: 17.1010154 },
    { lat: 48.64106, lng: 17.1007064 },
    { lat: 48.6412958, lng: 17.0997771 },
    { lat: 48.6414059, lng: 17.0997173 },
    { lat: 48.6417632, lng: 17.1002603 },
    { lat: 48.6418366, lng: 17.1002072 },
    { lat: 48.6418735, lng: 17.0997636 },
    { lat: 48.6419181, lng: 17.0997217 },
    { lat: 48.6421264, lng: 17.0998442 },
    { lat: 48.6422426, lng: 17.1000121 },
    { lat: 48.6423275, lng: 17.0999788 },
    { lat: 48.6425761, lng: 17.0996098 },
    { lat: 48.6427429, lng: 17.0995364 },
    { lat: 48.6427007, lng: 17.0992617 },
    { lat: 48.6424245, lng: 17.0991196 },
    { lat: 48.6424557, lng: 17.0987954 },
    { lat: 48.6423071, lng: 17.0987014 },
    { lat: 48.6423777, lng: 17.0984415 },
    { lat: 48.6426999, lng: 17.0986444 },
    { lat: 48.6430274, lng: 17.0983563 },
    { lat: 48.6434695, lng: 17.0987301 },
    { lat: 48.6435505, lng: 17.0987274 },
    { lat: 48.6436819, lng: 17.0984453 },
    { lat: 48.6436208, lng: 17.0982768 },
    { lat: 48.6436561, lng: 17.0981295 },
    { lat: 48.6438248, lng: 17.0981694 },
    { lat: 48.6438796, lng: 17.0983965 },
    { lat: 48.6440419, lng: 17.0987432 },
    { lat: 48.6441929, lng: 17.0987175 },
    { lat: 48.6450536, lng: 17.0988822 },
    { lat: 48.6451382, lng: 17.0989121 },
    { lat: 48.6458966, lng: 17.0893203 },
    { lat: 48.6459457, lng: 17.0887728 },
    { lat: 48.6460758, lng: 17.0885067 },
    { lat: 48.6459933, lng: 17.0879547 },
    { lat: 48.6460017, lng: 17.0877234 },
    { lat: 48.6457502, lng: 17.0876494 },
    { lat: 48.6458359, lng: 17.0866593 },
    { lat: 48.6457307, lng: 17.0856139 },
    { lat: 48.6457372, lng: 17.0850569 },
    { lat: 48.6459809, lng: 17.0849709 },
    { lat: 48.6461179, lng: 17.0830621 },
    { lat: 48.6462462, lng: 17.0823835 },
    { lat: 48.6458949, lng: 17.0822031 },
    { lat: 48.6459249, lng: 17.0819776 },
    { lat: 48.6459616, lng: 17.081753 },
    { lat: 48.646224, lng: 17.0810681 },
    { lat: 48.6462997, lng: 17.0809164 },
    { lat: 48.6463842, lng: 17.080947 },
    { lat: 48.6465011, lng: 17.0796298 },
    { lat: 48.646175, lng: 17.0795212 },
    { lat: 48.6462729, lng: 17.0789291 },
    { lat: 48.6464396, lng: 17.0785505 },
    { lat: 48.6463361, lng: 17.077722 },
    { lat: 48.6465992, lng: 17.0775862 },
    { lat: 48.647212, lng: 17.0755463 },
    { lat: 48.6472284, lng: 17.075155 },
    { lat: 48.6471492, lng: 17.0748024 },
    { lat: 48.6474548, lng: 17.0737527 },
    { lat: 48.6470019, lng: 17.0734378 },
    { lat: 48.6470073, lng: 17.0725151 },
    { lat: 48.6479179, lng: 17.071392 },
    { lat: 48.6485112, lng: 17.0718107 },
    { lat: 48.6488072, lng: 17.0718599 },
    { lat: 48.6493691, lng: 17.0710438 },
    { lat: 48.6491115, lng: 17.0706621 },
    { lat: 48.6489643, lng: 17.0701866 },
    { lat: 48.6488935, lng: 17.0694924 },
    { lat: 48.6487769, lng: 17.0692213 },
    { lat: 48.6479787, lng: 17.0688452 },
    { lat: 48.6477549, lng: 17.0682421 },
    { lat: 48.6477036, lng: 17.0678023 },
    { lat: 48.6479607, lng: 17.0670577 },
    { lat: 48.6479186, lng: 17.0669362 },
    { lat: 48.6476138, lng: 17.0668406 },
    { lat: 48.6474859, lng: 17.0660519 },
    { lat: 48.6477127, lng: 17.0655779 },
    { lat: 48.6476962, lng: 17.0654671 },
    { lat: 48.6477645, lng: 17.0654584 },
    { lat: 48.6475176, lng: 17.063694 },
    { lat: 48.6477253, lng: 17.0633232 },
    { lat: 48.647735, lng: 17.0634175 },
    { lat: 48.6485724, lng: 17.0636184 },
    { lat: 48.6491929, lng: 17.0636694 },
    { lat: 48.6494276, lng: 17.063614 },
    { lat: 48.6496107, lng: 17.0633135 },
    { lat: 48.6495628, lng: 17.0628402 },
    { lat: 48.650133, lng: 17.0625988 },
    { lat: 48.6504357, lng: 17.0623792 },
    { lat: 48.6506889, lng: 17.0620647 },
    { lat: 48.6507766, lng: 17.0613726 },
    { lat: 48.6509476, lng: 17.0605754 },
    { lat: 48.6507301, lng: 17.0602687 },
    { lat: 48.6505852, lng: 17.0602124 },
    { lat: 48.6501942, lng: 17.0602472 },
    { lat: 48.6495993, lng: 17.0604557 },
    { lat: 48.6493017, lng: 17.0598651 },
    { lat: 48.6489821, lng: 17.0597185 },
    { lat: 48.6489184, lng: 17.0595968 },
    { lat: 48.6484498, lng: 17.0580277 },
    { lat: 48.6486122, lng: 17.0567877 },
    { lat: 48.649018, lng: 17.0558221 },
    { lat: 48.6494507, lng: 17.0562039 },
    { lat: 48.649709, lng: 17.0558957 },
    { lat: 48.6509554, lng: 17.0548277 },
    { lat: 48.6510331, lng: 17.0534567 },
    { lat: 48.6511759, lng: 17.052726 },
    { lat: 48.6512734, lng: 17.052514 },
    { lat: 48.6510804, lng: 17.0524337 },
    { lat: 48.6509486, lng: 17.0520395 },
    { lat: 48.6509955, lng: 17.0519318 },
    { lat: 48.6509556, lng: 17.0518108 },
    { lat: 48.6506172, lng: 17.051636 },
    { lat: 48.6504765, lng: 17.0511599 },
    { lat: 48.6503981, lng: 17.0510852 },
    { lat: 48.650295, lng: 17.0512493 },
    { lat: 48.6501467, lng: 17.0510281 },
    { lat: 48.6499766, lng: 17.0511877 },
    { lat: 48.649819, lng: 17.0511955 },
    { lat: 48.6497682, lng: 17.0511455 },
    { lat: 48.6496645, lng: 17.0505997 },
    { lat: 48.6494546, lng: 17.0501357 },
    { lat: 48.6492167, lng: 17.0502106 },
    { lat: 48.6488655, lng: 17.0499971 },
    { lat: 48.6485134, lng: 17.0495354 },
    { lat: 48.6483828, lng: 17.0490092 },
    { lat: 48.6483757, lng: 17.0485193 },
    { lat: 48.6486167, lng: 17.0481587 },
    { lat: 48.6488749, lng: 17.0480338 },
    { lat: 48.6489592, lng: 17.0479268 },
    { lat: 48.6488762, lng: 17.0478688 },
    { lat: 48.6488702, lng: 17.0476779 },
    { lat: 48.6490453, lng: 17.0474282 },
    { lat: 48.6489476, lng: 17.0472057 },
    { lat: 48.6488337, lng: 17.0471389 },
    { lat: 48.6486248, lng: 17.0471418 },
    { lat: 48.6484962, lng: 17.0469819 },
    { lat: 48.6485176, lng: 17.0466956 },
    { lat: 48.6484512, lng: 17.0465657 },
    { lat: 48.6480852, lng: 17.0464014 },
    { lat: 48.6480376, lng: 17.0469819 },
    { lat: 48.6474101, lng: 17.0471434 },
    { lat: 48.6472802, lng: 17.047127 },
    { lat: 48.6472398, lng: 17.0468834 },
    { lat: 48.647154, lng: 17.0469006 },
    { lat: 48.6467533, lng: 17.0471229 },
    { lat: 48.6460351, lng: 17.0472805 },
    { lat: 48.6453417, lng: 17.0475554 },
    { lat: 48.64555, lng: 17.0473264 },
    { lat: 48.6458442, lng: 17.0465495 },
    { lat: 48.6458286, lng: 17.0464512 },
    { lat: 48.6455607, lng: 17.046034 },
    { lat: 48.6455521, lng: 17.045841 },
    { lat: 48.6454972, lng: 17.0457129 },
    { lat: 48.6449137, lng: 17.0454917 },
    { lat: 48.6446973, lng: 17.0456802 },
    { lat: 48.6442611, lng: 17.0455481 },
    { lat: 48.6439737, lng: 17.0455771 },
    { lat: 48.6437388, lng: 17.0453593 },
    { lat: 48.6438634, lng: 17.0452489 },
    { lat: 48.6438943, lng: 17.0451391 },
    { lat: 48.6436855, lng: 17.0443735 },
    { lat: 48.642732, lng: 17.0446621 },
    { lat: 48.6424134, lng: 17.0445447 },
    { lat: 48.6423031, lng: 17.0441852 },
    { lat: 48.642136, lng: 17.0440044 },
    { lat: 48.6419193, lng: 17.0435573 },
    { lat: 48.6415459, lng: 17.0431168 },
    { lat: 48.6412977, lng: 17.042572 },
    { lat: 48.6412544, lng: 17.0418837 },
    { lat: 48.641546, lng: 17.0418023 },
    { lat: 48.6415875, lng: 17.0416987 },
    { lat: 48.6416094, lng: 17.0412358 },
    { lat: 48.6415662, lng: 17.040898 },
    { lat: 48.6412316, lng: 17.0407653 },
    { lat: 48.6410428, lng: 17.040614 },
    { lat: 48.6410848, lng: 17.0402006 },
    { lat: 48.6410556, lng: 17.0397038 },
    { lat: 48.6408956, lng: 17.0389613 },
    { lat: 48.6402704, lng: 17.0378216 },
    { lat: 48.6400892, lng: 17.0372479 },
    { lat: 48.6395831, lng: 17.036037 },
    { lat: 48.6384799, lng: 17.0331168 },
    { lat: 48.6378925, lng: 17.0310253 },
    { lat: 48.6376537, lng: 17.0303844 },
    { lat: 48.6374415, lng: 17.0294751 },
    { lat: 48.6372073, lng: 17.0288532 },
    { lat: 48.6371288, lng: 17.0285627 },
    { lat: 48.6371403, lng: 17.0283001 },
    { lat: 48.6371862, lng: 17.02808 },
  ],
  Koválov: [
    { lat: 48.734503, lng: 17.290613 },
    { lat: 48.734422, lng: 17.290437 },
    { lat: 48.734189, lng: 17.289765 },
    { lat: 48.734063, lng: 17.289379 },
    { lat: 48.733999, lng: 17.289139 },
    { lat: 48.733953, lng: 17.288963 },
    { lat: 48.733937, lng: 17.288386 },
    { lat: 48.733955, lng: 17.287961 },
    { lat: 48.73397, lng: 17.287408 },
    { lat: 48.733908, lng: 17.286832 },
    { lat: 48.73389, lng: 17.286825 },
    { lat: 48.733954, lng: 17.286347 },
    { lat: 48.733955, lng: 17.28566 },
    { lat: 48.733955, lng: 17.285179 },
    { lat: 48.733925, lng: 17.284889 },
    { lat: 48.733915, lng: 17.284774 },
    { lat: 48.733894, lng: 17.284638 },
    { lat: 48.733874, lng: 17.284528 },
    { lat: 48.733895, lng: 17.284137 },
    { lat: 48.733894, lng: 17.28372 },
    { lat: 48.733795, lng: 17.283073 },
    { lat: 48.733734, lng: 17.282805 },
    { lat: 48.733694, lng: 17.282644 },
    { lat: 48.733597, lng: 17.282331 },
    { lat: 48.733506, lng: 17.281876 },
    { lat: 48.733407, lng: 17.281554 },
    { lat: 48.733337, lng: 17.281335 },
    { lat: 48.733276, lng: 17.281119 },
    { lat: 48.733251, lng: 17.280816 },
    { lat: 48.733169, lng: 17.280411 },
    { lat: 48.732987, lng: 17.279462 },
    { lat: 48.732878, lng: 17.279015 },
    { lat: 48.732731, lng: 17.278396 },
    { lat: 48.732596, lng: 17.277883 },
    { lat: 48.7324501, lng: 17.2771211 },
    { lat: 48.7326801, lng: 17.2758576 },
    { lat: 48.7326556, lng: 17.2756116 },
    { lat: 48.7326086, lng: 17.2752516 },
    { lat: 48.732554, lng: 17.275011 },
    { lat: 48.732405, lng: 17.274454 },
    { lat: 48.732298, lng: 17.274017 },
    { lat: 48.732266, lng: 17.273717 },
    { lat: 48.732209, lng: 17.273424 },
    { lat: 48.732124, lng: 17.273164 },
    { lat: 48.732063, lng: 17.272892 },
    { lat: 48.732086, lng: 17.272888 },
    { lat: 48.731994, lng: 17.272608 },
    { lat: 48.731888, lng: 17.272254 },
    { lat: 48.731851, lng: 17.272053 },
    { lat: 48.731657, lng: 17.271376 },
    { lat: 48.731601, lng: 17.270982 },
    { lat: 48.731404, lng: 17.270012 },
    { lat: 48.73136, lng: 17.269862 },
    { lat: 48.731247, lng: 17.269482 },
    { lat: 48.731154, lng: 17.269166 },
    { lat: 48.731139, lng: 17.26915 },
    { lat: 48.730921, lng: 17.268588 },
    { lat: 48.730873, lng: 17.26845 },
    { lat: 48.73085, lng: 17.268373 },
    { lat: 48.730835, lng: 17.267677 },
    { lat: 48.730758, lng: 17.267324 },
    { lat: 48.730738, lng: 17.267081 },
    { lat: 48.730709, lng: 17.266325 },
    { lat: 48.730691, lng: 17.265937 },
    { lat: 48.730627, lng: 17.265633 },
    { lat: 48.730591, lng: 17.265329 },
    { lat: 48.730536, lng: 17.265164 },
    { lat: 48.730455, lng: 17.265016 },
    { lat: 48.730376, lng: 17.264871 },
    { lat: 48.730289, lng: 17.264754 },
    { lat: 48.730177, lng: 17.264628 },
    { lat: 48.730092, lng: 17.264437 },
    { lat: 48.729955, lng: 17.264239 },
    { lat: 48.729755, lng: 17.263832 },
    { lat: 48.72961, lng: 17.263468 },
    { lat: 48.729559, lng: 17.263334 },
    { lat: 48.729508, lng: 17.26318 },
    { lat: 48.729425, lng: 17.262712 },
    { lat: 48.729389, lng: 17.262538 },
    { lat: 48.729322, lng: 17.262274 },
    { lat: 48.729213, lng: 17.261901 },
    { lat: 48.72906, lng: 17.261456 },
    { lat: 48.728953, lng: 17.261202 },
    { lat: 48.72877, lng: 17.260907 },
    { lat: 48.728705, lng: 17.260767 },
    { lat: 48.728594, lng: 17.260574 },
    { lat: 48.728413, lng: 17.260327 },
    { lat: 48.72829, lng: 17.260043 },
    { lat: 48.728245, lng: 17.259874 },
    { lat: 48.728172, lng: 17.259154 },
    { lat: 48.728164, lng: 17.258879 },
    { lat: 48.728216, lng: 17.258568 },
    { lat: 48.728276, lng: 17.258244 },
    { lat: 48.728352, lng: 17.258042 },
    { lat: 48.72843, lng: 17.257767 },
    { lat: 48.728555, lng: 17.257141 },
    { lat: 48.728562, lng: 17.256814 },
    { lat: 48.728606, lng: 17.2562 },
    { lat: 48.728564, lng: 17.255607 },
    { lat: 48.728591, lng: 17.255427 },
    { lat: 48.728681, lng: 17.255206 },
    { lat: 48.728801, lng: 17.254477 },
    { lat: 48.72888, lng: 17.253913 },
    { lat: 48.728863, lng: 17.253913 },
    { lat: 48.728352, lng: 17.253883 },
    { lat: 48.728231, lng: 17.253882 },
    { lat: 48.72816, lng: 17.253885 },
    { lat: 48.727857, lng: 17.253911 },
    { lat: 48.727792, lng: 17.253877 },
    { lat: 48.727767, lng: 17.253868 },
    { lat: 48.727695, lng: 17.253893 },
    { lat: 48.727255, lng: 17.254039 },
    { lat: 48.726524, lng: 17.254458 },
    { lat: 48.726158, lng: 17.254642 },
    { lat: 48.726051, lng: 17.254576 },
    { lat: 48.725902, lng: 17.254711 },
    { lat: 48.725761, lng: 17.254721 },
    { lat: 48.725728, lng: 17.254742 },
    { lat: 48.72561, lng: 17.254799 },
    { lat: 48.725428, lng: 17.254995 },
    { lat: 48.725206, lng: 17.255305 },
    { lat: 48.725028, lng: 17.255437 },
    { lat: 48.724773, lng: 17.255636 },
    { lat: 48.724566, lng: 17.255631 },
    { lat: 48.724556, lng: 17.255652 },
    { lat: 48.724416, lng: 17.255784 },
    { lat: 48.724249, lng: 17.255924 },
    { lat: 48.724093, lng: 17.256012 },
    { lat: 48.724009, lng: 17.256017 },
    { lat: 48.723919, lng: 17.256093 },
    { lat: 48.723858, lng: 17.256068 },
    { lat: 48.723688, lng: 17.256086 },
    { lat: 48.723636, lng: 17.256128 },
    { lat: 48.723586, lng: 17.256165 },
    { lat: 48.723575, lng: 17.256208 },
    { lat: 48.723566, lng: 17.25623 },
    { lat: 48.723481, lng: 17.25629 },
    { lat: 48.723386, lng: 17.256232 },
    { lat: 48.7233, lng: 17.256266 },
    { lat: 48.723315, lng: 17.256348 },
    { lat: 48.72321, lng: 17.256412 },
    { lat: 48.723139, lng: 17.256362 },
    { lat: 48.723063, lng: 17.256508 },
    { lat: 48.722543, lng: 17.256836 },
    { lat: 48.722453, lng: 17.256873 },
    { lat: 48.72241, lng: 17.256885 },
    { lat: 48.721806, lng: 17.257139 },
    { lat: 48.721456, lng: 17.257359 },
    { lat: 48.721252, lng: 17.257431 },
    { lat: 48.720814, lng: 17.257475 },
    { lat: 48.720797, lng: 17.257715 },
    { lat: 48.720658, lng: 17.257764 },
    { lat: 48.720492, lng: 17.257878 },
    { lat: 48.720375, lng: 17.257909 },
    { lat: 48.720351, lng: 17.257924 },
    { lat: 48.719804, lng: 17.257957 },
    { lat: 48.719724, lng: 17.257918 },
    { lat: 48.719394, lng: 17.257756 },
    { lat: 48.719079, lng: 17.257827 },
    { lat: 48.718878, lng: 17.257886 },
    { lat: 48.718729, lng: 17.258021 },
    { lat: 48.718548, lng: 17.258155 },
    { lat: 48.718345, lng: 17.258419 },
    { lat: 48.717495, lng: 17.259098 },
    { lat: 48.717214, lng: 17.2592 },
    { lat: 48.716935, lng: 17.259387 },
    { lat: 48.716787, lng: 17.259642 },
    { lat: 48.716151, lng: 17.259769 },
    { lat: 48.716058, lng: 17.259793 },
    { lat: 48.715749, lng: 17.259874 },
    { lat: 48.715455, lng: 17.259859 },
    { lat: 48.715285, lng: 17.259832 },
    { lat: 48.714838, lng: 17.259609 },
    { lat: 48.714645, lng: 17.259437 },
    { lat: 48.71456, lng: 17.259342 },
    { lat: 48.714266, lng: 17.258975 },
    { lat: 48.714134, lng: 17.258789 },
    { lat: 48.713609, lng: 17.258044 },
    { lat: 48.713303, lng: 17.257687 },
    { lat: 48.713275, lng: 17.257659 },
    { lat: 48.713227, lng: 17.257634 },
    { lat: 48.713183, lng: 17.257613 },
    { lat: 48.713142, lng: 17.257601 },
    { lat: 48.713099, lng: 17.257578 },
    { lat: 48.713046, lng: 17.257551 },
    { lat: 48.712909, lng: 17.257505 },
    { lat: 48.712811, lng: 17.257492 },
    { lat: 48.712562, lng: 17.257484 },
    { lat: 48.712442, lng: 17.257482 },
    { lat: 48.712315, lng: 17.257461 },
    { lat: 48.712199, lng: 17.257438 },
    { lat: 48.712071, lng: 17.257447 },
    { lat: 48.711811, lng: 17.257455 },
    { lat: 48.711566, lng: 17.257461 },
    { lat: 48.71128, lng: 17.257467 },
    { lat: 48.71124, lng: 17.257467 },
    { lat: 48.711041, lng: 17.257465 },
    { lat: 48.710897, lng: 17.257465 },
    { lat: 48.710696, lng: 17.257464 },
    { lat: 48.710497, lng: 17.257484 },
    { lat: 48.710207, lng: 17.257552 },
    { lat: 48.709979, lng: 17.257643 },
    { lat: 48.709832, lng: 17.257692 },
    { lat: 48.709764, lng: 17.257712 },
    { lat: 48.709561, lng: 17.257771 },
    { lat: 48.709514, lng: 17.257781 },
    { lat: 48.709391, lng: 17.257831 },
    { lat: 48.709361, lng: 17.257841 },
    { lat: 48.709334, lng: 17.257856 },
    { lat: 48.709316, lng: 17.257866 },
    { lat: 48.709295, lng: 17.257877 },
    { lat: 48.709259, lng: 17.257894 },
    { lat: 48.709226, lng: 17.257914 },
    { lat: 48.709174, lng: 17.257937 },
    { lat: 48.709125, lng: 17.25796 },
    { lat: 48.709046, lng: 17.258008 },
    { lat: 48.708883, lng: 17.258104 },
    { lat: 48.708852, lng: 17.258116 },
    { lat: 48.708839, lng: 17.258123 },
    { lat: 48.708816, lng: 17.258134 },
    { lat: 48.708751, lng: 17.258167 },
    { lat: 48.708707, lng: 17.258187 },
    { lat: 48.708647, lng: 17.25822 },
    { lat: 48.708588, lng: 17.258249 },
    { lat: 48.708529, lng: 17.25828 },
    { lat: 48.708472, lng: 17.258293 },
    { lat: 48.708428, lng: 17.258299 },
    { lat: 48.708367, lng: 17.258304 },
    { lat: 48.70831, lng: 17.258311 },
    { lat: 48.708229, lng: 17.258318 },
    { lat: 48.708148, lng: 17.258321 },
    { lat: 48.708057, lng: 17.258313 },
    { lat: 48.708023, lng: 17.258314 },
    { lat: 48.707998, lng: 17.258314 },
    { lat: 48.707968, lng: 17.258312 },
    { lat: 48.70792, lng: 17.258307 },
    { lat: 48.707871, lng: 17.258287 },
    { lat: 48.707831, lng: 17.258265 },
    { lat: 48.707803, lng: 17.258245 },
    { lat: 48.707288, lng: 17.257945 },
    { lat: 48.707162, lng: 17.257861 },
    { lat: 48.707094, lng: 17.257811 },
    { lat: 48.706963, lng: 17.257723 },
    { lat: 48.70693, lng: 17.257713 },
    { lat: 48.7067, lng: 17.257633 },
    { lat: 48.7066151, lng: 17.2576834 },
    { lat: 48.706433, lng: 17.2579379 },
    { lat: 48.7063487, lng: 17.2584592 },
    { lat: 48.7062273, lng: 17.2602876 },
    { lat: 48.7061116, lng: 17.2632518 },
    { lat: 48.7057275, lng: 17.2691819 },
    { lat: 48.7053953, lng: 17.2688383 },
    { lat: 48.705191, lng: 17.2688049 },
    { lat: 48.7041571, lng: 17.2678548 },
    { lat: 48.7040749, lng: 17.2677212 },
    { lat: 48.7037772, lng: 17.2675241 },
    { lat: 48.7032338, lng: 17.2679873 },
    { lat: 48.7029794, lng: 17.2679675 },
    { lat: 48.7026111, lng: 17.2682087 },
    { lat: 48.7021859, lng: 17.2680829 },
    { lat: 48.7020153, lng: 17.2683031 },
    { lat: 48.7017507, lng: 17.2684197 },
    { lat: 48.7014983, lng: 17.2682065 },
    { lat: 48.7014424, lng: 17.2682338 },
    { lat: 48.701346, lng: 17.2684489 },
    { lat: 48.7010503, lng: 17.2682329 },
    { lat: 48.7009822, lng: 17.2682771 },
    { lat: 48.7009353, lng: 17.2685284 },
    { lat: 48.700882, lng: 17.2685575 },
    { lat: 48.7007884, lng: 17.2684907 },
    { lat: 48.7002444, lng: 17.268706 },
    { lat: 48.6999401, lng: 17.2687338 },
    { lat: 48.6996416, lng: 17.2690579 },
    { lat: 48.6992315, lng: 17.2693307 },
    { lat: 48.6970355, lng: 17.269796 },
    { lat: 48.6955965, lng: 17.271004 },
    { lat: 48.6954048, lng: 17.2713543 },
    { lat: 48.6948993, lng: 17.2730828 },
    { lat: 48.6947383, lng: 17.2734362 },
    { lat: 48.6945045, lng: 17.2737434 },
    { lat: 48.6942758, lng: 17.2739271 },
    { lat: 48.6942312, lng: 17.2740379 },
    { lat: 48.6934587, lng: 17.2748141 },
    { lat: 48.6931448, lng: 17.2752655 },
    { lat: 48.6930309, lng: 17.2755247 },
    { lat: 48.692764, lng: 17.275865 },
    { lat: 48.6924954, lng: 17.2759027 },
    { lat: 48.6921046, lng: 17.2761115 },
    { lat: 48.6918452, lng: 17.2763245 },
    { lat: 48.6915231, lng: 17.2763409 },
    { lat: 48.6909488, lng: 17.2765567 },
    { lat: 48.6906224, lng: 17.2767626 },
    { lat: 48.6901939, lng: 17.2766537 },
    { lat: 48.689714, lng: 17.2775694 },
    { lat: 48.6886913, lng: 17.2787369 },
    { lat: 48.6882844, lng: 17.2790939 },
    { lat: 48.6877619, lng: 17.2793777 },
    { lat: 48.6877237, lng: 17.2803252 },
    { lat: 48.6874462, lng: 17.280696 },
    { lat: 48.6853187, lng: 17.2818786 },
    { lat: 48.6846414, lng: 17.2841178 },
    { lat: 48.6843676, lng: 17.2869198 },
    { lat: 48.6843816, lng: 17.2871343 },
    { lat: 48.684335, lng: 17.2873179 },
    { lat: 48.6842309, lng: 17.2889372 },
    { lat: 48.6843387, lng: 17.2909606 },
    { lat: 48.6843245, lng: 17.293812 },
    { lat: 48.6841958, lng: 17.2946763 },
    { lat: 48.684406, lng: 17.2958325 },
    { lat: 48.6837407, lng: 17.2993027 },
    { lat: 48.6830227, lng: 17.3011065 },
    { lat: 48.682939, lng: 17.3013274 },
    { lat: 48.6839254, lng: 17.3010214 },
    { lat: 48.6847536, lng: 17.3010565 },
    { lat: 48.6853958, lng: 17.3012913 },
    { lat: 48.6884774, lng: 17.3028301 },
    { lat: 48.6888285, lng: 17.3029322 },
    { lat: 48.6895755, lng: 17.3029852 },
    { lat: 48.6900137, lng: 17.3032108 },
    { lat: 48.6910845, lng: 17.303347 },
    { lat: 48.6921972, lng: 17.3033489 },
    { lat: 48.6933508, lng: 17.3035246 },
    { lat: 48.6950269, lng: 17.3040196 },
    { lat: 48.6958612, lng: 17.3041339 },
    { lat: 48.6973577, lng: 17.3048038 },
    { lat: 48.6982625, lng: 17.3049605 },
    { lat: 48.7019743, lng: 17.3038887 },
    { lat: 48.7033744, lng: 17.30367 },
    { lat: 48.7042762, lng: 17.3037453 },
    { lat: 48.7058068, lng: 17.3036731 },
    { lat: 48.7062248, lng: 17.3035541 },
    { lat: 48.7077103, lng: 17.3036603 },
    { lat: 48.7093913, lng: 17.3038872 },
    { lat: 48.7099989, lng: 17.3041437 },
    { lat: 48.7105511, lng: 17.3042942 },
    { lat: 48.7106345, lng: 17.3042735 },
    { lat: 48.7117693, lng: 17.3037667 },
    { lat: 48.7122841, lng: 17.3026411 },
    { lat: 48.7135598, lng: 17.3001914 },
    { lat: 48.7138436, lng: 17.299489 },
    { lat: 48.7142838, lng: 17.2982217 },
    { lat: 48.7145793, lng: 17.296957 },
    { lat: 48.7148098, lng: 17.2955747 },
    { lat: 48.7151689, lng: 17.292934 },
    { lat: 48.7152364, lng: 17.2926174 },
    { lat: 48.7152838, lng: 17.2926171 },
    { lat: 48.7155541, lng: 17.2910524 },
    { lat: 48.7158767, lng: 17.2916144 },
    { lat: 48.7164655, lng: 17.2923763 },
    { lat: 48.7170849, lng: 17.292591 },
    { lat: 48.7176919, lng: 17.292664 },
    { lat: 48.7183592, lng: 17.2929366 },
    { lat: 48.7189964, lng: 17.2933508 },
    { lat: 48.720311, lng: 17.2945208 },
    { lat: 48.7211369, lng: 17.2948498 },
    { lat: 48.7216231, lng: 17.2948762 },
    { lat: 48.7231777, lng: 17.2943962 },
    { lat: 48.725951, lng: 17.293687 },
    { lat: 48.7286536, lng: 17.2927822 },
    { lat: 48.7306711, lng: 17.2919683 },
    { lat: 48.732093, lng: 17.2914797 },
    { lat: 48.7327114, lng: 17.2913836 },
    { lat: 48.7333017, lng: 17.2914115 },
    { lat: 48.7335189, lng: 17.291349 },
    { lat: 48.7336921, lng: 17.2911635 },
    { lat: 48.7344486, lng: 17.2906566 },
    { lat: 48.734503, lng: 17.290613 },
  ],
  Jablonica: [
    { lat: 48.5775423, lng: 17.4137861 },
    { lat: 48.5776363, lng: 17.4138793 },
    { lat: 48.5777889, lng: 17.414194 },
    { lat: 48.5777761, lng: 17.4143653 },
    { lat: 48.5776308, lng: 17.4147543 },
    { lat: 48.5773142, lng: 17.4151182 },
    { lat: 48.5771137, lng: 17.4152318 },
    { lat: 48.5769845, lng: 17.41554 },
    { lat: 48.5763877, lng: 17.4164055 },
    { lat: 48.5762685, lng: 17.4165353 },
    { lat: 48.5761875, lng: 17.4165055 },
    { lat: 48.5757123, lng: 17.4170891 },
    { lat: 48.5754234, lng: 17.4172328 },
    { lat: 48.5755599, lng: 17.4177014 },
    { lat: 48.5753034, lng: 17.4182241 },
    { lat: 48.5751564, lng: 17.4192207 },
    { lat: 48.5752341, lng: 17.4201424 },
    { lat: 48.5752249, lng: 17.420667 },
    { lat: 48.5747478, lng: 17.4208696 },
    { lat: 48.5742391, lng: 17.4212309 },
    { lat: 48.5742889, lng: 17.4216698 },
    { lat: 48.5742101, lng: 17.4233711 },
    { lat: 48.5743954, lng: 17.4241789 },
    { lat: 48.5747934, lng: 17.4240083 },
    { lat: 48.5750101, lng: 17.4242344 },
    { lat: 48.5751735, lng: 17.4245261 },
    { lat: 48.5754596, lng: 17.4254604 },
    { lat: 48.5756069, lng: 17.42638 },
    { lat: 48.5758303, lng: 17.4272861 },
    { lat: 48.5759009, lng: 17.4282926 },
    { lat: 48.5759943, lng: 17.4288125 },
    { lat: 48.5760346, lng: 17.4302604 },
    { lat: 48.5761051, lng: 17.4303224 },
    { lat: 48.5762529, lng: 17.4306878 },
    { lat: 48.5762769, lng: 17.4311822 },
    { lat: 48.5764535, lng: 17.4314167 },
    { lat: 48.5762255, lng: 17.4321548 },
    { lat: 48.576159, lng: 17.4326643 },
    { lat: 48.5762257, lng: 17.4330162 },
    { lat: 48.5763021, lng: 17.4331523 },
    { lat: 48.5760873, lng: 17.4334288 },
    { lat: 48.5755439, lng: 17.4347074 },
    { lat: 48.5746624, lng: 17.43582 },
    { lat: 48.5743342, lng: 17.4360738 },
    { lat: 48.5738506, lng: 17.4369106 },
    { lat: 48.5737863, lng: 17.4378975 },
    { lat: 48.5738045, lng: 17.4394406 },
    { lat: 48.5734397, lng: 17.4415464 },
    { lat: 48.5733316, lng: 17.4419228 },
    { lat: 48.5731042, lng: 17.4423347 },
    { lat: 48.5724056, lng: 17.4430973 },
    { lat: 48.5716581, lng: 17.4440863 },
    { lat: 48.5714869, lng: 17.4443753 },
    { lat: 48.5708178, lng: 17.445876 },
    { lat: 48.570513, lng: 17.4463003 },
    { lat: 48.5701334, lng: 17.4466866 },
    { lat: 48.5691677, lng: 17.4474207 },
    { lat: 48.5680583, lng: 17.4484944 },
    { lat: 48.5677865, lng: 17.4487574 },
    { lat: 48.5671912, lng: 17.449212 },
    { lat: 48.5666866, lng: 17.4494436 },
    { lat: 48.5663652, lng: 17.4493297 },
    { lat: 48.5659301, lng: 17.4489382 },
    { lat: 48.565892, lng: 17.448871 },
    { lat: 48.565874, lng: 17.448928 },
    { lat: 48.565867, lng: 17.448943 },
    { lat: 48.565858, lng: 17.448964 },
    { lat: 48.56581, lng: 17.448998 },
    { lat: 48.565761, lng: 17.449032 },
    { lat: 48.565494, lng: 17.450003 },
    { lat: 48.56548, lng: 17.450298 },
    { lat: 48.565506, lng: 17.451044 },
    { lat: 48.565454, lng: 17.451271 },
    { lat: 48.565452, lng: 17.451589 },
    { lat: 48.56537, lng: 17.451801 },
    { lat: 48.565251, lng: 17.451905 },
    { lat: 48.564844, lng: 17.452413 },
    { lat: 48.564409, lng: 17.452759 },
    { lat: 48.564264, lng: 17.452866 },
    { lat: 48.564148, lng: 17.452924 },
    { lat: 48.564032, lng: 17.453006 },
    { lat: 48.563919, lng: 17.453063 },
    { lat: 48.563836, lng: 17.453047 },
    { lat: 48.563689, lng: 17.453172 },
    { lat: 48.563521, lng: 17.453221 },
    { lat: 48.56345, lng: 17.453295 },
    { lat: 48.563226, lng: 17.453327 },
    { lat: 48.562943, lng: 17.453433 },
    { lat: 48.562769, lng: 17.453585 },
    { lat: 48.562675, lng: 17.453598 },
    { lat: 48.562506, lng: 17.453647 },
    { lat: 48.562199, lng: 17.45378 },
    { lat: 48.562114, lng: 17.453784 },
    { lat: 48.562029, lng: 17.453831 },
    { lat: 48.56193, lng: 17.453853 },
    { lat: 48.561793, lng: 17.453924 },
    { lat: 48.561723, lng: 17.453999 },
    { lat: 48.5617, lng: 17.453965 },
    { lat: 48.561525, lng: 17.454044 },
    { lat: 48.561465, lng: 17.454087 },
    { lat: 48.561332, lng: 17.454142 },
    { lat: 48.561303, lng: 17.454213 },
    { lat: 48.561207, lng: 17.45421 },
    { lat: 48.561136, lng: 17.454311 },
    { lat: 48.56094, lng: 17.454446 },
    { lat: 48.560916, lng: 17.454514 },
    { lat: 48.560865, lng: 17.454584 },
    { lat: 48.560795, lng: 17.454601 },
    { lat: 48.560698, lng: 17.454566 },
    { lat: 48.560571, lng: 17.454693 },
    { lat: 48.56038, lng: 17.454845 },
    { lat: 48.560133, lng: 17.454985 },
    { lat: 48.560094, lng: 17.455079 },
    { lat: 48.559989, lng: 17.45533 },
    { lat: 48.55985, lng: 17.455743 },
    { lat: 48.559769, lng: 17.455787 },
    { lat: 48.55968, lng: 17.456005 },
    { lat: 48.559639, lng: 17.456104 },
    { lat: 48.559621, lng: 17.456146 },
    { lat: 48.559574, lng: 17.456274 },
    { lat: 48.559707, lng: 17.456455 },
    { lat: 48.55974, lng: 17.4565 },
    { lat: 48.560025, lng: 17.456808 },
    { lat: 48.560096, lng: 17.456888 },
    { lat: 48.560218, lng: 17.45708 },
    { lat: 48.560334, lng: 17.457266 },
    { lat: 48.560362, lng: 17.45731 },
    { lat: 48.560381, lng: 17.457349 },
    { lat: 48.560479, lng: 17.457547 },
    { lat: 48.560625, lng: 17.457842 },
    { lat: 48.560674, lng: 17.458143 },
    { lat: 48.560763, lng: 17.458433 },
    { lat: 48.560836, lng: 17.458663 },
    { lat: 48.56086, lng: 17.458784 },
    { lat: 48.560977, lng: 17.45885 },
    { lat: 48.561089, lng: 17.458718 },
    { lat: 48.561204, lng: 17.458824 },
    { lat: 48.561309, lng: 17.458753 },
    { lat: 48.561602, lng: 17.458978 },
    { lat: 48.561901, lng: 17.459143 },
    { lat: 48.561976, lng: 17.459182 },
    { lat: 48.562006, lng: 17.459197 },
    { lat: 48.562547, lng: 17.459478 },
    { lat: 48.562695, lng: 17.459555 },
    { lat: 48.562728, lng: 17.459573 },
    { lat: 48.56275, lng: 17.459583 },
    { lat: 48.563096, lng: 17.459824 },
    { lat: 48.563501, lng: 17.460142 },
    { lat: 48.563751, lng: 17.460484 },
    { lat: 48.564268, lng: 17.461186 },
    { lat: 48.56442, lng: 17.461392 },
    { lat: 48.564381, lng: 17.461466 },
    { lat: 48.56445, lng: 17.461686 },
    { lat: 48.564546, lng: 17.461892 },
    { lat: 48.564643, lng: 17.461924 },
    { lat: 48.564781, lng: 17.46197 },
    { lat: 48.564907, lng: 17.462123 },
    { lat: 48.565064, lng: 17.462416 },
    { lat: 48.565187, lng: 17.462415 },
    { lat: 48.565201, lng: 17.462415 },
    { lat: 48.565756, lng: 17.462978 },
    { lat: 48.565895, lng: 17.463118 },
    { lat: 48.56613, lng: 17.46345 },
    { lat: 48.566643, lng: 17.464167 },
    { lat: 48.566658, lng: 17.464187 },
    { lat: 48.566689, lng: 17.464225 },
    { lat: 48.566735, lng: 17.464282 },
    { lat: 48.566948, lng: 17.464546 },
    { lat: 48.567122, lng: 17.464504 },
    { lat: 48.567554, lng: 17.4648 },
    { lat: 48.567583, lng: 17.465059 },
    { lat: 48.567611, lng: 17.465297 },
    { lat: 48.567655, lng: 17.46528 },
    { lat: 48.567804, lng: 17.465221 },
    { lat: 48.567977, lng: 17.46535 },
    { lat: 48.568053, lng: 17.465525 },
    { lat: 48.568272, lng: 17.465612 },
    { lat: 48.568262, lng: 17.465712 },
    { lat: 48.568244, lng: 17.465877 },
    { lat: 48.568255, lng: 17.465892 },
    { lat: 48.568274, lng: 17.465917 },
    { lat: 48.568347, lng: 17.466011 },
    { lat: 48.568507, lng: 17.466214 },
    { lat: 48.568614, lng: 17.466284 },
    { lat: 48.568624, lng: 17.466291 },
    { lat: 48.568765, lng: 17.466389 },
    { lat: 48.568823, lng: 17.466429 },
    { lat: 48.568898, lng: 17.466457 },
    { lat: 48.568956, lng: 17.466479 },
    { lat: 48.569033, lng: 17.46662 },
    { lat: 48.569102, lng: 17.466751 },
    { lat: 48.569121, lng: 17.466786 },
    { lat: 48.569292, lng: 17.467108 },
    { lat: 48.569422, lng: 17.467161 },
    { lat: 48.569396, lng: 17.467452 },
    { lat: 48.569393, lng: 17.467479 },
    { lat: 48.569695, lng: 17.467486 },
    { lat: 48.569724, lng: 17.467646 },
    { lat: 48.569757, lng: 17.467833 },
    { lat: 48.569856, lng: 17.467956 },
    { lat: 48.569921, lng: 17.467928 },
    { lat: 48.569978, lng: 17.468201 },
    { lat: 48.569953, lng: 17.468452 },
    { lat: 48.570038, lng: 17.468554 },
    { lat: 48.569991, lng: 17.468789 },
    { lat: 48.570054, lng: 17.468999 },
    { lat: 48.570079, lng: 17.46915 },
    { lat: 48.57001, lng: 17.469446 },
    { lat: 48.569955, lng: 17.469635 },
    { lat: 48.570121, lng: 17.4701 },
    { lat: 48.570277, lng: 17.470182 },
    { lat: 48.570427, lng: 17.469927 },
    { lat: 48.570502, lng: 17.469992 },
    { lat: 48.570621, lng: 17.46991 },
    { lat: 48.570697, lng: 17.470011 },
    { lat: 48.570732, lng: 17.470503 },
    { lat: 48.570908, lng: 17.47073 },
    { lat: 48.570975, lng: 17.47068 },
    { lat: 48.571063, lng: 17.470692 },
    { lat: 48.571103, lng: 17.470883 },
    { lat: 48.571194, lng: 17.470889 },
    { lat: 48.571259, lng: 17.470984 },
    { lat: 48.57134, lng: 17.470965 },
    { lat: 48.571395, lng: 17.47106 },
    { lat: 48.571444, lng: 17.471081 },
    { lat: 48.571502, lng: 17.471156 },
    { lat: 48.571612, lng: 17.471115 },
    { lat: 48.571747, lng: 17.471146 },
    { lat: 48.571929, lng: 17.471129 },
    { lat: 48.571954, lng: 17.471134 },
    { lat: 48.57217, lng: 17.471265 },
    { lat: 48.572283, lng: 17.471268 },
    { lat: 48.572401, lng: 17.471348 },
    { lat: 48.5725, lng: 17.47128 },
    { lat: 48.572643, lng: 17.47128 },
    { lat: 48.572794, lng: 17.471218 },
    { lat: 48.572863, lng: 17.471216 },
    { lat: 48.572987, lng: 17.471623 },
    { lat: 48.57311, lng: 17.471752 },
    { lat: 48.573258, lng: 17.471829 },
    { lat: 48.573361, lng: 17.471851 },
    { lat: 48.573385, lng: 17.471854 },
    { lat: 48.573482, lng: 17.471835 },
    { lat: 48.573695, lng: 17.471962 },
    { lat: 48.573807, lng: 17.471897 },
    { lat: 48.573919, lng: 17.47191 },
    { lat: 48.573937, lng: 17.471946 },
    { lat: 48.573946, lng: 17.471928 },
    { lat: 48.574022, lng: 17.471797 },
    { lat: 48.574528, lng: 17.470923 },
    { lat: 48.574601, lng: 17.470869 },
    { lat: 48.574652, lng: 17.470831 },
    { lat: 48.574739, lng: 17.470767 },
    { lat: 48.574808, lng: 17.470856 },
    { lat: 48.574951, lng: 17.470671 },
    { lat: 48.575199, lng: 17.470644 },
    { lat: 48.575257, lng: 17.470512 },
    { lat: 48.575907, lng: 17.470466 },
    { lat: 48.575944, lng: 17.470497 },
    { lat: 48.576179, lng: 17.470696 },
    { lat: 48.576751, lng: 17.47118 },
    { lat: 48.577576, lng: 17.470983 },
    { lat: 48.578188, lng: 17.470832 },
    { lat: 48.578498, lng: 17.47085 },
    { lat: 48.578731, lng: 17.470863 },
    { lat: 48.578733, lng: 17.470863 },
    { lat: 48.578765, lng: 17.470865 },
    { lat: 48.579541, lng: 17.471331 },
    { lat: 48.579985, lng: 17.47097 },
    { lat: 48.580907, lng: 17.470227 },
    { lat: 48.581207, lng: 17.470191 },
    { lat: 48.581245, lng: 17.470185 },
    { lat: 48.581271, lng: 17.470182 },
    { lat: 48.581422, lng: 17.470158 },
    { lat: 48.581799, lng: 17.4703 },
    { lat: 48.581991, lng: 17.470569 },
    { lat: 48.582078, lng: 17.470691 },
    { lat: 48.582155, lng: 17.471242 },
    { lat: 48.58229, lng: 17.472208 },
    { lat: 48.582374, lng: 17.473903 },
    { lat: 48.582529, lng: 17.474337 },
    { lat: 48.582584, lng: 17.474489 },
    { lat: 48.5826507, lng: 17.4744321 },
    { lat: 48.5828283, lng: 17.4742808 },
    { lat: 48.5830815, lng: 17.4738259 },
    { lat: 48.5833373, lng: 17.4737113 },
    { lat: 48.5837307, lng: 17.4740525 },
    { lat: 48.5842626, lng: 17.4742287 },
    { lat: 48.5849089, lng: 17.4746751 },
    { lat: 48.5852427, lng: 17.4748248 },
    { lat: 48.5854436, lng: 17.4750349 },
    { lat: 48.5856462, lng: 17.4750793 },
    { lat: 48.5860212, lng: 17.4754139 },
    { lat: 48.5860661, lng: 17.4750728 },
    { lat: 48.5862608, lng: 17.474676 },
    { lat: 48.5870378, lng: 17.4740417 },
    { lat: 48.587627, lng: 17.4737366 },
    { lat: 48.5884807, lng: 17.4725007 },
    { lat: 48.5885887, lng: 17.471947 },
    { lat: 48.5892276, lng: 17.471447 },
    { lat: 48.591936, lng: 17.4711668 },
    { lat: 48.5932453, lng: 17.4702926 },
    { lat: 48.5938646, lng: 17.4702252 },
    { lat: 48.5952884, lng: 17.4696204 },
    { lat: 48.5962404, lng: 17.4693743 },
    { lat: 48.5968935, lng: 17.4700488 },
    { lat: 48.5972567, lng: 17.4707274 },
    { lat: 48.5975196, lng: 17.4710969 },
    { lat: 48.59793, lng: 17.4714549 },
    { lat: 48.5980773, lng: 17.4717257 },
    { lat: 48.5985091, lng: 17.4715554 },
    { lat: 48.5987896, lng: 17.4715785 },
    { lat: 48.5991882, lng: 17.4719479 },
    { lat: 48.5995764, lng: 17.4725076 },
    { lat: 48.5998619, lng: 17.4724678 },
    { lat: 48.5999066, lng: 17.4726197 },
    { lat: 48.6000066, lng: 17.4726654 },
    { lat: 48.6001289, lng: 17.4726162 },
    { lat: 48.6001504, lng: 17.4727052 },
    { lat: 48.6000934, lng: 17.4729083 },
    { lat: 48.6001306, lng: 17.4731005 },
    { lat: 48.6003501, lng: 17.4732837 },
    { lat: 48.6004469, lng: 17.4730698 },
    { lat: 48.6005753, lng: 17.47308 },
    { lat: 48.6005424, lng: 17.4733086 },
    { lat: 48.6005849, lng: 17.4734073 },
    { lat: 48.6007453, lng: 17.473406 },
    { lat: 48.60092, lng: 17.4731369 },
    { lat: 48.6013835, lng: 17.4730723 },
    { lat: 48.6014324, lng: 17.4733774 },
    { lat: 48.601549, lng: 17.4735059 },
    { lat: 48.601711, lng: 17.4733379 },
    { lat: 48.6020281, lng: 17.4734398 },
    { lat: 48.6021075, lng: 17.4737269 },
    { lat: 48.6022304, lng: 17.4735135 },
    { lat: 48.6022563, lng: 17.4732824 },
    { lat: 48.6023179, lng: 17.4732392 },
    { lat: 48.6023694, lng: 17.4734072 },
    { lat: 48.6024533, lng: 17.4733429 },
    { lat: 48.602338, lng: 17.4727773 },
    { lat: 48.6030366, lng: 17.4712547 },
    { lat: 48.6040662, lng: 17.4695346 },
    { lat: 48.604326, lng: 17.4694489 },
    { lat: 48.6045427, lng: 17.4697096 },
    { lat: 48.6053639, lng: 17.469114 },
    { lat: 48.6059853, lng: 17.4680417 },
    { lat: 48.6067488, lng: 17.4672849 },
    { lat: 48.6075932, lng: 17.4667114 },
    { lat: 48.6082475, lng: 17.4664048 },
    { lat: 48.60858, lng: 17.4660573 },
    { lat: 48.6090186, lng: 17.4658691 },
    { lat: 48.6097672, lng: 17.4657261 },
    { lat: 48.6102408, lng: 17.4657998 },
    { lat: 48.6110801, lng: 17.4662635 },
    { lat: 48.6114748, lng: 17.4663066 },
    { lat: 48.6117718, lng: 17.4665867 },
    { lat: 48.6121215, lng: 17.4672181 },
    { lat: 48.6127415, lng: 17.4676924 },
    { lat: 48.6135443, lng: 17.4674765 },
    { lat: 48.6148226, lng: 17.4668558 },
    { lat: 48.615468, lng: 17.4667236 },
    { lat: 48.6160732, lng: 17.4663141 },
    { lat: 48.6168815, lng: 17.4654288 },
    { lat: 48.6172285, lng: 17.4651527 },
    { lat: 48.6176966, lng: 17.4650723 },
    { lat: 48.6180358, lng: 17.4647437 },
    { lat: 48.6191076, lng: 17.4642144 },
    { lat: 48.6195564, lng: 17.4643456 },
    { lat: 48.6200915, lng: 17.4643236 },
    { lat: 48.6201563, lng: 17.464241 },
    { lat: 48.6203315, lng: 17.4642283 },
    { lat: 48.6205388, lng: 17.4640942 },
    { lat: 48.6211218, lng: 17.4639735 },
    { lat: 48.6212877, lng: 17.4638675 },
    { lat: 48.6213251, lng: 17.4637817 },
    { lat: 48.6213368, lng: 17.4637536 },
    { lat: 48.6211816, lng: 17.4630948 },
    { lat: 48.6208163, lng: 17.4619397 },
    { lat: 48.6205956, lng: 17.4607718 },
    { lat: 48.6204205, lng: 17.4602597 },
    { lat: 48.6202743, lng: 17.460058 },
    { lat: 48.6194581, lng: 17.4599215 },
    { lat: 48.6190258, lng: 17.4593295 },
    { lat: 48.6188149, lng: 17.4589634 },
    { lat: 48.6186937, lng: 17.4586234 },
    { lat: 48.6183269, lng: 17.4571824 },
    { lat: 48.6182248, lng: 17.4569296 },
    { lat: 48.619116, lng: 17.4556447 },
    { lat: 48.6189905, lng: 17.4556245 },
    { lat: 48.6186942, lng: 17.4554072 },
    { lat: 48.6185894, lng: 17.4552197 },
    { lat: 48.6183752, lng: 17.4550136 },
    { lat: 48.6179984, lng: 17.4549126 },
    { lat: 48.6181425, lng: 17.4533228 },
    { lat: 48.6188541, lng: 17.4534291 },
    { lat: 48.6187426, lng: 17.4523655 },
    { lat: 48.6185333, lng: 17.4503679 },
    { lat: 48.6176814, lng: 17.4482543 },
    { lat: 48.6176531, lng: 17.4482713 },
    { lat: 48.6175557, lng: 17.4480878 },
    { lat: 48.6175906, lng: 17.4477276 },
    { lat: 48.617752, lng: 17.4477185 },
    { lat: 48.6178762, lng: 17.4478239 },
    { lat: 48.617961, lng: 17.4476666 },
    { lat: 48.6181172, lng: 17.4475306 },
    { lat: 48.6181202, lng: 17.4474191 },
    { lat: 48.6178979, lng: 17.4470336 },
    { lat: 48.6178033, lng: 17.447021 },
    { lat: 48.6176597, lng: 17.4468453 },
    { lat: 48.6174332, lng: 17.4460778 },
    { lat: 48.6174407, lng: 17.4459122 },
    { lat: 48.6175398, lng: 17.4456967 },
    { lat: 48.6174796, lng: 17.4452864 },
    { lat: 48.6174655, lng: 17.4444532 },
    { lat: 48.6175498, lng: 17.4439194 },
    { lat: 48.6174945, lng: 17.4437112 },
    { lat: 48.6176356, lng: 17.4433057 },
    { lat: 48.6175858, lng: 17.442462 },
    { lat: 48.6176757, lng: 17.4421551 },
    { lat: 48.6178506, lng: 17.4419939 },
    { lat: 48.6181959, lng: 17.4423575 },
    { lat: 48.6182297, lng: 17.4417053 },
    { lat: 48.6183266, lng: 17.4410522 },
    { lat: 48.6182694, lng: 17.4406747 },
    { lat: 48.6183145, lng: 17.4402858 },
    { lat: 48.6184322, lng: 17.4397099 },
    { lat: 48.6185434, lng: 17.4393299 },
    { lat: 48.6186095, lng: 17.4392681 },
    { lat: 48.6186068, lng: 17.4391239 },
    { lat: 48.6182096, lng: 17.4391624 },
    { lat: 48.6182532, lng: 17.438737 },
    { lat: 48.6183413, lng: 17.4384549 },
    { lat: 48.6182498, lng: 17.4382085 },
    { lat: 48.6182255, lng: 17.4379711 },
    { lat: 48.6187975, lng: 17.4378709 },
    { lat: 48.6187774, lng: 17.4376475 },
    { lat: 48.6189644, lng: 17.4373935 },
    { lat: 48.6191753, lng: 17.4369521 },
    { lat: 48.6196054, lng: 17.4368952 },
    { lat: 48.6196623, lng: 17.4364286 },
    { lat: 48.6194662, lng: 17.4360671 },
    { lat: 48.6194803, lng: 17.435461 },
    { lat: 48.6202668, lng: 17.4352045 },
    { lat: 48.6203267, lng: 17.4350469 },
    { lat: 48.6202594, lng: 17.43428 },
    { lat: 48.6204443, lng: 17.4337852 },
    { lat: 48.6204586, lng: 17.4332684 },
    { lat: 48.6206186, lng: 17.4332639 },
    { lat: 48.6206995, lng: 17.4330467 },
    { lat: 48.6215967, lng: 17.4321939 },
    { lat: 48.621281, lng: 17.4314983 },
    { lat: 48.6218626, lng: 17.4313577 },
    { lat: 48.6218868, lng: 17.4307705 },
    { lat: 48.6219924, lng: 17.4307593 },
    { lat: 48.6219952, lng: 17.4305758 },
    { lat: 48.6220837, lng: 17.4305825 },
    { lat: 48.622181, lng: 17.4295169 },
    { lat: 48.62296, lng: 17.4290739 },
    { lat: 48.6230568, lng: 17.4294706 },
    { lat: 48.6230244, lng: 17.4300373 },
    { lat: 48.6232453, lng: 17.4303612 },
    { lat: 48.6233041, lng: 17.4306796 },
    { lat: 48.6232635, lng: 17.4308908 },
    { lat: 48.626284, lng: 17.4291933 },
    { lat: 48.6271299, lng: 17.4301044 },
    { lat: 48.6271799, lng: 17.4314448 },
    { lat: 48.6274982, lng: 17.4314075 },
    { lat: 48.6280999, lng: 17.4316107 },
    { lat: 48.6313684, lng: 17.4310296 },
    { lat: 48.6318684, lng: 17.4311131 },
    { lat: 48.6318825, lng: 17.4311921 },
    { lat: 48.6319755, lng: 17.4311999 },
    { lat: 48.6324368, lng: 17.4310998 },
    { lat: 48.6325638, lng: 17.4330365 },
    { lat: 48.6329667, lng: 17.4329271 },
    { lat: 48.6338949, lng: 17.4323977 },
    { lat: 48.6335576, lng: 17.4304746 },
    { lat: 48.6351842, lng: 17.4306019 },
    { lat: 48.6359735, lng: 17.4304765 },
    { lat: 48.6361848, lng: 17.4312144 },
    { lat: 48.6363949, lng: 17.4310036 },
    { lat: 48.6364655, lng: 17.4309608 },
    { lat: 48.6339513, lng: 17.4144857 },
    { lat: 48.6335368, lng: 17.4145534 },
    { lat: 48.63357, lng: 17.412952 },
    { lat: 48.6334733, lng: 17.4121174 },
    { lat: 48.6335845, lng: 17.4106203 },
    { lat: 48.6331502, lng: 17.4087901 },
    { lat: 48.6347235, lng: 17.4057828 },
    { lat: 48.6348663, lng: 17.4057543 },
    { lat: 48.6307516, lng: 17.4011919 },
    { lat: 48.6314079, lng: 17.3975953 },
    { lat: 48.6313327, lng: 17.3975682 },
    { lat: 48.6272954, lng: 17.3928267 },
    { lat: 48.6263355, lng: 17.3940004 },
    { lat: 48.6236281, lng: 17.3891661 },
    { lat: 48.6223309, lng: 17.3904501 },
    { lat: 48.6224316, lng: 17.3914645 },
    { lat: 48.6223927, lng: 17.3915759 },
    { lat: 48.6223183, lng: 17.3916675 },
    { lat: 48.621375, lng: 17.3921703 },
    { lat: 48.6208893, lng: 17.3931126 },
    { lat: 48.620865, lng: 17.3932414 },
    { lat: 48.6203482, lng: 17.3896572 },
    { lat: 48.6201242, lng: 17.3891476 },
    { lat: 48.6183456, lng: 17.3907912 },
    { lat: 48.6176557, lng: 17.3896109 },
    { lat: 48.617738, lng: 17.3899572 },
    { lat: 48.6176587, lng: 17.3900738 },
    { lat: 48.6175242, lng: 17.3901243 },
    { lat: 48.617105, lng: 17.3900865 },
    { lat: 48.6169996, lng: 17.3899621 },
    { lat: 48.6167344, lng: 17.390084 },
    { lat: 48.6167142, lng: 17.39047 },
    { lat: 48.61651, lng: 17.3908409 },
    { lat: 48.6154792, lng: 17.3912159 },
    { lat: 48.6152375, lng: 17.3913878 },
    { lat: 48.6159115, lng: 17.3925712 },
    { lat: 48.61577, lng: 17.393105 },
    { lat: 48.6155335, lng: 17.393309 },
    { lat: 48.6154172, lng: 17.3935203 },
    { lat: 48.6142165, lng: 17.3930117 },
    { lat: 48.6144112, lng: 17.3926379 },
    { lat: 48.6143201, lng: 17.3925903 },
    { lat: 48.6142772, lng: 17.3926951 },
    { lat: 48.6129561, lng: 17.3922111 },
    { lat: 48.6127939, lng: 17.391837 },
    { lat: 48.6127177, lng: 17.3914973 },
    { lat: 48.6127436, lng: 17.3912686 },
    { lat: 48.612676, lng: 17.3910615 },
    { lat: 48.6126654, lng: 17.3907215 },
    { lat: 48.6127621, lng: 17.3900548 },
    { lat: 48.6127773, lng: 17.3880572 },
    { lat: 48.6145181, lng: 17.3872563 },
    { lat: 48.6147283, lng: 17.3873471 },
    { lat: 48.6149198, lng: 17.3870233 },
    { lat: 48.6147425, lng: 17.3868135 },
    { lat: 48.6147747, lng: 17.3866324 },
    { lat: 48.6142955, lng: 17.3862604 },
    { lat: 48.6139811, lng: 17.386149 },
    { lat: 48.6137731, lng: 17.3862888 },
    { lat: 48.6139311, lng: 17.3856412 },
    { lat: 48.613759, lng: 17.3850591 },
    { lat: 48.6134124, lng: 17.3844334 },
    { lat: 48.6133553, lng: 17.384163 },
    { lat: 48.6130724, lng: 17.3840848 },
    { lat: 48.6124168, lng: 17.3841839 },
    { lat: 48.6118186, lng: 17.384084 },
    { lat: 48.6116347, lng: 17.3833387 },
    { lat: 48.6112687, lng: 17.3824139 },
    { lat: 48.6112492, lng: 17.3821502 },
    { lat: 48.611614, lng: 17.3807889 },
    { lat: 48.6118959, lng: 17.3799792 },
    { lat: 48.6116215, lng: 17.3796491 },
    { lat: 48.6115822, lng: 17.3792548 },
    { lat: 48.6112295, lng: 17.3792147 },
    { lat: 48.6110764, lng: 17.3793098 },
    { lat: 48.6110994, lng: 17.3798218 },
    { lat: 48.6101555, lng: 17.3860467 },
    { lat: 48.608412, lng: 17.3968009 },
    { lat: 48.6070169, lng: 17.3960643 },
    { lat: 48.6070034, lng: 17.3961694 },
    { lat: 48.6060133, lng: 17.3956541 },
    { lat: 48.6058698, lng: 17.3924793 },
    { lat: 48.6055441, lng: 17.3935176 },
    { lat: 48.6046418, lng: 17.3922471 },
    { lat: 48.6034223, lng: 17.394049 },
    { lat: 48.6036424, lng: 17.3945887 },
    { lat: 48.6040152, lng: 17.3945706 },
    { lat: 48.6046816, lng: 17.3952687 },
    { lat: 48.604978, lng: 17.3954856 },
    { lat: 48.6048888, lng: 17.3960088 },
    { lat: 48.6046469, lng: 17.3966711 },
    { lat: 48.6041633, lng: 17.3972626 },
    { lat: 48.6038695, lng: 17.3975392 },
    { lat: 48.6035573, lng: 17.397689 },
    { lat: 48.603194, lng: 17.3977467 },
    { lat: 48.6031162, lng: 17.3980119 },
    { lat: 48.602842, lng: 17.3977874 },
    { lat: 48.6027277, lng: 17.3978706 },
    { lat: 48.6026077, lng: 17.3981827 },
    { lat: 48.6024749, lng: 17.3983096 },
    { lat: 48.6023101, lng: 17.3986097 },
    { lat: 48.6022693, lng: 17.3990986 },
    { lat: 48.6023694, lng: 17.3993872 },
    { lat: 48.6024378, lng: 17.4004106 },
    { lat: 48.6023068, lng: 17.4006037 },
    { lat: 48.6021675, lng: 17.4009639 },
    { lat: 48.6010825, lng: 17.4005853 },
    { lat: 48.6003, lng: 17.4015672 },
    { lat: 48.5990982, lng: 17.4026838 },
    { lat: 48.5982985, lng: 17.4036809 },
    { lat: 48.5979849, lng: 17.4039144 },
    { lat: 48.5976889, lng: 17.4043854 },
    { lat: 48.5976348, lng: 17.4047348 },
    { lat: 48.5934917, lng: 17.4006799 },
    { lat: 48.5902403, lng: 17.4067654 },
    { lat: 48.5903552, lng: 17.4072498 },
    { lat: 48.5902153, lng: 17.4075326 },
    { lat: 48.5899352, lng: 17.4079183 },
    { lat: 48.5893068, lng: 17.4083471 },
    { lat: 48.5891546, lng: 17.4082299 },
    { lat: 48.5887211, lng: 17.4084256 },
    { lat: 48.5884574, lng: 17.40882 },
    { lat: 48.5882783, lng: 17.4089134 },
    { lat: 48.588118, lng: 17.409161 },
    { lat: 48.5878071, lng: 17.4094332 },
    { lat: 48.5872188, lng: 17.4096983 },
    { lat: 48.587023, lng: 17.4099022 },
    { lat: 48.5863387, lng: 17.4100633 },
    { lat: 48.5859236, lng: 17.4103344 },
    { lat: 48.5852316, lng: 17.4105669 },
    { lat: 48.5850621, lng: 17.4101441 },
    { lat: 48.5849157, lng: 17.4086855 },
    { lat: 48.5842922, lng: 17.4087346 },
    { lat: 48.5834246, lng: 17.4090653 },
    { lat: 48.5824703, lng: 17.409941 },
    { lat: 48.5819055, lng: 17.4108486 },
    { lat: 48.5816675, lng: 17.4109788 },
    { lat: 48.5812093, lng: 17.410949 },
    { lat: 48.5806112, lng: 17.4110079 },
    { lat: 48.5806329, lng: 17.4110472 },
    { lat: 48.579853, lng: 17.4114938 },
    { lat: 48.5797274, lng: 17.4119784 },
    { lat: 48.5788984, lng: 17.4117002 },
    { lat: 48.5782865, lng: 17.412212 },
    { lat: 48.5778489, lng: 17.4126739 },
    { lat: 48.5775785, lng: 17.413351 },
    { lat: 48.5775423, lng: 17.4137861 },
  ],
  HradištepodVrátnom: [
    { lat: 48.665564, lng: 17.501129 },
    { lat: 48.66557, lng: 17.50103 },
    { lat: 48.665595, lng: 17.500681 },
    { lat: 48.665625, lng: 17.50044 },
    { lat: 48.665731, lng: 17.500017 },
    { lat: 48.66586, lng: 17.499551 },
    { lat: 48.666016, lng: 17.499135 },
    { lat: 48.666131, lng: 17.498886 },
    { lat: 48.666361, lng: 17.498469 },
    { lat: 48.666503, lng: 17.49823 },
    { lat: 48.666635, lng: 17.498065 },
    { lat: 48.666709, lng: 17.49793 },
    { lat: 48.666794, lng: 17.49773 },
    { lat: 48.666997, lng: 17.497334 },
    { lat: 48.667218, lng: 17.497024 },
    { lat: 48.66722, lng: 17.49702 },
    { lat: 48.667226, lng: 17.497011 },
    { lat: 48.667307, lng: 17.496861 },
    { lat: 48.66737, lng: 17.496745 },
    { lat: 48.667475, lng: 17.496547 },
    { lat: 48.66754, lng: 17.496428 },
    { lat: 48.667561, lng: 17.496388 },
    { lat: 48.667577, lng: 17.496324 },
    { lat: 48.66764, lng: 17.496083 },
    { lat: 48.667719, lng: 17.495957 },
    { lat: 48.667746, lng: 17.495914 },
    { lat: 48.667747, lng: 17.495843 },
    { lat: 48.667747, lng: 17.495754 },
    { lat: 48.667779, lng: 17.495699 },
    { lat: 48.66782, lng: 17.49559 },
    { lat: 48.667907, lng: 17.49543 },
    { lat: 48.668058, lng: 17.494911 },
    { lat: 48.668148, lng: 17.494806 },
    { lat: 48.6669003, lng: 17.4940633 },
    { lat: 48.667139, lng: 17.4934302 },
    { lat: 48.6671846, lng: 17.4931909 },
    { lat: 48.6670875, lng: 17.4928531 },
    { lat: 48.6670978, lng: 17.4926433 },
    { lat: 48.667042, lng: 17.4925143 },
    { lat: 48.6664432, lng: 17.4930404 },
    { lat: 48.6661661, lng: 17.4932111 },
    { lat: 48.665729, lng: 17.4933336 },
    { lat: 48.6647396, lng: 17.4931041 },
    { lat: 48.6641699, lng: 17.4929183 },
    { lat: 48.6639556, lng: 17.4927644 },
    { lat: 48.6639542, lng: 17.4926352 },
    { lat: 48.6643222, lng: 17.4916225 },
    { lat: 48.6644115, lng: 17.4914484 },
    { lat: 48.664452, lng: 17.4914971 },
    { lat: 48.6645109, lng: 17.4908101 },
    { lat: 48.6644822, lng: 17.4897743 },
    { lat: 48.6643938, lng: 17.4887786 },
    { lat: 48.6642339, lng: 17.4888393 },
    { lat: 48.6640951, lng: 17.4885962 },
    { lat: 48.6635483, lng: 17.4884693 },
    { lat: 48.6637263, lng: 17.4878057 },
    { lat: 48.6634981, lng: 17.486885 },
    { lat: 48.6634894, lng: 17.4867305 },
    { lat: 48.66358, lng: 17.4864511 },
    { lat: 48.6632622, lng: 17.4864974 },
    { lat: 48.6623995, lng: 17.4869076 },
    { lat: 48.6623837, lng: 17.4867677 },
    { lat: 48.6620915, lng: 17.4869314 },
    { lat: 48.661534, lng: 17.4870772 },
    { lat: 48.6611795, lng: 17.4870623 },
    { lat: 48.6609374, lng: 17.4869653 },
    { lat: 48.6604334, lng: 17.4865935 },
    { lat: 48.6604797, lng: 17.486361 },
    { lat: 48.6604323, lng: 17.4859385 },
    { lat: 48.6600945, lng: 17.4851214 },
    { lat: 48.6601318, lng: 17.485087 },
    { lat: 48.6601149, lng: 17.4849626 },
    { lat: 48.6599984, lng: 17.484572 },
    { lat: 48.6597354, lng: 17.4842044 },
    { lat: 48.6590889, lng: 17.4836645 },
    { lat: 48.6590203, lng: 17.4834212 },
    { lat: 48.6590368, lng: 17.4830601 },
    { lat: 48.6588164, lng: 17.4829889 },
    { lat: 48.6584946, lng: 17.4830076 },
    { lat: 48.6582715, lng: 17.481564 },
    { lat: 48.6581628, lng: 17.4812404 },
    { lat: 48.6579207, lng: 17.4807216 },
    { lat: 48.6576907, lng: 17.4804636 },
    { lat: 48.657423, lng: 17.4798635 },
    { lat: 48.6562282, lng: 17.4799164 },
    { lat: 48.6553784, lng: 17.4797837 },
    { lat: 48.6554141, lng: 17.4795135 },
    { lat: 48.6553838, lng: 17.4794086 },
    { lat: 48.6552271, lng: 17.4793796 },
    { lat: 48.6538836, lng: 17.4794574 },
    { lat: 48.6539984, lng: 17.4779661 },
    { lat: 48.6528835, lng: 17.4775217 },
    { lat: 48.6530508, lng: 17.4764286 },
    { lat: 48.653094, lng: 17.47564 },
    { lat: 48.6531177, lng: 17.4749665 },
    { lat: 48.6516264, lng: 17.4743758 },
    { lat: 48.6517908, lng: 17.4754627 },
    { lat: 48.6512435, lng: 17.4756496 },
    { lat: 48.6501159, lng: 17.4763839 },
    { lat: 48.6491447, lng: 17.4772465 },
    { lat: 48.6485116, lng: 17.4779277 },
    { lat: 48.6484627, lng: 17.4780473 },
    { lat: 48.6481941, lng: 17.4776261 },
    { lat: 48.6470871, lng: 17.476528 },
    { lat: 48.6468507, lng: 17.476081 },
    { lat: 48.6463962, lng: 17.475594 },
    { lat: 48.6456985, lng: 17.4753864 },
    { lat: 48.6455309, lng: 17.47515 },
    { lat: 48.6452366, lng: 17.4755804 },
    { lat: 48.6450237, lng: 17.4760514 },
    { lat: 48.6436811, lng: 17.4744453 },
    { lat: 48.6429022, lng: 17.4736046 },
    { lat: 48.6425755, lng: 17.4734961 },
    { lat: 48.6421926, lng: 17.4727716 },
    { lat: 48.6420466, lng: 17.4719544 },
    { lat: 48.6414428, lng: 17.4723313 },
    { lat: 48.6409994, lng: 17.4726916 },
    { lat: 48.6406678, lng: 17.4717989 },
    { lat: 48.6392699, lng: 17.4725619 },
    { lat: 48.638945, lng: 17.4712951 },
    { lat: 48.6383885, lng: 17.4716183 },
    { lat: 48.6374476, lng: 17.4708395 },
    { lat: 48.6368295, lng: 17.4701629 },
    { lat: 48.6370194, lng: 17.4696221 },
    { lat: 48.6362616, lng: 17.4696219 },
    { lat: 48.6352415, lng: 17.4693507 },
    { lat: 48.6352863, lng: 17.4699805 },
    { lat: 48.6352205, lng: 17.4712816 },
    { lat: 48.6346805, lng: 17.4712089 },
    { lat: 48.634452, lng: 17.4712393 },
    { lat: 48.6342384, lng: 17.4711301 },
    { lat: 48.6338892, lng: 17.4710914 },
    { lat: 48.6339146, lng: 17.4678359 },
    { lat: 48.6338249, lng: 17.4678479 },
    { lat: 48.6331642, lng: 17.4656977 },
    { lat: 48.6318073, lng: 17.4662883 },
    { lat: 48.6316106, lng: 17.465087 },
    { lat: 48.6308237, lng: 17.4653199 },
    { lat: 48.6309386, lng: 17.4659858 },
    { lat: 48.6305446, lng: 17.4658276 },
    { lat: 48.6297292, lng: 17.4656902 },
    { lat: 48.629531, lng: 17.4657512 },
    { lat: 48.6281661, lng: 17.4665187 },
    { lat: 48.6279326, lng: 17.4647765 },
    { lat: 48.6277268, lng: 17.4638551 },
    { lat: 48.6262521, lng: 17.4645293 },
    { lat: 48.6256992, lng: 17.4650833 },
    { lat: 48.6248309, lng: 17.4661103 },
    { lat: 48.6246149, lng: 17.4656452 },
    { lat: 48.6245237, lng: 17.4653154 },
    { lat: 48.6241607, lng: 17.4634263 },
    { lat: 48.6237405, lng: 17.4618555 },
    { lat: 48.6236019, lng: 17.4609779 },
    { lat: 48.6225147, lng: 17.4583956 },
    { lat: 48.6219715, lng: 17.458679 },
    { lat: 48.6213771, lng: 17.4591856 },
    { lat: 48.6213926, lng: 17.4593904 },
    { lat: 48.6215689, lng: 17.4597991 },
    { lat: 48.6214661, lng: 17.4606062 },
    { lat: 48.6216788, lng: 17.4613887 },
    { lat: 48.6211264, lng: 17.4617895 },
    { lat: 48.6212389, lng: 17.4622979 },
    { lat: 48.6214975, lng: 17.4629834 },
    { lat: 48.621812, lng: 17.463307 },
    { lat: 48.621354, lng: 17.4637204 },
    { lat: 48.6213368, lng: 17.4637536 },
    { lat: 48.6213251, lng: 17.4637817 },
    { lat: 48.6212877, lng: 17.4638675 },
    { lat: 48.6211218, lng: 17.4639735 },
    { lat: 48.6205388, lng: 17.4640942 },
    { lat: 48.6203315, lng: 17.4642283 },
    { lat: 48.6201563, lng: 17.464241 },
    { lat: 48.6200915, lng: 17.4643236 },
    { lat: 48.6195564, lng: 17.4643456 },
    { lat: 48.6191076, lng: 17.4642144 },
    { lat: 48.6180358, lng: 17.4647437 },
    { lat: 48.6176966, lng: 17.4650723 },
    { lat: 48.6172285, lng: 17.4651527 },
    { lat: 48.6168815, lng: 17.4654288 },
    { lat: 48.6160732, lng: 17.4663141 },
    { lat: 48.615468, lng: 17.4667236 },
    { lat: 48.6148226, lng: 17.4668558 },
    { lat: 48.6135443, lng: 17.4674765 },
    { lat: 48.6127415, lng: 17.4676924 },
    { lat: 48.6121215, lng: 17.4672181 },
    { lat: 48.6117718, lng: 17.4665867 },
    { lat: 48.6114748, lng: 17.4663066 },
    { lat: 48.6110801, lng: 17.4662635 },
    { lat: 48.6102408, lng: 17.4657998 },
    { lat: 48.6097672, lng: 17.4657261 },
    { lat: 48.6090186, lng: 17.4658691 },
    { lat: 48.60858, lng: 17.4660573 },
    { lat: 48.6082475, lng: 17.4664048 },
    { lat: 48.6075932, lng: 17.4667114 },
    { lat: 48.6067488, lng: 17.4672849 },
    { lat: 48.6059853, lng: 17.4680417 },
    { lat: 48.6053639, lng: 17.469114 },
    { lat: 48.6045427, lng: 17.4697096 },
    { lat: 48.604326, lng: 17.4694489 },
    { lat: 48.6040662, lng: 17.4695346 },
    { lat: 48.6030366, lng: 17.4712547 },
    { lat: 48.602338, lng: 17.4727773 },
    { lat: 48.6024533, lng: 17.4733429 },
    { lat: 48.6023694, lng: 17.4734072 },
    { lat: 48.6023179, lng: 17.4732392 },
    { lat: 48.6022563, lng: 17.4732824 },
    { lat: 48.6022304, lng: 17.4735135 },
    { lat: 48.6021075, lng: 17.4737269 },
    { lat: 48.6020281, lng: 17.4734398 },
    { lat: 48.601711, lng: 17.4733379 },
    { lat: 48.601549, lng: 17.4735059 },
    { lat: 48.6014324, lng: 17.4733774 },
    { lat: 48.6013835, lng: 17.4730723 },
    { lat: 48.60092, lng: 17.4731369 },
    { lat: 48.6007453, lng: 17.473406 },
    { lat: 48.6005849, lng: 17.4734073 },
    { lat: 48.6005424, lng: 17.4733086 },
    { lat: 48.6005753, lng: 17.47308 },
    { lat: 48.6004469, lng: 17.4730698 },
    { lat: 48.6003501, lng: 17.4732837 },
    { lat: 48.6001306, lng: 17.4731005 },
    { lat: 48.6000934, lng: 17.4729083 },
    { lat: 48.6001504, lng: 17.4727052 },
    { lat: 48.6001289, lng: 17.4726162 },
    { lat: 48.6000066, lng: 17.4726654 },
    { lat: 48.5999066, lng: 17.4726197 },
    { lat: 48.5998619, lng: 17.4724678 },
    { lat: 48.5995764, lng: 17.4725076 },
    { lat: 48.5991882, lng: 17.4719479 },
    { lat: 48.5987896, lng: 17.4715785 },
    { lat: 48.5985091, lng: 17.4715554 },
    { lat: 48.5980773, lng: 17.4717257 },
    { lat: 48.59793, lng: 17.4714549 },
    { lat: 48.5975196, lng: 17.4710969 },
    { lat: 48.5972567, lng: 17.4707274 },
    { lat: 48.5968935, lng: 17.4700488 },
    { lat: 48.5962404, lng: 17.4693743 },
    { lat: 48.5952884, lng: 17.4696204 },
    { lat: 48.5938646, lng: 17.4702252 },
    { lat: 48.5932453, lng: 17.4702926 },
    { lat: 48.591936, lng: 17.4711668 },
    { lat: 48.5892276, lng: 17.471447 },
    { lat: 48.5885887, lng: 17.471947 },
    { lat: 48.5884807, lng: 17.4725007 },
    { lat: 48.587627, lng: 17.4737366 },
    { lat: 48.5870378, lng: 17.4740417 },
    { lat: 48.5862608, lng: 17.474676 },
    { lat: 48.5860661, lng: 17.4750728 },
    { lat: 48.5860212, lng: 17.4754139 },
    { lat: 48.5856462, lng: 17.4750793 },
    { lat: 48.5854436, lng: 17.4750349 },
    { lat: 48.5852427, lng: 17.4748248 },
    { lat: 48.5849089, lng: 17.4746751 },
    { lat: 48.5842626, lng: 17.4742287 },
    { lat: 48.5837307, lng: 17.4740525 },
    { lat: 48.5833373, lng: 17.4737113 },
    { lat: 48.5830815, lng: 17.4738259 },
    { lat: 48.5828283, lng: 17.4742808 },
    { lat: 48.5826507, lng: 17.4744321 },
    { lat: 48.582584, lng: 17.474489 },
    { lat: 48.582571, lng: 17.474821 },
    { lat: 48.582677, lng: 17.47502 },
    { lat: 48.582669, lng: 17.475307 },
    { lat: 48.582714, lng: 17.475651 },
    { lat: 48.582705, lng: 17.475783 },
    { lat: 48.582642, lng: 17.47605 },
    { lat: 48.5827, lng: 17.476158 },
    { lat: 48.582786, lng: 17.476095 },
    { lat: 48.582646, lng: 17.47634 },
    { lat: 48.582568, lng: 17.476407 },
    { lat: 48.582522, lng: 17.476827 },
    { lat: 48.582463, lng: 17.477011 },
    { lat: 48.582443, lng: 17.477077 },
    { lat: 48.582368, lng: 17.477119 },
    { lat: 48.582214, lng: 17.477771 },
    { lat: 48.582315, lng: 17.477979 },
    { lat: 48.582246, lng: 17.478166 },
    { lat: 48.582181, lng: 17.478269 },
    { lat: 48.582183, lng: 17.478401 },
    { lat: 48.58203, lng: 17.478619 },
    { lat: 48.5821, lng: 17.478629 },
    { lat: 48.582102, lng: 17.479201 },
    { lat: 48.582146, lng: 17.479313 },
    { lat: 48.582038, lng: 17.479418 },
    { lat: 48.58206, lng: 17.479488 },
    { lat: 48.582174, lng: 17.479819 },
    { lat: 48.582203, lng: 17.48038 },
    { lat: 48.58231, lng: 17.480408 },
    { lat: 48.582416, lng: 17.48113 },
    { lat: 48.582402, lng: 17.481391 },
    { lat: 48.582506, lng: 17.481422 },
    { lat: 48.582547, lng: 17.481489 },
    { lat: 48.582564, lng: 17.481539 },
    { lat: 48.582596, lng: 17.481632 },
    { lat: 48.582619, lng: 17.481688 },
    { lat: 48.582733, lng: 17.481919 },
    { lat: 48.582904, lng: 17.482228 },
    { lat: 48.582988, lng: 17.482298 },
    { lat: 48.583146, lng: 17.482492 },
    { lat: 48.583253, lng: 17.482573 },
    { lat: 48.58334, lng: 17.482826 },
    { lat: 48.58345, lng: 17.483037 },
    { lat: 48.583473, lng: 17.483297 },
    { lat: 48.583462, lng: 17.483597 },
    { lat: 48.583671, lng: 17.483816 },
    { lat: 48.583768, lng: 17.483704 },
    { lat: 48.58381, lng: 17.483817 },
    { lat: 48.583872, lng: 17.483794 },
    { lat: 48.584049, lng: 17.483729 },
    { lat: 48.5841, lng: 17.483699 },
    { lat: 48.58429, lng: 17.483597 },
    { lat: 48.584434, lng: 17.483556 },
    { lat: 48.584615, lng: 17.48357 },
    { lat: 48.584781, lng: 17.483711 },
    { lat: 48.584838, lng: 17.483801 },
    { lat: 48.585055, lng: 17.483873 },
    { lat: 48.585213, lng: 17.484034 },
    { lat: 48.585336, lng: 17.484037 },
    { lat: 48.585418, lng: 17.484125 },
    { lat: 48.58543, lng: 17.484163 },
    { lat: 48.585506, lng: 17.48469 },
    { lat: 48.585557, lng: 17.484801 },
    { lat: 48.585758, lng: 17.484974 },
    { lat: 48.585853, lng: 17.485102 },
    { lat: 48.585955, lng: 17.485128 },
    { lat: 48.586275, lng: 17.48533 },
    { lat: 48.5864, lng: 17.485261 },
    { lat: 48.586524, lng: 17.485265 },
    { lat: 48.586603, lng: 17.485368 },
    { lat: 48.58662, lng: 17.485367 },
    { lat: 48.586847, lng: 17.485499 },
    { lat: 48.58703, lng: 17.485595 },
    { lat: 48.587116, lng: 17.485476 },
    { lat: 48.587325, lng: 17.485448 },
    { lat: 48.587619, lng: 17.485749 },
    { lat: 48.587649, lng: 17.485926 },
    { lat: 48.587682, lng: 17.485984 },
    { lat: 48.587798, lng: 17.485988 },
    { lat: 48.587866, lng: 17.485948 },
    { lat: 48.587889, lng: 17.48603 },
    { lat: 48.58784, lng: 17.48605 },
    { lat: 48.587816, lng: 17.486129 },
    { lat: 48.587985, lng: 17.486331 },
    { lat: 48.588105, lng: 17.486449 },
    { lat: 48.588297, lng: 17.48686 },
    { lat: 48.588321, lng: 17.486927 },
    { lat: 48.588479, lng: 17.487393 },
    { lat: 48.588623, lng: 17.487371 },
    { lat: 48.588683, lng: 17.487458 },
    { lat: 48.588788, lng: 17.487559 },
    { lat: 48.588744, lng: 17.487713 },
    { lat: 48.588787, lng: 17.487821 },
    { lat: 48.589084, lng: 17.487942 },
    { lat: 48.589391, lng: 17.488237 },
    { lat: 48.589471, lng: 17.488225 },
    { lat: 48.589493, lng: 17.488553 },
    { lat: 48.589495, lng: 17.488683 },
    { lat: 48.58949, lng: 17.488673 },
    { lat: 48.589148, lng: 17.489449 },
    { lat: 48.58904, lng: 17.489826 },
    { lat: 48.589029, lng: 17.490203 },
    { lat: 48.588777, lng: 17.492135 },
    { lat: 48.588748, lng: 17.492773 },
    { lat: 48.588783, lng: 17.492984 },
    { lat: 48.588788, lng: 17.493018 },
    { lat: 48.58898, lng: 17.493764 },
    { lat: 48.58922, lng: 17.494698 },
    { lat: 48.589534, lng: 17.495904 },
    { lat: 48.589527, lng: 17.495946 },
    { lat: 48.590301, lng: 17.496369 },
    { lat: 48.590502, lng: 17.496511 },
    { lat: 48.590841, lng: 17.496858 },
    { lat: 48.591033, lng: 17.497097 },
    { lat: 48.591328, lng: 17.497445 },
    { lat: 48.5913471, lng: 17.4974612 },
    { lat: 48.592056, lng: 17.498064 },
    { lat: 48.5921162, lng: 17.49813 },
    { lat: 48.592224, lng: 17.498248 },
    { lat: 48.5924692, lng: 17.4982045 },
    { lat: 48.593053, lng: 17.498101 },
    { lat: 48.593415, lng: 17.498016 },
    { lat: 48.594242, lng: 17.497853 },
    { lat: 48.594571, lng: 17.49762 },
    { lat: 48.59501, lng: 17.497254 },
    { lat: 48.595082, lng: 17.497153 },
    { lat: 48.5959626, lng: 17.4967308 },
    { lat: 48.596147, lng: 17.496679 },
    { lat: 48.597173, lng: 17.496976 },
    { lat: 48.597519, lng: 17.497356 },
    { lat: 48.598698, lng: 17.498695 },
    { lat: 48.599075, lng: 17.499102 },
    { lat: 48.600273, lng: 17.50036 },
    { lat: 48.602848, lng: 17.503333 },
    { lat: 48.602945, lng: 17.503399 },
    { lat: 48.605402, lng: 17.50497 },
    { lat: 48.606032, lng: 17.505017 },
    { lat: 48.606645, lng: 17.505528 },
    { lat: 48.606689, lng: 17.505562 },
    { lat: 48.607841, lng: 17.50649 },
    { lat: 48.60851, lng: 17.507271 },
    { lat: 48.609069, lng: 17.507928 },
    { lat: 48.609873, lng: 17.508834 },
    { lat: 48.610668, lng: 17.509654 },
    { lat: 48.611676, lng: 17.510567 },
    { lat: 48.612428, lng: 17.511271 },
    { lat: 48.613009, lng: 17.511916 },
    { lat: 48.613612, lng: 17.51255 },
    { lat: 48.613778, lng: 17.51274 },
    { lat: 48.614029, lng: 17.513055 },
    { lat: 48.614576, lng: 17.513759 },
    { lat: 48.615452, lng: 17.514853 },
    { lat: 48.616475, lng: 17.516026 },
    { lat: 48.617114, lng: 17.516599 },
    { lat: 48.618212, lng: 17.517605 },
    { lat: 48.618651, lng: 17.517993 },
    { lat: 48.619595, lng: 17.518698 },
    { lat: 48.620255, lng: 17.519306 },
    { lat: 48.620755, lng: 17.519698 },
    { lat: 48.620863, lng: 17.519792 },
    { lat: 48.620884, lng: 17.51984 },
    { lat: 48.620912, lng: 17.519902 },
    { lat: 48.621177, lng: 17.520061 },
    { lat: 48.62145, lng: 17.520254 },
    { lat: 48.62231, lng: 17.521025 },
    { lat: 48.622461, lng: 17.521195 },
    { lat: 48.622547, lng: 17.521386 },
    { lat: 48.622599, lng: 17.521619 },
    { lat: 48.622655, lng: 17.52196 },
    { lat: 48.622649, lng: 17.522469 },
    { lat: 48.622673, lng: 17.522634 },
    { lat: 48.622698, lng: 17.522747 },
    { lat: 48.622817, lng: 17.522886 },
    { lat: 48.622952, lng: 17.52297 },
    { lat: 48.623021, lng: 17.522995 },
    { lat: 48.62317, lng: 17.523 },
    { lat: 48.623687, lng: 17.522785 },
    { lat: 48.623839, lng: 17.522761 },
    { lat: 48.623995, lng: 17.522796 },
    { lat: 48.624313, lng: 17.522827 },
    { lat: 48.624369, lng: 17.522857 },
    { lat: 48.624635, lng: 17.523107 },
    { lat: 48.624801, lng: 17.523444 },
    { lat: 48.625269, lng: 17.524215 },
    { lat: 48.625307, lng: 17.524385 },
    { lat: 48.625289, lng: 17.524795 },
    { lat: 48.625349, lng: 17.525155 },
    { lat: 48.625288, lng: 17.52564 },
    { lat: 48.625326, lng: 17.526065 },
    { lat: 48.625415, lng: 17.52636 },
    { lat: 48.625518, lng: 17.52648 },
    { lat: 48.626094, lng: 17.526922 },
    { lat: 48.626289, lng: 17.527127 },
    { lat: 48.6264788, lng: 17.5273499 },
    { lat: 48.626607, lng: 17.527628 },
    { lat: 48.626652, lng: 17.528428 },
    { lat: 48.626504, lng: 17.529087 },
    { lat: 48.625472, lng: 17.53116 },
    { lat: 48.625441, lng: 17.531289 },
    { lat: 48.625457, lng: 17.531506 },
    { lat: 48.625624, lng: 17.531743 },
    { lat: 48.625775, lng: 17.531861 },
    { lat: 48.626197, lng: 17.532068 },
    { lat: 48.626354, lng: 17.5322 },
    { lat: 48.626479, lng: 17.532377 },
    { lat: 48.626583, lng: 17.532614 },
    { lat: 48.626604, lng: 17.532788 },
    { lat: 48.626607, lng: 17.532916 },
    { lat: 48.626618, lng: 17.533121 },
    { lat: 48.627697, lng: 17.531556 },
    { lat: 48.628774, lng: 17.530144 },
    { lat: 48.630579, lng: 17.527805 },
    { lat: 48.632286, lng: 17.525744 },
    { lat: 48.634607, lng: 17.522662 },
    { lat: 48.636632, lng: 17.519735 },
    { lat: 48.636921, lng: 17.519473 },
    { lat: 48.637094, lng: 17.519251 },
    { lat: 48.63716, lng: 17.519131 },
    { lat: 48.637383, lng: 17.518975 },
    { lat: 48.637508, lng: 17.518831 },
    { lat: 48.637825, lng: 17.518402 },
    { lat: 48.638014, lng: 17.518185 },
    { lat: 48.638273, lng: 17.517956 },
    { lat: 48.638549, lng: 17.517611 },
    { lat: 48.638575, lng: 17.517578 },
    { lat: 48.638608, lng: 17.517535 },
    { lat: 48.639042, lng: 17.516972 },
    { lat: 48.639588, lng: 17.51624 },
    { lat: 48.639795, lng: 17.515943 },
    { lat: 48.640464, lng: 17.515314 },
    { lat: 48.640561, lng: 17.515223 },
    { lat: 48.640684, lng: 17.515106 },
    { lat: 48.640779, lng: 17.515006 },
    { lat: 48.640854, lng: 17.514788 },
    { lat: 48.641048, lng: 17.514466 },
    { lat: 48.641412, lng: 17.513994 },
    { lat: 48.642777, lng: 17.512769 },
    { lat: 48.643687, lng: 17.511573 },
    { lat: 48.646347, lng: 17.509582 },
    { lat: 48.647021, lng: 17.508762 },
    { lat: 48.647463, lng: 17.508128 },
    { lat: 48.647483, lng: 17.508105 },
    { lat: 48.64749, lng: 17.508078 },
    { lat: 48.647506, lng: 17.507998 },
    { lat: 48.647519, lng: 17.50798 },
    { lat: 48.647612, lng: 17.50752 },
    { lat: 48.647609, lng: 17.507459 },
    { lat: 48.647723, lng: 17.506981 },
    { lat: 48.647842, lng: 17.506418 },
    { lat: 48.647983, lng: 17.505656 },
    { lat: 48.647991, lng: 17.505605 },
    { lat: 48.648095, lng: 17.50567 },
    { lat: 48.648266, lng: 17.50593 },
    { lat: 48.648495, lng: 17.506087 },
    { lat: 48.648646, lng: 17.506165 },
    { lat: 48.648745, lng: 17.506223 },
    { lat: 48.648818, lng: 17.506255 },
    { lat: 48.648966, lng: 17.506432 },
    { lat: 48.649062, lng: 17.506522 },
    { lat: 48.649268, lng: 17.506497 },
    { lat: 48.649332, lng: 17.506547 },
    { lat: 48.649489, lng: 17.506742 },
    { lat: 48.649744, lng: 17.507018 },
    { lat: 48.649782, lng: 17.507057 },
    { lat: 48.650062, lng: 17.50704 },
    { lat: 48.650286, lng: 17.507183 },
    { lat: 48.650389, lng: 17.507243 },
    { lat: 48.650494, lng: 17.507372 },
    { lat: 48.65059, lng: 17.507364 },
    { lat: 48.65063, lng: 17.507411 },
    { lat: 48.650715, lng: 17.507628 },
    { lat: 48.650731, lng: 17.507554 },
    { lat: 48.65089, lng: 17.50765 },
    { lat: 48.650993, lng: 17.507898 },
    { lat: 48.651136, lng: 17.507879 },
    { lat: 48.651291, lng: 17.507892 },
    { lat: 48.65141, lng: 17.507991 },
    { lat: 48.651487, lng: 17.507981 },
    { lat: 48.651543, lng: 17.507974 },
    { lat: 48.651589, lng: 17.507969 },
    { lat: 48.651703, lng: 17.508003 },
    { lat: 48.651743, lng: 17.508023 },
    { lat: 48.651778, lng: 17.50804 },
    { lat: 48.651835, lng: 17.508068 },
    { lat: 48.65192, lng: 17.507961 },
    { lat: 48.652047, lng: 17.508033 },
    { lat: 48.652214, lng: 17.5081 },
    { lat: 48.65232, lng: 17.508106 },
    { lat: 48.652291, lng: 17.508066 },
    { lat: 48.652609, lng: 17.508155 },
    { lat: 48.653545, lng: 17.508494 },
    { lat: 48.654358, lng: 17.507706 },
    { lat: 48.654488, lng: 17.507579 },
    { lat: 48.654586, lng: 17.507484 },
    { lat: 48.654759, lng: 17.507325 },
    { lat: 48.655289, lng: 17.506834 },
    { lat: 48.655431, lng: 17.506715 },
    { lat: 48.655633, lng: 17.506469 },
    { lat: 48.656132, lng: 17.505968 },
    { lat: 48.656444, lng: 17.50591 },
    { lat: 48.656674, lng: 17.505868 },
    { lat: 48.657291, lng: 17.505999 },
    { lat: 48.657484, lng: 17.506041 },
    { lat: 48.657555, lng: 17.506101 },
    { lat: 48.658051, lng: 17.505848 },
    { lat: 48.658354, lng: 17.505635 },
    { lat: 48.658532, lng: 17.505606 },
    { lat: 48.658701, lng: 17.50556 },
    { lat: 48.658861, lng: 17.505345 },
    { lat: 48.659013, lng: 17.505324 },
    { lat: 48.659257, lng: 17.505315 },
    { lat: 48.65953, lng: 17.505255 },
    { lat: 48.659927, lng: 17.505106 },
    { lat: 48.660172, lng: 17.505033 },
    { lat: 48.660771, lng: 17.504743 },
    { lat: 48.660823, lng: 17.504715 },
    { lat: 48.660711, lng: 17.504295 },
    { lat: 48.660621, lng: 17.503872 },
    { lat: 48.660617, lng: 17.503316 },
    { lat: 48.660657, lng: 17.503066 },
    { lat: 48.66066, lng: 17.502956 },
    { lat: 48.66073, lng: 17.502889 },
    { lat: 48.6608, lng: 17.502839 },
    { lat: 48.660972, lng: 17.502689 },
    { lat: 48.661445, lng: 17.502572 },
    { lat: 48.662617, lng: 17.50236 },
    { lat: 48.662615, lng: 17.502322 },
    { lat: 48.662613, lng: 17.502266 },
    { lat: 48.662604, lng: 17.50196 },
    { lat: 48.663679, lng: 17.501839 },
    { lat: 48.663714, lng: 17.502036 },
    { lat: 48.663724, lng: 17.5021 },
    { lat: 48.663769, lng: 17.502055 },
    { lat: 48.664188, lng: 17.501912 },
    { lat: 48.664549, lng: 17.501852 },
    { lat: 48.664856, lng: 17.501779 },
    { lat: 48.665059, lng: 17.501709 },
    { lat: 48.665334, lng: 17.501522 },
    { lat: 48.665508, lng: 17.501275 },
    { lat: 48.665564, lng: 17.501129 },
  ],
  Hlboké: [
    { lat: 48.667078, lng: 17.4255176 },
    { lat: 48.6670351, lng: 17.4251166 },
    { lat: 48.6672541, lng: 17.4247093 },
    { lat: 48.6673881, lng: 17.4240707 },
    { lat: 48.6680754, lng: 17.4227285 },
    { lat: 48.6682929, lng: 17.4215317 },
    { lat: 48.6684289, lng: 17.4212212 },
    { lat: 48.6682584, lng: 17.4207863 },
    { lat: 48.6684129, lng: 17.4206411 },
    { lat: 48.6684567, lng: 17.4199365 },
    { lat: 48.668002, lng: 17.418893 },
    { lat: 48.667992, lng: 17.4162814 },
    { lat: 48.6682495, lng: 17.4152484 },
    { lat: 48.6683069, lng: 17.4145271 },
    { lat: 48.6685495, lng: 17.4142077 },
    { lat: 48.668761, lng: 17.4133701 },
    { lat: 48.6688248, lng: 17.4129234 },
    { lat: 48.668326, lng: 17.4101291 },
    { lat: 48.6678659, lng: 17.4101049 },
    { lat: 48.6680033, lng: 17.4088227 },
    { lat: 48.6678737, lng: 17.4084051 },
    { lat: 48.667869, lng: 17.4082446 },
    { lat: 48.6679969, lng: 17.4080232 },
    { lat: 48.6679789, lng: 17.4077768 },
    { lat: 48.6665592, lng: 17.4079549 },
    { lat: 48.6666199, lng: 17.4061122 },
    { lat: 48.6667525, lng: 17.4048078 },
    { lat: 48.6670469, lng: 17.4030847 },
    { lat: 48.6673865, lng: 17.4016107 },
    { lat: 48.6684142, lng: 17.3983055 },
    { lat: 48.6669928, lng: 17.3978603 },
    { lat: 48.6654936, lng: 17.3975559 },
    { lat: 48.664997, lng: 17.3973879 },
    { lat: 48.6650093, lng: 17.3968958 },
    { lat: 48.6649482, lng: 17.3967573 },
    { lat: 48.6635095, lng: 17.3959888 },
    { lat: 48.6635035, lng: 17.3959153 },
    { lat: 48.6632637, lng: 17.3959993 },
    { lat: 48.66315, lng: 17.3961075 },
    { lat: 48.6628987, lng: 17.3965876 },
    { lat: 48.6624345, lng: 17.3969375 },
    { lat: 48.6609177, lng: 17.3974822 },
    { lat: 48.6603546, lng: 17.3979106 },
    { lat: 48.6601662, lng: 17.3977372 },
    { lat: 48.6583954, lng: 17.3967097 },
    { lat: 48.6558521, lng: 17.3953167 },
    { lat: 48.6546784, lng: 17.3947383 },
    { lat: 48.6539964, lng: 17.394463 },
    { lat: 48.6513718, lng: 17.3936137 },
    { lat: 48.6489875, lng: 17.392564 },
    { lat: 48.6484917, lng: 17.3922688 },
    { lat: 48.6473563, lng: 17.3913246 },
    { lat: 48.6470642, lng: 17.3909476 },
    { lat: 48.6464476, lng: 17.3906505 },
    { lat: 48.6461701, lng: 17.3902903 },
    { lat: 48.6457053, lng: 17.3893051 },
    { lat: 48.6451682, lng: 17.3888068 },
    { lat: 48.644931, lng: 17.3883881 },
    { lat: 48.6446154, lng: 17.3880478 },
    { lat: 48.6443708, lng: 17.3876724 },
    { lat: 48.6442831, lng: 17.3874696 },
    { lat: 48.644256, lng: 17.3871711 },
    { lat: 48.6442496, lng: 17.3866752 },
    { lat: 48.6443094, lng: 17.3859385 },
    { lat: 48.6436285, lng: 17.3836909 },
    { lat: 48.6433651, lng: 17.3844552 },
    { lat: 48.6432848, lng: 17.3840888 },
    { lat: 48.6431486, lng: 17.3845485 },
    { lat: 48.6430658, lng: 17.3851375 },
    { lat: 48.6426121, lng: 17.3862049 },
    { lat: 48.6419743, lng: 17.3868061 },
    { lat: 48.6410951, lng: 17.3873739 },
    { lat: 48.6405711, lng: 17.3875491 },
    { lat: 48.6401891, lng: 17.3876157 },
    { lat: 48.6399986, lng: 17.3875542 },
    { lat: 48.6394019, lng: 17.3871804 },
    { lat: 48.6388657, lng: 17.3874137 },
    { lat: 48.6387741, lng: 17.3872704 },
    { lat: 48.638597, lng: 17.3874855 },
    { lat: 48.6381058, lng: 17.388325 },
    { lat: 48.637269, lng: 17.3901925 },
    { lat: 48.6366289, lng: 17.3918735 },
    { lat: 48.6363789, lng: 17.3927428 },
    { lat: 48.6348512, lng: 17.3949497 },
    { lat: 48.6351183, lng: 17.3956039 },
    { lat: 48.6349384, lng: 17.3958589 },
    { lat: 48.6343293, lng: 17.3974645 },
    { lat: 48.6318953, lng: 17.3943727 },
    { lat: 48.6244177, lng: 17.3853474 },
    { lat: 48.6244641, lng: 17.3852751 },
    { lat: 48.625218, lng: 17.3841015 },
    { lat: 48.6263025, lng: 17.3826919 },
    { lat: 48.626038, lng: 17.381331 },
    { lat: 48.6262327, lng: 17.3807452 },
    { lat: 48.6265881, lng: 17.3801126 },
    { lat: 48.6269881, lng: 17.3795901 },
    { lat: 48.6283178, lng: 17.3781671 },
    { lat: 48.629465, lng: 17.3772393 },
    { lat: 48.6298524, lng: 17.3767844 },
    { lat: 48.6299036, lng: 17.3766847 },
    { lat: 48.6298743, lng: 17.3766526 },
    { lat: 48.6304021, lng: 17.3756298 },
    { lat: 48.6307383, lng: 17.3746464 },
    { lat: 48.6307644, lng: 17.3747022 },
    { lat: 48.6310714, lng: 17.3746797 },
    { lat: 48.6312538, lng: 17.3747771 },
    { lat: 48.632171, lng: 17.374497 },
    { lat: 48.6324383, lng: 17.3742647 },
    { lat: 48.6324197, lng: 17.3741714 },
    { lat: 48.6331138, lng: 17.3733258 },
    { lat: 48.6333064, lng: 17.3733187 },
    { lat: 48.6337065, lng: 17.3728165 },
    { lat: 48.6339942, lng: 17.3729165 },
    { lat: 48.6342189, lng: 17.3731397 },
    { lat: 48.634313, lng: 17.3731461 },
    { lat: 48.6344268, lng: 17.3730211 },
    { lat: 48.6349635, lng: 17.3726912 },
    { lat: 48.6362966, lng: 17.3714957 },
    { lat: 48.6366682, lng: 17.3712694 },
    { lat: 48.6372724, lng: 17.3710748 },
    { lat: 48.6373233, lng: 17.370798 },
    { lat: 48.6372911, lng: 17.370477 },
    { lat: 48.6373214, lng: 17.3700533 },
    { lat: 48.638051, lng: 17.3694973 },
    { lat: 48.6383497, lng: 17.3691388 },
    { lat: 48.6388184, lng: 17.3691035 },
    { lat: 48.6394254, lng: 17.3687634 },
    { lat: 48.639927, lng: 17.3682425 },
    { lat: 48.640043, lng: 17.3681846 },
    { lat: 48.640368, lng: 17.3676558 },
    { lat: 48.6405743, lng: 17.3672672 },
    { lat: 48.640369, lng: 17.3658449 },
    { lat: 48.6449512, lng: 17.3639279 },
    { lat: 48.6448298, lng: 17.363051 },
    { lat: 48.6449083, lng: 17.3626226 },
    { lat: 48.6451089, lng: 17.3625622 },
    { lat: 48.6451514, lng: 17.3622945 },
    { lat: 48.6454594, lng: 17.3622046 },
    { lat: 48.6457715, lng: 17.3619477 },
    { lat: 48.6462672, lng: 17.3576504 },
    { lat: 48.6465749, lng: 17.3576074 },
    { lat: 48.6468265, lng: 17.357848 },
    { lat: 48.6478434, lng: 17.3582676 },
    { lat: 48.6489823, lng: 17.3581536 },
    { lat: 48.6490843, lng: 17.3569076 },
    { lat: 48.6540742, lng: 17.3543094 },
    { lat: 48.6543205, lng: 17.3538921 },
    { lat: 48.6544987, lng: 17.3533355 },
    { lat: 48.6560016, lng: 17.3510514 },
    { lat: 48.657985, lng: 17.3478359 },
    { lat: 48.6586668, lng: 17.3465803 },
    { lat: 48.6605703, lng: 17.3422261 },
    { lat: 48.6618011, lng: 17.340572 },
    { lat: 48.6624648, lng: 17.3358158 },
    { lat: 48.6623737, lng: 17.3343457 },
    { lat: 48.6628943, lng: 17.3319358 },
    { lat: 48.663035, lng: 17.3310808 },
    { lat: 48.6631813, lng: 17.3302065 },
    { lat: 48.6623994, lng: 17.3302205 },
    { lat: 48.6620934, lng: 17.3298397 },
    { lat: 48.6613626, lng: 17.3286992 },
    { lat: 48.6612092, lng: 17.3285651 },
    { lat: 48.6606223, lng: 17.3275647 },
    { lat: 48.660266, lng: 17.3274734 },
    { lat: 48.6601789, lng: 17.3272962 },
    { lat: 48.6597904, lng: 17.3270378 },
    { lat: 48.6588561, lng: 17.3268987 },
    { lat: 48.6568172, lng: 17.3254101 },
    { lat: 48.6561381, lng: 17.3246125 },
    { lat: 48.6552288, lng: 17.3221204 },
    { lat: 48.6552633, lng: 17.3220795 },
    { lat: 48.6548719, lng: 17.3215843 },
    { lat: 48.6537182, lng: 17.3211133 },
    { lat: 48.6531629, lng: 17.3211342 },
    { lat: 48.6528914, lng: 17.3210437 },
    { lat: 48.6526585, lng: 17.320849 },
    { lat: 48.6523601, lng: 17.3201732 },
    { lat: 48.6521787, lng: 17.3199033 },
    { lat: 48.6511856, lng: 17.3192609 },
    { lat: 48.6504014, lng: 17.3190318 },
    { lat: 48.6497139, lng: 17.3193495 },
    { lat: 48.6493667, lng: 17.3194123 },
    { lat: 48.6492268, lng: 17.3193523 },
    { lat: 48.6490053, lng: 17.3192574 },
    { lat: 48.6477351, lng: 17.318213 },
    { lat: 48.6465269, lng: 17.3174944 },
    { lat: 48.6455549, lng: 17.3166386 },
    { lat: 48.6447436, lng: 17.3161532 },
    { lat: 48.6434083, lng: 17.3157683 },
    { lat: 48.6423575, lng: 17.3152464 },
    { lat: 48.6416744, lng: 17.3149928 },
    { lat: 48.6405091, lng: 17.314878 },
    { lat: 48.6399158, lng: 17.3148939 },
    { lat: 48.6392039, lng: 17.3149759 },
    { lat: 48.638443, lng: 17.315311 },
    { lat: 48.6380197, lng: 17.3151761 },
    { lat: 48.6372516, lng: 17.3145212 },
    { lat: 48.6369648, lng: 17.3141428 },
    { lat: 48.6366958, lng: 17.3134993 },
    { lat: 48.6364162, lng: 17.3130798 },
    { lat: 48.6362322, lng: 17.3128908 },
    { lat: 48.635537, lng: 17.3124336 },
    { lat: 48.6350483, lng: 17.3122511 },
    { lat: 48.633571, lng: 17.3120132 },
    { lat: 48.6321277, lng: 17.3116214 },
    { lat: 48.6307989, lng: 17.3110798 },
    { lat: 48.6304957, lng: 17.3108992 },
    { lat: 48.6301919, lng: 17.3105534 },
    { lat: 48.6301138, lng: 17.3104133 },
    { lat: 48.6293038, lng: 17.3086013 },
    { lat: 48.6289622, lng: 17.3076281 },
    { lat: 48.628534, lng: 17.306056 },
    { lat: 48.6281838, lng: 17.3053165 },
    { lat: 48.6279436, lng: 17.3048217 },
    { lat: 48.626518, lng: 17.3026307 },
    { lat: 48.6257491, lng: 17.3008442 },
    { lat: 48.6249526, lng: 17.2997047 },
    { lat: 48.6247007, lng: 17.2994477 },
    { lat: 48.6236124, lng: 17.2989898 },
    { lat: 48.6227361, lng: 17.2984089 },
    { lat: 48.6215785, lng: 17.2978082 },
    { lat: 48.61979, lng: 17.2951348 },
    { lat: 48.6192388, lng: 17.2940185 },
    { lat: 48.6190574, lng: 17.2937436 },
    { lat: 48.6186595, lng: 17.2931406 },
    { lat: 48.6175868, lng: 17.2918755 },
    { lat: 48.6170333, lng: 17.2913415 },
    { lat: 48.6163954, lng: 17.2911401 },
    { lat: 48.6153364, lng: 17.2905631 },
    { lat: 48.6149537, lng: 17.2902582 },
    { lat: 48.6145321, lng: 17.2897393 },
    { lat: 48.6142515, lng: 17.2892685 },
    { lat: 48.6138815, lng: 17.2881585 },
    { lat: 48.6133911, lng: 17.2870526 },
    { lat: 48.612682, lng: 17.2856663 },
    { lat: 48.6119126, lng: 17.2849591 },
    { lat: 48.6105858, lng: 17.2841407 },
    { lat: 48.6098184, lng: 17.2835003 },
    { lat: 48.6090535, lng: 17.2831541 },
    { lat: 48.608535, lng: 17.2825366 },
    { lat: 48.608472, lng: 17.282448 },
    { lat: 48.608463, lng: 17.282533 },
    { lat: 48.60844, lng: 17.28272 },
    { lat: 48.608362, lng: 17.283267 },
    { lat: 48.608345, lng: 17.283387 },
    { lat: 48.60832, lng: 17.283621 },
    { lat: 48.608204, lng: 17.284474 },
    { lat: 48.608109, lng: 17.285333 },
    { lat: 48.608072, lng: 17.285711 },
    { lat: 48.608158, lng: 17.286712 },
    { lat: 48.608284, lng: 17.288119 },
    { lat: 48.608282, lng: 17.289147 },
    { lat: 48.608281, lng: 17.289609 },
    { lat: 48.608245, lng: 17.290902 },
    { lat: 48.608186, lng: 17.291934 },
    { lat: 48.608179, lng: 17.292093 },
    { lat: 48.608151, lng: 17.292569 },
    { lat: 48.608142, lng: 17.292665 },
    { lat: 48.60814, lng: 17.292712 },
    { lat: 48.60803, lng: 17.294143 },
    { lat: 48.607791, lng: 17.295134 },
    { lat: 48.607714, lng: 17.295451 },
    { lat: 48.607438, lng: 17.296137 },
    { lat: 48.607438, lng: 17.296143 },
    { lat: 48.607437, lng: 17.296166 },
    { lat: 48.607452, lng: 17.296154 },
    { lat: 48.607493, lng: 17.296122 },
    { lat: 48.607503, lng: 17.29615 },
    { lat: 48.607513, lng: 17.296149 },
    { lat: 48.60757, lng: 17.296146 },
    { lat: 48.607782, lng: 17.295506 },
    { lat: 48.607811, lng: 17.295385 },
    { lat: 48.607837, lng: 17.295269 },
    { lat: 48.607852, lng: 17.295279 },
    { lat: 48.608219, lng: 17.295531 },
    { lat: 48.608312, lng: 17.295595 },
    { lat: 48.609077, lng: 17.296124 },
    { lat: 48.609826, lng: 17.296642 },
    { lat: 48.610696, lng: 17.297247 },
    { lat: 48.611253, lng: 17.297635 },
    { lat: 48.61131, lng: 17.297675 },
    { lat: 48.611481, lng: 17.297794 },
    { lat: 48.61218, lng: 17.298269 },
    { lat: 48.61254, lng: 17.298513 },
    { lat: 48.613078, lng: 17.298888 },
    { lat: 48.613712, lng: 17.299329 },
    { lat: 48.61433, lng: 17.299769 },
    { lat: 48.614455, lng: 17.299855 },
    { lat: 48.615232, lng: 17.300411 },
    { lat: 48.615865, lng: 17.300855 },
    { lat: 48.61658, lng: 17.301356 },
    { lat: 48.617204, lng: 17.3018 },
    { lat: 48.617635, lng: 17.302102 },
    { lat: 48.617637, lng: 17.302104 },
    { lat: 48.617865, lng: 17.302262 },
    { lat: 48.618606, lng: 17.302789 },
    { lat: 48.619252, lng: 17.303249 },
    { lat: 48.619811, lng: 17.303636 },
    { lat: 48.62011, lng: 17.303833 },
    { lat: 48.620692, lng: 17.304237 },
    { lat: 48.620701, lng: 17.304244 },
    { lat: 48.621068, lng: 17.304497 },
    { lat: 48.621175, lng: 17.304571 },
    { lat: 48.621207, lng: 17.304593 },
    { lat: 48.621925, lng: 17.30508 },
    { lat: 48.6227, lng: 17.305609 },
    { lat: 48.62304, lng: 17.305844 },
    { lat: 48.623246, lng: 17.305985 },
    { lat: 48.623778, lng: 17.306353 },
    { lat: 48.623848, lng: 17.3064 },
    { lat: 48.62435, lng: 17.306742 },
    { lat: 48.625189, lng: 17.307316 },
    { lat: 48.625396, lng: 17.307459 },
    { lat: 48.625438, lng: 17.307487 },
    { lat: 48.625999, lng: 17.307873 },
    { lat: 48.627039, lng: 17.308589 },
    { lat: 48.627075, lng: 17.308613 },
    { lat: 48.627078, lng: 17.308615 },
    { lat: 48.62796, lng: 17.309217 },
    { lat: 48.62894, lng: 17.309884 },
    { lat: 48.629782, lng: 17.310467 },
    { lat: 48.629918, lng: 17.31056 },
    { lat: 48.629938, lng: 17.310575 },
    { lat: 48.630469, lng: 17.310938 },
    { lat: 48.630744, lng: 17.311115 },
    { lat: 48.631559, lng: 17.311683 },
    { lat: 48.632371, lng: 17.312237 },
    { lat: 48.632702, lng: 17.312463 },
    { lat: 48.633168, lng: 17.312787 },
    { lat: 48.633172, lng: 17.31279 },
    { lat: 48.633596, lng: 17.313086 },
    { lat: 48.633701, lng: 17.313155 },
    { lat: 48.634556, lng: 17.313761 },
    { lat: 48.635491, lng: 17.314424 },
    { lat: 48.636219, lng: 17.314937 },
    { lat: 48.636819, lng: 17.31536 },
    { lat: 48.637546, lng: 17.315882 },
    { lat: 48.637745, lng: 17.316019 },
    { lat: 48.63795, lng: 17.316164 },
    { lat: 48.637957, lng: 17.316168 },
    { lat: 48.638154, lng: 17.316308 },
    { lat: 48.638181, lng: 17.316328 },
    { lat: 48.63821, lng: 17.316348 },
    { lat: 48.63827, lng: 17.316392 },
    { lat: 48.638582, lng: 17.316613 },
    { lat: 48.639421, lng: 17.317204 },
    { lat: 48.639426, lng: 17.317209 },
    { lat: 48.640162, lng: 17.317729 },
    { lat: 48.640897, lng: 17.318244 },
    { lat: 48.641589, lng: 17.318739 },
    { lat: 48.642382, lng: 17.319302 },
    { lat: 48.643159, lng: 17.319846 },
    { lat: 48.64318, lng: 17.31986 },
    { lat: 48.643188, lng: 17.319868 },
    { lat: 48.643218, lng: 17.319889 },
    { lat: 48.644024, lng: 17.320468 },
    { lat: 48.644829, lng: 17.321046 },
    { lat: 48.645073, lng: 17.321207 },
    { lat: 48.646259, lng: 17.322046 },
    { lat: 48.647386, lng: 17.322851 },
    { lat: 48.647665, lng: 17.323042 },
    { lat: 48.648284, lng: 17.323486 },
    { lat: 48.648924, lng: 17.323942 },
    { lat: 48.648953, lng: 17.323965 },
    { lat: 48.649021, lng: 17.324021 },
    { lat: 48.649582, lng: 17.324419 },
    { lat: 48.650385, lng: 17.325005 },
    { lat: 48.651067, lng: 17.325491 },
    { lat: 48.651214, lng: 17.325597 },
    { lat: 48.65173, lng: 17.325969 },
    { lat: 48.652206, lng: 17.326313 },
    { lat: 48.652286, lng: 17.326371 },
    { lat: 48.652923, lng: 17.32683 },
    { lat: 48.653089, lng: 17.326948 },
    { lat: 48.653572, lng: 17.327301 },
    { lat: 48.653591, lng: 17.327315 },
    { lat: 48.653618, lng: 17.327335 },
    { lat: 48.654032, lng: 17.327629 },
    { lat: 48.653762, lng: 17.328588 },
    { lat: 48.653432, lng: 17.329729 },
    { lat: 48.653167, lng: 17.330637 },
    { lat: 48.652858, lng: 17.331726 },
    { lat: 48.652552, lng: 17.332789 },
    { lat: 48.652218, lng: 17.333951 },
    { lat: 48.652199, lng: 17.334016 },
    { lat: 48.651948, lng: 17.334914 },
    { lat: 48.651622, lng: 17.336062 },
    { lat: 48.651312, lng: 17.337134 },
    { lat: 48.650969, lng: 17.338324 },
    { lat: 48.65092, lng: 17.338507 },
    { lat: 48.650203, lng: 17.338163 },
    { lat: 48.649588, lng: 17.337861 },
    { lat: 48.649273, lng: 17.337524 },
    { lat: 48.649163, lng: 17.337364 },
    { lat: 48.648721, lng: 17.336793 },
    { lat: 48.648798, lng: 17.336735 },
    { lat: 48.648791, lng: 17.336597 },
    { lat: 48.648934, lng: 17.336602 },
    { lat: 48.648959, lng: 17.33648 },
    { lat: 48.649198, lng: 17.336571 },
    { lat: 48.649273, lng: 17.336023 },
    { lat: 48.649035, lng: 17.336 },
    { lat: 48.649227, lng: 17.335903 },
    { lat: 48.649279, lng: 17.335712 },
    { lat: 48.649249, lng: 17.335104 },
    { lat: 48.648625, lng: 17.335326 },
    { lat: 48.648451, lng: 17.33535 },
    { lat: 48.648387, lng: 17.335051 },
    { lat: 48.648125, lng: 17.335076 },
    { lat: 48.648091, lng: 17.33598 },
    { lat: 48.648087, lng: 17.336095 },
    { lat: 48.648189, lng: 17.336335 },
    { lat: 48.648479, lng: 17.336539 },
    { lat: 48.649146, lng: 17.337395 },
    { lat: 48.649258, lng: 17.337573 },
    { lat: 48.649562, lng: 17.337848 },
    { lat: 48.649463, lng: 17.3378 },
    { lat: 48.648991, lng: 17.337562 },
    { lat: 48.648886, lng: 17.337523 },
    { lat: 48.648587, lng: 17.337379 },
    { lat: 48.648574, lng: 17.337407 },
    { lat: 48.648554, lng: 17.337451 },
    { lat: 48.648541, lng: 17.337482 },
    { lat: 48.648099, lng: 17.338318 },
    { lat: 48.647595, lng: 17.339292 },
    { lat: 48.647348, lng: 17.339803 },
    { lat: 48.64683, lng: 17.340805 },
    { lat: 48.646176, lng: 17.342068 },
    { lat: 48.645586, lng: 17.34321 },
    { lat: 48.64509, lng: 17.344171 },
    { lat: 48.644212, lng: 17.345882 },
    { lat: 48.644739, lng: 17.34652 },
    { lat: 48.64518, lng: 17.347052 },
    { lat: 48.645822, lng: 17.347829 },
    { lat: 48.64617, lng: 17.348245 },
    { lat: 48.646234, lng: 17.348408 },
    { lat: 48.646305, lng: 17.348624 },
    { lat: 48.646349, lng: 17.348769 },
    { lat: 48.646365, lng: 17.348823 },
    { lat: 48.646604, lng: 17.349795 },
    { lat: 48.646906, lng: 17.35104 },
    { lat: 48.646982, lng: 17.351054 },
    { lat: 48.647206, lng: 17.351184 },
    { lat: 48.647318, lng: 17.351248 },
    { lat: 48.647117, lng: 17.351499 },
    { lat: 48.646922, lng: 17.351569 },
    { lat: 48.646909, lng: 17.351594 },
    { lat: 48.646735, lng: 17.351975 },
    { lat: 48.646922, lng: 17.352415 },
    { lat: 48.646768, lng: 17.352655 },
    { lat: 48.646684, lng: 17.352829 },
    { lat: 48.646431, lng: 17.353438 },
    { lat: 48.646423, lng: 17.353454 },
    { lat: 48.646383, lng: 17.353533 },
    { lat: 48.646353, lng: 17.353591 },
    { lat: 48.646288, lng: 17.353641 },
    { lat: 48.646214, lng: 17.353687 },
    { lat: 48.646186, lng: 17.353732 },
    { lat: 48.646121, lng: 17.353805 },
    { lat: 48.64599, lng: 17.353889 },
    { lat: 48.645698, lng: 17.354066 },
    { lat: 48.645506, lng: 17.354556 },
    { lat: 48.645472, lng: 17.354628 },
    { lat: 48.645454, lng: 17.35479 },
    { lat: 48.645427, lng: 17.355039 },
    { lat: 48.645397, lng: 17.355311 },
    { lat: 48.645366, lng: 17.35536 },
    { lat: 48.645336, lng: 17.355405 },
    { lat: 48.645191, lng: 17.355567 },
    { lat: 48.645077, lng: 17.355597 },
    { lat: 48.644959, lng: 17.355615 },
    { lat: 48.644717, lng: 17.355653 },
    { lat: 48.644689, lng: 17.355691 },
    { lat: 48.644562, lng: 17.355893 },
    { lat: 48.644394, lng: 17.356104 },
    { lat: 48.644332, lng: 17.35619 },
    { lat: 48.644127, lng: 17.356324 },
    { lat: 48.6440537, lng: 17.3564094 },
    { lat: 48.643952, lng: 17.356528 },
    { lat: 48.643725, lng: 17.356854 },
    { lat: 48.643484, lng: 17.357195 },
    { lat: 48.643441, lng: 17.35722 },
    { lat: 48.643371, lng: 17.357248 },
    { lat: 48.643197, lng: 17.357318 },
    { lat: 48.643133, lng: 17.357344 },
    { lat: 48.643015, lng: 17.357391 },
    { lat: 48.643004, lng: 17.357395 },
    { lat: 48.642854, lng: 17.357582 },
    { lat: 48.642878, lng: 17.358189 },
    { lat: 48.642798, lng: 17.358352 },
    { lat: 48.642766, lng: 17.358629 },
    { lat: 48.642662, lng: 17.358733 },
    { lat: 48.642577, lng: 17.358776 },
    { lat: 48.642271, lng: 17.358891 },
    { lat: 48.642208, lng: 17.358816 },
    { lat: 48.642036, lng: 17.358653 },
    { lat: 48.641735, lng: 17.358499 },
    { lat: 48.641641, lng: 17.358445 },
    { lat: 48.641459, lng: 17.358576 },
    { lat: 48.641238, lng: 17.359053 },
    { lat: 48.64115, lng: 17.359073 },
    { lat: 48.641035, lng: 17.359204 },
    { lat: 48.640906, lng: 17.35942 },
    { lat: 48.640764, lng: 17.359577 },
    { lat: 48.640694, lng: 17.359619 },
    { lat: 48.640364, lng: 17.359775 },
    { lat: 48.640043, lng: 17.359912 },
    { lat: 48.639715, lng: 17.360041 },
    { lat: 48.639641, lng: 17.360578 },
    { lat: 48.639529, lng: 17.360944 },
    { lat: 48.639434, lng: 17.361004 },
    { lat: 48.639371, lng: 17.361045 },
    { lat: 48.639352, lng: 17.361057 },
    { lat: 48.639284, lng: 17.361122 },
    { lat: 48.639217, lng: 17.361018 },
    { lat: 48.639145, lng: 17.361036 },
    { lat: 48.6389, lng: 17.361097 },
    { lat: 48.638733, lng: 17.361139 },
    { lat: 48.638669, lng: 17.361173 },
    { lat: 48.638609, lng: 17.361207 },
    { lat: 48.63853, lng: 17.361251 },
    { lat: 48.638456, lng: 17.361292 },
    { lat: 48.638362, lng: 17.361343 },
    { lat: 48.638232, lng: 17.361414 },
    { lat: 48.638169, lng: 17.361448 },
    { lat: 48.638096, lng: 17.361488 },
    { lat: 48.637849, lng: 17.361623 },
    { lat: 48.637673, lng: 17.361719 },
    { lat: 48.637468, lng: 17.36183 },
    { lat: 48.63717, lng: 17.361839 },
    { lat: 48.637076, lng: 17.361841 },
    { lat: 48.636973, lng: 17.361842 },
    { lat: 48.636854, lng: 17.361843 },
    { lat: 48.636676, lng: 17.361846 },
    { lat: 48.636626, lng: 17.361846 },
    { lat: 48.636562, lng: 17.361847 },
    { lat: 48.636519, lng: 17.361912 },
    { lat: 48.636398, lng: 17.362092 },
    { lat: 48.636354, lng: 17.362156 },
    { lat: 48.636315, lng: 17.362214 },
    { lat: 48.636282, lng: 17.362263 },
    { lat: 48.636213, lng: 17.362343 },
    { lat: 48.636135, lng: 17.362433 },
    { lat: 48.636075, lng: 17.362501 },
    { lat: 48.635977, lng: 17.362615 },
    { lat: 48.635826, lng: 17.362788 },
    { lat: 48.635782, lng: 17.362837 },
    { lat: 48.63574, lng: 17.362882 },
    { lat: 48.635646, lng: 17.362978 },
    { lat: 48.635491, lng: 17.363136 },
    { lat: 48.635473, lng: 17.36316 },
    { lat: 48.635317, lng: 17.363377 },
    { lat: 48.635171, lng: 17.363571 },
    { lat: 48.635114, lng: 17.363649 },
    { lat: 48.63501, lng: 17.363758 },
    { lat: 48.634957, lng: 17.363817 },
    { lat: 48.634909, lng: 17.363868 },
    { lat: 48.634862, lng: 17.363916 },
    { lat: 48.634758, lng: 17.364027 },
    { lat: 48.634691, lng: 17.36409 },
    { lat: 48.634607, lng: 17.36425 },
    { lat: 48.634554, lng: 17.364356 },
    { lat: 48.634547, lng: 17.364365 },
    { lat: 48.634412, lng: 17.364517 },
    { lat: 48.634391, lng: 17.364547 },
    { lat: 48.634357, lng: 17.364591 },
    { lat: 48.634293, lng: 17.364661 },
    { lat: 48.634223, lng: 17.364789 },
    { lat: 48.634175, lng: 17.364866 },
    { lat: 48.634011, lng: 17.365129 },
    { lat: 48.633878, lng: 17.365343 },
    { lat: 48.633822, lng: 17.365431 },
    { lat: 48.633775, lng: 17.365506 },
    { lat: 48.633739, lng: 17.365567 },
    { lat: 48.633675, lng: 17.365668 },
    { lat: 48.633645, lng: 17.365715 },
    { lat: 48.633589, lng: 17.365804 },
    { lat: 48.633562, lng: 17.365847 },
    { lat: 48.63354, lng: 17.365882 },
    { lat: 48.633424, lng: 17.366051 },
    { lat: 48.633222, lng: 17.366342 },
    { lat: 48.63311, lng: 17.3665 },
    { lat: 48.632973, lng: 17.366691 },
    { lat: 48.632911, lng: 17.36678 },
    { lat: 48.632891, lng: 17.366808 },
    { lat: 48.632837, lng: 17.366958 },
    { lat: 48.63269, lng: 17.367371 },
    { lat: 48.632806, lng: 17.36794 },
    { lat: 48.632784, lng: 17.368082 },
    { lat: 48.632758, lng: 17.368241 },
    { lat: 48.632579, lng: 17.368569 },
    { lat: 48.632535, lng: 17.368703 },
    { lat: 48.632479, lng: 17.3689 },
    { lat: 48.632345, lng: 17.369116 },
    { lat: 48.632158, lng: 17.36934 },
    { lat: 48.632078, lng: 17.369383 },
    { lat: 48.631838, lng: 17.369506 },
    { lat: 48.631358, lng: 17.369749 },
    { lat: 48.630666, lng: 17.369967 },
    { lat: 48.630397, lng: 17.370048 },
    { lat: 48.630304, lng: 17.370077 },
    { lat: 48.629845, lng: 17.370222 },
    { lat: 48.629501, lng: 17.37033 },
    { lat: 48.629262, lng: 17.370573 },
    { lat: 48.629093, lng: 17.371049 },
    { lat: 48.628729, lng: 17.371008 },
    { lat: 48.628652, lng: 17.370953 },
    { lat: 48.628626, lng: 17.371194 },
    { lat: 48.628534, lng: 17.37122 },
    { lat: 48.628511, lng: 17.371367 },
    { lat: 48.628263, lng: 17.371412 },
    { lat: 48.6281225, lng: 17.3713383 },
    { lat: 48.627784, lng: 17.371424 },
    { lat: 48.6278317, lng: 17.3713699 },
    { lat: 48.627528, lng: 17.371606 },
    { lat: 48.627501, lng: 17.371626 },
    { lat: 48.627482, lng: 17.371642 },
    { lat: 48.627427, lng: 17.371683 },
    { lat: 48.627154, lng: 17.371976 },
    { lat: 48.627039, lng: 17.372089 },
    { lat: 48.626701, lng: 17.372313 },
    { lat: 48.626088, lng: 17.372669 },
    { lat: 48.625655, lng: 17.372885 },
    { lat: 48.62527, lng: 17.373085 },
    { lat: 48.624794, lng: 17.373323 },
    { lat: 48.624441, lng: 17.373507 },
    { lat: 48.623984, lng: 17.373729 },
    { lat: 48.623843, lng: 17.373797 },
    { lat: 48.623469, lng: 17.374042 },
    { lat: 48.623367, lng: 17.374038 },
    { lat: 48.623273, lng: 17.374034 },
    { lat: 48.622957, lng: 17.37402 },
    { lat: 48.622399, lng: 17.374003 },
    { lat: 48.621989, lng: 17.37398 },
    { lat: 48.621546, lng: 17.373955 },
    { lat: 48.621372, lng: 17.37398 },
    { lat: 48.621233, lng: 17.373954 },
    { lat: 48.621037, lng: 17.373918 },
    { lat: 48.620849, lng: 17.373893 },
    { lat: 48.620691, lng: 17.373849 },
    { lat: 48.619962, lng: 17.373918 },
    { lat: 48.619833, lng: 17.373967 },
    { lat: 48.619791, lng: 17.373983 },
    { lat: 48.619615, lng: 17.374035 },
    { lat: 48.619557, lng: 17.374052 },
    { lat: 48.619374, lng: 17.374108 },
    { lat: 48.619152, lng: 17.374178 },
    { lat: 48.618992, lng: 17.37424 },
    { lat: 48.6188572, lng: 17.3742919 },
    { lat: 48.618667, lng: 17.374365 },
    { lat: 48.618361, lng: 17.374478 },
    { lat: 48.6181409, lng: 17.3745581 },
    { lat: 48.618079, lng: 17.374582 },
    { lat: 48.617824, lng: 17.374677 },
    { lat: 48.617759, lng: 17.374701 },
    { lat: 48.617585, lng: 17.374768 },
    { lat: 48.617366, lng: 17.374846 },
    { lat: 48.617153, lng: 17.374925 },
    { lat: 48.61695, lng: 17.375 },
    { lat: 48.616734, lng: 17.375085 },
    { lat: 48.616521, lng: 17.375164 },
    { lat: 48.616166, lng: 17.375293 },
    { lat: 48.61614, lng: 17.375303 },
    { lat: 48.615673, lng: 17.375438 },
    { lat: 48.615323, lng: 17.375575 },
    { lat: 48.614462, lng: 17.375855 },
    { lat: 48.613947, lng: 17.376044 },
    { lat: 48.613704, lng: 17.376118 },
    { lat: 48.613625, lng: 17.37613 },
    { lat: 48.6134287, lng: 17.3760748 },
    { lat: 48.612653, lng: 17.376212 },
    { lat: 48.612215, lng: 17.376254 },
    { lat: 48.612041, lng: 17.376605 },
    { lat: 48.6120355, lng: 17.376665 },
    { lat: 48.6120457, lng: 17.3768035 },
    { lat: 48.6113293, lng: 17.3770777 },
    { lat: 48.6107751, lng: 17.3774532 },
    { lat: 48.6103516, lng: 17.3781664 },
    { lat: 48.6097483, lng: 17.3793822 },
    { lat: 48.6091702, lng: 17.3793704 },
    { lat: 48.6093328, lng: 17.3799757 },
    { lat: 48.6110994, lng: 17.3798218 },
    { lat: 48.6110764, lng: 17.3793098 },
    { lat: 48.6112295, lng: 17.3792147 },
    { lat: 48.6115822, lng: 17.3792548 },
    { lat: 48.6116215, lng: 17.3796491 },
    { lat: 48.6118959, lng: 17.3799792 },
    { lat: 48.611614, lng: 17.3807889 },
    { lat: 48.6112492, lng: 17.3821502 },
    { lat: 48.6112687, lng: 17.3824139 },
    { lat: 48.6116347, lng: 17.3833387 },
    { lat: 48.6118186, lng: 17.384084 },
    { lat: 48.6124168, lng: 17.3841839 },
    { lat: 48.6130724, lng: 17.3840848 },
    { lat: 48.6133553, lng: 17.384163 },
    { lat: 48.6134124, lng: 17.3844334 },
    { lat: 48.613759, lng: 17.3850591 },
    { lat: 48.6139311, lng: 17.3856412 },
    { lat: 48.6137731, lng: 17.3862888 },
    { lat: 48.6139811, lng: 17.386149 },
    { lat: 48.6142955, lng: 17.3862604 },
    { lat: 48.6147747, lng: 17.3866324 },
    { lat: 48.6147425, lng: 17.3868135 },
    { lat: 48.6149198, lng: 17.3870233 },
    { lat: 48.6147283, lng: 17.3873471 },
    { lat: 48.6145181, lng: 17.3872563 },
    { lat: 48.6127773, lng: 17.3880572 },
    { lat: 48.6127621, lng: 17.3900548 },
    { lat: 48.6126654, lng: 17.3907215 },
    { lat: 48.612676, lng: 17.3910615 },
    { lat: 48.6127436, lng: 17.3912686 },
    { lat: 48.6127177, lng: 17.3914973 },
    { lat: 48.6127939, lng: 17.391837 },
    { lat: 48.6129561, lng: 17.3922111 },
    { lat: 48.6142772, lng: 17.3926951 },
    { lat: 48.6143201, lng: 17.3925903 },
    { lat: 48.6144112, lng: 17.3926379 },
    { lat: 48.6142165, lng: 17.3930117 },
    { lat: 48.6154172, lng: 17.3935203 },
    { lat: 48.6155335, lng: 17.393309 },
    { lat: 48.61577, lng: 17.393105 },
    { lat: 48.6159115, lng: 17.3925712 },
    { lat: 48.6152375, lng: 17.3913878 },
    { lat: 48.6154792, lng: 17.3912159 },
    { lat: 48.61651, lng: 17.3908409 },
    { lat: 48.6167142, lng: 17.39047 },
    { lat: 48.6167344, lng: 17.390084 },
    { lat: 48.6169996, lng: 17.3899621 },
    { lat: 48.617105, lng: 17.3900865 },
    { lat: 48.6175242, lng: 17.3901243 },
    { lat: 48.6176587, lng: 17.3900738 },
    { lat: 48.617738, lng: 17.3899572 },
    { lat: 48.6176557, lng: 17.3896109 },
    { lat: 48.6183456, lng: 17.3907912 },
    { lat: 48.6201242, lng: 17.3891476 },
    { lat: 48.6203482, lng: 17.3896572 },
    { lat: 48.620865, lng: 17.3932414 },
    { lat: 48.6208893, lng: 17.3931126 },
    { lat: 48.621375, lng: 17.3921703 },
    { lat: 48.6223183, lng: 17.3916675 },
    { lat: 48.6223927, lng: 17.3915759 },
    { lat: 48.6224316, lng: 17.3914645 },
    { lat: 48.6223309, lng: 17.3904501 },
    { lat: 48.6236281, lng: 17.3891661 },
    { lat: 48.6263355, lng: 17.3940004 },
    { lat: 48.6272954, lng: 17.3928267 },
    { lat: 48.6313327, lng: 17.3975682 },
    { lat: 48.6314079, lng: 17.3975953 },
    { lat: 48.6307516, lng: 17.4011919 },
    { lat: 48.6348663, lng: 17.4057543 },
    { lat: 48.6347235, lng: 17.4057828 },
    { lat: 48.6331502, lng: 17.4087901 },
    { lat: 48.6335845, lng: 17.4106203 },
    { lat: 48.6334733, lng: 17.4121174 },
    { lat: 48.63357, lng: 17.412952 },
    { lat: 48.6335368, lng: 17.4145534 },
    { lat: 48.6339513, lng: 17.4144857 },
    { lat: 48.6364655, lng: 17.4309608 },
    { lat: 48.6370458, lng: 17.430604 },
    { lat: 48.6375413, lng: 17.4304071 },
    { lat: 48.639484, lng: 17.4298642 },
    { lat: 48.6395179, lng: 17.4318651 },
    { lat: 48.6395801, lng: 17.4329349 },
    { lat: 48.6396703, lng: 17.4329327 },
    { lat: 48.639683, lng: 17.4330262 },
    { lat: 48.6397956, lng: 17.4330225 },
    { lat: 48.6398404, lng: 17.4331888 },
    { lat: 48.6411094, lng: 17.4330258 },
    { lat: 48.6411052, lng: 17.4338906 },
    { lat: 48.6413348, lng: 17.4338766 },
    { lat: 48.6416042, lng: 17.4336393 },
    { lat: 48.641996, lng: 17.4336398 },
    { lat: 48.6433207, lng: 17.4324599 },
    { lat: 48.6435813, lng: 17.4328239 },
    { lat: 48.644515, lng: 17.4338885 },
    { lat: 48.6447581, lng: 17.4333143 },
    { lat: 48.6463354, lng: 17.4343054 },
    { lat: 48.646775, lng: 17.4332217 },
    { lat: 48.6473999, lng: 17.4337898 },
    { lat: 48.6483021, lng: 17.432731 },
    { lat: 48.6490442, lng: 17.4336306 },
    { lat: 48.649553, lng: 17.4344605 },
    { lat: 48.6493548, lng: 17.4346463 },
    { lat: 48.6489666, lng: 17.4351982 },
    { lat: 48.6486686, lng: 17.4354258 },
    { lat: 48.6485215, lng: 17.4356146 },
    { lat: 48.6484683, lng: 17.4358781 },
    { lat: 48.6481216, lng: 17.4360907 },
    { lat: 48.6477491, lng: 17.4368307 },
    { lat: 48.6476436, lng: 17.4374204 },
    { lat: 48.6473363, lng: 17.4377883 },
    { lat: 48.6479803, lng: 17.4386348 },
    { lat: 48.6485827, lng: 17.4393095 },
    { lat: 48.6481219, lng: 17.4400835 },
    { lat: 48.6482243, lng: 17.4407078 },
    { lat: 48.6492539, lng: 17.440281 },
    { lat: 48.6503464, lng: 17.4402164 },
    { lat: 48.6508533, lng: 17.440034 },
    { lat: 48.6510222, lng: 17.4400296 },
    { lat: 48.6508799, lng: 17.4404859 },
    { lat: 48.6519406, lng: 17.4411021 },
    { lat: 48.6519544, lng: 17.4418117 },
    { lat: 48.6522843, lng: 17.4419184 },
    { lat: 48.6524339, lng: 17.4420662 },
    { lat: 48.6532554, lng: 17.4422313 },
    { lat: 48.6533319, lng: 17.4418043 },
    { lat: 48.653625, lng: 17.4416003 },
    { lat: 48.6537039, lng: 17.4411405 },
    { lat: 48.6542242, lng: 17.4414197 },
    { lat: 48.6545175, lng: 17.4414832 },
    { lat: 48.6550824, lng: 17.4414681 },
    { lat: 48.6557739, lng: 17.4412414 },
    { lat: 48.656583, lng: 17.4405296 },
    { lat: 48.6568229, lng: 17.4409593 },
    { lat: 48.6568831, lng: 17.441263 },
    { lat: 48.6566266, lng: 17.4424752 },
    { lat: 48.657267, lng: 17.44235 },
    { lat: 48.6578505, lng: 17.4420824 },
    { lat: 48.6587822, lng: 17.4414658 },
    { lat: 48.658848, lng: 17.4416616 },
    { lat: 48.6593206, lng: 17.4414067 },
    { lat: 48.6593269, lng: 17.4405362 },
    { lat: 48.6593916, lng: 17.440243 },
    { lat: 48.6600955, lng: 17.4399156 },
    { lat: 48.66081, lng: 17.4397684 },
    { lat: 48.6611292, lng: 17.4396885 },
    { lat: 48.6616294, lng: 17.4395802 },
    { lat: 48.6623681, lng: 17.439213 },
    { lat: 48.6627343, lng: 17.4386024 },
    { lat: 48.6629801, lng: 17.4379265 },
    { lat: 48.6633451, lng: 17.4365439 },
    { lat: 48.6634961, lng: 17.4357297 },
    { lat: 48.6635127, lng: 17.4353546 },
    { lat: 48.663812, lng: 17.4344261 },
    { lat: 48.663995, lng: 17.4335807 },
    { lat: 48.6641003, lng: 17.4334102 },
    { lat: 48.6642662, lng: 17.4328103 },
    { lat: 48.6643472, lng: 17.4326834 },
    { lat: 48.6643409, lng: 17.4322359 },
    { lat: 48.6644967, lng: 17.4320741 },
    { lat: 48.6650257, lng: 17.4317475 },
    { lat: 48.6654022, lng: 17.4312285 },
    { lat: 48.6656814, lng: 17.429704 },
    { lat: 48.6663122, lng: 17.4284317 },
    { lat: 48.6664055, lng: 17.4274579 },
    { lat: 48.6666817, lng: 17.4262222 },
    { lat: 48.666948, lng: 17.4259733 },
    { lat: 48.6671075, lng: 17.4257309 },
    { lat: 48.6670966, lng: 17.4256303 },
    { lat: 48.667078, lng: 17.4255176 },
  ],
  Dojč: [
    { lat: 48.70605, lng: 17.22413 },
    { lat: 48.7059501, lng: 17.2241693 },
    { lat: 48.7059034, lng: 17.2241978 },
    { lat: 48.7058694, lng: 17.2241446 },
    { lat: 48.7058575, lng: 17.2239962 },
    { lat: 48.7056355, lng: 17.2235604 },
    { lat: 48.7054763, lng: 17.2228609 },
    { lat: 48.705109, lng: 17.2220389 },
    { lat: 48.7050569, lng: 17.2218172 },
    { lat: 48.7049918, lng: 17.2212944 },
    { lat: 48.7050076, lng: 17.2210717 },
    { lat: 48.7051808, lng: 17.2205301 },
    { lat: 48.7051343, lng: 17.2204238 },
    { lat: 48.7048674, lng: 17.2204548 },
    { lat: 48.7041821, lng: 17.2206852 },
    { lat: 48.7041041, lng: 17.2203079 },
    { lat: 48.7041213, lng: 17.2195399 },
    { lat: 48.6997392, lng: 17.2226345 },
    { lat: 48.6945535, lng: 17.2242745 },
    { lat: 48.6940119, lng: 17.2240615 },
    { lat: 48.6933402, lng: 17.2239165 },
    { lat: 48.6922023, lng: 17.2233995 },
    { lat: 48.6886421, lng: 17.2213279 },
    { lat: 48.6884068, lng: 17.2212883 },
    { lat: 48.6869974, lng: 17.2216704 },
    { lat: 48.6850791, lng: 17.2230725 },
    { lat: 48.6844209, lng: 17.2237953 },
    { lat: 48.6839177, lng: 17.2240608 },
    { lat: 48.6833582, lng: 17.2222667 },
    { lat: 48.6833242, lng: 17.2222787 },
    { lat: 48.6823232, lng: 17.222794 },
    { lat: 48.6793761, lng: 17.2245734 },
    { lat: 48.6790084, lng: 17.2248376 },
    { lat: 48.6790716, lng: 17.2251087 },
    { lat: 48.677986, lng: 17.2258441 },
    { lat: 48.676924, lng: 17.2258941 },
    { lat: 48.6729175, lng: 17.2250005 },
    { lat: 48.6726, lng: 17.2250673 },
    { lat: 48.671764, lng: 17.2250161 },
    { lat: 48.6705402, lng: 17.2244489 },
    { lat: 48.670501, lng: 17.2252244 },
    { lat: 48.6705503, lng: 17.2258478 },
    { lat: 48.6704941, lng: 17.2260074 },
    { lat: 48.669902, lng: 17.2263644 },
    { lat: 48.6685718, lng: 17.2266664 },
    { lat: 48.6683483, lng: 17.2291109 },
    { lat: 48.6684638, lng: 17.2304138 },
    { lat: 48.6685921, lng: 17.2310238 },
    { lat: 48.6685653, lng: 17.2312487 },
    { lat: 48.6685205, lng: 17.2312365 },
    { lat: 48.6684436, lng: 17.2309618 },
    { lat: 48.6680152, lng: 17.2309588 },
    { lat: 48.6680928, lng: 17.2314665 },
    { lat: 48.6679372, lng: 17.2316086 },
    { lat: 48.6677811, lng: 17.231586 },
    { lat: 48.6676103, lng: 17.231448 },
    { lat: 48.6675354, lng: 17.2319207 },
    { lat: 48.6670286, lng: 17.2318094 },
    { lat: 48.6667151, lng: 17.2318345 },
    { lat: 48.6667896, lng: 17.232022 },
    { lat: 48.6668025, lng: 17.2322177 },
    { lat: 48.6666934, lng: 17.2322402 },
    { lat: 48.6665786, lng: 17.2320938 },
    { lat: 48.6665286, lng: 17.2321063 },
    { lat: 48.6665244, lng: 17.2322828 },
    { lat: 48.6664405, lng: 17.232482 },
    { lat: 48.6663366, lng: 17.2324833 },
    { lat: 48.666257, lng: 17.2320125 },
    { lat: 48.6662052, lng: 17.2319667 },
    { lat: 48.6659928, lng: 17.2320669 },
    { lat: 48.665873, lng: 17.2323423 },
    { lat: 48.6658291, lng: 17.2326159 },
    { lat: 48.6660241, lng: 17.2327298 },
    { lat: 48.6660577, lng: 17.232862 },
    { lat: 48.6659432, lng: 17.2329246 },
    { lat: 48.665741, lng: 17.2328918 },
    { lat: 48.6657021, lng: 17.233001 },
    { lat: 48.665711, lng: 17.2332587 },
    { lat: 48.665641, lng: 17.2334137 },
    { lat: 48.6654979, lng: 17.2334513 },
    { lat: 48.6653296, lng: 17.2332786 },
    { lat: 48.6652032, lng: 17.2333012 },
    { lat: 48.6650409, lng: 17.2331842 },
    { lat: 48.6649862, lng: 17.2332314 },
    { lat: 48.6649816, lng: 17.2334515 },
    { lat: 48.6649003, lng: 17.2335352 },
    { lat: 48.6645419, lng: 17.2334323 },
    { lat: 48.6645087, lng: 17.2334748 },
    { lat: 48.6645207, lng: 17.2337603 },
    { lat: 48.6642217, lng: 17.2341567 },
    { lat: 48.6642013, lng: 17.2343474 },
    { lat: 48.6639066, lng: 17.2345018 },
    { lat: 48.6638886, lng: 17.2345728 },
    { lat: 48.6639513, lng: 17.2347695 },
    { lat: 48.6639178, lng: 17.2349111 },
    { lat: 48.6637417, lng: 17.235033 },
    { lat: 48.6634168, lng: 17.2351256 },
    { lat: 48.6633397, lng: 17.235259 },
    { lat: 48.6631667, lng: 17.2353737 },
    { lat: 48.6631084, lng: 17.2356062 },
    { lat: 48.6630112, lng: 17.235722 },
    { lat: 48.6628711, lng: 17.2357738 },
    { lat: 48.6628481, lng: 17.235769 },
    { lat: 48.6625843, lng: 17.2358963 },
    { lat: 48.6625835, lng: 17.2359893 },
    { lat: 48.6626705, lng: 17.2359873 },
    { lat: 48.6626214, lng: 17.2361502 },
    { lat: 48.662679, lng: 17.2366965 },
    { lat: 48.6624676, lng: 17.2372415 },
    { lat: 48.6624478, lng: 17.2374903 },
    { lat: 48.6626291, lng: 17.2377904 },
    { lat: 48.6625392, lng: 17.2381706 },
    { lat: 48.6623728, lng: 17.238013 },
    { lat: 48.6622559, lng: 17.2379898 },
    { lat: 48.6621505, lng: 17.2383613 },
    { lat: 48.6620719, lng: 17.2384182 },
    { lat: 48.6620039, lng: 17.2381743 },
    { lat: 48.6620098, lng: 17.2378732 },
    { lat: 48.6618072, lng: 17.2377118 },
    { lat: 48.661755, lng: 17.2377597 },
    { lat: 48.6618441, lng: 17.237945 },
    { lat: 48.661815, lng: 17.2381566 },
    { lat: 48.6616579, lng: 17.2383765 },
    { lat: 48.6617692, lng: 17.2385923 },
    { lat: 48.6622422, lng: 17.2386023 },
    { lat: 48.6622307, lng: 17.2392998 },
    { lat: 48.6622675, lng: 17.2393625 },
    { lat: 48.6625269, lng: 17.2393217 },
    { lat: 48.6626143, lng: 17.2393947 },
    { lat: 48.6625229, lng: 17.2397054 },
    { lat: 48.6622498, lng: 17.2400703 },
    { lat: 48.6620641, lng: 17.2398059 },
    { lat: 48.6619447, lng: 17.239777 },
    { lat: 48.6618712, lng: 17.2398281 },
    { lat: 48.6618497, lng: 17.2399804 },
    { lat: 48.6619762, lng: 17.2402712 },
    { lat: 48.6620658, lng: 17.2407573 },
    { lat: 48.6620158, lng: 17.2408527 },
    { lat: 48.6618538, lng: 17.2406587 },
    { lat: 48.6617974, lng: 17.2406797 },
    { lat: 48.6617671, lng: 17.2408004 },
    { lat: 48.6618766, lng: 17.2408325 },
    { lat: 48.6618778, lng: 17.241159 },
    { lat: 48.6620815, lng: 17.2412323 },
    { lat: 48.6620546, lng: 17.2414086 },
    { lat: 48.661906, lng: 17.2416722 },
    { lat: 48.662125, lng: 17.2419266 },
    { lat: 48.6622876, lng: 17.2419936 },
    { lat: 48.6622729, lng: 17.2426795 },
    { lat: 48.6625569, lng: 17.243012 },
    { lat: 48.662596, lng: 17.2430197 },
    { lat: 48.662675, lng: 17.2428645 },
    { lat: 48.6628101, lng: 17.2428588 },
    { lat: 48.6626256, lng: 17.2432311 },
    { lat: 48.6627096, lng: 17.2434922 },
    { lat: 48.662856, lng: 17.2436553 },
    { lat: 48.6628504, lng: 17.243743 },
    { lat: 48.6628637, lng: 17.2437903 },
    { lat: 48.6629171, lng: 17.2439316 },
    { lat: 48.6630968, lng: 17.2440011 },
    { lat: 48.6631135, lng: 17.2441347 },
    { lat: 48.6629273, lng: 17.2442406 },
    { lat: 48.6628741, lng: 17.2443423 },
    { lat: 48.662691, lng: 17.2443759 },
    { lat: 48.6626984, lng: 17.244725 },
    { lat: 48.6626506, lng: 17.2449266 },
    { lat: 48.6630684, lng: 17.2450267 },
    { lat: 48.6630215, lng: 17.245241 },
    { lat: 48.6628121, lng: 17.245304 },
    { lat: 48.6626616, lng: 17.2455885 },
    { lat: 48.6627436, lng: 17.2457374 },
    { lat: 48.6629291, lng: 17.2457931 },
    { lat: 48.6630801, lng: 17.2460074 },
    { lat: 48.6631698, lng: 17.2456555 },
    { lat: 48.6632368, lng: 17.2456275 },
    { lat: 48.6632763, lng: 17.2457988 },
    { lat: 48.6634571, lng: 17.2458842 },
    { lat: 48.6634589, lng: 17.2459794 },
    { lat: 48.6633452, lng: 17.2460904 },
    { lat: 48.6631023, lng: 17.2465522 },
    { lat: 48.6632103, lng: 17.246987 },
    { lat: 48.6631027, lng: 17.2471225 },
    { lat: 48.6631063, lng: 17.2472124 },
    { lat: 48.6632533, lng: 17.24728 },
    { lat: 48.6634631, lng: 17.2476877 },
    { lat: 48.6635276, lng: 17.2477241 },
    { lat: 48.6635983, lng: 17.2480747 },
    { lat: 48.6640113, lng: 17.2480162 },
    { lat: 48.6639257, lng: 17.2482796 },
    { lat: 48.6639247, lng: 17.2484334 },
    { lat: 48.6640488, lng: 17.2486535 },
    { lat: 48.6641428, lng: 17.2487109 },
    { lat: 48.6641928, lng: 17.2489703 },
    { lat: 48.6644189, lng: 17.2489928 },
    { lat: 48.6645688, lng: 17.2488677 },
    { lat: 48.6646094, lng: 17.2490196 },
    { lat: 48.664521, lng: 17.2491333 },
    { lat: 48.6644869, lng: 17.249251 },
    { lat: 48.664516, lng: 17.2493345 },
    { lat: 48.6646497, lng: 17.2493408 },
    { lat: 48.664789, lng: 17.2495143 },
    { lat: 48.66504, lng: 17.2496224 },
    { lat: 48.6650775, lng: 17.2499649 },
    { lat: 48.6651786, lng: 17.2500094 },
    { lat: 48.6656296, lng: 17.2496182 },
    { lat: 48.6657851, lng: 17.2496316 },
    { lat: 48.6668, lng: 17.2503576 },
    { lat: 48.667089, lng: 17.2504717 },
    { lat: 48.6671814, lng: 17.2505551 },
    { lat: 48.6672504, lng: 17.2507298 },
    { lat: 48.6673395, lng: 17.250759 },
    { lat: 48.6673275, lng: 17.2511589 },
    { lat: 48.667379, lng: 17.251599 },
    { lat: 48.6673346, lng: 17.2518351 },
    { lat: 48.6673436, lng: 17.2529799 },
    { lat: 48.6672599, lng: 17.2533735 },
    { lat: 48.6671547, lng: 17.2544536 },
    { lat: 48.6671777, lng: 17.2547164 },
    { lat: 48.6671272, lng: 17.2553369 },
    { lat: 48.6672032, lng: 17.2564223 },
    { lat: 48.6670528, lng: 17.2576907 },
    { lat: 48.6668449, lng: 17.2584449 },
    { lat: 48.6668225, lng: 17.2586538 },
    { lat: 48.6668658, lng: 17.2590386 },
    { lat: 48.6667844, lng: 17.2590527 },
    { lat: 48.6666515, lng: 17.2601061 },
    { lat: 48.6664883, lng: 17.2607507 },
    { lat: 48.6664785, lng: 17.2608937 },
    { lat: 48.6665582, lng: 17.2612127 },
    { lat: 48.666623, lng: 17.261219 },
    { lat: 48.6665975, lng: 17.2614652 },
    { lat: 48.666332, lng: 17.2626501 },
    { lat: 48.6663223, lng: 17.2628613 },
    { lat: 48.6659635, lng: 17.262784 },
    { lat: 48.6657925, lng: 17.262811 },
    { lat: 48.6657604, lng: 17.2630435 },
    { lat: 48.6659243, lng: 17.2638027 },
    { lat: 48.6657507, lng: 17.2644427 },
    { lat: 48.665588, lng: 17.2647322 },
    { lat: 48.6655641, lng: 17.2652622 },
    { lat: 48.665909, lng: 17.266075 },
    { lat: 48.6660025, lng: 17.266691 },
    { lat: 48.6659728, lng: 17.2668763 },
    { lat: 48.6657476, lng: 17.2672811 },
    { lat: 48.6654113, lng: 17.2674587 },
    { lat: 48.6653301, lng: 17.2676506 },
    { lat: 48.6653267, lng: 17.2679906 },
    { lat: 48.6655334, lng: 17.2682648 },
    { lat: 48.6651115, lng: 17.2689617 },
    { lat: 48.6651175, lng: 17.2691399 },
    { lat: 48.6653864, lng: 17.2693451 },
    { lat: 48.6654958, lng: 17.2695513 },
    { lat: 48.6655347, lng: 17.2696963 },
    { lat: 48.6654768, lng: 17.2699812 },
    { lat: 48.665522, lng: 17.2703268 },
    { lat: 48.6656613, lng: 17.2707287 },
    { lat: 48.6658748, lng: 17.2709655 },
    { lat: 48.6659605, lng: 17.2712067 },
    { lat: 48.6659356, lng: 17.2714295 },
    { lat: 48.6657682, lng: 17.27158 },
    { lat: 48.6657769, lng: 17.2717817 },
    { lat: 48.6658495, lng: 17.2719738 },
    { lat: 48.6659311, lng: 17.2720449 },
    { lat: 48.666232, lng: 17.2719666 },
    { lat: 48.6663428, lng: 17.2720532 },
    { lat: 48.6664111, lng: 17.2722171 },
    { lat: 48.6664142, lng: 17.2723156 },
    { lat: 48.6662281, lng: 17.2728684 },
    { lat: 48.666196, lng: 17.2732085 },
    { lat: 48.6666018, lng: 17.2742766 },
    { lat: 48.6664631, lng: 17.275019 },
    { lat: 48.6664144, lng: 17.2751567 },
    { lat: 48.6662983, lng: 17.2752806 },
    { lat: 48.6663179, lng: 17.2757169 },
    { lat: 48.6662781, lng: 17.2759862 },
    { lat: 48.6661074, lng: 17.2762412 },
    { lat: 48.6661277, lng: 17.276345 },
    { lat: 48.666272, lng: 17.2766004 },
    { lat: 48.6663594, lng: 17.2769497 },
    { lat: 48.6664428, lng: 17.2770337 },
    { lat: 48.6664333, lng: 17.277128 },
    { lat: 48.6673377, lng: 17.2770026 },
    { lat: 48.6673429, lng: 17.2770644 },
    { lat: 48.6674723, lng: 17.2771608 },
    { lat: 48.6675404, lng: 17.2770083 },
    { lat: 48.6676493, lng: 17.2769459 },
    { lat: 48.6679392, lng: 17.2771788 },
    { lat: 48.6678457, lng: 17.2776488 },
    { lat: 48.6679809, lng: 17.2779366 },
    { lat: 48.6681187, lng: 17.2780379 },
    { lat: 48.668363, lng: 17.2790084 },
    { lat: 48.6682285, lng: 17.2792809 },
    { lat: 48.6680194, lng: 17.2793676 },
    { lat: 48.6678389, lng: 17.2797145 },
    { lat: 48.6677722, lng: 17.2800043 },
    { lat: 48.6676419, lng: 17.2801907 },
    { lat: 48.6672904, lng: 17.2805292 },
    { lat: 48.6670915, lng: 17.280564 },
    { lat: 48.66706, lng: 17.2806873 },
    { lat: 48.6670462, lng: 17.2808556 },
    { lat: 48.6672005, lng: 17.2811209 },
    { lat: 48.6672082, lng: 17.2812748 },
    { lat: 48.6673641, lng: 17.2814963 },
    { lat: 48.6672578, lng: 17.2815837 },
    { lat: 48.6673143, lng: 17.2820648 },
    { lat: 48.6676056, lng: 17.2821149 },
    { lat: 48.6679459, lng: 17.2824442 },
    { lat: 48.6677655, lng: 17.2828815 },
    { lat: 48.6676095, lng: 17.2827762 },
    { lat: 48.6674772, lng: 17.2828085 },
    { lat: 48.6674523, lng: 17.2829276 },
    { lat: 48.6675442, lng: 17.2835021 },
    { lat: 48.6674726, lng: 17.2839256 },
    { lat: 48.6675089, lng: 17.2841503 },
    { lat: 48.667465, lng: 17.2843438 },
    { lat: 48.66741, lng: 17.2844364 },
    { lat: 48.6671407, lng: 17.2843591 },
    { lat: 48.66706, lng: 17.2844058 },
    { lat: 48.6667678, lng: 17.284754 },
    { lat: 48.6663151, lng: 17.285034 },
    { lat: 48.6661647, lng: 17.2852212 },
    { lat: 48.6658668, lng: 17.2853637 },
    { lat: 48.6658169, lng: 17.2854837 },
    { lat: 48.6657865, lng: 17.2858278 },
    { lat: 48.6658696, lng: 17.2859908 },
    { lat: 48.6660351, lng: 17.2861394 },
    { lat: 48.6661976, lng: 17.2860136 },
    { lat: 48.6663549, lng: 17.2861563 },
    { lat: 48.6663784, lng: 17.2862585 },
    { lat: 48.6663593, lng: 17.2864976 },
    { lat: 48.6660688, lng: 17.2867712 },
    { lat: 48.6658876, lng: 17.2873329 },
    { lat: 48.6658846, lng: 17.287511 },
    { lat: 48.6660006, lng: 17.2878499 },
    { lat: 48.6662894, lng: 17.2882084 },
    { lat: 48.666733, lng: 17.2883383 },
    { lat: 48.6669927, lng: 17.2885936 },
    { lat: 48.6670011, lng: 17.2886896 },
    { lat: 48.6669388, lng: 17.2890502 },
    { lat: 48.6668493, lng: 17.2891293 },
    { lat: 48.6666784, lng: 17.2891616 },
    { lat: 48.6665713, lng: 17.2889729 },
    { lat: 48.6664087, lng: 17.2888539 },
    { lat: 48.6663367, lng: 17.2889107 },
    { lat: 48.666315, lng: 17.2891477 },
    { lat: 48.6660242, lng: 17.2899184 },
    { lat: 48.6660713, lng: 17.2901194 },
    { lat: 48.6661951, lng: 17.2902817 },
    { lat: 48.6664692, lng: 17.29021 },
    { lat: 48.6666327, lng: 17.2903603 },
    { lat: 48.6665919, lng: 17.2908031 },
    { lat: 48.6665306, lng: 17.2909377 },
    { lat: 48.6662061, lng: 17.2913128 },
    { lat: 48.6658223, lng: 17.2914016 },
    { lat: 48.665472, lng: 17.2916409 },
    { lat: 48.6654507, lng: 17.2917316 },
    { lat: 48.6655796, lng: 17.2920254 },
    { lat: 48.6657576, lng: 17.2921914 },
    { lat: 48.6655982, lng: 17.292345 },
    { lat: 48.665194, lng: 17.292553 },
    { lat: 48.665183, lng: 17.2926279 },
    { lat: 48.6653733, lng: 17.2930819 },
    { lat: 48.6654013, lng: 17.2935101 },
    { lat: 48.6655182, lng: 17.2940187 },
    { lat: 48.6656837, lng: 17.2940436 },
    { lat: 48.6658041, lng: 17.2939709 },
    { lat: 48.6660605, lng: 17.2939523 },
    { lat: 48.6662825, lng: 17.2950113 },
    { lat: 48.6663727, lng: 17.2950439 },
    { lat: 48.6667306, lng: 17.2948335 },
    { lat: 48.6671651, lng: 17.2948616 },
    { lat: 48.6672154, lng: 17.2951807 },
    { lat: 48.667175, lng: 17.2952721 },
    { lat: 48.6666736, lng: 17.2953945 },
    { lat: 48.666634, lng: 17.2955491 },
    { lat: 48.6666562, lng: 17.2957002 },
    { lat: 48.6667551, lng: 17.2958609 },
    { lat: 48.6669191, lng: 17.2958321 },
    { lat: 48.6669642, lng: 17.2959176 },
    { lat: 48.6674618, lng: 17.2959934 },
    { lat: 48.6673761, lng: 17.2962031 },
    { lat: 48.6671306, lng: 17.2965099 },
    { lat: 48.6670524, lng: 17.2970394 },
    { lat: 48.6670073, lng: 17.297128 },
    { lat: 48.6668851, lng: 17.2971598 },
    { lat: 48.666938, lng: 17.2973456 },
    { lat: 48.6670511, lng: 17.2973995 },
    { lat: 48.6670611, lng: 17.297552 },
    { lat: 48.6669638, lng: 17.2976348 },
    { lat: 48.6666371, lng: 17.2976307 },
    { lat: 48.6665684, lng: 17.297775 },
    { lat: 48.6665378, lng: 17.2980668 },
    { lat: 48.6667004, lng: 17.2981662 },
    { lat: 48.6668064, lng: 17.2980611 },
    { lat: 48.6671067, lng: 17.2981245 },
    { lat: 48.666951, lng: 17.2986652 },
    { lat: 48.666902, lng: 17.2987108 },
    { lat: 48.66697, lng: 17.2987306 },
    { lat: 48.6721631, lng: 17.2999575 },
    { lat: 48.6733766, lng: 17.3003053 },
    { lat: 48.6742302, lng: 17.3003712 },
    { lat: 48.6784779, lng: 17.3012555 },
    { lat: 48.6812808, lng: 17.3016568 },
    { lat: 48.6822721, lng: 17.3014599 },
    { lat: 48.682939, lng: 17.3013274 },
    { lat: 48.6830227, lng: 17.3011065 },
    { lat: 48.6837407, lng: 17.2993027 },
    { lat: 48.684406, lng: 17.2958325 },
    { lat: 48.6841958, lng: 17.2946763 },
    { lat: 48.6843245, lng: 17.293812 },
    { lat: 48.6843387, lng: 17.2909606 },
    { lat: 48.6842309, lng: 17.2889372 },
    { lat: 48.684335, lng: 17.2873179 },
    { lat: 48.6843816, lng: 17.2871343 },
    { lat: 48.6843676, lng: 17.2869198 },
    { lat: 48.6846414, lng: 17.2841178 },
    { lat: 48.6853187, lng: 17.2818786 },
    { lat: 48.6874462, lng: 17.280696 },
    { lat: 48.6877237, lng: 17.2803252 },
    { lat: 48.6877619, lng: 17.2793777 },
    { lat: 48.6882844, lng: 17.2790939 },
    { lat: 48.6886913, lng: 17.2787369 },
    { lat: 48.689714, lng: 17.2775694 },
    { lat: 48.6901939, lng: 17.2766537 },
    { lat: 48.6906224, lng: 17.2767626 },
    { lat: 48.6909488, lng: 17.2765567 },
    { lat: 48.6915231, lng: 17.2763409 },
    { lat: 48.6918452, lng: 17.2763245 },
    { lat: 48.6921046, lng: 17.2761115 },
    { lat: 48.6924954, lng: 17.2759027 },
    { lat: 48.692764, lng: 17.275865 },
    { lat: 48.6930309, lng: 17.2755247 },
    { lat: 48.6931448, lng: 17.2752655 },
    { lat: 48.6934587, lng: 17.2748141 },
    { lat: 48.6942312, lng: 17.2740379 },
    { lat: 48.6942758, lng: 17.2739271 },
    { lat: 48.6945045, lng: 17.2737434 },
    { lat: 48.6947383, lng: 17.2734362 },
    { lat: 48.6948993, lng: 17.2730828 },
    { lat: 48.6954048, lng: 17.2713543 },
    { lat: 48.6955965, lng: 17.271004 },
    { lat: 48.6970355, lng: 17.269796 },
    { lat: 48.6992315, lng: 17.2693307 },
    { lat: 48.6996416, lng: 17.2690579 },
    { lat: 48.6999401, lng: 17.2687338 },
    { lat: 48.7002444, lng: 17.268706 },
    { lat: 48.7007884, lng: 17.2684907 },
    { lat: 48.700882, lng: 17.2685575 },
    { lat: 48.7009353, lng: 17.2685284 },
    { lat: 48.7009822, lng: 17.2682771 },
    { lat: 48.7010503, lng: 17.2682329 },
    { lat: 48.701346, lng: 17.2684489 },
    { lat: 48.7014424, lng: 17.2682338 },
    { lat: 48.7014983, lng: 17.2682065 },
    { lat: 48.7017507, lng: 17.2684197 },
    { lat: 48.7020153, lng: 17.2683031 },
    { lat: 48.7021859, lng: 17.2680829 },
    { lat: 48.7026111, lng: 17.2682087 },
    { lat: 48.7029794, lng: 17.2679675 },
    { lat: 48.7032338, lng: 17.2679873 },
    { lat: 48.7037772, lng: 17.2675241 },
    { lat: 48.7040749, lng: 17.2677212 },
    { lat: 48.7041571, lng: 17.2678548 },
    { lat: 48.705191, lng: 17.2688049 },
    { lat: 48.7053953, lng: 17.2688383 },
    { lat: 48.7057275, lng: 17.2691819 },
    { lat: 48.7061116, lng: 17.2632518 },
    { lat: 48.7062273, lng: 17.2602876 },
    { lat: 48.7063487, lng: 17.2584592 },
    { lat: 48.706433, lng: 17.2579379 },
    { lat: 48.7066151, lng: 17.2576834 },
    { lat: 48.7067, lng: 17.257633 },
    { lat: 48.706699, lng: 17.257524 },
    { lat: 48.706697, lng: 17.257415 },
    { lat: 48.706688, lng: 17.25715 },
    { lat: 48.706664, lng: 17.256278 },
    { lat: 48.706652, lng: 17.255891 },
    { lat: 48.706495, lng: 17.255004 },
    { lat: 48.706466, lng: 17.254874 },
    { lat: 48.706366, lng: 17.254463 },
    { lat: 48.706249, lng: 17.253997 },
    { lat: 48.706102, lng: 17.253395 },
    { lat: 48.705919, lng: 17.252579 },
    { lat: 48.705794, lng: 17.252105 },
    { lat: 48.705593, lng: 17.251347 },
    { lat: 48.705511, lng: 17.251013 },
    { lat: 48.705502, lng: 17.250903 },
    { lat: 48.705492, lng: 17.250787 },
    { lat: 48.705474, lng: 17.250588 },
    { lat: 48.705457, lng: 17.250422 },
    { lat: 48.705426, lng: 17.250215 },
    { lat: 48.70528, lng: 17.249689 },
    { lat: 48.705258, lng: 17.249587 },
    { lat: 48.705159, lng: 17.24914 },
    { lat: 48.705123, lng: 17.248857 },
    { lat: 48.705087, lng: 17.248567 },
    { lat: 48.705045, lng: 17.248212 },
    { lat: 48.705034, lng: 17.248128 },
    { lat: 48.705007, lng: 17.247897 },
    { lat: 48.704978, lng: 17.247528 },
    { lat: 48.704951, lng: 17.246837 },
    { lat: 48.704946, lng: 17.246734 },
    { lat: 48.704877, lng: 17.245961 },
    { lat: 48.704813, lng: 17.245403 },
    { lat: 48.704789, lng: 17.24519 },
    { lat: 48.704754, lng: 17.245005 },
    { lat: 48.704703, lng: 17.244711 },
    { lat: 48.704655, lng: 17.244421 },
    { lat: 48.704632, lng: 17.244261 },
    { lat: 48.704609, lng: 17.244118 },
    { lat: 48.704563, lng: 17.243636 },
    { lat: 48.704556, lng: 17.24351 },
    { lat: 48.704552, lng: 17.243 },
    { lat: 48.704568, lng: 17.242509 },
    { lat: 48.704588, lng: 17.241995 },
    { lat: 48.704595, lng: 17.2418 },
    { lat: 48.704606, lng: 17.241566 },
    { lat: 48.704618, lng: 17.241556 },
    { lat: 48.705045, lng: 17.241498 },
    { lat: 48.705045, lng: 17.241468 },
    { lat: 48.704965, lng: 17.238936 },
    { lat: 48.705269, lng: 17.238837 },
    { lat: 48.705311, lng: 17.237091 },
    { lat: 48.705558, lng: 17.237026 },
    { lat: 48.705543, lng: 17.236924 },
    { lat: 48.705505, lng: 17.236662 },
    { lat: 48.705489, lng: 17.236552 },
    { lat: 48.705471, lng: 17.236435 },
    { lat: 48.705452, lng: 17.236301 },
    { lat: 48.705441, lng: 17.236214 },
    { lat: 48.705631, lng: 17.236161 },
    { lat: 48.70561, lng: 17.236018 },
    { lat: 48.705557, lng: 17.235669 },
    { lat: 48.705497, lng: 17.235258 },
    { lat: 48.705647, lng: 17.235183 },
    { lat: 48.705776, lng: 17.235116 },
    { lat: 48.705933, lng: 17.234801 },
    { lat: 48.705964, lng: 17.234641 },
    { lat: 48.705993, lng: 17.234511 },
    { lat: 48.706037, lng: 17.234277 },
    { lat: 48.706073, lng: 17.234036 },
    { lat: 48.70609, lng: 17.233693 },
    { lat: 48.70608, lng: 17.233285 },
    { lat: 48.706077, lng: 17.23324 },
    { lat: 48.706112, lng: 17.233115 },
    { lat: 48.706138, lng: 17.232948 },
    { lat: 48.706263, lng: 17.232398 },
    { lat: 48.706274, lng: 17.231957 },
    { lat: 48.706161, lng: 17.231403 },
    { lat: 48.705822, lng: 17.230131 },
    { lat: 48.706234, lng: 17.229592 },
    { lat: 48.706727, lng: 17.228307 },
    { lat: 48.706812, lng: 17.227985 },
    { lat: 48.706853, lng: 17.227563 },
    { lat: 48.706891, lng: 17.227344 },
    { lat: 48.706943, lng: 17.227181 },
    { lat: 48.707248, lng: 17.226657 },
    { lat: 48.707407, lng: 17.226315 },
    { lat: 48.707524, lng: 17.225955 },
    { lat: 48.707529, lng: 17.225942 },
    { lat: 48.70749, lng: 17.225879 },
    { lat: 48.707427, lng: 17.225777 },
    { lat: 48.707274, lng: 17.225532 },
    { lat: 48.707258, lng: 17.22547 },
    { lat: 48.707241, lng: 17.225403 },
    { lat: 48.707167, lng: 17.225239 },
    { lat: 48.70711, lng: 17.225236 },
    { lat: 48.707006, lng: 17.225091 },
    { lat: 48.706948, lng: 17.225117 },
    { lat: 48.706873, lng: 17.225033 },
    { lat: 48.706795, lng: 17.224849 },
    { lat: 48.706735, lng: 17.224709 },
    { lat: 48.706692, lng: 17.224681 },
    { lat: 48.706628, lng: 17.224637 },
    { lat: 48.70659, lng: 17.224612 },
    { lat: 48.706563, lng: 17.224552 },
    { lat: 48.706524, lng: 17.22454 },
    { lat: 48.706456, lng: 17.224521 },
    { lat: 48.706408, lng: 17.224447 },
    { lat: 48.706334, lng: 17.224386 },
    { lat: 48.706279, lng: 17.224314 },
    { lat: 48.706268, lng: 17.2243 },
    { lat: 48.706198, lng: 17.224218 },
    { lat: 48.706173, lng: 17.22421 },
    { lat: 48.706149, lng: 17.224202 },
    { lat: 48.706105, lng: 17.224187 },
    { lat: 48.70605, lng: 17.22413 },
  ],
  Častkov: [
    { lat: 48.740203, lng: 17.323337 },
    { lat: 48.739878, lng: 17.32362 },
    { lat: 48.739655, lng: 17.323855 },
    { lat: 48.739437, lng: 17.324114 },
    { lat: 48.739432, lng: 17.324138 },
    { lat: 48.7395204, lng: 17.3242897 },
    { lat: 48.7404815, lng: 17.3256041 },
    { lat: 48.7405848, lng: 17.3258536 },
    { lat: 48.7406964, lng: 17.326509 },
    { lat: 48.7407051, lng: 17.3273223 },
    { lat: 48.7407645, lng: 17.3277107 },
    { lat: 48.7409973, lng: 17.328424 },
    { lat: 48.7414442, lng: 17.3294554 },
    { lat: 48.7419065, lng: 17.3309026 },
    { lat: 48.741834, lng: 17.3314545 },
    { lat: 48.7418262, lng: 17.3324975 },
    { lat: 48.7419434, lng: 17.3344538 },
    { lat: 48.7417155, lng: 17.3358992 },
    { lat: 48.7412102, lng: 17.3380973 },
    { lat: 48.7408691, lng: 17.3399711 },
    { lat: 48.7406013, lng: 17.341014 },
    { lat: 48.7398665, lng: 17.3435399 },
    { lat: 48.7396325, lng: 17.3445087 },
    { lat: 48.7394894, lng: 17.3447583 },
    { lat: 48.7393289, lng: 17.3452603 },
    { lat: 48.7393005, lng: 17.3456069 },
    { lat: 48.7391649, lng: 17.3457276 },
    { lat: 48.7389888, lng: 17.3461457 },
    { lat: 48.7389754, lng: 17.3463219 },
    { lat: 48.7387877, lng: 17.3467195 },
    { lat: 48.7387052, lng: 17.346736 },
    { lat: 48.7385599, lng: 17.3472461 },
    { lat: 48.7381933, lng: 17.3477428 },
    { lat: 48.7379558, lng: 17.3479635 },
    { lat: 48.7380268, lng: 17.3482038 },
    { lat: 48.7379298, lng: 17.3483968 },
    { lat: 48.7371771, lng: 17.34926 },
    { lat: 48.7357302, lng: 17.3511381 },
    { lat: 48.7351923, lng: 17.351901 },
    { lat: 48.7346653, lng: 17.3527968 },
    { lat: 48.7337609, lng: 17.3541605 },
    { lat: 48.7331265, lng: 17.3550154 },
    { lat: 48.7326745, lng: 17.355466 },
    { lat: 48.7325427, lng: 17.3555067 },
    { lat: 48.7308582, lng: 17.3568052 },
    { lat: 48.7307698, lng: 17.3568874 },
    { lat: 48.730818, lng: 17.3569529 },
    { lat: 48.7311636, lng: 17.3570211 },
    { lat: 48.7313265, lng: 17.3569755 },
    { lat: 48.731456, lng: 17.3570554 },
    { lat: 48.7315386, lng: 17.3573691 },
    { lat: 48.7320239, lng: 17.3579033 },
    { lat: 48.7322586, lng: 17.3582842 },
    { lat: 48.7325534, lng: 17.3583003 },
    { lat: 48.7326461, lng: 17.3584558 },
    { lat: 48.7326982, lng: 17.35912 },
    { lat: 48.7328543, lng: 17.3592275 },
    { lat: 48.7329615, lng: 17.359052 },
    { lat: 48.73305, lng: 17.3590224 },
    { lat: 48.7331895, lng: 17.3590361 },
    { lat: 48.7332587, lng: 17.3591239 },
    { lat: 48.7333391, lng: 17.3600169 },
    { lat: 48.7334854, lng: 17.3603204 },
    { lat: 48.7337703, lng: 17.3602571 },
    { lat: 48.7340292, lng: 17.3600659 },
    { lat: 48.7345697, lng: 17.3610539 },
    { lat: 48.7351322, lng: 17.3616099 },
    { lat: 48.7353565, lng: 17.362054 },
    { lat: 48.7356002, lng: 17.3635812 },
    { lat: 48.7356048, lng: 17.3638271 },
    { lat: 48.7353243, lng: 17.3653397 },
    { lat: 48.735215, lng: 17.3661799 },
    { lat: 48.7352437, lng: 17.3684339 },
    { lat: 48.7352143, lng: 17.3690942 },
    { lat: 48.7352718, lng: 17.3692692 },
    { lat: 48.7353601, lng: 17.3693252 },
    { lat: 48.735203, lng: 17.3701954 },
    { lat: 48.7355847, lng: 17.3714135 },
    { lat: 48.7358696, lng: 17.371888 },
    { lat: 48.736036, lng: 17.3722527 },
    { lat: 48.7361996, lng: 17.3726049 },
    { lat: 48.7363602, lng: 17.3730456 },
    { lat: 48.7364983, lng: 17.3736936 },
    { lat: 48.7366868, lng: 17.3753511 },
    { lat: 48.7368359, lng: 17.3779702 },
    { lat: 48.7368008, lng: 17.3781768 },
    { lat: 48.7365692, lng: 17.3785579 },
    { lat: 48.7366835, lng: 17.3784937 },
    { lat: 48.7372195, lng: 17.3787994 },
    { lat: 48.73909, lng: 17.3801077 },
    { lat: 48.7394637, lng: 17.3796162 },
    { lat: 48.7395073, lng: 17.3797171 },
    { lat: 48.7400482, lng: 17.3792052 },
    { lat: 48.7400517, lng: 17.3794709 },
    { lat: 48.7413272, lng: 17.3784277 },
    { lat: 48.7422553, lng: 17.3774262 },
    { lat: 48.7421639, lng: 17.3770786 },
    { lat: 48.7430988, lng: 17.3767056 },
    { lat: 48.744256, lng: 17.3765401 },
    { lat: 48.744462, lng: 17.3765815 },
    { lat: 48.7446951, lng: 17.3769543 },
    { lat: 48.7449322, lng: 17.3771626 },
    { lat: 48.7451131, lng: 17.3774955 },
    { lat: 48.7451401, lng: 17.3776358 },
    { lat: 48.7453469, lng: 17.3778836 },
    { lat: 48.7454597, lng: 17.3781411 },
    { lat: 48.7463098, lng: 17.3792003 },
    { lat: 48.7464504, lng: 17.3795922 },
    { lat: 48.746624, lng: 17.3797261 },
    { lat: 48.746731, lng: 17.3799494 },
    { lat: 48.7469603, lng: 17.3800726 },
    { lat: 48.7473014, lng: 17.3808926 },
    { lat: 48.7477369, lng: 17.3812337 },
    { lat: 48.748044, lng: 17.3816528 },
    { lat: 48.7481251, lng: 17.3816111 },
    { lat: 48.7483544, lng: 17.3817154 },
    { lat: 48.7485191, lng: 17.3816978 },
    { lat: 48.7490334, lng: 17.3819267 },
    { lat: 48.7492257, lng: 17.3820905 },
    { lat: 48.7495282, lng: 17.3826095 },
    { lat: 48.749968, lng: 17.3832106 },
    { lat: 48.7505803, lng: 17.3836315 },
    { lat: 48.7522623, lng: 17.3842813 },
    { lat: 48.7538509, lng: 17.384079 },
    { lat: 48.7544431, lng: 17.3842435 },
    { lat: 48.7546874, lng: 17.3840903 },
    { lat: 48.7550864, lng: 17.3835366 },
    { lat: 48.7553846, lng: 17.3834123 },
    { lat: 48.756284, lng: 17.382543 },
    { lat: 48.7571687, lng: 17.3814113 },
    { lat: 48.7573916, lng: 17.3809452 },
    { lat: 48.7577573, lng: 17.3812065 },
    { lat: 48.7582081, lng: 17.381365 },
    { lat: 48.758702, lng: 17.3817892 },
    { lat: 48.7589488, lng: 17.3824941 },
    { lat: 48.759453, lng: 17.3820812 },
    { lat: 48.760891, lng: 17.381315 },
    { lat: 48.7611187, lng: 17.3813257 },
    { lat: 48.761888, lng: 17.3817475 },
    { lat: 48.7628586, lng: 17.3819262 },
    { lat: 48.7634402, lng: 17.3821538 },
    { lat: 48.7653318, lng: 17.3816918 },
    { lat: 48.7654765, lng: 17.3815218 },
    { lat: 48.7666734, lng: 17.3816892 },
    { lat: 48.7668544, lng: 17.3817462 },
    { lat: 48.7667638, lng: 17.3819347 },
    { lat: 48.7665573, lng: 17.3820239 },
    { lat: 48.7666341, lng: 17.382323 },
    { lat: 48.7669616, lng: 17.3822846 },
    { lat: 48.767009, lng: 17.3824766 },
    { lat: 48.7673081, lng: 17.3826136 },
    { lat: 48.7675135, lng: 17.3823125 },
    { lat: 48.7681633, lng: 17.3826521 },
    { lat: 48.7682421, lng: 17.3829803 },
    { lat: 48.7681937, lng: 17.3832098 },
    { lat: 48.768232, lng: 17.383261 },
    { lat: 48.7683421, lng: 17.3830919 },
    { lat: 48.7683446, lng: 17.3833257 },
    { lat: 48.7685279, lng: 17.3835574 },
    { lat: 48.768673, lng: 17.3835138 },
    { lat: 48.7692088, lng: 17.3837987 },
    { lat: 48.7691751, lng: 17.3839079 },
    { lat: 48.7696302, lng: 17.3843053 },
    { lat: 48.7696067, lng: 17.3843632 },
    { lat: 48.7696525, lng: 17.3844925 },
    { lat: 48.76992, lng: 17.3847022 },
    { lat: 48.7700349, lng: 17.3848725 },
    { lat: 48.7701524, lng: 17.3847867 },
    { lat: 48.7702927, lng: 17.3849983 },
    { lat: 48.7703397, lng: 17.3849753 },
    { lat: 48.7706736, lng: 17.3858937 },
    { lat: 48.7710873, lng: 17.3867897 },
    { lat: 48.7712925, lng: 17.3866258 },
    { lat: 48.7715356, lng: 17.3864388 },
    { lat: 48.7716107, lng: 17.3863593 },
    { lat: 48.7715264, lng: 17.3861207 },
    { lat: 48.7714435, lng: 17.3858186 },
    { lat: 48.7714289, lng: 17.3856439 },
    { lat: 48.7714569, lng: 17.3853969 },
    { lat: 48.7715147, lng: 17.3851363 },
    { lat: 48.771657, lng: 17.3846473 },
    { lat: 48.7715807, lng: 17.3845644 },
    { lat: 48.7716366, lng: 17.3842614 },
    { lat: 48.7716686, lng: 17.3841969 },
    { lat: 48.7716678, lng: 17.3841834 },
    { lat: 48.7716064, lng: 17.3841087 },
    { lat: 48.7715305, lng: 17.3840515 },
    { lat: 48.7715738, lng: 17.3837067 },
    { lat: 48.7715859, lng: 17.3834776 },
    { lat: 48.7715996, lng: 17.3829717 },
    { lat: 48.7715647, lng: 17.3826947 },
    { lat: 48.7715372, lng: 17.3825298 },
    { lat: 48.7714839, lng: 17.3822652 },
    { lat: 48.7714356, lng: 17.3820592 },
    { lat: 48.7713896, lng: 17.3819081 },
    { lat: 48.7712955, lng: 17.3816564 },
    { lat: 48.7711747, lng: 17.381429 },
    { lat: 48.7713409, lng: 17.3809969 },
    { lat: 48.7714394, lng: 17.3807012 },
    { lat: 48.7715073, lng: 17.3805136 },
    { lat: 48.7715872, lng: 17.3802633 },
    { lat: 48.7717549, lng: 17.3801479 },
    { lat: 48.7717981, lng: 17.3801021 },
    { lat: 48.7719942, lng: 17.3799545 },
    { lat: 48.7721407, lng: 17.3797935 },
    { lat: 48.7721762, lng: 17.379731 },
    { lat: 48.7724755, lng: 17.3796774 },
    { lat: 48.7724708, lng: 17.3795499 },
    { lat: 48.7726651, lng: 17.3792895 },
    { lat: 48.7727185, lng: 17.379157 },
    { lat: 48.7728729, lng: 17.3791144 },
    { lat: 48.7728037, lng: 17.3789886 },
    { lat: 48.7729008, lng: 17.378831 },
    { lat: 48.7732846, lng: 17.378617 },
    { lat: 48.7735752, lng: 17.3788465 },
    { lat: 48.7736569, lng: 17.379012 },
    { lat: 48.7738689, lng: 17.3791285 },
    { lat: 48.7739377, lng: 17.3790754 },
    { lat: 48.7742727, lng: 17.3790337 },
    { lat: 48.7742909, lng: 17.3788763 },
    { lat: 48.7742746, lng: 17.3787669 },
    { lat: 48.7743171, lng: 17.3784606 },
    { lat: 48.7742868, lng: 17.3779737 },
    { lat: 48.7739709, lng: 17.3776821 },
    { lat: 48.7737762, lng: 17.3776067 },
    { lat: 48.773728, lng: 17.3778241 },
    { lat: 48.7735495, lng: 17.3779257 },
    { lat: 48.7733534, lng: 17.377848 },
    { lat: 48.7733157, lng: 17.3782926 },
    { lat: 48.7730285, lng: 17.3786205 },
    { lat: 48.7728512, lng: 17.3786757 },
    { lat: 48.7727894, lng: 17.3782244 },
    { lat: 48.7728017, lng: 17.3779254 },
    { lat: 48.7728816, lng: 17.3778348 },
    { lat: 48.7729528, lng: 17.3778346 },
    { lat: 48.7730497, lng: 17.3777261 },
    { lat: 48.7732722, lng: 17.377272 },
    { lat: 48.7731071, lng: 17.3770068 },
    { lat: 48.7731583, lng: 17.3767525 },
    { lat: 48.7730387, lng: 17.3765607 },
    { lat: 48.7729291, lng: 17.376366 },
    { lat: 48.7728853, lng: 17.3761279 },
    { lat: 48.7727793, lng: 17.3757633 },
    { lat: 48.7727785, lng: 17.3755758 },
    { lat: 48.7726791, lng: 17.3754734 },
    { lat: 48.7725067, lng: 17.3745171 },
    { lat: 48.7725579, lng: 17.373808 },
    { lat: 48.7726157, lng: 17.3735491 },
    { lat: 48.7722154, lng: 17.3734383 },
    { lat: 48.7720826, lng: 17.3734641 },
    { lat: 48.7720186, lng: 17.3735026 },
    { lat: 48.7719109, lng: 17.3733749 },
    { lat: 48.7715271, lng: 17.374024 },
    { lat: 48.7711775, lng: 17.3735963 },
    { lat: 48.7711351, lng: 17.3737272 },
    { lat: 48.7710286, lng: 17.3736363 },
    { lat: 48.7715349, lng: 17.372747 },
    { lat: 48.7715365, lng: 17.3726881 },
    { lat: 48.7715664, lng: 17.3725895 },
    { lat: 48.7716192, lng: 17.3724996 },
    { lat: 48.7717279, lng: 17.3725179 },
    { lat: 48.7718171, lng: 17.3724155 },
    { lat: 48.7721429, lng: 17.3722119 },
    { lat: 48.7724151, lng: 17.3720914 },
    { lat: 48.7724896, lng: 17.3717401 },
    { lat: 48.7724464, lng: 17.3714561 },
    { lat: 48.7724878, lng: 17.3712767 },
    { lat: 48.7725798, lng: 17.3711442 },
    { lat: 48.7721911, lng: 17.3710546 },
    { lat: 48.772095, lng: 17.3708253 },
    { lat: 48.772018, lng: 17.3704915 },
    { lat: 48.7721758, lng: 17.3703281 },
    { lat: 48.772149, lng: 17.3701269 },
    { lat: 48.7722022, lng: 17.3700234 },
    { lat: 48.7723483, lng: 17.3700788 },
    { lat: 48.7724639, lng: 17.3700702 },
    { lat: 48.7725106, lng: 17.3702137 },
    { lat: 48.7726756, lng: 17.37032 },
    { lat: 48.7727856, lng: 17.370067 },
    { lat: 48.7728563, lng: 17.3697983 },
    { lat: 48.7729461, lng: 17.3694051 },
    { lat: 48.7727091, lng: 17.3692511 },
    { lat: 48.7726751, lng: 17.3691613 },
    { lat: 48.7727087, lng: 17.3690693 },
    { lat: 48.7728411, lng: 17.3689684 },
    { lat: 48.7730223, lng: 17.3689587 },
    { lat: 48.7731263, lng: 17.3689247 },
    { lat: 48.7732408, lng: 17.3689664 },
    { lat: 48.7732822, lng: 17.3688722 },
    { lat: 48.7733889, lng: 17.3688814 },
    { lat: 48.7734605, lng: 17.3687297 },
    { lat: 48.7734748, lng: 17.3685346 },
    { lat: 48.7732583, lng: 17.3683121 },
    { lat: 48.7737445, lng: 17.3678665 },
    { lat: 48.7742934, lng: 17.3673597 },
    { lat: 48.7742741, lng: 17.3675137 },
    { lat: 48.7744048, lng: 17.3676498 },
    { lat: 48.7744815, lng: 17.3679972 },
    { lat: 48.774396, lng: 17.3681059 },
    { lat: 48.7746996, lng: 17.3680684 },
    { lat: 48.7749326, lng: 17.3677954 },
    { lat: 48.7752239, lng: 17.3678095 },
    { lat: 48.7754579, lng: 17.3677236 },
    { lat: 48.7755654, lng: 17.3677656 },
    { lat: 48.7756084, lng: 17.367745 },
    { lat: 48.775628, lng: 17.3678076 },
    { lat: 48.7768092, lng: 17.3669277 },
    { lat: 48.776847, lng: 17.366781 },
    { lat: 48.7769634, lng: 17.3665893 },
    { lat: 48.7770306, lng: 17.3663881 },
    { lat: 48.7770575, lng: 17.3662628 },
    { lat: 48.7771462, lng: 17.3660795 },
    { lat: 48.7773043, lng: 17.3659221 },
    { lat: 48.7773157, lng: 17.3658022 },
    { lat: 48.7771824, lng: 17.3652966 },
    { lat: 48.7770527, lng: 17.3650928 },
    { lat: 48.777065, lng: 17.3647049 },
    { lat: 48.7770772, lng: 17.3644911 },
    { lat: 48.7770765, lng: 17.3643236 },
    { lat: 48.776994, lng: 17.3639404 },
    { lat: 48.7769822, lng: 17.3638104 },
    { lat: 48.7769617, lng: 17.3637196 },
    { lat: 48.7769664, lng: 17.3635839 },
    { lat: 48.7769495, lng: 17.3634263 },
    { lat: 48.7769166, lng: 17.3633016 },
    { lat: 48.7769066, lng: 17.3630638 },
    { lat: 48.7768815, lng: 17.3627785 },
    { lat: 48.7768421, lng: 17.3626597 },
    { lat: 48.7768319, lng: 17.3625356 },
    { lat: 48.7767949, lng: 17.3623326 },
    { lat: 48.7767974, lng: 17.3621775 },
    { lat: 48.7767793, lng: 17.3618479 },
    { lat: 48.7766147, lng: 17.3617115 },
    { lat: 48.7765039, lng: 17.36171 },
    { lat: 48.7763949, lng: 17.3615955 },
    { lat: 48.7762887, lng: 17.3614215 },
    { lat: 48.7761085, lng: 17.3612748 },
    { lat: 48.7760347, lng: 17.3612851 },
    { lat: 48.7759517, lng: 17.3611735 },
    { lat: 48.7757244, lng: 17.3610994 },
    { lat: 48.7756662, lng: 17.360968 },
    { lat: 48.7755775, lng: 17.3608895 },
    { lat: 48.7754662, lng: 17.3607567 },
    { lat: 48.7754442, lng: 17.3606103 },
    { lat: 48.7753538, lng: 17.3602931 },
    { lat: 48.7752199, lng: 17.3599728 },
    { lat: 48.7751885, lng: 17.3598869 },
    { lat: 48.7751309, lng: 17.3598195 },
    { lat: 48.7750949, lng: 17.3596479 },
    { lat: 48.7750266, lng: 17.3595872 },
    { lat: 48.7749245, lng: 17.3593706 },
    { lat: 48.7748509, lng: 17.3592791 },
    { lat: 48.7747541, lng: 17.358973 },
    { lat: 48.7747734, lng: 17.3589053 },
    { lat: 48.7748432, lng: 17.3588838 },
    { lat: 48.7749279, lng: 17.3587771 },
    { lat: 48.7749642, lng: 17.3585189 },
    { lat: 48.7748714, lng: 17.358432 },
    { lat: 48.7748113, lng: 17.3581136 },
    { lat: 48.774756, lng: 17.3579395 },
    { lat: 48.7746753, lng: 17.3575311 },
    { lat: 48.7744801, lng: 17.3571679 },
    { lat: 48.774415, lng: 17.3571239 },
    { lat: 48.7744605, lng: 17.3568324 },
    { lat: 48.7745197, lng: 17.3566281 },
    { lat: 48.7747464, lng: 17.3563613 },
    { lat: 48.7754045, lng: 17.3555703 },
    { lat: 48.7764178, lng: 17.3543562 },
    { lat: 48.7767714, lng: 17.3539395 },
    { lat: 48.7774661, lng: 17.353104 },
    { lat: 48.7775266, lng: 17.3530357 },
    { lat: 48.777501, lng: 17.352992 },
    { lat: 48.777283, lng: 17.352595 },
    { lat: 48.777075, lng: 17.352177 },
    { lat: 48.776826, lng: 17.351656 },
    { lat: 48.77679, lng: 17.351529 },
    { lat: 48.77676, lng: 17.35132 },
    { lat: 48.776659, lng: 17.350796 },
    { lat: 48.776631, lng: 17.350662 },
    { lat: 48.776554, lng: 17.350431 },
    { lat: 48.776429, lng: 17.350147 },
    { lat: 48.776299, lng: 17.349806 },
    { lat: 48.776222, lng: 17.349618 },
    { lat: 48.776095, lng: 17.349345 },
    { lat: 48.775848, lng: 17.348802 },
    { lat: 48.775688, lng: 17.348454 },
    { lat: 48.775567, lng: 17.348162 },
    { lat: 48.775308, lng: 17.347861 },
    { lat: 48.775098, lng: 17.347637 },
    { lat: 48.775006, lng: 17.347506 },
    { lat: 48.77489, lng: 17.347366 },
    { lat: 48.774729, lng: 17.347234 },
    { lat: 48.774414, lng: 17.347049 },
    { lat: 48.7741, lng: 17.346859 },
    { lat: 48.773791, lng: 17.346747 },
    { lat: 48.773468, lng: 17.346532 },
    { lat: 48.773185, lng: 17.346324 },
    { lat: 48.77304, lng: 17.346208 },
    { lat: 48.772861, lng: 17.346115 },
    { lat: 48.772509, lng: 17.345958 },
    { lat: 48.772131, lng: 17.345789 },
    { lat: 48.771867, lng: 17.345669 },
    { lat: 48.771778, lng: 17.345629 },
    { lat: 48.771711, lng: 17.345589 },
    { lat: 48.77163, lng: 17.345592 },
    { lat: 48.77149, lng: 17.345371 },
    { lat: 48.771361, lng: 17.345166 },
    { lat: 48.77115, lng: 17.344928 },
    { lat: 48.771078, lng: 17.344857 },
    { lat: 48.770991, lng: 17.344677 },
    { lat: 48.770615, lng: 17.34448 },
    { lat: 48.7706, lng: 17.34452 },
    { lat: 48.770577, lng: 17.344568 },
    { lat: 48.770451, lng: 17.34485 },
    { lat: 48.770121, lng: 17.345124 },
    { lat: 48.769703, lng: 17.345216 },
    { lat: 48.769392, lng: 17.345326 },
    { lat: 48.769248, lng: 17.345401 },
    { lat: 48.768893, lng: 17.3458 },
    { lat: 48.768593, lng: 17.346005 },
    { lat: 48.768564, lng: 17.346071 },
    { lat: 48.768563, lng: 17.346074 },
    { lat: 48.768546, lng: 17.346112 },
    { lat: 48.768479, lng: 17.346257 },
    { lat: 48.768382, lng: 17.346416 },
    { lat: 48.768293, lng: 17.346321 },
    { lat: 48.768261, lng: 17.34632 },
    { lat: 48.768169, lng: 17.346319 },
    { lat: 48.767823, lng: 17.346318 },
    { lat: 48.767408, lng: 17.345886 },
    { lat: 48.766883, lng: 17.34544 },
    { lat: 48.76636, lng: 17.345001 },
    { lat: 48.766119, lng: 17.344607 },
    { lat: 48.765804, lng: 17.344291 },
    { lat: 48.76581, lng: 17.344237 },
    { lat: 48.765857, lng: 17.344064 },
    { lat: 48.765684, lng: 17.343699 },
    { lat: 48.765403, lng: 17.343496 },
    { lat: 48.764994, lng: 17.343286 },
    { lat: 48.76473, lng: 17.343194 },
    { lat: 48.764709, lng: 17.343141 },
    { lat: 48.764677, lng: 17.343061 },
    { lat: 48.764514, lng: 17.342791 },
    { lat: 48.764341, lng: 17.342682 },
    { lat: 48.764135, lng: 17.342529 },
    { lat: 48.764129, lng: 17.342549 },
    { lat: 48.763961, lng: 17.342438 },
    { lat: 48.763758, lng: 17.342272 },
    { lat: 48.763503, lng: 17.34207 },
    { lat: 48.763264, lng: 17.341987 },
    { lat: 48.763144, lng: 17.341947 },
    { lat: 48.763073, lng: 17.34191 },
    { lat: 48.763051, lng: 17.341929 },
    { lat: 48.762855, lng: 17.342104 },
    { lat: 48.762591, lng: 17.342444 },
    { lat: 48.76237, lng: 17.342503 },
    { lat: 48.762317, lng: 17.342505 },
    { lat: 48.762244, lng: 17.342539 },
    { lat: 48.762156, lng: 17.342543 },
    { lat: 48.761974, lng: 17.342632 },
    { lat: 48.761528, lng: 17.342922 },
    { lat: 48.761152, lng: 17.343199 },
    { lat: 48.761033, lng: 17.343277 },
    { lat: 48.76088, lng: 17.343342 },
    { lat: 48.760711, lng: 17.343358 },
    { lat: 48.760477, lng: 17.343429 },
    { lat: 48.760203, lng: 17.3435 },
    { lat: 48.759894, lng: 17.343546 },
    { lat: 48.759632, lng: 17.343581 },
    { lat: 48.759405, lng: 17.343629 },
    { lat: 48.759255, lng: 17.343647 },
    { lat: 48.758908, lng: 17.343632 },
    { lat: 48.758803, lng: 17.343631 },
    { lat: 48.758631, lng: 17.343652 },
    { lat: 48.758225, lng: 17.343481 },
    { lat: 48.7580671, lng: 17.3433683 },
    { lat: 48.757771, lng: 17.343157 },
    { lat: 48.757608, lng: 17.342993 },
    { lat: 48.75737, lng: 17.342709 },
    { lat: 48.757111, lng: 17.342439 },
    { lat: 48.756855, lng: 17.342184 },
    { lat: 48.756814, lng: 17.342166 },
    { lat: 48.756203, lng: 17.341655 },
    { lat: 48.755927, lng: 17.341313 },
    { lat: 48.755718, lng: 17.34123 },
    { lat: 48.755054, lng: 17.34082 },
    { lat: 48.754956, lng: 17.340806 },
    { lat: 48.754606, lng: 17.340602 },
    { lat: 48.754362, lng: 17.340394 },
    { lat: 48.753762, lng: 17.340106 },
    { lat: 48.753251, lng: 17.339744 },
    { lat: 48.752728, lng: 17.339375 },
    { lat: 48.752418, lng: 17.339097 },
    { lat: 48.751975, lng: 17.338667 },
    { lat: 48.751592, lng: 17.338183 },
    { lat: 48.75113, lng: 17.337586 },
    { lat: 48.750622, lng: 17.336941 },
    { lat: 48.750158, lng: 17.336362 },
    { lat: 48.75002, lng: 17.336206 },
    { lat: 48.749698, lng: 17.335837 },
    { lat: 48.749584, lng: 17.335633 },
    { lat: 48.749429, lng: 17.335108 },
    { lat: 48.749117, lng: 17.334478 },
    { lat: 48.749147, lng: 17.334313 },
    { lat: 48.749326, lng: 17.333869 },
    { lat: 48.749193, lng: 17.333728 },
    { lat: 48.749118, lng: 17.333652 },
    { lat: 48.748869, lng: 17.333394 },
    { lat: 48.748774, lng: 17.333296 },
    { lat: 48.748534, lng: 17.332997 },
    { lat: 48.74828, lng: 17.33276 },
    { lat: 48.748144, lng: 17.332635 },
    { lat: 48.747701, lng: 17.332227 },
    { lat: 48.747705, lng: 17.332208 },
    { lat: 48.747657, lng: 17.332169 },
    { lat: 48.74753, lng: 17.332024 },
    { lat: 48.746893, lng: 17.331725 },
    { lat: 48.746361, lng: 17.331464 },
    { lat: 48.746076, lng: 17.331324 },
    { lat: 48.745873, lng: 17.331175 },
    { lat: 48.745428, lng: 17.330961 },
    { lat: 48.744986, lng: 17.330752 },
    { lat: 48.74484, lng: 17.330624 },
    { lat: 48.744431, lng: 17.330101 },
    { lat: 48.743984, lng: 17.329621 },
    { lat: 48.743994, lng: 17.329601 },
    { lat: 48.743996, lng: 17.329597 },
    { lat: 48.744043, lng: 17.329473 },
    { lat: 48.744066, lng: 17.329298 },
    { lat: 48.744067, lng: 17.329131 },
    { lat: 48.744056, lng: 17.328852 },
    { lat: 48.744044, lng: 17.328763 },
    { lat: 48.744033, lng: 17.32868 },
    { lat: 48.744024, lng: 17.328602 },
    { lat: 48.744023, lng: 17.328592 },
    { lat: 48.744026, lng: 17.328499 },
    { lat: 48.744027, lng: 17.328405 },
    { lat: 48.744026, lng: 17.328319 },
    { lat: 48.744021, lng: 17.328162 },
    { lat: 48.743963, lng: 17.328024 },
    { lat: 48.743919, lng: 17.327968 },
    { lat: 48.743883, lng: 17.327918 },
    { lat: 48.743848, lng: 17.327865 },
    { lat: 48.743742, lng: 17.327691 },
    { lat: 48.743651, lng: 17.327541 },
    { lat: 48.743608, lng: 17.327471 },
    { lat: 48.74357, lng: 17.327408 },
    { lat: 48.743531, lng: 17.327342 },
    { lat: 48.743492, lng: 17.327281 },
    { lat: 48.743447, lng: 17.327214 },
    { lat: 48.743403, lng: 17.327166 },
    { lat: 48.743366, lng: 17.327127 },
    { lat: 48.743336, lng: 17.327094 },
    { lat: 48.743233, lng: 17.326987 },
    { lat: 48.743139, lng: 17.326908 },
    { lat: 48.743104, lng: 17.326879 },
    { lat: 48.742982, lng: 17.326777 },
    { lat: 48.742869, lng: 17.326689 },
    { lat: 48.742734, lng: 17.326575 },
    { lat: 48.74262, lng: 17.326473 },
    { lat: 48.742526, lng: 17.326393 },
    { lat: 48.742407, lng: 17.326289 },
    { lat: 48.74234, lng: 17.326231 },
    { lat: 48.742285, lng: 17.326184 },
    { lat: 48.742221, lng: 17.326129 },
    { lat: 48.742146, lng: 17.326066 },
    { lat: 48.742103, lng: 17.326025 },
    { lat: 48.742078, lng: 17.326004 },
    { lat: 48.74202, lng: 17.325954 },
    { lat: 48.741965, lng: 17.325906 },
    { lat: 48.741844, lng: 17.325801 },
    { lat: 48.741786, lng: 17.325749 },
    { lat: 48.741726, lng: 17.325698 },
    { lat: 48.741661, lng: 17.325643 },
    { lat: 48.741601, lng: 17.325587 },
    { lat: 48.741579, lng: 17.325548 },
    { lat: 48.741554, lng: 17.325508 },
    { lat: 48.741514, lng: 17.325445 },
    { lat: 48.741427, lng: 17.325305 },
    { lat: 48.741346, lng: 17.325178 },
    { lat: 48.741261, lng: 17.325041 },
    { lat: 48.741222, lng: 17.324978 },
    { lat: 48.741177, lng: 17.324906 },
    { lat: 48.741139, lng: 17.324846 },
    { lat: 48.7411, lng: 17.324784 },
    { lat: 48.741004, lng: 17.32463 },
    { lat: 48.740924, lng: 17.324501 },
    { lat: 48.740894, lng: 17.324451 },
    { lat: 48.740865, lng: 17.324403 },
    { lat: 48.740843, lng: 17.324366 },
    { lat: 48.740758, lng: 17.324234 },
    { lat: 48.740716, lng: 17.324162 },
    { lat: 48.740675, lng: 17.324097 },
    { lat: 48.740633, lng: 17.32403 },
    { lat: 48.740594, lng: 17.323967 },
    { lat: 48.740559, lng: 17.323911 },
    { lat: 48.740516, lng: 17.323839 },
    { lat: 48.74021, lng: 17.32335 },
    { lat: 48.740203, lng: 17.323337 },
  ],
  Čáry: [
    { lat: 48.6512734, lng: 17.052514 },
    { lat: 48.6511759, lng: 17.052726 },
    { lat: 48.6510331, lng: 17.0534567 },
    { lat: 48.6509554, lng: 17.0548277 },
    { lat: 48.649709, lng: 17.0558957 },
    { lat: 48.6494507, lng: 17.0562039 },
    { lat: 48.649018, lng: 17.0558221 },
    { lat: 48.6486122, lng: 17.0567877 },
    { lat: 48.6484498, lng: 17.0580277 },
    { lat: 48.6489184, lng: 17.0595968 },
    { lat: 48.6489821, lng: 17.0597185 },
    { lat: 48.6493017, lng: 17.0598651 },
    { lat: 48.6495993, lng: 17.0604557 },
    { lat: 48.6501942, lng: 17.0602472 },
    { lat: 48.6505852, lng: 17.0602124 },
    { lat: 48.6507301, lng: 17.0602687 },
    { lat: 48.6509476, lng: 17.0605754 },
    { lat: 48.6507766, lng: 17.0613726 },
    { lat: 48.6506889, lng: 17.0620647 },
    { lat: 48.6504357, lng: 17.0623792 },
    { lat: 48.650133, lng: 17.0625988 },
    { lat: 48.6495628, lng: 17.0628402 },
    { lat: 48.6496107, lng: 17.0633135 },
    { lat: 48.6494276, lng: 17.063614 },
    { lat: 48.6491929, lng: 17.0636694 },
    { lat: 48.6485724, lng: 17.0636184 },
    { lat: 48.647735, lng: 17.0634175 },
    { lat: 48.6477253, lng: 17.0633232 },
    { lat: 48.6475176, lng: 17.063694 },
    { lat: 48.6477645, lng: 17.0654584 },
    { lat: 48.6476962, lng: 17.0654671 },
    { lat: 48.6477127, lng: 17.0655779 },
    { lat: 48.6474859, lng: 17.0660519 },
    { lat: 48.6476138, lng: 17.0668406 },
    { lat: 48.6479186, lng: 17.0669362 },
    { lat: 48.6479607, lng: 17.0670577 },
    { lat: 48.6477036, lng: 17.0678023 },
    { lat: 48.6477549, lng: 17.0682421 },
    { lat: 48.6479787, lng: 17.0688452 },
    { lat: 48.6487769, lng: 17.0692213 },
    { lat: 48.6488935, lng: 17.0694924 },
    { lat: 48.6489643, lng: 17.0701866 },
    { lat: 48.6491115, lng: 17.0706621 },
    { lat: 48.6493691, lng: 17.0710438 },
    { lat: 48.6488072, lng: 17.0718599 },
    { lat: 48.6485112, lng: 17.0718107 },
    { lat: 48.6479179, lng: 17.071392 },
    { lat: 48.6470073, lng: 17.0725151 },
    { lat: 48.6470019, lng: 17.0734378 },
    { lat: 48.6474548, lng: 17.0737527 },
    { lat: 48.6471492, lng: 17.0748024 },
    { lat: 48.6472284, lng: 17.075155 },
    { lat: 48.647212, lng: 17.0755463 },
    { lat: 48.6465992, lng: 17.0775862 },
    { lat: 48.6463361, lng: 17.077722 },
    { lat: 48.6464396, lng: 17.0785505 },
    { lat: 48.6462729, lng: 17.0789291 },
    { lat: 48.646175, lng: 17.0795212 },
    { lat: 48.6465011, lng: 17.0796298 },
    { lat: 48.6463842, lng: 17.080947 },
    { lat: 48.6462997, lng: 17.0809164 },
    { lat: 48.646224, lng: 17.0810681 },
    { lat: 48.6459616, lng: 17.081753 },
    { lat: 48.6459249, lng: 17.0819776 },
    { lat: 48.6458949, lng: 17.0822031 },
    { lat: 48.6462462, lng: 17.0823835 },
    { lat: 48.6461179, lng: 17.0830621 },
    { lat: 48.6459809, lng: 17.0849709 },
    { lat: 48.6457372, lng: 17.0850569 },
    { lat: 48.6457307, lng: 17.0856139 },
    { lat: 48.6458359, lng: 17.0866593 },
    { lat: 48.6457502, lng: 17.0876494 },
    { lat: 48.6460017, lng: 17.0877234 },
    { lat: 48.6459933, lng: 17.0879547 },
    { lat: 48.6460758, lng: 17.0885067 },
    { lat: 48.6459457, lng: 17.0887728 },
    { lat: 48.6458966, lng: 17.0893203 },
    { lat: 48.645937, lng: 17.0892969 },
    { lat: 48.6461266, lng: 17.0894793 },
    { lat: 48.6461373, lng: 17.0900566 },
    { lat: 48.646921, lng: 17.0905502 },
    { lat: 48.6472052, lng: 17.0919688 },
    { lat: 48.6462801, lng: 17.0921575 },
    { lat: 48.6465447, lng: 17.0932843 },
    { lat: 48.6468745, lng: 17.0957675 },
    { lat: 48.6476083, lng: 17.0963439 },
    { lat: 48.6481516, lng: 17.0964446 },
    { lat: 48.6484264, lng: 17.099015 },
    { lat: 48.6494891, lng: 17.102602 },
    { lat: 48.6496686, lng: 17.1043805 },
    { lat: 48.6499853, lng: 17.1046186 },
    { lat: 48.6503317, lng: 17.103168 },
    { lat: 48.6514891, lng: 17.1033052 },
    { lat: 48.6515856, lng: 17.1042363 },
    { lat: 48.651705, lng: 17.1042834 },
    { lat: 48.6520599, lng: 17.1042512 },
    { lat: 48.6523589, lng: 17.1043462 },
    { lat: 48.652721, lng: 17.1037655 },
    { lat: 48.6529505, lng: 17.1035795 },
    { lat: 48.6530732, lng: 17.1036936 },
    { lat: 48.6532684, lng: 17.1037063 },
    { lat: 48.6534007, lng: 17.103606 },
    { lat: 48.6536121, lng: 17.1036411 },
    { lat: 48.6539663, lng: 17.1038842 },
    { lat: 48.6540892, lng: 17.1038629 },
    { lat: 48.6543598, lng: 17.1040065 },
    { lat: 48.6545803, lng: 17.1040067 },
    { lat: 48.6567674, lng: 17.1100522 },
    { lat: 48.6573004, lng: 17.1110256 },
    { lat: 48.658158, lng: 17.1121674 },
    { lat: 48.6587801, lng: 17.1109671 },
    { lat: 48.6593399, lng: 17.1111763 },
    { lat: 48.6615679, lng: 17.1137462 },
    { lat: 48.6629951, lng: 17.1154979 },
    { lat: 48.6630616, lng: 17.115363 },
    { lat: 48.6633598, lng: 17.1150225 },
    { lat: 48.663486, lng: 17.1147159 },
    { lat: 48.6642383, lng: 17.1136693 },
    { lat: 48.6651036, lng: 17.1127233 },
    { lat: 48.6653485, lng: 17.1125876 },
    { lat: 48.6654586, lng: 17.1123902 },
    { lat: 48.6655379, lng: 17.112446 },
    { lat: 48.6656022, lng: 17.1123597 },
    { lat: 48.6658303, lng: 17.112625 },
    { lat: 48.6671095, lng: 17.1106245 },
    { lat: 48.6674668, lng: 17.1108468 },
    { lat: 48.6676405, lng: 17.1108781 },
    { lat: 48.6691617, lng: 17.1107646 },
    { lat: 48.6695087, lng: 17.1098246 },
    { lat: 48.6696707, lng: 17.1099142 },
    { lat: 48.6707505, lng: 17.1081606 },
    { lat: 48.6701635, lng: 17.1072924 },
    { lat: 48.669746, lng: 17.106107 },
    { lat: 48.6705432, lng: 17.104486 },
    { lat: 48.6707043, lng: 17.1045978 },
    { lat: 48.6716556, lng: 17.1020397 },
    { lat: 48.6723695, lng: 17.1004059 },
    { lat: 48.672428, lng: 17.1002504 },
    { lat: 48.6723502, lng: 17.1000525 },
    { lat: 48.6726881, lng: 17.0994498 },
    { lat: 48.6726253, lng: 17.0987351 },
    { lat: 48.6726362, lng: 17.0984638 },
    { lat: 48.6727199, lng: 17.098328 },
    { lat: 48.6729197, lng: 17.0981583 },
    { lat: 48.6730508, lng: 17.0991006 },
    { lat: 48.6732029, lng: 17.099497 },
    { lat: 48.6737388, lng: 17.0990956 },
    { lat: 48.6740289, lng: 17.0992099 },
    { lat: 48.6735605, lng: 17.0962107 },
    { lat: 48.6739395, lng: 17.095948 },
    { lat: 48.6742714, lng: 17.0954413 },
    { lat: 48.6744609, lng: 17.0953186 },
    { lat: 48.6747976, lng: 17.0948829 },
    { lat: 48.6752375, lng: 17.093612 },
    { lat: 48.6757529, lng: 17.0926756 },
    { lat: 48.675723, lng: 17.0925332 },
    { lat: 48.6758286, lng: 17.0923225 },
    { lat: 48.6758196, lng: 17.0922378 },
    { lat: 48.675929, lng: 17.0921502 },
    { lat: 48.6759922, lng: 17.0919496 },
    { lat: 48.6760881, lng: 17.0918791 },
    { lat: 48.6762446, lng: 17.0912844 },
    { lat: 48.6763942, lng: 17.091073 },
    { lat: 48.6764076, lng: 17.0909866 },
    { lat: 48.6763312, lng: 17.0909429 },
    { lat: 48.6764535, lng: 17.0908455 },
    { lat: 48.6765162, lng: 17.0907004 },
    { lat: 48.676521, lng: 17.0905366 },
    { lat: 48.6766562, lng: 17.0904304 },
    { lat: 48.6767383, lng: 17.0902583 },
    { lat: 48.6765963, lng: 17.0898615 },
    { lat: 48.6766663, lng: 17.0897088 },
    { lat: 48.676806, lng: 17.0896355 },
    { lat: 48.6768598, lng: 17.0895121 },
    { lat: 48.6768181, lng: 17.0894809 },
    { lat: 48.6768494, lng: 17.0892738 },
    { lat: 48.676938, lng: 17.0892459 },
    { lat: 48.6770313, lng: 17.0887539 },
    { lat: 48.6771577, lng: 17.0884477 },
    { lat: 48.6771161, lng: 17.0883851 },
    { lat: 48.6772571, lng: 17.0882122 },
    { lat: 48.6774025, lng: 17.087621 },
    { lat: 48.6774369, lng: 17.0873272 },
    { lat: 48.6773874, lng: 17.087229 },
    { lat: 48.6774332, lng: 17.0871869 },
    { lat: 48.677447, lng: 17.0869563 },
    { lat: 48.6774923, lng: 17.0869573 },
    { lat: 48.677469, lng: 17.086865 },
    { lat: 48.6775999, lng: 17.0866429 },
    { lat: 48.6776108, lng: 17.0865193 },
    { lat: 48.6777337, lng: 17.0864643 },
    { lat: 48.6779022, lng: 17.0860648 },
    { lat: 48.6779058, lng: 17.085933 },
    { lat: 48.6781065, lng: 17.0857767 },
    { lat: 48.6782787, lng: 17.085264 },
    { lat: 48.6782612, lng: 17.0850532 },
    { lat: 48.6782994, lng: 17.0848829 },
    { lat: 48.6783722, lng: 17.0848742 },
    { lat: 48.6783855, lng: 17.0847688 },
    { lat: 48.6784885, lng: 17.0846883 },
    { lat: 48.6784909, lng: 17.0845906 },
    { lat: 48.6785695, lng: 17.0845657 },
    { lat: 48.6786586, lng: 17.0840607 },
    { lat: 48.6787815, lng: 17.0839729 },
    { lat: 48.6789198, lng: 17.0827052 },
    { lat: 48.6791867, lng: 17.0820513 },
    { lat: 48.6792112, lng: 17.0817619 },
    { lat: 48.6793824, lng: 17.081368 },
    { lat: 48.6795086, lng: 17.0808766 },
    { lat: 48.6794525, lng: 17.080818 },
    { lat: 48.6795506, lng: 17.0807125 },
    { lat: 48.6795446, lng: 17.0805578 },
    { lat: 48.6796236, lng: 17.0805032 },
    { lat: 48.6796835, lng: 17.0803559 },
    { lat: 48.6797526, lng: 17.0803732 },
    { lat: 48.6797877, lng: 17.0802748 },
    { lat: 48.6797442, lng: 17.0802304 },
    { lat: 48.6798648, lng: 17.0800592 },
    { lat: 48.6799288, lng: 17.079685 },
    { lat: 48.6800234, lng: 17.0796476 },
    { lat: 48.6800759, lng: 17.0791693 },
    { lat: 48.6801871, lng: 17.0789927 },
    { lat: 48.6802911, lng: 17.0789588 },
    { lat: 48.6803851, lng: 17.0787095 },
    { lat: 48.6804142, lng: 17.0787232 },
    { lat: 48.6803758, lng: 17.0785046 },
    { lat: 48.6804695, lng: 17.0784194 },
    { lat: 48.6805711, lng: 17.0781488 },
    { lat: 48.6806439, lng: 17.0781752 },
    { lat: 48.6807451, lng: 17.0780681 },
    { lat: 48.6807465, lng: 17.0778537 },
    { lat: 48.6808452, lng: 17.077807 },
    { lat: 48.6808316, lng: 17.0775388 },
    { lat: 48.6809601, lng: 17.0772323 },
    { lat: 48.6809419, lng: 17.0771489 },
    { lat: 48.6816701, lng: 17.0755798 },
    { lat: 48.6817995, lng: 17.07482 },
    { lat: 48.6820563, lng: 17.0744365 },
    { lat: 48.6821328, lng: 17.0739631 },
    { lat: 48.6820884, lng: 17.0726033 },
    { lat: 48.6821502, lng: 17.0720091 },
    { lat: 48.6820545, lng: 17.0717492 },
    { lat: 48.6819209, lng: 17.0715592 },
    { lat: 48.6818267, lng: 17.0704337 },
    { lat: 48.6816846, lng: 17.0699003 },
    { lat: 48.6817253, lng: 17.0694331 },
    { lat: 48.6818843, lng: 17.0691926 },
    { lat: 48.6821384, lng: 17.0690069 },
    { lat: 48.6826787, lng: 17.0681406 },
    { lat: 48.6829362, lng: 17.0674687 },
    { lat: 48.683091, lng: 17.0666471 },
    { lat: 48.684768, lng: 17.064746 },
    { lat: 48.684693, lng: 17.064542 },
    { lat: 48.684703, lng: 17.064532 },
    { lat: 48.684818, lng: 17.064407 },
    { lat: 48.684861, lng: 17.064358 },
    { lat: 48.684978, lng: 17.06423 },
    { lat: 48.685114, lng: 17.064071 },
    { lat: 48.6851993, lng: 17.0639713 },
    { lat: 48.68528, lng: 17.063877 },
    { lat: 48.685335, lng: 17.063842 },
    { lat: 48.685553, lng: 17.063733 },
    { lat: 48.685635, lng: 17.063627 },
    { lat: 48.686255, lng: 17.062196 },
    { lat: 48.686343, lng: 17.062092 },
    { lat: 48.686757, lng: 17.061296 },
    { lat: 48.686774, lng: 17.061235 },
    { lat: 48.686821, lng: 17.061045 },
    { lat: 48.686894, lng: 17.060786 },
    { lat: 48.68703, lng: 17.060602 },
    { lat: 48.687159, lng: 17.06036 },
    { lat: 48.687206, lng: 17.060084 },
    { lat: 48.687292, lng: 17.059879 },
    { lat: 48.687315, lng: 17.059816 },
    { lat: 48.687682, lng: 17.058852 },
    { lat: 48.687576, lng: 17.057754 },
    { lat: 48.68741, lng: 17.056363 },
    { lat: 48.686934, lng: 17.055225 },
    { lat: 48.686893, lng: 17.055128 },
    { lat: 48.6868473, lng: 17.0550625 },
    { lat: 48.6842716, lng: 17.0522488 },
    { lat: 48.6844872, lng: 17.0514939 },
    { lat: 48.6843152, lng: 17.0512755 },
    { lat: 48.6839136, lng: 17.0513178 },
    { lat: 48.6835766, lng: 17.0511316 },
    { lat: 48.6829353, lng: 17.0509552 },
    { lat: 48.6823027, lng: 17.050992 },
    { lat: 48.6809161, lng: 17.0504218 },
    { lat: 48.6806112, lng: 17.050225 },
    { lat: 48.6801297, lng: 17.0500551 },
    { lat: 48.6798701, lng: 17.0500426 },
    { lat: 48.6793656, lng: 17.0495161 },
    { lat: 48.6787962, lng: 17.049402 },
    { lat: 48.6771109, lng: 17.0487961 },
    { lat: 48.6757357, lng: 17.0449898 },
    { lat: 48.6754701, lng: 17.0446726 },
    { lat: 48.675215, lng: 17.0445936 },
    { lat: 48.673804, lng: 17.0445666 },
    { lat: 48.6730343, lng: 17.0457151 },
    { lat: 48.6730648, lng: 17.0458649 },
    { lat: 48.6733153, lng: 17.0463618 },
    { lat: 48.6731626, lng: 17.0465748 },
    { lat: 48.673197, lng: 17.0468176 },
    { lat: 48.6728997, lng: 17.0472314 },
    { lat: 48.6722208, lng: 17.0479204 },
    { lat: 48.6712126, lng: 17.048564 },
    { lat: 48.6704363, lng: 17.0489337 },
    { lat: 48.6696139, lng: 17.0489062 },
    { lat: 48.6691472, lng: 17.0494834 },
    { lat: 48.6688062, lng: 17.0491052 },
    { lat: 48.6678934, lng: 17.0500075 },
    { lat: 48.6665442, lng: 17.0514921 },
    { lat: 48.6662758, lng: 17.0517874 },
    { lat: 48.6660831, lng: 17.0514768 },
    { lat: 48.6653342, lng: 17.0514319 },
    { lat: 48.6646858, lng: 17.0519698 },
    { lat: 48.6645999, lng: 17.0518028 },
    { lat: 48.6643919, lng: 17.0522521 },
    { lat: 48.6642171, lng: 17.0524238 },
    { lat: 48.6635749, lng: 17.0523849 },
    { lat: 48.6624909, lng: 17.0527626 },
    { lat: 48.6624077, lng: 17.0526357 },
    { lat: 48.6619508, lng: 17.0513457 },
    { lat: 48.6604862, lng: 17.0535818 },
    { lat: 48.6600576, lng: 17.0543788 },
    { lat: 48.6592642, lng: 17.0536237 },
    { lat: 48.6590782, lng: 17.0546331 },
    { lat: 48.6577647, lng: 17.0544936 },
    { lat: 48.6576309, lng: 17.0554728 },
    { lat: 48.6575517, lng: 17.0558524 },
    { lat: 48.6575019, lng: 17.0558515 },
    { lat: 48.6574094, lng: 17.0567068 },
    { lat: 48.657099, lng: 17.0585488 },
    { lat: 48.6556815, lng: 17.0579734 },
    { lat: 48.6558992, lng: 17.0570692 },
    { lat: 48.6555043, lng: 17.0567931 },
    { lat: 48.6553712, lng: 17.0565798 },
    { lat: 48.6553585, lng: 17.0557887 },
    { lat: 48.655535, lng: 17.0551922 },
    { lat: 48.6551328, lng: 17.0550262 },
    { lat: 48.6545435, lng: 17.0551838 },
    { lat: 48.6540846, lng: 17.055216 },
    { lat: 48.6534638, lng: 17.0551608 },
    { lat: 48.6534273, lng: 17.0546701 },
    { lat: 48.6526169, lng: 17.0545016 },
    { lat: 48.6526745, lng: 17.0534313 },
    { lat: 48.6524389, lng: 17.0527878 },
    { lat: 48.6522668, lng: 17.0526175 },
    { lat: 48.6521034, lng: 17.0526409 },
    { lat: 48.6519565, lng: 17.0522236 },
    { lat: 48.6516763, lng: 17.0520724 },
    { lat: 48.651514, lng: 17.052245 },
    { lat: 48.6514053, lng: 17.0525259 },
    { lat: 48.6512734, lng: 17.052514 },
  ],
  Cerová: [
    { lat: 48.565892, lng: 17.448871 },
    { lat: 48.5659301, lng: 17.4489382 },
    { lat: 48.5663652, lng: 17.4493297 },
    { lat: 48.5666866, lng: 17.4494436 },
    { lat: 48.5671912, lng: 17.449212 },
    { lat: 48.5677865, lng: 17.4487574 },
    { lat: 48.5680583, lng: 17.4484944 },
    { lat: 48.5691677, lng: 17.4474207 },
    { lat: 48.5701334, lng: 17.4466866 },
    { lat: 48.570513, lng: 17.4463003 },
    { lat: 48.5708178, lng: 17.445876 },
    { lat: 48.5714869, lng: 17.4443753 },
    { lat: 48.5716581, lng: 17.4440863 },
    { lat: 48.5724056, lng: 17.4430973 },
    { lat: 48.5731042, lng: 17.4423347 },
    { lat: 48.5733316, lng: 17.4419228 },
    { lat: 48.5734397, lng: 17.4415464 },
    { lat: 48.5738045, lng: 17.4394406 },
    { lat: 48.5737863, lng: 17.4378975 },
    { lat: 48.5738506, lng: 17.4369106 },
    { lat: 48.5743342, lng: 17.4360738 },
    { lat: 48.5746624, lng: 17.43582 },
    { lat: 48.5755439, lng: 17.4347074 },
    { lat: 48.5760873, lng: 17.4334288 },
    { lat: 48.5763021, lng: 17.4331523 },
    { lat: 48.5762257, lng: 17.4330162 },
    { lat: 48.576159, lng: 17.4326643 },
    { lat: 48.5762255, lng: 17.4321548 },
    { lat: 48.5764535, lng: 17.4314167 },
    { lat: 48.5762769, lng: 17.4311822 },
    { lat: 48.5762529, lng: 17.4306878 },
    { lat: 48.5761051, lng: 17.4303224 },
    { lat: 48.5760346, lng: 17.4302604 },
    { lat: 48.5759943, lng: 17.4288125 },
    { lat: 48.5759009, lng: 17.4282926 },
    { lat: 48.5758303, lng: 17.4272861 },
    { lat: 48.5756069, lng: 17.42638 },
    { lat: 48.5754596, lng: 17.4254604 },
    { lat: 48.5751735, lng: 17.4245261 },
    { lat: 48.5750101, lng: 17.4242344 },
    { lat: 48.5747934, lng: 17.4240083 },
    { lat: 48.5743954, lng: 17.4241789 },
    { lat: 48.5742101, lng: 17.4233711 },
    { lat: 48.5742889, lng: 17.4216698 },
    { lat: 48.5742391, lng: 17.4212309 },
    { lat: 48.5747478, lng: 17.4208696 },
    { lat: 48.5752249, lng: 17.420667 },
    { lat: 48.5752341, lng: 17.4201424 },
    { lat: 48.5751564, lng: 17.4192207 },
    { lat: 48.5753034, lng: 17.4182241 },
    { lat: 48.5755599, lng: 17.4177014 },
    { lat: 48.5754234, lng: 17.4172328 },
    { lat: 48.5757123, lng: 17.4170891 },
    { lat: 48.5761875, lng: 17.4165055 },
    { lat: 48.5762685, lng: 17.4165353 },
    { lat: 48.5763877, lng: 17.4164055 },
    { lat: 48.5769845, lng: 17.41554 },
    { lat: 48.5771137, lng: 17.4152318 },
    { lat: 48.5773142, lng: 17.4151182 },
    { lat: 48.5776308, lng: 17.4147543 },
    { lat: 48.5777761, lng: 17.4143653 },
    { lat: 48.5777889, lng: 17.414194 },
    { lat: 48.5776363, lng: 17.4138793 },
    { lat: 48.5775423, lng: 17.4137861 },
    { lat: 48.5775785, lng: 17.413351 },
    { lat: 48.5778489, lng: 17.4126739 },
    { lat: 48.5782865, lng: 17.412212 },
    { lat: 48.5788984, lng: 17.4117002 },
    { lat: 48.5797274, lng: 17.4119784 },
    { lat: 48.579853, lng: 17.4114938 },
    { lat: 48.5806329, lng: 17.4110472 },
    { lat: 48.5806112, lng: 17.4110079 },
    { lat: 48.5812093, lng: 17.410949 },
    { lat: 48.5816675, lng: 17.4109788 },
    { lat: 48.5819055, lng: 17.4108486 },
    { lat: 48.5824703, lng: 17.409941 },
    { lat: 48.5834246, lng: 17.4090653 },
    { lat: 48.5842922, lng: 17.4087346 },
    { lat: 48.5849157, lng: 17.4086855 },
    { lat: 48.5850621, lng: 17.4101441 },
    { lat: 48.5852316, lng: 17.4105669 },
    { lat: 48.5859236, lng: 17.4103344 },
    { lat: 48.5863387, lng: 17.4100633 },
    { lat: 48.587023, lng: 17.4099022 },
    { lat: 48.5872188, lng: 17.4096983 },
    { lat: 48.5878071, lng: 17.4094332 },
    { lat: 48.588118, lng: 17.409161 },
    { lat: 48.5882783, lng: 17.4089134 },
    { lat: 48.5884574, lng: 17.40882 },
    { lat: 48.5887211, lng: 17.4084256 },
    { lat: 48.5891546, lng: 17.4082299 },
    { lat: 48.5893068, lng: 17.4083471 },
    { lat: 48.5899352, lng: 17.4079183 },
    { lat: 48.5902153, lng: 17.4075326 },
    { lat: 48.5903552, lng: 17.4072498 },
    { lat: 48.5902403, lng: 17.4067654 },
    { lat: 48.5934917, lng: 17.4006799 },
    { lat: 48.5976348, lng: 17.4047348 },
    { lat: 48.5976889, lng: 17.4043854 },
    { lat: 48.5979849, lng: 17.4039144 },
    { lat: 48.5982985, lng: 17.4036809 },
    { lat: 48.5990982, lng: 17.4026838 },
    { lat: 48.6003, lng: 17.4015672 },
    { lat: 48.6010825, lng: 17.4005853 },
    { lat: 48.6021675, lng: 17.4009639 },
    { lat: 48.6023068, lng: 17.4006037 },
    { lat: 48.6024378, lng: 17.4004106 },
    { lat: 48.6023694, lng: 17.3993872 },
    { lat: 48.6022693, lng: 17.3990986 },
    { lat: 48.6023101, lng: 17.3986097 },
    { lat: 48.6024749, lng: 17.3983096 },
    { lat: 48.6026077, lng: 17.3981827 },
    { lat: 48.6027277, lng: 17.3978706 },
    { lat: 48.602842, lng: 17.3977874 },
    { lat: 48.6031162, lng: 17.3980119 },
    { lat: 48.603194, lng: 17.3977467 },
    { lat: 48.6035573, lng: 17.397689 },
    { lat: 48.6038695, lng: 17.3975392 },
    { lat: 48.6041633, lng: 17.3972626 },
    { lat: 48.6046469, lng: 17.3966711 },
    { lat: 48.6048888, lng: 17.3960088 },
    { lat: 48.604978, lng: 17.3954856 },
    { lat: 48.6046816, lng: 17.3952687 },
    { lat: 48.6040152, lng: 17.3945706 },
    { lat: 48.6036424, lng: 17.3945887 },
    { lat: 48.6034223, lng: 17.394049 },
    { lat: 48.6046418, lng: 17.3922471 },
    { lat: 48.6055441, lng: 17.3935176 },
    { lat: 48.6058698, lng: 17.3924793 },
    { lat: 48.6060133, lng: 17.3956541 },
    { lat: 48.6070034, lng: 17.3961694 },
    { lat: 48.6070169, lng: 17.3960643 },
    { lat: 48.608412, lng: 17.3968009 },
    { lat: 48.6101555, lng: 17.3860467 },
    { lat: 48.6110994, lng: 17.3798218 },
    { lat: 48.6093328, lng: 17.3799757 },
    { lat: 48.6091702, lng: 17.3793704 },
    { lat: 48.6097483, lng: 17.3793822 },
    { lat: 48.6103516, lng: 17.3781664 },
    { lat: 48.6107751, lng: 17.3774532 },
    { lat: 48.6113293, lng: 17.3770777 },
    { lat: 48.6120457, lng: 17.3768035 },
    { lat: 48.6120355, lng: 17.376665 },
    { lat: 48.612041, lng: 17.376605 },
    { lat: 48.612015, lng: 17.376267 },
    { lat: 48.612014, lng: 17.376241 },
    { lat: 48.611994, lng: 17.376238 },
    { lat: 48.61151, lng: 17.376162 },
    { lat: 48.6108, lng: 17.376054 },
    { lat: 48.610718, lng: 17.376041 },
    { lat: 48.610284, lng: 17.375982 },
    { lat: 48.609519, lng: 17.375878 },
    { lat: 48.609214, lng: 17.375854 },
    { lat: 48.609, lng: 17.37584 },
    { lat: 48.608567, lng: 17.375805 },
    { lat: 48.608324, lng: 17.375787 },
    { lat: 48.607838, lng: 17.375753 },
    { lat: 48.607453, lng: 17.375728 },
    { lat: 48.607229, lng: 17.375557 },
    { lat: 48.607213, lng: 17.375546 },
    { lat: 48.606393, lng: 17.374947 },
    { lat: 48.60595, lng: 17.374633 },
    { lat: 48.605572, lng: 17.374257 },
    { lat: 48.604916, lng: 17.373548 },
    { lat: 48.6048011, lng: 17.3734255 },
    { lat: 48.604427, lng: 17.373027 },
    { lat: 48.604117, lng: 17.37269 },
    { lat: 48.603963, lng: 17.372523 },
    { lat: 48.603389, lng: 17.371911 },
    { lat: 48.602954, lng: 17.371474 },
    { lat: 48.602938, lng: 17.371458 },
    { lat: 48.602913, lng: 17.371431 },
    { lat: 48.60291, lng: 17.371428 },
    { lat: 48.602886, lng: 17.371403 },
    { lat: 48.602842, lng: 17.371328 },
    { lat: 48.602627, lng: 17.370977 },
    { lat: 48.602544, lng: 17.370843 },
    { lat: 48.602489, lng: 17.370751 },
    { lat: 48.602467, lng: 17.370716 },
    { lat: 48.602449, lng: 17.370686 },
    { lat: 48.602422, lng: 17.370641 },
    { lat: 48.602408, lng: 17.370617 },
    { lat: 48.602392, lng: 17.370591 },
    { lat: 48.602367, lng: 17.370551 },
    { lat: 48.602351, lng: 17.370525 },
    { lat: 48.602308, lng: 17.370453 },
    { lat: 48.602277, lng: 17.370405 },
    { lat: 48.60225, lng: 17.370361 },
    { lat: 48.602219, lng: 17.370312 },
    { lat: 48.602159, lng: 17.370214 },
    { lat: 48.602129, lng: 17.370164 },
    { lat: 48.602111, lng: 17.370137 },
    { lat: 48.6021, lng: 17.370117 },
    { lat: 48.602071, lng: 17.37007 },
    { lat: 48.602042, lng: 17.370021 },
    { lat: 48.602032, lng: 17.370004 },
    { lat: 48.602016, lng: 17.369979 },
    { lat: 48.60199, lng: 17.369936 },
    { lat: 48.601961, lng: 17.369887 },
    { lat: 48.601933, lng: 17.369841 },
    { lat: 48.601906, lng: 17.369798 },
    { lat: 48.601875, lng: 17.369745 },
    { lat: 48.601847, lng: 17.3697 },
    { lat: 48.601815, lng: 17.369648 },
    { lat: 48.601784, lng: 17.369595 },
    { lat: 48.601754, lng: 17.369546 },
    { lat: 48.601697, lng: 17.369453 },
    { lat: 48.601666, lng: 17.369402 },
    { lat: 48.60161, lng: 17.36931 },
    { lat: 48.601585, lng: 17.36927 },
    { lat: 48.601557, lng: 17.369225 },
    { lat: 48.601528, lng: 17.369176 },
    { lat: 48.601498, lng: 17.369126 },
    { lat: 48.601467, lng: 17.369077 },
    { lat: 48.601437, lng: 17.369028 },
    { lat: 48.601413, lng: 17.368989 },
    { lat: 48.601388, lng: 17.368946 },
    { lat: 48.601368, lng: 17.368913 },
    { lat: 48.601364, lng: 17.368907 },
    { lat: 48.601335, lng: 17.368861 },
    { lat: 48.601296, lng: 17.368796 },
    { lat: 48.60124, lng: 17.368704 },
    { lat: 48.601221, lng: 17.368674 },
    { lat: 48.601201, lng: 17.368642 },
    { lat: 48.601185, lng: 17.368614 },
    { lat: 48.601163, lng: 17.368579 },
    { lat: 48.601143, lng: 17.368546 },
    { lat: 48.601125, lng: 17.368517 },
    { lat: 48.601109, lng: 17.368491 },
    { lat: 48.601095, lng: 17.368466 },
    { lat: 48.601067, lng: 17.368423 },
    { lat: 48.601048, lng: 17.368393 },
    { lat: 48.601029, lng: 17.36836 },
    { lat: 48.601008, lng: 17.368327 },
    { lat: 48.600996, lng: 17.368307 },
    { lat: 48.600985, lng: 17.36829 },
    { lat: 48.60096, lng: 17.368249 },
    { lat: 48.600943, lng: 17.368222 },
    { lat: 48.60092, lng: 17.368184 },
    { lat: 48.600904, lng: 17.368158 },
    { lat: 48.600884, lng: 17.368125 },
    { lat: 48.600867, lng: 17.368098 },
    { lat: 48.60085, lng: 17.368068 },
    { lat: 48.600814, lng: 17.368012 },
    { lat: 48.600794, lng: 17.367979 },
    { lat: 48.600773, lng: 17.367944 },
    { lat: 48.600701, lng: 17.367826 },
    { lat: 48.600678, lng: 17.36779 },
    { lat: 48.600655, lng: 17.367753 },
    { lat: 48.600636, lng: 17.367722 },
    { lat: 48.600615, lng: 17.367688 },
    { lat: 48.600595, lng: 17.367655 },
    { lat: 48.600574, lng: 17.367622 },
    { lat: 48.600531, lng: 17.367551 },
    { lat: 48.600513, lng: 17.367521 },
    { lat: 48.600459, lng: 17.367435 },
    { lat: 48.600425, lng: 17.36738 },
    { lat: 48.600406, lng: 17.367348 },
    { lat: 48.600368, lng: 17.367285 },
    { lat: 48.600342, lng: 17.367243 },
    { lat: 48.600288, lng: 17.367154 },
    { lat: 48.600241, lng: 17.367079 },
    { lat: 48.600208, lng: 17.367025 },
    { lat: 48.600184, lng: 17.366987 },
    { lat: 48.600166, lng: 17.366957 },
    { lat: 48.600145, lng: 17.366922 },
    { lat: 48.600118, lng: 17.366879 },
    { lat: 48.600021, lng: 17.366721 },
    { lat: 48.599997, lng: 17.366683 },
    { lat: 48.599906, lng: 17.366531 },
    { lat: 48.599838, lng: 17.36642 },
    { lat: 48.5998, lng: 17.366358 },
    { lat: 48.59977, lng: 17.366309 },
    { lat: 48.599748, lng: 17.366273 },
    { lat: 48.599726, lng: 17.366238 },
    { lat: 48.599676, lng: 17.366155 },
    { lat: 48.599632, lng: 17.366083 },
    { lat: 48.599591, lng: 17.366017 },
    { lat: 48.599567, lng: 17.365977 },
    { lat: 48.599537, lng: 17.365928 },
    { lat: 48.599464, lng: 17.365807 },
    { lat: 48.599431, lng: 17.365755 },
    { lat: 48.599397, lng: 17.365699 },
    { lat: 48.599353, lng: 17.365627 },
    { lat: 48.599325, lng: 17.365583 },
    { lat: 48.599244, lng: 17.365453 },
    { lat: 48.599219, lng: 17.365411 },
    { lat: 48.599172, lng: 17.365335 },
    { lat: 48.599117, lng: 17.365246 },
    { lat: 48.599089, lng: 17.365201 },
    { lat: 48.599028, lng: 17.365103 },
    { lat: 48.598988, lng: 17.365037 },
    { lat: 48.598962, lng: 17.364997 },
    { lat: 48.598915, lng: 17.364919 },
    { lat: 48.59888, lng: 17.364862 },
    { lat: 48.59885, lng: 17.364814 },
    { lat: 48.598774, lng: 17.364693 },
    { lat: 48.598749, lng: 17.364652 },
    { lat: 48.598696, lng: 17.364567 },
    { lat: 48.598671, lng: 17.364524 },
    { lat: 48.59861, lng: 17.364425 },
    { lat: 48.598575, lng: 17.364368 },
    { lat: 48.598544, lng: 17.364317 },
    { lat: 48.598532, lng: 17.364296 },
    { lat: 48.598491, lng: 17.364231 },
    { lat: 48.598452, lng: 17.364166 },
    { lat: 48.598432, lng: 17.364133 },
    { lat: 48.598395, lng: 17.364074 },
    { lat: 48.598309, lng: 17.36393 },
    { lat: 48.59818, lng: 17.363723 },
    { lat: 48.597997, lng: 17.363427 },
    { lat: 48.597895, lng: 17.363261 },
    { lat: 48.597715, lng: 17.362969 },
    { lat: 48.597539, lng: 17.362677 },
    { lat: 48.597493, lng: 17.362606 },
    { lat: 48.597396, lng: 17.362448 },
    { lat: 48.597309, lng: 17.362301 },
    { lat: 48.597276, lng: 17.362255 },
    { lat: 48.597252, lng: 17.362212 },
    { lat: 48.597223, lng: 17.362163 },
    { lat: 48.5972, lng: 17.362127 },
    { lat: 48.597134, lng: 17.362018 },
    { lat: 48.597088, lng: 17.361943 },
    { lat: 48.597016, lng: 17.361826 },
    { lat: 48.596866, lng: 17.361585 },
    { lat: 48.596824, lng: 17.361513 },
    { lat: 48.596794, lng: 17.361468 },
    { lat: 48.5967, lng: 17.36131 },
    { lat: 48.596694, lng: 17.361302 },
    { lat: 48.59667, lng: 17.361264 },
    { lat: 48.596657, lng: 17.361238 },
    { lat: 48.596643, lng: 17.361222 },
    { lat: 48.596634, lng: 17.361205 },
    { lat: 48.596609, lng: 17.361163 },
    { lat: 48.596586, lng: 17.361124 },
    { lat: 48.596418, lng: 17.360848 },
    { lat: 48.596295, lng: 17.360651 },
    { lat: 48.596324, lng: 17.360614 },
    { lat: 48.596568, lng: 17.360214 },
    { lat: 48.595741, lng: 17.359139 },
    { lat: 48.595746, lng: 17.359069 },
    { lat: 48.595675, lng: 17.358213 },
    { lat: 48.595413, lng: 17.357867 },
    { lat: 48.595189, lng: 17.357657 },
    { lat: 48.595092, lng: 17.357626 },
    { lat: 48.594796, lng: 17.357265 },
    { lat: 48.594786, lng: 17.356998 },
    { lat: 48.595051, lng: 17.356413 },
    { lat: 48.594598, lng: 17.356022 },
    { lat: 48.59415, lng: 17.356035 },
    { lat: 48.593819, lng: 17.356547 },
    { lat: 48.593782, lng: 17.356606 },
    { lat: 48.592292, lng: 17.358925 },
    { lat: 48.591629, lng: 17.359935 },
    { lat: 48.590652, lng: 17.356717 },
    { lat: 48.591771, lng: 17.355308 },
    { lat: 48.591901, lng: 17.355541 },
    { lat: 48.592009, lng: 17.355736 },
    { lat: 48.592029, lng: 17.355768 },
    { lat: 48.592037, lng: 17.355805 },
    { lat: 48.59205, lng: 17.355856 },
    { lat: 48.592117, lng: 17.355971 },
    { lat: 48.592494, lng: 17.355486 },
    { lat: 48.592309, lng: 17.355156 },
    { lat: 48.592065, lng: 17.354936 },
    { lat: 48.592115, lng: 17.354874 },
    { lat: 48.592041, lng: 17.354827 },
    { lat: 48.592007, lng: 17.354804 },
    { lat: 48.591997, lng: 17.354798 },
    { lat: 48.591961, lng: 17.354773 },
    { lat: 48.591833, lng: 17.35469 },
    { lat: 48.591615, lng: 17.354368 },
    { lat: 48.591103, lng: 17.353617 },
    { lat: 48.5906618, lng: 17.3529821 },
    { lat: 48.59049, lng: 17.352735 },
    { lat: 48.589723, lng: 17.351629 },
    { lat: 48.589146, lng: 17.350792 },
    { lat: 48.588651, lng: 17.350081 },
    { lat: 48.588122, lng: 17.349317 },
    { lat: 48.588061, lng: 17.349228 },
    { lat: 48.588003, lng: 17.349143 },
    { lat: 48.587941, lng: 17.349052 },
    { lat: 48.587882, lng: 17.348967 },
    { lat: 48.587822, lng: 17.348878 },
    { lat: 48.587697, lng: 17.348696 },
    { lat: 48.587632, lng: 17.3486 },
    { lat: 48.587567, lng: 17.348507 },
    { lat: 48.587503, lng: 17.348414 },
    { lat: 48.587377, lng: 17.348231 },
    { lat: 48.587307, lng: 17.348133 },
    { lat: 48.587172, lng: 17.347938 },
    { lat: 48.58704, lng: 17.347747 },
    { lat: 48.586944, lng: 17.347609 },
    { lat: 48.586899, lng: 17.347575 },
    { lat: 48.586734, lng: 17.34745 },
    { lat: 48.586575, lng: 17.347328 },
    { lat: 48.586278, lng: 17.347133 },
    { lat: 48.585739, lng: 17.346779 },
    { lat: 48.5846905, lng: 17.3461495 },
    { lat: 48.5846295, lng: 17.3460755 },
    { lat: 48.5841165, lng: 17.3454615 },
    { lat: 48.5833473, lng: 17.3470426 },
    { lat: 48.5813362, lng: 17.3523957 },
    { lat: 48.581276, lng: 17.3526711 },
    { lat: 48.5818655, lng: 17.3528579 },
    { lat: 48.5805022, lng: 17.3574042 },
    { lat: 48.5795535, lng: 17.3568175 },
    { lat: 48.5771842, lng: 17.3557132 },
    { lat: 48.5767274, lng: 17.3568523 },
    { lat: 48.5766368, lng: 17.3572302 },
    { lat: 48.5763022, lng: 17.3579927 },
    { lat: 48.5757831, lng: 17.3588328 },
    { lat: 48.5754098, lng: 17.3592907 },
    { lat: 48.5750887, lng: 17.3598778 },
    { lat: 48.5742396, lng: 17.359708 },
    { lat: 48.573717, lng: 17.3597852 },
    { lat: 48.5732054, lng: 17.3600456 },
    { lat: 48.5728461, lng: 17.3603223 },
    { lat: 48.5722208, lng: 17.3609526 },
    { lat: 48.5714595, lng: 17.3614249 },
    { lat: 48.5712792, lng: 17.361672 },
    { lat: 48.5711204, lng: 17.3624259 },
    { lat: 48.5711354, lng: 17.3626056 },
    { lat: 48.5708247, lng: 17.3635927 },
    { lat: 48.5706711, lng: 17.3638701 },
    { lat: 48.5704523, lng: 17.3641007 },
    { lat: 48.5697038, lng: 17.3646135 },
    { lat: 48.5699446, lng: 17.3653466 },
    { lat: 48.5699911, lng: 17.3656425 },
    { lat: 48.5697539, lng: 17.3661294 },
    { lat: 48.5695474, lng: 17.3668087 },
    { lat: 48.5697113, lng: 17.3671051 },
    { lat: 48.5695837, lng: 17.3674217 },
    { lat: 48.5695694, lng: 17.367635 },
    { lat: 48.5696534, lng: 17.3678498 },
    { lat: 48.569565, lng: 17.3682091 },
    { lat: 48.5695705, lng: 17.3683991 },
    { lat: 48.5695556, lng: 17.368483 },
    { lat: 48.569118, lng: 17.3689781 },
    { lat: 48.5689262, lng: 17.3692796 },
    { lat: 48.5684712, lng: 17.3696285 },
    { lat: 48.5682764, lng: 17.3696904 },
    { lat: 48.5678839, lng: 17.3700468 },
    { lat: 48.5677181, lng: 17.3700509 },
    { lat: 48.5676116, lng: 17.3702699 },
    { lat: 48.5667043, lng: 17.3706338 },
    { lat: 48.5662178, lng: 17.370742 },
    { lat: 48.5655645, lng: 17.3712221 },
    { lat: 48.5651909, lng: 17.3717301 },
    { lat: 48.5650876, lng: 17.3717572 },
    { lat: 48.5648866, lng: 17.3720011 },
    { lat: 48.5648563, lng: 17.3721621 },
    { lat: 48.5645625, lng: 17.3725436 },
    { lat: 48.5641351, lng: 17.372883 },
    { lat: 48.5635061, lng: 17.3737706 },
    { lat: 48.5634178, lng: 17.3740279 },
    { lat: 48.5634315, lng: 17.3742912 },
    { lat: 48.5631204, lng: 17.3746869 },
    { lat: 48.5628835, lng: 17.3751234 },
    { lat: 48.5627595, lng: 17.3754751 },
    { lat: 48.562648, lng: 17.3762981 },
    { lat: 48.5623646, lng: 17.3765254 },
    { lat: 48.5621687, lng: 17.3765528 },
    { lat: 48.5615627, lng: 17.377361 },
    { lat: 48.560917, lng: 17.3784465 },
    { lat: 48.561374, lng: 17.3798315 },
    { lat: 48.561069, lng: 17.3797609 },
    { lat: 48.5608678, lng: 17.3799681 },
    { lat: 48.5607061, lng: 17.3799317 },
    { lat: 48.5603053, lng: 17.3801601 },
    { lat: 48.5598951, lng: 17.3804876 },
    { lat: 48.5594791, lng: 17.3810032 },
    { lat: 48.5591261, lng: 17.3811336 },
    { lat: 48.55888, lng: 17.381357 },
    { lat: 48.558823, lng: 17.38183 },
    { lat: 48.559161, lng: 17.382176 },
    { lat: 48.559242, lng: 17.382541 },
    { lat: 48.559483, lng: 17.382566 },
    { lat: 48.559592, lng: 17.382593 },
    { lat: 48.559797, lng: 17.382479 },
    { lat: 48.560172, lng: 17.382388 },
    { lat: 48.560257, lng: 17.382668 },
    { lat: 48.560264, lng: 17.382817 },
    { lat: 48.560161, lng: 17.383258 },
    { lat: 48.560107, lng: 17.383445 },
    { lat: 48.560207, lng: 17.383778 },
    { lat: 48.560214, lng: 17.38383 },
    { lat: 48.560268, lng: 17.38397 },
    { lat: 48.560289, lng: 17.384132 },
    { lat: 48.560371, lng: 17.384705 },
    { lat: 48.560415, lng: 17.385554 },
    { lat: 48.56042, lng: 17.385635 },
    { lat: 48.560836, lng: 17.385583 },
    { lat: 48.560979, lng: 17.385812 },
    { lat: 48.56108, lng: 17.385991 },
    { lat: 48.561227, lng: 17.386302 },
    { lat: 48.561372, lng: 17.386615 },
    { lat: 48.561497, lng: 17.386881 },
    { lat: 48.561669, lng: 17.387221 },
    { lat: 48.561731, lng: 17.387373 },
    { lat: 48.561817, lng: 17.387545 },
    { lat: 48.561936, lng: 17.387825 },
    { lat: 48.562092, lng: 17.388202 },
    { lat: 48.562331, lng: 17.388596 },
    { lat: 48.562328, lng: 17.388691 },
    { lat: 48.562447, lng: 17.388903 },
    { lat: 48.562561, lng: 17.389149 },
    { lat: 48.562702, lng: 17.389459 },
    { lat: 48.562868, lng: 17.389822 },
    { lat: 48.562939, lng: 17.38997 },
    { lat: 48.562872, lng: 17.390158 },
    { lat: 48.562856, lng: 17.390264 },
    { lat: 48.56276, lng: 17.390531 },
    { lat: 48.562689, lng: 17.390684 },
    { lat: 48.562622, lng: 17.390904 },
    { lat: 48.562627, lng: 17.391036 },
    { lat: 48.562581, lng: 17.391165 },
    { lat: 48.562516, lng: 17.391153 },
    { lat: 48.562382, lng: 17.391082 },
    { lat: 48.562366, lng: 17.391073 },
    { lat: 48.562495, lng: 17.39206 },
    { lat: 48.562521, lng: 17.392243 },
    { lat: 48.562637, lng: 17.392466 },
    { lat: 48.562675, lng: 17.39268 },
    { lat: 48.562589, lng: 17.392987 },
    { lat: 48.562507, lng: 17.393289 },
    { lat: 48.562479, lng: 17.393514 },
    { lat: 48.562441, lng: 17.393763 },
    { lat: 48.562369, lng: 17.394185 },
    { lat: 48.562084, lng: 17.394878 },
    { lat: 48.561952, lng: 17.39531 },
    { lat: 48.561742, lng: 17.395859 },
    { lat: 48.561564, lng: 17.396266 },
    { lat: 48.561572, lng: 17.396279 },
    { lat: 48.561726, lng: 17.396548 },
    { lat: 48.561865, lng: 17.396849 },
    { lat: 48.562114, lng: 17.39755 },
    { lat: 48.561821, lng: 17.397764 },
    { lat: 48.561648, lng: 17.397963 },
    { lat: 48.561457, lng: 17.398086 },
    { lat: 48.561197, lng: 17.398294 },
    { lat: 48.561283, lng: 17.398583 },
    { lat: 48.561376, lng: 17.398774 },
    { lat: 48.561463, lng: 17.398866 },
    { lat: 48.561543, lng: 17.398951 },
    { lat: 48.561644, lng: 17.399071 },
    { lat: 48.561685, lng: 17.399137 },
    { lat: 48.561719, lng: 17.399477 },
    { lat: 48.561711, lng: 17.399477 },
    { lat: 48.561715, lng: 17.399511 },
    { lat: 48.561771, lng: 17.400082 },
    { lat: 48.561768, lng: 17.400092 },
    { lat: 48.56176, lng: 17.400511 },
    { lat: 48.561776, lng: 17.400903 },
    { lat: 48.561781, lng: 17.401039 },
    { lat: 48.561859, lng: 17.401587 },
    { lat: 48.561879, lng: 17.401804 },
    { lat: 48.561856, lng: 17.401992 },
    { lat: 48.56185, lng: 17.40208 },
    { lat: 48.561904, lng: 17.402318 },
    { lat: 48.561912, lng: 17.40272 },
    { lat: 48.561879, lng: 17.402816 },
    { lat: 48.561875, lng: 17.402842 },
    { lat: 48.561845, lng: 17.403032 },
    { lat: 48.561839, lng: 17.40319 },
    { lat: 48.561837, lng: 17.403348 },
    { lat: 48.561844, lng: 17.403457 },
    { lat: 48.561861, lng: 17.403556 },
    { lat: 48.561869, lng: 17.403607 },
    { lat: 48.561905, lng: 17.403669 },
    { lat: 48.561782, lng: 17.404048 },
    { lat: 48.561661, lng: 17.404274 },
    { lat: 48.561498, lng: 17.404539 },
    { lat: 48.561575, lng: 17.404513 },
    { lat: 48.561669, lng: 17.404573 },
    { lat: 48.561703, lng: 17.404698 },
    { lat: 48.561699, lng: 17.404827 },
    { lat: 48.561822, lng: 17.404946 },
    { lat: 48.562123, lng: 17.405003 },
    { lat: 48.561508, lng: 17.405935 },
    { lat: 48.561402, lng: 17.406123 },
    { lat: 48.561179, lng: 17.406515 },
    { lat: 48.561227, lng: 17.406606 },
    { lat: 48.560918, lng: 17.407456 },
    { lat: 48.560867, lng: 17.407536 },
    { lat: 48.560745, lng: 17.407613 },
    { lat: 48.560541, lng: 17.407686 },
    { lat: 48.560134, lng: 17.407988 },
    { lat: 48.560006, lng: 17.407853 },
    { lat: 48.559882, lng: 17.407827 },
    { lat: 48.559804, lng: 17.407882 },
    { lat: 48.559746, lng: 17.407949 },
    { lat: 48.559724, lng: 17.408003 },
    { lat: 48.559658, lng: 17.408118 },
    { lat: 48.559632, lng: 17.408262 },
    { lat: 48.559613, lng: 17.408397 },
    { lat: 48.559447, lng: 17.408635 },
    { lat: 48.559497, lng: 17.40898 },
    { lat: 48.55939, lng: 17.409341 },
    { lat: 48.559359, lng: 17.409541 },
    { lat: 48.559351, lng: 17.40995 },
    { lat: 48.559353, lng: 17.410214 },
    { lat: 48.559444, lng: 17.410505 },
    { lat: 48.559531, lng: 17.410646 },
    { lat: 48.55962, lng: 17.410827 },
    { lat: 48.559667, lng: 17.411018 },
    { lat: 48.559679, lng: 17.411202 },
    { lat: 48.55971, lng: 17.4113 },
    { lat: 48.559731, lng: 17.411469 },
    { lat: 48.559785, lng: 17.41162 },
    { lat: 48.559802, lng: 17.411836 },
    { lat: 48.5597, lng: 17.41204 },
    { lat: 48.559391, lng: 17.412509 },
    { lat: 48.559274, lng: 17.412645 },
    { lat: 48.559212, lng: 17.412896 },
    { lat: 48.559127, lng: 17.413082 },
    { lat: 48.559051, lng: 17.413213 },
    { lat: 48.558975, lng: 17.413366 },
    { lat: 48.558953, lng: 17.41367 },
    { lat: 48.558937, lng: 17.413864 },
    { lat: 48.558812, lng: 17.414085 },
    { lat: 48.558645, lng: 17.414395 },
    { lat: 48.558617, lng: 17.414659 },
    { lat: 48.558513, lng: 17.414852 },
    { lat: 48.558486, lng: 17.414996 },
    { lat: 48.558329, lng: 17.415395 },
    { lat: 48.558327, lng: 17.415514 },
    { lat: 48.558265, lng: 17.41568 },
    { lat: 48.55823, lng: 17.415891 },
    { lat: 48.558185, lng: 17.415958 },
    { lat: 48.558175, lng: 17.416032 },
    { lat: 48.558162, lng: 17.416096 },
    { lat: 48.55811, lng: 17.416205 },
    { lat: 48.558111, lng: 17.416269 },
    { lat: 48.558055, lng: 17.416486 },
    { lat: 48.558034, lng: 17.416545 },
    { lat: 48.558012, lng: 17.416659 },
    { lat: 48.55797, lng: 17.416807 },
    { lat: 48.55803, lng: 17.416981 },
    { lat: 48.558032, lng: 17.417351 },
    { lat: 48.558018, lng: 17.417408 },
    { lat: 48.557943, lng: 17.417646 },
    { lat: 48.557953, lng: 17.417679 },
    { lat: 48.558035, lng: 17.417721 },
    { lat: 48.558073, lng: 17.417757 },
    { lat: 48.558074, lng: 17.417819 },
    { lat: 48.558075, lng: 17.417872 },
    { lat: 48.558075, lng: 17.418037 },
    { lat: 48.558118, lng: 17.418205 },
    { lat: 48.558094, lng: 17.41842 },
    { lat: 48.558103, lng: 17.41855 },
    { lat: 48.558078, lng: 17.418737 },
    { lat: 48.558102, lng: 17.418924 },
    { lat: 48.558163, lng: 17.419072 },
    { lat: 48.558159, lng: 17.419318 },
    { lat: 48.558175, lng: 17.419609 },
    { lat: 48.558192, lng: 17.419776 },
    { lat: 48.558164, lng: 17.420097 },
    { lat: 48.558198, lng: 17.420183 },
    { lat: 48.558206, lng: 17.420419 },
    { lat: 48.558225, lng: 17.420627 },
    { lat: 48.558228, lng: 17.420707 },
    { lat: 48.558288, lng: 17.421016 },
    { lat: 48.558264, lng: 17.421218 },
    { lat: 48.55824, lng: 17.421388 },
    { lat: 48.558224, lng: 17.421576 },
    { lat: 48.558342, lng: 17.421757 },
    { lat: 48.558433, lng: 17.4219 },
    { lat: 48.558423, lng: 17.422359 },
    { lat: 48.558413, lng: 17.42287 },
    { lat: 48.558304, lng: 17.423187 },
    { lat: 48.558268, lng: 17.423379 },
    { lat: 48.558223, lng: 17.423652 },
    { lat: 48.558206, lng: 17.423853 },
    { lat: 48.558207, lng: 17.424059 },
    { lat: 48.55811, lng: 17.424289 },
    { lat: 48.558066, lng: 17.424557 },
    { lat: 48.558033, lng: 17.424744 },
    { lat: 48.557998, lng: 17.42486 },
    { lat: 48.557902, lng: 17.42492 },
    { lat: 48.557808, lng: 17.425115 },
    { lat: 48.557752, lng: 17.425239 },
    { lat: 48.557706, lng: 17.425495 },
    { lat: 48.557615, lng: 17.425721 },
    { lat: 48.557475, lng: 17.425952 },
    { lat: 48.557354, lng: 17.426166 },
    { lat: 48.557292, lng: 17.426268 },
    { lat: 48.557217, lng: 17.426488 },
    { lat: 48.557178, lng: 17.426571 },
    { lat: 48.557147, lng: 17.426607 },
    { lat: 48.557039, lng: 17.426678 },
    { lat: 48.556883, lng: 17.426606 },
    { lat: 48.556585, lng: 17.42645 },
    { lat: 48.556557, lng: 17.426279 },
    { lat: 48.556408, lng: 17.426117 },
    { lat: 48.556338, lng: 17.425992 },
    { lat: 48.556129, lng: 17.425933 },
    { lat: 48.556099, lng: 17.425908 },
    { lat: 48.556071, lng: 17.425844 },
    { lat: 48.556055, lng: 17.425748 },
    { lat: 48.556027, lng: 17.425713 },
    { lat: 48.55593, lng: 17.425673 },
    { lat: 48.555819, lng: 17.425596 },
    { lat: 48.555715, lng: 17.425608 },
    { lat: 48.555668, lng: 17.425543 },
    { lat: 48.555481, lng: 17.425591 },
    { lat: 48.555297, lng: 17.425742 },
    { lat: 48.555155, lng: 17.425758 },
    { lat: 48.555109, lng: 17.425795 },
    { lat: 48.55508, lng: 17.425751 },
    { lat: 48.555022, lng: 17.425789 },
    { lat: 48.555009, lng: 17.42582 },
    { lat: 48.554965, lng: 17.425827 },
    { lat: 48.554909, lng: 17.425951 },
    { lat: 48.554854, lng: 17.425969 },
    { lat: 48.554796, lng: 17.426046 },
    { lat: 48.554752, lng: 17.426013 },
    { lat: 48.554736, lng: 17.426018 },
    { lat: 48.554702, lng: 17.426072 },
    { lat: 48.554686, lng: 17.426062 },
    { lat: 48.55468, lng: 17.426015 },
    { lat: 48.554652, lng: 17.426011 },
    { lat: 48.554545, lng: 17.426034 },
    { lat: 48.554431, lng: 17.426184 },
    { lat: 48.554386, lng: 17.426164 },
    { lat: 48.554343, lng: 17.426207 },
    { lat: 48.554328, lng: 17.426299 },
    { lat: 48.554305, lng: 17.426328 },
    { lat: 48.554315, lng: 17.426352 },
    { lat: 48.554532, lng: 17.426248 },
    { lat: 48.555164, lng: 17.426146 },
    { lat: 48.555256, lng: 17.426193 },
    { lat: 48.555471, lng: 17.426114 },
    { lat: 48.555505, lng: 17.426072 },
    { lat: 48.555841, lng: 17.426027 },
    { lat: 48.555901, lng: 17.426019 },
    { lat: 48.555939, lng: 17.426041 },
    { lat: 48.556145, lng: 17.426042 },
    { lat: 48.55624, lng: 17.426188 },
    { lat: 48.556251, lng: 17.426173 },
    { lat: 48.556402, lng: 17.426419 },
    { lat: 48.556582, lng: 17.427023 },
    { lat: 48.556731, lng: 17.427202 },
    { lat: 48.556788, lng: 17.427245 },
    { lat: 48.556861, lng: 17.427301 },
    { lat: 48.55695, lng: 17.42737 },
    { lat: 48.557226, lng: 17.427595 },
    { lat: 48.557358, lng: 17.427691 },
    { lat: 48.557395, lng: 17.427719 },
    { lat: 48.557433, lng: 17.427749 },
    { lat: 48.557598, lng: 17.427877 },
    { lat: 48.557675, lng: 17.427959 },
    { lat: 48.557818, lng: 17.428112 },
    { lat: 48.55798, lng: 17.428175 },
    { lat: 48.558147, lng: 17.428258 },
    { lat: 48.558364, lng: 17.428336 },
    { lat: 48.558516, lng: 17.428392 },
    { lat: 48.55872, lng: 17.428552 },
    { lat: 48.558918, lng: 17.428789 },
    { lat: 48.559088, lng: 17.429067 },
    { lat: 48.559206, lng: 17.429114 },
    { lat: 48.559199, lng: 17.429141 },
    { lat: 48.559181, lng: 17.429237 },
    { lat: 48.559215, lng: 17.429313 },
    { lat: 48.559293, lng: 17.429494 },
    { lat: 48.559338, lng: 17.429599 },
    { lat: 48.559358, lng: 17.4296365 },
    { lat: 48.5594144, lng: 17.4297473 },
    { lat: 48.55948, lng: 17.430047 },
    { lat: 48.559486, lng: 17.430076 },
    { lat: 48.559512, lng: 17.430181 },
    { lat: 48.559554, lng: 17.430402 },
    { lat: 48.559579, lng: 17.430538 },
    { lat: 48.559581, lng: 17.430552 },
    { lat: 48.55957, lng: 17.430628 },
    { lat: 48.559565, lng: 17.430668 },
    { lat: 48.559561, lng: 17.430692 },
    { lat: 48.559575, lng: 17.430772 },
    { lat: 48.55958, lng: 17.430809 },
    { lat: 48.559583, lng: 17.430819 },
    { lat: 48.559583, lng: 17.43082 },
    { lat: 48.559583, lng: 17.430826 },
    { lat: 48.559586, lng: 17.430843 },
    { lat: 48.559614, lng: 17.430993 },
    { lat: 48.559626, lng: 17.431058 },
    { lat: 48.559632, lng: 17.43107 },
    { lat: 48.559745, lng: 17.431331 },
    { lat: 48.559767, lng: 17.431383 },
    { lat: 48.559818, lng: 17.43159 },
    { lat: 48.559829, lng: 17.431646 },
    { lat: 48.55982, lng: 17.431781 },
    { lat: 48.559822, lng: 17.431824 },
    { lat: 48.559819, lng: 17.431996 },
    { lat: 48.559832, lng: 17.432088 },
    { lat: 48.559873, lng: 17.432373 },
    { lat: 48.559933, lng: 17.43271 },
    { lat: 48.559971, lng: 17.433077 },
    { lat: 48.560116, lng: 17.433276 },
    { lat: 48.560194, lng: 17.433348 },
    { lat: 48.560266, lng: 17.433415 },
    { lat: 48.560287, lng: 17.433451 },
    { lat: 48.560584, lng: 17.434046 },
    { lat: 48.560765, lng: 17.434371 },
    { lat: 48.560838, lng: 17.434487 },
    { lat: 48.561071, lng: 17.434859 },
    { lat: 48.561262, lng: 17.435255 },
    { lat: 48.561355, lng: 17.435497 },
    { lat: 48.561501, lng: 17.435891 },
    { lat: 48.561567, lng: 17.436167 },
    { lat: 48.561698, lng: 17.436402 },
    { lat: 48.56178, lng: 17.43657 },
    { lat: 48.561984, lng: 17.436665 },
    { lat: 48.562107, lng: 17.436673 },
    { lat: 48.562163, lng: 17.436702 },
    { lat: 48.562202, lng: 17.436627 },
    { lat: 48.562268, lng: 17.436607 },
    { lat: 48.562283, lng: 17.43661 },
    { lat: 48.562349, lng: 17.436636 },
    { lat: 48.562506, lng: 17.436559 },
    { lat: 48.562585, lng: 17.436711 },
    { lat: 48.562824, lng: 17.43696 },
    { lat: 48.562918, lng: 17.437182 },
    { lat: 48.562989, lng: 17.437286 },
    { lat: 48.563007, lng: 17.437474 },
    { lat: 48.563037, lng: 17.437662 },
    { lat: 48.563151, lng: 17.437763 },
    { lat: 48.56323, lng: 17.437865 },
    { lat: 48.563309, lng: 17.437944 },
    { lat: 48.563396, lng: 17.438084 },
    { lat: 48.563406, lng: 17.438198 },
    { lat: 48.563532, lng: 17.438274 },
    { lat: 48.563648, lng: 17.438579 },
    { lat: 48.563786, lng: 17.438904 },
    { lat: 48.563886, lng: 17.439207 },
    { lat: 48.563992, lng: 17.439409 },
    { lat: 48.564018, lng: 17.439668 },
    { lat: 48.564058, lng: 17.439796 },
    { lat: 48.564067, lng: 17.439956 },
    { lat: 48.564128, lng: 17.440099 },
    { lat: 48.564196, lng: 17.440188 },
    { lat: 48.564276, lng: 17.440486 },
    { lat: 48.564293, lng: 17.440667 },
    { lat: 48.564415, lng: 17.44094 },
    { lat: 48.564539, lng: 17.441275 },
    { lat: 48.56461, lng: 17.441317 },
    { lat: 48.564686, lng: 17.441491 },
    { lat: 48.564695, lng: 17.441519 },
    { lat: 48.564707, lng: 17.441562 },
    { lat: 48.564752, lng: 17.441717 },
    { lat: 48.564755, lng: 17.441983 },
    { lat: 48.564797, lng: 17.442242 },
    { lat: 48.564793, lng: 17.442358 },
    { lat: 48.564772, lng: 17.442438 },
    { lat: 48.564752, lng: 17.44281 },
    { lat: 48.564729, lng: 17.442884 },
    { lat: 48.564712, lng: 17.443024 },
    { lat: 48.564705, lng: 17.443126 },
    { lat: 48.564625, lng: 17.443248 },
    { lat: 48.564581, lng: 17.443289 },
    { lat: 48.564674, lng: 17.44343 },
    { lat: 48.564677, lng: 17.443642 },
    { lat: 48.564791, lng: 17.443883 },
    { lat: 48.564759, lng: 17.444143 },
    { lat: 48.564819, lng: 17.444375 },
    { lat: 48.56497, lng: 17.444518 },
    { lat: 48.564996, lng: 17.444607 },
    { lat: 48.565056, lng: 17.444659 },
    { lat: 48.565106, lng: 17.444786 },
    { lat: 48.565252, lng: 17.444944 },
    { lat: 48.565334, lng: 17.445083 },
    { lat: 48.565413, lng: 17.445152 },
    { lat: 48.565481, lng: 17.445207 },
    { lat: 48.565516, lng: 17.445235 },
    { lat: 48.565607, lng: 17.445301 },
    { lat: 48.565735, lng: 17.445345 },
    { lat: 48.565788, lng: 17.445401 },
    { lat: 48.566005, lng: 17.445551 },
    { lat: 48.566068, lng: 17.445619 },
    { lat: 48.566147, lng: 17.445677 },
    { lat: 48.566172, lng: 17.445717 },
    { lat: 48.566255, lng: 17.446016 },
    { lat: 48.566275, lng: 17.446188 },
    { lat: 48.566361, lng: 17.446348 },
    { lat: 48.566391, lng: 17.446525 },
    { lat: 48.566471, lng: 17.446809 },
    { lat: 48.566263, lng: 17.447171 },
    { lat: 48.566242, lng: 17.447218 },
    { lat: 48.566202, lng: 17.447349 },
    { lat: 48.566159, lng: 17.447527 },
    { lat: 48.565903, lng: 17.448389 },
    { lat: 48.565873, lng: 17.44857 },
    { lat: 48.565919, lng: 17.448753 },
    { lat: 48.565924, lng: 17.448794 },
    { lat: 48.565914, lng: 17.448815 },
    { lat: 48.565892, lng: 17.448871 },
  ],
  BorskýSvätýJur: [
    { lat: 48.563816, lng: 17.05231 },
    { lat: 48.56378, lng: 17.05276 },
    { lat: 48.563741, lng: 17.053231 },
    { lat: 48.563724, lng: 17.053459 },
    { lat: 48.56371, lng: 17.053633 },
    { lat: 48.563702, lng: 17.053734 },
    { lat: 48.563662, lng: 17.054229 },
    { lat: 48.563644, lng: 17.054461 },
    { lat: 48.563639, lng: 17.054512 },
    { lat: 48.563624, lng: 17.054714 },
    { lat: 48.563616, lng: 17.054808 },
    { lat: 48.563581, lng: 17.05525 },
    { lat: 48.563577, lng: 17.055309 },
    { lat: 48.563535, lng: 17.055809 },
    { lat: 48.563529, lng: 17.055881 },
    { lat: 48.563497, lng: 17.056282 },
    { lat: 48.563467, lng: 17.05667 },
    { lat: 48.563461, lng: 17.056746 },
    { lat: 48.563448, lng: 17.056919 },
    { lat: 48.563415, lng: 17.057321 },
    { lat: 48.563396, lng: 17.057562 },
    { lat: 48.563394, lng: 17.057587 },
    { lat: 48.563384, lng: 17.057716 },
    { lat: 48.563377, lng: 17.057812 },
    { lat: 48.563351, lng: 17.058121 },
    { lat: 48.563321, lng: 17.058517 },
    { lat: 48.563291, lng: 17.058908 },
    { lat: 48.563262, lng: 17.059283 },
    { lat: 48.563235, lng: 17.059619 },
    { lat: 48.563207, lng: 17.059962 },
    { lat: 48.563183, lng: 17.060268 },
    { lat: 48.56316, lng: 17.060578 },
    { lat: 48.563152, lng: 17.060676 },
    { lat: 48.563137, lng: 17.060859 },
    { lat: 48.563114, lng: 17.061143 },
    { lat: 48.563091, lng: 17.061453 },
    { lat: 48.563086, lng: 17.061486 },
    { lat: 48.563061, lng: 17.061609 },
    { lat: 48.563055, lng: 17.061637 },
    { lat: 48.563013, lng: 17.061855 },
    { lat: 48.56285, lng: 17.062713 },
    { lat: 48.562719, lng: 17.063402 },
    { lat: 48.562601, lng: 17.064021 },
    { lat: 48.56245, lng: 17.064825 },
    { lat: 48.562314, lng: 17.065526 },
    { lat: 48.562206, lng: 17.066088 },
    { lat: 48.562069, lng: 17.066808 },
    { lat: 48.561967, lng: 17.067339 },
    { lat: 48.561864, lng: 17.067876 },
    { lat: 48.561832, lng: 17.068053 },
    { lat: 48.561826, lng: 17.06808 },
    { lat: 48.5618, lng: 17.068213 },
    { lat: 48.561763, lng: 17.068405 },
    { lat: 48.561678, lng: 17.068852 },
    { lat: 48.561642, lng: 17.06902 },
    { lat: 48.561617, lng: 17.069142 },
    { lat: 48.561581, lng: 17.069333 },
    { lat: 48.561492, lng: 17.069796 },
    { lat: 48.56145, lng: 17.070059 },
    { lat: 48.561362, lng: 17.070532 },
    { lat: 48.561231, lng: 17.071247 },
    { lat: 48.561485, lng: 17.071284 },
    { lat: 48.561537, lng: 17.071297 },
    { lat: 48.561572, lng: 17.071306 },
    { lat: 48.561576, lng: 17.071307 },
    { lat: 48.561562, lng: 17.071392 },
    { lat: 48.561507, lng: 17.071694 },
    { lat: 48.561328, lng: 17.072724 },
    { lat: 48.561231, lng: 17.073236 },
    { lat: 48.560981, lng: 17.073964 },
    { lat: 48.5608, lng: 17.074492 },
    { lat: 48.560677, lng: 17.075389 },
    { lat: 48.56059, lng: 17.076116 },
    { lat: 48.560503, lng: 17.076817 },
    { lat: 48.560309, lng: 17.077681 },
    { lat: 48.560108, lng: 17.078587 },
    { lat: 48.559935, lng: 17.07948 },
    { lat: 48.559913, lng: 17.079806 },
    { lat: 48.559924, lng: 17.079969 },
    { lat: 48.559962, lng: 17.080424 },
    { lat: 48.56012, lng: 17.080652 },
    { lat: 48.560301, lng: 17.081023 },
    { lat: 48.560278, lng: 17.081444 },
    { lat: 48.560138, lng: 17.0824 },
    { lat: 48.559881, lng: 17.083093 },
    { lat: 48.5599, lng: 17.083757 },
    { lat: 48.559911, lng: 17.084097 },
    { lat: 48.559893, lng: 17.084246 },
    { lat: 48.559868, lng: 17.084431 },
    { lat: 48.559948, lng: 17.085149 },
    { lat: 48.559765, lng: 17.085842 },
    { lat: 48.559666, lng: 17.086213 },
    { lat: 48.559415, lng: 17.087157 },
    { lat: 48.559203, lng: 17.087947 },
    { lat: 48.559191, lng: 17.087998 },
    { lat: 48.558998, lng: 17.088722 },
    { lat: 48.558786, lng: 17.08953 },
    { lat: 48.55869, lng: 17.089888 },
    { lat: 48.558447, lng: 17.090807 },
    { lat: 48.558502, lng: 17.091143 },
    { lat: 48.558648, lng: 17.092068 },
    { lat: 48.558744, lng: 17.092702 },
    { lat: 48.558753, lng: 17.092752 },
    { lat: 48.558854, lng: 17.093415 },
    { lat: 48.558934, lng: 17.093932 },
    { lat: 48.559065, lng: 17.094772 },
    { lat: 48.559125, lng: 17.09516 },
    { lat: 48.559357, lng: 17.095505 },
    { lat: 48.559648, lng: 17.095926 },
    { lat: 48.559695, lng: 17.095906 },
    { lat: 48.559833, lng: 17.096025 },
    { lat: 48.559922, lng: 17.096176 },
    { lat: 48.559964, lng: 17.096249 },
    { lat: 48.559987, lng: 17.096308 },
    { lat: 48.560028, lng: 17.096412 },
    { lat: 48.560066, lng: 17.096527 },
    { lat: 48.56025, lng: 17.097187 },
    { lat: 48.560179, lng: 17.097244 },
    { lat: 48.560395, lng: 17.097957 },
    { lat: 48.560597, lng: 17.098811 },
    { lat: 48.560635, lng: 17.098984 },
    { lat: 48.560726, lng: 17.099271 },
    { lat: 48.561148, lng: 17.099177 },
    { lat: 48.56128, lng: 17.099149 },
    { lat: 48.561226, lng: 17.098808 },
    { lat: 48.561025, lng: 17.09876 },
    { lat: 48.561177, lng: 17.098139 },
    { lat: 48.561185, lng: 17.097868 },
    { lat: 48.561186, lng: 17.09776 },
    { lat: 48.561193, lng: 17.097675 },
    { lat: 48.561372, lng: 17.097653 },
    { lat: 48.561759, lng: 17.097594 },
    { lat: 48.561765, lng: 17.097477 },
    { lat: 48.562053, lng: 17.097408 },
    { lat: 48.562235, lng: 17.097364 },
    { lat: 48.562207, lng: 17.097551 },
    { lat: 48.562222, lng: 17.097892 },
    { lat: 48.562144, lng: 17.098306 },
    { lat: 48.561903, lng: 17.098682 },
    { lat: 48.561885, lng: 17.098941 },
    { lat: 48.561896, lng: 17.099185 },
    { lat: 48.561905, lng: 17.099448 },
    { lat: 48.561945, lng: 17.099625 },
    { lat: 48.561969, lng: 17.099738 },
    { lat: 48.561989, lng: 17.099838 },
    { lat: 48.562011, lng: 17.099945 },
    { lat: 48.562015, lng: 17.100022 },
    { lat: 48.562029, lng: 17.100067 },
    { lat: 48.562108, lng: 17.100053 },
    { lat: 48.562305, lng: 17.100025 },
    { lat: 48.56242, lng: 17.100007 },
    { lat: 48.562415, lng: 17.100176 },
    { lat: 48.562433, lng: 17.100408 },
    { lat: 48.562391, lng: 17.100643 },
    { lat: 48.56238, lng: 17.100749 },
    { lat: 48.562371, lng: 17.100842 },
    { lat: 48.56237, lng: 17.1009 },
    { lat: 48.562365, lng: 17.101078 },
    { lat: 48.562371, lng: 17.101275 },
    { lat: 48.562341, lng: 17.101505 },
    { lat: 48.562296, lng: 17.101731 },
    { lat: 48.562279, lng: 17.102047 },
    { lat: 48.562528, lng: 17.102016 },
    { lat: 48.562532, lng: 17.101698 },
    { lat: 48.56278, lng: 17.101651 },
    { lat: 48.562785, lng: 17.101917 },
    { lat: 48.562789, lng: 17.102657 },
    { lat: 48.563179, lng: 17.10277 },
    { lat: 48.563197, lng: 17.1033 },
    { lat: 48.563266, lng: 17.103586 },
    { lat: 48.563352, lng: 17.10377 },
    { lat: 48.563387, lng: 17.103791 },
    { lat: 48.56326, lng: 17.104342 },
    { lat: 48.563018, lng: 17.105376 },
    { lat: 48.562832, lng: 17.106184 },
    { lat: 48.562654, lng: 17.106429 },
    { lat: 48.5626684, lng: 17.1064732 },
    { lat: 48.5636957, lng: 17.1081044 },
    { lat: 48.5643798, lng: 17.1088332 },
    { lat: 48.5652803, lng: 17.1090038 },
    { lat: 48.5661069, lng: 17.1084092 },
    { lat: 48.5670668, lng: 17.1071073 },
    { lat: 48.5678411, lng: 17.1099829 },
    { lat: 48.5686858, lng: 17.1093551 },
    { lat: 48.5689553, lng: 17.1101498 },
    { lat: 48.569204, lng: 17.1105067 },
    { lat: 48.5696442, lng: 17.1109504 },
    { lat: 48.5700627, lng: 17.1109246 },
    { lat: 48.5701018, lng: 17.1108505 },
    { lat: 48.5704593, lng: 17.1109593 },
    { lat: 48.570485, lng: 17.1109061 },
    { lat: 48.5705593, lng: 17.1110354 },
    { lat: 48.5707312, lng: 17.1111554 },
    { lat: 48.5707692, lng: 17.1110968 },
    { lat: 48.5711297, lng: 17.1111302 },
    { lat: 48.5712013, lng: 17.1110044 },
    { lat: 48.5713506, lng: 17.1111585 },
    { lat: 48.5714716, lng: 17.1111582 },
    { lat: 48.5715417, lng: 17.111225 },
    { lat: 48.571668, lng: 17.1111571 },
    { lat: 48.5716668, lng: 17.1109868 },
    { lat: 48.5717735, lng: 17.110877 },
    { lat: 48.5720754, lng: 17.1109789 },
    { lat: 48.5722686, lng: 17.1111478 },
    { lat: 48.5723913, lng: 17.1113428 },
    { lat: 48.5724159, lng: 17.111592 },
    { lat: 48.5724651, lng: 17.111633 },
    { lat: 48.5724522, lng: 17.1117762 },
    { lat: 48.5725081, lng: 17.1118858 },
    { lat: 48.5726531, lng: 17.1119575 },
    { lat: 48.5727577, lng: 17.1121356 },
    { lat: 48.5728416, lng: 17.1121413 },
    { lat: 48.5729875, lng: 17.1122928 },
    { lat: 48.5731504, lng: 17.1122783 },
    { lat: 48.5732311, lng: 17.1124047 },
    { lat: 48.5732196, lng: 17.112501 },
    { lat: 48.5733325, lng: 17.1126208 },
    { lat: 48.5733015, lng: 17.1128146 },
    { lat: 48.5733599, lng: 17.1127918 },
    { lat: 48.5734478, lng: 17.1129232 },
    { lat: 48.5735626, lng: 17.1128979 },
    { lat: 48.5735963, lng: 17.1130122 },
    { lat: 48.5737107, lng: 17.1130015 },
    { lat: 48.5735964, lng: 17.1131646 },
    { lat: 48.573623, lng: 17.1134438 },
    { lat: 48.5735385, lng: 17.1134799 },
    { lat: 48.5735516, lng: 17.113642 },
    { lat: 48.5738193, lng: 17.1137749 },
    { lat: 48.5737879, lng: 17.1138468 },
    { lat: 48.5738131, lng: 17.1139011 },
    { lat: 48.5737235, lng: 17.1139622 },
    { lat: 48.5737029, lng: 17.1141605 },
    { lat: 48.5737785, lng: 17.1142425 },
    { lat: 48.5736685, lng: 17.114575 },
    { lat: 48.5737999, lng: 17.1147969 },
    { lat: 48.5738037, lng: 17.1149872 },
    { lat: 48.5739162, lng: 17.1151495 },
    { lat: 48.5739471, lng: 17.1153727 },
    { lat: 48.5740201, lng: 17.1153848 },
    { lat: 48.5741194, lng: 17.115836 },
    { lat: 48.5742108, lng: 17.1159344 },
    { lat: 48.5741583, lng: 17.1161265 },
    { lat: 48.5742214, lng: 17.1163117 },
    { lat: 48.5743716, lng: 17.1162249 },
    { lat: 48.5745082, lng: 17.1162561 },
    { lat: 48.5744791, lng: 17.1164775 },
    { lat: 48.5747074, lng: 17.1167681 },
    { lat: 48.5746917, lng: 17.1172207 },
    { lat: 48.5747411, lng: 17.1173188 },
    { lat: 48.5747315, lng: 17.1179144 },
    { lat: 48.574794, lng: 17.1179712 },
    { lat: 48.5747807, lng: 17.1181923 },
    { lat: 48.5749092, lng: 17.1182194 },
    { lat: 48.5748998, lng: 17.1182847 },
    { lat: 48.5749649, lng: 17.1183436 },
    { lat: 48.5749602, lng: 17.118542 },
    { lat: 48.5751322, lng: 17.1186492 },
    { lat: 48.5751797, lng: 17.1189017 },
    { lat: 48.5751009, lng: 17.1190882 },
    { lat: 48.5751094, lng: 17.1192503 },
    { lat: 48.5752726, lng: 17.1196104 },
    { lat: 48.5753375, lng: 17.1196355 },
    { lat: 48.5753223, lng: 17.1196956 },
    { lat: 48.5753861, lng: 17.1198204 },
    { lat: 48.5754498, lng: 17.1198446 },
    { lat: 48.5754586, lng: 17.1200741 },
    { lat: 48.5755533, lng: 17.120258 },
    { lat: 48.575488, lng: 17.1205302 },
    { lat: 48.5755237, lng: 17.1207749 },
    { lat: 48.5753783, lng: 17.1209658 },
    { lat: 48.5752963, lng: 17.1209391 },
    { lat: 48.5751657, lng: 17.1210468 },
    { lat: 48.5751151, lng: 17.1211669 },
    { lat: 48.5751147, lng: 17.1212954 },
    { lat: 48.5751597, lng: 17.1213278 },
    { lat: 48.5751055, lng: 17.1213632 },
    { lat: 48.5751165, lng: 17.1216919 },
    { lat: 48.5754649, lng: 17.1220848 },
    { lat: 48.5755772, lng: 17.1222949 },
    { lat: 48.5757037, lng: 17.122211 },
    { lat: 48.5757749, lng: 17.1223611 },
    { lat: 48.5758319, lng: 17.1223353 },
    { lat: 48.5760494, lng: 17.1227634 },
    { lat: 48.5761152, lng: 17.1230039 },
    { lat: 48.5761042, lng: 17.1235129 },
    { lat: 48.5760719, lng: 17.1235535 },
    { lat: 48.5761064, lng: 17.1236446 },
    { lat: 48.5760661, lng: 17.1236508 },
    { lat: 48.576092, lng: 17.1237328 },
    { lat: 48.5760605, lng: 17.1239053 },
    { lat: 48.5762092, lng: 17.1244871 },
    { lat: 48.5763638, lng: 17.1254076 },
    { lat: 48.5764597, lng: 17.125627 },
    { lat: 48.5770705, lng: 17.1253815 },
    { lat: 48.5779487, lng: 17.1251531 },
    { lat: 48.5787663, lng: 17.1248575 },
    { lat: 48.5801041, lng: 17.1245375 },
    { lat: 48.5889442, lng: 17.1219295 },
    { lat: 48.5909445, lng: 17.1213765 },
    { lat: 48.5909856, lng: 17.1214341 },
    { lat: 48.5910337, lng: 17.1214429 },
    { lat: 48.5917232, lng: 17.1200859 },
    { lat: 48.5930648, lng: 17.1172816 },
    { lat: 48.5973315, lng: 17.108714 },
    { lat: 48.5991806, lng: 17.1060014 },
    { lat: 48.5992171, lng: 17.1060418 },
    { lat: 48.5997299, lng: 17.1053994 },
    { lat: 48.6008539, lng: 17.1038348 },
    { lat: 48.6008525, lng: 17.1037958 },
    { lat: 48.6008063, lng: 17.1037636 },
    { lat: 48.6014677, lng: 17.1012858 },
    { lat: 48.6014133, lng: 17.1002592 },
    { lat: 48.602199, lng: 17.0970612 },
    { lat: 48.6032906, lng: 17.0940755 },
    { lat: 48.6042318, lng: 17.0918306 },
    { lat: 48.6050178, lng: 17.0909025 },
    { lat: 48.6054315, lng: 17.0903161 },
    { lat: 48.6063049, lng: 17.0893771 },
    { lat: 48.6074611, lng: 17.087506 },
    { lat: 48.6103691, lng: 17.0845341 },
    { lat: 48.6117334, lng: 17.0833157 },
    { lat: 48.6138413, lng: 17.0812321 },
    { lat: 48.6143505, lng: 17.0793153 },
    { lat: 48.6148667, lng: 17.0782433 },
    { lat: 48.6154662, lng: 17.0771977 },
    { lat: 48.616148, lng: 17.0758482 },
    { lat: 48.6168366, lng: 17.0747692 },
    { lat: 48.6178975, lng: 17.0737438 },
    { lat: 48.6183755, lng: 17.0734446 },
    { lat: 48.6187817, lng: 17.0730508 },
    { lat: 48.6198061, lng: 17.0717223 },
    { lat: 48.6202406, lng: 17.0706434 },
    { lat: 48.6204734, lng: 17.070259 },
    { lat: 48.621473, lng: 17.0680821 },
    { lat: 48.6211647, lng: 17.0672305 },
    { lat: 48.6216022, lng: 17.0665632 },
    { lat: 48.6218734, lng: 17.0662454 },
    { lat: 48.6219609, lng: 17.0664238 },
    { lat: 48.6227671, lng: 17.0654101 },
    { lat: 48.6229355, lng: 17.0658102 },
    { lat: 48.6231511, lng: 17.0655428 },
    { lat: 48.6234729, lng: 17.0664076 },
    { lat: 48.6243999, lng: 17.0650581 },
    { lat: 48.6258163, lng: 17.0635619 },
    { lat: 48.6256463, lng: 17.0625193 },
    { lat: 48.6258098, lng: 17.0623459 },
    { lat: 48.6258055, lng: 17.0621991 },
    { lat: 48.6256144, lng: 17.0610115 },
    { lat: 48.6253243, lng: 17.0596292 },
    { lat: 48.625175, lng: 17.0591086 },
    { lat: 48.6260993, lng: 17.0584773 },
    { lat: 48.6258729, lng: 17.0563319 },
    { lat: 48.6257888, lng: 17.0557926 },
    { lat: 48.625616, lng: 17.0552406 },
    { lat: 48.6267396, lng: 17.0537266 },
    { lat: 48.6267552, lng: 17.0534405 },
    { lat: 48.6263757, lng: 17.0525596 },
    { lat: 48.627137, lng: 17.0492182 },
    { lat: 48.62691, lng: 17.0487367 },
    { lat: 48.6273259, lng: 17.0481542 },
    { lat: 48.6272232, lng: 17.0478547 },
    { lat: 48.6273486, lng: 17.0477214 },
    { lat: 48.6274805, lng: 17.0474349 },
    { lat: 48.6277093, lng: 17.0466734 },
    { lat: 48.6277926, lng: 17.0457683 },
    { lat: 48.6283034, lng: 17.0435009 },
    { lat: 48.6298371, lng: 17.0382088 },
    { lat: 48.6308046, lng: 17.0337486 },
    { lat: 48.630808, lng: 17.0329675 },
    { lat: 48.6314875, lng: 17.0314911 },
    { lat: 48.632597, lng: 17.030757 },
    { lat: 48.6340786, lng: 17.029573 },
    { lat: 48.6340729, lng: 17.0296754 },
    { lat: 48.6345184, lng: 17.0293303 },
    { lat: 48.6353929, lng: 17.0283956 },
    { lat: 48.6353662, lng: 17.0288677 },
    { lat: 48.6371862, lng: 17.02808 },
    { lat: 48.6374692, lng: 17.027187 },
    { lat: 48.6378744, lng: 17.0265148 },
    { lat: 48.6381534, lng: 17.0259297 },
    { lat: 48.6383875, lng: 17.0252668 },
    { lat: 48.6384655, lng: 17.0249024 },
    { lat: 48.638482, lng: 17.0246119 },
    { lat: 48.638183, lng: 17.0219266 },
    { lat: 48.6381758, lng: 17.021539 },
    { lat: 48.6382805, lng: 17.0204349 },
    { lat: 48.6384093, lng: 17.0180253 },
    { lat: 48.6383853, lng: 17.0145497 },
    { lat: 48.6382555, lng: 17.0119108 },
    { lat: 48.638332, lng: 17.0083873 },
    { lat: 48.6382166, lng: 17.007423 },
    { lat: 48.6380832, lng: 17.0069256 },
    { lat: 48.6378003, lng: 17.0063042 },
    { lat: 48.6375697, lng: 17.0061209 },
    { lat: 48.6372031, lng: 17.0055847 },
    { lat: 48.636279, lng: 17.0046765 },
    { lat: 48.6357309, lng: 17.0039962 },
    { lat: 48.6353677, lng: 17.0032572 },
    { lat: 48.6347292, lng: 17.0013697 },
    { lat: 48.6343569, lng: 16.9986593 },
    { lat: 48.6343581, lng: 16.99845 },
    { lat: 48.6343662, lng: 16.9970642 },
    { lat: 48.6343718, lng: 16.9961107 },
    { lat: 48.6346658, lng: 16.9951447 },
    { lat: 48.6347188, lng: 16.9949706 },
    { lat: 48.6348882, lng: 16.9941379 },
    { lat: 48.6349813, lng: 16.9927058 },
    { lat: 48.6348574, lng: 16.9919665 },
    { lat: 48.6346333, lng: 16.9914128 },
    { lat: 48.634045, lng: 16.9894831 },
    { lat: 48.6338879, lng: 16.9886225 },
    { lat: 48.6338591, lng: 16.9875069 },
    { lat: 48.6336466, lng: 16.9857342 },
    { lat: 48.633678, lng: 16.9853678 },
    { lat: 48.6340133, lng: 16.9841241 },
    { lat: 48.6340827, lng: 16.9834159 },
    { lat: 48.6340422, lng: 16.9830913 },
    { lat: 48.6330644, lng: 16.9785216 },
    { lat: 48.6313966, lng: 16.9777675 },
    { lat: 48.6311366, lng: 16.9776494 },
    { lat: 48.6310742, lng: 16.977613 },
    { lat: 48.6295475, lng: 16.9766895 },
    { lat: 48.6280959, lng: 16.9810759 },
    { lat: 48.6285041, lng: 16.9812957 },
    { lat: 48.6289223, lng: 16.981182 },
    { lat: 48.6289408, lng: 16.9813035 },
    { lat: 48.6289435, lng: 16.9819697 },
    { lat: 48.6288292, lng: 16.9836037 },
    { lat: 48.6285023, lng: 16.9847737 },
    { lat: 48.6281975, lng: 16.985706 },
    { lat: 48.626709, lng: 16.9896076 },
    { lat: 48.6264053, lng: 16.9907397 },
    { lat: 48.6245147, lng: 16.9945298 },
    { lat: 48.6237854, lng: 16.9961004 },
    { lat: 48.6241173, lng: 16.9970701 },
    { lat: 48.6250152, lng: 16.9986386 },
    { lat: 48.6254231, lng: 16.9994805 },
    { lat: 48.6260026, lng: 17.0011331 },
    { lat: 48.626346, lng: 17.0016796 },
    { lat: 48.6268526, lng: 17.0022983 },
    { lat: 48.6269511, lng: 17.0025831 },
    { lat: 48.6271903, lng: 17.0027575 },
    { lat: 48.6270482, lng: 17.003459 },
    { lat: 48.6269062, lng: 17.0039115 },
    { lat: 48.6268806, lng: 17.004183 },
    { lat: 48.6266085, lng: 17.0054158 },
    { lat: 48.6258301, lng: 17.0053021 },
    { lat: 48.6258324, lng: 17.0074097 },
    { lat: 48.6260348, lng: 17.0073783 },
    { lat: 48.6260129, lng: 17.0080372 },
    { lat: 48.6257841, lng: 17.008192 },
    { lat: 48.625382, lng: 17.0085958 },
    { lat: 48.6251989, lng: 17.0089603 },
    { lat: 48.6233426, lng: 17.0109736 },
    { lat: 48.6234585, lng: 17.0112328 },
    { lat: 48.6235828, lng: 17.0111307 },
    { lat: 48.6236863, lng: 17.0113575 },
    { lat: 48.6234195, lng: 17.0122522 },
    { lat: 48.6227988, lng: 17.0128434 },
    { lat: 48.6222041, lng: 17.0135731 },
    { lat: 48.6218947, lng: 17.0138732 },
    { lat: 48.6214001, lng: 17.0145252 },
    { lat: 48.6211361, lng: 17.014999 },
    { lat: 48.6206955, lng: 17.0155633 },
    { lat: 48.6203525, lng: 17.0163526 },
    { lat: 48.6190898, lng: 17.0173738 },
    { lat: 48.6190132, lng: 17.0176789 },
    { lat: 48.617994, lng: 17.0188591 },
    { lat: 48.6149005, lng: 17.0229373 },
    { lat: 48.611326, lng: 17.0264101 },
    { lat: 48.6109098, lng: 17.0269851 },
    { lat: 48.6088052, lng: 17.0308548 },
    { lat: 48.6086273, lng: 17.0310141 },
    { lat: 48.6081571, lng: 17.031863 },
    { lat: 48.6078035, lng: 17.0314952 },
    { lat: 48.6061052, lng: 17.0345255 },
    { lat: 48.6052563, lng: 17.0364857 },
    { lat: 48.6049879, lng: 17.0361633 },
    { lat: 48.6044993, lng: 17.0371385 },
    { lat: 48.6047551, lng: 17.0374114 },
    { lat: 48.6043753, lng: 17.0380392 },
    { lat: 48.6038268, lng: 17.0374347 },
    { lat: 48.6028039, lng: 17.0387647 },
    { lat: 48.6023267, lng: 17.0381747 },
    { lat: 48.6019417, lng: 17.0390649 },
    { lat: 48.6018114, lng: 17.0394931 },
    { lat: 48.601369, lng: 17.0402318 },
    { lat: 48.6012185, lng: 17.0403793 },
    { lat: 48.6000848, lng: 17.0408062 },
    { lat: 48.5991915, lng: 17.0417644 },
    { lat: 48.5985818, lng: 17.0422222 },
    { lat: 48.597814, lng: 17.0424481 },
    { lat: 48.5961066, lng: 17.043098 },
    { lat: 48.5953317, lng: 17.0430522 },
    { lat: 48.593665, lng: 17.0433878 },
    { lat: 48.590556, lng: 17.04378 },
    { lat: 48.5905773, lng: 17.0439794 },
    { lat: 48.5895674, lng: 17.0445174 },
    { lat: 48.5884444, lng: 17.045252 },
    { lat: 48.5883192, lng: 17.0425586 },
    { lat: 48.5873607, lng: 17.0426536 },
    { lat: 48.5858366, lng: 17.0425445 },
    { lat: 48.585282, lng: 17.0434127 },
    { lat: 48.5847656, lng: 17.0439605 },
    { lat: 48.5846849, lng: 17.0439901 },
    { lat: 48.5845983, lng: 17.0438264 },
    { lat: 48.5845025, lng: 17.0437484 },
    { lat: 48.5844631, lng: 17.0437852 },
    { lat: 48.584223, lng: 17.044092 },
    { lat: 48.5829959, lng: 17.046077 },
    { lat: 48.582796, lng: 17.0455782 },
    { lat: 48.5828302, lng: 17.0450483 },
    { lat: 48.5830403, lng: 17.044017 },
    { lat: 48.5833359, lng: 17.0431598 },
    { lat: 48.5833493, lng: 17.0431232 },
    { lat: 48.5829035, lng: 17.0429321 },
    { lat: 48.582311, lng: 17.0425448 },
    { lat: 48.5820235, lng: 17.0421362 },
    { lat: 48.5817914, lng: 17.0425475 },
    { lat: 48.581444, lng: 17.0428401 },
    { lat: 48.5811038, lng: 17.0430393 },
    { lat: 48.581039, lng: 17.0429647 },
    { lat: 48.5798361, lng: 17.0436544 },
    { lat: 48.5794755, lng: 17.0429887 },
    { lat: 48.5791595, lng: 17.0431941 },
    { lat: 48.5791855, lng: 17.0421948 },
    { lat: 48.579048, lng: 17.0424015 },
    { lat: 48.5782215, lng: 17.0431931 },
    { lat: 48.5777588, lng: 17.0434856 },
    { lat: 48.5769127, lng: 17.0437227 },
    { lat: 48.5766672, lng: 17.0434019 },
    { lat: 48.5765189, lng: 17.0433313 },
    { lat: 48.5763265, lng: 17.0430434 },
    { lat: 48.5762004, lng: 17.0430168 },
    { lat: 48.5759699, lng: 17.0424025 },
    { lat: 48.5750243, lng: 17.0417907 },
    { lat: 48.5749299, lng: 17.0417456 },
    { lat: 48.5748311, lng: 17.0418083 },
    { lat: 48.5737522, lng: 17.0404778 },
    { lat: 48.5734574, lng: 17.0401989 },
    { lat: 48.5728886, lng: 17.0399758 },
    { lat: 48.5721563, lng: 17.0394264 },
    { lat: 48.5720495, lng: 17.0393355 },
    { lat: 48.5717983, lng: 17.0388975 },
    { lat: 48.5709287, lng: 17.0384707 },
    { lat: 48.5707107, lng: 17.0394552 },
    { lat: 48.5694909, lng: 17.039015 },
    { lat: 48.5693675, lng: 17.0392102 },
    { lat: 48.5691733, lng: 17.0393797 },
    { lat: 48.569089, lng: 17.039387 },
    { lat: 48.5689356, lng: 17.039259 },
    { lat: 48.5686972, lng: 17.039259 },
    { lat: 48.5683499, lng: 17.0393868 },
    { lat: 48.5680795, lng: 17.0396133 },
    { lat: 48.5677887, lng: 17.0396588 },
    { lat: 48.5674284, lng: 17.0400601 },
    { lat: 48.5673669, lng: 17.0402005 },
    { lat: 48.5673548, lng: 17.0404068 },
    { lat: 48.5672551, lng: 17.0404868 },
    { lat: 48.567039, lng: 17.0405329 },
    { lat: 48.5668998, lng: 17.040482 },
    { lat: 48.5667361, lng: 17.0405985 },
    { lat: 48.5666646, lng: 17.04091 },
    { lat: 48.5665678, lng: 17.0410501 },
    { lat: 48.5665189, lng: 17.0413752 },
    { lat: 48.5663255, lng: 17.0420561 },
    { lat: 48.5661912, lng: 17.0422759 },
    { lat: 48.5661601, lng: 17.042418 },
    { lat: 48.5662033, lng: 17.0431695 },
    { lat: 48.5654633, lng: 17.0439707 },
    { lat: 48.5653388, lng: 17.0440352 },
    { lat: 48.5653161, lng: 17.0447997 },
    { lat: 48.5654076, lng: 17.0450664 },
    { lat: 48.5653565, lng: 17.0452266 },
    { lat: 48.565384, lng: 17.0453322 },
    { lat: 48.5658804, lng: 17.0458526 },
    { lat: 48.5659658, lng: 17.0460307 },
    { lat: 48.5660297, lng: 17.0460398 },
    { lat: 48.5660971, lng: 17.0462659 },
    { lat: 48.5661876, lng: 17.0463183 },
    { lat: 48.5661604, lng: 17.0465669 },
    { lat: 48.5661922, lng: 17.0474857 },
    { lat: 48.5634482, lng: 17.0517958 },
    { lat: 48.5638084, lng: 17.0523065 },
    { lat: 48.563816, lng: 17.05231 },
  ],
  BorskýMikuláš: [
    { lat: 48.60798, lng: 17.1716749 },
    { lat: 48.6075408, lng: 17.1728329 },
    { lat: 48.6071238, lng: 17.1739496 },
    { lat: 48.606518, lng: 17.1751649 },
    { lat: 48.6062481, lng: 17.1758788 },
    { lat: 48.6019935, lng: 17.1851945 },
    { lat: 48.6015342, lng: 17.1863396 },
    { lat: 48.6015105, lng: 17.1865952 },
    { lat: 48.6013712, lng: 17.1867758 },
    { lat: 48.6010506, lng: 17.187525 },
    { lat: 48.6006022, lng: 17.1887522 },
    { lat: 48.598847, lng: 17.1925778 },
    { lat: 48.5985133, lng: 17.1934057 },
    { lat: 48.5982852, lng: 17.1938132 },
    { lat: 48.5973528, lng: 17.1946958 },
    { lat: 48.5965826, lng: 17.1952423 },
    { lat: 48.5951549, lng: 17.1966752 },
    { lat: 48.5942021, lng: 17.197433 },
    { lat: 48.5939891, lng: 17.1977286 },
    { lat: 48.5937013, lng: 17.1979909 },
    { lat: 48.5928621, lng: 17.1985042 },
    { lat: 48.5913045, lng: 17.1997819 },
    { lat: 48.5901938, lng: 17.2009196 },
    { lat: 48.5890029, lng: 17.2017847 },
    { lat: 48.588586, lng: 17.2019918 },
    { lat: 48.5867407, lng: 17.2037713 },
    { lat: 48.5866853, lng: 17.204043 },
    { lat: 48.5866983, lng: 17.2042698 },
    { lat: 48.5871267, lng: 17.2073692 },
    { lat: 48.5872505, lng: 17.2086202 },
    { lat: 48.5877307, lng: 17.2117712 },
    { lat: 48.5879372, lng: 17.2124316 },
    { lat: 48.5879993, lng: 17.2128037 },
    { lat: 48.5879602, lng: 17.2138662 },
    { lat: 48.5881648, lng: 17.2161021 },
    { lat: 48.5885722, lng: 17.216961 },
    { lat: 48.5887389, lng: 17.2175011 },
    { lat: 48.5887645, lng: 17.2177153 },
    { lat: 48.5887306, lng: 17.2181751 },
    { lat: 48.5885106, lng: 17.2191178 },
    { lat: 48.5885954, lng: 17.2196821 },
    { lat: 48.5892368, lng: 17.2188089 },
    { lat: 48.5902225, lng: 17.2206574 },
    { lat: 48.590805, lng: 17.2201047 },
    { lat: 48.5909526, lng: 17.2204893 },
    { lat: 48.5911265, lng: 17.2207271 },
    { lat: 48.5910762, lng: 17.2207722 },
    { lat: 48.5911764, lng: 17.2209512 },
    { lat: 48.5916745, lng: 17.2214936 },
    { lat: 48.591857, lng: 17.2218287 },
    { lat: 48.5922195, lng: 17.2221524 },
    { lat: 48.5924224, lng: 17.2225023 },
    { lat: 48.5915867, lng: 17.2233094 },
    { lat: 48.5916697, lng: 17.2236716 },
    { lat: 48.5921435, lng: 17.2250284 },
    { lat: 48.5925726, lng: 17.2265416 },
    { lat: 48.5924427, lng: 17.2267282 },
    { lat: 48.5916781, lng: 17.2272808 },
    { lat: 48.5921271, lng: 17.228362 },
    { lat: 48.5923854, lng: 17.2280658 },
    { lat: 48.5925904, lng: 17.2285331 },
    { lat: 48.5929186, lng: 17.2280812 },
    { lat: 48.5940778, lng: 17.2305755 },
    { lat: 48.5936564, lng: 17.2313437 },
    { lat: 48.5931203, lng: 17.2321014 },
    { lat: 48.5938043, lng: 17.2330145 },
    { lat: 48.5942862, lng: 17.2334342 },
    { lat: 48.59396, lng: 17.2344436 },
    { lat: 48.5942773, lng: 17.2346993 },
    { lat: 48.5941674, lng: 17.2354642 },
    { lat: 48.5945588, lng: 17.2357451 },
    { lat: 48.5955395, lng: 17.236875 },
    { lat: 48.5952926, lng: 17.2374114 },
    { lat: 48.5954228, lng: 17.2375688 },
    { lat: 48.5953466, lng: 17.237748 },
    { lat: 48.5947968, lng: 17.2388813 },
    { lat: 48.5945941, lng: 17.2390106 },
    { lat: 48.5949301, lng: 17.2394123 },
    { lat: 48.5944959, lng: 17.2398344 },
    { lat: 48.5956527, lng: 17.2410971 },
    { lat: 48.5965435, lng: 17.2418915 },
    { lat: 48.5968616, lng: 17.2420736 },
    { lat: 48.5967218, lng: 17.2427095 },
    { lat: 48.596137, lng: 17.2434498 },
    { lat: 48.5959408, lng: 17.2435931 },
    { lat: 48.5957969, lng: 17.243483 },
    { lat: 48.5956688, lng: 17.2437192 },
    { lat: 48.5953206, lng: 17.2446545 },
    { lat: 48.5955666, lng: 17.2448741 },
    { lat: 48.5955366, lng: 17.2459925 },
    { lat: 48.5954127, lng: 17.2467159 },
    { lat: 48.5951535, lng: 17.2477334 },
    { lat: 48.5950657, lng: 17.2478055 },
    { lat: 48.5949, lng: 17.2478132 },
    { lat: 48.5946811, lng: 17.2486576 },
    { lat: 48.5944546, lng: 17.2489878 },
    { lat: 48.5941379, lng: 17.2488448 },
    { lat: 48.5938861, lng: 17.248627 },
    { lat: 48.5938523, lng: 17.248696 },
    { lat: 48.5986051, lng: 17.2565027 },
    { lat: 48.5985754, lng: 17.2565507 },
    { lat: 48.5986213, lng: 17.2565609 },
    { lat: 48.5986395, lng: 17.2565639 },
    { lat: 48.5950652, lng: 17.2623566 },
    { lat: 48.5925353, lng: 17.2668719 },
    { lat: 48.592524, lng: 17.266898 },
    { lat: 48.594154, lng: 17.267649 },
    { lat: 48.594401, lng: 17.267764 },
    { lat: 48.595181, lng: 17.268294 },
    { lat: 48.595465, lng: 17.268335 },
    { lat: 48.595542, lng: 17.26833 },
    { lat: 48.596041, lng: 17.268301 },
    { lat: 48.596642, lng: 17.267641 },
    { lat: 48.596882, lng: 17.267554 },
    { lat: 48.597393, lng: 17.267565 },
    { lat: 48.597436, lng: 17.267566 },
    { lat: 48.598467, lng: 17.267588 },
    { lat: 48.59891, lng: 17.267671 },
    { lat: 48.599065, lng: 17.267738 },
    { lat: 48.59939, lng: 17.26796 },
    { lat: 48.599484, lng: 17.268062 },
    { lat: 48.599791, lng: 17.268396 },
    { lat: 48.600132, lng: 17.268862 },
    { lat: 48.60048, lng: 17.26947 },
    { lat: 48.60079, lng: 17.270197 },
    { lat: 48.60149, lng: 17.271265 },
    { lat: 48.602045, lng: 17.272086 },
    { lat: 48.602098, lng: 17.272165 },
    { lat: 48.60227, lng: 17.272462 },
    { lat: 48.602625, lng: 17.273004 },
    { lat: 48.602742, lng: 17.273263 },
    { lat: 48.603122, lng: 17.273884 },
    { lat: 48.603508, lng: 17.274569 },
    { lat: 48.603736, lng: 17.274864 },
    { lat: 48.604028, lng: 17.27572 },
    { lat: 48.604251, lng: 17.276553 },
    { lat: 48.604278, lng: 17.276648 },
    { lat: 48.605005, lng: 17.278179 },
    { lat: 48.605383, lng: 17.278822 },
    { lat: 48.605797, lng: 17.279348 },
    { lat: 48.606326, lng: 17.280015 },
    { lat: 48.606396, lng: 17.280101 },
    { lat: 48.60678, lng: 17.280665 },
    { lat: 48.606793, lng: 17.280685 },
    { lat: 48.607126, lng: 17.281255 },
    { lat: 48.607682, lng: 17.281749 },
    { lat: 48.608004, lng: 17.28203 },
    { lat: 48.608472, lng: 17.282448 },
    { lat: 48.608535, lng: 17.2825366 },
    { lat: 48.6090535, lng: 17.2831541 },
    { lat: 48.6098184, lng: 17.2835003 },
    { lat: 48.6105858, lng: 17.2841407 },
    { lat: 48.6119126, lng: 17.2849591 },
    { lat: 48.612682, lng: 17.2856663 },
    { lat: 48.6133911, lng: 17.2870526 },
    { lat: 48.6138815, lng: 17.2881585 },
    { lat: 48.6142515, lng: 17.2892685 },
    { lat: 48.6145321, lng: 17.2897393 },
    { lat: 48.6149537, lng: 17.2902582 },
    { lat: 48.6153364, lng: 17.2905631 },
    { lat: 48.6163954, lng: 17.2911401 },
    { lat: 48.6170333, lng: 17.2913415 },
    { lat: 48.6175868, lng: 17.2918755 },
    { lat: 48.6186595, lng: 17.2931406 },
    { lat: 48.6190574, lng: 17.2937436 },
    { lat: 48.6192388, lng: 17.2940185 },
    { lat: 48.61979, lng: 17.2951348 },
    { lat: 48.6215785, lng: 17.2978082 },
    { lat: 48.6227361, lng: 17.2984089 },
    { lat: 48.6236124, lng: 17.2989898 },
    { lat: 48.6247007, lng: 17.2994477 },
    { lat: 48.6249526, lng: 17.2997047 },
    { lat: 48.6257491, lng: 17.3008442 },
    { lat: 48.626518, lng: 17.3026307 },
    { lat: 48.6279436, lng: 17.3048217 },
    { lat: 48.6281838, lng: 17.3053165 },
    { lat: 48.628534, lng: 17.306056 },
    { lat: 48.6289622, lng: 17.3076281 },
    { lat: 48.6293038, lng: 17.3086013 },
    { lat: 48.6301138, lng: 17.3104133 },
    { lat: 48.6301575, lng: 17.3103575 },
    { lat: 48.6303094, lng: 17.3093464 },
    { lat: 48.6317448, lng: 17.2996487 },
    { lat: 48.63391, lng: 17.2850204 },
    { lat: 48.6340318, lng: 17.2844679 },
    { lat: 48.6352603, lng: 17.2769273 },
    { lat: 48.6354795, lng: 17.2763948 },
    { lat: 48.6358383, lng: 17.2759936 },
    { lat: 48.635956, lng: 17.2757713 },
    { lat: 48.6361039, lng: 17.2757024 },
    { lat: 48.6363757, lng: 17.2753857 },
    { lat: 48.6364316, lng: 17.2751891 },
    { lat: 48.6365819, lng: 17.2751562 },
    { lat: 48.6368421, lng: 17.2749544 },
    { lat: 48.6370635, lng: 17.2749377 },
    { lat: 48.6371676, lng: 17.2750778 },
    { lat: 48.6372618, lng: 17.2750484 },
    { lat: 48.6374415, lng: 17.2751172 },
    { lat: 48.6376681, lng: 17.2754701 },
    { lat: 48.6376141, lng: 17.2756289 },
    { lat: 48.6376707, lng: 17.2757508 },
    { lat: 48.6415238, lng: 17.2738932 },
    { lat: 48.641601, lng: 17.2742431 },
    { lat: 48.6421343, lng: 17.2736775 },
    { lat: 48.6424492, lng: 17.2736746 },
    { lat: 48.6426758, lng: 17.2734403 },
    { lat: 48.6428249, lng: 17.273134 },
    { lat: 48.6430535, lng: 17.2724521 },
    { lat: 48.6428283, lng: 17.2721526 },
    { lat: 48.6441402, lng: 17.2698924 },
    { lat: 48.645844, lng: 17.268987 },
    { lat: 48.6458973, lng: 17.2688512 },
    { lat: 48.6515734, lng: 17.2640674 },
    { lat: 48.6542387, lng: 17.2526526 },
    { lat: 48.6542643, lng: 17.2524733 },
    { lat: 48.6540752, lng: 17.2518179 },
    { lat: 48.6568722, lng: 17.247995 },
    { lat: 48.6577167, lng: 17.2467361 },
    { lat: 48.6589175, lng: 17.2450908 },
    { lat: 48.6591623, lng: 17.2460096 },
    { lat: 48.6623053, lng: 17.2440783 },
    { lat: 48.6628504, lng: 17.243743 },
    { lat: 48.662856, lng: 17.2436553 },
    { lat: 48.6627096, lng: 17.2434922 },
    { lat: 48.6626256, lng: 17.2432311 },
    { lat: 48.6628101, lng: 17.2428588 },
    { lat: 48.662675, lng: 17.2428645 },
    { lat: 48.662596, lng: 17.2430197 },
    { lat: 48.6625569, lng: 17.243012 },
    { lat: 48.6622729, lng: 17.2426795 },
    { lat: 48.6622876, lng: 17.2419936 },
    { lat: 48.662125, lng: 17.2419266 },
    { lat: 48.661906, lng: 17.2416722 },
    { lat: 48.6620546, lng: 17.2414086 },
    { lat: 48.6620815, lng: 17.2412323 },
    { lat: 48.6618778, lng: 17.241159 },
    { lat: 48.6618766, lng: 17.2408325 },
    { lat: 48.6617671, lng: 17.2408004 },
    { lat: 48.6617974, lng: 17.2406797 },
    { lat: 48.6618538, lng: 17.2406587 },
    { lat: 48.6620158, lng: 17.2408527 },
    { lat: 48.6620658, lng: 17.2407573 },
    { lat: 48.6619762, lng: 17.2402712 },
    { lat: 48.6618497, lng: 17.2399804 },
    { lat: 48.6618712, lng: 17.2398281 },
    { lat: 48.6619447, lng: 17.239777 },
    { lat: 48.6620641, lng: 17.2398059 },
    { lat: 48.6622498, lng: 17.2400703 },
    { lat: 48.6625229, lng: 17.2397054 },
    { lat: 48.6626143, lng: 17.2393947 },
    { lat: 48.6625269, lng: 17.2393217 },
    { lat: 48.6622675, lng: 17.2393625 },
    { lat: 48.6622307, lng: 17.2392998 },
    { lat: 48.6622422, lng: 17.2386023 },
    { lat: 48.6617692, lng: 17.2385923 },
    { lat: 48.6616579, lng: 17.2383765 },
    { lat: 48.661815, lng: 17.2381566 },
    { lat: 48.6618441, lng: 17.237945 },
    { lat: 48.661755, lng: 17.2377597 },
    { lat: 48.6618072, lng: 17.2377118 },
    { lat: 48.6620098, lng: 17.2378732 },
    { lat: 48.6620039, lng: 17.2381743 },
    { lat: 48.6620719, lng: 17.2384182 },
    { lat: 48.6621505, lng: 17.2383613 },
    { lat: 48.6622559, lng: 17.2379898 },
    { lat: 48.6623728, lng: 17.238013 },
    { lat: 48.6625392, lng: 17.2381706 },
    { lat: 48.6626291, lng: 17.2377904 },
    { lat: 48.6624478, lng: 17.2374903 },
    { lat: 48.6624676, lng: 17.2372415 },
    { lat: 48.662679, lng: 17.2366965 },
    { lat: 48.6626214, lng: 17.2361502 },
    { lat: 48.6626705, lng: 17.2359873 },
    { lat: 48.6625835, lng: 17.2359893 },
    { lat: 48.6625843, lng: 17.2358963 },
    { lat: 48.6628481, lng: 17.235769 },
    { lat: 48.6628711, lng: 17.2357738 },
    { lat: 48.662867, lng: 17.2357651 },
    { lat: 48.6625234, lng: 17.2348705 },
    { lat: 48.6622779, lng: 17.2347642 },
    { lat: 48.6621335, lng: 17.2348345 },
    { lat: 48.6619208, lng: 17.2350703 },
    { lat: 48.661837, lng: 17.2348263 },
    { lat: 48.6618754, lng: 17.234759 },
    { lat: 48.6618467, lng: 17.2345972 },
    { lat: 48.6619377, lng: 17.2344545 },
    { lat: 48.6619084, lng: 17.234302 },
    { lat: 48.661685, lng: 17.2339633 },
    { lat: 48.6616979, lng: 17.2338495 },
    { lat: 48.6620043, lng: 17.2335646 },
    { lat: 48.6619235, lng: 17.2333842 },
    { lat: 48.6614449, lng: 17.2332711 },
    { lat: 48.6614143, lng: 17.2333558 },
    { lat: 48.6612537, lng: 17.2334364 },
    { lat: 48.6610602, lng: 17.2331229 },
    { lat: 48.6610614, lng: 17.23287 },
    { lat: 48.6611362, lng: 17.2325654 },
    { lat: 48.6606791, lng: 17.2322465 },
    { lat: 48.6606131, lng: 17.2322582 },
    { lat: 48.6606123, lng: 17.2323638 },
    { lat: 48.6604819, lng: 17.2323212 },
    { lat: 48.6605018, lng: 17.2319251 },
    { lat: 48.6604284, lng: 17.231874 },
    { lat: 48.6604365, lng: 17.231345 },
    { lat: 48.6606016, lng: 17.2311607 },
    { lat: 48.6606941, lng: 17.230833 },
    { lat: 48.6605849, lng: 17.2305665 },
    { lat: 48.6606268, lng: 17.2303954 },
    { lat: 48.6603576, lng: 17.2299978 },
    { lat: 48.6603402, lng: 17.2298554 },
    { lat: 48.6604203, lng: 17.2292551 },
    { lat: 48.6602991, lng: 17.228876 },
    { lat: 48.6603672, lng: 17.2287249 },
    { lat: 48.6601072, lng: 17.2285193 },
    { lat: 48.6600776, lng: 17.2282407 },
    { lat: 48.6599805, lng: 17.2280038 },
    { lat: 48.6596846, lng: 17.227953 },
    { lat: 48.659594, lng: 17.2280185 },
    { lat: 48.6593924, lng: 17.2278051 },
    { lat: 48.6593459, lng: 17.2276824 },
    { lat: 48.6593629, lng: 17.2273961 },
    { lat: 48.6594412, lng: 17.2272128 },
    { lat: 48.6593905, lng: 17.2269896 },
    { lat: 48.659283, lng: 17.226835 },
    { lat: 48.6594026, lng: 17.2265253 },
    { lat: 48.6592516, lng: 17.2263787 },
    { lat: 48.659118, lng: 17.2263553 },
    { lat: 48.6590891, lng: 17.2265172 },
    { lat: 48.6588829, lng: 17.2267463 },
    { lat: 48.6587191, lng: 17.2266455 },
    { lat: 48.6587069, lng: 17.2265298 },
    { lat: 48.6585621, lng: 17.2263733 },
    { lat: 48.6585418, lng: 17.226252 },
    { lat: 48.658241, lng: 17.2263006 },
    { lat: 48.6581664, lng: 17.2261285 },
    { lat: 48.6581872, lng: 17.225931 },
    { lat: 48.658018, lng: 17.2256592 },
    { lat: 48.6580027, lng: 17.2255318 },
    { lat: 48.6578456, lng: 17.2254121 },
    { lat: 48.6578489, lng: 17.2253262 },
    { lat: 48.6577669, lng: 17.2251957 },
    { lat: 48.657977, lng: 17.2249721 },
    { lat: 48.6581674, lng: 17.2230009 },
    { lat: 48.6583525, lng: 17.2223319 },
    { lat: 48.6587304, lng: 17.2216421 },
    { lat: 48.6590216, lng: 17.2214134 },
    { lat: 48.6582374, lng: 17.22044 },
    { lat: 48.6578892, lng: 17.2211704 },
    { lat: 48.6575145, lng: 17.2212637 },
    { lat: 48.6568309, lng: 17.221891 },
    { lat: 48.6565458, lng: 17.2209989 },
    { lat: 48.6557365, lng: 17.2215726 },
    { lat: 48.6556242, lng: 17.2213605 },
    { lat: 48.6551126, lng: 17.2209524 },
    { lat: 48.6549627, lng: 17.2205659 },
    { lat: 48.6551653, lng: 17.2203152 },
    { lat: 48.6557661, lng: 17.2200573 },
    { lat: 48.6560615, lng: 17.2195015 },
    { lat: 48.655993, lng: 17.2190819 },
    { lat: 48.6558533, lng: 17.2186964 },
    { lat: 48.6549996, lng: 17.2186676 },
    { lat: 48.6547602, lng: 17.2184811 },
    { lat: 48.6544541, lng: 17.2174085 },
    { lat: 48.6541684, lng: 17.2172369 },
    { lat: 48.6542324, lng: 17.2170625 },
    { lat: 48.6541643, lng: 17.2166311 },
    { lat: 48.6537486, lng: 17.2154697 },
    { lat: 48.6533083, lng: 17.2145021 },
    { lat: 48.6524767, lng: 17.2146573 },
    { lat: 48.6521618, lng: 17.2139095 },
    { lat: 48.6515351, lng: 17.2128403 },
    { lat: 48.6511827, lng: 17.2111892 },
    { lat: 48.6504884, lng: 17.21019 },
    { lat: 48.6504618, lng: 17.2101312 },
    { lat: 48.6497894, lng: 17.208542 },
    { lat: 48.6504535, lng: 17.2080634 },
    { lat: 48.650605, lng: 17.2069389 },
    { lat: 48.6507196, lng: 17.206878 },
    { lat: 48.65028, lng: 17.2056342 },
    { lat: 48.6503506, lng: 17.2055743 },
    { lat: 48.650715, lng: 17.2047818 },
    { lat: 48.6505893, lng: 17.2040469 },
    { lat: 48.6510396, lng: 17.203683 },
    { lat: 48.6508557, lng: 17.2029522 },
    { lat: 48.650706, lng: 17.2025697 },
    { lat: 48.6514819, lng: 17.2020397 },
    { lat: 48.6513724, lng: 17.2011212 },
    { lat: 48.6502467, lng: 17.2013357 },
    { lat: 48.6499955, lng: 17.1992885 },
    { lat: 48.6501463, lng: 17.1981549 },
    { lat: 48.6500541, lng: 17.1978387 },
    { lat: 48.6499224, lng: 17.1977947 },
    { lat: 48.6496908, lng: 17.1979329 },
    { lat: 48.6496374, lng: 17.1978778 },
    { lat: 48.6495173, lng: 17.197121 },
    { lat: 48.6493313, lng: 17.1968729 },
    { lat: 48.6495964, lng: 17.1962054 },
    { lat: 48.6495764, lng: 17.1961249 },
    { lat: 48.6494189, lng: 17.1959315 },
    { lat: 48.6493089, lng: 17.1959217 },
    { lat: 48.6489843, lng: 17.1960715 },
    { lat: 48.6489052, lng: 17.1959527 },
    { lat: 48.6488527, lng: 17.1957409 },
    { lat: 48.6488566, lng: 17.1956635 },
    { lat: 48.649011, lng: 17.1954858 },
    { lat: 48.6491356, lng: 17.1951847 },
    { lat: 48.6493129, lng: 17.1950668 },
    { lat: 48.6494376, lng: 17.1949014 },
    { lat: 48.6494704, lng: 17.1945613 },
    { lat: 48.649399, lng: 17.1941341 },
    { lat: 48.649581, lng: 17.193999 },
    { lat: 48.6495692, lng: 17.1938711 },
    { lat: 48.6495465, lng: 17.1937852 },
    { lat: 48.6493484, lng: 17.1936258 },
    { lat: 48.6489199, lng: 17.1931136 },
    { lat: 48.6482609, lng: 17.1921887 },
    { lat: 48.6479945, lng: 17.1912901 },
    { lat: 48.6488557, lng: 17.190871 },
    { lat: 48.6486345, lng: 17.1905504 },
    { lat: 48.6485042, lng: 17.1901204 },
    { lat: 48.6485083, lng: 17.1899933 },
    { lat: 48.6482961, lng: 17.1893512 },
    { lat: 48.6483701, lng: 17.1885811 },
    { lat: 48.6486233, lng: 17.187646 },
    { lat: 48.6487924, lng: 17.1864688 },
    { lat: 48.6487297, lng: 17.1857648 },
    { lat: 48.648605, lng: 17.1855697 },
    { lat: 48.6482084, lng: 17.1849742 },
    { lat: 48.6480988, lng: 17.1847214 },
    { lat: 48.6479595, lng: 17.1835956 },
    { lat: 48.6478762, lng: 17.1832256 },
    { lat: 48.6480595, lng: 17.1827716 },
    { lat: 48.6480509, lng: 17.1826918 },
    { lat: 48.6480057, lng: 17.1824046 },
    { lat: 48.6479017, lng: 17.1823674 },
    { lat: 48.6477304, lng: 17.1818325 },
    { lat: 48.6478412, lng: 17.1814584 },
    { lat: 48.6475628, lng: 17.1812312 },
    { lat: 48.6475427, lng: 17.1810996 },
    { lat: 48.6475653, lng: 17.1806249 },
    { lat: 48.6476326, lng: 17.1803974 },
    { lat: 48.6476066, lng: 17.1801248 },
    { lat: 48.6476398, lng: 17.1800298 },
    { lat: 48.6478279, lng: 17.1799534 },
    { lat: 48.6478735, lng: 17.1797385 },
    { lat: 48.6478576, lng: 17.1795498 },
    { lat: 48.6480532, lng: 17.1791621 },
    { lat: 48.6480473, lng: 17.1788032 },
    { lat: 48.6479887, lng: 17.178617 },
    { lat: 48.6479181, lng: 17.1785584 },
    { lat: 48.6478669, lng: 17.1779749 },
    { lat: 48.6477648, lng: 17.1778485 },
    { lat: 48.6477805, lng: 17.1776972 },
    { lat: 48.6479149, lng: 17.1775078 },
    { lat: 48.6480256, lng: 17.1775106 },
    { lat: 48.6481508, lng: 17.1773027 },
    { lat: 48.6483665, lng: 17.1771642 },
    { lat: 48.6484389, lng: 17.1765733 },
    { lat: 48.6483733, lng: 17.1762817 },
    { lat: 48.647994, lng: 17.1761352 },
    { lat: 48.647841, lng: 17.1756515 },
    { lat: 48.6476511, lng: 17.175328 },
    { lat: 48.6450623, lng: 17.1760183 },
    { lat: 48.6436515, lng: 17.1764813 },
    { lat: 48.6431406, lng: 17.1768689 },
    { lat: 48.6422723, lng: 17.1774532 },
    { lat: 48.6417112, lng: 17.1776994 },
    { lat: 48.6412555, lng: 17.1778096 },
    { lat: 48.6410077, lng: 17.1780236 },
    { lat: 48.6399715, lng: 17.1793621 },
    { lat: 48.6396348, lng: 17.1803141 },
    { lat: 48.639654, lng: 17.1807391 },
    { lat: 48.6398308, lng: 17.1819191 },
    { lat: 48.6391214, lng: 17.1822941 },
    { lat: 48.6389325, lng: 17.1823228 },
    { lat: 48.6383097, lng: 17.181727 },
    { lat: 48.637351, lng: 17.1815297 },
    { lat: 48.6363853, lng: 17.1806012 },
    { lat: 48.6360969, lng: 17.1808996 },
    { lat: 48.635513, lng: 17.1821762 },
    { lat: 48.6347183, lng: 17.1824441 },
    { lat: 48.6344068, lng: 17.182821 },
    { lat: 48.6344876, lng: 17.1830866 },
    { lat: 48.6342962, lng: 17.1835682 },
    { lat: 48.633432, lng: 17.182738 },
    { lat: 48.6327254, lng: 17.1814917 },
    { lat: 48.6326526, lng: 17.1809776 },
    { lat: 48.6323332, lng: 17.1812861 },
    { lat: 48.6316181, lng: 17.1800829 },
    { lat: 48.6308609, lng: 17.1804198 },
    { lat: 48.6297926, lng: 17.181191 },
    { lat: 48.6296335, lng: 17.1812262 },
    { lat: 48.6287143, lng: 17.1820177 },
    { lat: 48.6276976, lng: 17.1833074 },
    { lat: 48.6268224, lng: 17.1843143 },
    { lat: 48.6251653, lng: 17.1842706 },
    { lat: 48.6240643, lng: 17.1824614 },
    { lat: 48.6234068, lng: 17.1819711 },
    { lat: 48.6219295, lng: 17.1802462 },
    { lat: 48.6200635, lng: 17.1807771 },
    { lat: 48.6195762, lng: 17.18259 },
    { lat: 48.6190955, lng: 17.1838726 },
    { lat: 48.6178894, lng: 17.1832025 },
    { lat: 48.6171554, lng: 17.1830877 },
    { lat: 48.6162293, lng: 17.1839242 },
    { lat: 48.6158397, lng: 17.1846588 },
    { lat: 48.6155694, lng: 17.1849407 },
    { lat: 48.6153499, lng: 17.1850396 },
    { lat: 48.6146266, lng: 17.1851038 },
    { lat: 48.6144384, lng: 17.1850437 },
    { lat: 48.6142713, lng: 17.1851476 },
    { lat: 48.6129136, lng: 17.1838104 },
    { lat: 48.6129302, lng: 17.183363 },
    { lat: 48.6128214, lng: 17.182238 },
    { lat: 48.6128467, lng: 17.1817886 },
    { lat: 48.6127721, lng: 17.1807367 },
    { lat: 48.6129572, lng: 17.1792219 },
    { lat: 48.6129009, lng: 17.1785474 },
    { lat: 48.6128119, lng: 17.1781418 },
    { lat: 48.6123055, lng: 17.1771724 },
    { lat: 48.6106799, lng: 17.1753637 },
    { lat: 48.6095496, lng: 17.17373 },
    { lat: 48.6084362, lng: 17.172264 },
    { lat: 48.60798, lng: 17.1716749 },
  ],
  Štefanov: [
    { lat: 48.6504884, lng: 17.21019 },
    { lat: 48.6511827, lng: 17.2111892 },
    { lat: 48.6515351, lng: 17.2128403 },
    { lat: 48.6521618, lng: 17.2139095 },
    { lat: 48.6524767, lng: 17.2146573 },
    { lat: 48.6533083, lng: 17.2145021 },
    { lat: 48.6537486, lng: 17.2154697 },
    { lat: 48.6541643, lng: 17.2166311 },
    { lat: 48.6542324, lng: 17.2170625 },
    { lat: 48.6541684, lng: 17.2172369 },
    { lat: 48.6544541, lng: 17.2174085 },
    { lat: 48.6547602, lng: 17.2184811 },
    { lat: 48.6549996, lng: 17.2186676 },
    { lat: 48.6558533, lng: 17.2186964 },
    { lat: 48.655993, lng: 17.2190819 },
    { lat: 48.6560615, lng: 17.2195015 },
    { lat: 48.6557661, lng: 17.2200573 },
    { lat: 48.6551653, lng: 17.2203152 },
    { lat: 48.6549627, lng: 17.2205659 },
    { lat: 48.6551126, lng: 17.2209524 },
    { lat: 48.6556242, lng: 17.2213605 },
    { lat: 48.6557365, lng: 17.2215726 },
    { lat: 48.6565458, lng: 17.2209989 },
    { lat: 48.6568309, lng: 17.221891 },
    { lat: 48.6575145, lng: 17.2212637 },
    { lat: 48.6578892, lng: 17.2211704 },
    { lat: 48.6582374, lng: 17.22044 },
    { lat: 48.6590216, lng: 17.2214134 },
    { lat: 48.6587304, lng: 17.2216421 },
    { lat: 48.6583525, lng: 17.2223319 },
    { lat: 48.6581674, lng: 17.2230009 },
    { lat: 48.657977, lng: 17.2249721 },
    { lat: 48.6577669, lng: 17.2251957 },
    { lat: 48.6578489, lng: 17.2253262 },
    { lat: 48.6578456, lng: 17.2254121 },
    { lat: 48.6580027, lng: 17.2255318 },
    { lat: 48.658018, lng: 17.2256592 },
    { lat: 48.6581872, lng: 17.225931 },
    { lat: 48.6581664, lng: 17.2261285 },
    { lat: 48.658241, lng: 17.2263006 },
    { lat: 48.6585418, lng: 17.226252 },
    { lat: 48.6585621, lng: 17.2263733 },
    { lat: 48.6587069, lng: 17.2265298 },
    { lat: 48.6587191, lng: 17.2266455 },
    { lat: 48.6588829, lng: 17.2267463 },
    { lat: 48.6590891, lng: 17.2265172 },
    { lat: 48.659118, lng: 17.2263553 },
    { lat: 48.6592516, lng: 17.2263787 },
    { lat: 48.6594026, lng: 17.2265253 },
    { lat: 48.659283, lng: 17.226835 },
    { lat: 48.6593905, lng: 17.2269896 },
    { lat: 48.6594412, lng: 17.2272128 },
    { lat: 48.6593629, lng: 17.2273961 },
    { lat: 48.6593459, lng: 17.2276824 },
    { lat: 48.6593924, lng: 17.2278051 },
    { lat: 48.659594, lng: 17.2280185 },
    { lat: 48.6596846, lng: 17.227953 },
    { lat: 48.6599805, lng: 17.2280038 },
    { lat: 48.6600776, lng: 17.2282407 },
    { lat: 48.6601072, lng: 17.2285193 },
    { lat: 48.6603672, lng: 17.2287249 },
    { lat: 48.6602991, lng: 17.228876 },
    { lat: 48.6604203, lng: 17.2292551 },
    { lat: 48.6603402, lng: 17.2298554 },
    { lat: 48.6603576, lng: 17.2299978 },
    { lat: 48.6606268, lng: 17.2303954 },
    { lat: 48.6605849, lng: 17.2305665 },
    { lat: 48.6606941, lng: 17.230833 },
    { lat: 48.6606016, lng: 17.2311607 },
    { lat: 48.6604365, lng: 17.231345 },
    { lat: 48.6604284, lng: 17.231874 },
    { lat: 48.6605018, lng: 17.2319251 },
    { lat: 48.6604819, lng: 17.2323212 },
    { lat: 48.6606123, lng: 17.2323638 },
    { lat: 48.6606131, lng: 17.2322582 },
    { lat: 48.6606791, lng: 17.2322465 },
    { lat: 48.6611362, lng: 17.2325654 },
    { lat: 48.6610614, lng: 17.23287 },
    { lat: 48.6610602, lng: 17.2331229 },
    { lat: 48.6612537, lng: 17.2334364 },
    { lat: 48.6614143, lng: 17.2333558 },
    { lat: 48.6614449, lng: 17.2332711 },
    { lat: 48.6619235, lng: 17.2333842 },
    { lat: 48.6620043, lng: 17.2335646 },
    { lat: 48.6616979, lng: 17.2338495 },
    { lat: 48.661685, lng: 17.2339633 },
    { lat: 48.6619084, lng: 17.234302 },
    { lat: 48.6619377, lng: 17.2344545 },
    { lat: 48.6618467, lng: 17.2345972 },
    { lat: 48.6618754, lng: 17.234759 },
    { lat: 48.661837, lng: 17.2348263 },
    { lat: 48.6619208, lng: 17.2350703 },
    { lat: 48.6621335, lng: 17.2348345 },
    { lat: 48.6622779, lng: 17.2347642 },
    { lat: 48.6625234, lng: 17.2348705 },
    { lat: 48.662867, lng: 17.2357651 },
    { lat: 48.6628711, lng: 17.2357738 },
    { lat: 48.6630112, lng: 17.235722 },
    { lat: 48.6631084, lng: 17.2356062 },
    { lat: 48.6631667, lng: 17.2353737 },
    { lat: 48.6633397, lng: 17.235259 },
    { lat: 48.6634168, lng: 17.2351256 },
    { lat: 48.6637417, lng: 17.235033 },
    { lat: 48.6639178, lng: 17.2349111 },
    { lat: 48.6639513, lng: 17.2347695 },
    { lat: 48.6638886, lng: 17.2345728 },
    { lat: 48.6639066, lng: 17.2345018 },
    { lat: 48.6642013, lng: 17.2343474 },
    { lat: 48.6642217, lng: 17.2341567 },
    { lat: 48.6645207, lng: 17.2337603 },
    { lat: 48.6645087, lng: 17.2334748 },
    { lat: 48.6645419, lng: 17.2334323 },
    { lat: 48.6649003, lng: 17.2335352 },
    { lat: 48.6649816, lng: 17.2334515 },
    { lat: 48.6649862, lng: 17.2332314 },
    { lat: 48.6650409, lng: 17.2331842 },
    { lat: 48.6652032, lng: 17.2333012 },
    { lat: 48.6653296, lng: 17.2332786 },
    { lat: 48.6654979, lng: 17.2334513 },
    { lat: 48.665641, lng: 17.2334137 },
    { lat: 48.665711, lng: 17.2332587 },
    { lat: 48.6657021, lng: 17.233001 },
    { lat: 48.665741, lng: 17.2328918 },
    { lat: 48.6659432, lng: 17.2329246 },
    { lat: 48.6660577, lng: 17.232862 },
    { lat: 48.6660241, lng: 17.2327298 },
    { lat: 48.6658291, lng: 17.2326159 },
    { lat: 48.665873, lng: 17.2323423 },
    { lat: 48.6659928, lng: 17.2320669 },
    { lat: 48.6662052, lng: 17.2319667 },
    { lat: 48.666257, lng: 17.2320125 },
    { lat: 48.6663366, lng: 17.2324833 },
    { lat: 48.6664405, lng: 17.232482 },
    { lat: 48.6665244, lng: 17.2322828 },
    { lat: 48.6665286, lng: 17.2321063 },
    { lat: 48.6665786, lng: 17.2320938 },
    { lat: 48.6666934, lng: 17.2322402 },
    { lat: 48.6668025, lng: 17.2322177 },
    { lat: 48.6667896, lng: 17.232022 },
    { lat: 48.6667151, lng: 17.2318345 },
    { lat: 48.6670286, lng: 17.2318094 },
    { lat: 48.6675354, lng: 17.2319207 },
    { lat: 48.6676103, lng: 17.231448 },
    { lat: 48.6677811, lng: 17.231586 },
    { lat: 48.6679372, lng: 17.2316086 },
    { lat: 48.6680928, lng: 17.2314665 },
    { lat: 48.6680152, lng: 17.2309588 },
    { lat: 48.6684436, lng: 17.2309618 },
    { lat: 48.6685205, lng: 17.2312365 },
    { lat: 48.6685653, lng: 17.2312487 },
    { lat: 48.6685921, lng: 17.2310238 },
    { lat: 48.6684638, lng: 17.2304138 },
    { lat: 48.6683483, lng: 17.2291109 },
    { lat: 48.6685718, lng: 17.2266664 },
    { lat: 48.669902, lng: 17.2263644 },
    { lat: 48.6704941, lng: 17.2260074 },
    { lat: 48.6705503, lng: 17.2258478 },
    { lat: 48.670501, lng: 17.2252244 },
    { lat: 48.6705402, lng: 17.2244489 },
    { lat: 48.671764, lng: 17.2250161 },
    { lat: 48.6726, lng: 17.2250673 },
    { lat: 48.6729175, lng: 17.2250005 },
    { lat: 48.676924, lng: 17.2258941 },
    { lat: 48.677986, lng: 17.2258441 },
    { lat: 48.6790716, lng: 17.2251087 },
    { lat: 48.6790084, lng: 17.2248376 },
    { lat: 48.6793761, lng: 17.2245734 },
    { lat: 48.6823232, lng: 17.222794 },
    { lat: 48.6833242, lng: 17.2222787 },
    { lat: 48.6833582, lng: 17.2222667 },
    { lat: 48.6839177, lng: 17.2240608 },
    { lat: 48.6844209, lng: 17.2237953 },
    { lat: 48.6850791, lng: 17.2230725 },
    { lat: 48.6869974, lng: 17.2216704 },
    { lat: 48.6884068, lng: 17.2212883 },
    { lat: 48.6886421, lng: 17.2213279 },
    { lat: 48.6922023, lng: 17.2233995 },
    { lat: 48.6933402, lng: 17.2239165 },
    { lat: 48.6940119, lng: 17.2240615 },
    { lat: 48.6945535, lng: 17.2242745 },
    { lat: 48.6997392, lng: 17.2226345 },
    { lat: 48.7041213, lng: 17.2195399 },
    { lat: 48.7041041, lng: 17.2203079 },
    { lat: 48.7041821, lng: 17.2206852 },
    { lat: 48.7048674, lng: 17.2204548 },
    { lat: 48.7051343, lng: 17.2204238 },
    { lat: 48.7051808, lng: 17.2205301 },
    { lat: 48.7050076, lng: 17.2210717 },
    { lat: 48.7049918, lng: 17.2212944 },
    { lat: 48.7050569, lng: 17.2218172 },
    { lat: 48.705109, lng: 17.2220389 },
    { lat: 48.7054763, lng: 17.2228609 },
    { lat: 48.7056355, lng: 17.2235604 },
    { lat: 48.7058575, lng: 17.2239962 },
    { lat: 48.7058694, lng: 17.2241446 },
    { lat: 48.7059034, lng: 17.2241978 },
    { lat: 48.7059501, lng: 17.2241693 },
    { lat: 48.70605, lng: 17.22413 },
    { lat: 48.706112, lng: 17.224106 },
    { lat: 48.706334, lng: 17.223996 },
    { lat: 48.7065, lng: 17.223913 },
    { lat: 48.70694, lng: 17.223695 },
    { lat: 48.707269, lng: 17.22353 },
    { lat: 48.707508, lng: 17.223421 },
    { lat: 48.707453, lng: 17.223247 },
    { lat: 48.70737, lng: 17.223004 },
    { lat: 48.707347, lng: 17.222931 },
    { lat: 48.707262, lng: 17.222636 },
    { lat: 48.707221, lng: 17.222495 },
    { lat: 48.707159, lng: 17.222182 },
    { lat: 48.707111, lng: 17.221963 },
    { lat: 48.707084, lng: 17.221787 },
    { lat: 48.707054, lng: 17.221626 },
    { lat: 48.707033, lng: 17.221489 },
    { lat: 48.707011, lng: 17.221351 },
    { lat: 48.706993, lng: 17.221244 },
    { lat: 48.707589, lng: 17.220933 },
    { lat: 48.7075, lng: 17.220641 },
    { lat: 48.707461, lng: 17.220503 },
    { lat: 48.707689, lng: 17.220427 },
    { lat: 48.707688, lng: 17.220393 },
    { lat: 48.707684, lng: 17.219955 },
    { lat: 48.7077, lng: 17.219782 },
    { lat: 48.707706, lng: 17.219728 },
    { lat: 48.707711, lng: 17.219694 },
    { lat: 48.707709, lng: 17.219639 },
    { lat: 48.707705, lng: 17.219543 },
    { lat: 48.707707, lng: 17.219454 },
    { lat: 48.707709, lng: 17.219422 },
    { lat: 48.707711, lng: 17.219379 },
    { lat: 48.707717, lng: 17.219175 },
    { lat: 48.707724, lng: 17.218982 },
    { lat: 48.707724, lng: 17.218967 },
    { lat: 48.707725, lng: 17.218918 },
    { lat: 48.707727, lng: 17.218885 },
    { lat: 48.707726, lng: 17.218862 },
    { lat: 48.707729, lng: 17.218764 },
    { lat: 48.708107, lng: 17.218678 },
    { lat: 48.70856, lng: 17.218579 },
    { lat: 48.708575, lng: 17.218311 },
    { lat: 48.708577, lng: 17.218272 },
    { lat: 48.708584, lng: 17.218183 },
    { lat: 48.708768, lng: 17.218137 },
    { lat: 48.708824, lng: 17.218126 },
    { lat: 48.709031, lng: 17.218103 },
    { lat: 48.70905, lng: 17.218101 },
    { lat: 48.709102, lng: 17.217879 },
    { lat: 48.709121, lng: 17.217788 },
    { lat: 48.709138, lng: 17.217696 },
    { lat: 48.709222, lng: 17.217254 },
    { lat: 48.709246, lng: 17.217067 },
    { lat: 48.709299, lng: 17.216467 },
    { lat: 48.70931, lng: 17.216311 },
    { lat: 48.7093, lng: 17.216006 },
    { lat: 48.709294, lng: 17.215837 },
    { lat: 48.709284, lng: 17.215743 },
    { lat: 48.709249, lng: 17.215488 },
    { lat: 48.709183, lng: 17.215075 },
    { lat: 48.709165, lng: 17.214943 },
    { lat: 48.709154, lng: 17.214864 },
    { lat: 48.709135, lng: 17.214697 },
    { lat: 48.709123, lng: 17.214521 },
    { lat: 48.709111, lng: 17.214307 },
    { lat: 48.709111, lng: 17.214126 },
    { lat: 48.709129, lng: 17.213913 },
    { lat: 48.709134, lng: 17.213555 },
    { lat: 48.709136, lng: 17.213254 },
    { lat: 48.709143, lng: 17.212838 },
    { lat: 48.709151, lng: 17.212604 },
    { lat: 48.709157, lng: 17.21256 },
    { lat: 48.709185, lng: 17.212367 },
    { lat: 48.709213, lng: 17.212192 },
    { lat: 48.709226, lng: 17.212122 },
    { lat: 48.709256, lng: 17.211998 },
    { lat: 48.709324, lng: 17.211501 },
    { lat: 48.709383, lng: 17.211055 },
    { lat: 48.709442, lng: 17.210637 },
    { lat: 48.709456, lng: 17.210633 },
    { lat: 48.709467, lng: 17.210241 },
    { lat: 48.709487, lng: 17.209694 },
    { lat: 48.70951, lng: 17.208568 },
    { lat: 48.709496, lng: 17.207751 },
    { lat: 48.70947, lng: 17.206131 },
    { lat: 48.709471, lng: 17.206101 },
    { lat: 48.709468, lng: 17.206056 },
    { lat: 48.709469, lng: 17.206021 },
    { lat: 48.70947, lng: 17.205861 },
    { lat: 48.709459, lng: 17.205529 },
    { lat: 48.709417, lng: 17.205332 },
    { lat: 48.709334, lng: 17.205057 },
    { lat: 48.709253, lng: 17.204671 },
    { lat: 48.709196, lng: 17.204284 },
    { lat: 48.709124, lng: 17.203773 },
    { lat: 48.709068, lng: 17.203333 },
    { lat: 48.709023, lng: 17.202897 },
    { lat: 48.70901, lng: 17.202621 },
    { lat: 48.709008, lng: 17.20237 },
    { lat: 48.708972, lng: 17.202353 },
    { lat: 48.708943, lng: 17.202342 },
    { lat: 48.708909, lng: 17.202328 },
    { lat: 48.708813, lng: 17.202288 },
    { lat: 48.708728, lng: 17.202255 },
    { lat: 48.708677, lng: 17.202235 },
    { lat: 48.708621, lng: 17.202211 },
    { lat: 48.708556, lng: 17.202179 },
    { lat: 48.708473, lng: 17.202142 },
    { lat: 48.708358, lng: 17.20209 },
    { lat: 48.708233, lng: 17.202024 },
    { lat: 48.708074, lng: 17.201934 },
    { lat: 48.70792, lng: 17.201443 },
    { lat: 48.707877, lng: 17.201271 },
    { lat: 48.707783, lng: 17.201242 },
    { lat: 48.707547, lng: 17.201167 },
    { lat: 48.707498, lng: 17.20115 },
    { lat: 48.707456, lng: 17.201134 },
    { lat: 48.707374, lng: 17.201102 },
    { lat: 48.707346, lng: 17.201089 },
    { lat: 48.707207, lng: 17.201004 },
    { lat: 48.707013, lng: 17.200567 },
    { lat: 48.706855, lng: 17.200217 },
    { lat: 48.706789, lng: 17.200181 },
    { lat: 48.706727, lng: 17.200149 },
    { lat: 48.706594, lng: 17.200085 },
    { lat: 48.706517, lng: 17.200043 },
    { lat: 48.706451, lng: 17.200017 },
    { lat: 48.70636, lng: 17.199983 },
    { lat: 48.70629, lng: 17.199956 },
    { lat: 48.706063, lng: 17.199869 },
    { lat: 48.705861, lng: 17.199791 },
    { lat: 48.705815, lng: 17.199839 },
    { lat: 48.705729, lng: 17.199916 },
    { lat: 48.705503, lng: 17.200118 },
    { lat: 48.705425, lng: 17.200186 },
    { lat: 48.70522, lng: 17.200361 },
    { lat: 48.7051, lng: 17.200478 },
    { lat: 48.705064, lng: 17.200578 },
    { lat: 48.705023, lng: 17.200808 },
    { lat: 48.705014, lng: 17.201071 },
    { lat: 48.704882, lng: 17.201383 },
    { lat: 48.70476, lng: 17.201479 },
    { lat: 48.704709, lng: 17.201537 },
    { lat: 48.704681, lng: 17.201587 },
    { lat: 48.704634, lng: 17.201669 },
    { lat: 48.704587, lng: 17.201681 },
    { lat: 48.704389, lng: 17.20158 },
    { lat: 48.704239, lng: 17.201432 },
    { lat: 48.704106, lng: 17.20134 },
    { lat: 48.704056, lng: 17.201278 },
    { lat: 48.703973, lng: 17.20117 },
    { lat: 48.703945, lng: 17.201086 },
    { lat: 48.703935, lng: 17.20106 },
    { lat: 48.703904, lng: 17.200978 },
    { lat: 48.703938, lng: 17.200909 },
    { lat: 48.703964, lng: 17.200846 },
    { lat: 48.703978, lng: 17.200808 },
    { lat: 48.703972, lng: 17.200779 },
    { lat: 48.703959, lng: 17.200748 },
    { lat: 48.703951, lng: 17.200726 },
    { lat: 48.703918, lng: 17.200642 },
    { lat: 48.703871, lng: 17.200533 },
    { lat: 48.703871, lng: 17.200441 },
    { lat: 48.703871, lng: 17.200412 },
    { lat: 48.703882, lng: 17.200296 },
    { lat: 48.703872, lng: 17.200155 },
    { lat: 48.703892, lng: 17.200058 },
    { lat: 48.70413, lng: 17.199865 },
    { lat: 48.704131, lng: 17.19982 },
    { lat: 48.704133, lng: 17.199764 },
    { lat: 48.704134, lng: 17.199705 },
    { lat: 48.704135, lng: 17.19965 },
    { lat: 48.704137, lng: 17.199584 },
    { lat: 48.704137, lng: 17.199526 },
    { lat: 48.704142, lng: 17.199471 },
    { lat: 48.704149, lng: 17.199407 },
    { lat: 48.704155, lng: 17.199342 },
    { lat: 48.704162, lng: 17.199276 },
    { lat: 48.704164, lng: 17.199247 },
    { lat: 48.704168, lng: 17.199215 },
    { lat: 48.704177, lng: 17.199116 },
    { lat: 48.70418, lng: 17.199085 },
    { lat: 48.70418, lng: 17.199075 },
    { lat: 48.704183, lng: 17.199059 },
    { lat: 48.704184, lng: 17.199045 },
    { lat: 48.70419, lng: 17.198982 },
    { lat: 48.704195, lng: 17.198934 },
    { lat: 48.704197, lng: 17.1989 },
    { lat: 48.704199, lng: 17.198867 },
    { lat: 48.704202, lng: 17.198842 },
    { lat: 48.704203, lng: 17.19883 },
    { lat: 48.704205, lng: 17.198808 },
    { lat: 48.70421, lng: 17.198746 },
    { lat: 48.704217, lng: 17.198688 },
    { lat: 48.704217, lng: 17.198612 },
    { lat: 48.704217, lng: 17.198556 },
    { lat: 48.704214, lng: 17.19841 },
    { lat: 48.704212, lng: 17.198352 },
    { lat: 48.704211, lng: 17.198283 },
    { lat: 48.704211, lng: 17.198249 },
    { lat: 48.70421, lng: 17.198219 },
    { lat: 48.70421, lng: 17.198175 },
    { lat: 48.704208, lng: 17.198118 },
    { lat: 48.704208, lng: 17.198018 },
    { lat: 48.704153, lng: 17.197945 },
    { lat: 48.704032, lng: 17.19779 },
    { lat: 48.70393, lng: 17.197659 },
    { lat: 48.703827, lng: 17.197521 },
    { lat: 48.703781, lng: 17.197458 },
    { lat: 48.70376, lng: 17.19739 },
    { lat: 48.703733, lng: 17.197307 },
    { lat: 48.703709, lng: 17.197231 },
    { lat: 48.703659, lng: 17.197083 },
    { lat: 48.70361, lng: 17.196935 },
    { lat: 48.703597, lng: 17.196904 },
    { lat: 48.703583, lng: 17.196877 },
    { lat: 48.703559, lng: 17.196817 },
    { lat: 48.703533, lng: 17.196759 },
    { lat: 48.703507, lng: 17.196697 },
    { lat: 48.703482, lng: 17.196645 },
    { lat: 48.703459, lng: 17.196587 },
    { lat: 48.70345, lng: 17.196566 },
    { lat: 48.703442, lng: 17.196548 },
    { lat: 48.703435, lng: 17.196535 },
    { lat: 48.703426, lng: 17.19651 },
    { lat: 48.703411, lng: 17.196478 },
    { lat: 48.703399, lng: 17.19645 },
    { lat: 48.703382, lng: 17.196403 },
    { lat: 48.703373, lng: 17.196379 },
    { lat: 48.703365, lng: 17.196355 },
    { lat: 48.703357, lng: 17.196341 },
    { lat: 48.70335, lng: 17.196319 },
    { lat: 48.703328, lng: 17.196268 },
    { lat: 48.703314, lng: 17.196232 },
    { lat: 48.7033, lng: 17.196197 },
    { lat: 48.703289, lng: 17.196162 },
    { lat: 48.703274, lng: 17.196127 },
    { lat: 48.703259, lng: 17.196091 },
    { lat: 48.703247, lng: 17.19606 },
    { lat: 48.703233, lng: 17.196027 },
    { lat: 48.703222, lng: 17.195991 },
    { lat: 48.703209, lng: 17.195957 },
    { lat: 48.703196, lng: 17.195923 },
    { lat: 48.703182, lng: 17.195893 },
    { lat: 48.703177, lng: 17.195876 },
    { lat: 48.703169, lng: 17.195856 },
    { lat: 48.703158, lng: 17.195828 },
    { lat: 48.703153, lng: 17.195812 },
    { lat: 48.703127, lng: 17.195752 },
    { lat: 48.703102, lng: 17.195681 },
    { lat: 48.703087, lng: 17.195643 },
    { lat: 48.70308, lng: 17.195625 },
    { lat: 48.703054, lng: 17.195562 },
    { lat: 48.703045, lng: 17.195535 },
    { lat: 48.703032, lng: 17.195505 },
    { lat: 48.703018, lng: 17.195472 },
    { lat: 48.703004, lng: 17.195436 },
    { lat: 48.702992, lng: 17.1954 },
    { lat: 48.702981, lng: 17.195368 },
    { lat: 48.702949, lng: 17.195281 },
    { lat: 48.702934, lng: 17.195246 },
    { lat: 48.702922, lng: 17.195211 },
    { lat: 48.70291, lng: 17.195185 },
    { lat: 48.702892, lng: 17.195135 },
    { lat: 48.702883, lng: 17.195111 },
    { lat: 48.702861, lng: 17.195055 },
    { lat: 48.702849, lng: 17.195026 },
    { lat: 48.702837, lng: 17.194993 },
    { lat: 48.702803, lng: 17.194897 },
    { lat: 48.702784, lng: 17.194856 },
    { lat: 48.702773, lng: 17.194831 },
    { lat: 48.702756, lng: 17.194789 },
    { lat: 48.702731, lng: 17.194726 },
    { lat: 48.702708, lng: 17.194667 },
    { lat: 48.702684, lng: 17.194604 },
    { lat: 48.702669, lng: 17.194571 },
    { lat: 48.70266, lng: 17.194548 },
    { lat: 48.702645, lng: 17.194564 },
    { lat: 48.702447, lng: 17.194167 },
    { lat: 48.7023461, lng: 17.1939681 },
    { lat: 48.702239, lng: 17.193753 },
    { lat: 48.702231, lng: 17.193589 },
    { lat: 48.702238, lng: 17.193418 },
    { lat: 48.702267, lng: 17.193074 },
    { lat: 48.702304, lng: 17.192726 },
    { lat: 48.702272, lng: 17.192597 },
    { lat: 48.702206, lng: 17.192349 },
    { lat: 48.702072, lng: 17.192055 },
    { lat: 48.70191, lng: 17.191693 },
    { lat: 48.701862, lng: 17.191578 },
    { lat: 48.7016424, lng: 17.1910378 },
    { lat: 48.7013252, lng: 17.1906263 },
    { lat: 48.7012609, lng: 17.1901536 },
    { lat: 48.7010832, lng: 17.1897455 },
    { lat: 48.7009228, lng: 17.1893021 },
    { lat: 48.700887, lng: 17.1890901 },
    { lat: 48.7007869, lng: 17.1887555 },
    { lat: 48.700678, lng: 17.188259 },
    { lat: 48.700651, lng: 17.188029 },
    { lat: 48.700642, lng: 17.187953 },
    { lat: 48.700595, lng: 17.187549 },
    { lat: 48.700585, lng: 17.187487 },
    { lat: 48.70056, lng: 17.18749 },
    { lat: 48.700493, lng: 17.187473 },
    { lat: 48.700485, lng: 17.187417 },
    { lat: 48.700483, lng: 17.187405 },
    { lat: 48.700471, lng: 17.187293 },
    { lat: 48.700464, lng: 17.18725 },
    { lat: 48.700448, lng: 17.187109 },
    { lat: 48.700431, lng: 17.186973 },
    { lat: 48.700421, lng: 17.186888 },
    { lat: 48.700412, lng: 17.186811 },
    { lat: 48.700412, lng: 17.18681 },
    { lat: 48.700404, lng: 17.18675 },
    { lat: 48.700388, lng: 17.18669 },
    { lat: 48.700373, lng: 17.186623 },
    { lat: 48.700368, lng: 17.186566 },
    { lat: 48.700355, lng: 17.186433 },
    { lat: 48.700341, lng: 17.186295 },
    { lat: 48.700335, lng: 17.186235 },
    { lat: 48.700328, lng: 17.186161 },
    { lat: 48.700317, lng: 17.186136 },
    { lat: 48.700307, lng: 17.186113 },
    { lat: 48.70027, lng: 17.18604 },
    { lat: 48.700235, lng: 17.185974 },
    { lat: 48.70023, lng: 17.185956 },
    { lat: 48.700237, lng: 17.185861 },
    { lat: 48.700236, lng: 17.185802 },
    { lat: 48.70022, lng: 17.18573 },
    { lat: 48.70019, lng: 17.1856 },
    { lat: 48.700178, lng: 17.185551 },
    { lat: 48.700159, lng: 17.185469 },
    { lat: 48.700139, lng: 17.185377 },
    { lat: 48.700078, lng: 17.185101 },
    { lat: 48.700098, lng: 17.184874 },
    { lat: 48.700103, lng: 17.184657 },
    { lat: 48.700101, lng: 17.184595 },
    { lat: 48.700058, lng: 17.184295 },
    { lat: 48.699632, lng: 17.18376 },
    { lat: 48.69957, lng: 17.183679 },
    { lat: 48.699358, lng: 17.183419 },
    { lat: 48.69926, lng: 17.18327 },
    { lat: 48.699061, lng: 17.182977 },
    { lat: 48.698723, lng: 17.182595 },
    { lat: 48.698577, lng: 17.182378 },
    { lat: 48.698474, lng: 17.182267 },
    { lat: 48.697993, lng: 17.181737 },
    { lat: 48.697662, lng: 17.181326 },
    { lat: 48.697545, lng: 17.181161 },
    { lat: 48.697278, lng: 17.180768 },
    { lat: 48.697019, lng: 17.180293 },
    { lat: 48.696772, lng: 17.179834 },
    { lat: 48.696632, lng: 17.179651 },
    { lat: 48.696522, lng: 17.179501 },
    { lat: 48.696239, lng: 17.179336 },
    { lat: 48.695908, lng: 17.179142 },
    { lat: 48.695892, lng: 17.179133 },
    { lat: 48.695604, lng: 17.178965 },
    { lat: 48.695323, lng: 17.178801 },
    { lat: 48.694951, lng: 17.178585 },
    { lat: 48.694883, lng: 17.178553 },
    { lat: 48.694617, lng: 17.178443 },
    { lat: 48.69432, lng: 17.178318 },
    { lat: 48.693968, lng: 17.178169 },
    { lat: 48.6938683, lng: 17.1781013 },
    { lat: 48.6938, lng: 17.178055 },
    { lat: 48.6936616, lng: 17.1779634 },
    { lat: 48.693587, lng: 17.177914 },
    { lat: 48.693265, lng: 17.177852 },
    { lat: 48.693039, lng: 17.177804 },
    { lat: 48.692847, lng: 17.177832 },
    { lat: 48.692742, lng: 17.177843 },
    { lat: 48.692385, lng: 17.177917 },
    { lat: 48.692158, lng: 17.177961 },
    { lat: 48.691984, lng: 17.178075 },
    { lat: 48.69177, lng: 17.178214 },
    { lat: 48.691636, lng: 17.178254 },
    { lat: 48.691323, lng: 17.178349 },
    { lat: 48.691094, lng: 17.178422 },
    { lat: 48.690917, lng: 17.178473 },
    { lat: 48.690599, lng: 17.178491 },
    { lat: 48.690411, lng: 17.178416 },
    { lat: 48.690264, lng: 17.178324 },
    { lat: 48.690158, lng: 17.178214 },
    { lat: 48.68989, lng: 17.178044 },
    { lat: 48.689736, lng: 17.178039 },
    { lat: 48.689375, lng: 17.178008 },
    { lat: 48.689086, lng: 17.17797 },
    { lat: 48.68877, lng: 17.178008 },
    { lat: 48.688735, lng: 17.178007 },
    { lat: 48.688699, lng: 17.178022 },
    { lat: 48.688621, lng: 17.178046 },
    { lat: 48.688424, lng: 17.178121 },
    { lat: 48.68826, lng: 17.178135 },
    { lat: 48.688097, lng: 17.178139 },
    { lat: 48.68796, lng: 17.178114 },
    { lat: 48.687532, lng: 17.178031 },
    { lat: 48.687282, lng: 17.177933 },
    { lat: 48.686907, lng: 17.177825 },
    { lat: 48.686734, lng: 17.1777 },
    { lat: 48.686557, lng: 17.177595 },
    { lat: 48.686505, lng: 17.177562 },
    { lat: 48.686457, lng: 17.177596 },
    { lat: 48.686438, lng: 17.17761 },
    { lat: 48.686417, lng: 17.177598 },
    { lat: 48.686383, lng: 17.177579 },
    { lat: 48.686346, lng: 17.177559 },
    { lat: 48.68632, lng: 17.177541 },
    { lat: 48.686288, lng: 17.177514 },
    { lat: 48.686181, lng: 17.177505 },
    { lat: 48.686123, lng: 17.177477 },
    { lat: 48.68589, lng: 17.176821 },
    { lat: 48.685845, lng: 17.176545 },
    { lat: 48.685784, lng: 17.17594 },
    { lat: 48.685732, lng: 17.175659 },
    { lat: 48.685683, lng: 17.175465 },
    { lat: 48.685608, lng: 17.175158 },
    { lat: 48.685422, lng: 17.174946 },
    { lat: 48.68536, lng: 17.174906 },
    { lat: 48.685197, lng: 17.174819 },
    { lat: 48.68506, lng: 17.174738 },
    { lat: 48.6842669, lng: 17.1743094 },
    { lat: 48.6837615, lng: 17.1741677 },
    { lat: 48.6830736, lng: 17.1744077 },
    { lat: 48.6824319, lng: 17.1744422 },
    { lat: 48.6803255, lng: 17.1742143 },
    { lat: 48.679692, lng: 17.1737602 },
    { lat: 48.6789735, lng: 17.1737106 },
    { lat: 48.6780837, lng: 17.1735497 },
    { lat: 48.6777548, lng: 17.1733769 },
    { lat: 48.6770715, lng: 17.1732923 },
    { lat: 48.6755592, lng: 17.1728234 },
    { lat: 48.6747134, lng: 17.1723734 },
    { lat: 48.6737277, lng: 17.1716362 },
    { lat: 48.6723718, lng: 17.171007 },
    { lat: 48.6721453, lng: 17.1708119 },
    { lat: 48.6720424, lng: 17.1705902 },
    { lat: 48.6718184, lng: 17.1705853 },
    { lat: 48.6711025, lng: 17.170235 },
    { lat: 48.6705765, lng: 17.1701248 },
    { lat: 48.6688413, lng: 17.1704487 },
    { lat: 48.6683933, lng: 17.1706429 },
    { lat: 48.6680496, lng: 17.1705904 },
    { lat: 48.6637581, lng: 17.1689397 },
    { lat: 48.6610169, lng: 17.1727522 },
    { lat: 48.6607385, lng: 17.1732183 },
    { lat: 48.6605239, lng: 17.173252 },
    { lat: 48.658426, lng: 17.1742226 },
    { lat: 48.6534031, lng: 17.1760754 },
    { lat: 48.6532197, lng: 17.1754432 },
    { lat: 48.6524971, lng: 17.1756711 },
    { lat: 48.6522323, lng: 17.1760893 },
    { lat: 48.6514043, lng: 17.1769549 },
    { lat: 48.6513387, lng: 17.1774498 },
    { lat: 48.6505437, lng: 17.1778083 },
    { lat: 48.6505202, lng: 17.1777111 },
    { lat: 48.6504177, lng: 17.1777695 },
    { lat: 48.6509553, lng: 17.1796311 },
    { lat: 48.6510433, lng: 17.1799357 },
    { lat: 48.6512134, lng: 17.1809625 },
    { lat: 48.6516244, lng: 17.1825571 },
    { lat: 48.6516534, lng: 17.1831094 },
    { lat: 48.6515962, lng: 17.1838973 },
    { lat: 48.6515213, lng: 17.1846044 },
    { lat: 48.6513872, lng: 17.1850381 },
    { lat: 48.6514034, lng: 17.1851779 },
    { lat: 48.6515441, lng: 17.1854424 },
    { lat: 48.6514776, lng: 17.185952 },
    { lat: 48.6515354, lng: 17.1862441 },
    { lat: 48.6515088, lng: 17.1865918 },
    { lat: 48.6516315, lng: 17.1869098 },
    { lat: 48.6518323, lng: 17.1871095 },
    { lat: 48.6518498, lng: 17.1874556 },
    { lat: 48.6513418, lng: 17.1876487 },
    { lat: 48.6501612, lng: 17.187785 },
    { lat: 48.6497653, lng: 17.187353 },
    { lat: 48.6494609, lng: 17.1873469 },
    { lat: 48.6492641, lng: 17.1867332 },
    { lat: 48.6490431, lng: 17.1862297 },
    { lat: 48.6488941, lng: 17.1860432 },
    { lat: 48.6487297, lng: 17.1857648 },
    { lat: 48.6487924, lng: 17.1864688 },
    { lat: 48.6486233, lng: 17.187646 },
    { lat: 48.6483701, lng: 17.1885811 },
    { lat: 48.6482961, lng: 17.1893512 },
    { lat: 48.6485083, lng: 17.1899933 },
    { lat: 48.6485042, lng: 17.1901204 },
    { lat: 48.6486345, lng: 17.1905504 },
    { lat: 48.6488557, lng: 17.190871 },
    { lat: 48.6479945, lng: 17.1912901 },
    { lat: 48.6482609, lng: 17.1921887 },
    { lat: 48.6489199, lng: 17.1931136 },
    { lat: 48.6493484, lng: 17.1936258 },
    { lat: 48.6495465, lng: 17.1937852 },
    { lat: 48.6495692, lng: 17.1938711 },
    { lat: 48.649581, lng: 17.193999 },
    { lat: 48.649399, lng: 17.1941341 },
    { lat: 48.6494704, lng: 17.1945613 },
    { lat: 48.6494376, lng: 17.1949014 },
    { lat: 48.6493129, lng: 17.1950668 },
    { lat: 48.6491356, lng: 17.1951847 },
    { lat: 48.649011, lng: 17.1954858 },
    { lat: 48.6488566, lng: 17.1956635 },
    { lat: 48.6488527, lng: 17.1957409 },
    { lat: 48.6489052, lng: 17.1959527 },
    { lat: 48.6489843, lng: 17.1960715 },
    { lat: 48.6493089, lng: 17.1959217 },
    { lat: 48.6494189, lng: 17.1959315 },
    { lat: 48.6495764, lng: 17.1961249 },
    { lat: 48.6495964, lng: 17.1962054 },
    { lat: 48.6493313, lng: 17.1968729 },
    { lat: 48.6495173, lng: 17.197121 },
    { lat: 48.6496374, lng: 17.1978778 },
    { lat: 48.6496908, lng: 17.1979329 },
    { lat: 48.6499224, lng: 17.1977947 },
    { lat: 48.6500541, lng: 17.1978387 },
    { lat: 48.6501463, lng: 17.1981549 },
    { lat: 48.6499955, lng: 17.1992885 },
    { lat: 48.6502467, lng: 17.2013357 },
    { lat: 48.6513724, lng: 17.2011212 },
    { lat: 48.6514819, lng: 17.2020397 },
    { lat: 48.650706, lng: 17.2025697 },
    { lat: 48.6508557, lng: 17.2029522 },
    { lat: 48.6510396, lng: 17.203683 },
    { lat: 48.6505893, lng: 17.2040469 },
    { lat: 48.650715, lng: 17.2047818 },
    { lat: 48.6503506, lng: 17.2055743 },
    { lat: 48.65028, lng: 17.2056342 },
    { lat: 48.6507196, lng: 17.206878 },
    { lat: 48.650605, lng: 17.2069389 },
    { lat: 48.6504535, lng: 17.2080634 },
    { lat: 48.6497894, lng: 17.208542 },
    { lat: 48.6504618, lng: 17.2101312 },
    { lat: 48.6504884, lng: 17.21019 },
  ],
  BílkoveHumence: [
    { lat: 48.589503, lng: 17.265737 },
    { lat: 48.590186, lng: 17.265642 },
    { lat: 48.590189, lng: 17.265692 },
    { lat: 48.590192, lng: 17.265731 },
    { lat: 48.590253, lng: 17.265721 },
    { lat: 48.590397, lng: 17.265723 },
    { lat: 48.590482, lng: 17.265743 },
    { lat: 48.590576, lng: 17.265804 },
    { lat: 48.590677, lng: 17.26589 },
    { lat: 48.590987, lng: 17.266218 },
    { lat: 48.591381, lng: 17.266443 },
    { lat: 48.59166, lng: 17.266573 },
    { lat: 48.592262, lng: 17.266825 },
    { lat: 48.592468, lng: 17.266902 },
    { lat: 48.592512, lng: 17.266918 },
    { lat: 48.592524, lng: 17.266898 },
    { lat: 48.5925353, lng: 17.2668719 },
    { lat: 48.5950652, lng: 17.2623566 },
    { lat: 48.5986395, lng: 17.2565639 },
    { lat: 48.5986213, lng: 17.2565609 },
    { lat: 48.5985754, lng: 17.2565507 },
    { lat: 48.5986051, lng: 17.2565027 },
    { lat: 48.5938523, lng: 17.248696 },
    { lat: 48.5938861, lng: 17.248627 },
    { lat: 48.5941379, lng: 17.2488448 },
    { lat: 48.5944546, lng: 17.2489878 },
    { lat: 48.5946811, lng: 17.2486576 },
    { lat: 48.5949, lng: 17.2478132 },
    { lat: 48.5950657, lng: 17.2478055 },
    { lat: 48.5951535, lng: 17.2477334 },
    { lat: 48.5954127, lng: 17.2467159 },
    { lat: 48.5955366, lng: 17.2459925 },
    { lat: 48.5955666, lng: 17.2448741 },
    { lat: 48.5953206, lng: 17.2446545 },
    { lat: 48.5956688, lng: 17.2437192 },
    { lat: 48.5957969, lng: 17.243483 },
    { lat: 48.5959408, lng: 17.2435931 },
    { lat: 48.596137, lng: 17.2434498 },
    { lat: 48.5967218, lng: 17.2427095 },
    { lat: 48.5968616, lng: 17.2420736 },
    { lat: 48.5965435, lng: 17.2418915 },
    { lat: 48.5956527, lng: 17.2410971 },
    { lat: 48.5944959, lng: 17.2398344 },
    { lat: 48.5949301, lng: 17.2394123 },
    { lat: 48.5945941, lng: 17.2390106 },
    { lat: 48.5947968, lng: 17.2388813 },
    { lat: 48.5953466, lng: 17.237748 },
    { lat: 48.5954228, lng: 17.2375688 },
    { lat: 48.5952926, lng: 17.2374114 },
    { lat: 48.5955395, lng: 17.236875 },
    { lat: 48.5945588, lng: 17.2357451 },
    { lat: 48.5941674, lng: 17.2354642 },
    { lat: 48.5942773, lng: 17.2346993 },
    { lat: 48.59396, lng: 17.2344436 },
    { lat: 48.5942862, lng: 17.2334342 },
    { lat: 48.5938043, lng: 17.2330145 },
    { lat: 48.5931203, lng: 17.2321014 },
    { lat: 48.5936564, lng: 17.2313437 },
    { lat: 48.5940778, lng: 17.2305755 },
    { lat: 48.5929186, lng: 17.2280812 },
    { lat: 48.5925904, lng: 17.2285331 },
    { lat: 48.5923854, lng: 17.2280658 },
    { lat: 48.5921271, lng: 17.228362 },
    { lat: 48.5916781, lng: 17.2272808 },
    { lat: 48.5924427, lng: 17.2267282 },
    { lat: 48.5925726, lng: 17.2265416 },
    { lat: 48.5921435, lng: 17.2250284 },
    { lat: 48.5916697, lng: 17.2236716 },
    { lat: 48.5915867, lng: 17.2233094 },
    { lat: 48.5924224, lng: 17.2225023 },
    { lat: 48.5922195, lng: 17.2221524 },
    { lat: 48.591857, lng: 17.2218287 },
    { lat: 48.5916745, lng: 17.2214936 },
    { lat: 48.5911764, lng: 17.2209512 },
    { lat: 48.5910762, lng: 17.2207722 },
    { lat: 48.5911265, lng: 17.2207271 },
    { lat: 48.5909526, lng: 17.2204893 },
    { lat: 48.590805, lng: 17.2201047 },
    { lat: 48.5902225, lng: 17.2206574 },
    { lat: 48.5892368, lng: 17.2188089 },
    { lat: 48.5885954, lng: 17.2196821 },
    { lat: 48.5885106, lng: 17.2191178 },
    { lat: 48.5887306, lng: 17.2181751 },
    { lat: 48.5887645, lng: 17.2177153 },
    { lat: 48.5887389, lng: 17.2175011 },
    { lat: 48.5885722, lng: 17.216961 },
    { lat: 48.5881648, lng: 17.2161021 },
    { lat: 48.5880612, lng: 17.2164285 },
    { lat: 48.5873118, lng: 17.2175477 },
    { lat: 48.5862928, lng: 17.2192346 },
    { lat: 48.5844872, lng: 17.2219594 },
    { lat: 48.5844122, lng: 17.222158 },
    { lat: 48.5830917, lng: 17.2244445 },
    { lat: 48.5826476, lng: 17.2249695 },
    { lat: 48.5818051, lng: 17.2261713 },
    { lat: 48.5812219, lng: 17.2272742 },
    { lat: 48.5811921, lng: 17.2272487 },
    { lat: 48.5811101, lng: 17.2273948 },
    { lat: 48.5807194, lng: 17.2277404 },
    { lat: 48.5807013, lng: 17.2277567 },
    { lat: 48.5810509, lng: 17.2303544 },
    { lat: 48.580841, lng: 17.2325756 },
    { lat: 48.5807264, lng: 17.2332518 },
    { lat: 48.580757, lng: 17.234492 },
    { lat: 48.580785, lng: 17.235842 },
    { lat: 48.580832, lng: 17.236427 },
    { lat: 48.58088, lng: 17.237009 },
    { lat: 48.580993, lng: 17.238348 },
    { lat: 48.58101, lng: 17.238401 },
    { lat: 48.581872, lng: 17.240784 },
    { lat: 48.581915, lng: 17.240904 },
    { lat: 48.58251, lng: 17.24241 },
    { lat: 48.583049, lng: 17.243781 },
    { lat: 48.583055, lng: 17.243801 },
    { lat: 48.5831, lng: 17.243938 },
    { lat: 48.582847, lng: 17.244173 },
    { lat: 48.583089, lng: 17.244151 },
    { lat: 48.583331, lng: 17.244186 },
    { lat: 48.583527, lng: 17.244396 },
    { lat: 48.583714, lng: 17.245132 },
    { lat: 48.583953, lng: 17.246048 },
    { lat: 48.584211, lng: 17.247184 },
    { lat: 48.584398, lng: 17.248078 },
    { lat: 48.584445, lng: 17.248282 },
    { lat: 48.58464, lng: 17.24909 },
    { lat: 48.58485, lng: 17.249945 },
    { lat: 48.585129, lng: 17.251235 },
    { lat: 48.585147, lng: 17.251302 },
    { lat: 48.5853, lng: 17.251836 },
    { lat: 48.585561, lng: 17.252516 },
    { lat: 48.585689, lng: 17.253028 },
    { lat: 48.585837, lng: 17.253606 },
    { lat: 48.585938, lng: 17.254003 },
    { lat: 48.586024, lng: 17.254278 },
    { lat: 48.586047, lng: 17.254345 },
    { lat: 48.586243, lng: 17.254696 },
    { lat: 48.586436, lng: 17.255069 },
    { lat: 48.58647, lng: 17.255144 },
    { lat: 48.586532, lng: 17.255326 },
    { lat: 48.586551, lng: 17.255396 },
    { lat: 48.586579, lng: 17.255535 },
    { lat: 48.586598, lng: 17.255661 },
    { lat: 48.586611, lng: 17.255859 },
    { lat: 48.586619, lng: 17.255986 },
    { lat: 48.586631, lng: 17.257236 },
    { lat: 48.586618, lng: 17.258195 },
    { lat: 48.586673, lng: 17.258202 },
    { lat: 48.586676, lng: 17.258248 },
    { lat: 48.587219, lng: 17.258268 },
    { lat: 48.587485, lng: 17.258173 },
    { lat: 48.587898, lng: 17.259703 },
    { lat: 48.587909, lng: 17.259746 },
    { lat: 48.588756, lng: 17.262893 },
    { lat: 48.5888, lng: 17.262871 },
    { lat: 48.588864, lng: 17.263105 },
    { lat: 48.588818, lng: 17.263139 },
    { lat: 48.589243, lng: 17.264769 },
    { lat: 48.589503, lng: 17.265737 },
  ],
  ŠaštínStráže: [
    { lat: 48.6458966, lng: 17.0893203 },
    { lat: 48.6451382, lng: 17.0989121 },
    { lat: 48.6450536, lng: 17.0988822 },
    { lat: 48.6441929, lng: 17.0987175 },
    { lat: 48.6440419, lng: 17.0987432 },
    { lat: 48.6438796, lng: 17.0983965 },
    { lat: 48.6438248, lng: 17.0981694 },
    { lat: 48.6436561, lng: 17.0981295 },
    { lat: 48.6436208, lng: 17.0982768 },
    { lat: 48.6436819, lng: 17.0984453 },
    { lat: 48.6435505, lng: 17.0987274 },
    { lat: 48.6434695, lng: 17.0987301 },
    { lat: 48.6430274, lng: 17.0983563 },
    { lat: 48.6426999, lng: 17.0986444 },
    { lat: 48.6423777, lng: 17.0984415 },
    { lat: 48.6423071, lng: 17.0987014 },
    { lat: 48.6424557, lng: 17.0987954 },
    { lat: 48.6424245, lng: 17.0991196 },
    { lat: 48.6427007, lng: 17.0992617 },
    { lat: 48.6427429, lng: 17.0995364 },
    { lat: 48.6425761, lng: 17.0996098 },
    { lat: 48.6423275, lng: 17.0999788 },
    { lat: 48.6422426, lng: 17.1000121 },
    { lat: 48.6421264, lng: 17.0998442 },
    { lat: 48.6419181, lng: 17.0997217 },
    { lat: 48.6418735, lng: 17.0997636 },
    { lat: 48.6418366, lng: 17.1002072 },
    { lat: 48.6417632, lng: 17.1002603 },
    { lat: 48.6414059, lng: 17.0997173 },
    { lat: 48.6412958, lng: 17.0997771 },
    { lat: 48.64106, lng: 17.1007064 },
    { lat: 48.6411418, lng: 17.1010154 },
    { lat: 48.6410289, lng: 17.1010991 },
    { lat: 48.6409642, lng: 17.10086 },
    { lat: 48.6406222, lng: 17.1011682 },
    { lat: 48.6405779, lng: 17.1014673 },
    { lat: 48.6401167, lng: 17.1015976 },
    { lat: 48.6399321, lng: 17.101793 },
    { lat: 48.6396405, lng: 17.102414 },
    { lat: 48.6396433, lng: 17.1025225 },
    { lat: 48.6397664, lng: 17.1027927 },
    { lat: 48.6397091, lng: 17.1033845 },
    { lat: 48.6396251, lng: 17.103546 },
    { lat: 48.6387479, lng: 17.1037228 },
    { lat: 48.6385846, lng: 17.1039497 },
    { lat: 48.6385348, lng: 17.1043189 },
    { lat: 48.6386247, lng: 17.1046974 },
    { lat: 48.6387336, lng: 17.1048058 },
    { lat: 48.6388149, lng: 17.1051916 },
    { lat: 48.6385708, lng: 17.1062161 },
    { lat: 48.6383966, lng: 17.1063646 },
    { lat: 48.6380816, lng: 17.1069558 },
    { lat: 48.6380456, lng: 17.1072786 },
    { lat: 48.6381171, lng: 17.1075495 },
    { lat: 48.6380957, lng: 17.1079222 },
    { lat: 48.6380023, lng: 17.1082127 },
    { lat: 48.6377055, lng: 17.1085385 },
    { lat: 48.6373973, lng: 17.1096853 },
    { lat: 48.6372522, lng: 17.1100311 },
    { lat: 48.6371626, lng: 17.1100116 },
    { lat: 48.6368247, lng: 17.1102119 },
    { lat: 48.6362072, lng: 17.1106736 },
    { lat: 48.6360823, lng: 17.110985 },
    { lat: 48.6359578, lng: 17.1110494 },
    { lat: 48.6360187, lng: 17.1114337 },
    { lat: 48.6359666, lng: 17.1114971 },
    { lat: 48.6334259, lng: 17.11391 },
    { lat: 48.6327085, lng: 17.1119936 },
    { lat: 48.6310249, lng: 17.1128683 },
    { lat: 48.6309119, lng: 17.1128677 },
    { lat: 48.630553, lng: 17.1133784 },
    { lat: 48.6294048, lng: 17.1131732 },
    { lat: 48.6284035, lng: 17.1133213 },
    { lat: 48.6281731, lng: 17.1134594 },
    { lat: 48.6275827, lng: 17.114074 },
    { lat: 48.6269749, lng: 17.1145101 },
    { lat: 48.6263409, lng: 17.1146778 },
    { lat: 48.6258909, lng: 17.1149195 },
    { lat: 48.6255491, lng: 17.1151031 },
    { lat: 48.625092, lng: 17.1151933 },
    { lat: 48.6241467, lng: 17.1155364 },
    { lat: 48.62268, lng: 17.1162087 },
    { lat: 48.6215814, lng: 17.1162866 },
    { lat: 48.6200175, lng: 17.1168577 },
    { lat: 48.6190632, lng: 17.1173878 },
    { lat: 48.6188419, lng: 17.1176073 },
    { lat: 48.6184831, lng: 17.118171 },
    { lat: 48.6182474, lng: 17.118327 },
    { lat: 48.6177336, lng: 17.1184183 },
    { lat: 48.6170791, lng: 17.1184151 },
    { lat: 48.6162327, lng: 17.1181441 },
    { lat: 48.6158575, lng: 17.1181239 },
    { lat: 48.6156382, lng: 17.118257 },
    { lat: 48.6154133, lng: 17.1181243 },
    { lat: 48.6148158, lng: 17.1171489 },
    { lat: 48.6142069, lng: 17.1163789 },
    { lat: 48.6135911, lng: 17.1152689 },
    { lat: 48.6132645, lng: 17.1148982 },
    { lat: 48.6127817, lng: 17.1141368 },
    { lat: 48.6123764, lng: 17.113351 },
    { lat: 48.6119734, lng: 17.1128017 },
    { lat: 48.6115538, lng: 17.1117029 },
    { lat: 48.6115094, lng: 17.1102528 },
    { lat: 48.6113478, lng: 17.1094127 },
    { lat: 48.6113247, lng: 17.1085661 },
    { lat: 48.6110957, lng: 17.1077492 },
    { lat: 48.6102429, lng: 17.1070148 },
    { lat: 48.6085051, lng: 17.1068615 },
    { lat: 48.6076443, lng: 17.1068492 },
    { lat: 48.6072466, lng: 17.106698 },
    { lat: 48.60634, lng: 17.1054496 },
    { lat: 48.6055851, lng: 17.1050788 },
    { lat: 48.6050808, lng: 17.1045869 },
    { lat: 48.6043016, lng: 17.1042748 },
    { lat: 48.6040213, lng: 17.104036 },
    { lat: 48.6034132, lng: 17.10452 },
    { lat: 48.6028683, lng: 17.105156 },
    { lat: 48.602749, lng: 17.1050118 },
    { lat: 48.6025353, lng: 17.1049431 },
    { lat: 48.6023359, lng: 17.1047326 },
    { lat: 48.6021294, lng: 17.1043655 },
    { lat: 48.6020276, lng: 17.1040319 },
    { lat: 48.6018087, lng: 17.1037655 },
    { lat: 48.6012519, lng: 17.1037357 },
    { lat: 48.6008525, lng: 17.1037958 },
    { lat: 48.6008539, lng: 17.1038348 },
    { lat: 48.5997299, lng: 17.1053994 },
    { lat: 48.5992171, lng: 17.1060418 },
    { lat: 48.5991806, lng: 17.1060014 },
    { lat: 48.5973315, lng: 17.108714 },
    { lat: 48.5930648, lng: 17.1172816 },
    { lat: 48.5917232, lng: 17.1200859 },
    { lat: 48.5910337, lng: 17.1214429 },
    { lat: 48.5909856, lng: 17.1214341 },
    { lat: 48.591367, lng: 17.1225869 },
    { lat: 48.5933327, lng: 17.1284386 },
    { lat: 48.6025982, lng: 17.1548176 },
    { lat: 48.6072141, lng: 17.1677823 },
    { lat: 48.6084137, lng: 17.1706738 },
    { lat: 48.6079488, lng: 17.1715807 },
    { lat: 48.60798, lng: 17.1716749 },
    { lat: 48.6084362, lng: 17.172264 },
    { lat: 48.6095496, lng: 17.17373 },
    { lat: 48.6106799, lng: 17.1753637 },
    { lat: 48.6123055, lng: 17.1771724 },
    { lat: 48.6128119, lng: 17.1781418 },
    { lat: 48.6129009, lng: 17.1785474 },
    { lat: 48.6129572, lng: 17.1792219 },
    { lat: 48.6127721, lng: 17.1807367 },
    { lat: 48.6128467, lng: 17.1817886 },
    { lat: 48.6128214, lng: 17.182238 },
    { lat: 48.6129302, lng: 17.183363 },
    { lat: 48.6129136, lng: 17.1838104 },
    { lat: 48.6142713, lng: 17.1851476 },
    { lat: 48.6144384, lng: 17.1850437 },
    { lat: 48.6146266, lng: 17.1851038 },
    { lat: 48.6153499, lng: 17.1850396 },
    { lat: 48.6155694, lng: 17.1849407 },
    { lat: 48.6158397, lng: 17.1846588 },
    { lat: 48.6162293, lng: 17.1839242 },
    { lat: 48.6171554, lng: 17.1830877 },
    { lat: 48.6178894, lng: 17.1832025 },
    { lat: 48.6190955, lng: 17.1838726 },
    { lat: 48.6195762, lng: 17.18259 },
    { lat: 48.6200635, lng: 17.1807771 },
    { lat: 48.6219295, lng: 17.1802462 },
    { lat: 48.6234068, lng: 17.1819711 },
    { lat: 48.6240643, lng: 17.1824614 },
    { lat: 48.6251653, lng: 17.1842706 },
    { lat: 48.6268224, lng: 17.1843143 },
    { lat: 48.6276976, lng: 17.1833074 },
    { lat: 48.6287143, lng: 17.1820177 },
    { lat: 48.6296335, lng: 17.1812262 },
    { lat: 48.6297926, lng: 17.181191 },
    { lat: 48.6308609, lng: 17.1804198 },
    { lat: 48.6316181, lng: 17.1800829 },
    { lat: 48.6323332, lng: 17.1812861 },
    { lat: 48.6326526, lng: 17.1809776 },
    { lat: 48.6327254, lng: 17.1814917 },
    { lat: 48.633432, lng: 17.182738 },
    { lat: 48.6342962, lng: 17.1835682 },
    { lat: 48.6344876, lng: 17.1830866 },
    { lat: 48.6344068, lng: 17.182821 },
    { lat: 48.6347183, lng: 17.1824441 },
    { lat: 48.635513, lng: 17.1821762 },
    { lat: 48.6360969, lng: 17.1808996 },
    { lat: 48.6363853, lng: 17.1806012 },
    { lat: 48.637351, lng: 17.1815297 },
    { lat: 48.6383097, lng: 17.181727 },
    { lat: 48.6389325, lng: 17.1823228 },
    { lat: 48.6391214, lng: 17.1822941 },
    { lat: 48.6398308, lng: 17.1819191 },
    { lat: 48.639654, lng: 17.1807391 },
    { lat: 48.6396348, lng: 17.1803141 },
    { lat: 48.6399715, lng: 17.1793621 },
    { lat: 48.6410077, lng: 17.1780236 },
    { lat: 48.6412555, lng: 17.1778096 },
    { lat: 48.6417112, lng: 17.1776994 },
    { lat: 48.6422723, lng: 17.1774532 },
    { lat: 48.6431406, lng: 17.1768689 },
    { lat: 48.6436515, lng: 17.1764813 },
    { lat: 48.6450623, lng: 17.1760183 },
    { lat: 48.6476511, lng: 17.175328 },
    { lat: 48.647841, lng: 17.1756515 },
    { lat: 48.647994, lng: 17.1761352 },
    { lat: 48.6483733, lng: 17.1762817 },
    { lat: 48.6484389, lng: 17.1765733 },
    { lat: 48.6483665, lng: 17.1771642 },
    { lat: 48.6481508, lng: 17.1773027 },
    { lat: 48.6480256, lng: 17.1775106 },
    { lat: 48.6479149, lng: 17.1775078 },
    { lat: 48.6477805, lng: 17.1776972 },
    { lat: 48.6477648, lng: 17.1778485 },
    { lat: 48.6478669, lng: 17.1779749 },
    { lat: 48.6479181, lng: 17.1785584 },
    { lat: 48.6479887, lng: 17.178617 },
    { lat: 48.6480473, lng: 17.1788032 },
    { lat: 48.6480532, lng: 17.1791621 },
    { lat: 48.6478576, lng: 17.1795498 },
    { lat: 48.6478735, lng: 17.1797385 },
    { lat: 48.6478279, lng: 17.1799534 },
    { lat: 48.6476398, lng: 17.1800298 },
    { lat: 48.6476066, lng: 17.1801248 },
    { lat: 48.6476326, lng: 17.1803974 },
    { lat: 48.6475653, lng: 17.1806249 },
    { lat: 48.6475427, lng: 17.1810996 },
    { lat: 48.6475628, lng: 17.1812312 },
    { lat: 48.6478412, lng: 17.1814584 },
    { lat: 48.6477304, lng: 17.1818325 },
    { lat: 48.6479017, lng: 17.1823674 },
    { lat: 48.6480057, lng: 17.1824046 },
    { lat: 48.6480509, lng: 17.1826918 },
    { lat: 48.6480595, lng: 17.1827716 },
    { lat: 48.6478762, lng: 17.1832256 },
    { lat: 48.6479595, lng: 17.1835956 },
    { lat: 48.6480988, lng: 17.1847214 },
    { lat: 48.6482084, lng: 17.1849742 },
    { lat: 48.648605, lng: 17.1855697 },
    { lat: 48.6487297, lng: 17.1857648 },
    { lat: 48.6488941, lng: 17.1860432 },
    { lat: 48.6490431, lng: 17.1862297 },
    { lat: 48.6492641, lng: 17.1867332 },
    { lat: 48.6494609, lng: 17.1873469 },
    { lat: 48.6497653, lng: 17.187353 },
    { lat: 48.6501612, lng: 17.187785 },
    { lat: 48.6513418, lng: 17.1876487 },
    { lat: 48.6518498, lng: 17.1874556 },
    { lat: 48.6518323, lng: 17.1871095 },
    { lat: 48.6516315, lng: 17.1869098 },
    { lat: 48.6515088, lng: 17.1865918 },
    { lat: 48.6515354, lng: 17.1862441 },
    { lat: 48.6514776, lng: 17.185952 },
    { lat: 48.6515441, lng: 17.1854424 },
    { lat: 48.6514034, lng: 17.1851779 },
    { lat: 48.6513872, lng: 17.1850381 },
    { lat: 48.6515213, lng: 17.1846044 },
    { lat: 48.6515962, lng: 17.1838973 },
    { lat: 48.6516534, lng: 17.1831094 },
    { lat: 48.6516244, lng: 17.1825571 },
    { lat: 48.6512134, lng: 17.1809625 },
    { lat: 48.6510433, lng: 17.1799357 },
    { lat: 48.6509553, lng: 17.1796311 },
    { lat: 48.6504177, lng: 17.1777695 },
    { lat: 48.6505202, lng: 17.1777111 },
    { lat: 48.6505437, lng: 17.1778083 },
    { lat: 48.6513387, lng: 17.1774498 },
    { lat: 48.6514043, lng: 17.1769549 },
    { lat: 48.6522323, lng: 17.1760893 },
    { lat: 48.6524971, lng: 17.1756711 },
    { lat: 48.6532197, lng: 17.1754432 },
    { lat: 48.6534031, lng: 17.1760754 },
    { lat: 48.658426, lng: 17.1742226 },
    { lat: 48.6605239, lng: 17.173252 },
    { lat: 48.6607385, lng: 17.1732183 },
    { lat: 48.6610169, lng: 17.1727522 },
    { lat: 48.6637581, lng: 17.1689397 },
    { lat: 48.6680496, lng: 17.1705904 },
    { lat: 48.6683933, lng: 17.1706429 },
    { lat: 48.6688413, lng: 17.1704487 },
    { lat: 48.6705765, lng: 17.1701248 },
    { lat: 48.6711025, lng: 17.170235 },
    { lat: 48.6718184, lng: 17.1705853 },
    { lat: 48.6720424, lng: 17.1705902 },
    { lat: 48.6721453, lng: 17.1708119 },
    { lat: 48.6723718, lng: 17.171007 },
    { lat: 48.6737277, lng: 17.1716362 },
    { lat: 48.6747134, lng: 17.1723734 },
    { lat: 48.6755592, lng: 17.1728234 },
    { lat: 48.6770715, lng: 17.1732923 },
    { lat: 48.6777548, lng: 17.1733769 },
    { lat: 48.6780837, lng: 17.1735497 },
    { lat: 48.6789735, lng: 17.1737106 },
    { lat: 48.679692, lng: 17.1737602 },
    { lat: 48.6803255, lng: 17.1742143 },
    { lat: 48.6824319, lng: 17.1744422 },
    { lat: 48.6830736, lng: 17.1744077 },
    { lat: 48.6837615, lng: 17.1741677 },
    { lat: 48.6842669, lng: 17.1743094 },
    { lat: 48.68506, lng: 17.174738 },
    { lat: 48.685067, lng: 17.174719 },
    { lat: 48.685418, lng: 17.173724 },
    { lat: 48.685474, lng: 17.173467 },
    { lat: 48.685563, lng: 17.172813 },
    { lat: 48.685681, lng: 17.172539 },
    { lat: 48.685705, lng: 17.17249 },
    { lat: 48.685778, lng: 17.172244 },
    { lat: 48.68581, lng: 17.172092 },
    { lat: 48.685833, lng: 17.171965 },
    { lat: 48.685884, lng: 17.171766 },
    { lat: 48.685946, lng: 17.171533 },
    { lat: 48.685973, lng: 17.171276 },
    { lat: 48.685972, lng: 17.171001 },
    { lat: 48.686061, lng: 17.170507 },
    { lat: 48.68614, lng: 17.170282 },
    { lat: 48.686326, lng: 17.169974 },
    { lat: 48.686674, lng: 17.169558 },
    { lat: 48.686919, lng: 17.168974 },
    { lat: 48.686976, lng: 17.168729 },
    { lat: 48.686995, lng: 17.168759 },
    { lat: 48.68701, lng: 17.168726 },
    { lat: 48.687004, lng: 17.168327 },
    { lat: 48.687003, lng: 17.168279 },
    { lat: 48.687016, lng: 17.167932 },
    { lat: 48.687063, lng: 17.167525 },
    { lat: 48.687114, lng: 17.167394 },
    { lat: 48.687145, lng: 17.167236 },
    { lat: 48.687338, lng: 17.166985 },
    { lat: 48.687186, lng: 17.165739 },
    { lat: 48.687228, lng: 17.165615 },
    { lat: 48.687218, lng: 17.165052 },
    { lat: 48.687132, lng: 17.164525 },
    { lat: 48.687105, lng: 17.164535 },
    { lat: 48.687012, lng: 17.164229 },
    { lat: 48.686984, lng: 17.163739 },
    { lat: 48.68692, lng: 17.163591 },
    { lat: 48.686825, lng: 17.16351 },
    { lat: 48.686777, lng: 17.163456 },
    { lat: 48.686508, lng: 17.163229 },
    { lat: 48.686396, lng: 17.163025 },
    { lat: 48.686274, lng: 17.162852 },
    { lat: 48.686022, lng: 17.16274 },
    { lat: 48.685982, lng: 17.162721 },
    { lat: 48.685926, lng: 17.162696 },
    { lat: 48.6852, lng: 17.160816 },
    { lat: 48.685181, lng: 17.160759 },
    { lat: 48.68429, lng: 17.158199 },
    { lat: 48.68427, lng: 17.158159 },
    { lat: 48.684246, lng: 17.158081 },
    { lat: 48.684101, lng: 17.157602 },
    { lat: 48.684072, lng: 17.157514 },
    { lat: 48.683632, lng: 17.156039 },
    { lat: 48.683279, lng: 17.154222 },
    { lat: 48.683217, lng: 17.153624 },
    { lat: 48.683223, lng: 17.153583 },
    { lat: 48.683267, lng: 17.15344 },
    { lat: 48.681539, lng: 17.151269 },
    { lat: 48.6805888, lng: 17.1502718 },
    { lat: 48.6800114, lng: 17.1497469 },
    { lat: 48.6797171, lng: 17.1495847 },
    { lat: 48.6793672, lng: 17.1494886 },
    { lat: 48.6779359, lng: 17.1492211 },
    { lat: 48.677416, lng: 17.1491691 },
    { lat: 48.6762943, lng: 17.149223 },
    { lat: 48.6763989, lng: 17.1474081 },
    { lat: 48.676664, lng: 17.1457103 },
    { lat: 48.6761155, lng: 17.1453453 },
    { lat: 48.6752341, lng: 17.1445283 },
    { lat: 48.6749356, lng: 17.1444408 },
    { lat: 48.6745795, lng: 17.1444411 },
    { lat: 48.6741326, lng: 17.1445164 },
    { lat: 48.6739328, lng: 17.144638 },
    { lat: 48.6738284, lng: 17.1441198 },
    { lat: 48.6732455, lng: 17.144259 },
    { lat: 48.6733316, lng: 17.1448883 },
    { lat: 48.673139, lng: 17.1449817 },
    { lat: 48.6728541, lng: 17.1449943 },
    { lat: 48.6725105, lng: 17.1434388 },
    { lat: 48.6719879, lng: 17.1435474 },
    { lat: 48.6719602, lng: 17.1432685 },
    { lat: 48.6718815, lng: 17.1431893 },
    { lat: 48.6710196, lng: 17.1433958 },
    { lat: 48.670841, lng: 17.1433451 },
    { lat: 48.6706811, lng: 17.1431811 },
    { lat: 48.6705252, lng: 17.1425735 },
    { lat: 48.6703605, lng: 17.1425443 },
    { lat: 48.6704214, lng: 17.142831 },
    { lat: 48.6700372, lng: 17.1427112 },
    { lat: 48.6693699, lng: 17.1428018 },
    { lat: 48.6693248, lng: 17.1427694 },
    { lat: 48.6697299, lng: 17.1405318 },
    { lat: 48.6696163, lng: 17.1405073 },
    { lat: 48.6695276, lng: 17.1408511 },
    { lat: 48.6691751, lng: 17.1405653 },
    { lat: 48.6682519, lng: 17.1393288 },
    { lat: 48.6670259, lng: 17.1389742 },
    { lat: 48.6670129, lng: 17.1382749 },
    { lat: 48.6668061, lng: 17.1382238 },
    { lat: 48.6665963, lng: 17.1370795 },
    { lat: 48.66622, lng: 17.1371995 },
    { lat: 48.6656282, lng: 17.1376221 },
    { lat: 48.6654196, lng: 17.1376965 },
    { lat: 48.66506, lng: 17.1376577 },
    { lat: 48.6645457, lng: 17.1372994 },
    { lat: 48.6644608, lng: 17.1353236 },
    { lat: 48.6644685, lng: 17.1337351 },
    { lat: 48.6638735, lng: 17.1334048 },
    { lat: 48.6639095, lng: 17.1328968 },
    { lat: 48.6642447, lng: 17.1308815 },
    { lat: 48.6644144, lng: 17.1308875 },
    { lat: 48.6645224, lng: 17.1290073 },
    { lat: 48.6647711, lng: 17.1261088 },
    { lat: 48.665037, lng: 17.1244272 },
    { lat: 48.6647615, lng: 17.1243117 },
    { lat: 48.6650012, lng: 17.1227134 },
    { lat: 48.6643694, lng: 17.1226948 },
    { lat: 48.6645814, lng: 17.1214071 },
    { lat: 48.6647647, lng: 17.1206206 },
    { lat: 48.6643646, lng: 17.1203322 },
    { lat: 48.6642238, lng: 17.1208449 },
    { lat: 48.6637249, lng: 17.1206152 },
    { lat: 48.6642343, lng: 17.1183223 },
    { lat: 48.6642314, lng: 17.1181948 },
    { lat: 48.6640866, lng: 17.1181615 },
    { lat: 48.6642124, lng: 17.1177459 },
    { lat: 48.6639658, lng: 17.1172017 },
    { lat: 48.6638042, lng: 17.1169833 },
    { lat: 48.6628721, lng: 17.1161247 },
    { lat: 48.6629951, lng: 17.1154979 },
    { lat: 48.6615679, lng: 17.1137462 },
    { lat: 48.6593399, lng: 17.1111763 },
    { lat: 48.6587801, lng: 17.1109671 },
    { lat: 48.658158, lng: 17.1121674 },
    { lat: 48.6573004, lng: 17.1110256 },
    { lat: 48.6567674, lng: 17.1100522 },
    { lat: 48.6545803, lng: 17.1040067 },
    { lat: 48.6543598, lng: 17.1040065 },
    { lat: 48.6540892, lng: 17.1038629 },
    { lat: 48.6539663, lng: 17.1038842 },
    { lat: 48.6536121, lng: 17.1036411 },
    { lat: 48.6534007, lng: 17.103606 },
    { lat: 48.6532684, lng: 17.1037063 },
    { lat: 48.6530732, lng: 17.1036936 },
    { lat: 48.6529505, lng: 17.1035795 },
    { lat: 48.652721, lng: 17.1037655 },
    { lat: 48.6523589, lng: 17.1043462 },
    { lat: 48.6520599, lng: 17.1042512 },
    { lat: 48.651705, lng: 17.1042834 },
    { lat: 48.6515856, lng: 17.1042363 },
    { lat: 48.6514891, lng: 17.1033052 },
    { lat: 48.6503317, lng: 17.103168 },
    { lat: 48.6499853, lng: 17.1046186 },
    { lat: 48.6496686, lng: 17.1043805 },
    { lat: 48.6494891, lng: 17.102602 },
    { lat: 48.6484264, lng: 17.099015 },
    { lat: 48.6481516, lng: 17.0964446 },
    { lat: 48.6476083, lng: 17.0963439 },
    { lat: 48.6468745, lng: 17.0957675 },
    { lat: 48.6465447, lng: 17.0932843 },
    { lat: 48.6462801, lng: 17.0921575 },
    { lat: 48.6472052, lng: 17.0919688 },
    { lat: 48.646921, lng: 17.0905502 },
    { lat: 48.6461373, lng: 17.0900566 },
    { lat: 48.6461266, lng: 17.0894793 },
    { lat: 48.645937, lng: 17.0892969 },
    { lat: 48.6458966, lng: 17.0893203 },
  ],
};

export default UnitsSenica;
